import React from 'react';
import { Row, Col } from 'react-bootstrap';

// Import Icons
import backicon from 'icons/back.svg';

const ViewBox = ({ data, closeViewParent }) => {
  return (
    <div className='view-edit-box-conatiner pb-5'>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => closeViewParent(false)}
          />
          <div>
            <h5 className='view-edit-box-title'>Cloud Eye Application</h5>
          </div>
        </div>
      </div>
      <div className='table-view-box-content mt-4 mb-5'>
        <Row>
          <Col md={12} className='mt-3'>
            <div className='account-request-view-table-container'>
              <table width='100%'>
                <tbody className='account-request-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Request UID
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-uppercase'
                    >
                      {data.uid}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      User GID
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-uppercase'
                    >
                      {data.gid}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Cloud Type
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.type}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Cloud Service
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.service}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Submitted By
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.submittedBy}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ViewBox;
