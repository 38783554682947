import React from 'react';
import { Row, Col } from 'react-bootstrap';

const CardDetail = ({ data }) => {
  return (
    <Row>
      <Col md={12} className='card-detail-wrapper'>
        {data['FreeStorageSpace'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Free Storage Space</div>
                <div className='card-values'>
                  {Object.keys(data['FreeStorageSpace']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['FreeStorageSpace']).pop()
                  ).toFixed(2)}
                  B
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['DatabaseConnections'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Database Connections</div>
                <div className='card-values'>
                  {Object.keys(data['DatabaseConnections']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['DatabaseConnections']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['FreeableMemory'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Freeable Memory</div>
                <div className='card-values'>
                  {Object.keys(data['FreeableMemory']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['FreeableMemory']).pop()
                  ).toFixed(2)}
                  B
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['WriteIOPS'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Write IO PS</div>
                <div className='card-values'>
                  {Object.keys(data['WriteIOPS']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(Object.values(data['WriteIOPS']).pop()).toFixed(2)}B
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['ReadIOPS'] && (
          <div className='mb-2'>
            <div>
              <div className='card-title'>Read IO PS</div>
              <div className='card-values'>
                {Object.keys(data['ReadIOPS']).pop()}
              </div>
              <div className='card-values'>
                {parseInt(Object.values(data['ReadIOPS']).pop()).toFixed(2)}B
              </div>
            </div>
          </div>
        )}
      </Col>
      <Col md={12} className='d-flex justify-content-end mt-3'>
        <div className='regular-text utc-note'>*All timings are in UTC</div>
      </Col>
    </Row>
  );
};

export default CardDetail;
