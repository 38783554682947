import React from "react";
import { Row, Col, Table } from "react-bootstrap";

// Import Icons
import backicon from "icons/back.svg";
import editicon from "icons/edit.svg";

import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";

const AccountRequestsView = ({ data, closeViewParent }) => {
  return (
    <div className="view-edit-box-conatiner">
      <div className="view-edit-box-top-bar">
        <div className="view-edit-box-back-title-container">
          <img
            src={backicon}
            alt="Back"
            className="view-edit-box-back-btn"
            onClick={() => closeViewParent(false)}
          />
          <div>
            <h5 className="view-edit-box-title">Billing Details</h5>
            {(data.requestStatus === "Approved" ||
              data.requestStatus === "Cancelled" ||
              data.requestStatus === "Declined") && (
              <Row>
                <Col md={12}>
                  <div className="d-flex justify-content-center">
                    <span className="account-request-view-request-message">
                      {data.RequestActionTakenBy &&
                        `Request ${data.requestStatus} by
                      ${data.RequestActionTakenBy}`}
                    </span>
                  </div>
                </Col>
              </Row>
            )}
          </div>
          {data.requestStatus === "Approved" && (
            <span className="view-edit-box-accountid mx-3">
              ID: {data.accountnumber}
            </span>
          )}
        </div>
      </div>
      <div className="table-view-box-content mt-4 mb-5">
        <Row>
          <Col md={12}>
            <div className="account-request-view-title-container">
              <p className="account-request-view-title">User Id: {data?.id}</p>
            </div>
          </Col>
          <Col md={12} className="mt-3">
            <div className="account-request-view-table-container">
              <table width="100%">
                <tbody className="account-request-view-table-tbody">
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data?.values?.firstName}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Billing Amount
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data?.values?.billingAmount}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Required Amount
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data?.values?.requiredAmount}
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Provided Hour
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.hour}
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Provided Minute
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.minute}
                    </td>
                  </tr> */}
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Charges
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data?.values?.status}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Submitted By
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data?.submittedBy}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AccountRequestsView;
