import React from 'react';
import { Row, Col } from 'react-bootstrap';

// Import Internal Components
import AccountRequestsTable from './AccountRequestsTable';

const AccountRequestsViewRequests = () => {
  return (
    <div className='mt-4'>
      <Row>
        <Col md={12}>
          <AccountRequestsTable />
        </Col>
      </Row>
    </div>
  );
};

export default AccountRequestsViewRequests;
