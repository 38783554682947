import {
  SERVICE,
  MORIARTY,
  REMEDY_TICKET,
  PRISMA,
  TREND,
} from './dashboardConstant';

export const dashboardChartReducer = (
  state = {
    serviceCanvas: false,
    moriartyCanvas: false,
    remedyTicketCanvas: false,
    prismaCanvas: false,
    trendCanvas: false,
  },
  action
) => {
  switch (action.type) {
    case SERVICE:
      return { ...state, serviceCanvas: action.payload };

    case MORIARTY:
      return { ...state, moriartyCanvas: action.payload };

    case REMEDY_TICKET:
      return { ...state, remedyTicketCanvas: action.payload };

    case PRISMA:
      return { ...state, prismaCanvas: action.payload };

    case TREND:
      return { ...state, trendCanvas: action.payload };

    default:
      return state;
  }
};
