import AWS from "aws-sdk";
import Axios from "axios";
import { getApiCall } from "helper/getApiCall";

const roleArn = "arn:aws:iam::310338475039:role/Hub-portal-Billing";
const region = "us-east-1";
const bucketName = "gcs-chargebacks";

export const getAwsSdkSts = async () => {
  AWS.config.update({
    credentials: {
      accessKeyId: "AKIAUQQM5KQP225XZ3HM",
      secretAccessKey: "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",
      sessionToken: null,
    },
    region: region,
  });

  async function connectToS3WithAssumeRole() {
    try {
      // 1. Configure AWS SDK with temporary credentials
      const sts = new AWS.STS({ region });

      const assumeRoleParams = {
        RoleArn: roleArn,
        RoleSessionName: "S3ConnectionSession",
        DurationSeconds: 3600, // Set a reasonable session duration (e.g., 1 hour)
      };

      const data = await sts.assumeRole(assumeRoleParams).promise();

      const credentials = data.Credentials;
      AWS.config.update({
        credentials: {
          accessKeyId: credentials.AccessKeyId,
          secretAccessKey: credentials.SecretAccessKey,
          sessionToken: credentials.SessionToken,
        },
        region: region,
      });
    } catch (err) {
      console.log("errrr", err);
    }
  }
  console.log(connectToS3WithAssumeRole());
};

export const handleDownload = (apiUrl, template) => {
  // const s3 = new AWS.S3();
  // let status;
  // const params = {
  //   Bucket: bucketName,
  //   Key: `${template}`,
  //   PartNumber: null,
  // };

  function downloadBlob(
    blob,
    // name = `${"2024-03-Charges-2024-02-Usage-v1.xlsx"}`
    name
  ) {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    console.log("blob content", blob);
    const blobUrl = URL.createObjectURL(blob);
    // Create a link element
    const link = document.createElement("a");
    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;
    // Append link to the body
    document.body.appendChild(link);
    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  }

  // return s3
  //   .getObject(params)
  //   .promise()
  //   .then((response) => {
  //     let csvBlob = new Blob([response.Body], {
  //       type:
  //         "application/vnd.openxmlformats- officedocument.spreadsheetml.sheet;charset=utf-8",
  //     });
  //     downloadBlob(csvBlob, `${template}`);
  //     return true;
  //   })
  //   .catch((err) => {
  //     console.log("err", err);
  //     console.log("err.stack", err.stack);
  //     return false;
  //   });

  return Axios.get(apiUrl, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/pdf",
    },
  })
    .then((response) => {
      console.log("file dowload response", response.data);
      let csvBlob = new Blob([response?.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      downloadBlob(csvBlob, template);
      return true;
    })
    .catch((err) => {
      console.log("error", err);
      return false;
    });
};
