import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import SpinnerLoader from 'components/Common/SpinnerLoader';

const CIDRDownload = () => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const fileName = `Azure CIDR`;
  const [downloadLoading, setDownloadLoading] = useState(false);

  function downloadCIDR() {
    setDownloadLoading(true);
    const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: setIdToken,
      },
    };
    const apiUrl = `${process.env.REACT_APP_AZURE_CIDR_DOWNLOAD_URL}`;

    axios.post(apiUrl, {}, config).then((r) => {
      exportToCSV(r.data, fileName);
      setDownloadLoading(false);
    });
  }

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <>
      {downloadLoading && <SpinnerLoader />}
      {downloadLoading === false && (
        <button
          type='button'
          className='teal-bordered-btn w-100'
          onClick={(e) => {
            downloadCIDR();
          }}
        >
          <i className='fa fa-download' style={{ marginRight: '6px' }}></i>
          Download
        </button>
      )}
    </>
  );
};

export default CIDRDownload;
