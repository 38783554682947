import React from 'react';
import { Form } from 'react-bootstrap';

const StaticDefaultValueDropdown = ({
  label,
  selectValues,
  isInvalid,
  invalidMessage,
  isInfoBubbleAvailable,
  infoBubbleTitle,
  ...others
}) => {
  return (
    <Form.Group className='mb-3'>
      <Form.Label>
        {label}
        {isInfoBubbleAvailable && (
          <i
            className='fa fa-info-circle px-2'
            data-toggle='tooltip'
            data-placement='bottom'
            title={infoBubbleTitle}
          ></i>
        )}
      </Form.Label>

      <Form.Select isInvalid={isInvalid} {...others}>
        {selectValues.map((data, index) => (
          <option key={index} value={data.value}>
            {data.label}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type='invalid'>
        {invalidMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default StaticDefaultValueDropdown;
