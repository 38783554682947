export const snoozeAlarmValidator = ({
  startDate,
  endDate,
  startDateHour,
  startDateMinute,
  endDateHour,
  endDateMinute,
}) => {
  let errors = {};

  if (!startDate || startDate === null) {
    errors.startDate = 'Start date is required';
  }

  if (!endDate || endDate === null) {
    errors.endDate = 'End date is required';
  }

  if (!startDateHour) {
    errors.startDateHour = 'Start date hour is required';
  }

  if (!startDateMinute) {
    errors.startDateMinute = 'Start date minute is required';
  }

  if (!endDateHour) {
    errors.endDateHour = 'End date hour is required';
  }

  if (!endDateMinute) {
    errors.endDateMinute = 'End date minute is required';
  }

  // Hour Comparison

  if (startDateHour > endDateHour) {
    errors.endDateHour = 'End date hour is lesser';
  }

  if (startDateHour > endDateHour && startDateMinute > endDateMinute) {
    errors.endDateMinute = 'End date minute is lesser';
  }

  if (startDateHour === endDateHour && startDateMinute === endDateMinute) {
    errors.endDateHour = 'End date hour is same as Start date hour';
    errors.endDateMinute = 'End date minute is same as Start date minute';
  }

  return errors;
};

// EC2 Set Alarm Validator
export const ec2SetAlarmValidator = ({
  metricName,
  alarmDescription,
  statistic,
  period,
  evaluationPeriod,
  condition,
  threshold,
  resource,
  diskPath,
}) => {
  let errors = {};

  if (!metricName) {
    errors.metricName = 'Metric name is required';
  }

  if (!alarmDescription) {
    errors.alarmDescription = 'Alarm Description is required';
  }

  if (!statistic) {
    errors.statistic = 'Statistic is required';
  }

  if (!period) {
    errors.period = 'Period is required';
  }

  let isnum = /^\d+$/.test(evaluationPeriod);
  if (!evaluationPeriod) {
    errors.evaluationPeriod = 'Evaluation Period is required';
  } else if (isnum === false) {
    errors.evaluationPeriod = 'Only digits is allowed';
  }

  if (!condition) {
    errors.condition = 'Condition is required';
  }

  if (!threshold) {
    errors.threshold = 'Threshold is required';
  }

  if (!resource || resource.length === 0) {
    errors.resource = 'Resource is required';
  }

  if (metricName === 'disk_used_percent') {
    if (!diskPath || diskPath.length === 0) {
      errors.diskPath = 'Disk Path is required';
    }
  }

  return errors;
};

// EFS Set Alarm Validator

export const efsSetAlarmValidator = ({
  metricName,
  alarmDescription,
  statistic,
  period,
  evaluationPeriod,
  condition,
  threshold,
  resource,
}) => {
  let errors = {};

  if (!metricName) {
    errors.metricName = 'Metric name is required';
  }

  if (!alarmDescription) {
    errors.alarmDescription = 'Alarm Description is required';
  }

  if (!statistic) {
    errors.statistic = 'Statistic is required';
  }

  if (!period) {
    errors.period = 'Period is required';
  }

  let isnum = /^\d+$/.test(evaluationPeriod);
  if (!evaluationPeriod) {
    errors.evaluationPeriod = 'Evaluation Period is required';
  } else if (isnum === false) {
    errors.evaluationPeriod = 'Only digits is allowed';
  }

  if (!condition) {
    errors.condition = 'Condition is required';
  }

  let isThresholdnum = /^\d+$/.test(threshold);
  if (!threshold) {
    errors.threshold = 'Threshold is required';
  } else if (isThresholdnum === false) {
    errors.threshold = 'Only digits is allowed';
  }

  if (!resource || resource.length === 0) {
    errors.resource = 'Resource is required';
  }

  return errors;
};

// LB Set Alarm Validator

export const lbSetAlarmValidator = ({
  metricName,
  alarmDescription,
  statistic,
  period,
  evaluationPeriod,
  condition,
  threshold,
  resource,
}) => {
  let errors = {};

  if (!metricName) {
    errors.metricName = 'Metric name is required';
  }

  if (!alarmDescription) {
    errors.alarmDescription = 'Alarm Description is required';
  }

  if (!statistic) {
    errors.statistic = 'Statistic is required';
  }

  if (!period) {
    errors.period = 'Period is required';
  }

  let isnum = /^\d+$/.test(evaluationPeriod);
  if (!evaluationPeriod) {
    errors.evaluationPeriod = 'Evaluation Period is required';
  } else if (isnum === false) {
    errors.evaluationPeriod = 'Only digits is allowed';
  }

  if (!condition) {
    errors.condition = 'Condition is required';
  }

  let isThresholdnum = /^\d+$/.test(threshold);
  if (!threshold) {
    errors.threshold = 'Threshold is required';
  } else if (isThresholdnum === false) {
    errors.threshold = 'Only digits is allowed';
  }

  if (!resource || resource.length === 0) {
    errors.resource = 'Resource is required';
  }

  return errors;
};

// LB Set Alarm Validator

export const rdsSetAlarmValidator = ({
  metricName,
  alarmDescription,
  statistic,
  period,
  evaluationPeriod,
  condition,
  threshold,
  resource,
}) => {
  let errors = {};

  if (!metricName) {
    errors.metricName = 'Metric name is required';
  }

  if (!alarmDescription) {
    errors.alarmDescription = 'Alarm Description is required';
  }

  if (!statistic) {
    errors.statistic = 'Statistic is required';
  }

  if (!period) {
    errors.period = 'Period is required';
  }

  let isnum = /^\d+$/.test(evaluationPeriod);
  if (!evaluationPeriod) {
    errors.evaluationPeriod = 'Evaluation Period is required';
  } else if (isnum === false) {
    errors.evaluationPeriod = 'Only digits is allowed';
  }

  if (!condition) {
    errors.condition = 'Condition is required';
  }

  if (!threshold) {
    errors.threshold = 'Threshold is required';
  }

  if (!resource || resource.length === 0) {
    errors.resource = 'Resource is required';
  }

  return errors;
};
