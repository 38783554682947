import React from "react";

// Import Internal Components

import AccountDetails from "./AccountDetails";

const FormSteps = (step, setStep, formData, setFormData) => [
  {
    title: "Account details",
    content: (
      <AccountDetails
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
      />
    ),
  },
];

export default FormSteps;
