import React from "react";

const ErrorAlert = ({ addErrorMsg, setShowErrorAlert, setAddErrorMsg }) => {
  const handleClick = () => {
    setShowErrorAlert(false);
    setAddErrorMsg(null);
  };
  return (
    <div className="d-flex justify-content-center flex-column">
      <p>{addErrorMsg}</p>
      <p>
        <button
          type="button"
          className="teal-filled-btn"
          onClick={() => {
            handleClick();
          }}
        >
          Ok
        </button>
      </p>
    </div>
  );
};

export default ErrorAlert;
