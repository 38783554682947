import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import SpinnerLoader from "components/Common/SpinnerLoader";
import CustomModal from "components/Modal/CustomModal";
import TextInput from "components/Forms/TextInput";

import Cancel from "./Cancel";
import Submit from "./Submit";

// Validator
import { toolExceptionValidate } from "../Validator/validator";

// Helper
import { getApiCall } from "helper/getApiCall";
import { postApiCall } from "helper/postApiCall";

//static data
import {
  biaRatingOption,
  customeRoleData,
  customeRoleDataValues,
  exceptionCategoryOption,
  exceptionTimeLineOption,
} from "static-contents/customRoleData";

// Roles
import {
  CS_Hub_AWS_Account_Request_Admin,
  CS_Hub_Security_Admin_Branch_1,
} from "roles/roles";
import { regionData } from "static-contents/region-data";
import WarningMessage from "./WarningMessage";
const AddRequest = () => {
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // React Select Option
  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid red",
    }),
  };

  // Data Input Error
  const [dataInputError, setDataInputError] = useState({});

  // Account Number State & API State
  const [loadingDropdown, setLoadingDropdown] = useState(true);
  const [accountNumberNameData, setAccountNumberNameData] = useState([]);
  const [accountNumberData, setAccountNumberData] = useState("");
  const [accountNameData, setAccountNameData] = useState("");
  const [getAccountsApiError, setGetAccountsApiError] = useState(null);

  const [accountNumberArray, setaccountNumberArray] = useState([]);

  const [biaRating, setBiaRating] = useState("");
  const [projectName, setProjectName] = useState("");
  const [exceptionDetail, setExceptionDetail] = useState("");
  const [exceptionTimeLine, setExceptionTimeLine] = useState("");
  const [exceptionCategory, setExceptionCategory] = useState("");
  const [accountOwnerName, setAccountOwnerName] = useState("");
  const [region, setRegion] = useState("");

  const [showCancel, setShowCancel] = useState(false);
  const [showSubmit, setshowSubmit] = useState(false);

  const [showAccountListError, setShowAccountListError] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancel(value);
  }, []);

  const closeModalSubmit = useCallback((value) => {
    setshowSubmit(value);
  }, []);

  const [formSubmitMessage, setFormSubmitMessage] = useState(null);
  const [errorMessgeSubmit, setErrorMessgeSubmit] = useState(null);

  // ID Token
  const idToken = `${userInfo.idToken}`;

  // Form Submit Variable
  const formApiUrl = `${process.env.REACT_APP_SECURITY_POSTURE_ADD}`;

  // Form Submit
  function handleFormSubmit(e) {
    e.preventDefault();
    // Validate
    const errors = toolExceptionValidate({
      accountNumber: accountNumberData,
      BIARating: biaRating,
      accountName: accountNameData,
      projectName: projectName,
      accountOwnerName: accountOwnerName,
      exceptionDetail: exceptionDetail,
      exceptionTimeline: exceptionTimeLine,
      exceptionCategory: exceptionCategory,
      region: region,
    });
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setLoadingDropdown(true);
      const currentEpochTime = Date.now();
      const formRequestBody = {
        accountnumber: `${accountNumberData}`,
        accountname: `${accountNameData}`,
        Gid: `${userInfo?.preferred_username}`,
        requestStatus: "Pending",
        submittedBy: `${userInfo?.email}`,
        uid: `u-${currentEpochTime}`,
        biarating: `${biaRating}`,
        projectname: `${projectName}`,
        securitycontact: `${accountOwnerName}`,
        exceptiondetails: `${exceptionDetail}`,
        exceptiontimeline: `${exceptionTimeLine}`,
        exceptioncategory: `${exceptionCategory}`,
        region: `${region}`,
      };

      postApiCall(formApiUrl, idToken, formRequestBody)
        .then((response) => {
          console.log("response", response);
          if (response.data?.error) {
            setFormSubmitMessage(response.data?.error);
            setErrorMessgeSubmit(true);
          } else {
            setFormSubmitMessage(null);
            setErrorMessgeSubmit(false);
          }
          setshowSubmit(true);
          setLoadingDropdown(false);
        })
        .catch((err) => {
          setFormSubmitMessage(
            "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
          );
          setshowSubmit(true);
          setLoadingDropdown(false);
        });
    }
  }

  // API Variables
  const apiUrl = `${process.env.REACT_APP_SECURITY_AWS_FETCH_ACCOUNTS}`;

  // Account Number Useeffect
  useEffect(() => {
    setGetAccountsApiError(null);
    setLoadingDropdown(true);
    setAccountNumberNameData([]);

    const requestBody = {
      email: `${userInfo?.email}`,
      user_type: `${
        userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)
          ? "admin"
          : "user"
      }`,
    };
    if (userInfo.groups.includes(CS_Hub_Security_Admin_Branch_1)) {
      requestBody.env = "DEV";
    }
    postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        if (response.data?.length) {
          setAccountNumberNameData(response.data);

          const accountNumberOptions = response.data
            .filter((f) => f !== "")
            .map((d) => ({
              value: d.split(" - ")[0],
              label: d.split(" - ")[0],
            }));

          setaccountNumberArray(accountNumberOptions);

          setLoadingDropdown(false);
        } else {
          setShowAccountListError(true);
        }
      })
      .catch((err) => {
        setLoadingDropdown(false);
        setGetAccountsApiError(
          "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
        );
      });

    return () => {
      setAccountNumberNameData([]);
    };
  }, []);
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "projectName") {
      setProjectName(value);
    } else if (name === "exceptionDetail") {
      setExceptionDetail(value);
    } else if (name === "accountOwnerName") {
      setAccountOwnerName(value);
    }
  };

  return (
    <>
      <Row>
        <Col className="mb-3">
          <h5 className="page-content-title">
            {t("AWS Tools Exception Handling")}
          </h5>
        </Col>
      </Row>
      {(loadingDropdown || getAccountsApiError) && (
        <Row>
          <Col>
            <div className="d-flex justify-content-center">
              <SpinnerLoader />
            </div>
          </Col>
        </Row>
      )}
      {loadingDropdown === false && getAccountsApiError === null && (
        <Form onSubmit={handleFormSubmit}>
          <Row>
            <Col md={6} className="mt-2">
              <Form.Group className="mb-3" controlId="selectAccountNo">
                <Form.Label>{t("Select Account For Exception")}</Form.Label>
                <Select
                  name="accountNumber"
                  placeholder="Account Number"
                  searchable={true}
                  value={{ label: accountNumberData }}
                  isClearable={true}
                  onChange={(value) => {
                    const accNum = value !== null ? value.value : "";
                    setAccountNumberData(accNum);

                    if (value !== null) {
                      for (let i = 0; i < accountNumberNameData.length; i++) {
                        let accNumName = accountNumberNameData[i];
                        let splited = accNumName.split(" - ")[0];
                        if (splited === accNum) {
                          let splitedAccName = accNumName.split(" - ")[1];
                          setAccountNameData(splitedAccName);
                        }
                      }
                    } else {
                      setAccountNameData("");
                    }
                  }}
                  options={accountNumberArray}
                  styles={dataInputError.accountNumber && customStyles}
                />

                <p className="custom-error-message">
                  {dataInputError.accountNumber}
                </p>
              </Form.Group>
            </Col>
            {/* <-- newly added field role selection --> */}

            <Col md={6} className="mt-2 ">
              <Form.Group className="mb-3" controlId="selectBIARating">
                <Form.Label>{t("BIA Rating")}</Form.Label>
                <Select
                  name="BIARating"
                  placeholder="BIA Rating"
                  searchable={true}
                  value={{ label: biaRating }}
                  isClearable={true}
                  onChange={(value) => {
                    const roleDesc = value !== null ? value.value : "";
                    setBiaRating(roleDesc);
                  }}
                  options={biaRatingOption}
                  styles={dataInputError.BIARating && customStyles}
                />

                <p className="custom-error-message">
                  {dataInputError.BIARating}
                </p>
              </Form.Group>
            </Col>

            {/* --- end ---  */}
          </Row>
          <Row>
            <Col md={6} className="mt-2">
              <Form.Group className="mb-3" controlId="selectAccountName">
                <Form.Label>{t("account_name")}</Form.Label>

                <Form.Control
                  name="accountName"
                  type="text"
                  readOnly
                  placeholder={t("account_name")}
                  value={accountNameData}
                  isInvalid={dataInputError.accountName ? true : false}
                />

                <Form.Control.Feedback type="invalid">
                  {dataInputError.accountName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <TextInput
                name="projectName"
                label={t("Project Name")}
                placeholder={t("Project Name")}
                value={projectName}
                onChange={(e) => handleChange(e)}
                isInvalid={dataInputError.projectName ? true : false}
                invalidMessage={dataInputError.projectName}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mt-2">
              <Form.Group className="mb-3" controlId="selectAccountOwnerName">
                <Form.Label>{t("Account Security Contact Email")}</Form.Label>

                <Form.Control
                  name="accountOwnerName"
                  type="text"
                  onChange={(e) => handleChange(e)}
                  placeholder={t("Account Security Contact Email")}
                  value={accountOwnerName}
                  isInvalid={dataInputError.accountOwnerName ? true : false}
                />

                <Form.Control.Feedback type="invalid">
                  {dataInputError.accountOwnerName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <TextInput
                name="exceptionDetail"
                label={t("Exception Detail")}
                placeholder={t("Exception detail")}
                value={exceptionDetail}
                onChange={(e) => handleChange(e)}
                isInvalid={dataInputError.exceptionDetail ? true : false}
                invalidMessage={dataInputError.exceptionDetail}
              />
            </Col>
            <Col md={6} className="mt-2 ">
              <Form.Group className="mb-3" controlId="selectExceptionTimeline">
                <Form.Label>{t("Exception Timeline")}</Form.Label>
                <Select
                  name="exceptionTimeline"
                  placeholder="Exception Timeline"
                  searchable={true}
                  value={{ label: exceptionTimeLine }}
                  isClearable={true}
                  onChange={(value) => {
                    const exceptionTimeLineval =
                      value !== null ? value.value : "";
                    setExceptionTimeLine(exceptionTimeLineval);
                  }}
                  options={exceptionTimeLineOption}
                  styles={dataInputError.exceptionTimeline && customStyles}
                />

                <p className="custom-error-message">
                  {dataInputError.exceptionTimeline}
                </p>
              </Form.Group>
            </Col>
            <Col md={6} className="mt-2 ">
              <Form.Group className="mb-3" controlId="selectExceptionTimeline">
                <Form.Label>{t("Exception Category")}</Form.Label>
                <Select
                  name="exceptionCategory"
                  placeholder="Exception Category"
                  searchable={true}
                  value={{ label: exceptionCategory }}
                  isClearable={true}
                  onChange={(value) => {
                    const exceptioncategoryval =
                      value !== null ? value?.value : "";
                    setExceptionCategory(exceptioncategoryval);
                  }}
                  options={exceptionCategoryOption}
                  styles={dataInputError.exceptionCategory && customStyles}
                />

                <p className="custom-error-message">
                  {dataInputError.exceptionCategory}
                </p>
              </Form.Group>
            </Col>
            <Col md={6} className="mt-2 ">
              <Form.Group className="mb-3" controlId="selectExceptionTimeline">
                <Form.Label>{t("Region")}</Form.Label>
                <Select
                  name="region"
                  placeholder="Select Region"
                  searchable={true}
                  value={{ label: region }}
                  isClearable={true}
                  onChange={(value) => {
                    const regionVal = value !== null ? value?.value : "";
                    setRegion(regionVal);
                  }}
                  options={regionData}
                  styles={dataInputError.region && customStyles}
                />

                <p className="custom-error-message">{dataInputError.region}</p>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={5} className="offset-md-7">
              <ul className="btn-ul">
                <li>
                  <button
                    type="button"
                    className="teal-bordered-btn"
                    onClick={() => {
                      setShowCancel(true);
                    }}
                  >
                    {t("cancel")}
                  </button>
                </li>
                <li>
                  <button type="submit" className="teal-filled-btn">
                    {t("submit")}
                  </button>
                </li>
              </ul>
            </Col>
          </Row>
        </Form>
      )}

      {showCancel && (
        <CustomModal
          showModal={true}
          children={<Cancel closeModalParent={closeModalCallback} />}
        />
      )}
      {showAccountListError && (
        <CustomModal
          showModal={true}
          fileUpload={true}
          children={
            <WarningMessage setShowAccountListError={setShowAccountListError} />
          }
        />
      )}

      {showSubmit && (
        <CustomModal
          showModal={true}
          children={
            <Submit
              message={formSubmitMessage}
              errorMessgeSubmit={errorMessgeSubmit}
              closeModalParent={closeModalSubmit}
            />
          }
        />
      )}
    </>
  );
};

export default AddRequest;
