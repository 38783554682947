import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { useGetStartedList } from 'static-contents/user-dashboard-getstarted';
import { useTranslation } from 'react-i18next';

const GetStarted = () => {
  const {t} = useTranslation();
  const getStartedItems = useGetStartedList();
  
  return (
    <div className='custom-card mb-3 get-started-items'>
      <span className='maintitle-text'>{t("getStarted")}</span>

      <ul className='user-dashboard-ul'>
        {getStartedItems.map((data, index) => (
          <li key={index}>
            <Row>
              <Col md={2}>
                <div>
                  <img src={data.icon} alt='' />
                </div>
              </Col>
              <Col md={10}>
                <div>
                  <a
                    href={data.link}
                    className='getstarted-title'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {data.title}
                  </a>

                  <p className='mb-0 getstarted-subtitle'>{t("freeClass")}</p>
                </div>
              </Col>
            </Row>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GetStarted;
