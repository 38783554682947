import React, { useState } from "react";
import { useSelector } from "react-redux";

import Loader from "components/Common/Loader";
import AlertMessage from "components/Common/AlertMessage";

import { postApiCall } from "helper/postApiCall";

const AccountRequestsCancel = ({
  yesParent,
  closeModalParent,
  status,
  uid,
}) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successCanceled, setSuccessCanceled] = useState(false);

  async function pendingToCanceled() {
    setLoading(true);
    const idToken = userInfo.idToken;

    const getApiUrl = `${process.env.REACT_APP_SSM_PATCHING_SUBMIT_REQUEST_URL}`;

    const setApiUrl = `${getApiUrl}?uid=${uid}&type=3&actiontakenby=${userInfo.email}`;
    await postApiCall(setApiUrl, idToken).then((response) => {
      if (response.data.status === "Success") {
        setSuccessCanceled(true);
      } else {
        setError("Failed to updated");
      }
    });
  }
  return (
    <div className="d-flex justify-content-center flex-column">
      {successCanceled === false && (
        <>
          <p>Are you sure you want to cancel this request</p>
          {error !== null && (
            <div className="d-flex justify-content-center flex-column">
              <AlertMessage
                cssClassName="alert-message-danger"
                alertMessage={error}
              />
            </div>
          )}
          {loading && <Loader />}
          {loading === false && error === null && (
            <ul className="btn-ul">
              <li>
                <button
                  type="button"
                  className="teal-bordered-btn"
                  onClick={() => {
                    closeModalParent(null);
                  }}
                >
                  No
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="teal-filled-btn"
                  onClick={() => {
                    pendingToCanceled();
                  }}
                >
                  Yes
                </button>
              </li>
            </ul>
          )}
        </>
      )}
      {successCanceled === true && (
        <>
          <p>Request Cancelled!</p>
          <p>
            <button
              type="button"
              className="teal-filled-btn"
              onClick={() => {
                yesParent(null);
              }}
            >
              Ok
            </button>
          </p>
        </>
      )}
    </div>
  );
};

export default AccountRequestsCancel;
