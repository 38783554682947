import React from 'react';

// Import Internal Components
// import AccountContact from './AccountContact';
// import BillingContact from './BillingContact';
// import SecurityContact from './SecurityContact';
// import TechnicalContact from './TechnicalContact';
import BulkEditBillingInfo from './BulkEditBillingInfo';
// import GroupEmailAddress from './GroupEmailAddress';
import BulkEditAccountDetails from './BulkEditAccountDetails';
import AccountConfirmation from './AccountConfirmation';

const BulkEditFormSteps = (step, setStep, formData, setFormData, selectedAccounts, availableData) => [
    {
        title: 'Account Confirmation',
        content: (
          <AccountConfirmation
            setStep={setStep}
            step={step}
            formData={formData}
            setFormData={setFormData}
            selectedAccounts={selectedAccounts}
            availableData={availableData}
          />
        ),
      },
  {
    title: 'Billing Info',
    content: (
      <BulkEditBillingInfo
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
      />
    ),
  },

  {
    title: 'Account details',
    content: (
      <BulkEditAccountDetails
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
        selectedAccounts={selectedAccounts}
      />
    ),
  },
];

export default BulkEditFormSteps;
