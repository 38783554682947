const notificationValidator = (values) => {
  let errors = {};

  if (!values.contactType) {
    errors.contactType = 'Contact Type is required';
  }

  if (!values.subject) {
    errors.subject = 'Subject is required';
  }

  if (!values.message) {
    errors.message = 'Message is required';
  }

  if (values.isAllAccountSelected === false) {
    if (
      values.validatedAccountName === null &&
      values.validatedAccountNumber === null &&
      values.validatedGsirt === null
    ) {
      errors.optionalValue =
        'Either account name or account number or gsirtsplunk opco index is required';
    }
  }

  return errors;
};

export { notificationValidator };
