import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

// Import Components
import RequestWidget from "./RequestWidget";
import ServiceNowCR from "./ServiceNowCR";
import Moriarty from "./Moriarty";
import RemedyTicketCount from "./RemedyTicketCount";
import Prisma from "./Prisma";
import TrendMicro from "./TrendMicro";
import AWSServiceStatus from "./AWSServiceStatus";
import AzureServiceStatus from "./AzureServiceStatus";
import Licenses from "./Licenses";
import GcpServiceStatus from "./GcpServiceStatus";

// Import Constant
import { USER_INFO } from "redux/user/userConstant";

import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";

const DashboardIndex = ({ history }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });

        if (!info?.groups?.includes(CS_Hub_AWS_Account_Request_Admin)) {
          history.push("/user-dashboard");
        }
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch]);

  return (
    <>
      {userInfo !== null &&
        authState &&
        authState.isAuthenticated &&
        userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) && (
          <Container fluid className="mb-3">
            <Row>
              <Col md={12} xl={12} className="mt-4">
                <Row>
                  <RequestWidget />
                </Row>
              </Col>
              <Col md={8} xl={8} className="mt-4">
                <Row>
                  <Col md={6}>
                    <Licenses />
                  </Col>
                  <Col md={6}>
                    {/* <Row>
                      <Col md={12}>
                        <ServiceNowCR />
                      </Col>
                    </Row> */}
                    <Row>
                      <Col md={12}>
                        <Moriarty />
                      </Col>
                    </Row>
                  </Col>

                  <Col md={6}>
                    <Prisma />
                  </Col>
                  <Col md={6}>
                    <TrendMicro />
                  </Col>
                </Row>
                {/* <Row className='mt-3'>
                  <Col md={6}>
                    <RemedyTicketCount />
                  </Col>
                </Row> */}
              </Col>
              <Col md={4} className="mt-4">
                <div className="sticky-top custom-sticky-top-position ">
                  {/* <Row>
                    <Col className='mb-2'>
                     
                    </Col>
                  </Row> */}
                  <Row>
                    <Col className="mb-2">
                      <AWSServiceStatus />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-2">
                      <AzureServiceStatus />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-2">
                      <GcpServiceStatus />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        )}
    </>
  );
};

export default DashboardIndex;
