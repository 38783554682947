import React from 'react';

const Update = ({ yesParent, status, mode }) => {
  // Redux State
  const successMsg =  (mode === 'Update') ? 'Request Updated Successfully!' : "Disassociated VPC's successfully."
  const failedMsg =  (mode === 'Update') ? 'Failed To Update!' : "Failed to remove the VPC's"

  return (
    <div className='d-flex justify-content-center flex-column'>
      {status !== null && (
        <>
          <p>{ Number(status) === 200 ? successMsg : failedMsg }</p>
          <p>
            <button
              type='button'
              className='teal-filled-btn'
              onClick={() => {
                yesParent(null);
              }}
            >
              Ok
            </button>
          </p>
        </>
      )}
    </div>
  );
};

export default Update;
