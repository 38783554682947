import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

// Roles
import {
  CS_Hub_AWS_Account_Request_Admin,
  CS_Hub_AWS_Dynamo_Search_Request_Admin,
} from 'roles/roles';

const AzureView = ({ data, userInfo }) => {
  const [currentInfo, setCurrentInfo] = useState('Account Contact');
  return (
    <>
      <Row className='mt-4'>
        <Col md={4} xs={6}>
          <button
            className={
              currentInfo === 'Account Contact'
                ? 'view-edit-box-form-title-btn-active'
                : 'view-edit-box-form-title-btn'
            }
            onClick={() => setCurrentInfo('Account Contact')}
          >
            Account Contact
          </button>
        </Col>
        <Col md={4} xs={6}>
          <button
            className={
              currentInfo === 'Billing Contact'
                ? 'view-edit-box-form-title-btn-active'
                : 'view-edit-box-form-title-btn'
            }
            onClick={() => setCurrentInfo('Billing Contact')}
          >
            Billing Contact
          </button>
        </Col>
        <Col md={4} xs={6}>
          <button
            className={
              currentInfo === 'Security Contact'
                ? 'view-edit-box-form-title-btn-active'
                : 'view-edit-box-form-title-btn'
            }
            onClick={() => setCurrentInfo('Security Contact')}
          >
            Security Contact
          </button>
        </Col>
        <Col md={4} xs={6}>
          <button
            className={
              currentInfo === 'Technical Contact'
                ? 'view-edit-box-form-title-btn-active'
                : 'view-edit-box-form-title-btn'
            }
            onClick={() => setCurrentInfo('Technical Contact')}
          >
            Technical Contact
          </button>
        </Col>

        {(userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
          userInfo.groups.includes(CS_Hub_AWS_Dynamo_Search_Request_Admin)) && (
          <Col md={4} xs={6}>
            <button
              className={
                currentInfo === 'Billing Info'
                  ? 'view-edit-box-form-title-btn-active'
                  : 'view-edit-box-form-title-btn'
              }
              onClick={() => setCurrentInfo('Billing Info')}
            >
              Billing Info
            </button>
          </Col>
        )}

        <Col md={4} xs={6}>
          <button
            className={
              currentInfo === 'Group Email Address'
                ? 'view-edit-box-form-title-btn-active'
                : 'view-edit-box-form-title-btn'
            }
            onClick={() => setCurrentInfo('Group Email Address')}
          >
            Group Email Address
          </button>
        </Col>
        <Col md={4} xs={6}>
          <button
            className={
              currentInfo === 'Account Details'
                ? 'view-edit-box-form-title-btn-active'
                : 'view-edit-box-form-title-btn'
            }
            onClick={() => setCurrentInfo('Account Details')}
          >
            Account Details
          </button>
        </Col>
      </Row>

      <Row className='mt-4'>
        <Col md={12}>
          {currentInfo === 'Account Contact' && (
            <div className='account-search-view-table-container'>
              <table width='100%'>
                <tbody className='account-search-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      First Name
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.firstNameAO}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Last Name
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.lastNameAO}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Email
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.emailAO}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Country
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.countryAO}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Phone Number
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.phoneNoAO}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {currentInfo === 'Billing Contact' && (
            <div className='account-search-view-table-container'>
              <table width='100%'>
                <tbody className='account-search-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      First Name
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.firstNameBC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Last Name
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.lastNameBC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Email
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.emailBC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Country
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.countryBC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Phone Number
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.phoneNoBC}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {currentInfo === 'Security Contact' && (
            <div className='account-search-view-table-container'>
              <table width='100%'>
                <tbody className='account-search-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      First Name
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.firstNameSC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Last Name
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.lastNameSC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Email
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.emailSC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Country
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.countrySC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Phone Number
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.phoneNoSC}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {currentInfo === 'Technical Contact' && (
            <div className='account-search-view-table-container'>
              <table width='100%'>
                <tbody className='account-search-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      First Name
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.firstNameTC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Last Name
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.lastNameTC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Email
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.emailTC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Country
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.countryTC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Phone Number
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.phoneNoTC}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {currentInfo === 'Billing Info' &&
            (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
              userInfo.groups.includes(
                CS_Hub_AWS_Dynamo_Search_Request_Admin
              )) && (
              <div className='account-search-view-table-container'>
                <table width='100%'>
                  <tbody className='account-search-view-table-tbody'>
                    <tr>
                      <td
                        width='50%'
                        className='account-search-view-table-td-key'
                      >
                        Billing Operating Company
                      </td>
                      <td
                        width='50%'
                        className='account-search-view-table-td-value'
                      >
                        {data.billingopco}
                      </td>
                    </tr>
                    <tr>
                      <td
                        width='50%'
                        className='account-search-view-table-td-key'
                      >
                        Billing Sub Operating Company
                      </td>
                      <td
                        width='50%'
                        className='account-search-view-table-td-value'
                      >
                        {data.billingSubOpco}
                      </td>
                    </tr>
                    <tr>
                      <td
                        width='50%'
                        className='account-search-view-table-td-key'
                      >
                        Billing Department
                      </td>
                      <td
                        width='50%'
                        className='account-search-view-table-td-value'
                      >
                        {data.billingDepartment}
                      </td>
                    </tr>
                    <tr>
                      <td
                        width='50%'
                        className='account-search-view-table-td-key'
                      >
                        Billing Region
                      </td>
                      <td
                        width='50%'
                        className='account-search-view-table-td-value'
                      >
                        {data.billingRegion}
                      </td>
                    </tr>
                    <tr>
                      <td
                        width='50%'
                        className='account-search-view-table-td-key'
                      >
                        MAX Monthly Expected Spend in USD
                      </td>
                      <td
                        width='50%'
                        className='account-search-view-table-td-value'
                      >
                        {data.maxSpend}
                      </td>
                    </tr>
                    <tr>
                      <td
                        width='50%'
                        className='account-search-view-table-td-key'
                      >
                        Bill To
                      </td>
                      <td
                        width='50%'
                        className='account-search-view-table-td-value'
                      >
                        {data.billto}
                      </td>
                    </tr>
                    {data.billto === 'Global IS (GISC)' && (
                      <tr>
                        <td
                          width='50%'
                          className='account-search-view-table-td-key'
                        >
                          IO
                        </td>
                        <td
                          width='50%'
                          className='account-search-view-table-td-value'
                        >
                          {data.io}
                        </td>
                      </tr>
                    )}
                    {data.billto === 'Other Teams' && (
                      <tr>
                        <td
                          width='50%'
                          className='account-search-view-table-td-key'
                        >
                          Cost Center
                        </td>
                        <td
                          width='50%'
                          className='account-search-view-table-td-value'
                        >
                          {data.costcenter}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}

          {currentInfo === 'Group Email Address' && (
            <div className='account-search-view-table-container'>
              <table width='100%'>
                <tbody className='account-search-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Technical Group:
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.technicalGroup}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Security Group:
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.securityGroup}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      Billing Group: 
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.billingGroup}
                    </td>
                  </tr>
                </tbody>
              </table>
              <Row>
                <Col md={12} className='mt-3 mb-3'>
                  <div className='account-search-view-title-container'>
                    <p className='account-search-view-title'>
                      Additional Details
                    </p>
                  </div>
                </Col>
              </Row>
              <div className='account-search-view-table-container'>
                <table width='100%'>
                  <tbody className='account-search-view-table-tbody'>
                    <tr>
                      <td
                        width='50%'
                        className='account-search-view-table-td-key'
                      >
                        Avail Golden AMI:
                      </td>
                      <td
                        width='50%'
                        className='account-search-view-table-td-value'
                      >
                        {(data.pgcsgoldenami?.toLowerCase() == "no" || data.pgcsgoldenami?.toLowerCase() == "false") ? "No" : "Yes" }
                      </td>
                    </tr>
                    <tr>
                      <td
                        width='50%'
                        className='account-search-view-table-td-key'
                      >
                        Avail Trend Micro:
                      </td>
                      <td
                        width='50%'
                        className='account-search-view-table-td-value'
                      >
                        {(data.ptm?.toLowerCase() == "no" || data.ptm?.toLowerCase() == "false") ? "No" : "Yes" }
                      </td>
                    </tr>
                    <tr>
                      <td
                        width='50%'
                        className='account-search-view-table-td-key'
                      >
                        Avail Conformity Tool:
                      </td>
                      <td
                        width='50%'
                        className='account-search-view-table-td-value'
                      >
                        {(data.conformity?.toLowerCase() == "no" || data.conformity?.toLowerCase() == "false") ? "No" : "Yes" }
                      </td>
                    </tr>
                    <tr>
                      <td
                        width='50%'
                        className='account-search-view-table-td-key'
                      >
                        Avail Managed AD:
                      </td>
                      <td
                        width='50%'
                        className='account-search-view-table-td-value'
                      >
                        {(data.sonyad?.toLowerCase() == "no" || data.sonyad?.toLowerCase() == "false") ? "No" : "Yes" }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {currentInfo === 'Account Details' && (
            <div className='account-search-view-table-container'>
              <table width='100%'>
                <tbody className='account-search-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      *Account Name
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.accountname}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      *Account Description
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.accountdescription}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      *Application Name
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.purposeid}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      *Operating Company
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.opco}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      *Sub Operating Company
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.subopco}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      *Department
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.department}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      *Environment
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.environmenttype}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      *Asset User Base
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.assetUserBase}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-search-view-table-td-key'
                    >
                      *Data Classification
                    </td>
                    <td
                      width='50%'
                      className='account-search-view-table-td-value'
                    >
                      {data.dataClassification}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default AzureView;
