import { numberWithCommas } from "api/utilities";
import Axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cloudability from "../../images/cloudability-logo.png";

export default function BillingDetails({ selAccountLists, cloud }) {
  const selAccounts = useMemo(
    () => selAccountLists?.map((acc) => acc.DropdownValue),
    [selAccountLists]
  );

  const { t } = useTranslation();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const [apiLoading, setApiLoading] = useState(false);
  const [costDetails, setCostDetails] = useState({});

  useEffect(() => {
    setApiLoading(true);
    const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: setIdToken,
      },
    };

    const requestBody = {
      cloud,
      accounts: selAccounts,
    };

    Axios.post(
      `${process.env.REACT_APP_BILLING_COST_DETAILS}`,
      requestBody,
      config
    )
      .then((response) => {
        setCostDetails(response.data);
      })
      .finally(() => setApiLoading(false));

    return () => {
      setCostDetails({});
    };
  }, [selAccounts, cloud]);

  return (
    <Row className="my-3">
      <Col md={4} xs={12}>
        <div className="custom-card h-100">
          <div className="d-flex report-widget-wrapper h-100 justify-content-between">
            <div>
              <h5 className="dashboard-title mb-3">Last Invoice Charges</h5>
            </div>
            <div className="text-muted mt-2 report-widget-logo-value-wrapper justify-content-start">
              <h4 className="report-widget-value-font mb-0">
                ${numberWithCommas(costDetails.costtilldate || 0)}
              </h4>
            </div>
          </div>
        </div>
      </Col>
      <Col md={4} xs={12}>
        <div className="custom-card h-100">
          <div className="d-flex report-widget-wrapper h-100 justify-content-between">
            <div>
              <h5 className="dashboard-title mb-3">Unblended Cost Till Date</h5>
            </div>
            <div className="text-muted mt-2 report-widget-logo-value-wrapper justify-content-start">
              <h4 className="report-widget-value-font mb-0">
                ${numberWithCommas(costDetails.latestcost || 0)}
              </h4>
            </div>
          </div>
        </div>
      </Col>
      <Col md={4} xs={12}>
        <div className="custom-card">
          <div className="d-flex report-widget-wrapper">
            <h5 className="dashboard-title mb-1">
              Detailed Resource Cost Report
            </h5>
            <div>
              <img src={cloudability} alt="cloudability" />
              <br />
              <a
                href="https://status.aws.amazon.com/"
                className="service-status-link fw-bold"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("clickHereToViewMore")}
              </a>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
