import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Cancel = ({ closeModalParent }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-center flex-column">
      <p>{t("data_loss_warning")}</p>
      <p>{t("sure_to_continue")}</p>
      <ul className="btn-ul">
        <li>
          <button
            type="button"
            className="teal-bordered-btn"
            onClick={() => {
              closeModalParent(false);
            }}
          >
            {t("no")}
          </button>
        </li>
        <li>
          <Link to="/tool-exception-azure-view">
            <button type="button" className="teal-filled-btn">
              {t("yes")}
            </button>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Cancel;
