export const filterPatternValidator = ({ filterPatternText, instanceId }) => {
  let errors = {};

  if (!filterPatternText) {
    errors.filterPatternText = 'Filter Pattern is required';
  }

  if (!instanceId) {
    errors.instanceId = 'Instance is required';
  }

  return errors;
};

// Set Alarm Validator

export const logsSetAlarmValidator = ({
  filterPattern,
  filterName,
  metricName,
  metricValue,
  unit,
  defaultValue,
  alarmDescription,
  statistic,
  period,
  evaluationPeriod,
  condition,
  threshold,
  resource,
  tagValue,
  dimensionsFisrt,
  dimensionsSecond,
  dimensionsThird,
}) => {
  let errors = {};

  if (!filterPattern) {
    errors.filterPattern = 'Filter Pattern is required';
  }

  if (!filterName) {
    errors.filterName = 'Filter name is required';
  } else if (/^[a-zA-Z0-9]*$/.test(filterName) === false) {
    errors.filterName = 'Only alphabets & digits allowed';
  }

  if (!metricName) {
    errors.metricName = 'Metric name is required';
  } else if (/^[a-zA-Z0-9]*$/.test(metricName) === false) {
    errors.metricName = 'Only alphabets & digits allowed';
  }

  if (!metricValue) {
    errors.metricValue = 'Metric value is required';
  } else if (/^[a-zA-Z0-9]*$/.test(metricValue) === false) {
    errors.metricValue = 'Only alphabets & digits allowed';
  }

  if (/^[a-zA-Z0-9]*$/.test(unit) === false) {
    errors.unit = 'Only alphabets & digits allowed';
  }

  if (/^[a-zA-Z0-9]*$/.test(defaultValue) === false) {
    errors.defaultValue = 'Only alphabets & digits allowed';
  }

  if (!alarmDescription) {
    errors.alarmDescription = 'Alarm Description is required';
  }

  if (!statistic) {
    errors.statistic = 'Statistic is required';
  }

  if (!period) {
    errors.period = 'Period is required';
  }

  let isnum = /^\d+$/.test(evaluationPeriod);
  if (!evaluationPeriod) {
    errors.evaluationPeriod = 'Evaluation Period is required';
  } else if (isnum === false) {
    errors.evaluationPeriod = 'Only digits is allowed';
  }

  if (!condition) {
    errors.condition = 'Condition is required';
  }

  let isThresholdnum = /^\d+$/.test(threshold);
  if (!threshold) {
    errors.threshold = 'Threshold is required';
  } else if (isThresholdnum === false) {
    errors.threshold = 'Only digits is allowed';
  }

  if (!resource) {
    errors.resource = 'Resource is required';
  }

  if (!dimensionsFisrt.key) {
    errors.dimensionFirstKey = 'Dimension Key is required';
  }
  if (!dimensionsFisrt.value) {
    errors.dimensionFirstValue = 'Dimension value is required';
  }

  if (tagValue > 1) {
    if (!dimensionsSecond.key) {
      errors.dimensionSecondKey = 'Dimension Key is required';
    }
    if (!dimensionsSecond.value) {
      errors.dimensionSecondValue = 'Dimension value is required';
    }
  }

  if (tagValue > 2) {
    if (!dimensionsThird.key) {
      errors.dimensionThirdKey = 'Dimension Key is required';
    }
    if (!dimensionsThird.value) {
      errors.dimensionThirdValue = 'Dimension value is required';
    }
  }

  return errors;
};
