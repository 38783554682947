import React from 'react';

// Import Image
import azureLogo from 'images/azure-logo.svg';
import { useTranslation } from 'react-i18next';

const AzureServiceStatus = () => {
  const {t}=useTranslation();
  return (
    <div className='custom-card'>
      <h5 className='subtitle-text mb-3'>{t("azureServiceStatus")}</h5>
      <div>
        <img src={azureLogo} alt='Azure' className='service-status-img' />
        <a
          href='https://status.azure.com/en-us/status'
          className='service-status-link'
          target='_blank'
          rel='noopener noreferrer'
        >
          {t("clickHereToViewMore")}
        </a>
        <p className='service-status-description'>
          {t("checkBackTextAzure")}
        </p>
      </div>
    </div>
  );
};

export default AzureServiceStatus;
