import awsIcon from 'icons/sub-menu-icons/awscolor.svg';
import azureIcon from 'icons/sub-menu-icons/azurecolor.svg';
import { useTranslation } from 'react-i18next';

export function useGetStartedList(){
  const {t} = useTranslation();
  return t("getstarted_items",{awsIcon: awsIcon, azureIcon: azureIcon});
}

const getstarted = [

  {
    icon: awsIcon,
    title: 'AWS Cloud Practitioner Essentials Day | October 7',
    link: 'https://urldefense.com/v3/__https:/pages.awscloud.com/TRAINCERT-APAC-alwayson-OE-CPE-20221007-reg-event.html?trk=ae61b116-d3a8-4e82-b876-740b231cd07d__;!!JmoZiZGBv3RvKRSx!67W2JLlL8aSyeGDqgTRfCFV6d_PO_tXOK6tpLgP1kqSE8MvEcZPeYfjRnpe2NVnCJ7TumLe9gQQARU22SRM$',
  },

  {
    icon: awsIcon,
    title: 'AWS Cloud Practitioner Essentials Day | October 31',
    link: 'https://urldefense.com/v3/__https:/pages.awscloud.com/TRAINCERT-APAC-alwayson-OE-CPE-20221031-reg-event.html?trk=ae61b116-d3a8-4e82-b876-740b231cd07d__;!!JmoZiZGBv3RvKRSx!67W2JLlL8aSyeGDqgTRfCFV6d_PO_tXOK6tpLgP1kqSE8MvEcZPeYfjRnpe2NVnCJ7TumLe9gQQAonxhz7o$'  },




  {
    icon: awsIcon,
    title: 'Machine Learning Basics| October 7',
    link: 'https://urldefense.com/v3/__https:/pages.awscloud.com/TRAINCERT-APAC-alwayson-OE-ML-20221007-reg-event.html?trk=ae61b116-d3a8-4e82-b876-740b231cd07d__;!!JmoZiZGBv3RvKRSx!67W2JLlL8aSyeGDqgTRfCFV6d_PO_tXOK6tpLgP1kqSE8MvEcZPeYfjRnpe2NVnCJ7TumLe9gQQAa5AyWqI$',
  },



    {
      icon: awsIcon,
      title: 'Modern Applications on AWS | October 17',
      link: 'https://urldefense.com/v3/__https:/pages.awscloud.com/TRAINCERT-APAC-alwayson-OE-ModernApps-20221017-reg-event.html?trk=ae61b116-d3a8-4e82-b876-740b231cd07d__;!!JmoZiZGBv3RvKRSx!67W2JLlL8aSyeGDqgTRfCFV6d_PO_tXOK6tpLgP1kqSE8MvEcZPeYfjRnpe2NVnCJ7TumLe9gQQAD11Rkhg$'

    },

    {
      icon: awsIcon,
      title: 'Modern Applications on AWS | October 28',
      link: 'https://urldefense.com/v3/__https:/pages.awscloud.com/TRAINCERT-APAC-alwayson-OE-ModernApps-20221028-reg-event.html?trk=ae61b116-d3a8-4e82-b876-740b231cd07d__;!!JmoZiZGBv3RvKRSx!67W2JLlL8aSyeGDqgTRfCFV6d_PO_tXOK6tpLgP1kqSE8MvEcZPeYfjRnpe2NVnCJ7TumLe9gQQAjAimAzk$'

    },

    {
      icon: awsIcon,
      title: 'Machine Learning Basics | October 31 ',
      link: 'https://urldefense.com/v3/__https:/pages.awscloud.com/TRAINCERT-APAC-alwayson-OE-ML-20221031-reg-event.html?trk=ae61b116-d3a8-4e82-b876-740b231cd07d__;!!JmoZiZGBv3RvKRSx!67W2JLlL8aSyeGDqgTRfCFV6d_PO_tXOK6tpLgP1kqSE8MvEcZPeYfjRnpe2NVnCJ7TumLe9gQQADQR5FWM$'

    },

    {
      icon: awsIcon,
      title: 'Data Analytics Fundamentals on AWS | October 31',
      link: 'https://urldefense.com/v3/__https:/pages.awscloud.com/TRAINCERT-APAC-alwayson-OE-datanalaytics-20221031-reg-event.html?trk=ae61b116-d3a8-4e82-b876-740b231cd07d__;!!JmoZiZGBv3RvKRSx!67W2JLlL8aSyeGDqgTRfCFV6d_PO_tXOK6tpLgP1kqSE8MvEcZPeYfjRnpe2NVnCJ7TumLe9gQQAkDWC_EE$'

    },

    {
      icon: awsIcon,
      title: 'Exam Readiness: Solutions Architect - Associate| October 7',
      link: 'https://urldefense.com/v3/__https:/pages.awscloud.com/TRAINCERT-APAC-alwayson-OE-Get-Certified-SAA-20221007-reg-event.html?trk=ae61b116-d3a8-4e82-b876-740b231cd07d__;!!JmoZiZGBv3RvKRSx!67W2JLlL8aSyeGDqgTRfCFV6d_PO_tXOK6tpLgP1kqSE8MvEcZPeYfjRnpe2NVnCJ7TumLe9gQQAkwqiM9w$'

    },
    {
      icon: awsIcon,
      title: 'Exam Readiness: Solutions Architect - Associate | October 10',
      link: 'https://urldefense.com/v3/__https:/pages.awscloud.com/TRAINCERT-APAC-alwayson-OE-Get-Certified-SAA-20221010-reg-event.html?trk=ae61b116-d3a8-4e82-b876-740b231cd07d__;!!JmoZiZGBv3RvKRSx!67W2JLlL8aSyeGDqgTRfCFV6d_PO_tXOK6tpLgP1kqSE8MvEcZPeYfjRnpe2NVnCJ7TumLe9gQQAO0vx70A$'

    },
    {
      icon: awsIcon,
      title: 'Exam Readiness - AWS Certified SysOps Administrator - Associate  | October 10',
      link: 'https://urldefense.com/v3/__https:/pages.awscloud.com/TRAINCERT-APAC-alwayson-OE-Get-Certified-Sysops-20221010.html?trk=ae61b116-d3a8-4e82-b876-740b231cd07d__;!!JmoZiZGBv3RvKRSx!67W2JLlL8aSyeGDqgTRfCFV6d_PO_tXOK6tpLgP1kqSE8MvEcZPeYfjRnpe2NVnCJ7TumLe9gQQAe-GXCac$'

    },
    {
      icon: awsIcon,
      title: 'Exam Readiness:Cloud Practitioner | October 10',
      link: 'https://urldefense.com/v3/__https:/pages.awscloud.com/TRAINCERT-APAC-alwayson-OE-Get-Certified-CP-20221010-reg-event.html?trk=ae61b116-d3a8-4e82-b876-740b231cd07d__;!!JmoZiZGBv3RvKRSx!67W2JLlL8aSyeGDqgTRfCFV6d_PO_tXOK6tpLgP1kqSE8MvEcZPeYfjRnpe2NVnCJ7TumLe9gQQA83GQvCk$'

    },
    {
      icon: awsIcon,
      title: 'Exam Readiness:Developer - Associate| October 17',
      link: 'https://urldefense.com/v3/__https:/pages.awscloud.com/TRAINCERT-APAC-alwayson-OE-Get-Certified-DVA-IN-20221017-reg-event.html?trk=ae61b116-d3a8-4e82-b876-740b231cd07d__;!!JmoZiZGBv3RvKRSx!67W2JLlL8aSyeGDqgTRfCFV6d_PO_tXOK6tpLgP1kqSE8MvEcZPeYfjRnpe2NVnCJ7TumLe9gQQA4oSffkY$'

    },
    {
      icon: awsIcon,
      title: 'Exam Readiness:Developer - Associate| October 28',
      link: 'https://urldefense.com/v3/__https:/pages.awscloud.com/TRAINCERT-APAC-alwayson-OE-Get-Certified-DVA-IN-20221028-reg-event.html?trk=ae61b116-d3a8-4e82-b876-740b231cd07d__;!!JmoZiZGBv3RvKRSx!67W2JLlL8aSyeGDqgTRfCFV6d_PO_tXOK6tpLgP1kqSE8MvEcZPeYfjRnpe2NVnCJ7TumLe9gQQAf-yagQU$'

    },
    {
      icon: awsIcon,
      title: 'Exam Readiness: Cloud Practitioner| October 31',
      link: 'https://urldefense.com/v3/__https:/pages.awscloud.com/TRAINCERT-APAC-alwayson-OE-Get-Certified-CP-20221031-reg-event.html?trk=ae61b116-d3a8-4e82-b876-740b231cd07d__;!!JmoZiZGBv3RvKRSx!67W2JLlL8aSyeGDqgTRfCFV6d_PO_tXOK6tpLgP1kqSE8MvEcZPeYfjRnpe2NVnCJ7TumLe9gQQA298DJz4$'

    },
    
      
  {
    icon: azureIcon,
    title: 'Azure Basics Training',
    link: 'https://docs.microsoft.com/en-us/learn/azure/',
  },
  {
    icon: azureIcon,
    title: 'Azure Developer Videos',
    link: 'https://azure.microsoft.com/en-us/resources/videos/',
  },
  // {
  //   icon: azureIcon,
  //   title: 'Azure Fundamentals',
  //   link:
  //     'https://docs.microsoft.com/en-us/learn/paths/az-900-describe-cloud-concepts/',
  // },

  // {
  //   icon: awsIcon,
  //   title: 'AWS Deep Learning Containers',
  //   link: 'https://www.youtube.com/watch?v=qAFUQwTFnkY',
  // },
  // {
  //   icon: awsIcon,
  //   title: 'Integrate External IdPs with AWS Grafana',
  //   link: 'https://www.youtube.com/watch?v=t0NtkHtYeZ4',
  // },
  // {
  //   icon: awsIcon,
  //   title: 'Query Data Lake from Amazon Redshift',
  //   link: 'https://www.youtube.com/watch?v=-pyy0qNmEKo',
  // },
];

export { getstarted };
