import React, { useState, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';
import CustomModal from 'components/Modal/CustomModal';

import NewRequestSubmit from './NewRequestSubmit';
import NewRequestCancel from './NewRequestCancel';

// Helper
import { postApiCall } from 'helper/postApiCall';
import { requestTrainingValidator } from './validator';

// Static Data
import { cloudServiceData } from './cloudServiceData';


const NewRequest = () => {
  const {t}=useTranslation();
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // Form State
  const [cloudType, setCloudType] = useState('');
  const [cloudService, setCloudService] = useState('');

  const [formSubmitModal, setFormSubmitModal] = useState(false);

  // Data Input Error
  const [dataInputError, setDataInputError] = useState({});

  // API State
  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState(null);

  // API Variable
  const idToken = userInfo.idToken;
  const apiUrl = `${process.env.REACT_APP_REQUEST_TRAINING_ADD}`;

  // Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // Form Submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errors = requestTrainingValidator({
      cloudType,
      cloudService,
    });
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      const currentEpochTime = Date.now();
      const requestBody = {
        type: cloudType,
        service: cloudService,
        uid: `u-${currentEpochTime}`,
        gid: `${userInfo.preferred_username}`,
        submittedBy: `${userInfo.email}`,
        createdOn: `${currentEpochTime}`,
      };

      setLoading(true);
      postApiCall(apiUrl, idToken, requestBody)
        .then((response) => {
          setFormSubmitModal(true);
        })
        .catch((err) => {
          seterror(
            t("failed_to_retrieve_data")
          );
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Row>
        <Col md={12} className='mt-3 mb-3'>
          <span className='subtitle-text'>{t("request_for_cloud_training")}</span>
        </Col>
      </Row>
      {loading && <SpinnerLoader />}

      {error !== null && (
        <Row>
          <Col className='mt-2'>
            <DangerAlert message={error} />
          </Col>
        </Row>
      )}

      {loading === false && (
        <Form onSubmit={handleFormSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className='mb-3' controlId='selectRegion'>
                <Form.Label>*{t("select_cloud_type")}</Form.Label>
                <Form.Select
                  value={cloudType}
                  onChange={(e) => setCloudType(e.target.value)}
                  isInvalid={dataInputError.cloudType ? true : false}
                >
                  <option value=''>{t("select_cloud_type")}</option>

                  <option value='AWS'>AWS</option>
                  <option value='Azure'>Azure</option>
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  {dataInputError.cloudType}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className='mb-3' controlId='selectRegion'>
                <Form.Label>*{t("select_cloud_service")}</Form.Label>
                <Form.Select
                  value={cloudService}
                  onChange={(e) => setCloudService(e.target.value)}
                  isInvalid={dataInputError.cloudService ? true : false}
                >
                  <option value=''>{t("select_cloud_service")}</option>

                  {cloudServiceData
                    .filter((ele) => ele.type === cloudType)
                    .map((data, index) => (
                      <option key={index} value={data.service}>
                        {data.service}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  {dataInputError.cloudService}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='d-flex justify-content-start'>
              <ul className='px-0 btn-ul'>
                <li>
                  <button
                    type='button'
                    className='teal-bordered-btn'
                    onClick={() => {
                      setShowCancelModal(true);
                    }}
                  >
                    {t("cancel")}
                  </button>
                </li>
                <li>
                  <button type='submit' className='teal-filled-btn'>
                    {t("submit")}
                  </button>
                </li>
              </ul>
            </Col>
          </Row>
        </Form>
      )}

      {formSubmitModal && (
        <CustomModal showModal={true} children={<NewRequestSubmit />} />
      )}
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<NewRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

export default NewRequest;
