import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Form } from 'react-bootstrap';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

// Helper
import { postApiCall } from 'helper/postApiCall';

// Components
import SpinnerLoader from 'components/Common/SpinnerLoader';

const LineChart = ({
  data,
  application,
  instanceId,
  objectKeyData,
  chartTitle,
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const isMounted = useRef(false);

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // API State
  const [apiLoading, setApiLoading] = useState(true);
  const [apiError, setApiError] = useState(null);

  // Component State
  const [dateValue, setDateValue] = useState('1');
  const [chartData, setChartData] = useState({});

  // API Variables
  const apiUrl = `${process.env.REACT_APP_CLOUD_EYE_RDS_METRICS}`;
  const idToken = `${userInfo.idToken}`;
  let requestBody = {
    application: application,
    instid: instanceId,
    days: dateValue,
  };

  // Chart Options
  var chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
      },
    },
  };

  // Date Dependency Load
  useEffect(() => {
    if (isMounted.current) {
      let tempResponseLabelArray = [];
      let tempResponseValueArray = [];
      setApiLoading(true);
      postApiCall(apiUrl, idToken, requestBody)
        .then((response) => {
          let responseValue = response.data[objectKeyData];
          for (const property in responseValue) {
            tempResponseValueArray.push(responseValue[property]);
            tempResponseLabelArray.push(property);
          }
          setChartData({
            labels: tempResponseLabelArray,
            datasets: [
              {
                data: tempResponseValueArray,
                borderColor: 'rgb(58, 196, 170)',
                backgroundColor: 'rgba(221, 243, 238, 1)',
                lineTension: 0.5,
                fill: true,
              },
            ],
          });
          setApiLoading(false);
        })
        .catch((err) => {
          setApiError(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setApiLoading(false);
        });
    }
    // return () => {}
  }, [dateValue]);

  // First Load
  useEffect(() => {
    let tempLabelArray = [];
    let tempValueArray = [];
    for (const property in data[objectKeyData]) {
      tempValueArray.push(data[objectKeyData][property]);
      tempLabelArray.push(property);
    }

    setChartData({
      labels: tempLabelArray,
      datasets: [
        {
          data: tempValueArray,
          borderColor: 'rgb(58, 196, 170)',
          backgroundColor: 'rgba(221, 243, 238, 1)',
          lineTension: 0.5,
          fill: true,
        },
      ],
    });
    setApiLoading(false);

    isMounted.current = true;
    return () => {
      // dataChart.destroy();
      // setDateValue('1');
    };
  }, []);

  return (
    <Row>
      <Col md={8}>
        <div className='metrics-chart-title'>{chartTitle}</div>
      </Col>
      {apiLoading === false && (
        <Col md={4}>
          <Form.Select
            className='mb-2'
            size='sm'
            value={dateValue}
            onChange={(e) => {
              setDateValue(e.target.value);
            }}
          >
            <option value='1'>Last 1 Day</option>
            <option value='7'>Last 7 Days</option>
            <option value='30'>Last 30 Days</option>
            <option value='60'>Last 60 Days</option>
          </Form.Select>
        </Col>
      )}
      {apiLoading && (
        <Col md={12} className='mt-3'>
          <SpinnerLoader />
        </Col>
      )}
      {apiLoading === false && (
        <Col md={12}>
          <Line
            options={chartOptions}
            data={chartData}
            style={{ height: '350px', width: '100%' }}
          />
        </Col>
      )}
    </Row>
  );
};

export default LineChart;
