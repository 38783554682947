import React from 'react';
import { Link } from 'react-router-dom';

const Submit = ({ message }) => {
  return (
    <div className='d-flex justify-content-center flex-column'>
      {message !== null && <p>{message}</p>}
      {message === null && (
        <p>
          Request for Custom Okta Role Creation has been Submitted Successfully!
        </p>
      )}
      <ul className='btn-ul'>
        <li>
          <Link to='/network-hub-view'>
            <button type='button' className='teal-filled-btn'>
              Ok
            </button>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Submit;
