import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

// Import Internal Components
import OktaSidebar from "../../OktaSidebar";
import AddRequest from "./AddRequest";

// Import Constant
import { USER_INFO } from "redux/user/userConstant";
import {
  CS_Hub_AWS_Account_Request_Admin,
  CS_Hub_AWS_Dynamo_Search_Request_Admin,
  CS_Hub_Security_Admin_Branch_2,
} from "roles/roles";
import NotFound from "pages/NotFound";

const AddRequestIndex = () => {
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch]);

  const userAccess =
    userInfo?.groups?.includes(CS_Hub_Security_Admin_Branch_2) ||
    userInfo?.groups?.includes(CS_Hub_AWS_Dynamo_Search_Request_Admin) ||
    userInfo?.groups?.includes(CS_Hub_AWS_Account_Request_Admin)
      ? true
      : false;

  return (
    <>
      {userInfo !== null &&
        authState &&
        authState.isAuthenticated &&
        (userAccess ? (
          <>
            <Container fluid>
              <Row>
                <Col md={2}>
                  <OktaSidebar />
                </Col>
                <Col md={10} className="mt-4">
                  <AddRequest />
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <NotFound notAuthorised />
        ))}
    </>
  );
};

export default AddRequestIndex;
