import React from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

// Import Icons
import backicon from 'icons/back.svg';

const ViewBox = ({ data, closeViewParent }) => {
  return (
    <div className='view-edit-box-conatiner pb-5'>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => closeViewParent(false)}
          />
          <div>
            <h5 className='view-edit-box-title'>Network Hub</h5>
            {(data.requestStatus === 'Approved' ||
              data.requestStatus === 'Cancelled' ||
              data.requestStatus === 'Declined') && (
              <Row>
                <Col md={12}>
                  <div className='d-flex justify-content-center'>
                    <span className='account-request-view-request-message'>
                      {data.RequestActionTakenBy &&
                        `Request ${data.requestStatus} by
                      ${data.RequestActionTakenBy}`}
                    </span>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
      <div className='table-view-box-content mt-4 mb-5'>
        <Row>
          <Col md={12} className='mt-3'>
            <div className='account-request-view-table-container'>
              <table width='100%'>
                <tbody className='account-request-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Request ID
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-uppercase'
                    >
                      {data.uid}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className='account-request-view-title-container'>
              <p className='account-request-view-title'>
                Source AWS Account Details
              </p>
            </div>
          </Col>
          <Col md={12} className='mt-3'>
            <div className='account-request-view-table-container'>
              <table width='100%'>
                <tbody className='account-request-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Source Account Number
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-uppercase'
                    >
                      {data.sourceAccountnumber}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Source CIDR
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-uppercase'
                    >
                      {data.sourceCIDR}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Source Region
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.sourceRegion}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Source Transit Gateway Attachment ID
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.sourceTGA}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className='account-request-view-title-container'>
              <p className='account-request-view-title'>
                Destination AWS Account Details
              </p>
            </div>
          </Col>
          <Col md={12} className='mt-3'>
            <div className='account-request-view-table-container'>
              <table width='100%'>
                <tbody className='account-request-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Destination Account Number
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.destinationAccountnumber}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Destination CIDR
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.destinationCIDR}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Destination Region
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.destinationRegion}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Source Transit Gateway Attachment ID
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.destinationTGA}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ViewBox;
