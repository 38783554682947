import {
  SELECTED_MONTH,
  SORTING_BOOLEAN,
  TABLE_REFRESH,
  SHOW_MANDATORY,
  DUPLICATED_DATA,
  AWS_CREDITS_SHOW_MANDATORY,
  GSID_SHOW_MANDATORY,
  MOD_SHOW_MANDATORY,
  PAM_SHOW_MANDATORY,
  BILLING_SCREEN_DATE,
} from "./EditableTableConstant";

export const editableTableReducer = (
  state = {
    selectedMonth: 0,
    sortingBoolean: {
      columnId: null,
      bool: undefined,
    },
    isTableRefresh: false,
    showMandatory: {
      accountId: null,
      gsidTag: null,
      ioCode: null,
      opsCost: null,
    },
    duplicatedData: false,
    awsCreditsShowMandatory: {
      accountId: null,
      description: null,
      totalCredits: null,
      accontName: null,
      remainder: null,
    },
    gsidShowMandatory: {
      gsid: null,
      io: null,
      opco: null,
      subOpco: null,
      department: null,
      region: null,
      firstName: null,
      lastName: null,
      email: null,
    },
    modShowMandatory: {
      gsid: null,
      account: null,
      hostingModification: null,
      postModification: null,
      reason: null,
    },
    pamShowMandatory: {
      awsAccountNo: null,
      countOfServerName: null,
      sumOfUnitCost: null,
    },
    billingScreenDate: null,
  },
  action
) => {
  switch (action.type) {
    case SELECTED_MONTH:
      return { ...state, selectedMonth: action.payload };
    case SORTING_BOOLEAN:
      return { ...state, sortingBoolean: action.payload };
    case TABLE_REFRESH:
      return { ...state, isTableRefresh: action.payload };
    case SHOW_MANDATORY:
      return { ...state, showMandatory: action.payload };
    case DUPLICATED_DATA:
      return { ...state, duplicatedData: action.payload };
    case AWS_CREDITS_SHOW_MANDATORY:
      return { ...state, awsCreditsShowMandatory: action.payload };
    case GSID_SHOW_MANDATORY:
      return { ...state, gsidShowMandatory: action.payload };
    case MOD_SHOW_MANDATORY:
      return { ...state, modShowMandatory: action.payload };
    case PAM_SHOW_MANDATORY:
      return { ...state, pamShowMandatory: action.payload };
    case BILLING_SCREEN_DATE:
      return { ...state, billingScreenDate: action.payload };
    default:
      return state;
  }
};
