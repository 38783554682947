import React from 'react';
import { Row, Col } from 'react-bootstrap';

const CardDetail = ({ data }) => {
  return (
    <Row>
      <Col md={12} className='card-detail-wrapper'>
        {data['NetworkIn'] && (
          <div className='mb-2'>
            <div>
              <div className='card-title'>Network In</div>
              <div className='card-values'>
                {Object.keys(data['NetworkIn']).pop()}
              </div>
              <div className='card-values'>
                {parseInt(Object.values(data['NetworkIn']).pop()).toFixed(2)}B
              </div>
            </div>
          </div>
        )}
        {data['NetworkOut'] && <div className='card-center-border'></div>}
        {data['NetworkOut'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Network Out</div>
                <div className='card-values'>
                  {Object.keys(data['NetworkOut']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(Object.values(data['NetworkOut']).pop()).toFixed(2)}
                  B
                </div>
              </div>
            </div>
          </>
        )}
      </Col>
      <Col md={12} className='d-flex justify-content-end mt-3'>
        <div className='regular-text utc-note'>*All timings are in UTC</div>
      </Col>
    </Row>
  );
};

export default CardDetail;
