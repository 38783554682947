export const templateValiadtor = ({ templateName, templateKey, template }) => {
  let errors = {};

  if (!templateName) {
    errors.templateName = 'Template Name is required';
  }

  if (!templateKey) {
    errors.templateKey = 'Template Key is required';
  }

  if (!template) {
    errors.template = 'Template is required';
  }

  return errors;
};
