import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

// Icon
import snoozeIcon from 'icons/snooze-icon.svg';

// Helper
import { postApiCall } from 'helper/postApiCall';

// Internal
import MenuTabs from './MenuTabs';
import QuickOverView from './QuickOverView';
import ApplicationHealth from './ApplicationHealth';
import WebserviceDatatable from './WebserviceDatatable';
import ServiceDatatable from './ServiceDatatable';
import ApplicationDatatable from './ApplicationDatatable';
import ResourceHealthDatatable from './ResourceHealthDatatable';

import SnoozeAlarm from './SnoozeAlarm';

// Cloud & Infrastructure Datatable
import EC2Datatable from './EC2Datatable';
import EFSDatatable from './EFSDatatable';
import LBDatatable from './LBDatatable';
import RDSDatatable from './RDSDatatable';
import LambdaDatatable from './LambdaDatatable';
import ApiGatewayDatatable from './ApiGatewayDatatable';
import DynamoDBDatatable from './DynamoDBDatatable';

// Icons
import amazonec2icon from 'icons/aws-ec2-icon.svg';
import amazonefsicon from 'icons/amazon-efs-icon.svg';
import amazonlbicon from 'icons/aws-elastic-load-balancing-icon.svg';
import amazonrdsicon from 'icons/aws-rds-icon.svg';
import amazonlambda from 'icons/aws-lambda-icon.svg';
import amazonapigateway from 'icons/aws-api-gateway-icon.svg';
import amazondynamodb from 'icons/aws-dynamodb-icon.svg';

import {
  CLOUD_EYE_USER_TABLE,
  CLOUD_EYE_APPLICATION_NAME,
} from 'redux/cloudeye-user/cloueyeUserConstant';

const CloudEyeMonitor = ({ applicationName }) => {
  const isMounted = useRef(false);

  const dispatch = useDispatch();

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const cloudEye = useSelector((state) => state.cloudEye);
  const { cloudEyeUserTable, cloudEyeApplicationName } = cloudEye;

  const [selectedApplicationName, setSelectedApplicationName] = useState();

  // Webservice
  const [webserviceApiLoading, setWebserviceApiLoading] = useState(true);
  const [webServiceApiData, setWebServiceApiData] = useState([]);
  const [webServiceApiError, setWebServiceApiError] = useState(null);

  // Resource Health
  const [resourceHealthApiLoading, setResourceHealthApiLoading] =
    useState(true);
  const [resourceHealthApiData, setResourceHealthApiData] = useState([]);
  const [resourceHealthApiError, setResourceHealthApiError] = useState(null);

  // Service
  const [serviceApiLoading, setServiceApiLoading] = useState(true);
  const [serviceApiData, setServiceApiData] = useState([]);
  const [serviceApiError, setServiceApiError] = useState(null);

  // Application
  const [applicationApiLoading, setApplicationApiLoading] = useState(true);
  const [applicationApiData, setApplicationApiData] = useState([]);
  const [applicationApiError, setApplicationApiError] = useState(null);

  // Cloud & Infrastructure
  const [cloudInfrastructureValue, setCloudInfrastructureValue] = useState('');
  const [cloudInfrastructureNotify, setCloudInfrastructureNotify] = useState(
    []
  );

  // Snooze Alarm Part
  const [showSnoozeAlarm, setShowSnoozeAlarm] = useState(false);

  // ! Close Snooze Callback
  const closeSnoozeCallback = useCallback((value) => {
    setShowSnoozeAlarm(value);
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (cloudEyeUserTable === 'Webservice Health') {
        setTimeout(() => {
          var elmntToView = document.getElementById('Webservice Health');
          elmntToView.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          });
        }, 1000);
      }

      if (cloudEyeUserTable === 'Resource Health') {
        setTimeout(() => {
          var elmntToView = document.getElementById('Resource Health');
          elmntToView.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          });
        }, 1000);
      }

      if (cloudEyeUserTable === 'Service') {
        setTimeout(() => {
          var elmntToView = document.getElementById('Service');
          elmntToView.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          });
        }, 1000);
      }

      if (cloudEyeUserTable === 'Application') {
        setTimeout(() => {
          var elmntToView = document.getElementById('Application');
          elmntToView.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          });
        }, 1000);
      }
    }

    return () => {};
  }, [cloudEyeUserTable]);

  useEffect(() => {
    if (isMounted.current) {
      if (selectedApplicationName !== '') {
        dispatch({
          type: CLOUD_EYE_USER_TABLE,
          payload: '',
        });
        const idToken = `${userInfo.idToken}`;
        const requestBody = {
          application: `${selectedApplicationName.trim()}`,
        };

        const webserviceApi = `${process.env.REACT_APP_CLOUD_EYE_USER_WEBSERVICE}`;
        const fetchWebserviceHealth = async () => {
          setWebserviceApiLoading(true);
          postApiCall(webserviceApi, idToken, requestBody)
            .then((response) => {
              if (response.data.statusCode === 200) {
                setWebServiceApiData(response);
                setWebserviceApiLoading(false);
              } else {
                setWebServiceApiError(
                  'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
                );
                setWebserviceApiLoading(false);
              }
            })
            .catch((err) => {
              setWebServiceApiError(
                'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
              );
              setWebserviceApiLoading(false);
            });
        };

        const resourceHeathApi = `${process.env.REACT_APP_CLOUD_EYE_USER_RESOURCE_HEALTH}`;
        const fetchResourceHealth = async () => {
          setResourceHealthApiLoading(true);
          postApiCall(resourceHeathApi, idToken, requestBody)
            .then((response) => {
              if (response.data.statusCode === 200) {
                setResourceHealthApiData(response);
                setCloudInfrastructureNotify(response.data.issueres);
                setResourceHealthApiLoading(false);
              } else {
                setResourceHealthApiError(
                  'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
                );
                setResourceHealthApiLoading(false);
              }
            })
            .catch((err) => {
              setResourceHealthApiError(
                'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
              );
              setResourceHealthApiLoading(false);
            });
        };

        const serviceApi = `${process.env.REACT_APP_CLOUD_EYE_USER_SERVICE}`;
        const fetchServiceHealth = async () => {
          setServiceApiLoading(true);
          postApiCall(serviceApi, idToken, requestBody)
            .then((response) => {
              if (response.data.statusCode === 200) {
                setServiceApiData(response);
                setServiceApiLoading(false);
              } else {
                setServiceApiError(
                  'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
                );
                setServiceApiLoading(false);
              }
            })
            .catch((err) => {
              setServiceApiError(
                'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
              );
              setServiceApiLoading(false);
            });
        };

        const applicationApi = `${process.env.REACT_APP_CLOUD_EYE_USER_APPLICATION}`;
        const fetchApplicationHealth = async () => {
          setApplicationApiLoading(true);

          postApiCall(applicationApi, idToken, requestBody)
            .then((response) => {
              if (response.data.statusCode === 200) {
                setApplicationApiData(response);
                setApplicationApiLoading(false);
              } else {
                setApplicationApiError(
                  'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
                );
                setApplicationApiLoading(false);
              }
            })
            .catch((err) => {
              setApplicationApiError(
                'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
              );
              setApplicationApiLoading(false);
            });
        };

        fetchWebserviceHealth();
        fetchResourceHealth();
        fetchServiceHealth();
        fetchApplicationHealth();
      }
    }
  }, [selectedApplicationName, dispatch]);

  useEffect(() => {
    setSelectedApplicationName(applicationName);

    isMounted.current = true;

    dispatch({
      type: CLOUD_EYE_APPLICATION_NAME,
      payload: applicationName,
    });
  }, []);

  return (
    <Row>
      <Col md={12} className='px-3'>
        <MenuTabs
          applicationName={selectedApplicationName}
          accountName={resourceHealthApiData}
          accountNameLoading={resourceHealthApiLoading}
        />

        {selectedApplicationName === '' && (
          <div className='d-flex justify-content-center align-items-center '>
            Please select the application
          </div>
        )}
        {selectedApplicationName !== '' && (
          <div className='mt-4'>
            <Row>
              <Col md={6} className='mb-3'>
                <div className='application-container-title mb-3'>
                  Quick Overview
                </div>
                <QuickOverView
                  webserviceLoading={webserviceApiLoading}
                  webserviceData={webServiceApiData}
                  webServiceError={webServiceApiError}
                  resourceHealthLoading={resourceHealthApiLoading}
                  resourceHealthData={resourceHealthApiData}
                  resourceHealthError={resourceHealthApiError}
                />
              </Col>

              <Col md={6} className='mb-3'>
                <div className='application-container-title mb-3'>
                  Application Health
                </div>
                <ApplicationHealth
                  serviceLoading={serviceApiLoading}
                  serviceData={serviceApiData}
                  serviceError={serviceApiError}
                  applicationLoading={applicationApiLoading}
                  applicationData={applicationApiData}
                  applicationError={applicationApiError}
                />
              </Col>
            </Row>
          </div>
        )}

        {selectedApplicationName !== '' &&
          resourceHealthApiLoading === false &&
          resourceHealthApiData.data.body.length > 0 && (
            <div className='mt-4 mb-3'>
              {/* <div className='application-container-title mb-3'>
                Cloud & Infrastructure
              </div> */}
              <Row>
                <Col md={8} className='mb-3'>
                  <div className='application-container-title mb-3'>
                    Cloud & Infrastructure
                  </div>
                </Col>
                {/* <Col md={4} className='mb-3'>
                  <button
                    type='button'
                    className='teal-bordered-btn w-100'
                    onClick={() => {
                      setShowSnoozeAlarm(true);
                    }}
                  >
                    <img src={snoozeIcon} alt='Snooze' /> Snooze All Resource
                    Alerts
                  </button>
                </Col> */}
              </Row>
              <Row>
                {resourceHealthApiData.data.body.map((data, index) => (
                  <Col
                    md={3}
                    key={index}
                    className='mb-3 custom-pointer custom-realtive'
                    onClick={() => {
                      setCloudInfrastructureValue(data.resource);
                      dispatch({
                        type: CLOUD_EYE_USER_TABLE,
                        payload: 'Cloud & Infrastructure',
                      });
                      setTimeout(() => {
                        var elmntToView = document.getElementById(
                          data.resource
                        );
                        elmntToView.scrollIntoView({
                          behavior: 'smooth',
                          block: 'nearest',
                          inline: 'start',
                        });
                      }, 1000);
                    }}
                  >
                    {cloudInfrastructureNotify.includes(data.resource) && (
                      <div className='custom-notify'>
                        <i className='fa fa-exclamation '></i>
                      </div>
                    )}

                    <div className='custom-card cloud-infrastructure-container '>
                      <div
                        className='regular-text-bold'
                        style={{ marginLeft: '14px' }}
                      >
                        Amazon{' '}
                        <span className='text-uppercase'>{data.resource}</span>
                      </div>
                      <div className='mt-3 d-flex justify-content-center align-items-center'>
                        {data.resource === 'ec2' && (
                          <div className='cloud-infrastructure-img-container'>
                            <img
                              src={amazonec2icon}
                              alt='EC2'
                              className='cloud-infrastructure-img'
                            />
                          </div>
                        )}
                        {data.resource === 'efs' && (
                          <div className='cloud-infrastructure-img-container'>
                            <img
                              src={amazonefsicon}
                              alt='EFS'
                              className='cloud-infrastructure-img'
                            />
                          </div>
                        )}
                        {data.resource === 'lb' && (
                          <div className='cloud-infrastructure-img-container'>
                            <img
                              src={amazonlbicon}
                              alt='LB'
                              className='cloud-infrastructure-img'
                            />
                          </div>
                        )}
                        {data.resource === 'rds' && (
                          <div className='cloud-infrastructure-img-container'>
                            <img
                              src={amazonrdsicon}
                              alt='RDS'
                              className='cloud-infrastructure-img'
                            />
                          </div>
                        )}
                        {data.resource === 'lambda' && (
                          <div className='cloud-infrastructure-img-container'>
                            <img
                              src={amazonlambda}
                              alt='Lambda'
                              className='cloud-infrastructure-img'
                            />
                          </div>
                        )}
                        {data.resource === 'apigateway' && (
                          <div className='cloud-infrastructure-img-container'>
                            <img
                              src={amazonapigateway}
                              alt='API Gateway'
                              className='cloud-infrastructure-img'
                            />
                          </div>
                        )}
                        {data.resource === 'dynamodb' && (
                          <div className='cloud-infrastructure-img-container'>
                            <img
                              src={amazondynamodb}
                              alt='Dynamo DB'
                              className='cloud-infrastructure-img'
                            />
                          </div>
                        )}
                        <span className='px-4 cloud-infrastructure-count-text'>
                          {data.count}
                        </span>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}

        <div id='Webservice Health'>
          {selectedApplicationName !== '' &&
            cloudEyeUserTable === 'Webservice Health' && (
              <Col md={12}>
                <WebserviceDatatable
                  webserviceData={webServiceApiData}
                  selectedApplicationName={selectedApplicationName}
                />
              </Col>
            )}
        </div>

        <div id='Resource Health'>
          {selectedApplicationName !== '' &&
            cloudEyeUserTable === 'Resource Health' && (
              <ResourceHealthDatatable
                resourceHealthData={resourceHealthApiData}
              />
            )}
        </div>

        <div id='Service'>
          {selectedApplicationName !== '' && cloudEyeUserTable === 'Service' && (
            <Col md={12}>
              <ServiceDatatable serviceData={serviceApiData} />
            </Col>
          )}
        </div>

        <div id='Application'>
          {selectedApplicationName !== '' &&
            cloudEyeUserTable === 'Application' && (
              <Col md={12}>
                <ApplicationDatatable applicationData={applicationApiData} />
              </Col>
            )}
        </div>

        <div id='ec2'>
          {selectedApplicationName !== '' &&
            cloudEyeUserTable === 'Cloud & Infrastructure' &&
            cloudInfrastructureValue === 'ec2' && (
              <EC2Datatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
              />
            )}
        </div>

        <div id='efs'>
          {selectedApplicationName !== '' &&
            cloudEyeUserTable === 'Cloud & Infrastructure' &&
            cloudInfrastructureValue === 'efs' && (
              <EFSDatatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
              />
            )}
        </div>

        <div id='lb'>
          {selectedApplicationName !== '' &&
            cloudEyeUserTable === 'Cloud & Infrastructure' &&
            cloudInfrastructureValue === 'lb' && (
              <LBDatatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
              />
            )}
        </div>

        <div id='rds'>
          {selectedApplicationName !== '' &&
            cloudEyeUserTable === 'Cloud & Infrastructure' &&
            cloudInfrastructureValue === 'rds' && (
              <RDSDatatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
              />
            )}
        </div>

        <div id='lambda'>
          {selectedApplicationName !== '' &&
            cloudEyeUserTable === 'Cloud & Infrastructure' &&
            cloudInfrastructureValue === 'lambda' && (
              <LambdaDatatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
              />
            )}
        </div>

        <div id='apigateway'>
          {selectedApplicationName !== '' &&
            cloudEyeUserTable === 'Cloud & Infrastructure' &&
            cloudInfrastructureValue === 'apigateway' && (
              <ApiGatewayDatatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
              />
            )}
        </div>

        <div id='dynamodb'>
          {selectedApplicationName !== '' &&
            cloudEyeUserTable === 'Cloud & Infrastructure' &&
            cloudInfrastructureValue === 'dynamodb' && (
              <DynamoDBDatatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
              />
            )}
        </div>

        {/* Snooze Alarm */}
        {showSnoozeAlarm && (
          <SnoozeAlarm
            application={selectedApplicationName}
            closeSnoozeParent={closeSnoozeCallback}
          />
        )}
      </Col>
    </Row>
  );
};

export default CloudEyeMonitor;
