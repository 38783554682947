import React, { useState, useCallback } from 'react';

// Common Component
import CustomModal from 'components/Modal/CustomModal';

// Internal Component
import GitlabConfirm from './GitlabConfirm';

// Icon
import gitlabIcon from 'icons/gitlab-icon.svg';
import { useTranslation } from 'react-i18next';

const Gitlab = () => {
  // Component State
  const [showModal, setShowModal] = useState(false);

  const closeModalCallback = useCallback((value) => {
    setShowModal(value);
  }, []);
  const {t}=useTranslation();

  return (
    <>
      <div className='gitlab-container'>
        <div className='gitlab-img'>
          <img src={gitlabIcon} alt='' />
        </div>
        <p className='gitlab-text'>{t("activate_your_gitlab")}</p>

        <button
          type='button'
          className='gitlab-btn'
          onClick={() => {
            setShowModal(true);
          }}
        >
          {t("activate")}
        </button>
        {showModal === true && (
          <CustomModal
            showModal={true}
            children={<GitlabConfirm closeModal={closeModalCallback} />}
          />
        )}
      </div>
    </>
  );
};

export default Gitlab;
