import React from 'react';

// Import Image
import awsLogo from '../../images/aws-logo.svg';
import { useTranslation } from 'react-i18next';

const AWSServiceStatus = () => {
  const {t}=useTranslation();
  return (
    <div className='custom-card'>
      <h5 className='dashboard-title mb-3'>{t("serviceStatus")}</h5>
      <div>
        <img src={awsLogo} alt='AWS' className='service-status-img' />
        <a
          href='https://status.aws.amazon.com/'
          className='service-status-link'
          target='_blank'
          rel='noopener noreferrer'
        >
         {t("clickHereToViewMore")}
        </a>
        <p className='service-status-description'>
         {t("checkBackText")}
        </p>
      </div>
    </div>
  );
};

export default AWSServiceStatus;
