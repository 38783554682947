import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactECharts from "echarts-for-react";
import axios from "axios";
import moment from "moment";

// Date Picker
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";

// Import Component
import RemedyTicketCanvas from "../../components/Canvas/RemedyTicketCanvas";
import SpinnerLoader from "components/Common/SpinnerLoader";

// Import Constant
import {
  PRISMA,
  REMEDY_TICKET,
  TREND,
  MORIARTY,
  SERVICE,
} from "../../redux/dashboard/dashboardConstant";
import { useMemo } from "react";

const BillingChart = ({
  selAccountLists,
  cloud,
  height = null,
  innerHeight = null,
  setInvoiceChartOpen = () => {},
}) => {
  // ! Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const selAccounts = useMemo(() => {
    return selAccountLists?.map((acc) => acc.DropdownValue);
  }, [selAccountLists]);

  // ! API State
  const [loading, setLoading] = useState(true);

  // ! Chart State
  const [xAxis, setxAxis] = useState([]);
  const [xAxisData, setXAxisData] = useState();
  const [yAxisData, setYAxisData] = useState();

  // ! Chart Datasets
  var dataSet = [
    {
      name: "Cost",
      type: "bar",
      stack: "1",
      barWidth: 15,
      data: yAxisData,
    },
    {
      name: "Date",
      type: "bar",
      stack: "1",
      barWidth: 15,
      data: xAxisData,
    },
  ];

  // ! Chart Options
  var option = {
    tooltip: {},
    legend: {
      show: false,
    },
    xAxis: [
      {
        type: "category",
        axisTick: { show: false },
        axisLine: {
          show: false,
        },
        axisLabel: height
          ? {
              fontSize: 10,
              align: `center`,
              interval: 0,
              padding: [0, 0, 0, 20],
              margin: 10,
            }
          : {},
        // data: ['W1', 'W2', 'W3', 'W4'],
        data: xAxisData,
      },
    ],
    yAxis: [
      height
        ? {
            type: "value",
            axisLabel: { formatter: (value) => `($)${value}`, padding: 0 },
            // axisLabel: {
            //   formatter: function (value) {
            //     if (value < 1e3) return value;
            //     if (value >= 1e3) return +(value / 1e3).toFixed(1) + "K";
            //   },
            // },
          }
        : {
            type: "value",
          },
    ],
    series: dataSet,
    grid: height
      ? {
          top: "15%",
          left: "15%", // Adjust the left margin
          // containLabel: true,
        }
      : {},
  };

  // ! API Varaibles
  const apiUrl = `${process.env.REACT_APP_BILLING_COST_HIST}`;
  const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

  useEffect(() => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: setIdToken,
      },
    };
    const requestBody = {
      cloud,
      accounts: selAccounts,
    };
    axios.post(apiUrl, requestBody, config).then((response) => {
      const yvalueArray = [];
      const xvalueArray = [];
      const yaxisData = [];
      const xaxisData = [];

      response.data?.forEach((val) => {
        if (height) {
          const monthSplit = val?.month?.split("-");
          const monthVal = `${monthSplit[0].substr(0, 3)}-${monthSplit[1]}`;
          xvalueArray.push(monthVal);
        } else {
          xvalueArray.push(val?.month);
        }
        yvalueArray.push(val?.charge);
      });

      for (let i = 0; i < yvalueArray.length; i++) {
        let object = {};
        object.value = yvalueArray[i];
        object.itemStyle = {
          color: "#3AC4AA",
        };
        yaxisData.push(object);
      }
      setYAxisData(yaxisData);

      for (let i = 0; i < xvalueArray.length; i++) {
        let object = {};
        object.value = xvalueArray[i];
        object.itemStyle = {
          color: "#B1EFE4",
        };
        xaxisData.push(object);
      }
      setXAxisData(xaxisData);

      setLoading(false);
    });

    return () => {
      setxAxis([]);
      setYAxisData();
      setXAxisData();
    };
  }, [selAccounts, cloud]);

  return (
    <div className="custom-card">
      {loading && (
        <Row>
          <Col className="d-flex justify-content-center mt-3">
            <SpinnerLoader />
          </Col>
        </Row>
      )}

      {loading === false && (
        <>
          <Row
            className={`${
              height ? "flex-row justify-content-center align-items-center" : ""
            }`}
          >
            <Col md={7} xs={7} className="text-start">
              <h5 className={`dashboard-title ${height ? "mb-0" : ""}`}>
                {height ? "Invoice By Month" : "Invoice"}
              </h5>
            </Col>
            {!height ? (
              <Col md={5} xs={5}>
                <ul className="remedy-legend mt-0">
                  <li className="d-flex">
                    <div className="billing-cost-legend d-inline"></div>
                    {height ? "Grand Total Charged" : "Cost"}
                  </li>
                </ul>
              </Col>
            ) : null}
            {height ? (
              <Col md={5} xs={5} className="text-end">
                <span
                  className="fs-5 fw-normal cursor-pointer"
                  onClick={() => {
                    setInvoiceChartOpen(false);
                  }}
                >
                  x
                </span>
              </Col>
            ) : null}
          </Row>
          {/* <div style={{ height: "250px", width: "100%" }}>
            <ReactECharts option={option} />
          </div> */}
          <div
            style={{ height: `${height ? height : "300px"}`, width: "100%" }}
          >
            <ReactECharts
              option={option}
              style={{ height: `${innerHeight ? innerHeight : "300px"}` }}
            />
          </div>
          {height && (
            <Row
              className={`${
                height
                  ? "flex-row justify-content-center align-items-center"
                  : ""
              }`}
            >
              <Col md={12} xs={12}>
                <ul className="remedy-legend mt-0">
                  <li className="d-flex">
                    <div className="billing-cost-legend d-inline"></div>
                    Grand Total Charged
                  </li>
                </ul>
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default BillingChart;
