import React, { useRef } from "react";
import { useOnDemandScanApi } from "./dataloader";
import useInitScreen from "components/InitPage";
import { useOktaAuth } from "@okta/okta-react";
import { useFormik } from "formik";
import { Col, Row } from "react-bootstrap";
import SpinnerLoader from "components/Common/SpinnerLoader";
import Table from "components/Table";
import Slider from "components/Slider";

export default function OnDemandScanIndex() {
  useInitScreen();
  const fileRef = useRef(null);
  const { authState } = useOktaAuth();
  const { mutateAsync: upload, data = [], isLoading } = useOnDemandScanApi();
  const { handleSubmit, getFieldProps, values } = useFormik({
    initialValues: { cfn: "", file: "" },
    onSubmit: async (values) => {
      if (fileRef.current.files[0]) {
        const fr = new FileReader();
        fr.readAsText(fileRef.current.files[0]);
        fr.onload = function () {
          upload({
            template: btoa(fr.result),
            idToken: authState.idToken.idToken,
          });
        };
      } else {
        upload({
          template: btoa(values.cfn),
          idToken: authState.idToken.idToken,
        });
      }
    },
  });

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="custom-card mb-4">
          <textarea
            style={{ width: "100%", height: 200 }}
            value={values.cfn}
            rows={50}
            type="text"
            {...getFieldProps("cfn")}
          />
          <div>
            <input
              accept=".yml"
              ref={fileRef}
              type="file"
              {...getFieldProps("file")}
            />
          </div>
        </div>
        <button type="submit" className="teal-filled-btn">
          Run the scan
        </button>
        {isLoading && (
          <Row>
            <Col className="d-flex justify-content-center align-items-center">
              <SpinnerLoader />
            </Col>
          </Row>
        )}
      </form>
      {!!data.length && (
        <div className="custom-card mb-4">
          <Table
            data={data}
            columns={columns}
            text="Scan Results"
            idField="id"
          />
        </div>
      )}
      {false && (
        <Slider headerText="Scan Details">
          <Row>
            <Col md={12} className="mb-3">
              <div className="view-alarm-container">
                <Row>
                  <Col md={3} className="mb-2 px-3">
                    <div className="view-alarm-key mb-2">Header</div>
                    <div className="view-alarm-data">Text</div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Slider>
      )}
    </div>
  );
}

const columns = [
  {
    dataField: "rule-title",
    text: "Rule",
    sort: true,
    searchable: true,
  },
  {
    dataField: "service",
    text: "Service",
    sort: true,
    searchable: true,
  },
  {
    dataField: "categories",
    text: "Categories",
    sort: true,
    searchable: true,
    formatter: (cellContent, row) => {
      return row.categories?.map((item) => <div key={item}>{item}</div>);
    },
  },
  {
    dataField: "risk-level",
    text: "Risk Level",
    sort: true,
    searchable: true,
  },
  {
    dataField: "status",
    text: "Counts",
    sort: true,
    searchable: true,
  },
];
