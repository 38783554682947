import React, { useState } from "react";
import { useSelector } from "react-redux";

import Loader from "components/Common/Loader";
import AlertMessage from "components/Common/AlertMessage";

import { postApiCall } from "helper/postApiCall";
import { Col, Form, Row } from "react-bootstrap";

const Decline = ({ yesParent, closeModalParent, uid }) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successDeclined, setSuccessDeclined] = useState(false);
  const [declineReason, setDeclineReason] = useState(null);
  const [showMandatory, setShowMandatory] = useState(false);

  async function declineRequest() {
    if (declineReason) {
      setLoading(true);
      const idToken = userInfo.idToken;
      const requestBody = `{}`;
      const apiUrl = `${process.env.REACT_APP_SECURITY_POSTURE_AZURE_SUBMIT}`;
      const setApiUrl = `${apiUrl}?uid=${uid}&type=0&actiontakenby=${userInfo.email}&declineMessage=${declineReason}`;

      await postApiCall(setApiUrl, idToken, requestBody)
        .then((response) => {
          setSuccessDeclined(true);
        })
        .catch((err) => {
          setError(err);
          setSuccessDeclined(false);
        });
    } else {
      setShowMandatory(true);
    }
  }

  return (
    <div className="d-flex justify-content-center flex-column">
      {successDeclined === false && (
        <>
          <p>Are you sure you want to decline this request ?</p>
          <Row>
            <Col md={2} />
            <Col md={8}>
              <Form.Group className="mb-3" controlId="bundleDescription">
                <Form.Label>{"Reason for decline"}</Form.Label>
                <Form.Control
                  class={`form-control ${showMandatory ? "is-invalid" : ""}`}
                  as="textarea"
                  style={{ whiteSpace: "normal" }}
                  rows={3}
                  value={declineReason}
                  onChange={(e) => {
                    setDeclineReason(e.target.value);
                    setShowMandatory(true);
                  }}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={2} />
          </Row>
          {loading && <Loader />}
          {loading === false && error === null && (
            <ul className="btn-ul">
              <li>
                <button
                  type="button"
                  className="teal-bordered-btn"
                  onClick={() => {
                    closeModalParent(null);
                  }}
                >
                  No
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="teal-filled-btn"
                  onClick={() => {
                    declineRequest();
                  }}
                >
                  Yes
                </button>
              </li>
            </ul>
          )}
        </>
      )}

      {successDeclined === true && (
        <>
          {error !== null && (
            <div className="d-flex justify-content-center flex-column">
              <AlertMessage
                cssClassName="alert-message-danger"
                alertMessage={error}
              />
            </div>
          )}
          {error === null && (
            <>
              <p>Request Declined!</p>
              <p>
                <button
                  type="button"
                  className="teal-filled-btn"
                  onClick={() => {
                    yesParent(null);
                  }}
                >
                  Ok
                </button>
              </p>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Decline;
