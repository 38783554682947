import React, { useState, useRef, useCallback } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';
import CustomModal from 'components/Modal/CustomModal';
import ModalCancel from './ModalCancel';
import ModalSubmit from './ModalSubmit';

// Form
import TextInput from 'components/Forms/TextInput';

// Helper
import { postApiCall } from 'helper/postApiCall';

// Validator
import { documentValidator } from './validator';
import { useTranslation } from 'react-i18next';

const AddDocument = () => {
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const {t} = useTranslation();
  // Component State
  const [apiLoading, setApiLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  // Form State
  const [isUrlSelected, setIsUrlSelected] = useState(false);

  const [subject, setSubject] = useState('');
  const [category, setCategory] = useState('');
  const [tag, setTag] = useState('admin');
  const [description, setDescription] = useState('');
  const [filePath, setFilePath] = useState('');

  const [url, setUrl] = useState('');

  const [fileName, setFileName] = useState('');
  const [baseFile, setBaseFile] = useState('');

  const ref = useRef();

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Modal State
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const filePath = e.target.files[0].name;
    const fileSize = e.target.files[0].size;
    const checkFileSize = Math.round(fileSize / 1024);

    const allowedExtensions = ['pdf'];
    const fileExtension = filePath.split('.').pop();

    if (checkFileSize >= 5120) {
      alert('File size should be below 5MB');
      ref.current.value = '';
    } else if (!allowedExtensions.includes(fileExtension)) {
      alert('file type not allowed');
      ref.current.value = '';
    } else {
      const base64 = await convertBase64(file);
      setBaseFile(base64);
      setFileName(file.name);
    }
  };

  // API Variable
  const apiUrl = `${process.env.REACT_APP_ADD_DOCUMENT}`;
  const idToken = `${userInfo.idToken}`;

  // Form Submit
  const handleFormSubmit = () => {
    setDataInputError({});
    const errors = documentValidator({
      isUrlSelected,
      subject,
      category,
      url,
      filePath,
      baseFile,
      description,
    });
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setApiLoading(true);

      let requestBody;

      if (isUrlSelected) {
        requestBody = {
          subject: subject,
          categoryname: category,
          tag: tag,
          description: description,
          Url: url,
        };
      } else {
        requestBody = {
          subject: subject,
          categoryname: category,
          tag: tag,
          description: description,
          path: filePath,
          filename: fileName,
          file: baseFile,
        };
      }

      postApiCall(apiUrl, idToken, requestBody)
        .then((response) => {
          setSubmitMessage(response.data.message);
          setShowSubmitModal(true);

          setApiLoading(false);
        })
        .catch((err) => {
          setApiError(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setApiLoading(false);
        });
    }
  };

  return (
    <Container fluid>
      {apiLoading && (
        <Row>
          <Col md={12} className='d-flex justify-content-center mt-5'>
            <SpinnerLoader />
          </Col>
        </Row>
      )}

      {apiLoading === false && apiError !== null && (
        <Row>
          <Col md={12} className='mt-5'>
            <DangerAlert message={apiError} />
          </Col>
        </Row>
      )}

      {apiLoading === false && apiError === null && (
        <div className='px-3'>
          <Row>
            <Col md={12} className='mt-3 mb-4 d-flex align-items-center'>
              <span className='maintitle-text'>{t("add_document")}</span>
            </Col>
          </Row>

          <Row>
            <Col md={12} className='mt-3 mb-4 d-flex align-items-center'>
              <span className='regular-text'>{t("upload_url")}:</span>
              <label className='custom-toggle-switch mx-3'>
                <input
                  type='checkbox'
                  checked={isUrlSelected}
                  onChange={(e) => {
                    let getCheckedValue = e.target.checked;
                    setIsUrlSelected(getCheckedValue);
                  }}
                />
                <span className='custom-toggle-slider round'></span>
              </label>
            </Col>
          </Row>

          <Row>
            <Col md={6} className='mt-1'>
              <TextInput
                label={`*${t("subject_name")}:`} 
                name='subjectText'
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder={t("subject_name")}
                isInvalid={dataInputError.subject ? true : false}
                invalidMessage={dataInputError.subject}
              />
            </Col>
            <Col md={6} className='mt-1'>
              <TextInput
                label={`*${t("category_name")}:`}
                name='categoryText'
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                placeholder={t("category_name")}
                isInvalid={dataInputError.category ? true : false}
                invalidMessage={dataInputError.category}
              />
            </Col>
            {isUrlSelected && (
              <Col md={6} className='mt-1'>
                <TextInput
                  label={`*${t("url")}:`}
                  name='urlText'
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder={t("url")}
                  isInvalid={dataInputError.url ? true : false}
                  invalidMessage={dataInputError.url}
                />
              </Col>
            )}

            <Col md={6} className='mt-1'>
              <Form.Group className='mb-3'>
                <Form.Label>Tag:</Form.Label>
                <Form.Select
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                >
                  <option value='admin'>{t("admin")}</option>
                  <option value='user'>{t("user")}</option>
                </Form.Select>
              </Form.Group>
            </Col>

            {isUrlSelected === false && (
              <Col md={6} className='mt-1'>
                <TextInput
                  label={`*${t("path")}:`}
                  name='pathText'
                  value={filePath}
                  onChange={(e) => setFilePath(e.target.value)}
                  placeholder={t("path")}
                  isInvalid={dataInputError.filePath ? true : false}
                  invalidMessage={dataInputError.filePath}
                />
              </Col>
            )}

            <Col md={12} className='mt-1'>
              <Form.Group className='mb-3'>
                <Form.Label>*{t("description")}:</Form.Label>

                <Form.Control
                  as='textarea'
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  isInvalid={dataInputError.description ? true : false}
                />
                <div className='custom-error-message-text'>
                  {dataInputError.description}
                </div>
              </Form.Group>
            </Col>

            {isUrlSelected === false && (
              <Col md={12} className='mt-1'>
                <Form.Group controlId='formFile' className='mb-3'>
                  <Form.Label>{t("file_upload")}:</Form.Label>
                  {fileName !== '' && <div className='mb-3'>{fileName}</div>}
                  {/* <div className='custom-upload-container'>
                    <p className='custom-upload-btn'>
                      <i className='fa fa-cloud-upload'></i>
                      Add File
                      <input
                        type='file'
                        ref={ref}
                        onChange={(e) => {
                          uploadImage(e);
                        }}
                      />
                    </p>
                  </div> */}
                  <div className='custom-upload-wrapper'>
                    <button className='custom-upload-button'>
                      <i className='fa fa-cloud-upload'></i>
                      {t("add_file")}
                    </button>
                    <input
                      type='file'
                      ref={ref}
                      onChange={(e) => {
                        uploadImage(e);
                      }}
                    />
                  </div>
                </Form.Group>
                <div className='custom-error-message-text'>
                  {dataInputError.baseFile}
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              {apiLoading === false && (
                <div className='d-flex justify-content-end'>
                  <ul className='px-0 btn-ul'>
                    <li>
                      <button
                        type='button'
                        className='teal-bordered-btn'
                        onClick={() => {
                          setShowCancelModal(true);
                        }}
                      >
                        {t("cancel")}
                      </button>
                    </li>
                    <li>
                      <button
                        type='button'
                        className='teal-filled-btn'
                        onClick={() => {
                          handleFormSubmit();
                        }}
                      >
                        {t("submit")}
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </Col>
          </Row>

          {showCancelModal && (
            <CustomModal
              showModal={true}
              children={<ModalCancel closeModalParent={closeModalCallback} />}
            />
          )}

          {showSubmitModal && (
            <CustomModal
              showModal={true}
              children={<ModalSubmit message={submitMessage} />}
            />
          )}
        </div>
      )}
    </Container>
  );
};

export default AddDocument;
