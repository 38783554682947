import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

// Components
import TextInput from 'components/Forms/TextInput';
import SpinnerLoader from 'components/Common/SpinnerLoader';
import BillingCompanyDropdown from 'components/Forms/BillingCompanyDropdown';
import BillingSubCompanyDropdown from 'components/Forms/BillingSubCompanyDropdown';
import BillingDepartmentDropdown from 'components/Forms/BillingDepartmentDropdown';
import StaticDropdown from 'components/Forms/StaticDropdown';
import CustomModal from 'components/Modal/CustomModal';
import DangerAlert from 'components/Message/DangerAlert';

import EditCancel from './EditCancel';

import { WORKSPACE_REQUEST_RELOAD } from 'redux/workspace-request/workspaceRequestConstant';

// Data
import {
  staticbillingRegion,
  staticbillto,
} from 'pages/Requests/AWS/StaticData/awsStaticData';

// Helper
import { getApiCall } from 'helper/getApiCall';
import { postApiCall } from 'helper/postApiCall';

// Validator
import { awsWorkspaceValidator } from '../validator';

// Import Icons
import backicon from 'icons/back.svg';

import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

const EditBox = ({ data, closeEditParent }) => {
  const dispatch = useDispatch();
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const idToken = userInfo.idToken;

  // API Get State
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  // Region Static Data
  const regionData = [
    { label: 'us-east-1', value: 'us-east-1' },
    { label: 'ap-southeast-1', value: 'ap-southeast-1' },
    { label: 'eu-west-1', value: 'eu-west-1' },
  ];

   // directory data
   const directoryData = [
    { label: 'am.sony.com(AM)', value: 'AM' },
    { label: 'sm.sony.com(Sony Music)', value: 'SonyMusic' },
  ]

  // Form State
  const [WOgid, setWOgid] = useState(data.WOgid);
  const [WOmail, setWOmail] = useState(data.WOmail);
  const [WOfirstName, setWOfirstName] = useState(data.WOfirstName);
  const [WOlasttName, setWOlasttName] = useState(data.WOlasttName);
  const [WOphno, setWOphno] = useState(data.WOphno);

  const [usergid, setusergid] = useState(data.usergid);
  const [usermail, setusermail] = useState(data.usermail);
  const [userfirstName, setuserfirstName] = useState(data.userfirstName);
  const [userlasttName, setuserlasttName] = useState(data.userlasttName);
  const [userphno, setuserphno] = useState(data.userphno);

  const [BOmail, setBOmail] = useState(data.BOmail);
  const [BOfirstName, setBOfirstName] = useState(data.BOfirstName);
  const [BOlasttName, setBOlasttName] = useState(data.BOlasttName);
  const [BOphno, setBOphno] = useState(data.BOphno);

  const [billingopco, setBillingopco] = useState(data.billingopco);
  const [billingsubopco, setBillingsubopco] = useState(data.billingsupco);
  const [billingdepartment, setBillingdepartment] = useState(
    data.billingdepartment
  );
  const [billto, setbillto] = useState(data.billto);
  const [io, setIo] = useState(data.io);
  const [costcenter, setCostcenter] = useState(data.costcenter);
  const [billingregion, setbillingregion] = useState(data.billingregion);

  const [region, setRegion] = useState(data.region);
  const [bundleOptions, setBundleOptions] = useState([]);
  const [bundleId, setBundleId] = useState(data.bundleId);

  // Component State
  const [billingSubOpCompany, setBillingSubOpCompany] = useState([]);
  const [billingDepartment, setBillingDepartment] = useState([]);
  const [pasteToUserDetails, setpasteToUserDetails] = useState(false);
  const [pasteToBillingContact, setpasteToBillingContact] = useState(false);
  const [isDisclaimerAgreed, setIsDisclaimerAgreed] = useState(false);

  // choose directory 
  const [directory, setDirectory] = useState(data?.directory);

  // Data Input Error
  const [dataInputError, setDataInputError] = useState({});

  // Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  const closeEditCallback = useCallback((value) => {
    closeEditParent(value);
  }, []);

  // handle onchange
  const handleData = ({ target }) => {
    if (target.name === 'billingopco') {
      setBillingsubopco('');
      setBillingdepartment('');

      setBillingSubOpCompany([]);
      setBillingDepartment([]);
      let opCompany = target.value;
      setBillingopco(opCompany);
      if (opCompany !== '') {
        const getSubOpCompany = responseData.find(
          (t) => t.opco === opCompany
        ).subopcod;
        setBillingSubOpCompany(getSubOpCompany);
      }
    }
    if (target.name === 'billingsubopco') {
      setBillingdepartment('');
      setBillingDepartment([]);
      let opSubCompany = target.value;
      setBillingsubopco(opSubCompany);
      if (opSubCompany !== '') {
        const getDepartment = billingSubOpCompany.find(
          (t) => t.subopco === opSubCompany
        ).dept;
        setBillingDepartment(getDepartment);
      }
    }
    if (target.name === 'billingdepartment') {
      let department = target.value;
      setBillingdepartment(department);
    }
  };

  // API Form Submit State
  const [formSubmitError, setFormSubmitError] = useState(null);
  const [formSubmitModal, setFormSubmitModal] = useState(false);
  const [formSubmitRefUid, setFormSubmitRefUid] = useState(null);

  // Form Submit
  const handleContinue = (e) => {
    e.preventDefault();
    const formSubmitApiUrl = `${process.env.REACT_APP_AWS_WORKSPACES_ADD}?type=edit`;
    const errors = awsWorkspaceValidator({
      WOgid,
      WOmail,
      WOfirstName,
      WOlasttName,
      WOphno,
      usergid,
      usermail,
      userfirstName,
      userlasttName,
      userphno,
      BOmail,
      BOfirstName,
      BOlasttName,
      BOphno,
      billingopco,
      billingsubopco,
      billingdepartment,
      billto,
      io,
      costcenter,
      billingregion,
      region,
      bundleId,
      directory,
    });
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setLoading(true);
      const currentEpochTime = Date.now();
      const formSubmitRequestBody = {
        WOgid,
        WOmail,
        WOfirstName,
        WOlasttName,
        WOphno,
        usergid,
        usermail,
        userfirstName,
        userlasttName,
        userphno,
        BOmail,
        BOfirstName,
        BOlasttName,
        BOphno,
        billingopco: billingopco,
        billingsupco: billingsubopco,
        billingdepartment: billingdepartment,
        billto: billto,
        io: billto === 'Global IS (GISC)' ? io : 'N/A',
        costcenter: billto === 'Other Teams' ? costcenter : 'N/A',
        billingregion,
        region,
        bundleId,
        uid: data.uid,
        requestoremail: data.requestoremail,
        requestStatus: 'Pending',
        submittedBy: data.submittedBy,
        directory : region === 'us-east-1' ? directory : region === 'ap-southeast-1' ? 'AP' : 'EU',
      };

      postApiCall(formSubmitApiUrl, idToken, formSubmitRequestBody)
        .then((response) => {
          setFormSubmitRefUid(currentEpochTime);
          setFormSubmitModal(true);
        })
        .catch((err) => {
          setFormSubmitError(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
        // adding query string fr oauth
        const queryObj = `{
          "submittedBy": "${userInfo?.email}",
          "type_of_user": ${userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? 1 : 0}
        }`
        const encodedString = Buffer.from(queryObj).toString('base64');
        // end
    
        const billingApiUrl = `${process.env.REACT_APP_AWS_ACCOUNT_REQUEST_BILLING_INFO}?enchash=${encodedString}`;
    getApiCall(billingApiUrl, idToken).then((response) => {
      setResponseData(response.data.body);
      let getSubOpCompany = [];
      if (data.billingopco !== '') {
        setBillingSubOpCompany([]);
        setBillingDepartment([]);

        const checkOpco = response.data.body.find(
          (t) => t.opco === data.billingopco
        );

        if (checkOpco === undefined) {
          setBillingSubOpCompany([]);
        } else {
          getSubOpCompany = response.data.body.find(
            (t) => t.opco === data.billingopco
          ).subopcod;
          setBillingSubOpCompany(getSubOpCompany);
        }
      }
      if (data.billingSubOpco !== '') {
        setBillingDepartment([]);

        const checkSubOpco = getSubOpCompany.find(
          (t) => t.subopco === data.billingsupco
        );

        if (checkSubOpco === undefined) {
          setBillingDepartment([]);
        } else {
          const getDepartment = getSubOpCompany.find(
            (t) => t.subopco === data.billingsupco
          ).dept;

          setBillingDepartment(getDepartment);
        }
      }
      setLoading(false);
    });
  }, []);

  // Bundle Selection API Call
  useEffect(() => {
    // if (region !== '') {
    const bundleUrl = `${process.env.REACT_APP_AWS_WORKSPACES_BUNDLE}`;
    const bundleRequestBody = `{"region":"${region}"}`;
    postApiCall(bundleUrl, idToken, bundleRequestBody).then((response) => {
      setBundleOptions(response.data);
    });
    // }
    return () => {
      setBundleOptions([]);
    };
  }, [region]);

  // Bundle Selection API Call
  useEffect(() => {
    const bundleUrl = `${process.env.REACT_APP_AWS_WORKSPACES_BUNDLE}`;
    const bundleRequestBody = `{"region":"${data.region}"}`;
    postApiCall(bundleUrl, idToken, bundleRequestBody).then((response) => {
      setBundleOptions(response.data);
    });

    return () => {
      setBundleOptions([]);
    };
  }, []);

  return (
    <div className='view-edit-box-conatiner pb-5'>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => closeEditParent(false)}
          />
          <div>
            <h5 className='view-edit-box-title'>AWS Workspace Request</h5>
          </div>
        </div>
      </div>

      {loading && (
        <Row>
          <Col className='mt-2'>
            <SpinnerLoader />
          </Col>
        </Row>
      )}

      {loading === false && (
        <div className='table-view-box-content mt-4 mb-5'>
          <Form onSubmit={handleContinue}>
            {formSubmitError !== null && (
              <Row>
                <Col className='mt-2'>
                  <DangerAlert message={formSubmitError} />
                </Col>
              </Row>
            )}

            <Row>
              <Col md={12} className='mt-3 mb-3'>
                <div className='account-request-view-title-container'>
                  <p className='account-request-view-title'>
                    Workspace Owner Details
                  </p>
                </div>
              </Col>

              <Col md={12}>
                <TextInput
                  label='Global ID'
                  placeholder='Global ID'
                  value={WOgid}
                  onChange={(e) => setWOgid(e.target.value)}
                  isInvalid={dataInputError.WOgid ? true : false}
                  invalidMessage={dataInputError.WOgid}
                  infoBubbleTitle='A workspace owner is the person requesting the workspace and can request one or more workspaces for either him/herself or others in the team. ' 
          
                />
              </Col>

              <Col md={12}>
                <TextInput
                  label='Email Address:'
                  name='Email Address'
                  value={WOmail}
                  onChange={(e) => setWOmail(e.target.value)}
                  placeholder='Email Address'
                  isInvalid={dataInputError.WOmail ? true : false}
                  invalidMessage={dataInputError.WOmail}
                />
              </Col>

              <Col md={12}>
                <TextInput
                  label='First Name:'
                  name='First Name'
                  value={WOfirstName}
                  onChange={(e) => setWOfirstName(e.target.value)}
                  placeholder='First Name'
                  isInvalid={dataInputError.WOfirstName ? true : false}
                  invalidMessage={dataInputError.WOfirstName}
                />
              </Col>

              <Col md={12}>
                <TextInput
                  label='Last Name:'
                  name='Last Name'
                  value={WOlasttName}
                  onChange={(e) => setWOlasttName(e.target.value)}
                  placeholder='Last Name'
                  isInvalid={dataInputError.WOlasttName ? true : false}
                  invalidMessage={dataInputError.WOlasttName}
                />
              </Col>

              <Col md={12}>
                <TextInput
                  label='Phone No:'
                  name='Phone No'
                  value={WOphno}
                  onChange={(e) => setWOphno(e.target.value)}
                  placeholder='Phone No'
                  isInvalid={dataInputError.WOphno ? true : false}
                  invalidMessage={dataInputError.WOphno}
                />
              </Col>

              <Col md={12} className='mt-3 mb-3'>
                <div className='account-request-view-title-container'>
                  <p className='account-request-view-title'>
                    Workspace User Details
                  </p>
                </div>
              </Col>

              <Col md={12}>
                <TextInput
                  label='Global ID'
                  placeholder='Global ID'
                  value={usergid}
                  onChange={(e) => setusergid(e.target.value)}
                  isInvalid={dataInputError.usergid ? true : false}
                  invalidMessage={dataInputError.usergid}
                  infoBubbleTitle='The workspace user is the individual to whom this workspace will be assigned to for use and can only have one workspace assigned.' 
    
                />
              </Col>

              <Col md={12}>
                <TextInput
                  label='Email Address:'
                  name='Email Address'
                  value={usermail}
                  onChange={(e) => setusermail(e.target.value)}
                  placeholder='Email Address'
                  isInvalid={dataInputError.usermail ? true : false}
                  invalidMessage={dataInputError.usermail}
                />
              </Col>

              <Col md={12}>
                <TextInput
                  label='First Name:'
                  name='First Name'
                  value={userfirstName}
                  onChange={(e) => setuserfirstName(e.target.value)}
                  placeholder='First Name'
                  isInvalid={dataInputError.userfirstName ? true : false}
                  invalidMessage={dataInputError.userfirstName}
                />
              </Col>

              <Col md={12}>
                <TextInput
                  label='Last Name:'
                  name='Last Name'
                  value={userlasttName}
                  onChange={(e) => setuserlasttName(e.target.value)}
                  placeholder='Last Name'
                  isInvalid={dataInputError.userlasttName ? true : false}
                  invalidMessage={dataInputError.userlasttName}
                />
              </Col>

              <Col md={12}>
                <TextInput
                  label='Phone No:'
                  name='Phone No'
                  value={userphno}
                  onChange={(e) => setuserphno(e.target.value)}
                  placeholder='Phone No'
                  isInvalid={dataInputError.userphno ? true : false}
                  invalidMessage={dataInputError.userphno}
                />
              </Col>

              <Col md={12} className='mt-3 mb-3'>
                <Form.Group className='mb-3' controlId='WOCheckbox'>
                  <Form.Check
                    type='checkbox'
                    label='Same as Workspace Owner'
                    defaultChecked={pasteToUserDetails}
                    onChange={(e) => {
                      setpasteToUserDetails(!pasteToUserDetails);
                      if (e.target.checked) {
                        setusergid(WOgid);
                        setusermail(WOmail);
                        setuserfirstName(WOfirstName);
                        setuserlasttName(WOlasttName);
                        setuserphno(WOphno);
                      } else {
                        setusergid(data.usergid);
                        setusermail(data.usermail);
                        setuserfirstName(data.userfirstName);
                        setuserlasttName(data.userlasttName);
                        setuserphno(data.userphno);
                      }
                    }}
                  />
                </Form.Group>
              </Col>

              <Col md={12} className='mt-3 mb-3'>
                <div className='account-request-view-title-container'>
                  <p className='account-request-view-title'>Billing Contact</p>
                </div>
              </Col>

              <Col md={12}>
                <TextInput
                  label='Email Address:'
                  name='Email Address'
                  value={BOmail}
                  onChange={(e) => setBOmail(e.target.value)}
                  placeholder='Email Address'
                  isInvalid={dataInputError.BOmail ? true : false}
                  invalidMessage={dataInputError.BOmail}
                />
              </Col>

              <Col md={12}>
                <TextInput
                  label='First Name:'
                  name='First Name'
                  value={BOfirstName}
                  onChange={(e) => setBOfirstName(e.target.value)}
                  placeholder='First Name'
                  isInvalid={dataInputError.BOfirstName ? true : false}
                  invalidMessage={dataInputError.BOfirstName}
                />
              </Col>

              <Col md={12}>
                <TextInput
                  label='Last Name:'
                  name='Last Name'
                  value={BOlasttName}
                  onChange={(e) => setBOlasttName(e.target.value)}
                  placeholder='Last Name'
                  isInvalid={dataInputError.BOlasttName ? true : false}
                  invalidMessage={dataInputError.BOlasttName}
                />
              </Col>

              <Col md={12}>
                <TextInput
                  label='Phone No:'
                  name='Phone No'
                  value={BOphno}
                  onChange={(e) => setBOphno(e.target.value)}
                  placeholder='Phone No'
                  isInvalid={dataInputError.BOphno ? true : false}
                  invalidMessage={dataInputError.BOphno}
                />
              </Col>

              <Col md={12}>
                <Form.Group className='mb-3' controlId='BOCheckbox'>
                  <Form.Check
                    type='checkbox'
                    label='Same as Workspace Owner'
                    defaultChecked={pasteToBillingContact}
                    onChange={(e) => {
                      setpasteToBillingContact(!pasteToBillingContact);
                      if (e.target.checked) {
                        setBOmail(WOmail);
                        setBOfirstName(WOfirstName);
                        setBOlasttName(WOlasttName);
                        setBOphno(WOphno);
                      } else {
                        setBOmail(data.BOmail);
                        setBOfirstName(data.BOfirstName);
                        setBOlasttName(data.BOlasttName);
                        setBOphno(data.BOphno);
                      }
                    }}
                  />
                </Form.Group>
              </Col>

              <Col md={12} className='mt-3 mb-3'>
                <div className='account-request-view-title-container'>
                  <p className='account-request-view-title'>Billing Details</p>
                </div>
              </Col>

              <Col md={12}>
                <BillingCompanyDropdown
                  label='Billing Operating Company:'
                  defaultSelectMessage='Select Operating Company'
                  name='billingopco'
                  value={billingopco}
                  onChange={(e) => handleData(e)}
                  selectValues={responseData}
                  isInvalid={dataInputError.billingopco ? true : false}
                  invalidMessage={dataInputError.billingopco}
                />
              </Col>

              <Col md={12}>
                <BillingSubCompanyDropdown
                  label='Billing Sub Operating Company:'
                  defaultSelectMessage='Select Sub Operating Company'
                  name='billingsubopco'
                  value={billingsubopco}
                  onChange={(e) => handleData(e)}
                  selectValues={billingSubOpCompany}
                  isInvalid={dataInputError.billingsubopco ? true : false}
                  invalidMessage={dataInputError.billingsubopco}
                />
              </Col>

              <Col md={12}>
                <BillingDepartmentDropdown
                  label='Billing Department:'
                  defaultSelectMessage='Select Department'
                  name='billingdepartment'
                  value={billingdepartment}
                  onChange={(e) => handleData(e)}
                  selectValues={billingDepartment}
                  isInvalid={dataInputError.billingdepartment ? true : false}
                  invalidMessage={dataInputError.billingdepartment}
                />
              </Col>

              <Col md={12}>
                <StaticDropdown
                  label='Bill To:'
                  defaultSelectMessage='Select Bill To'
                  name='billto'
                  value={billto}
                  onChange={(e) => setbillto(e.target.value)}
                  selectValues={staticbillto}
                  isInvalid={dataInputError.billto ? true : false}
                  invalidMessage={dataInputError.billto}
                />
              </Col>

              {billto === 'Global IS (GISC)' && (
                <Col md={12}>
                  <TextInput
                    label='IO:'
                    name='io'
                    value={io}
                    onChange={(e) => setIo(e.target.value)}
                    placeholder='IO'
                    isInvalid={dataInputError.io ? true : false}
                    invalidMessage={dataInputError.io}
                  />
                </Col>
              )}
              {billto === 'Other Teams' && (
                <Col md={12}>
                  <TextInput
                    label='Cost Center:'
                    name='costcenter'
                    value={costcenter}
                    onChange={(e) => setCostcenter(e.target.value)}
                    placeholder='Cost Center'
                    isInvalid={dataInputError.costcenter ? true : false}
                    invalidMessage={dataInputError.costcenter}
                  />
                </Col>
              )}

              <Col md={12}>
                <StaticDropdown
                  label='Billing Region:'
                  defaultSelectMessage='Select Region'
                  name='billingRegion'
                  value={billingregion}
                  onChange={(e) => setbillingregion(e.target.value)}
                  selectValues={staticbillingRegion}
                  isInvalid={dataInputError.billingregion ? true : false}
                  invalidMessage={dataInputError.billingregion}
                />
              </Col>

              <Col md={12} className='mt-3 mb-3'>
                <div className='account-request-view-title-container'>
                  <p className='account-request-view-title'>
                    Workspace Details/Configurations
                  </p> 
                </div>
              </Col>

              <Col md={12}>
                <StaticDropdown
                  label='*Billing Region:'
                  defaultSelectMessage='Select Region'
                  name='billingRegion'
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  selectValues={regionData}
                  isInvalid={dataInputError.region ? true : false}
                  invalidMessage={dataInputError.region}
                />
              </Col>

              { region === 'us-east-1' ?

              <Col md={12}>
                <StaticDropdown
                  label={'Choose Directory:'}
                  defaultSelectMessage={'choose directory'}
                  name='directory'
                  value={directory}
                  onChange={(e) => {
                    setDirectory(e.target.value);
                  }}
                  selectValues={directoryData}
                  isInvalid={dataInputError.directory ? true : false}
                  invalidMessage={dataInputError.directory}
                />
              </Col>
            : null
            }

              <Col md={12}>
                <Form.Group className='mb-3'>
                  <Form.Label>Select Bundle</Form.Label>
                  <Form.Select
                    isInvalid={dataInputError.bundleId ? true : false}
                    onChange={(e) => setBundleId(e.target.value)}
                    value={bundleId}
                  >
                    <option value=''>Select Bundle</option>
                    {bundleOptions.map((data, index) => (
                      <option key={index} value={data.id}>
                        {data.name} - {data.desc}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>
                    {dataInputError.bundleId}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={12}>
                <p className='cidr-note text-center'>
                  Note: One user can have only one workspace allocated.
                </p>
              </Col>

              <Col md={12}>
                <Form.Group className='mb-3' controlId='disclaimerCheckbox'>
                  <Form.Check
                    type='checkbox'
                    label='I acknowledge that the workspace is owned by Sony and will adhere to the Sony usage guidelines and policies (I am trying to get a link for any posted guidelines) in effect including not storing any deemed confidential or secret information. I also acknowledge that the assigned workspace will not be used to process any non-authorized processes (i.e., blockchain and private web/file servers) and will not download/install/execute any software or call any API that is not related to the workload pertinent to the project or operations for which the workspace is intended to.'
                    defaultChecked={isDisclaimerAgreed}
                    onChange={(e) => {
                      setIsDisclaimerAgreed(!isDisclaimerAgreed);
                    }}
                  />
                </Form.Group>
              </Col>

              {/* Buttons */}
              <Col md={12} className='d-flex justify-content-end'>
                <ul className='px-0 btn-ul'>
                  <li>
                    <button
                      type='button'
                      className='teal-bordered-btn'
                      onClick={() => {
                        setShowCancelModal(true);
                      }}
                    >
                      Cancel
                    </button>
                  </li>
                  <li>
                    <button
                      type='submit'
                      className='teal-filled-btn'
                      disabled={isDisclaimerAgreed ? false : true}
                    >
                      Submit
                    </button>
                  </li>
                </ul>
              </Col>
            </Row>
          </Form>
        </div>
      )}

      {/* Cancel Modal */}
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={
            <EditCancel
              closeModalParent={closeModalCallback}
              closeEditForm={closeEditCallback}
            />
          }
        />
      )}

      {/* Submit Modal */}
      {formSubmitModal && (
        <CustomModal
          showModal={true}
          children={
            <div className='d-flex justify-content-center flex-column'>
              <p>Your request was submitted successfully</p>
              <p>Check your status using this ref#</p>
              <p>u-{formSubmitRefUid}</p>
              <p>
                <button
                  type='button'
                  className='teal-filled-btn'
                  onClick={() => {
                    closeEditParent(false);
                    dispatch({
                      type: WORKSPACE_REQUEST_RELOAD,
                      payload: true,
                    });
                    setFormSubmitModal(false);
                  }}
                >
                  Ok
                </button>
              </p>
            </div>
          }
        />
      )}
    </div>
  );
};

export default EditBox;
