import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';

import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

const CIDRApproveBox = ({
  show,
  uid,
  approveNoParent,
  userInfo,
  approvedParent,
  cidrServiceType,
}) => {
  // ! Modal Show State
  const [showApproveModal, setShowApproveModal] = useState(show);

  // ! Approved Yes Loading State
  const [approveLoading, setApproveLoading] = useState(false);

  // ! Approve Success State
  const [approveSuccess, setApproveSuccess] = useState(false);

  // ! Approve Yes Function
  function approveRequest() {
    setApproveLoading(true);
    const setIdToken = `{"idToken":{ "idToken": "${userInfo.idToken}"}}`;

    if (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: setIdToken,
        },
      };

      const apiUrl = `${process.env.REACT_APP_AZURE_CIDR_REQUEST_MODIFY}`;
      axios
        .post(
          `${apiUrl}?uid=${uid}&type=1&admingid=${userInfo.preferred_username}&adminemail=${userInfo.email}`,
          {},
          config
        )
        .then((response) => {
          setApproveLoading(false);
          setApproveSuccess(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  return (
    <>
      {approveSuccess === false && (
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={showApproveModal}
        >
          <Modal.Body className='text-center'>
            <p>
              <i className='bx bxs-check-circle cidr-check-mark'></i>
            </p>
            <p className='cidr-modal-success-msg'>
              Are you sure you want to approve this request?
            </p>

            {approveLoading ? (
              <Spinner animation='border' />
            ) : (
              <p>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    approveNoParent(false);
                  }}
                >
                  No
                </button>
                <button
                  type='button'
                  className='mx-3 teal-filled-btn'
                  onClick={() => {
                    approveRequest();
                  }}
                >
                  Yes
                </button>
              </p>
            )}
          </Modal.Body>
        </Modal>
      )}

      {approveSuccess && (
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={showApproveModal}
        >
          <Modal.Body className='text-center'>
            <p>
              <i className='bx bxs-check-circle cidr-check-mark'></i>
            </p>
            <p className='cidr-modal-success-msg'>Request Approved!</p>

            <p>
              <button
                type='button'
                className='mx-3 teal-filled-btn'
                onClick={() => {
                  setApproveSuccess(false);
                  setShowApproveModal(false);
                  approvedParent(true);
                }}
              >
                ok
              </button>
            </p>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default CIDRApproveBox;
