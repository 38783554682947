import React from 'react';
import { Modal } from 'react-bootstrap';

const ReasonMessage = ({ closeModalParent, message }) => {
  return (
    <Modal size='lg' centered show={true}>
      <Modal.Body className='text-center'>
        <p>
          <i className='fa fa-remove cidr-error-mark'></i>
        </p>
        <div className='d-flex justify-content-center flex-column'>
          <p>Reason</p>
          <p>{message}</p>
          <ul className='btn-ul'>
            <li>
              <button
                type='button'
                className='teal-filled-btn'
                onClick={() => {
                  closeModalParent(false);
                }}
              >
                Ok
              </button>
            </li>
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReasonMessage;
