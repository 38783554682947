import React from 'react';
import { Link } from 'react-router-dom';

const NewRequestSubmit = () => {
  return (
    <div className='d-flex justify-content-center flex-column'>
      <p>
        Your Cloud training request is submitted. Sony_Global_Cloud Team will
        contact you
      </p>
      <p>
        <button type='button' className='teal-filled-btn'>
          <Link to='/request-training-view'>Ok</Link>
        </button>
      </p>
    </div>
  );
};

export default NewRequestSubmit;
