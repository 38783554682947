import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NewRequestCancel = ({ closeModalParent }) => {
  const {t} = useTranslation();
  return (
    <div className='d-flex justify-content-center flex-column'>
       <p>{t("data_loss_warning")}</p>
      <p>{t("sure_to_continue")}</p>

      <ul className='btn-ul'>
        <li>
          <button
            type='button'
            className='teal-bordered-btn'
            onClick={() => {
              closeModalParent(false);
            }}
          >
           {t("no")}
          </button>
        </li>
        <li>
          <button type='button' className='teal-filled-btn'>
            <Link to='/request-training-view'>
              {t("yes")}
            </Link>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default NewRequestCancel;
