export const DefaultAWSData = {
  accountDetails: {
    accountNumber: "",
    drBackUp: "",
    region: "",
    crossBackUp: "",
    backUpPlan: "",
    accountName: "",
  },
  requestStatus: "",
  uid: "",
  aws_account_id: "",
  RequestActionTakenBy: "",
  submittedBy: "",
};

export const AWSReducer = (awsData, { type, payload }) => {
  switch (type) {
    case "UPDATE_BACKUP_REQUEST":
      return {
        ...awsData,
        accountDetails: {
          ...awsData.accountDetails,
          ...payload,
        },
      };
    case "UPDATE_COMMON_DETAILS":
      return {
        ...awsData,
        ...payload,
      };

    default:
      return awsData;
  }
};
