import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';

import {
  CS_Hub_AWS_Account_Request_Admin,
  Everyone_Role,
  CS_Hub_AWS_Account_CIDR_Security,
} from 'roles/roles';

const CIDRCancelBox = ({
  show,
  uid,
  cancelNoParent,
  userInfo,
  canceledParent,
  cidrServiceType,
}) => {
  // ! Modal Show State
  const [showCancelModal, setShowCancelModal] = useState(show);

  // ! Cancel Yes Loading State
  const [cancelLoading, setCancelLoading] = useState(false);

  // ! Cancel Success State
  const [cancelSuccess, setCancelSuccess] = useState(false);

  // ! Cancel Yes Function
  function cancelRequest() {
    setCancelLoading(true);
    const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

    if (
      userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
      userInfo.groups.includes(CS_Hub_AWS_Account_CIDR_Security) ||
      userInfo.groups.includes(Everyone_Role)
    ) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: setIdToken,
        },
      };

      const apiUrl = `${process.env.REACT_APP_AZURE_CIDR_REQUEST_MODIFY}`;
      axios
        .post(
          `${apiUrl}?uid=${uid}&type=3&admingid=${userInfo.preferred_username}&adminemail=${userInfo.email}`,
          {},
          config
        )
        .then((response) => {
          setCancelLoading(false);
          setCancelSuccess(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <>
      {cancelSuccess === false && (
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={showCancelModal}
        >
          <Modal.Body className='text-center'>
            <p>
              <i className='bx bxs-check-circle cidr-check-mark'></i>
            </p>
            <p className='cidr-modal-success-msg'>
              Are you sure you want to cancel this request?
            </p>

            {cancelLoading ? (
              <Spinner animation='border' />
            ) : (
              <p>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    cancelNoParent(false);
                  }}
                >
                  No
                </button>
                <button
                  type='button'
                  className='mx-3 teal-filled-btn'
                  onClick={() => {
                    cancelRequest();
                  }}
                >
                  Yes
                </button>
              </p>
            )}
          </Modal.Body>
        </Modal>
      )}

      {cancelSuccess && (
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={showCancelModal}
        >
          <Modal.Body className='text-center'>
            <p>
              <i className='bx bxs-check-circle cidr-check-mark'></i>
            </p>
            <p className='cidr-modal-success-msg'>Request Canceled!</p>

            <p>
              <button
                type='button'
                className='mx-3 teal-filled-btn'
                onClick={() => {
                  setCancelSuccess(false);
                  setShowCancelModal(false);
                  canceledParent(true);
                }}
              >
                ok
              </button>
            </p>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default CIDRCancelBox;
