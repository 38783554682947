import React, { useReducer, useState } from "react";
import { AWSReducer, DefaultAWSData } from "./aws-reducer";
import FormSteps from "./FormSteps";

const NewRequest = () => {
  let [step, setStep] = useState(0);
  const [instanceList, setInstanceList] = useState([]);
  const [formData, setFormData] = useReducer(AWSReducer, DefaultAWSData);
  let steps = FormSteps(
    step,
    setStep,
    formData,
    setFormData,
    instanceList,
    setInstanceList
  );
  return <>{steps[`${step}`].content}</>;
};

export default NewRequest;
