import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import ViewWidget from './ViewWidget';
import BillingWidget from './BillingWidget';
import TicketChart from './TicketChart';

import SpinnerLoader from 'components/Common/SpinnerLoader';

// Helper
import { postApiCall } from 'helper/postApiCall';

import { CLOUD_EYE_MANAGEMENT_VIEW_DETAIL } from 'redux/cloudeye-management/cloudEyeManagementConstant';

const CloudEyeMonitor = ({ currentDomainName }) => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // API State
  const [viewApiLoading, setViewApiLoading] = useState(true);
  const [viewApiResponseData, setViewApiResponseData] = useState([]);
  const [viewApiError, setViewApiError] = useState(null);

  const [billingApiLoading, setBillingApiLoading] = useState(true);
  const [billingApiResponseData, setBillingApiResponseData] = useState([]);
  const [billingApiError, setBillingApiError] = useState(null);

  const [ticketApiLoading, setTicketApiLoading] = useState(true);
  const [ticketApiResponseData, setTicketApiResponseData] = useState([]);
  const [ticketApiError, setTicketApiError] = useState(null);

  // Component State
  const [regionDropdown, setRegionDropdown] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');

  const idToken = `${userInfo.idToken}`;

  useEffect(() => {
    if (isMounted.current) {
      const fetchViewData = async () => {
        const viewApiUrl = `${process.env.REACT_APP_CLOUD_EYE_MANAGEMENT_VIEW_REQUEST}`;
        const viewRequestBody = { region: selectedRegion };

        setViewApiLoading(true);
        await postApiCall(viewApiUrl, idToken, viewRequestBody)
          .then((response) => {
            setViewApiResponseData(response.data);
            setViewApiLoading(false);
          })
          .catch((err) => {
            setViewApiError(
              'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
            );
            setViewApiLoading(false);
          });
      };

      const fetchBillingData = async () => {
        const billingApiUrl = `${process.env.REACT_APP_CLOUD_EYE_MANAGEMENT_BILLING}`;
        const billingRequestBody = { region: selectedRegion };

        setBillingApiLoading(true);
        await postApiCall(billingApiUrl, idToken, billingRequestBody)
          .then((response) => {
            setBillingApiResponseData(response.data);
            setBillingApiLoading(false);
          })
          .catch((err) => {
            setBillingApiError(
              'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
            );
            setBillingApiLoading(false);
          });
      };

      fetchViewData();
      fetchBillingData();
    }
  }, [selectedRegion]);

  useEffect(() => {
    const tempArray = [];
    for (var i = 0; i < userInfo.groups.length; i++) {
      const searchTerm = 'CS Hub-ManagementUser-';
      const indexOfFirst = userInfo.groups[i].indexOf(searchTerm);
      if (indexOfFirst > -1) {
        if (userInfo.groups[i] !== 'CS Hub-ManagementUser-Monitoring') {
          tempArray.push(userInfo.groups[i].split('-')[2]);
        }
      }
    }

    setRegionDropdown(tempArray);
    isMounted.current = true;
    return () => {
      setRegionDropdown([]);
    };
  }, []);

  return (
    <>
      <Row>
        <Col md={3} className='mb-4'>
          <Form.Label>Region</Form.Label>
          <Form.Select
            size='sm'
            onChange={(e) => {
              dispatch({
                type: CLOUD_EYE_MANAGEMENT_VIEW_DETAIL,
                payload: '',
              });
              setSelectedRegion(e.target.value);
            }}
          >
            <option value=''>Select</option>
            {regionDropdown.map((name, index) => (
              <option value={name} key={index}>
                {name}
              </option>
            ))}
          </Form.Select>
        </Col>
        {selectedRegion === '' && (
          <Col
            md={3}
            className='mb-3 d-flex justify-content-center align-items-center'
          >
            <div className='subtitle-text'>Please select the region</div>
          </Col>
        )}
      </Row>

      {selectedRegion !== '' && (
        <Row>
          <Col md={6} className='mb-3'>
            <TicketChart region={selectedRegion} />
          </Col>
          <Col md={6} className='mb-3'>
            <ViewWidget
              loading={viewApiLoading}
              data={viewApiResponseData}
              errorResponse={viewApiError}
              currentDomainName={currentDomainName}
            />
            <BillingWidget
              loading={billingApiLoading}
              data={billingApiResponseData}
              errorResponse={billingApiError}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default CloudEyeMonitor;
