import React, { useMemo, useState, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import countryList from 'react-select-country-list';
import { useTranslation } from 'react-i18next';

// Import Form Component
import TextInput from 'components/Forms/TextInput';
import CountryDropdown from 'components/Forms/CountryDropdown';
import CustomModal from 'components/Modal/CustomModal';

// Import Internal Components
import FormProgress from './FormProgress';
import NewRequestCancel from './NewRequestCancel';

// Validator
import { securityContactValidate } from '../../Validator/validator';

const SecurityContact = ({ step, setStep, formData, setFormData }) => {
  // Fetch Country List
  const countryData = useMemo(() => countryList().getData(), []);

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // Set Same Data
  const sameAsAccountData = (e) => {
    let getCheckBoxState = e.target.checked;
    if (getCheckBoxState === true) {
      setFormData({
        type: 'UPDATE_SECURITY_CONTACT',
        payload: {
          [`firstNameSC`]: formData.accountContact.firstNameAO,
        },
      });
      setFormData({
        type: 'UPDATE_SECURITY_CONTACT',
        payload: {
          [`lastNameSC`]: formData.accountContact.lastNameAO,
        },
      });
      setFormData({
        type: 'UPDATE_SECURITY_CONTACT',
        payload: {
          [`emailSC`]: formData.accountContact.emailAO,
        },
      });
      setFormData({
        type: 'UPDATE_SECURITY_CONTACT',
        payload: {
          [`countrySC`]: formData.accountContact.countryAO,
        },
      });
      setFormData({
        type: 'UPDATE_SECURITY_CONTACT',
        payload: {
          [`phoneNoSC`]: formData.accountContact.phoneNoAO,
        },
      });
    } else {
      setFormData({
        type: 'UPDATE_SECURITY_CONTACT',
        payload: {
          [`firstNameSC`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_SECURITY_CONTACT',
        payload: {
          [`lastNameSC`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_SECURITY_CONTACT',
        payload: {
          [`emailSC`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_SECURITY_CONTACT',
        payload: {
          [`countrySC`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_SECURITY_CONTACT',
        payload: {
          [`phoneNoSC`]: '',
        },
      });
    }
  };

  // handle onchange
  const handleData = ({ target }) => {
    setFormData({
      type: 'UPDATE_SECURITY_CONTACT',
      payload: { [target.name]: target.value },
    });
  };

  const handleContinue = (e) => {
    e.preventDefault();
    const errors = securityContactValidate(formData.securityContact);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setStep(step + 1);
    }
  };
  const {t}=useTranslation();
  return (
    <>
      <Row>
        <Col md={12} xs={12}>
        <FormProgress 
          accountDetails 
          accountContact 
          billingContact 
          />
        </Col>
      </Row>
      <Row className='px-3'>
        <Col md={12} xs={12}>
          <h5 className='page-content-title mb-4'>{t("security_contact")}</h5>
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        <Row className='px-3'>
          <Col md={6}>
            <TextInput
              label={"*"+t("first_name")+":"}
              name='firstNameSC'
              value={formData.securityContact.firstNameSC}
              onChange={(e) => handleData(e)}
              placeholder={t("first_name")}
              isInvalid={dataInputError.firstNameSC ? true : false}
              invalidMessage={dataInputError.firstNameSC}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label={"*"+t("last_name")+":"}
              name='lastNameSC'
              value={formData.securityContact.lastNameSC}
              onChange={(e) => handleData(e)}
              placeholder={t("last_name")}
              isInvalid={dataInputError.lastNameSC ? true : false}
              invalidMessage={dataInputError.lastNameSC}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label={"*"+t("email")+":"}
              name='emailSC'
              value={formData.securityContact.emailSC}
              onChange={(e) => handleData(e)}
              placeholder={t("email")}
              isInvalid={dataInputError.emailSC ? true : false}
              invalidMessage={dataInputError.emailSC}
            />
          </Col>
          <Col md={6}>
            <CountryDropdown
              label={"*"+t("country")+":"}
              name='countrySC'
              value={formData.securityContact.countrySC}
              onChange={(e) => handleData(e)}
              selectValues={countryData}
              isInvalid={dataInputError.countrySC ? true : false}
              invalidMessage={dataInputError.countrySC}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label={"*"+t("phone_number")+":"}
              name='phoneNoSC'
              value={formData.securityContact.phoneNoSC}
              onChange={(e) => handleData(e)}
              placeholder={t("phone_number")}
              isInvalid={dataInputError.phoneNoSC ? true : false}
              invalidMessage={dataInputError.phoneNoSC}
            />
          </Col>
        </Row>
        <Row className='px-3'>
          <Col>
            <Form.Group className='mb-3 '>
              <Form.Check
                type='checkbox'
                label={t("same as subscription contact")}
                className='checkbox-input'
                onChange={(e) => sameAsAccountData(e)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className='px-3'>
          <div className='col-md-5 offset-md-7'>
            <ul className='px-0 btn-ul'>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => setStep(step - 1)}
                >
                  {t("back")}
                </button>
              </li>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    setShowCancelModal(true);
                  }}
                >
                  {t("cancel")}
                </button>
              </li>
              <li>
                <button type='submit' className='teal-filled-btn'>
                  {t("save_continue")}
                </button>
              </li>
            </ul>
          </div>
        </Row>
      </Form>
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<NewRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

export default SecurityContact;
