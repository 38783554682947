export const awsWorkspaceValidator = ({
  WOgid,
  WOmail,
  WOfirstName,
  WOlasttName,
  WOphno,
  usergid,
  usermail,
  userfirstName,
  userlasttName,
  userphno,
  BOmail,
  BOfirstName,
  BOlasttName,
  BOphno,
  billingopco,
  billingsubopco,
  billingdepartment,
  billto,
  io,
  costcenter,
  billingregion,
  region,
  bundleId,
  directory,
}) => {
  let errors = {};

  if (!WOgid) {
    errors.WOgid = 'Global ID is required';
  }

  let isWOmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    WOmail
  );
  if (!WOmail) {
    errors.WOmail = 'Email address is required';
  } else if (isWOmail === false) {
    errors.WOmail = 'Email address is invalid';
  }

  if (!WOfirstName) {
    errors.WOfirstName = 'First Name is required';
  }

  if (!WOlasttName) {
    errors.WOlasttName = 'Last Name is required';
  }

  let isWOphno = /^(?=.*[0-9])[- +()0-9]+$/.test(WOphno);
  if (!WOphno) {
    errors.WOphno = 'Phone number is required';
  } else if (isWOphno === false) {
    errors.WOphno = 'Only digits, space, hyphens and plus is allowed';
  }

  // -------

  if (!usergid) {
    errors.usergid = 'Global ID is required';
  }

  let isusermail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    usermail
  );
  if (!usermail) {
    errors.usermail = 'Email address is required';
  } else if (isusermail === false) {
    errors.usermail = 'Email address is invalid';
  }

  if (!userfirstName) {
    errors.userfirstName = 'First Name is required';
  }

  if (!userlasttName) {
    errors.userlasttName = 'Last Name is required';
  }

  let isuserphno = /^(?=.*[0-9])[- +()0-9]+$/.test(userphno);
  if (!userphno) {
    errors.userphno = 'Phone number is required';
  } else if (isuserphno === false) {
    errors.userphno = 'Only digits, space, hyphens and plus is allowed';
  }

  // ---

  let isBOmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    BOmail
  );
  if (!BOmail) {
    errors.BOmail = 'Email address is required';
  } else if (isBOmail === false) {
    errors.BOmail = 'Email address is invalid';
  }

  if (!BOfirstName) {
    errors.BOfirstName = 'First Name is required';
  }

  if (!BOlasttName) {
    errors.BOlasttName = 'Last Name is required';
  }

  let isBOphno = /^(?=.*[0-9])[- +()0-9]+$/.test(BOphno);
  if (!BOphno) {
    errors.BOphno = 'Phone number is required';
  } else if (isBOphno === false) {
    errors.BOphno = 'Only digits, space, hyphens and plus is allowed';
  }

  // ---

  if (!billingopco) {
    errors.billingopco = 'Billing operating company is required';
  }

  if (!billingsubopco) {
    errors.billingsubopco = 'Billing sub operating company is required';
  }

  if (!billingdepartment) {
    errors.billingdepartment = 'Billing department is required';
  }

  if (!billto) {
    errors.billto = 'Bill to is required';
  }

  if (billto === 'Global IS (GISC)') {
    if (!io) {
      errors.io = 'IO is required';
    }
  } else {
    if (!costcenter) {
      errors.costcenter = 'Cost center is required';
    }
  }

  if (!billingregion) {
    errors.billingregion = 'Billing Region is required';
  }

  if (!region) {
    errors.region = 'Region is required';
  }

  if (!bundleId) {
    errors.bundleId = 'Bundle ID is required';
  }

  if (region === 'us-east-1' && !directory) {
    errors.directory = 'Directory is required';
  }
  return errors;
};
