import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';

// Tag Input
import ReactTagInput from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import CustomModal from 'components/Modal/CustomModal';
import TextInput from 'components/Forms/TextInput';
import DangerAlert from 'components/Message/DangerAlert';

import Cancel from './Cancel';
import Submit from './Submit';

// Validator
import { managedAdValiadtor } from './validator';

// Helper
import { getApiCall } from 'helper/getApiCall';
import { postApiCall } from 'helper/postApiCall';

// Static Data
import { awsRegionData } from 'pages/CIDR/region-data';

const AddRequest = () => {
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // Common Variable
  const idToken = `${userInfo.idToken}`;

  // React Select Option
  const customStyles = {
    control: (base) => ({
      ...base,
      border: '1px solid red',
    }),
  };

  // Data Input Error
  const [dataInputError, setDataInputError] = useState({});

  // Common State
  const [loading, setLoading] = useState(true);

  // Component State
  const [showCancel, setShowCancel] = useState(false);
  const [showSubmit, setshowSubmit] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancel(value);
  }, []);

  // Form State
  const [accountNumber, setAccountNumber] = useState('');
  const [region, setRegion] = useState('');
  const [vpcCIDR, setVpcCIDR] = useState('');
  const [opco, setOpco] = useState('');
  const [primaryContact, setPrimaryContact] = useState('');
  const [adminAccess, setAdminAccess] = useState('no');
  const [estimatedAppUsers, setEstimatedAppUsers] = useState(`<50`);
  const [usersList, setUsersList] = useState([]);

  const [formSubmitMessage, setFormSubmitMessage] = useState(null);

  // Form Submit
  function formSubmit() {
    const formApiUrl = `${process.env.REACT_APP_MANAGEDAD_REQUEST_ADD}`;
    const errors = managedAdValiadtor({
      accountNumber,
      region,
      vpcCIDR,
      opco,
      primaryContact,
      adminAccess,
      estimatedAppUsers,
      usersList,
    });
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setLoading(true);
      const currentEpochTime = Date.now();
      const formRequestBody = {
        uid: `u-${currentEpochTime}`,
        submittedBy: `${userInfo.email}`,
        accountnumber: `${accountNumber}`,
        region: `${region}`,
        cidr: `${vpcCIDR}`,
        opco: `${opco}`,
        primaryContact: `${primaryContact}`,
        adminAccess: `${adminAccess}`,
        ...(adminAccess === 'yes' && {
          estimatedUsers: estimatedAppUsers,
        }),
        ...(adminAccess === 'yes' && {
          users: usersList,
        }),
        applicationName: 'appname',
        requestStatus: 'pending',
      };

      postApiCall(formApiUrl, idToken, formRequestBody)
        .then((response) => {
          if (response.data.message !== '') {
            setFormSubmitMessage(response.data.message);
          } else {
            setFormSubmitMessage(
              'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
            );
          }
          setshowSubmit(true);
          setLoading(false);
        })
        .catch((err) => {
          setFormSubmitMessage(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setshowSubmit(true);
          setLoading(false);
        });
    }
  }

  // Account Number State & API State
  const [accountNumberArray, setaccountNumberArray] = useState([]);
  const [accountsApiError, setAccountsApiError] = useState(null);

  const accountApiUrl = `${process.env.REACT_APP_AWS_CIDR_ADD_REQUEST_ACCOUNT_NUMBER_NAME}`;

  useEffect(() => {
    setLoading(true);

    getApiCall(accountApiUrl, idToken)
      .then((response) => {
        const accountNumberOptions = response.data
          .filter((f) => f !== '')
          .map((d) => ({
            value: d.split(' - ')[0],
            label: d.split(' - ')[0],
          }));

        setaccountNumberArray(accountNumberOptions);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAccountsApiError(
          'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
        );
      });

    return () => {
      setaccountNumberArray([]);
    };
  }, []);

  return (
    <>
      <Row>
        <Col className='mb-2'>
          <h5 className='page-content-title'>Request New Connection</h5>
        </Col>
      </Row>
      {loading && (
        <Row>
          <Col>
            <div className='d-flex justify-content-center'>
              <SpinnerLoader />
            </div>
          </Col>
        </Row>
      )}
      {loading === false && accountsApiError !== null && (
        <DangerAlert message={accountsApiError} />
      )}
      {loading === false && accountsApiError === null && (
        <>
          <Row>
            <Col md={6}>
              <Form.Group controlId='accountNo'>
                <Form.Label>Select Account:</Form.Label>
                <Select
                  name='accounNumber'
                  placeholder='Account Number'
                  searchable={true}
                  value={{ label: accountNumber }}
                  isClearable={true}
                  onChange={(value) => {
                    const accNum = value !== null ? value.value : '';
                    setAccountNumber(accNum);
                  }}
                  options={accountNumberArray}
                  styles={dataInputError.accountNumber && customStyles}
                />

                <p className='custom-error-message'>
                  {dataInputError.accountNumber}
                </p>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-2'>
              <Form.Group controlId='selectRegion'>
                <Form.Label>Select Region</Form.Label>
                <Form.Select
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  isInvalid={dataInputError.region ? true : false}
                >
                  <option value=''>None</option>
                  {awsRegionData.map((data, index) => (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  {dataInputError.region}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <TextInput
                label='Enter VPC CIDR'
                placeholder='Enter VPC CIDR'
                value={vpcCIDR}
                onChange={(e) => setVpcCIDR(e.target.value)}
                isInvalid={dataInputError.vpcCIDR ? true : false}
                invalidMessage={dataInputError.vpcCIDR}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-2'>
              <Form.Group controlId='selectRegion'>
                <Form.Label>Select OPCO</Form.Label>
                <Form.Select
                  value={opco}
                  onChange={(e) => setOpco(e.target.value)}
                  isInvalid={dataInputError.opco ? true : false}
                >
                  <option value=''>None</option>
                  <option value='SCA'>SCA</option>
                  <option value='GISC'>GISC</option>
                  <option value='SOMC'>SOMC</option>
                  <option value='SIE'>SIE</option>
                  <option value='FSMC'>FSMC</option>
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  {dataInputError.opco}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-2'>
              <TextInput
                label='Primary Contact:'
                name='primaryContact'
                value={primaryContact}
                onChange={(e) => setPrimaryContact(e.target.value)}
                placeholder='Mail Id'
                isInvalid={dataInputError.primaryContact ? true : false}
                invalidMessage={dataInputError.primaryContact}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Label>Admin Access</Form.Label>
              <div className='mb-3'>
                <Form.Check
                  inline
                  label='No'
                  name='adminAccessGroup'
                  type='radio'
                  id={`adminAccessGroup-1`}
                  value='no'
                  checked={adminAccess === 'no'}
                  onChange={(e) => setAdminAccess(e.currentTarget.value)}
                />
                <Form.Check
                  inline
                  label='Yes'
                  name='adminAccessGroup'
                  type='radio'
                  id={`adminAccessGroup-1`}
                  value='yes'
                  checked={adminAccess === 'yes'}
                  onChange={(e) => setAdminAccess(e.currentTarget.value)}
                />
              </div>
            </Col>
          </Row>
          {adminAccess === 'yes' && (
            <>
              <Row>
                <Col md={6}>
                  <Form.Label>Estimated App Users</Form.Label>
                  <div className='mb-3'>
                    <Form.Check
                      inline
                      label={`<50`}
                      name='estimatedAppUsers'
                      type='radio'
                      id={`estimatedAppUsersGroup-1`}
                      value={`<50`}
                      checked={estimatedAppUsers === `<50`}
                      onChange={(e) =>
                        setEstimatedAppUsers(e.currentTarget.value)
                      }
                    />
                    <Form.Check
                      inline
                      label='51-250'
                      name='estimatedAppUsers'
                      type='radio'
                      id={`estimatedAppUsersGroup-1`}
                      value='51-250'
                      checked={estimatedAppUsers === '51-250'}
                      onChange={(e) =>
                        setEstimatedAppUsers(e.currentTarget.value)
                      }
                    />
                    <Form.Check
                      inline
                      label={`>250`}
                      name='estimatedAppUsers'
                      type='radio'
                      id={`estimatedAppUsersGroup-1`}
                      value={`250`}
                      checked={estimatedAppUsers === `250`}
                      onChange={(e) =>
                        setEstimatedAppUsers(e.currentTarget.value)
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} className='mb-2'>
                  <Form.Label>Users List</Form.Label>
                  <ReactTagInput
                    tags={usersList}
                    onChange={(newTags) => setUsersList(newTags)}
                    placeholder='Users List ( Enter GID )'
                    removeOnBackspace={true}
                    maxTags={
                      estimatedAppUsers === `<50`
                        ? 49
                        : estimatedAppUsers === `51-250`
                        ? 250
                        : estimatedAppUsers === `>250`
                    }
                  />
                  <div className='custom-error-message-text'>
                    {dataInputError.usersList}
                  </div>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col md={6} className='d-flex flex-row mt-3'>
              <ul className='btn-ul'>
                <li>
                  <button
                    type='button'
                    className='teal-bordered-btn'
                    onClick={() => {
                      setShowCancel(true);
                    }}
                  >
                    Cancel
                  </button>
                </li>
                <li>
                  <button
                    type='button'
                    className='teal-filled-btn'
                    onClick={() => {
                      formSubmit();
                    }}
                  >
                    Submit
                  </button>
                </li>
              </ul>
            </Col>
          </Row>

          {showCancel && (
            <CustomModal
              showModal={true}
              children={<Cancel closeModalParent={closeModalCallback} />}
            />
          )}

          {showSubmit && (
            <CustomModal
              showModal={true}
              children={<Submit message={formSubmitMessage} />}
            />
          )}
        </>
      )}
    </>
  );
};

export default AddRequest;
