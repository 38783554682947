import React from "react";

const CrossBackUpTextComponent = () => (
  <>
    <ul className="warning-ul font-fs">
      Cross account backup is optional and works for EC2, RDS, EFS and S3. Cross
      account backup for DynamoDB is not supported. Before enabling cross
      account, note the followings:
      <li>
        For EC2, if the volume is encrypted with default KMS, cross account
        backup will NOT work. For cross account backup of EC2, the volume must
        be encrypted with CMK.
      </li>
      <li>
        For RDS, it must be encrypted with CMK. Since RDS encryption cannot be
        changed after creation. Do not opt-in unless it is encrypted with CMK or
        you are planning to backup a new RDS with CMK encryption.
      </li>
      <li>
        For EFS and S3, default AWS server side encryption is supported for
        cross account backup. However, in the case of S3, versioning must be
        enabled.
      </li>
    </ul>
  </>
);

export default CrossBackUpTextComponent;
