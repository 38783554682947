import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// Import Form Component
import TextInput from "components/Forms/TextInput";
import CountryDropdown from "components/Forms/CountryDropdown";

// Import Common Component
import CustomModal from "components/Modal/CustomModal";

// Import Internal Components

import SpinnerLoader from "components/Common/SpinnerLoader";
import FormProgress from "./FormProgress";
import NewRequestCancel from "./NewRequestCancel";

// Validator
import { accountContactValidate } from "../../Validator/validator";

// Helper
import { postApiCall } from "helper/postApiCall";

// Roles
import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";
import EnableAccount from "./EnableWindow/EnableAccount";
import { SELECTED_INSTANCES } from "redux/aws-host-patch-management/patchAccountConstant";

// const dummyData = [
//   "813699041141 - gisc_am_nonprod",
//   "307168263208 - gisc_sisense_production",
//   "180382065785 - smp_dwa-data_development",
//   "629406808975 - gisc_dealer-source_production",
//   "158425239653 - seu_ultrawideband_poc",
// ];
const instancesDummy = [
  {
    InstanceId: "i-038e2a5318d47fb1f",
    DisplayName: "Gitlab FrontEndInstance",
    "SSM Status": "running",
  },
  {
    InstanceId: "i-00d61982c78cfbcfd",
    DisplayName: "Gitlab FrontEndInstance",
    "SSM Status": "running",
  },
];

const GISAccount = ({
  step,
  setStep,
  formData,
  setFormData,
  instanceList,
  setInstanceList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // ID Token
  const idToken = `${userInfo.idToken}`;

  // React Select Option
  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid red",
    }),
  };
  // Fetch Country List
  const countryData = useMemo(() => countryList().getData(), []);

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [accountToDisable, setAccountToDisable] = useState(null);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [loadingDropdown, setLoadingDropdown] = useState(false);
  const [getAccountsApiError, setGetAccountsApiError] = useState(null);
  const [accountNumberNameData, setAccountNumberNameData] = useState([]);
  const [isEnableWindow, setEnableWindow] = useState(false);
  const [selectedAccNumber, setSelectedAccNumber] = useState(null);

  // const [instanceList, setInstanceList] = useState([]);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // handle onchange
  const handleData = ({ target }) => {
    setFormData({
      type: "UPDATE_ACCOUNT_CONTACT",
      payload: { [target.name]: target.value },
    });
  };

  const handleEditWindowClose = () => {
    setEnableWindow(false);
    setShowCancelModal(false);
    fetchTableData();
  };

  // API Variables
  const apiUrl = `${process.env.REACT_APP_SSM_PATCHING_GET_ACCOUNTS_URL}`;

  const requestBody = {
    email: `${userInfo?.email}`,
    user_type: `${
      userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)
        ? "admin"
        : "user"
    }`,
  };

  // Account Number Useeffect

  const fetchTableData = useCallback(() => {
    setGetAccountsApiError(null);
    setLoadingDropdown(true);
    setAccountNumberNameData([]);

    postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        setAccountNumberNameData(tableDataFormatter(response?.data));
        // setAccountNumberNameData(tableDataFormatter(dummyData));
        setLoadingDropdown(false);
      })
      .catch((err) => {
        setLoadingDropdown(false);
        setGetAccountsApiError(
          "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
        );
      });
  }, [apiUrl, idToken, requestBody]);

  const getInstancesApi = `${process.env.REACT_APP_SSM_PATCHING_GET_INSTANCES_URL}`;
  const disableRequestApi = `${process.env.REACT_APP_SSM_PATCHING_DISABLE_REQUEST_SUBMIT}`;

  const disableAccount = (accountDetails) => {
    setLoadingDropdown(true);
    const disableRequestBody = {
      accountnumber: `${accountDetails?.accountnumber}`,
    };
    postApiCall(disableRequestApi, idToken, disableRequestBody)
      .then((response) => {
        setShowDisableModal(false);
        setLoadingDropdown(false);
        setAccountToDisable(null);
      })
      .catch((err) => {
        setLoadingDropdown(false);
        setGetAccountsApiError("Something went wrong");
      });
  };
  const fetchInstances = useCallback(
    (row) => {
      dispatch({
        type: SELECTED_INSTANCES,
        payload: [],
      });
      setLoadingDropdown(true);
      setGetAccountsApiError(null);
      setInstanceList([]);
      setSelectedAccNumber(null);
      const requestBody = { accountnumber: `${row?.accountnumber}` };

      if (row?.bool.toUpperCase() === "TRUE") {
        // setShowDisableModal(true);
        // setAccountToDisable(row);
      } else {
        // setInstanceList(instancesDummy);
        // setSelectedAccNumber(row);
        // setEnableWindow(true);
        // setLoadingDropdown(false);
        postApiCall(getInstancesApi, idToken, requestBody)
          .then((response) => {
            setInstanceList(response?.data?.body?.items);
            const allInstanceIds = response?.data?.body?.items.map(
              (item) => item?.InstanceId
            );
            setFormData({
              type: "UPDATE_INSTALLATION_SCHEDULE",
              payload: {
                [`startDateHour`]: "",
                [`startDateMinute`]: "",
                [`week`]: "",
                [`dow`]: "",
                [`selectedAccount`]: row,
              },
            });
            dispatch({
              type: SELECTED_INSTANCES,
              payload: allInstanceIds,
            });
            // setInstanceList(instancesDummy);
            setSelectedAccNumber(row);
            // setEnableWindow(true);
            setLoadingDropdown(false);
            setStep(step + 1);
          })
          .catch((err) => {
            setLoadingDropdown(false);
            setGetAccountsApiError(
              "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
            );
          });
      }
    },
    [getInstancesApi, idToken, isEnableWindow]
  );

  useEffect(() => {
    fetchTableData();
    return () => {
      setAccountNumberNameData([]);
    };
  }, []);

  const defaultSorted = [
    // {
    //   dataField: "accountnumber",
    //   order: "desc",
    // },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {t("showing_results", { from, to, size })}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: accountNumberNameData?.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  // ! Option Part
  const [options, setOptions] = useState({
    showOption: null,
    selectedOptionUID: null,
    selectedOptionData: {},
  });

  const columns = [
    {
      dataField: "accountnumber",
      text: t("account_number").toUpperCase(),
      sort: true,
    },
    {
      dataField: "accountname",
      text: t("account_name").toUpperCase(),
      sort: true,
    },

    {
      isDummyField: true,
      text: "",
      events: {
        onClick: () => {},
      },
      formatter: (cell, row) => (
        <>
          <Button
            disabled={row?.bool.toUpperCase() === "TRUE"}
            onClick={() => {
              fetchInstances(row);
            }}
            type="button"
            id="custom-switch"
            className="toggle-switch-gis"
            // label={`Toggle to ${
            //   row?.bool.toUpperCase() === "TRUE" ? "Opt-Out" : "Opt-In"
            // }`}
          >
            Enroll
          </Button>
        </>
      ),
      Sty: { textAlign: "center" },
      headerFormatter: (cellContent, row) => {
        const handleMultiSelect = (e, row) => {};
        return (
          <>
            <p className="patch-actions">Action</p>
          </>
        );
      },
      formatExtraData: options,
    },
  ];

  const tableDataFormatter = (responseData) => {
    const dataFormatter = responseData?.map((item) => {
      const getStringData = item?.split(" - ");
      const formattedObj = {};
      formattedObj.accountnumber = getStringData[0];
      formattedObj.accountname = getStringData[1];
      formattedObj.bool = getStringData[2] ? getStringData[2] : "False";
      return formattedObj;
    });
    return dataFormatter;
  };

  //close side window
  const closeCallback = useCallback((value) => {
    setEnableWindow(value);
    fetchTableData();
  }, []);

  return (
    <>
      <Row>
        <Col md={12} xs={12}>
          <FormProgress />
        </Col>
      </Row>
      {/* <Row className="px-3">
        <Col md={12} xs={12}>
          <h5 className="page-content-title mb-4">
            {t("GIS Account Selection")}
          </h5>
        </Col>
      </Row> */}
      {loadingDropdown && (
        <Row>
          <Col>
            <div className="d-flex justify-content-center">
              <SpinnerLoader />
            </div>
          </Col>
        </Row>
      )}
      {loadingDropdown === false && getAccountsApiError === null && (
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="id"
          columns={columns}
          data={accountNumberNameData}
        >
          {({ paginationProps, paginationTableProps }) => (
            <>
              <ToolkitProvider
                keyField="accountnumber"
                columns={columns}
                data={accountNumberNameData}
                search
              >
                {(toolkitProps) => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md={4} className="d-flex align-items-center mb-2">
                        <h5 className="page-content-title mb-0">
                          {t("AWS Patch Automation")}
                        </h5>
                      </Col>

                      <Col md={1} className="mb-2">
                        <button
                          className="teal-bordered-btn w-100"
                          type="button"
                          onClick={() => {
                            fetchTableData();
                          }}
                        >
                          <i className="fa fa-refresh"></i>
                        </button>
                      </Col>

                      <Col md={4} className="mb-2">
                        <div className="search-input-group">
                          <i className="fa fa-search"></i>
                          <SearchBar
                            {...toolkitProps.searchProps}
                            className="custom-search-input-form-control"
                            placeholder={t("search")}
                          />
                        </div>
                      </Col>
                      <Col md={2} className="mb-2">
                        <Link to="/aws-host-patch-request">
                          <button
                            className="teal-filled-btn w-100"
                            type="button"
                            // onClick={() => {
                            // bulkUpdate(false);
                            // }}
                          >
                            {t("back")}
                          </button>
                        </Link>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="9" className="mt-4">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={
                              "custom-table-head custom-pointer GIS-Account"
                            }
                            bodyClasses={"custom-table-body"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-3">
                      <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                        <div>
                          <PaginationTotalStandalone {...paginationProps} />
                        </div>
                        <div>
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </>
          )}
        </PaginationProvider>
      )}
      {loadingDropdown === false && accountNumberNameData?.length === 0 && (
        <div className="d-flex justify-content-center align-items-center">
          No Data to Display
        </div>
      )}
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={
            <NewRequestCancel
              closeModalParent={closeModalCallback}
              handleEditWindowClose={handleEditWindowClose}
            />
          }
        />
      )}

      {/* {isEnableWindow && selectedAccNumber && (
        <EnableAccount
          data={accountNumberNameData}
          instanceList={instanceList}
          selectedData={selectedAccNumber}
          cloudTitle="AWS"
          closeEditParent={closeCallback}
          handleEditWindowClose={handleEditWindowClose}
        />
      )} */}
      {showDisableModal && (
        <CustomModal
          showModal={true}
          children={
            <NewRequestCancel
              closeModalParent={closeModalCallback}
              disableAccount={disableAccount}
              accountToDisable={accountToDisable}
            />
          }
        />
      )}
    </>
  );
};

export default GISAccount;
