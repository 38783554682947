export const billingInfoValidate = (values) => {
    let errors = {};
    if (!values.billingopco) {
      errors.billingopco = 'Billing operating company is required';
    }
    if (!values.billingSubOpco) {
      errors.billingSubOpco = 'Billing sub operating company is required';
    }
  
    if (!values.billingDepartment) {
      errors.billingDepartment = 'Billing department is required';
    }
  
    if (!values.billingRegion) {
      errors.billingRegion = 'Billing region is required';
    }
  
    if (!values.maxSpend) {
      errors.maxSpend = 'Max spend is required';
    }
    if (!values.billto) {
      errors.billto = 'Bill to is required';
    }
  
    if (values.billto === 'Global IS (GISC)') {
      if (!values.io) {
        errors.io = 'IO is required';
      }
    } else {
      if (!values.costcenter) {
        errors.costcenter = 'Cost center is required';
      }
    }
  
    return errors;
  };

  export const accountDetailsValidate = (values) => {
    let errors = {};
    if (!values.opco) {
      errors.opco = 'Operating company is required';
    }
    if (!values.subopco) {
      errors.subopco = 'Sub operating company is required';
    }
  
    if (!values.department) {
      errors.department = 'Department is required';
    }
  
    return errors;
  };
  