import axios from 'axios';

export const getApiCall = (apiUrl, token) => {
  const idToken = `{ "idToken":{ "idToken":"${token}"}}`;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: idToken,
    },
  };
  return axios
    .get(apiUrl, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};
