import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import MenuTabs from '../MenuTabs';

import Prisma from './Prisma';
import TrendMicro from './TrendMicro';

import splunkIcon from 'icons/splunk.svg';

import { CLOUD_EYE_APPLICATION_NAME } from 'redux/cloudeye-user/cloueyeUserConstant';

const Security = () => {
  const isMounted = useRef(false);
  const dispatch = useDispatch();

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const cloudEye = useSelector((state) => state.cloudEye);
  const { cloudEyeApplicationName } = cloudEye;

  // Application Bar State
  const [toggleApplicationBar, setToggleApplicationBar] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 767) {
      setToggleApplicationBar(true);
    }
  };

  const [userApplicationDetails, setUserApplicationDetails] = useState([]);
  const [selectedApplicationName, setSelectedApplicationName] = useState('');

  // Dependency Load
  useEffect(() => {
    if (isMounted.current) {
    }
    return () => {};
  }, [selectedApplicationName, dispatch]);

  useEffect(() => {
    if (isMounted.current) {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [toggleApplicationBar]);

  // 1st Load
  useEffect(() => {
    let userApplicationDetailsArray = [];
    for (var i = 0; i < userInfo.groups.length; i++) {
      const searchTerm = 'Monitoring';
      const indexOfFirst = userInfo.groups[i].indexOf(searchTerm);
      if (indexOfFirst === 7) {
        let temptext = userInfo.groups[i].substring(
          18,
          userInfo.groups[i].length
        );
        userApplicationDetailsArray.push(temptext);
      }
    }
    setUserApplicationDetails(userApplicationDetailsArray);
    setSelectedApplicationName(cloudEyeApplicationName);

    isMounted.current = true;
  }, []);
  const {t}=useTranslation();
  return (
    <Row>
      {toggleApplicationBar ? (
        <Col md={2} className='px-0 custom-application-bar'>
          <div
            className='custom-arrow-icon'
            onClick={() => setToggleApplicationBar(false)}
          >
            <i className='fa fa-angle-left'></i>
          </div>
          <div className='application-name-container'>
            <span className='subtitle-text px-1'>{t('application_details')}</span>
            <Form.Group className='mt-3'>
              <Form.Select
                value={
                  userApplicationDetails.includes(selectedApplicationName)
                    ? selectedApplicationName
                    : ''
                }
                onChange={(e) => {
                  setSelectedApplicationName(e.target.value);
                  dispatch({
                    type: CLOUD_EYE_APPLICATION_NAME,
                    payload: `${e.target.value}`,
                  });
                }}
              >
                <option value=''>{t('select_an_application')}</option>
                {userApplicationDetails.map((data, index) => (
                  <option key={index} value={data.trim()}>
                    {data.trim()}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
        </Col>
      ) : (
        <Col md={1} className='px-0 custom-application-bar'>
          <div
            className='custom-arrow-icon'
            onClick={() => setToggleApplicationBar(true)}
          >
            <i className='fa fa-angle-right'></i>
          </div>
          <div className='application-name-container'>
            <h5 className='application-bar-text-rotate'>{t('application_details')}</h5>
          </div>
        </Col>
      )}

      <Col md={toggleApplicationBar ? 10 : 11} className='px-3'>
        <MenuTabs />
        <Row>
          {selectedApplicationName === '' && (
            <Col
              md={12}
              className='d-flex justify-content-center align-items-center '
            >
              {t("please_select_the_application")}
            </Col>
          )}
          {selectedApplicationName !== '' && (
            <>
              <Col md={6} className='mb-3'>
                <Prisma
                  applicationName={selectedApplicationName}
                  userInfo={userInfo}
                />
              </Col>
              <Col md={6} className='mb-3'>
                <TrendMicro
                  applicationName={selectedApplicationName}
                  userInfo={userInfo}
                />
              </Col>
              <Col md={12}>
                <div className='px-2'>
                  <img src={splunkIcon} alt='Splunk' className='splunk-img' />
                  <a
                    href='https://status.aws.amazon.com/'
                    className='splunk-link'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {t("click_here_for_more_details")+"..."}
                  </a>
                </div>
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default Security;
