import SpinnerLoader from "components/Common/SpinnerLoader";
import useInitScreen from "components/InitPage";
import React from "react";
import Select from "react-select";

import {
  useApplicationListApiForCloudEye,
  useCreateRequestForAppsInCloudEye,
} from "./dataloader";
import { useOktaAuth } from "@okta/okta-react";
import { useFormik } from "formik";
import { Col, Container, Form, Row } from "react-bootstrap";
import TextInput from "components/Forms/TextInput";
import StaticDropdown from "components/Forms/StaticDropdown";
import * as yup from "yup";
import { Link } from "react-router-dom";
import CloudEyeIcon from "components/Common/CloudEyeIcon";

export default function AddMain() {
  useInitScreen();
  return (
    <Container fluid>
      <Row>
        <Col md={2} className="px-0">
          <div className="page-content-sidebar-container">
            <ul className="passport-sidebar-logo-list">
              <li>
                <CloudEyeIcon />
                <span className="passport-sidebar-title px-2">CloudEye</span>
              </li>
            </ul>
            <ul className="page-content-sidebar-link-list p-0">
              <li className={"page-content-sidebar-link-inactive"}>
                <Link to={`/view-cloud-eye-request`}>View Access</Link>
              </li>
              <li className={"page-content-sidebar-link-active"}>
                <Link to={`/create-cloud-eye-request`}>Add Access</Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col md={10}>
          <CreateCloudEyeRequests />
        </Col>
      </Row>
    </Container>
  );
}

export function CreateCloudEyeRequests() {
  const { data: appsList, isFetching } = useApplicationListApiForCloudEye();

  return (
    <div>
      {isFetching && <SpinnerLoader />}
      {!!appsList.length && <CreateRequestForm appsList={appsList} />}
    </div>
  );
}

const RoleOptions = [
  { label: "ReadOnly", value: "ReadOnly" },
  { label: "Admin", value: "Admin" },
];

const validationSchema = yup.object({
  Email: yup.string().email().required("Required"),
  Application: yup.array().of(yup.object()).required("Required"),
  Role: yup.string().required("Required"),
});

function CreateRequestForm({ appsList }) {
  const { mutateAsync: create, isLoading } =
    useCreateRequestForAppsInCloudEye();
  const { authState } = useOktaAuth();
  const ApplicationOptions = React.useMemo(() => {
    return appsList.map((item) => ({ label: item, value: item }));
  }, [appsList]);

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    errors,
    touched,
    resetForm,
  } = useFormik({
    validationSchema,
    initialValues: {
      Email: "",
      Application: "",
      Role: "",
    },
    onSubmit: async (values) => {
      await create({
        ...values,
        Application: values.Application.map((item) => item.value),
        idToken: authState.idToken.idToken,
      });
      await resetForm();
    },
  });

  return (
    <div>
      <div className="custom-card mb-4">
        {isLoading && <SpinnerLoader />}
        <div className="view-edit-box-top-bar">
          <div className="view-edit-box-back-title-container">
            <div>
              <h5 className="view-edit-box-title">Add CloudEye User access</h5>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <Row>
            <Col md={6}>
              <TextInput
                label="Email:"
                {...getFieldProps("Email")}
                isInvalid={errors.Email && touched.Email}
                invalidMessage={errors.Email}
                placeholder="Email"
              />
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Application:</Form.Label>
                <Select
                  isMulti
                  {...getFieldProps("Application")}
                  onChange={(selectedValues) => {
                    setFieldValue("Application", selectedValues);
                  }}
                  options={ApplicationOptions}
                />

                {touched.Application && errors.Application && (
                  <div style={{ color: "#dc3545" }}>{errors.Application}</div>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <StaticDropdown
                label="Role:"
                {...getFieldProps("Role")}
                defaultSelectMessage="Select Value"
                selectValues={RoleOptions}
                isInvalid={errors.Role && touched.Role}
                invalidMessage={errors.Role}
              />
            </Col>
          </Row>
          <Row className="px-3">
            <Col md={12} className="d-flex justify-content-end">
              <ul className="px-0 btn-ul">
                <li>
                  <button
                    type="button"
                    className="teal-bordered-btn"
                    onClick={() => {
                      resetForm();
                    }}
                  >
                    Cancel
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="teal-filled-btn"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Submit
                  </button>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
