export const toolExceptionValidate = (values) => {
  let errors = {};

  if (!values.accountNumber) {
    errors.accountNumber = "Account Number is required";
  }

  if (!values.BIARating) {
    errors.BIARating = "BIA Rating is required";
  }

  if (!values.accountName) {
    errors.accountName = "Account Name is required";
  }

  if (!values.projectName) {
    errors.projectName = "Project Name is required";
  }
  let isEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    values.accountOwnerName
  );
  if (!values.accountOwnerName) {
    errors.accountOwnerName = "Email address is required";
  } else if (isEmail === false) {
    errors.accountOwnerName = "Email address is invalid";
  }

  if (!values.exceptionDetail) {
    errors.exceptionDetail = "Exception Detail is required";
  }
  if (!values.exceptionTimeline) {
    errors.exceptionTimeline = "Exception Timeline is required";
  }
  if (!values.exceptionCategory) {
    errors.exceptionCategory = "Exception Category is required";
  }
  if (!values.region) {
    errors.region = "Region is required";
  }
  return errors;
};
