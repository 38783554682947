import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from 'components/Common/Loader';
import AlertMessage from 'components/Common/AlertMessage';

import { postApiCall } from 'helper/postApiCall';

const Approve = (
  { 
    yesParent,
    closeModalParent,
    status,
    uid,
     
      }
  ) => {
  // Redux State
  const isHostIsProd = useSelector((state) => state?.vpcRequests?.environmentValue);
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [approved, setApproved] = useState(false);

  async function approveRequest() {
    setLoading(true);
    const idToken = userInfo.idToken;
    const requestBody = `{"environment": "${isHostIsProd}"}`;

    const getApiUrl = `${process.env.REACT_APP_NETWORK_VPC_SUBMIT}`;

    const setApiUrl = `${getApiUrl}?uid=${uid}&type=1&adminemail=${userInfo.email}`;
    await postApiCall(setApiUrl, idToken, requestBody)
      .then((response) => {
        if (response.status === 200) {
          setApproved(true);
        } else {
          setError('Failed to update');
        }
      })
      .catch((err) => {
        setError('Failed to update');
      });
  }
  return (
    <div className='d-flex justify-content-center flex-column'>
      {approved === false && (
        <>
          <p>Are you sure you want to approve this request</p>
          {error !== null && (
            <div className='d-flex justify-content-center flex-column'>
              <AlertMessage
                cssClassName='alert-message-danger'
                alertMessage={error}
              />
            </div>
          )}
          {loading && <Loader />}
          {loading === false && error === null && (
            <ul className='btn-ul'>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    closeModalParent(null);
                  }}
                >
                  No
                </button>
              </li>
              <li>
                <button
                  type='button'
                  className='teal-filled-btn'
                  onClick={() => {
                    approveRequest();
                  }}
                >
                  Yes
                </button>
              </li>
            </ul>
          )}
        </>
      )}
      {approved === true && (
        <>
          <p>Request Approved!</p>
          <p>
            <button
              type='button'
              className='teal-filled-btn'
              onClick={() => {
                yesParent(null);
              }}
            >
              Ok
            </button>
          </p>
        </>
      )}
    </div>
  );
};

export default Approve;
