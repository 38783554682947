import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

// Import Internal Components
import WorkspaceSidebar from '../WorkspaceSidebar';
import NewRequest from './NewRequest';

// Import Constant
import { USER_INFO } from 'redux/user/userConstant';

const NewRequestIndex = ({ history }) => {
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch]);

  return (
    <>
      {userInfo !== null && authState && authState.isAuthenticated && (
        <>
          <Container fluid>
            <Row>
              <Col md={2} className='px-0'>
                <WorkspaceSidebar />
              </Col>
              <Col md={10} className='mt-4'>
                <NewRequest />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default NewRequestIndex;
