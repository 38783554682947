import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';

// Import Icon
import awsLogo from 'images/aws-logo.svg';
import azureLogo from 'images/azure-logo.svg';

// Import Constant
import { USER_INFO } from 'redux/user/userConstant';

const CloudPreference = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const { authState, oktaAuth } = useOktaAuth();
  const {t} = useTranslation();

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch]);
  return (
    <>
      {userInfo !== null && authState && authState.isAuthenticated && (
        <div
          className='header-section-new flex-column' 
        >
          <p className='cloud-preference-p'>{t('cloud_preference')}</p>
          <div className='d-flex flex-column flex-md-row gap-4'>
            <div className="disable-div">
            <Link to='/trend-micro-aws-requests'>
            <div className='logoContainer'>
              <img src={awsLogo} alt='AWS' className='cloud-preference-img' />
              </div>
            </Link>
            </div>

            <Link to='/trend-micro-azure-requests'>
            <div className='logoContainer'>
            <img
                src={azureLogo}
                alt='Azure'
                className='cloud-preference-img'
                style={{ marginLeft: '0px' }}
              />
            </div>
             
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default CloudPreference;
