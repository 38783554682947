import React, { useState, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

// Import Form Component
import TextInput from 'components/Forms/TextInput';
import CustomModal from 'components/Modal/CustomModal';

// Import Internal Component
import EditRequestCancel from './EditRequestCancel';
import EditFormMenu from './EditFormMenu';

// Validator
import { groupEmailAddressValidate } from '../../../Validator/validator';

const EditGroupEmailAddress = ({ step, setStep, formData, setFormData }) => {
  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // handle onchange
  const handleData = ({ target }) => {
    setFormData({
      type: 'UPDATE_GROUP_EMAIL_ADDRESS',
      payload: { [target.name]: target.value },
    });
  };

  const handleContinue = (e) => {
    e.preventDefault();
    const errors = groupEmailAddressValidate(formData.groupEmailAddress);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setStep(step + 1);
    }
  };
  return (
    <>
      <Row>
        <Col md={12} xs={12}>
          <EditFormMenu groupEmailAddress />
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        <Row className='px-3'>
          <Col md={12}>
            <TextInput
              label='*Technical Group:'
              name='technicalGroup'
              value={formData.groupEmailAddress.technicalGroup}
              onChange={(e) => handleData(e)}
              placeholder='Technical Group'
              isInvalid={dataInputError.technicalGroup ? true : false}
              invalidMessage={dataInputError.technicalGroup}
            />
          </Col>
          <Col md={12}>
            <TextInput
              label='*Security Group:'
              name='securityGroup'
              value={formData.groupEmailAddress.securityGroup}
              onChange={(e) => handleData(e)}
              placeholder='Security Group'
              isInvalid={dataInputError.securityGroup ? true : false}
              invalidMessage={dataInputError.securityGroup}
            />
          </Col>
          <Col md={12}>
            <TextInput
              label='*Billing Group:'
              name='billingGroup'
              value={formData.groupEmailAddress.billingGroup}
              onChange={(e) => handleData(e)}
              placeholder='Billing Group'
              isInvalid={dataInputError.billingGroup ? true : false}
              invalidMessage={dataInputError.billingGroup}
            />
          </Col>
        </Row>

        <Row className='px-3'>
          <div className='d-flex justify-content-end'>
            <ul className='px-0 btn-ul'>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => setStep(step - 1)}
                >
                  Back
                </button>
              </li>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    setShowCancelModal(true);
                  }}
                >
                  Cancel
                </button>
              </li>
              <li>
                <button type='submit' className='teal-filled-btn'>
                  Save & Continue
                </button>
              </li>
            </ul>
          </div>
        </Row>
      </Form>
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<EditRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

export default EditGroupEmailAddress;
