import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

// Roles
import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

// Helper
import { postApiCall } from 'helper/postApiCall';

const ViewTemplate = () => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // ID Token
  const idToken = `${userInfo.idToken}`;

  // API State
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Component State
  const [responseData, setResponseData] = useState([]);
  const [templatesArray, setTemplatesArray] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templateData, setTemplateData] = useState({});
  const [fileName, setFileName] = useState('');
  const {t}=useTranslation();

  // Export to JSON
  const exportData = () => {
    var fileToSave = new Blob([JSON.stringify(templateData, null, 2)], {
      type: 'application/json',
    });

    saveAs(fileToSave, fileName);
  };

  // API Variable
  const apiUrl = `${process.env.REACT_APP_TEMPLATES_VIEW}`;
  let requestBody = `{}`;

  useEffect(() => {
    postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        setResponseData(response.data);
        let tempTemplateArray = [];
        for (let i = 0; i < response.data.length; i++) {
          tempTemplateArray.push(response.data[i].templateName);
        }
        const tempTemplate = tempTemplateArray.map((d) => ({
          value: d,
          label: d,
        }));
        setTemplatesArray(tempTemplate);
        setLoading(false);
      })
      .catch((err) => {
        setError(
          'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
        );
        setLoading(false);
      });

    return () => {
      setTemplatesArray([]);
    };
  }, []);

  return (
    <>
      <Row>
        <Col md={10} className='mt-2 mb-2'>
          <h5 className='page-content-title'>Templates</h5>
        </Col>
        {userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) && (
          <Col md={2} className='mt-2 mb-2'>
            <Link to='/templates-add'>
              <button className='teal-filled-btn w-100'>
                <i className='fa fa-plus mx-2'></i>
                {t("add_template")}
              </button>
            </Link>
          </Col>
        )}
      </Row>
      {loading && (
        <Row>
          <Col>
            <div className='d-flex justify-content-center'>
              <SpinnerLoader />
            </div>
          </Col>
        </Row>
      )}
      {loading === false && error !== null && (
        <Row>
          <Col>
            <DangerAlert message={error} />
          </Col>
        </Row>
      )}
      {loading === false && error === null && (
        <>
          <Row>
            <Col md={6} className='mb-2'>
              <Form.Group controlId='chooseTemplate'>
                <Form.Label>{t("choose_template")}</Form.Label>
                <Select
                  name='chooseTemplate'
                  placeholder={t("choose_template")}
                  searchable={true}
                  value={{ label: selectedTemplate }}
                  isClearable={true}
                  onChange={(value) => {
                    const templateVal = value !== null ? value.value : '';
                    setSelectedTemplate(templateVal);
                    if (templateVal !== '') {
                      let getTemplateData = responseData.filter(
                        (f) => f.templateName === templateVal
                      );
                      setTemplateData(getTemplateData[0].template);
                      setFileName(getTemplateData[0].templateKey);
                    }
                  }}
                  options={templatesArray}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={10} className='mb-2'>
              <Form.Group controlId='accountNo'>
                <Form.Label>Template</Form.Label>
                <div className='template-data-div'>
                  <pre>
                    {selectedTemplate !== ''
                      ? JSON.stringify(templateData, null, 2)
                      : t("select_template_to_view_json")}
                  </pre>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3} className='d-flex flex-row mt-3'>
              <button
                type='button'
                className='teal-bordered-btn w-100'
                onClick={() => {
                  exportData();
                }}
                disabled={selectedTemplate === '' ? true : false}
              >
                <i
                  className='fa fa-download'
                  style={{ marginRight: '6px' }}
                ></i>
                {t("download")}
              </button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ViewTemplate;
