import React from "react";
import { Link, useLocation, withRouter } from "react-router-dom";

const PageContentSidebar = ({
  type = null,
  firstLogoLink,
  firstLogoImg,
  fisrtLogoAlt,
  firstLogoFirstMatchingLink,
  firstLogoSecondMatchingLink,
  firstLogoFirstLinkText,
  firstLogoSecondLinkText,
  secondLogoLink,
  secondLogoImg,
  secondLogoAlt,
  secondLogoFirstMatchingLink,
  secondLogoSecondMatchingLink,
  secondLogoFirstLinkText,
  secondLogoSecondLinkText,
  thirdLogoLink,
  thirdLogoImg,
  thirdLogoAlt,
  thirdLogoFirstMatchingLink,
  thirdLogoSecondMatchingLink,
  thirdLogoFirstLinkText,
  thirdLogoThirdLinkText,
  showLinks,
}) => {
  // Routing Variables
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <div className="page-content-sidebar-container">
      {/* Logo List */}
      {firstLogoLink ? (
        <ul className="page-content-sidebar-logo-list">
          <div
            className={`${
              firstLogoLink === "/trend-micro-aws-requests" ? "disable-div" : ""
            }`}
          >
            <Link to={firstLogoLink}>
              <li
                className={`${
                  splitLocation[1] === firstLogoFirstMatchingLink ||
                  splitLocation[1] === firstLogoSecondMatchingLink
                    ? "page-content-sidebar-logo-first-active"
                    : "page-content-sidebar-logo-first-inactive"
                }`}
              >
                <img src={firstLogoImg} alt={fisrtLogoAlt} />
              </li>
            </Link>
          </div>

          <div
            className={`${
              firstLogoLink === "/aws-host-patch-request" ||
              firstLogoLink === "/aws-backup-new-request"
                ? "disable-div"
                : ""
            }`}
          >
            <Link to={secondLogoLink}>
              <li
                className={
                  splitLocation[1] === secondLogoFirstMatchingLink ||
                  splitLocation[1] === secondLogoSecondMatchingLink
                    ? "page-content-sidebar-logo-second-active"
                    : `page-content-sidebar-logo-second-inactive ${(type = "accountRequest"
                        ? "rounded-0"
                        : "")}`
                }
              >
                <img src={secondLogoImg} alt={secondLogoAlt} />
              </li>
            </Link>
          </div>

          {thirdLogoLink ? (
            <Link to={thirdLogoLink}>
              <li
                className={
                  splitLocation[1] === thirdLogoFirstMatchingLink ||
                  splitLocation[1] === thirdLogoSecondMatchingLink
                    ? "page-content-sidebar-logo-second-active"
                    : "page-content-sidebar-logo-second-inactive"
                }
              >
                <img src={thirdLogoImg} alt={thirdLogoAlt} />
              </li>
            </Link>
          ) : null}
        </ul>
      ) : null}

      {/* Link List */}

      {showLinks === "yes" && (
        <>
          {(splitLocation[1] === firstLogoFirstMatchingLink ||
            splitLocation[1] === firstLogoSecondMatchingLink) && (
            <ul className="page-content-sidebar-link-list">
              <li
                className={
                  splitLocation[1] === firstLogoFirstMatchingLink
                    ? "page-content-sidebar-link-active"
                    : "page-content-sidebar-link-inactive"
                }
              >
                <Link to={`/${firstLogoFirstMatchingLink}`}>
                  {firstLogoFirstLinkText}
                </Link>
              </li>
              <li
                className={
                  splitLocation[1] === firstLogoSecondMatchingLink
                    ? "page-content-sidebar-link-active"
                    : "page-content-sidebar-link-inactive"
                }
              >
                <Link to={`/${firstLogoSecondMatchingLink}`}>
                  {firstLogoSecondLinkText}
                </Link>
              </li>
            </ul>
          )}

          {(splitLocation[1] === secondLogoFirstMatchingLink ||
            splitLocation[1] === secondLogoSecondMatchingLink) && (
            <ul className="page-content-sidebar-link-list">
              <li
                className={
                  splitLocation[1] === secondLogoFirstMatchingLink
                    ? "page-content-sidebar-link-active"
                    : "page-content-sidebar-link-inactive"
                }
              >
                <Link to={`/${secondLogoFirstMatchingLink}`}>
                  {secondLogoFirstLinkText}
                </Link>
              </li>

              <li
                className={
                  splitLocation[1] === secondLogoSecondMatchingLink
                    ? "page-content-sidebar-link-active"
                    : "page-content-sidebar-link-inactive"
                }
              >
                <Link to={`/${secondLogoSecondMatchingLink}`}>
                  {secondLogoSecondLinkText}
                </Link>
              </li>
            </ul>
          )}
          {thirdLogoLink &&
            (splitLocation[1] === thirdLogoFirstMatchingLink ||
              splitLocation[1] === thirdLogoSecondMatchingLink) && (
              <ul className="page-content-sidebar-link-list">
                <li
                  className={
                    splitLocation[1] === thirdLogoFirstMatchingLink
                      ? "page-content-sidebar-link-active"
                      : "page-content-sidebar-link-inactive"
                  }
                >
                  <Link to={`/${thirdLogoFirstMatchingLink}`}>
                    {thirdLogoFirstLinkText}
                  </Link>
                </li>
                <li
                  className={
                    splitLocation[1] === thirdLogoSecondMatchingLink
                      ? "page-content-sidebar-link-active"
                      : "page-content-sidebar-link-inactive"
                  }
                >
                  <Link to={`/${thirdLogoSecondMatchingLink}`}>
                    {thirdLogoThirdLinkText}
                  </Link>
                </li>
              </ul>
            )}
        </>
      )}
    </div>
  );
};

export default withRouter(PageContentSidebar);
