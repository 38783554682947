import React, { useState, useEffect } from "react";
import { Dropdown, Row } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector } from "react-redux";
import Ticker from "react-ticker";

import { USER_INFO } from "redux/user/userConstant";

// import { scrollingText } from 'static-contents/scrollingText';
import { useTranslation } from "react-i18next";

const Header = () => {
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const scrollingText = t("scrolling_text");

  function toggleClick() {
    const toggle = document.getElementById("top-bar-toggle");
    const nav = document.getElementById("side-bar-nav");
    const headerpd = document.getElementById("top-bar-toggle");
    const pagecontent = document.getElementById("page-content");

    const sonyshortlogo = document.getElementById("sony-short-logo");
    const sonyfulllogo = document.getElementById("sony-full-logo");

    nav.classList.toggle("side-bar-show");
    toggle.classList.toggle("fa-times");
    headerpd.classList.toggle("toggle-icon-padding");
    pagecontent.classList.toggle("page-content-padding");

    sonyshortlogo.classList.toggle("side-short-logo-visibility");
    sonyfulllogo.classList.toggle("side-full-logo-visibility");
  }

  // ! Logout Function
  const logoutHandler = async () => {
    dispatch({
      type: USER_INFO,
      payload: null,
    });
    oktaAuth.signOut();
  };

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 767) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <>
      {userInfo !== null && (
        <header className="top-bar">
          <div className="top-bar-toggle" onClick={() => toggleClick()}>
            {/* <i
              className='fa fa-navicon fa-times toggle-icon-padding'
              id='top-bar-toggle'
            ></i> */}
            <i
              className={
                "fa fa-navicon fa-times " +
                (!isMobileView ? "toggle-icon-padding" : "")
              }
              id="top-bar-toggle"
            ></i>
          </div>
          {/* <div className="scrolling-text-container">
            <ul className="scrolling-text">
              <Ticker speed={3}>
                {() =>
                  scrollingText.map((data, index) => (
                    <li key={index}>{data.message}</li>
                  ))
                }
              </Ticker>
            </ul>
          </div> */}

          <div style={{ display: "flex", gap: 8 }}>
            <div>
              <Dropdown>
                <Dropdown.Toggle id="language-toggle">
                  {i18n.language === "en" ? "EN" : "JA"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      i18n.changeLanguage("en");
                    }}
                  >
                    English (EN)
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      i18n.changeLanguage("ja");
                    }}
                  >
                    日本語 (JA)
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {userInfo !== null && (
              <div>
                <Dropdown>
                  <Dropdown.Toggle>{userInfo.name}</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        logoutHandler();
                      }}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
