import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import Form Component
import TextInput from 'components/Forms/TextInput';
import Loader from 'components/Common/Loader';
import BillingCompanyDropdown from 'components/Forms/BillingCompanyDropdown';
import BillingSubCompanyDropdown from 'components/Forms/BillingSubCompanyDropdown';
import BillingDepartmentDropdown from 'components/Forms/BillingDepartmentDropdown';
import StaticDropdown from 'components/Forms/StaticDropdown';
import CustomModal from 'components/Modal/CustomModal';

// Import Internal Components
import FormProgress from './FormProgress';
import NewRequestSubmit from './NewRequestSubmit';
import NewRequestCancel from './NewRequestCancel';

// Helper
import { getApiCall } from 'helper/getApiCall';
import { postApiCall } from 'helper/postApiCall';

// Validator
import { accountDetailsValidate } from '../../Validator/validator';

// Static Data
import {
  staticEnvironmentType,
  staticAssetUserBase,
  staticDataClassification,
} from '../StaticData/awsStaticData';
import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

const AccountDetails = ({ step, setStep, formData, setFormData }) => {
  const {t}=useTranslation();
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [apiError, setApiError] = useState(null);

  // Set ID Token Globally
  const idToken = userInfo.idToken;

  // Response Data State
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  // Component Form Data State
  const [billingSubOpCompany, setBillingSubOpCompany] = useState([]);
  const [billingDepartment, setBillingDepartment] = useState([]);

  // Form Submit State
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [newRequestUid, setNewRequestUid] = useState(null);

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // handle onchange
  const handleData = ({ target }) => {
    if (target.name === 'opco') {
      setFormData({
        type: 'UPDATE_ACCOUNT_DETAILS',
        payload: {
          [`subopco`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_ACCOUNT_DETAILS',
        payload: {
          [`department`]: '',
        },
      });
      setBillingSubOpCompany([]);
      setBillingDepartment([]);
      let opCompany = target.value;
      if (opCompany !== '') {
        const getSubOpCompany = responseData.find(
          (t) => t.opco === opCompany
        ).subopcod;
        setBillingSubOpCompany(getSubOpCompany);
      }
    }
    if (target.name === 'subopco') {
      setFormData({
        type: 'UPDATE_ACCOUNT_DETAILS',
        payload: {
          [`department`]: '',
        },
      });
      setBillingDepartment([]);
      let opSubCompany = target.value;
      if (opSubCompany !== '') {
        const getDepartment = billingSubOpCompany.find(
          (t) => t.subopco === opSubCompany
        ).dept;
        setBillingDepartment(getDepartment);
      }
    }

    if (
      formData.accountDetails.opco !== '' &&
      formData.accountDetails.purposeid !== '' &&
      formData.accountDetails.environmenttype !== ''
    ) {
      setFormData({
        type: 'UPDATE_ACCOUNT_DETAILS',
        payload: {
          [`accountName`]: `${
            formData.accountDetails.opco
          }_${formData.accountDetails.purposeid.replace(/ /g, '_')}_${
            formData.accountDetails.environmenttype
          }`,
        },
      });
    }

    setFormData({
      type: 'UPDATE_ACCOUNT_DETAILS',
      payload: { [target.name]: target.value },
    });
  };

  const handleContinue = (e) => {
    e.preventDefault();

    const errors = accountDetailsValidate(formData.accountDetails);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setFormSubmitLoading(true);

      // Form Submission
      const currentEpochTime = Date.now();

      const formSubmitApiUrl = `${process.env.REACT_APP_AWS_ACCOUNT_REQUEST_NEW_REQUEST_SUBMIT}`;
      postApiCall(formSubmitApiUrl, idToken, {
        firstNameAO: formData.accountContact.firstNameAO,
        lastNameAO: formData.accountContact.lastNameAO,
        emailAO: formData.accountContact.emailAO,
        countryAO: formData.accountContact.countryAO,
        phoneNoAO: formData.accountContact.phoneNoAO,
        firstNameBC: formData.billingContact.firstNameBC,
        lastNameBC: formData.billingContact.lastNameBC,
        emailBC: formData.billingContact.emailBC,
        countryBC: formData.billingContact.countryBC,
        phoneNoBC: formData.billingContact.phoneNoBC,
        firstNameSC: formData.securityContact.firstNameSC,
        lastNameSC: formData.securityContact.lastNameSC,
        emailSC: formData.securityContact.emailSC,
        countrySC: formData.securityContact.countrySC,
        phoneNoSC: formData.securityContact.phoneNoSC,
        firstNameTC: formData.technicalContact.firstNameTC,
        lastNameTC: formData.technicalContact.lastNameTC,
        emailTC: formData.technicalContact.emailTC,
        countryTC: formData.technicalContact.countryTC,
        phoneNoTC: formData.technicalContact.phoneNoTC,
        billingopco: formData.billingInfo.billingopco,
        billingSubOpco: formData.billingInfo.billingSubOpco,
        billingDepartment: formData.billingInfo.billingDepartment,
        billingRegion: formData.billingInfo.billingRegion,
        maxSpend: formData.billingInfo.maxSpend,
        billto: formData.billingInfo.billto,
        io: formData.billingInfo.io,
        costcenter: formData.billingInfo.costcenter,
        technicalGroup: formData.groupEmailAddress.technicalGroup,
        securityGroup: formData.groupEmailAddress.securityGroup,
        billingGroup: formData.groupEmailAddress.billingGroup,
        pgcsgoldenami: formData.groupEmailAddress.pgcsgoldenami,
        ptm: userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? formData.groupEmailAddress.ptm : "True",
        conformity: userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? formData.groupEmailAddress.conformity : "True",
        sonyad: formData.groupEmailAddress.sonyad,
        opco: formData.accountDetails.opco,
        subopco: formData.accountDetails.subopco,
        department: formData.accountDetails.department,
        environmenttype: formData.accountDetails.environmenttype,
        assetUserBase: formData.accountDetails.assetUserBase,
        dataClassification: formData.accountDetails.dataClassification,
        purposeid: formData.accountDetails.purposeid,
        accountdescription: formData.accountDetails.accountdescription,
        accountName: formData.accountDetails.accountName,
        requestStatus: 'Pending',
        uid: `u-${currentEpochTime}`,
        submittedBy: userInfo.preferred_username,
      })
        .then((response) => {
          if (response.data.status === 'Success') {
            setNewRequestUid(`u-${currentEpochTime}`);
            setFormSubmitSuccess(true);
            setFormSubmitLoading(false);
          }
        })
        .catch((err) => {
          setFormSubmitLoading(false);
          setApiError(
            t("failed_to_retrieve_data")
          );
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    // adding query string fr oauth
    const queryObj = `{
      "submittedBy": "${userInfo?.email}",
      "type_of_user": ${userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? 1 : 0}
    }`
    const encodedString = Buffer.from(queryObj).toString('base64');
    // end

    const apiUrl = `${process.env.REACT_APP_AWS_ACCOUNT_REQUEST_BILLING_INFO}?enchash=${encodedString}`;

    getApiCall(apiUrl, idToken).then((response) => {
      setResponseData(response.data.body);
      setLoading(false);
    });
  }, []);
  
  return (
    <>
      <Row>
        <Col md={12} xs={12}>
          <FormProgress
            accountContact
            billingContact
            securityContact
            technicalContact
            billingInfo
            groupEmailAddress
            accountDetails
          />
        </Col>
      </Row>
      <Row className='px-3'>
        <Col md={12} xs={12}>
          <h5 className='page-content-title mb-4'>{t("account_details")}</h5>
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        {loading && <Loader />}
        {loading === false && (
          <>
            <Row className='px-3'>
              <Col md={6}>
                <TextInput
                  readOnly
                  label={"*"+t("account_name")+":"}
                  name='accountName'
                  value={`${
                    formData.accountDetails.opco
                  }_${formData.accountDetails.purposeid.replace(/ /g, '_')}_${
                    formData.accountDetails.environmenttype
                  }`}
                  onChange={(e) => handleData(e)}
                  placeholder={t("account_name")}
                  isInvalid={dataInputError.accountName ? true : false}
                  invalidMessage={dataInputError.accountName}
                  isInfoBubbleAvailable={true}
                  infoBubbleTitle={t("account_details_account_name_info_bubble")}
                />
              </Col>

              <Col md={6}>
                <TextInput
                  label={"*"+t("account_description")+":"}
                  name='accountdescription'
                  value={formData.accountDetails.accountdescription}
                  onChange={(e) => handleData(e)}
                  placeholder={t("account_description")}
                  isInvalid={dataInputError.accountdescription ? true : false}
                  invalidMessage={dataInputError.accountdescription}
                  isInfoBubbleAvailable={true}
                  infoBubbleTitle={t("account_details_account_description_info_bubble")}
                />
              </Col>

              <Col md={6}>
                <TextInput
                  label={"*"+t("application_name")+":"}
                  name='purposeid'
                  value={formData.accountDetails.purposeid}
                  onChange={(e) => handleData(e)}
                  placeholder={t("application_name")}
                  isInvalid={dataInputError.purposeid ? true : false}
                  invalidMessage={dataInputError.purposeid}
                  isInfoBubbleAvailable={true}
                  infoBubbleTitle={t("account_details_application_name_info_bubble")}
                />
              </Col>

              <Col md={6}>
                <BillingCompanyDropdown
                  label={"*"+t("operating_company")+":"}
                  defaultSelectMessage={t("select_op_company")}
                  name='opco'
                  value={formData.accountDetails.opco}
                  onChange={(e) => handleData(e)}
                  selectValues={responseData}
                  isInvalid={dataInputError.opco ? true : false}
                  invalidMessage={dataInputError.opco}
                />
              </Col>
              <Col md={6}>
                <BillingSubCompanyDropdown
                  label={"*"+t("sub_operating_company")+":"}
                  defaultSelectMessage={t("select_sub_op_company")} 
                  name='subopco'
                  value={formData.accountDetails.subopco}
                  onChange={(e) => handleData(e)}
                  selectValues={billingSubOpCompany}
                  isInvalid={dataInputError.subopco ? true : false}
                  invalidMessage={dataInputError.subopco}
                />
              </Col>
              <Col md={6}>
                <BillingDepartmentDropdown
                  label={"*"+t("department")+":"}
                  defaultSelectMessage={t("select_department")}
                  name='department'
                  value={formData.accountDetails.department}
                  onChange={(e) => handleData(e)}
                  selectValues={billingDepartment}
                  isInvalid={dataInputError.department ? true : false}
                  invalidMessage={dataInputError.department}
                />
              </Col>

              <Col md={6}>
                <StaticDropdown
                  label={"*"+t("environment_type")+":"}
                  defaultSelectMessage={t("select_environment_type")}
                  name='environmenttype'
                  value={formData.accountDetails.environmenttype}
                  onChange={(e) => handleData(e)}
                  selectValues={staticEnvironmentType}
                  isInvalid={dataInputError.environmenttype ? true : false}
                  invalidMessage={dataInputError.environmenttype}
                />
              </Col>

              <Col md={6}>
                <StaticDropdown
                  label={"*"+t("asset_user_base")+":"}
                  defaultSelectMessage={t("select_asset_user_base")}
                  name='assetUserBase'
                  value={formData.accountDetails.assetUserBase}
                  onChange={(e) => handleData(e)}
                  selectValues={staticAssetUserBase}
                  isInvalid={dataInputError.assetUserBase ? true : false}
                  invalidMessage={dataInputError.assetUserBase}
                />
              </Col>

              <Col md={6}>
                <StaticDropdown
                  label={"*"+t("data_classification")+":"}
                  defaultSelectMessage={t("select_data_classification")}
                  name='dataClassification'
                  value={formData.accountDetails.dataClassification}
                  onChange={(e) => handleData(e)}
                  selectValues={staticDataClassification}
                  isInvalid={dataInputError.dataClassification ? true : false}
                  invalidMessage={dataInputError.dataClassification}
                />
              </Col>
            </Row>

            <Row className='px-3'>
              <Col md={12}>
                <div className='d-flex justify-content-center'>
                  {formSubmitLoading === false && apiError !== null && (
                    <Alert variant='danger'>{apiError}</Alert>
                  )}
                </div>
              </Col>
              <div className='col-md-5 offset-md-7'>
                {formSubmitLoading === true && (
                  <div className='mb-3'>
                    <Loader />
                  </div>
                )}
                {formSubmitLoading === false && (
                  <ul className='px-0 btn-ul'>
                    <li>
                      <button
                        type='button'
                        className='teal-bordered-btn'
                        onClick={() => setStep(step - 1)}
                      >
                        {t("back")}
                      </button>
                    </li>
                    <li>
                      <button
                        type='button'
                        className='teal-bordered-btn'
                        onClick={() => {
                          setShowCancelModal(true);
                        }}
                      >
                        {t("cancel")}
                      </button>
                    </li>
                    <li>
                      <button type='submit' className='teal-filled-btn'>
                      {t("save_submit")}
                      </button>
                    </li>
                  </ul>
                )}
              </div>
            </Row>
          </>
        )}
      </Form>
      {formSubmitSuccess && (
        <CustomModal
          showModal={true}
          children={<NewRequestSubmit refUid={newRequestUid} />}
        />
      )}
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<NewRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

export default AccountDetails;
