import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { useTranslation } from 'react-i18next';

import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";

// Import Icons
import backicon from "icons/back.svg";

// Helper
import { postApiCall } from "helper/postApiCall";

// Components
import SpinnerLoader from "components/Common/SpinnerLoader";
import DangerAlert from "components/Message/DangerAlert";
import TextInput from "components/Forms/TextInput";
import CustomModal from "components/Modal/CustomModal";
import StaticDropdown from "components/Forms/StaticDropdown";

// Internal
import CancelForm from "./CancelForm";
import SubmitForm from "./SubmitForm";

// Static Data
import {
  ec2MetricNames,
  setAlarmStatistic,
  setAlarmPeriod,
  setAlarmCondition,
  setAlarmThreshold,
} from "./setAlarmStaticData";

// Validator
import { ec2SetAlarmValidator } from "./validator";

const EC2SetAlarmAll = ({
  application,
  namespace,
  tableData,
  closeSetAlarmParent,
  platform,
}) => {
  const {t}=useTranslation();
  const isMounted = useRef(false);
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // ! Modal State & Callback
  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // Close Modal Callback (Modal No Click Part)
  const closeFormCallback = useCallback((value) => {
    closeSetAlarmParent(value);
  }, []);

  const [showSubmitModal, setShowSubmitModal] = useState(false);

  // auto create alarm checkbox
  const [enableAutoCreateAlarm, setAutoCreateAlarm] = useState(false);

  const closeSubmitModalCallback = useCallback((value) => {
    setShowSubmitModal(value);
  }, []);

  // ! API Common ID Token
  const idToken = `${userInfo.idToken}`;

  // ! Form State
  const [metricName, setMetricName] = useState("");
  const [nameSpace, setNameSpace] = useState("AWS/EC2");
  const [alarmDescription, setAlarmDescription] = useState("");
  const [statistic, setStatistic] = useState("");
  const [period, setPeriod] = useState("");
  const [evaluationPeriod, setEvaluationPeriod] = useState("3");
  const [condition, setCondition] = useState("");
  const [threshold, setThreshold] = useState("");
  const [resource, setResource] = useState([]);
  const [diskPath, setDiskPath] = useState("");
  const [additionalEmail, setAdditionalEmail] = useState([]);

  const [dropdownData, setdropdownData] = useState([]);

  // ! Form Validation State
  const [dataInputError, setDataInputError] = useState({});

  // ! Form Submit API State
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [formSubmitResponseData, setFormSubmitResponseData] = useState([]);
  const [formSubmitError, setFormSubmitError] = useState(null);

  // ! Form Submit API Variables
  const formSubmitApiUrl = `${process.env.REACT_APP_CLOUD_EYE_INFRASTRUCTURE_SET_ALARM}`;

  // ! Form Submit
  function formHandler() {
    const errors = ec2SetAlarmValidator({
      metricName,
      alarmDescription,
      statistic,
      period,
      evaluationPeriod,
      condition,
      threshold,
      resource,
      diskPath,
    });
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setFormSubmitLoading(true);
      let resourceArray = [];
      for (let i = 0; i < resource.length; i++) {
        resourceArray.push(resource[i].DropdownValue);
      }

      const formSubmitRequestBody = {
        MetricName: metricName,
        Namespace: nameSpace,
        AlarmDescription: alarmDescription,
        Statistic: statistic,
        Period: period,
        EvaluationPeriods: evaluationPeriod,
        ComparisonOperator: condition,
        Threshold: threshold,
        Instance: resourceArray,
        ...(metricName === "disk_used_percent" && {
          DiskPaths: diskPath,
        }),
        application: application,
        requestoremailid: `${userInfo.email}`,
        platfrm: platform,
        emailids: additionalEmail.length > 0 ? additionalEmail : [],
        checkbox: enableAutoCreateAlarm ? "YES" : "NO"
      };
      setFormSubmitLoading(true);
      postApiCall(formSubmitApiUrl, idToken, formSubmitRequestBody)
        .then((response) => {
          if (response.data.status === "success") {
            setFormSubmitResponseData(
              "Your request is submitted. You will recieve email notification on successful alert setup"
            );
          } else {
            setFormSubmitResponseData(
              "Error setting up the alarm, Contact Sony Global Cloud Team for Assistance"
            );
          }
          setShowSubmitModal(true);
          setFormSubmitLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setFormSubmitError(
            "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
          );
          setFormSubmitLoading(false);
        });
    }
  }

  // ! Disk Usage State
  const [loading, setLoading] = useState(null);
  const [responseData, setResponseData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isMounted.current) {
      if ((metricName === "disk_used_percent" || metricName === "logical_disk") && resource.length > -1) {
        
        setLoading(true);
        const diskUsageApiUrl = `${process.env.REACT_APP_CLOUD_EYE_EC2_METRICS_CPU_USAGE}`;
        const diskUsageRequestBody = {
          application: application,
          instid: `${resource[resource?.length - 1]?.instid}`,
          context: "diskpaths",
        };
        postApiCall(diskUsageApiUrl, idToken, diskUsageRequestBody)
          .then((response) => {
            setResponseData(response.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setError(
              "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
            );
            setLoading(false);
          });
      }
    }
  }, [resource]);

  useEffect(() => {
    let tempArray = [];
    for (let i = 0; i < tableData.length; i++) {
      let tempObj = {};
      tempObj["ec2UniqueId"] = i;
      tempObj[
        "DropdownLabel"
      ] = `${tableData[i].InstanceId}(${tableData[i].DisplayName})`;
      tempObj[
        "DropdownValue"
      ] = `${tableData[i].InstanceId}---${tableData[i].DisplayName}`;
      tempObj["instid"] = `${tableData[i].InstanceId}`;
      tempArray.push(tempObj);
    }
    setdropdownData(tempArray);
    isMounted.current = true;
  }, []);

  const handleAutoCreateAlarms = (e) => {
    setAutoCreateAlarm(e.target.checked);
    console.log('setAutoCreateAlarm', e.target.checked)
  }

  return (
    <div className="view-edit-box-conatiner pb-5" style={{ width: "640px" }}>
      <div className="view-edit-box-top-bar">
        <div className="view-edit-box-back-title-container">
          <img
            src={backicon}
            alt="Back"
            className="view-edit-box-back-btn"
            onClick={() => closeSetAlarmParent(false)}
          />
          <div>
            <h5 className="view-edit-box-title">Set Alarms</h5>
          </div>
        </div>
      </div>
      {formSubmitLoading && <SpinnerLoader />}
      {formSubmitLoading === false && formSubmitError !== null && (
        <DangerAlert message={formSubmitError} />
      )}
      {formSubmitLoading === false && formSubmitError === null && (
        <div className="mt-4">
          <Row>
            <Col md={6}>
              <StaticDropdown
                label="Metric Name:"
                name="metricName"
                defaultSelectMessage="Select Metric"
                value={metricName}
                onChange={(e) => {
                  setMetricName(e.target.value);
                  setResource([]);
                }}
                selectValues={ec2MetricNames}
                isInvalid={dataInputError.metricName ? true : false}
                invalidMessage={dataInputError.metricName}
              />
            </Col>
            <Col md={6}>
              <TextInput
                label="Namespace:"
                name="url"
                value={nameSpace}
                placeholder="Namespace"
                isReadOnly={true}
              />
            </Col>
            <Col md={6}>
              <TextInput
                label="Alarm Description:"
                name="alarmDescription"
                value={alarmDescription}
                onChange={(e) => setAlarmDescription(e.target.value)}
                placeholder="Alarm Description"
                isInvalid={dataInputError.alarmDescription ? true : false}
                invalidMessage={dataInputError.alarmDescription}
              />
            </Col>
            <Col md={6}>
              <StaticDropdown
                label="Statistic:"
                name="statistic"
                defaultSelectMessage="Select Statistic"
                value={statistic}
                onChange={(e) => setStatistic(e.target.value)}
                selectValues={setAlarmStatistic}
                isInvalid={dataInputError.statistic ? true : false}
                invalidMessage={dataInputError.statistic}
              />
            </Col>
            <Col md={6}>
              <StaticDropdown
                label="Period:"
                name="period"
                defaultSelectMessage="Select Period"
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
                selectValues={setAlarmPeriod}
                isInvalid={dataInputError.period ? true : false}
                invalidMessage={dataInputError.period}
              />
            </Col>
            <Col md={6}>
              <TextInput
                label="Evaluation Period:"
                name="evaluationPeriod"
                value={evaluationPeriod}
                onChange={(e) => setEvaluationPeriod(e.target.value)}
                placeholder="Evaluation Period"
                isInvalid={dataInputError.evaluationPeriod ? true : false}
                invalidMessage={dataInputError.evaluationPeriod}
              />
            </Col>
            <Col md={6}>
              <StaticDropdown
                label="Condition:"
                name="condition"
                defaultSelectMessage="Select Condition"
                value={condition}
                onChange={(e) => setCondition(e.target.value)}
                selectValues={setAlarmCondition}
                isInvalid={dataInputError.condition ? true : false}
                invalidMessage={dataInputError.condition}
              />
            </Col>
            <Col md={6}>
              <StaticDropdown
                label="Threshold:"
                name="threshold"
                defaultSelectMessage="Select Threshold"
                value={threshold}
                onChange={(e) => setThreshold(e.target.value)}
                selectValues={setAlarmThreshold}
                isInvalid={dataInputError.threshold ? true : false}
                invalidMessage={dataInputError.threshold}
              />
            </Col>

            <Col md={12}>
              <Form.Group className="mb-3" controlId="resource">
                <Form.Label>Resource</Form.Label>
                <Multiselect
                  selectedValues={{}}
                  options={dropdownData}
                  onSelect={(selectedList, selectedItem) => {
                    const selectedArr = [...selectedList]
                    setResource(selectedArr);
                  }}
                  onRemove={(selectedList, selectedItem) => {
                    const selectedArr = [...selectedList]
                    setResource(selectedArr);
                  }}
                  displayValue="DropdownLabel"
                  disablePreSelectedValues={true}
                  closeOnSelect={false}
                  avoidHighlightFirstOption={true}
                  singleSelect={
                    metricName === "disk_used_percent" ? true : false
                  }
                  value={resource}
                />

                <p className="custom-error-message">
                  {dataInputError.resource}
                </p>
              </Form.Group>
            </Col>

            {/* Disk Path Dropdown */}
            {metricName === "disk_used_percent" && loading === true && (
              <Col md={12}>
                <SpinnerLoader />
              </Col>
            )}
            {metricName === "disk_used_percent" && loading === false && (
              <Col md={12}>
                <Form.Group className="mb-3" controlId="resource">
                  <Form.Label>Disk Path</Form.Label>
                  <Multiselect
                    options={responseData}
                    onSelect={(selectedList, selectedItem) => {
                      setDiskPath(selectedList);
                    }}
                    onRemove={(selectedList, selectedItem) => {
                      setDiskPath(selectedList);
                    }}
                    displayValue={responseData}
                    disablePreSelectedValues={true}
                    closeOnSelect={false}
                    avoidHighlightFirstOption={true}
                    isObject={false}
                  />

                  <p className="custom-error-message">
                    {dataInputError.diskPath}
                  </p>
                </Form.Group>
              </Col>
            )}

            <Col md={6}>
              <Form.Group className="mb-3" controlId="resource">
                <Form.Label>
                  Additional Email
                  <br />
                  (Please press enter after entering the email id)
                </Form.Label>
                <ReactMultiEmail
                  // style={myStyle}
                  emails={additionalEmail}
                  onChange={(value) => {
                    setAdditionalEmail(value);
                  }}
                  // validateEmail={(email) => {
                  //   return setIsEmailValid(isEmail(email)); // return boolean
                  // }}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span
                          data-tag-handle
                          onClick={() => removeEmail(index)}
                        >
                          ×
                        </span>
                      </div>
                    );
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="pe-3">
          <Col md={6}>
            <Form.Group className='mb-1'>
              <Form.Check
                type='checkbox'
                label={t("Auto create alarms for new EC2")}
                className='checkbox-input'
                onChange={(e) => handleAutoCreateAlarms(e)}
              />
            </Form.Group>     
          </Col>
            <Col md={6} className="d-flex justify-content-end">
              <ul className="px-0 btn-ul">
                <li>
                  <button
                    type="button"
                    className="teal-bordered-btn"
                    onClick={() => {
                      setShowCancelModal(true);
                    }}
                  >
                    Cancel
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="teal-filled-btn"
                    onClick={() => formHandler()}
                  >
                    Submit
                  </button>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      )}
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={
            <CancelForm
              closeModalParent={closeModalCallback}
              closeFormParent={closeFormCallback}
            />
          }
        />
      )}

      {showSubmitModal && (
        <CustomModal
          showModal={true}
          children={
            <SubmitForm
              closeSubmitModalParent={closeSubmitModalCallback}
              closeFormParent={closeFormCallback}
              message={formSubmitResponseData}
            />
          }
        />
      )}
    </div>
  );
};

export default EC2SetAlarmAll;
