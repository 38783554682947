import React from 'react';

const SubmitForm = ({ message, closeSubmitModalParent, closeFormParent }) => {
  return (
    <div className='d-flex justify-content-center flex-column'>
      <p>{message}</p>
      <ul className='btn-ul'>
        <li>
          <button
            type='button'
            className='teal-filled-btn'
            onClick={() => {
              closeSubmitModalParent(false);
              closeFormParent(false);
            }}
          >
            Ok
          </button>
        </li>
      </ul>
    </div>
  );
};

export default SubmitForm;
