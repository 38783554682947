import React, { useState, useRef, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import MenuTabs from '../MenuTabs';

import Prisma from './Prisma';
import TrendMicro from './TrendMicro';

import splunkIcon from 'icons/splunk.svg';

const Security = ({ applicationName }) => {
  const isMounted = useRef(false);
  const dispatch = useDispatch();

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [selectedApplicationName, setSelectedApplicationName] = useState('');

  // Dependency Load
  useEffect(() => {
    if (isMounted.current) {
    }
    return () => {};
  }, [selectedApplicationName, dispatch]);

  // 1st Load
  useEffect(() => {
    setSelectedApplicationName(applicationName);

    isMounted.current = true;
  }, []);
  return (
    <Row>
      <Col md={12} className='px-3'>
        <MenuTabs applicationName={selectedApplicationName} />
        <Row>
          {selectedApplicationName === '' && (
            <Col
              md={12}
              className='d-flex justify-content-center align-items-center '
            >
              Please select the application
            </Col>
          )}
          {selectedApplicationName !== '' && (
            <>
              <Col md={6} className='mb-3'>
                <Prisma
                  applicationName={selectedApplicationName}
                  userInfo={userInfo}
                />
              </Col>
              <Col md={6} className='mb-3'>
                <TrendMicro
                  applicationName={selectedApplicationName}
                  userInfo={userInfo}
                />
              </Col>
              <Col md={12}>
                <div className='px-2'>
                  <img src={splunkIcon} alt='Splunk' className='splunk-img' />
                  <a
                    href='https://status.aws.amazon.com/'
                    className='splunk-link'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Click here for more details...
                  </a>
                </div>
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default Security;
