import React from "react";
import { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import DangerAlert from "components/Message/DangerAlert";

const NewRequestConfirmation = ({
  closeModalParent,
  handleProceed = () => {},
}) => {
  const { t } = useTranslation();
  const [isDisclaimerAgreed, setIsDisclaimerAgreed] = useState(false);
  return (
    <Row>
      <div className="d-flex justify-content-center flex-column">
        <Col md={12}>
          <p className="mx-5 fs-14">
            <Row>
              <div className="confirmation-warning">
                <i class="fa fa-warning text-warning h4"></i>
              </div>
              <div className="confirmation-text">
                <span>
                  Continuing will add the selected instances to our automated
                  patching solution. This means patches will be applied
                  automatically according to your chosen schedule. However,
                  please note that for full compliance with security standards,
                  a manual reboot is required after patch installation.
                </span>
              </div>
            </Row>
          </p>
        </Col>

        <Form.Check
          className="review-confirm-modal-check"
          type="checkbox"
          label={<div className="ms-2 fs-13">I agree to continue</div>}
          defaultChecked={isDisclaimerAgreed}
          onChange={(e) => {
            setIsDisclaimerAgreed(!isDisclaimerAgreed);
          }}
        />
        <ul className="btn-ul">
          <li>
            <button
              type="button"
              className="teal-bordered-btn"
              onClick={() => {
                closeModalParent(false);
              }}
            >
              {t("Cancel")}
            </button>
          </li>
          <li>
            <button
              disabled={!isDisclaimerAgreed}
              type="button"
              className={`teal-filled-btn ${
                isDisclaimerAgreed ? "" : "disable-div"
              }`}
              onClick={(e) => {
                handleProceed(e);
              }}
            >
              {t("Proceed")}
            </button>
          </li>
        </ul>
      </div>
    </Row>
  );
};

export default NewRequestConfirmation;
