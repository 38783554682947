import axios from "axios";

export function getToken() {
  return "eyJraWQiOiJLZmxNSlNvNmNvTGRZNy1JREEtbEIyMlFVclo2TFVVcmVLODdxZDNVYWc4IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHVodGV3aGN5c1ZCbzNlRTM1NyIsIm5hbWUiOiJSYWhpbCBnbWFpbCIsImVtYWlsIjoicmFoaWxAZ29wcm90b3ouY29tIiwidmVyIjoxLCJpc3MiOiJodHRwczovL3Nvbnl0ZWNoLm9rdGEuY29tIiwiYXVkIjoiMG9hd3FqNXdiUzA4THF2QnczNTYiLCJpYXQiOjE2NTQ5MjMxMzAsImV4cCI6MTY1NDkyNjczMCwianRpIjoiSUQuQ2lmUkpNYWVqanlkNlU0MHhQWEVSVXV5VWVBVEFEN2dWRXdpNTdmS3NkdyIsImFtciI6WyJwd2QiLCJtZmEiLCJzd2siXSwiaWRwIjoiMDBvcmVmZTljSlVpeGw5VFgzNTYiLCJub25jZSI6ImhYSzlUb2cyeDBENHYxWTIyZ1NCM3owVHdER2ZpZW5VSDNsZm5BRVhhVlRvczA1V091bktGTGxEMXNSRjlSMVEiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiI5MTAzMDkwMDExIiwiYXV0aF90aW1lIjoxNjU0OTIzMDk1LCJhdF9oYXNoIjoiNm5hZ3hJTjlmdE40dnUtc3EwMk5UZyJ9.lnfcaozPTWLV0tItjkkcM6Aywu0URJGP7Ii6LmJ1Q8528l9J7t4Dcrq-tJHku92elUN8ev8zQcMkXpnrBCEcDwcTmrl6ueexisl_3en1PcJzQXCbZj9DhFz9p9Zdg-vVNGXOIdLpft6I3SZCd2O9x-FJSnkcjDJP8suxPFcJbetNhMDrzrUGTTceSA-nUnhhnHsmTHK5pt6f9PkZCfnnMPzsaYWklLNouJ2zR3RcLnOBWLKVB11tTDa_0-Po00IHqwXlozYI7qfZB51HD7YoFwOkl6IgLPW9m0IiXjC_MKyapYaCCNWXJfHzLayesqZMox3znDwG-_uqabDj7dc3dw";
}

function client(url, { body, ...customConfig } = {}) {
  const headers = { "content-type": "application/json" };
  if (!customConfig.skipToken) {
    const token = getToken();
    if (token) {
      headers["Authorization"] = token;
    }
  }

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  return axios({
    url,
    data: body,
    ...config,
  });
}

export default client;
