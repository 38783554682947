import React from 'react';
import { Link } from 'react-router-dom';

const NewRequestSubmit = ({ refUid, formSubmitSuccessMsg }) => {
  return (
    <div className='d-flex justify-content-center flex-column'>
      {formSubmitSuccessMsg === 400 ? 
      <p> Subscription already exists in Trend Micro Deep Security</p>
      :
      <>
      <p>Your subscription request was submitted successfully</p>
      <p>Check your status using this ref#</p>
      </>
}
{formSubmitSuccessMsg === 200 ? <p>{refUid} </p> : null}
      <p>
        <button type='button' className='teal-filled-btn'>
          <Link to='/trend-micro-azure-requests'>Ok</Link>
        </button>
      </p>
    </div>
  );
};

export default NewRequestSubmit;
