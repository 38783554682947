import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

import Quotes from "./Quotes";
import GetStarted from "./GetStarted";
import Faq from "./Faq";
import Video from "./Video";
import AWSServiceStatus from "./AWSServiceStatus";

// Import Constant
import { USER_INFO } from "redux/user/userConstant";

import { video } from "static-contents/user-dashboard-video";
import AzureServiceStatus from "./AzureServiceStatus";
import GcpServiceStatus from "./GcpServiceStatus";

const UserDashboardIndex = () => {
  const dispatch = useDispatch();

  const { authState, oktaAuth } = useOktaAuth();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch]);

  return (
    <>
      {userInfo !== null && authState && authState.isAuthenticated && (
        <Container fluid className="mb-3">
          <Quotes />
          <Row>
            {/* <Col md={3} className="mb-3">
             
            </Col> */}
            <Col md={6} className="mb-3">
              <Faq />
            </Col>
            <Col md={3} className="">
              <Col md={12} className="mb-3">
                <AWSServiceStatus />
              </Col>
              <Col md={12} className="mb-3">
                <AzureServiceStatus />
              </Col>
              <Col md={12} className="mb-3">
                <GcpServiceStatus />
              </Col>
            </Col>
            <Col md={3}>
              <GetStarted />
              {video.map((data, index) => (
                <Video videoLink={data} key={index} />
              ))}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default UserDashboardIndex;
