import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { postApiCall } from 'helper/postApiCall';
import Loader from 'components/Common/Loader';

const GitlabConfirm = ({ closeModal }) => {
  //translations
  const { t } = useTranslation();

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // API URL
  const apiUrl = `${process.env.REACT_APP_GITLAB_ACTIVATION}`;

  // ID Token
  const idToken = `${userInfo.idToken}`;

  // Request Body
  const requestBody = `{"email":"${userInfo.email}"}`;

  // Component State
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [showMessage, setShowMessage] = useState(false);

  function gitlabActivate() {
    setLoading(true);
    postApiCall(apiUrl, idToken, requestBody).then((response) => {
      console.log(response.data);
      setResponseData(response.data.message);
      setLoading(false);
      setShowMessage(true);
    });
  }
  return (
    <>
      <div className='d-flex justify-content-center flex-column'>
        {loading && <Loader />}
        {loading === false && showMessage === false && (
          <>
            <p>{t("Are you sure you want to activate?")}</p>
            <ul className='btn-ul'>
              <li>
                <button
                  type='button'
                  className='teal-filled-btn'
                  onClick={() => {
                    gitlabActivate();
                  }}
                >
                  {t("Confirm")}
                </button>
              </li>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    closeModal(false);
                  }}
                >
                  {t("Cancel")}
                </button>
              </li>
            </ul>
          </>
        )}
      </div>

      {showMessage && (
        <div className='d-flex justify-content-center flex-column'>
          <p>{responseData}</p>

          <ul className='btn-ul'>
            <li>
              <button
                className='teal-filled-btn'
                onClick={() => {
                  closeModal(false);
                }}
              >
                Ok
              </button>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default GitlabConfirm;
