import Api from "api/api";
import client from "api/client";
import { prearePostRequest } from "api/cloudeye";

export function resourceQueryApi(values) {
  return client(Api.resourceQuery, {
    ...prearePostRequest(values),
  }).then((res) => res.data);
}
export function numberWithCommas(x) {
  return x.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}
