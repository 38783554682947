import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form, InputGroup, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import CustomModal from 'components/Modal/CustomModal';
import TextInput from 'components/Forms/TextInput';

import Cancel from './Cancel';
import Submit from './Submit';

import DangerAlert from 'components/Message/DangerAlert';

// Validator
import { networkHubValiadtor } from './validator';

// Helper
import { getApiCall } from 'helper/getApiCall';
import { postApiCall } from 'helper/postApiCall';

// Static Data
import { awsRegionData } from 'pages/CIDR/region-data';

const AddRequest = () => {
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // Common Variable
  const idToken = `${userInfo.idToken}`;

  // React Select Option
  const customStyles = {
    control: (base) => ({
      ...base,
      border: '1px solid red',
    }),
  };

  // Data Input Error
  const [dataInputError, setDataInputError] = useState({});

  // Common State
  const [loading, setLoading] = useState(true);

  // Component State
  const [showCancel, setShowCancel] = useState(false);
  const [showSubmit, setshowSubmit] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancel(value);
  }, []);

  // Form State
  const [sourceAccountNumber, setSourceAccountNumber] = useState('');
  const [sourceCIDR, setSourceCIDR] = useState('');
  const [sourceRegion, setSourceRegion] = useState('');
  const [sourceTransitGateway, setsourceTransitGateway] = useState('');

  const [destinationAccountNumber, setDestinationAccountNumber] = useState('');
  const [destinationCIDR, setDestinationCIDR] = useState('');
  const [destinationRegion, setDestinationRegion] = useState('');
  const [destinationTransitGateway, setDestinationTransitGateway] =
    useState('');

  const [formSubmitMessage, setFormSubmitMessage] = useState(null);

  // Form Submit
  function formSubmit() {
    const formApiUrl = `${process.env.REACT_APP_NETWORK_HUB_ADD}`;
    const errors = networkHubValiadtor({
      sourceAccountNumber,
      sourceCIDR,
      sourceRegion,
      sourceTransitGateway,
      destinationAccountNumber,
      destinationCIDR,
      destinationRegion,
      destinationTransitGateway,
    });
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setLoading(true);
      const currentEpochTime = Date.now();
      const formRequestBody = {
        uid: `u-${currentEpochTime}`,
        submittedBy: `${userInfo.email}`,
        sourceAccountnumber: `${sourceAccountNumber}`,
        sourceRegion: `${sourceRegion}`,
        sourceCIDR: `${sourceCIDR}`,
        sourceTGA: `tgw-attach-${sourceTransitGateway}`,
        destinationAccountnumber: `${destinationAccountNumber}`,
        destinationRegion: `${destinationRegion}`,
        destinationCIDR: `${destinationCIDR}`,
        destinationTGA: `tgw-attach-${destinationTransitGateway}`,
        requestStatus: 'pending',
      };

      postApiCall(formApiUrl, idToken, formRequestBody)
        .then((response) => {
          if (response.data.message !== '') {
            setFormSubmitMessage(response.data.message);
          } else {
            setFormSubmitMessage(
              'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
            );
          }
          setshowSubmit(true);
          setLoading(false);
        })
        .catch((err) => {
          setFormSubmitMessage(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setshowSubmit(true);
          setLoading(false);
        });
    }
  }

  // Account Number State & API State
  const [accountNumberArray, setaccountNumberArray] = useState([]);
  const [accountsApiError, setAccountsApiError] = useState(null);

  const accountApiUrl = `${process.env.REACT_APP_AWS_CIDR_ADD_REQUEST_ACCOUNT_NUMBER_NAME}`;

  useEffect(() => {
    setLoading(true);

    getApiCall(accountApiUrl, idToken)
      .then((response) => {
        const accountNumberOptions = response.data
          .filter((f) => f !== '')
          .map((d) => ({
            value: d.split(' - ')[0],
            label: d.split(' - ')[0],
          }));

        setaccountNumberArray(accountNumberOptions);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAccountsApiError(
          'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
        );
      });

    return () => {
      setaccountNumberArray([]);
    };
  }, []);

  return (
    <>
      <Row>
        <Col className='mb-2'>
          <h5 className='page-content-title'>Request New Connection</h5>
        </Col>
      </Row>
      {loading && (
        <Row>
          <Col>
            <div className='d-flex justify-content-center'>
              <SpinnerLoader />
            </div>
          </Col>
        </Row>
      )}
      {loading === false && accountsApiError !== null && (
        <DangerAlert message={accountsApiError} />
      )}
      {loading === false && accountsApiError === null && (
        <>
          <Row>
            <Col md={12} className='mb-3'>
              <div className='subtitle-text'>Source AWS Account Details</div>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-2'>
              <Form.Group controlId='accountNo'>
                <Form.Label>Select Account:</Form.Label>
                <Select
                  name='accounNumber'
                  placeholder='Account Number'
                  searchable={true}
                  value={{ label: sourceAccountNumber }}
                  isClearable={true}
                  onChange={(value) => {
                    const accNum = value !== null ? value.value : '';
                    setSourceAccountNumber(accNum);
                  }}
                  options={accountNumberArray}
                  styles={dataInputError.sourceAccountNumber && customStyles}
                />

                <p className='custom-error-message'>
                  {dataInputError.sourceAccountNumber}
                </p>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-2'>
              <TextInput
                label='Enter CIDR'
                placeholder='Enter CIDR'
                value={sourceCIDR}
                onChange={(e) => setSourceCIDR(e.target.value)}
                isInvalid={dataInputError.sourceCIDR ? true : false}
                invalidMessage={dataInputError.sourceCIDR}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-2'>
              <Form.Group controlId='selectRegion'>
                <Form.Label>Select Region</Form.Label>
                <Form.Select
                  value={sourceRegion}
                  onChange={(e) => setSourceRegion(e.target.value)}
                  isInvalid={dataInputError.sourceRegion ? true : false}
                >
                  <option value=''>None</option>
                  {awsRegionData.map((data, index) => (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  {dataInputError.sourceRegion}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-2'>
              {/* <TextInput
                label='Enter Transit Gateway Attachment ID'
                placeholder='Enter Transit Gateway Attachment ID'
                value={sourceTransitGateway}
                onChange={(e) => setsourceTransitGateway(e.target.value)}
                isInvalid={dataInputError.sourceTransitGateway ? true : false}
                invalidMessage={dataInputError.sourceTransitGateway}
              /> */}

              <Form.Label>Enter Transit Gateway Attachment ID</Form.Label>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='basic-addon1'>tgw-attach-</InputGroup.Text>
                <FormControl
                  placeholder='Enter Transit Gateway Attachment ID'
                  aria-label='sourceTGA'
                  value={sourceTransitGateway}
                  isInvalid={dataInputError.sourceTransitGateway ? true : false}
                  onChange={(e) => setsourceTransitGateway(e.target.value)}
                />
              </InputGroup>
              <div className='custom-error-message-text'>
                {dataInputError.sourceTransitGateway}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className='mb-3'>
              <div className='subtitle-text'>
                Destination AWS Account Details
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-2'>
              <Form.Group controlId='accountNo'>
                <Form.Label>Select Account:</Form.Label>
                <Select
                  name='accounNumber'
                  placeholder='Account Number'
                  searchable={true}
                  value={{ label: destinationAccountNumber }}
                  isClearable={true}
                  onChange={(value) => {
                    const accNum = value !== null ? value.value : '';
                    setDestinationAccountNumber(accNum);
                  }}
                  options={accountNumberArray}
                  styles={
                    dataInputError.destinationAccountNumber && customStyles
                  }
                />

                <p className='custom-error-message'>
                  {dataInputError.destinationAccountNumber}
                </p>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-2'>
              <TextInput
                label='Enter CIDR'
                placeholder='Enter CIDR'
                value={destinationCIDR}
                onChange={(e) => setDestinationCIDR(e.target.value)}
                isInvalid={dataInputError.destinationCIDR ? true : false}
                invalidMessage={dataInputError.destinationCIDR}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-2'>
              <Form.Group controlId='selectRegion'>
                <Form.Label>Select Region</Form.Label>
                <Form.Select
                  value={destinationRegion}
                  onChange={(e) => setDestinationRegion(e.target.value)}
                  isInvalid={dataInputError.destinationRegion ? true : false}
                >
                  <option value=''>None</option>
                  {awsRegionData.map((data, index) => (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  {dataInputError.destinationRegion}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-2'>
              {/* <TextInput
                label='Enter Transit Gateway Attachment ID'
                placeholder='Enter Transit Gateway Attachment ID'
                value={destinationTransitGateway}
                onChange={(e) => setDestinationTransitGateway(e.target.value)}
                isInvalid={
                  dataInputError.destinationTransitGateway ? true : false
                }
                invalidMessage={dataInputError.destinationTransitGateway}
              /> */}
              <Form.Label>Enter Transit Gateway Attachment ID</Form.Label>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='basic-addon1'>tgw-attach-</InputGroup.Text>
                <FormControl
                  placeholder='Enter Transit Gateway Attachment ID'
                  aria-label='destinationTGA'
                  value={destinationTransitGateway}
                  isInvalid={
                    dataInputError.destinationTransitGateway ? true : false
                  }
                  onChange={(e) => setDestinationTransitGateway(e.target.value)}
                />
              </InputGroup>
              <div className='custom-error-message-text'>
                {dataInputError.destinationTransitGateway}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='d-flex flex-row mt-3'>
              <ul className='btn-ul'>
                <li>
                  <button
                    type='button'
                    className='teal-bordered-btn'
                    onClick={() => {
                      setShowCancel(true);
                    }}
                  >
                    Cancel
                  </button>
                </li>
                <li>
                  <button
                    type='button'
                    className='teal-filled-btn'
                    onClick={() => {
                      formSubmit();
                    }}
                  >
                    Submit
                  </button>
                </li>
              </ul>
            </Col>
          </Row>

          {showCancel && (
            <CustomModal
              showModal={true}
              children={<Cancel closeModalParent={closeModalCallback} />}
            />
          )}

          {showSubmit && (
            <CustomModal
              showModal={true}
              children={<Submit message={formSubmitMessage} />}
            />
          )}
        </>
      )}
    </>
  );
};

export default AddRequest;
