import React from 'react';
import { Carousel, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { quotes } from 'static-contents/user-dashboard-quotes';

const Quotes = () => {
  const {t} = useTranslation();
  const quotesItems = t("quotes_items");
  return (
    <>
      <Row>
        <div className='col-md-6 offset-md-3 mt-4'>
          <div className='user-dashboard-title text-center'>
            {t("welcomeToHubPortal")}
          </div>
        </div>
      </Row>
      <Row>
        <Col md={12} className='d-flex justify-content-center'>
          <Carousel
            pause='hover'
            controls={false}
            indicators={false}
            className='mt-3 mb-4'
          >
            {quotesItems.map((data, index) => (
              <Carousel.Item key={index}>
                <h4 className='user-dashboard-quotes-text'>{data}</h4>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </>
  );
};

export default Quotes;
