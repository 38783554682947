import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

// Import Constant
import { USER_INFO } from "redux/user/userConstant";

// Roles
import CloudEyeMonitor from "./CloudEyeMonitor";
import { useCloudListApplistApi } from "./dataloader";
import { CS_Hub_CloudEye } from "roles/roles";

const UserCloudEyeIndex = ({ history }) => {
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  useCloudListApplistApi();

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });

        const tempArray = [];
        for (var i = 0; i < info.groups.length; i++) {
          const searchTerm = CS_Hub_CloudEye ;
          const indexOfFirst = info.groups[i].indexOf(searchTerm);
          if (indexOfFirst > -1) {
            tempArray.push(indexOfFirst);
          }
        }

        if (tempArray.includes(-1) || tempArray.length === 0) {
          history.push("/landing");
        }
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch, history]);
  return (
    <>
      {userInfo !== null && authState && authState.isAuthenticated && (
        <>
          <div className="cloudeye-user-container mt-3">
            <CloudEyeMonitor />
          </div>
        </>
      )}
    </>
  );
};

export default UserCloudEyeIndex;
