import React from 'react';
import { Row, Col } from 'react-bootstrap';

const Video = ({ videoLink }) => {
  return (
    <Row>
      <Col md={12} className='mb-3'>
        <iframe
          width='100%'
          height='180'
          src={`${videoLink}`}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          title='Embedded youtube'
        />
      </Col>
    </Row>
  );
};

export default Video;
