import React from 'react';
import { useDispatch } from 'react-redux';

import { ACCOUNT_REQUEST_RELOAD } from 'redux/account-requests/accountRequestConstant';

const EditRequestSubmit = ({ message }) => {
  const dispatch = useDispatch();
  return (
    <div className='d-flex justify-content-center flex-column'>
      <p>{message}</p>
      <p>
        <button
          type='button'
          className='teal-filled-btn'
          onClick={() => {
            dispatch({
              type: ACCOUNT_REQUEST_RELOAD,
              payload: true,
            });
          }}
        >
          Ok
        </button>
      </p>
    </div>
  );
};

export default EditRequestSubmit;
