import React from 'react';

// Import Internal Components
import EditAccountContact from './EditAccountContact';
import EditBillingContact from './EditBillingContact';
import EditSecurityContact from './EditSecurityContact';
import EditTechnicalContact from './EditTechnicalContact';
import EditBillingInfo from './EditBillingInfo';
import EditGroupEmailAddress from './EditGroupEmailAddress';
import EditAccountDetails from './EditAccountDetails';

const EditFormSteps = (step, setStep, formData, setFormData) => [
  {
    title: 'Subscription details',
    content: (
      <EditAccountDetails
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
      />
    ),
  },
  {
    title: 'Subscription Contact',
    content: (
      <EditAccountContact
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
      />
    ),
  },
  {
    title: 'Security Contact',
    content: (
      <EditSecurityContact
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
      />
    ),
  },
  {
    title: 'Technical Contact',
    content: (
      <EditTechnicalContact
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
      />
    ),
  },
  {
    title: 'Billing Info',
    content: (
      <EditBillingInfo
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
      />
    ),
  },
];

export default EditFormSteps;
