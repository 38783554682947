import React, { useReducer, useEffect, useState } from "react";
import { AWSReducer, DefaultAWSData } from "../edit-aws-reducer";

import EditFormSteps from "./EditFormSteps";

const EditForm = ({ data, accountNumberNameData, accountNumberArray }) => {
  let [step, setStep] = useState(0);
  const [formData, setFormData] = useReducer(AWSReducer, DefaultAWSData);
  let steps = EditFormSteps(
    step,
    setStep,
    formData,
    setFormData,
    accountNumberNameData,
    accountNumberArray
  );

  useEffect(() => {
    // Account Contact
    setFormData({
      type: "UPDATE_BACKUP_REQUEST",
      payload: {
        [`accountNumber`]: data.accountNumber,
        [`accountName`]: data.accountName,
        [`region`]: data.region,
        [`drBackUp`]: data.drBackUp === "true" ? "yes" : "no",
        [`crossBackUp`]: data.crossBackUp === "true" ? "yes" : "no",
        [`backUpPlan`]: data.backUpPlan,
      },
    });
    // Request Status
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`requestStatus`]: data.requestStatus },
    });
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`uid`]: data.uid },
    });
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`aws_account_id`]: data.accountNumber },
    });
    // if (
    //   data.requestStatus === 'Approved' ||
    //   data.requestStatus === 'Update Declined'
    // ) {
    //   setFormData({
    //     type: 'UPDATE_COMMON_DETAILS',
    //     payload: { [`unEditableAccountName`]: data.accountName },
    //   });
    // }
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`RequestActionTakenBy`]: data.RequestActionTakenBy },
    });
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`submittedBy`]: data.submittedBy },
    });
  }, []);
  return <>{steps[`${step}`].content}</>;
};

export default EditForm;
