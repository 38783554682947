import React, { useState, useCallback } from "react";
import { Row, Col, Form, Table, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Loader from "components/Common/Loader";
import EnableAccountFormMenu from "./EditEnableAccountFormMenu";

import NewRequestSubmit from "./NewRequestSubmit";
import CustomModal from "components/Modal/CustomModal";
import NewRequestCancel from "./NewRequestCancel";

// Helper
import { postApiCall } from "helper/postApiCall";

function ReviewAccount({
  step,
  setStep,
  formData,
  setFormData,
  selectedAccounts,
  instanceList,
  handleEditWindowClose,
}) {
  const { t } = useTranslation();

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // Set ID Token Globally
  const idToken = userInfo.idToken;

  const selectedInstances = useSelector(
    (state) => state.awsHostPatching?.selectedInstancesList
  );

  // Response Data State
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [newRequestUid, setNewRequestUid] = useState(null);

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);
  // Form Submit State
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const enhancedData = instanceList.filter((item) =>
    selectedInstances?.includes(item.InstanceId)
  );

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitLoading(true);

    // Form Submission
    const currentEpochTime = Date.now();

    const formSubmitApiUrl = `${process.env.REACT_APP_SSM_PATCHING_EDIT_REQUEST_SUBMIT}`;

    const requestBody = {
      accountname: selectedAccounts?.accountname,
      accountnumber: selectedAccounts?.accountnumber,
      week: formData?.installatonSchedule?.week,
      dow: formData?.installatonSchedule?.dow,
      hour: formData?.installatonSchedule?.startDateHour,
      instances: selectedInstances,
      minute: formData?.installatonSchedule?.startDateMinute,
      requestStatus: "Pending",
      uid: selectedAccounts?.uid,
      submittedBy: userInfo.email,
    };

    postApiCall(formSubmitApiUrl, idToken, requestBody)
      .then((response) => {
        if (response.data.status === "Success") {
          setNewRequestUid(`u-${currentEpochTime}`);
          setFormSubmitSuccess(true);
          setFormSubmitLoading(false);
        }
      })
      .catch((err) => {
        setFormSubmitLoading(false);
        setApiError(t("failed_to_retrieve_data"));
      });
  };
  return (
    <>
      <Row>
        <Col md={12} xs={12}>
          <EnableAccountFormMenu
            installatonSchedule
            targetInstance
            reviewAccount
          />
        </Col>
      </Row>

      <Row>
        <span className="page-content-title mt-4 mb-2">
          Kindly check and Proceed further
        </span>
        <Col md={12} xs={12}>
          <div className="review-acc-details mb-2 mt-3">
            <h5 className="subtitle-text d-inline  fw-bold">
              {t("Account Number:")}
            </h5>
            <h5 className="subtitle-text d-inline  ms-2 fw-bold">
              {selectedAccounts?.accountnumber}
            </h5>
          </div>
          <div className="review-acc-details mb-2">
            <h5 className="subtitle-text d-inline  fw-bold">
              {t("Provided Time - ")}
            </h5>
            <h5 className="subtitle-text d-inline ms-2 fw-bold">{`Day: ${formData?.installatonSchedule?.startDate} | Hour: ${formData?.installatonSchedule?.startDateHour} | Minutes: ${formData?.installatonSchedule?.startDateMinute} (UTC)`}</h5>
          </div>
          <div className="review-acc-details mb-4">
            <h5 className="subtitle-text d-inline  fw-bold">
              {t("Scan Schedule:")}
            </h5>
            <h5 className="subtitle-text d-inline  ms-2 fw-bold">
              “First Saturday of Every month at 08:30 AM UTC”
            </h5>
          </div>

          <h5 className="subtitle-text d-inline  fw-bold">
            {t("Selected Targets")}
          </h5>
          <Table
            bordered={true}
            size="sm"
            className="account-confirmation-table"
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Instance Id</th>
                <th>Display Name</th>
                <th>SSM Status</th>
              </tr>
            </thead>
            <tbody>
              {enhancedData.map((item, index) => (
                <>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.InstanceId}</td>
                    <td>{item?.DisplayName}</td>
                    <td>{item?.["SSM Status"]}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </Col>
        <div className="col-md-12 mt-4">
          <Col md={12}>
            <div className="d-flex justify-content-center">
              {formSubmitLoading === false && apiError !== null && (
                <Alert variant="danger">{apiError}</Alert>
              )}
            </div>
          </Col>
          <div className="col-md-5 offset-md-7">
            {formSubmitLoading === true && (
              <div className="mb-3">
                <Loader />
              </div>
            )}
          </div>
          {formSubmitLoading === false && (
            <ul className="px-0 btn-ul justify-content-start">
              <li>
                <button
                  type="button"
                  className="teal-bordered-btn"
                  onClick={() => setStep(step - 1)}
                >
                  {t("back")}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="teal-bordered-btn"
                  onClick={() => {
                    setShowCancelModal(true);
                  }}
                >
                  {t("cancel")}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  className="teal-filled-btn"
                >
                  {t("save_submit")}
                </button>
              </li>
            </ul>
          )}
        </div>
      </Row>

      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={
            <NewRequestCancel
              closeModalParent={closeModalCallback}
              handleEditWindowClose={handleEditWindowClose}
            />
          }
        />
      )}
      {formSubmitSuccess && (
        <CustomModal
          showModal={true}
          children={<NewRequestSubmit refUid={newRequestUid} />}
        />
      )}
    </>
  );
}

export default ReviewAccount;
