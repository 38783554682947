import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';

import backicon from 'icons/back.svg';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import Pagination from 'components/Pagination/Pagination';

// Import Constant
import { MORIARTY } from '../../redux/dashboard/dashboardConstant';

// Roles
import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

const MoriartyCanvas = ({ title, envType, userInfo }) => {
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  const dispatch = useDispatch();
  function hideCanvas() {
    dispatch({
      type: MORIARTY,
      payload: false,
    });
  }

  // Pagination Part
  let PageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch Data
  const fetchData = useCallback(() => {
    const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

    const requestBody = {
      envtype: envType,
    };

    if (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: setIdToken,
        },
      };

      axios
        .post(`${process.env.REACT_APP_MORIARTY_DETAILS}`, requestBody, config)
        .then((response) => {
          setResponseData(response.data);
          setLoading(false);
        });
    }
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return responseData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, responseData, PageSize]);

  useEffect(() => {
    fetchData();
    return () => {
      setResponseData([]);
    };
  }, [fetchData]);

  return (
    <div>
      <div className='offcanvas-details-wrapper pb-5'>
        {loading && (
          <Row>
            <Col className='d-flex justify-content-center align-items-center'>
              <SpinnerLoader />
            </Col>
          </Row>
        )}
        {loading === false && (
          <>
            <div className='offcanvas-details-top-bar'>
              <div
                className='offcanvas-details-back-heading'
                style={{ width: '60%' }}
              >
                <img
                  src={backicon}
                  alt='Back'
                  className='view-edit-box-back-btn'
                  onClick={() => hideCanvas()}
                />
                <h5 className='view-edit-box-title d-flex align-items-center'>
                  {title} - {envType} Accounts
                </h5>
              </div>
            </div>
            {/* records */}

            <Table
              responsive
              className='mt-3 mb-0 table-centered table-nowrap table-borderless'
            >
              <thead className='offcanvas-details-table-head-font'>
                <tr className='mb-2'>
                  <td>AWS ACCOUNT NAME</td>
                  <td>AWS ACCOUNT ID</td>
                  <td>LAST RUN DATE</td>
                  {/* <td>ENVIRONMENT</td> */}
                  <td>FAILURE REASON</td>
                </tr>
              </thead>
              <tbody className='offcanvas-details-table-body-font'>
                {currentTableData.map((data, index) => (
                  <tr key={index} className='mb-3'>
                    <td>{data.AccountName}</td>
                    <td>{data.AccountId}</td>
                    <td>
                      {moment(data.ExecutionDate, 'YYYY-MM-DD').format(
                        'MMM DD, YYYY'
                      )}
                    </td>
                    {/* <td>{data.Envtype}</td> */}
                    <td>
                      <Link to='/aws-moriarty'>
                        Click here for more details
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className='d-flex justify-content-end align-items-baseline mt-3'>
              <Pagination
                className='pagination-bar'
                currentPage={currentPage}
                totalCount={responseData.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
                currentTableDataLength={currentTableData.length}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MoriartyCanvas;
