import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ACCOUNT_REQUEST_RELOAD } from 'redux/account-requests/accountRequestConstant';

const EditRequestCancel = ({ closeModalParent }) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  return (
    <div className='d-flex justify-content-center flex-column'>
      <p>{t("data_loss_warning")}</p>
      <p>{t("sure_to_continue")}</p>
   
      <ul className='btn-ul'>
        <li>
          <button
            type='button'
            className='teal-bordered-btn'
            onClick={() => {
              closeModalParent(false);
            }}
          >
            No
          </button>
        </li>
        <li>
          <button
            type='button'
            className='teal-filled-btn'
            onClick={() => {
              dispatch({
                type: ACCOUNT_REQUEST_RELOAD,
                payload: true,
              });
            }}
          >
            Yes
          </button>
        </li>
      </ul>
    </div>
  );
};

export default EditRequestCancel;
