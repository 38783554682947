import Loader from "components/Common/Loader";
import React, { useCallback } from "react";

const VersionHistoryModal = ({
  versionData,
  setShowVersionHistory = () => {},
}) => {
  const VersionTable = useCallback(() => {
    console.log(versionData);
    return (
      <div className="w-100 version-history">
        <div className="header">
          <h6 className="float-start">Vesion history</h6>
          <span
            className="float-end custom-pointer"
            onClick={() => {
              setShowVersionHistory(false);
            }}
          >
            x
          </span>
        </div>
        <div>
          <table className="table p-5 fs-13 mb-5 table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">Modified On</th>
                <th scope="col">Modified At</th>
                <th scope="col">Modified By</th>
              </tr>
            </thead>
            <tbody>
              {versionData?.map((item, i) => (
                <tr>
                  <td scope="row">{i + 1}</td>
                  <td>{item?.updateddate || "-"}</td>
                  <td>{item?.updatedtime || "-"}</td>
                  <td>{item?.updatedby || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }, [versionData]);

  return <>{!versionData?.length ? <Loader /> : VersionTable()}</>;
};

export default VersionHistoryModal;
