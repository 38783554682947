import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Import Internal Component
import EditForm from './Edit/EditForm';
import { TAB_ERROR_VALUES } from "redux/edit-window/editValueConstant.js";

// Import Icons
import backicon from 'icons/back.svg';

const AccountRequestsEdit = ({ data, cloudTitle, closeEditParent }) => {
  const dispatch = useDispatch()
  const handleClickBack = () => {
    closeEditParent(false);
    dispatch({
      type: TAB_ERROR_VALUES,
      payload: [],
    });
  }
  return (
    <div className='view-edit-box-conatiner'>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => handleClickBack()}
          />
          <h5 className='view-edit-box-title'>{cloudTitle} Account Details</h5>
          {data.requestStatus === 'Approved' && (
            <span className='view-edit-box-accountid mx-3'>
              Account ID: {data.aws_account_id}
            </span>
          )}
        </div>
      </div>
      <div className='table-edit-box-content px-1 mt-4 mb-5'>
        <EditForm data={data} />
      </div>
    </div>
  );
};

export default AccountRequestsEdit;
