const staticbillingRegion = [
  { label: "AM", value: "AM" },
  { label: "AP", value: "AP" },
  { label: "CN", value: "CN" },
  { label: "EU", value: "EU" },
  { label: "JP", value: "JP" },
  { label: "IN", value: "IN" },
];

const staticspend = [
  { label: "$1,000", value: "1000" },
  { label: "$5,000", value: "5000" },
  { label: "$50,000", value: "50000" },
  { label: "$75,000", value: "75000" },
  { label: "$100,000", value: "100000" },
  { label: "$150,000", value: "150000" },
  { label: "$200,000", value: "200000" },
  { label: "$250,000", value: "250000" },
  { label: "$300,000", value: "300000" },
  { label: "$350,000", value: "350000" },
  { label: "$400,000", value: "400000" },
  { label: "$450,000", value: "450000" },
  { label: "$500,000", value: "500000" },
];

const staticbillto = [
  { label: "Global IS (GISC)", value: "Global IS (GISC)" },
  { label: "Other Teams", value: "Other Teams" },
];

const trueFalseData = [
  { label: "No", value: "False" },
  { label: "Yes", value: "True" },
];

const staticEnvironmentType = [
  { label: "Development", value: "Development" },
  { label: "Non-Production", value: "Non-Production" },
  { label: "POC", value: "POC" },
  { label: "Production", value: "Production" },
  { label: "Staging", value: "Staging" },
  { label: "Test", value: "Test" },
  { label: "Sandbox", value: "Sandbox" },
  { label: "UAT", value: "UAT" },
];

const staticAssetUserBase = [
  { label: "B2B", value: "B2B" },
  { label: "B2C", value: "B2C" },
  { label: "Internal", value: "Internal" },
  { label: "None", value: "None" },
];

const staticDataClassification = [
  { label: "Confidential", value: "Confidential" },
  { label: "Customer PII", value: "Customer PII" },
  { label: "Employee PII", value: "Employee PII" },
  { label: "Intelectual Property", value: "Intelectual Property" },
  { label: "Public", value: "Public" },
  { label: "Secret", value: "Secret" },
  { label: "Sensitive PI", value: "Sensitive PI" },
];

export {
  staticbillingRegion,
  staticspend,
  staticbillto,
  trueFalseData,
  staticEnvironmentType,
  staticAssetUserBase,
  staticDataClassification,
};
