import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { TAB_ERROR_VALUES } from "redux/edit-window/editValueConstant.js";
import { groupEmailAddressValidate, accountContactValidate, billingContactValidate, securityContactValidate, technicalContactValidate, billingInfoValidate, accountDetailsValidate } from 'pages/AccountUpdate/Validator/validator';

const EditFormMenu = ({
  accountContact,
  billingContact,
  securityContact,
  technicalContact,
  billingInfo,
  groupEmailAddress,
  accountDetails,
  setStep,
  formData,
  setDataInputError,
  step,
  isApplicationNameTouched = false,
}) => {
  const dispatch = useDispatch()
  const tabErrorValues = useSelector(
    (state) => state?.editValue?.tabErrorReduxValues
  );

  const validationOrder = [ accountContactValidate, billingContactValidate, securityContactValidate, technicalContactValidate, billingInfoValidate, groupEmailAddressValidate, accountDetailsValidate ]

  const formDataOrder = [ 'accountContact', 'billingContact', 'securityContact', 'technicalContact', 'billingInfo', 'groupEmailAddress', 'accountDetails' ]

const formMenuList = [  ]
  const handleMenuClick = (value) => {
    debugger
    const errors = validationOrder[step](formData[formDataOrder[step]], isApplicationNameTouched);
    setDataInputError(errors);
    let filteredErrorValues;

    if(tabErrorValues?.includes(value)){
      filteredErrorValues = tabErrorValues.filter(item => item !== value)
    }

    if (Object.keys(errors).length > 0) {
      return;
    } else {
      dispatch({
        type: TAB_ERROR_VALUES,
        payload: filteredErrorValues,
      });
      setStep(value)
    }
  }

  return (
    <Row>
    <Col md={4}>
      <button
      className={`${accountContact
          ? 'view-edit-box-form-title-btn-active'
          : 'view-edit-box-form-title-btn'} ${tabErrorValues?.includes(0) ? 'edit-module-validation-error' : ''}`
      }
      onClick={() => handleMenuClick(0)}
      >
        Account Contact
      </button>
    </Col>
    <Col md={4}>
      <button
      className={`${billingContact
        ? 'view-edit-box-form-title-btn-active'
        : 'view-edit-box-form-title-btn'} ${tabErrorValues?.includes(1) ? 'edit-module-validation-error' : ''}`
    }
    onClick={() => handleMenuClick(1)}
      >
        Billing Contact
      </button>
    </Col>
    <Col md={4}>
      <button
      className={`${securityContact
        ? 'view-edit-box-form-title-btn-active'
        : 'view-edit-box-form-title-btn'} ${tabErrorValues?.includes(2) ? 'edit-module-validation-error' : ''}`
    }
    onClick={() => handleMenuClick(2)}
      >
        Security Contact
      </button>
    </Col>
    <Col md={4}>
      <button
      className={`${technicalContact
        ? 'view-edit-box-form-title-btn-active'
        : 'view-edit-box-form-title-btn'} ${tabErrorValues?.includes(3) ? 'edit-module-validation-error' : ''}`
    }
    onClick={() => handleMenuClick(3)}
      >
        Technical Contact
      </button>
    </Col>
    <Col md={4}>
      <button
      className={`${billingInfo
        ? 'view-edit-box-form-title-btn-active'
        : 'view-edit-box-form-title-btn'} ${tabErrorValues?.includes(4) ? 'edit-module-validation-error' : ''}`
    }
    onClick={() => handleMenuClick(4)}
      >
        Billing Info
      </button>
    </Col>
    <Col md={4}>
      <button
      className={`${groupEmailAddress
        ? 'view-edit-box-form-title-btn-active'
        : 'view-edit-box-form-title-btn'} ${tabErrorValues?.includes(5) ? 'edit-module-validation-error' : ''}`
    }
    onClick={() => handleMenuClick(5)}
      >
        Group Email Address
      </button>
    </Col>
    <Col md={4}>
      <button
      className={`${accountDetails
        ? 'view-edit-box-form-title-btn-active'
        : 'view-edit-box-form-title-btn'} ${tabErrorValues?.includes(6) ? 'edit-module-validation-error' : ''}`
    }
    onClick={() => handleMenuClick(6)}
      >
        Account Details
      </button>
    </Col>
  </Row>
  );
};

export default EditFormMenu;
