import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import SpinnerLoader from 'components/Common/SpinnerLoader';

const Home = () => {
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      oktaAuth.signInWithRedirect({ originalUri: '/landing' });
    }
  }, [authState, oktaAuth]);

  return (
    <div className='d-flex justify-content-center align-items-center custom-landing-loader'>
      <SpinnerLoader />
    </div>
  );
};

export default Home;
