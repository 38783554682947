import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function WarningMessage({
  handleClose,
  setIsDisclaimerAgreed,
  isDisclaimerAgreed,
  setAgreedPlan,
  setFormData,
  fieldName,
  edit = false,
  children,
}) {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-center flex-column">
      {children}
      <Form.Check
        className="review-confirm-modal-check"
        type="checkbox"
        label={<div className="ms-2 fs-13">I agree to continue</div>}
        defaultChecked={isDisclaimerAgreed}
        onChange={(e) => {
          setIsDisclaimerAgreed(!isDisclaimerAgreed);
        }}
      />
      <ul className="btn-ul">
        <li>
          <button
            type="button"
            className={`teal-filled-btn ${
              isDisclaimerAgreed ? "" : "disabled-div disabled-bg"
            }`}
            onClick={() => {
              handleClose(false);
              setAgreedPlan(true);
              if (fieldName !== "backUpPlan") {
                setFormData({
                  type: edit ? "UPDATE_BACKUP_REQUEST" : "UPDATE_BACKUP_PLAN",
                  payload: { [fieldName]: "yes" },
                });
              }
            }}
          >
            {t("yes")}
          </button>
        </li>
        <li>
          <button
            type="button"
            className="teal-bordered-btn"
            onClick={() => {
              handleClose(false);
              setAgreedPlan(false);
              if (fieldName !== "backUpPlan") {
                setFormData({
                  type: edit ? "UPDATE_BACKUP_REQUEST" : "UPDATE_BACKUP_PLAN",
                  payload: { [fieldName]: "no" },
                });
              }
            }}
          >
            {t("no")}
          </button>
        </li>
      </ul>
    </div>
  );
}

export default WarningMessage;
