import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next';

// Import Common Components
import TextInput from 'components/Forms/TextInput';
import Loader from 'components/Common/Loader';
import BillingCompanyDropdown from 'components/Forms/BillingCompanyDropdown';
import BillingSubCompanyDropdown from 'components/Forms/BillingSubCompanyDropdown';
import BillingDepartmentDropdown from 'components/Forms/BillingDepartmentDropdown';
import StaticDropdown from 'components/Forms/StaticDropdown';
import CustomModal from 'components/Modal/CustomModal';

// Import Internal Components
import FormProgress from './FormProgress';
import NewRequestCancel from './NewRequestCancel';

// Helper
import { getApiCall } from 'helper/getApiCall';

// Validator
import { billingInfoValidate } from '../../Validator/validator';

// Static Data
import {
  staticbillingRegion,
  staticspend,
  staticbillto,
} from '../StaticData/awsStaticData';

import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

const BillingInfo = ({ step, setStep, formData, setFormData }) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // Response Data State
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  // Component Form Data State
  const [billingSubOpCompany, setBillingSubOpCompany] = useState([]);
  const [billingDepartment, setBillingDepartment] = useState([]);
  const [billTo, setbillTo] = useState(null);

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // handle onchange
  const handleData = ({ target }) => {
    if (target.name === 'billingopco') {
      setFormData({
        type: 'UPDATE_BILLING_INFO',
        payload: {
          [`billingSubOpco`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_BILLING_INFO',
        payload: {
          [`billingDepartment`]: '',
        },
      });
      setBillingSubOpCompany([]);
      setBillingDepartment([]);
      let opCompany = target.value;
      if (opCompany !== '') {
        const getSubOpCompany = responseData.find(
          (t) => t.opco === opCompany
        ).subopcod;
        setBillingSubOpCompany(getSubOpCompany);
      }
    }
    if (target.name === 'billingSubOpco') {
      setFormData({
        type: 'UPDATE_BILLING_INFO',
        payload: {
          [`billingDepartment`]: '',
        },
      });
      setBillingDepartment([]);
      let opSubCompany = target.value;
      if (opSubCompany !== '') {
        const getDepartment = billingSubOpCompany.find(
          (t) => t.subopco === opSubCompany
        ).dept;
        setBillingDepartment(getDepartment);
      }
    }

    if (target.name === 'billto') {
      let getBillTo = target.value;
      setbillTo(getBillTo);
    }

    if (target.name === 'io') {
      setFormData({
        type: 'UPDATE_BILLING_INFO',
        payload: {
          [`costcenter`]: 'NA',
        },
      });
    }

    if (target.name === 'costcenter') {
      setFormData({
        type: 'UPDATE_BILLING_INFO',
        payload: {
          [`io`]: 'NA',
        },
      });
    }

    setFormData({
      type: 'UPDATE_BILLING_INFO',
      payload: { [target.name]: target.value },
    });
  };

  const handleContinue = (e) => {
    e.preventDefault();
    const errors = billingInfoValidate(formData.billingInfo);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setStep(step + 1);
    }
  };

  useEffect(() => {
    setLoading(true);

    // adding query string fr oauth
    const queryObj = `{
      "submittedBy": "${userInfo?.email}",
      "type_of_user": ${userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? 1 : 0}
    }`
    const encodedString = Buffer.from(queryObj).toString('base64');
    // end

    const apiUrl = `${process.env.REACT_APP_AZURE_ACCOUNT_REQUEST_BILLING_INFO}?enchash=${encodedString}`;
    const idToken = userInfo.idToken;
    getApiCall(apiUrl, idToken).then((response) => {
      setResponseData(response.data.body);
      setLoading(false);
    });
  }, []);
  const {t} = useTranslation();

  return (
    <>
      <Row>
        <Col md={12} xs={12}>
          <FormProgress
            accountContact
            billingContact
            securityContact
            technicalContact
          />
        </Col>
      </Row>
      <Row className='px-3'>
        <Col md={12} xs={12}>
          <h5 className='page-content-title mb-4'>{t("billing_info")}</h5>
        </Col>
      </Row>

      <Form onSubmit={handleContinue}>
        {loading && <Loader />}
        {loading === false && (
          <>
            <Row className='px-3'>
              <Col md={6}>
                <BillingCompanyDropdown
                  label={"*"+t("select_billing_operating_company")+":"}
                  defaultSelectMessage={t("select_op_company")}
                  name='billingopco'
                  value={formData.billingInfo.billingopco}
                  onChange={(e) => handleData(e)}
                  selectValues={responseData}
                  isInvalid={dataInputError.billingopco ? true : false}
                  invalidMessage={dataInputError.billingopco}
                />
              </Col>

              <Col md={6}>
                <BillingSubCompanyDropdown
                  label={"*"+t("billing_subop_company")+":"}
                  defaultSelectMessage={t("select_subop_company")}
                  name='billingSubOpco'
                  value={formData.billingInfo.billingSubOpco}
                  onChange={(e) => handleData(e)}
                  selectValues={billingSubOpCompany}
                  isInvalid={dataInputError.billingSubOpco ? true : false}
                  invalidMessage={dataInputError.billingSubOpco}
                />
              </Col>

              <Col md={6}>
                <BillingDepartmentDropdown
                  label={"*"+t("billing_dep")+":"}
                  defaultSelectMessage={t("select_department")}
                  name='billingDepartment'
                  value={formData.billingInfo.billingDepartment}
                  onChange={(e) => handleData(e)}
                  selectValues={billingDepartment}
                  isInvalid={dataInputError.billingDepartment ? true : false}
                  invalidMessage={dataInputError.billingDepartment}
                />
              </Col>

              <Col md={6}>
                <StaticDropdown
                  label={"*"+t("billing_region")+":"}
                  defaultSelectMessage={t("select_region")}
                  name='billingRegion'
                  value={formData.billingInfo.billingRegion}
                  onChange={(e) => handleData(e)}
                  selectValues={staticbillingRegion}
                  isInvalid={dataInputError.billingRegion ? true : false}
                  invalidMessage={dataInputError.billingRegion}
                />
              </Col>

              <Col md={6}>
                <StaticDropdown
                  label={"*"+t("max_monthly_expected_spend")+":"}
                  defaultSelectMessage={t("select_expected_spend")}
                  name='maxSpend'
                  value={formData.billingInfo.maxSpend}
                  onChange={(e) => handleData(e)}
                  selectValues={staticspend}
                  isInvalid={dataInputError.maxSpend ? true : false}
                  invalidMessage={dataInputError.maxSpend}
                />
              </Col>

              <Col md={6}>
                <StaticDropdown
                  label={"*"+t("bill_to")+":"}
                  defaultSelectMessage={t("bill_to_msg")}
                  name='billto'
                  value={formData.billingInfo.billto}
                  onChange={(e) => handleData(e)}
                  selectValues={staticbillto}
                  isInvalid={dataInputError.billto ? true : false}
                  invalidMessage={dataInputError.billto}
                />
              </Col>

              {billTo === 'Global IS (GISC)' && (
                <Col md={6}>
                  <TextInput
                    label='*IO:'
                    name='io'
                    value={formData.billingInfo.io}
                    onChange={(e) => handleData(e)}
                    placeholder='IO'
                    isInvalid={dataInputError.io ? true : false}
                    invalidMessage={dataInputError.io}
                  />
                </Col>
              )}

              {billTo === 'Other Teams' && (
                <Col md={6}>
                  <TextInput
                    label={"*"+t("cost_center")+":"}
                    name='costcenter'
                    value={formData.billingInfo.costcenter}
                    onChange={(e) => handleData(e)}
                    placeholder={t("cost_center")}
                    isInvalid={dataInputError.costcenter ? true : false}
                    invalidMessage={dataInputError.costcenter}
                  />
                </Col>
              )}
            </Row>
            <Row className='px-3'>
              <div className='col-md-5 offset-md-7'>
                <ul className='px-0 btn-ul'>
                  <li>
                    <button
                      type='button'
                      className='teal-bordered-btn'
                      onClick={() => setStep(step - 1)}
                    >
                      {t("back")}
                    </button>
                  </li>
                  <li>
                    <button
                      type='button'
                      className='teal-bordered-btn'
                      onClick={() => {
                        setShowCancelModal(true);
                      }}
                    >
                      {t("cancel")}
                    </button>
                  </li>
                  <li>
                    <button type='submit' className='teal-filled-btn'>
                      {t("save_continue")}
                    </button>
                  </li>
                </ul>
              </div>
            </Row>
          </>
        )}
      </Form>
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<NewRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

export default BillingInfo;
