import React from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import Logo
import oktaicon from 'icons/okta-icon.svg';

const OktaSidebar = () => {
  // Routing Variables
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const {t} = useTranslation();

  return (
    <div className='okta-sidebar-container'>
      <ul className='okta-sidebar-logo-list'>
        <li>
          <img src={oktaicon} alt='Okta' />
          <span className='okta-sidebar-title px-2'>Okta</span>
        </li>
      </ul>

      <>
        <div className='my-2'>
          <Link
            to='/okta-custom-role-view'
            className='okta-sidebar-list-title custom-pointer'
          >
            {t("custom_role")}
          </Link>
        </div>
        {(splitLocation[1] === 'okta-custom-role-view' ||
          splitLocation[1] === 'okta-custom-role-add') && (
          <ul className='okta-sidebar-link-list'>
            <li
              className={
                splitLocation[1] === 'okta-custom-role-view'
                  ? 'okta-sidebar-link-active px-3'
                  : 'okta-sidebar-link-inactive px-3'
              }
            >
              <Link to='/okta-custom-role-view'>{t("view_request")}</Link>
            </li>
            <li
              className={
                splitLocation[1] === 'okta-custom-role-add'
                  ? 'okta-sidebar-link-active px-3'
                  : 'okta-sidebar-link-inactive px-3'
              }
            >
              <Link to='/okta-custom-role-add'>{t("role_request")}</Link>
            </li>
          </ul>
        )}
      </>

      <div
        className={
          'okta-sidebar-list-title custom-pointer mt-2 ' +
          (splitLocation[1] === 'okta-view-assignment' ? 'active' : '')
        }
      >
        <Link to='/okta-view-assignment'>{t("view_assignment")}</Link>
      </div>
    </div>
  );
};

export default withRouter(OktaSidebar);
