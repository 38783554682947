import React, { useEffect, useState, useReducer, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// Component
import ViewAlarm from "./ViewAlarm";
import EFSSetAlarm from "./EFSSetAlarm";
import { Authority } from "components/Authority";
import ViewMetricsIcon from "components/Common/ViewMetricsIcon";
import ViewAlarmIcon from "components/Common/ViewAlarmIcon";
import SetAlarmIcon from "components/Common/SetAlarmIcon";
import EFSSetAlarmAll from "./EFSSetAlarmAll";

const initialState = { selectedRowId: "", action: "" };
function reducer(state, action) {
  switch (action.type) {
    case "view_alarm":
      return {
        ...state,
        action: "view_alarm",
        selectedRowId: action.id,
      };
    case "create_alarm":
      return {
        ...state,
        action: "create_alarm",
        selectedRowId: action.id,
      };
    case "clear_action":
      return {
        ...state,
        action: "",
        selectedRowId: "",
      };

    default:
      return state;
  }
}

const EFSDatatable = ({
  selectedApplicationName,
  resourceHealthData,
  cloudInfrastructureSelectedValue,
  allAppName,
}) => {
  const [resourceHealthArrayData, setResourceHealthArrayData] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showSetAlarm, setShowSetAlarm] = useState(false);

  // ! Close View Callback
  const closeViewCallback = useCallback((value) => {
    setShowSetAlarm(false);
  }, []);

  // ! Close Set Alarm Callback
  const closeSetAlarmCallback = useCallback((value) => {
    setShowSetAlarm(value);
  }, []);

  const columns = [
    {
      dataField: "EFS",
      text: "EFS",
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        const monitoringStatus = row.monitoringstatus;
        if (monitoringStatus) {
          return (
            <div>
              <span className="info-green" title="Monitored">
                <i class="fa fa-info-circle mr-3" aria-hidden="true"></i>
              </span>
              {row.EFS}
            </div>
          );
        } else {
          return (
            <div>
              <span className="info-red" title="Not Monitored">
                <i class="fa fa-info-circle mr-3" aria-hidden="true"></i>
              </span>
              {row.EFS}
            </div>
          );
        }
      },
    },
    {
      dataField: "EFSName",
      text: "EFS NAME",
      sort: true,
      searchable: true,
    },
    {
      dataField: "State",
      text: "STATE",
      sort: true,
      searchable: true,
    },
    {
      dataField: "Size",
      text: "SIZE",
      sort: true,
      searchable: true,
    },
    {
      dataField: "dummyActionField",
      text: "ACTION",
      formatter: (cellContent, row) => {
        return (
          <React.Fragment>
            <Link
              to={`/efs-metrics/${selectedApplicationName}/${row.EFS}/${row.EFSName}`}
            >
              <button type="button" className="teal-nobordered-btn">
                <ViewMetricsIcon />
              </button>
            </Link>
            <Authority name={selectedApplicationName} apps={allAppName}>
              <button
                type="button"
                className="teal-nobordered-btn"
                onClick={() => {
                  dispatch({ type: "view_alarm", id: row.EFS });
                }}
              >
                <ViewAlarmIcon />
              </button>
              <button
                type="button"
                className="teal-nobordered-btn"
                onClick={() => {
                  dispatch({ type: "create_alarm", id: row.EFS });
                }}
              >
                <SetAlarmIcon />
              </button>
            </Authority>
          </React.Fragment>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "efsUniqueId",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: resourceHealthArrayData.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    let getSelectedValuesObject = resourceHealthData.data.body.find(
      (o) => o.resource === cloudInfrastructureSelectedValue
    );
    let resourceHealthArray = [];

    for (var i = 0; i < getSelectedValuesObject.items.length; i++) {
      var resourceHealthObject = {};
      resourceHealthObject["efsUniqueId"] = i;
      resourceHealthObject["EFS"] = getSelectedValuesObject.items[i].EFS;
      resourceHealthObject["EFSName"] =
        getSelectedValuesObject.items[i].EFSName;
      resourceHealthObject["State"] = getSelectedValuesObject.items[i].State;
      resourceHealthObject["Size"] = getSelectedValuesObject.items[i].Size;
      resourceHealthObject["monitoringstatus"] =
        getSelectedValuesObject.items[i].monitoringstatus;

      resourceHealthArray.push(resourceHealthObject);
    }

    setResourceHealthArrayData(resourceHealthArray);
  }, [cloudInfrastructureSelectedValue]);
  return (
    <div className="custom-card mb-4">
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="efsUniqueId"
        columns={columns}
        data={resourceHealthArrayData}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="efsUniqueId"
            columns={columns}
            data={resourceHealthArrayData}
            search
          >
            {(toolkitProps) => (
              <>
                <Row className="d-flex align-items-center">
                  <Col md={7} className="mb-2">
                    <div className="subtitle-text">
                      <span className="text-uppercase">
                        {cloudInfrastructureSelectedValue}{" "}
                      </span>
                      Details
                    </div>
                  </Col>

                  {resourceHealthArrayData.length > 0 && (
                    <Col md={3} className="mb-2">
                      <div className="search-input-group">
                        <i className="fa fa-search"></i>
                        <SearchBar
                          {...toolkitProps.searchProps}
                          className="custom-search-input-form-control"
                        />
                      </div>
                    </Col>
                  )}
                  {resourceHealthArrayData.length > 0 && (
                    <>
                      <Col md={2} className="mb-2">
                        <button
                          type="button"
                          className="teal-filled-btn w-100"
                          onClick={() => setShowSetAlarm(true)}
                        >
                          <i className="fa fa-plus"></i> Set Alarm
                        </button>
                      </Col>
                    </>
                  )}
                </Row>

                <>
                  <Row>
                    <Col md={12} xl={12} className="mt-4">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"efsUniqueId"}
                          responsive
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={
                            "custom-table-head custom-pointer"
                          }
                          bodyClasses={"custom-table-body"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                    <Col>
                      {resourceHealthArrayData.length === 0 && (
                        <div className="custom-error-message text-center">
                          No Data to Display
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-3">
                    <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                      <div>
                        <PaginationTotalStandalone {...paginationProps} />
                      </div>
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>

      {/* View Alarm */}
      {state.action === "view_alarm" && (
        <ViewAlarm
          application={selectedApplicationName}
          namespace="AWS/EFS"
          closeViewParent={() => dispatch({ type: "clear_action" })}
          id={state.selectedRowId}
        />
      )}

      {/* Set Alarm */}
      {state.action === "create_alarm" && (
        <EFSSetAlarm
          application={selectedApplicationName}
          namespace="AWS/EFS"
          tableData={resourceHealthArrayData}
          closeSetAlarmParent={() => dispatch({ type: "clear_action" })}
          id={state.selectedRowId}
        />
      )}
      {/* Set Alarm */}
      {showSetAlarm && (
        <EFSSetAlarmAll
          application={selectedApplicationName}
          namespace='AWS/EFS'
          tableData={resourceHealthArrayData}
          closeSetAlarmParent={closeSetAlarmCallback}
          platform={state.platform}
        />
      )}
    </div>
  );
};

export default EFSDatatable;
