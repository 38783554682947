import React, { useState } from 'react';
import { Modal, Row, Col, Form } from 'react-bootstrap';
import moment from 'moment';
import { useSelector } from 'react-redux';

// Date Picker
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar, utils } from 'react-modern-calendar-datepicker';

// Components
import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

// Static Data
import { hoursData, minutesData } from './staticData';

// Helper
import { postApiCall } from 'helper/postApiCall';

// Validator
import { snoozeAlarmValidator } from './validator';

const SnoozeAlarm = ({ application, namespace, closeSnoozeParent }) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // Data Input Error
  const [dataInputError, setDataInputError] = useState({});

  // Date Picker Props
  const defaultValue = {
    from: null,
    to: null,
  };
  const [selectedDayRange, setSelectedDayRange] = useState(defaultValue);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Date Range Picker OnChange
  function datePickerOnChange(value) {
    setSelectedDayRange(value);

    if (value.from !== null && value.to !== null) {
      let mergeFromDate = `${value.from.year}-${value.from.month}-${value.from.day}`;
      let convertFromDay = moment(mergeFromDate).format('DD');
      let convertFromMonth = moment(mergeFromDate).format('MMM');
      let convertFromYear = moment(mergeFromDate).format('YYYY');
      setStartDate({
        day: convertFromDay,
        month: convertFromMonth,
        year: convertFromYear,
      });
      let mergeToDate = `${value.to.year}-${value.to.month}-${value.to.day}`;
      let convertToDay = moment(mergeToDate).format('DD');
      let convertToMonth = moment(mergeToDate).format('MMM');
      let convertToYear = moment(mergeToDate).format('YYYY');
      setEndDate({
        day: convertToDay,
        month: convertToMonth,
        year: convertToYear,
      });
    }
  }

  // Time Picker State
  const [startDateHour, setStartDateHour] = useState('');
  const [startDateMinute, setStartDateMinute] = useState('');
  const [endDateHour, setEndDateHour] = useState('');
  const [endDateMinute, setEndDateMinute] = useState('');

  // Form State
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);

  // Snooze Hanlder Submit
  function snoozeHandler() {
    // Validate
    const errors = snoozeAlarmValidator({
      startDate,
      endDate,
      startDateHour,
      startDateMinute,
      endDateHour,
      endDateMinute,
    });
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setLoading(true);
      const apiUrl = `${process.env.REACT_APP_CLOUD_EYE_SNOOZE_ALARM}`;
      const idToken = `${userInfo.idToken}`;
      const requestBody = {
        application: `${application}`,
        // namespace: `${namespace}`,
        StartTime: {
          minute: parseInt(startDateMinute),
          hour: parseInt(startDateHour),
          date: parseInt(startDate.day),
          month: `${startDate.month.toUpperCase()}`,
          year: parseInt(startDate.year),
        },
        EndTime: {
          minute: parseInt(endDateMinute),
          hour: parseInt(endDateHour),
          date: parseInt(endDate.day),
          month: `${endDate.month.toUpperCase()}`,
          year: parseInt(endDate.year),
        },
      };

      postApiCall(apiUrl, idToken, requestBody)
        .then((response) => {
          if (response.status === 200) {
            setResponseData(response.data.status);
          } else {
            setResponseData(
              'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
            );
          }
          setLoading(false);
        })
        .catch((err) => {
          setError(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setLoading(false);
        });
    }
  }

  return (
    <Modal size='lg' centered show={true}>
      <Modal.Body className='text-center'>
        {loading && (
          <Row>
            <Col>
              <SpinnerLoader />
            </Col>
          </Row>
        )}
        {loading === false && error !== null && (
          <Row>
            <Col className='mb-4'>
              <DangerAlert message={error} />
            </Col>
          </Row>
        )}
        {loading === false && responseData === null && (
          <Row>
            <Col md={6}>
              {startDate !== null && endDate !== null && (
                <div className='snooze-alarm-title'>
                  Start Date: {startDate.day}-{startDate.month}-{startDate.year}{' '}
                  - End Date: {endDate.day}-{endDate.month}-{endDate.year}
                </div>
              )}
              <div className='snooze-alarm-title'></div>
              <div className='snooze-alarm-title mt-3'>Select Date Range :</div>
              <Calendar
                value={selectedDayRange}
                onChange={(value) => {
                  setSelectedDayRange(value);
                  datePickerOnChange(value);
                }}
                colorPrimary='#3ac4aa'
                colorPrimaryLight='#ddf3ee'
                shouldHighlightWeekends
                minimumDate={utils().getToday()}
              />
            </Col>
            <Col md={6} className='mt-3'>
              <div className='snooze-alarm-title'>
                {startDateHour !== '' && startDateMinute !== '' && (
                  <span>
                    Start Time: {startDateHour}:{startDateMinute}
                  </span>
                )}
              </div>
              <div className='snooze-alarm-title'>Select Start Time</div>
              <div className='d-flex flex-row flex-wrap justify-content-center'>
                <Form.Group className='mb-3 px-1' controlId='startTime'>
                  <Form.Select
                    size='sm'
                    className='snooze-alarm-time-select'
                    value={startDateHour}
                    onChange={(e) => setStartDateHour(e.target.value)}
                    isInvalid={dataInputError.startDateHour ? true : false}
                  >
                    <option value=''>HH</option>
                    {hoursData.map((data, index) => (
                      <option key={index} value={data}>
                        {data}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback
                    type='invalid'
                    className='snooze-invalid-feedback'
                  >
                    {dataInputError.startDateHour}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mb-3 px-1' controlId='startTime'>
                  <Form.Select
                    size='sm'
                    className='snooze-alarm-time-select'
                    value={startDateMinute}
                    onChange={(e) => setStartDateMinute(e.target.value)}
                    isInvalid={dataInputError.startDateMinute ? true : false}
                  >
                    <option value=''>MM</option>
                    {minutesData.map((data, index) => (
                      <option key={index} value={data}>
                        {data}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback
                    type='invalid'
                    className='snooze-invalid-feedback'
                  >
                    {dataInputError.startDateMinute}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className='snooze-alarm-title'>
                {endDateHour !== '' && endDateMinute !== '' && (
                  <span>
                    End Time: {endDateHour}:{endDateMinute}
                  </span>
                )}
              </div>
              <div className='snooze-alarm-title'>Select End Time</div>
              <div className='d-flex flex-row flex-wrap justify-content-center'>
                <Form.Group className='mb-3 px-1' controlId='startTime'>
                  <Form.Select
                    size='sm'
                    className='snooze-alarm-time-select'
                    value={endDateHour}
                    onChange={(e) => setEndDateHour(e.target.value)}
                    isInvalid={dataInputError.endDateHour ? true : false}
                  >
                    <option value=''>HH</option>
                    {hoursData.map((data, index) => (
                      <option key={index} value={data}>
                        {data}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback
                    type='invalid'
                    className='snooze-invalid-feedback'
                  >
                    {dataInputError.endDateHour}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mb-3 px-1' controlId='startTime'>
                  <Form.Select
                    size='sm'
                    className='snooze-alarm-time-select'
                    value={endDateMinute}
                    onChange={(e) => setEndDateMinute(e.target.value)}
                    isInvalid={dataInputError.endDateMinute ? true : false}
                  >
                    <option value=''>MM</option>
                    {minutesData.map((data, index) => (
                      <option key={index} value={data}>
                        {data}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback
                    type='invalid'
                    className='snooze-invalid-feedback'
                  >
                    {dataInputError.endDateMinute}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div>
                <ul className='px-0 btn-ul'>
                  <li>
                    <button
                      type='button'
                      className='teal-bordered-btn'
                      onClick={() => closeSnoozeParent(false)}
                    >
                      Cancel
                    </button>
                  </li>
                  <li>
                    <button
                      type='button'
                      className='teal-filled-btn'
                      onClick={() => {
                        snoozeHandler();
                      }}
                    >
                      Submit
                    </button>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        )}

        {responseData !== null && loading === false && (
          <div>
            <p>
              <i className='fa fa-check-circle cidr-check-mark'></i>
            </p>
            <p>{responseData}</p>
            <p>
              <button
                type='button'
                className='teal-filled-btn'
                onClick={() => closeSnoozeParent(false)}
              >
                Ok
              </button>
            </p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SnoozeAlarm;
