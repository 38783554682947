import React from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from "react-table";
import Pagination from "react-bootstrap/Pagination";
import { Button, Col, Row } from "react-bootstrap";
import CustomModal from "components/Modal/CustomModal";
import RequestSubmitConfirmation from "./RequestSubmitConfirmation";
import EditableCell from "./EditableCell";
import { useDispatch, useSelector } from "react-redux";
import {
  DUPLICATED_DATA,
  GSID_SHOW_MANDATORY,
  SORTING_BOOLEAN,
  TABLE_REFRESH,
} from "redux/billing/EditableTableConstant";
import { postApiCall } from "helper/postApiCall";
import moment from "moment";
import GlobalFilter from "./GlobalFilter";
import MonthSelection from "./MonthSelection";
import CSVDownload from "./CSVDownload";
import {
  billingScreen,
  getISTTime,
  gsidIoHeaders,
} from "static-contents/billing-data";
import FileUpload from "../FileUpload/FileUpload";
import { CS_Hub_GCS_Chargeback_Admins } from "roles/roles";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AddEditForm from "./AddEditForm";

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};
const getDay = new Date().getDate();

// Be sure to pass our updateMyData and the skipPageReset option
function Table({
  columns,
  data,
  setData,
  updateMyData,
  skipPageReset,
  setOriginalData,
  setShowViewBox,
  setSelectedViewData,
  showViewBox,
  sortBy,
  removeSorting,
  saveDataApi,
  undoConfirmation,
  resetData,
  setLoading,
}) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    removeRow,
    defaultPageSize,
    addRow,
    state: { pageIndex, pageSize, globalFilter },
    setSortBy,
    setGlobalFilter,
    preGlobalFilteredRows,
  } = useTable(
    {
      columns,
      data,
      setData,
      setOriginalData,
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      removeRow: (rowIndex) => {
        const setFilterFunc = (old) =>
          old.filter((_row, index) => index !== rowIndex);
        setData(setFilterFunc);
        // setOriginalData(setFilterFunc);
      },
      addRow: (newRow) => {
        const setFunc = (old) => [newRow, ...old];
        setData(setFunc);
        // setOriginalData(setFunc);
      },
      initialState: {
        pageIndex: 0,
        pageSize: 15,
      },

      sortBy,
    },

    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const screenDate = useSelector((state) => state?.billing?.billingScreenDate);
  const getScreenAcess = billingScreen?.gsidScreenDate();
  const deleteApiUrl = `${process.env.REACT_APP_BILLING_GSIDIO_ADD_EDIT}`;
  const dispatch = useDispatch();
  const billingStateValues = useSelector((state) => state.billing);
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const idToken = userInfo.idToken;

  const [showConfirmationSave, setShowConfirmationSave] = React.useState(false);
  const [showValidationError, setShowValidationError] = React.useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(
    false
  );
  const [apiError, setApiError] = React.useState(null);
  const [deletableRow, setDeletableRow] = React.useState(null);
  const [dateObject] = React.useState(new Date());

  //file upload
  const [isFileUploadOpen, setFileUploadOpen] = React.useState(false);
  const [isEditOpen, setEditOpen] = React.useState(false);
  const [editSelectedRow, setEditSelectedRow] = React.useState({});

  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

  const AddNewButton = () => {
    // const handleAddNew = () => {
    //   // const newData = {
    //   //   firstName: "mk",
    //   //   billingAmount: 12545,
    //   //   requiredAmount: 5475,
    //   //   status: 789,
    //   // };
    //   dispatch({
    //     type: GSID_SHOW_MANDATORY,
    //     payload: {
    //       accountId: null,
    //       description: null,
    //       totalCredits: null,
    //     },
    //   });
    //   addRow({
    //     GSID: "",
    //     IO: "",
    //     "Billing Opco": "",
    //     "Billing Sub OpCo": "",
    //     "Billing Department": "",
    //     "Billing Region": "",
    //     "Billing First Name": "",
    //     "Billing Last Name": "",
    //     "Billing Email": "",
    //   });
    //   dispatch({
    //     type: TABLE_REFRESH,
    //     payload: !billingStateValues?.isTableRefresh,
    //   });
    // };
    const handleAddNew = () => {
      const newItem = {
        values: {
          GSID: "",
          IO: "",
          "Billing Opco": "",
          "Billing Sub OpCo": "",
          "Billing Department": "",
          "Billing Region": "",
          "Billing First Name": "",
          "Billing Last Name": "",
          "Billing Email": "",
        },
      };
      setEditSelectedRow(newItem);
      setEditOpen(true);
    };
    return (
      <div class="billing tooltip">
        {" "}
        <i
          class="fa fa-plus mx-2"
          onClick={() => {
            handleAddNew();
          }}
        ></i>
        <span class="tooltiptext">Add New</span>
      </div>
    );
  };

  const handleDelete = () => {
    // removeRow(deletableRow);
    // dispatch({
    //   type: TABLE_REFRESH,
    //   payload: !billingStateValues?.isTableRefresh,
    // });
    setLoading(true);
    setShowDeleteConfirmation(false);
    const requestBody = {
      requestType: "delete",
      actionType: "GSIDIO",
      updatedby: userInfo?.email,
      updateddate: getISTTime().getDate,
      updatedtime: getISTTime().getTime,
      data: [deletableRow?.values],
    };
    postApiCall(deleteApiUrl, idToken, requestBody)
      .then((response) => {
        if (response.data.status === "Success") {
          setLoading(false);
          setDeletableRow(null);
          resetData();
        }
      })
      .catch((err) => {
        setApiError(
          "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
        );
      });
  };

  const ActionButtonComponent = ({ selectedRow }) => {
    const handleDeleteConfirmation = () => {
      // setDeletableRow(selectedRow?.index);
      setDeletableRow(selectedRow);
      setShowDeleteConfirmation(true);
    };

    return (
      <>
        <div className="m-1 table--action-btn text-center d-flex justify-content-evenly">
          <button
            type="button"
            className=" py-1 px-1 delete-btn-billing  custom-no-data-message-text"
            onClick={() => {
              // setShowViewBox(!showViewBox);
              setEditSelectedRow(selectedRow);
              setEditOpen(true);
            }}
          >
            <i class="fa fa-edit utc-note" aria-hidden="true"></i>
          </button>
          <button
            type="button"
            className=" py-1 px-1 delete-btn-billing  custom-no-data-message-text"
            onClick={() => {
              handleDeleteConfirmation();
            }}
          >
            <i class="fa fa-trash text-danger" aria-hidden="true"></i>
          </button>
        </div>
      </>
    );
  };
  const saveApiCall = (async) => {
    saveDataApi(data);
    setShowConfirmationSave(false);
  };

  const SaveButton = ({ selectedRow }) => {
    const handleSaveValidation = () => {
      const submittedData = data;
      const madatoryFields = {
        gsid: null,
        io: null,
        opco: null,
        subOpco: null,
        department: null,
        region: null,
        firstName: null,
        lastName: null,
        email: null,
      };

      submittedData?.find((item) => {
        if (item["GSID"] === "" || !item["GSID"]) {
          madatoryFields.gsid = true;
          return true;
        } else if (item["IO"] === "" || !item["IO"]) {
          madatoryFields.io = true;
          return true;
        } else if (item["Billing Opco"] === "" || !item["Billing Opco"]) {
          madatoryFields.opco = true;
          return true;
        } else if (
          item["Billing Sub OpCo"] === "" ||
          !item["Billing Sub OpCo"]
        ) {
          madatoryFields.subOpco = true;
          return true;
        } else if (
          item["Billing Department"] === "" ||
          !item["Billing Department"]
        ) {
          madatoryFields.department = true;
          return true;
        } else if (item["Billing Region"] === "" || !item["Billing Region"]) {
          madatoryFields.region = true;
          return true;
        } else if (
          item["Billing First Name"] === "" ||
          !item["Billing First Name"]
        ) {
          madatoryFields.firstName = true;
          return true;
        } else if (
          item["Billing Last Name"] === "" ||
          !item["Billing Last Name"]
        ) {
          madatoryFields.lastName = true;
          return true;
        } else if (item["Billing Email"] === "" || !item["Billing Email"]) {
          madatoryFields.email = true;
          return true;
        } else {
          return false;
        }
      });
      if (
        madatoryFields.gsid ||
        madatoryFields.io ||
        madatoryFields.opco ||
        madatoryFields.subOpco ||
        madatoryFields.department ||
        madatoryFields.region ||
        madatoryFields.firstName ||
        madatoryFields.lastName ||
        madatoryFields.email
      ) {
        setShowValidationError(true);
        dispatch({
          type: GSID_SHOW_MANDATORY,
          payload: madatoryFields,
        });
      } else {
        setShowConfirmationSave(true);
      }
    };
    return (
      <div class="billing tooltip">
        {" "}
        <i
          class="fa fa-save mx-2"
          onClick={() => {
            handleSaveValidation();
          }}
        ></i>
        <span class="tooltiptext">Save</span>
      </div>
    );
  };

  const paginationArray = pageOptions.slice(
    pageCount - pageIndex > 4 ? pageIndex : pageCount - 5,
    pageCount - pageIndex > 4
      ? pageIndex + 5
      : pageCount - pageIndex < 5
      ? pageCount
      : pageCount - pageIndex
  );
  console.log("paginationArray", paginationArray);
  //pagination component
  const PaginationComponent = () => {
    return (
      <>
        <Pagination className="">
          {pageIndex > 1 ? (
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
          ) : null}
          {pageIndex > 0 ? (
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
          ) : null}
          {paginationArray.map((item) => {
            return (
              <Pagination.Item
                onClick={() => gotoPage(item)}
                disabled={item === pageIndex}
                active={item === pageIndex}
              >
                {item + 1}
              </Pagination.Item>
            );
          })}
          {pageIndex < pageOptions.length - 1 ? (
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
          ) : null}
          {pageIndex < pageCount - 3 ? (
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          ) : null}
        </Pagination>
      </>
    );
  };
  const PageCountComponent = () => {
    return (
      <div
        className="pagination ps-0 go-to-page"
        style={
          billingStateValues?.selectedMonth === 0
            ? { marginTop: "20px" }
            : { marginTop: "5px" }
        }
      >
        <div className="d-flex flex-grow-1 justify-content-start pt-2 pb-2 mb-0 align-items-center custom-no-data-message-text">
          {billingStateValues?.selectedMonth === 0 && (
            <>
              Note :
              <strong>
                {`This screen is enabled from 01/${moment(dateObject).format(
                  "MM"
                )}/${dateObject?.getFullYear()} to 10/${moment(
                  dateObject
                ).format("MM")}/${dateObject?.getFullYear()}`}
              </strong>
            </>
          )}
        </div>
        <div className="d-flex flex-grow-0 justify-content-start pt-2 pb-2 mb-0 align-items-center">
          <span className="custom-no-data-message-text">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span className="custom-no-data-message-text ps-1">
            {" "}
            | Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              className="z-index-99 go-to-page-input"
            />
          </span>{" "}
        </div>
      </div>
    );
  };
  const PaginationDetails = () => {
    return (
      <div className="pagination ps-0">
        <div className="d-flex flex-grow-1 justify-content-start pt-2 pb-2 mb-4 align-items-center"></div>
        <div className="d-flex justify-content-end">
          <PaginationComponent />
        </div>
        {/* <select
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select> */}
      </div>
    );
  };

  const AddNewRow = () => {
    return (
      <>
        <AddNewButton />
        <SaveButton selectedRow={1} />
      </>
    );
  };

  React.useEffect(() => {
    const { sortingBoolean } = billingStateValues;
    if (sortingBoolean?.bool === true) {
      setSortBy([{ id: sortingBoolean.columnId, desc: true }, ...sortBy]);
    } else if (sortingBoolean?.bool === false) {
      setSortBy([{ id: sortingBoolean.columnId, desc: false }, ...sortBy]);
    }
    if (globalFilter) {
      setGlobalFilter(globalFilter);
    }
  }, [billingStateValues?.isTableRefresh]);

  const UndoButton = () => (
    <div class="billing tooltip">
      {" "}
      <i
        className="fa fa-undo mx-2"
        onClick={() => {
          undoConfirmation();
        }}
      ></i>
      <span class="tooltiptext">Undo</span>
    </div>
  );
  const FileUploadButton = () => {
    return (
      <div class="billing tooltip">
        {" "}
        <i
          className="fa fa-upload mx-2"
          onClick={() => {
            setFileUploadOpen(true);
          }}
        ></i>
        <span class="tooltiptext">Upload</span>
      </div>
    );
  };
  const validationStatement = (cell) => {
    const { value, column } = cell;
    return (
      (column?.id === "GSID" ||
        column?.id === "IO" ||
        column?.id === "Billing Opco" ||
        column?.id === "Billing Sub OpCo" ||
        column?.id === "Billing First Name" ||
        column?.id === "Billing Last Name" ||
        column?.id === "Billing Email" ||
        column?.id === "Billing Department" ||
        column?.id === "Billing Region") &&
      (billingStateValues.gsidShowMandatory.gsid ||
        billingStateValues.gsidShowMandatory.io ||
        billingStateValues.gsidShowMandatory.opco ||
        billingStateValues.gsidShowMandatory.subOpco ||
        billingStateValues.gsidShowMandatory.department ||
        billingStateValues.gsidShowMandatory.region ||
        billingStateValues.gsidShowMandatory.firstName ||
        billingStateValues.gsidShowMandatory.lastName ||
        billingStateValues.gsidShowMandatory.email) &&
      value === ""
    );
  };
  const addEditFormClose = () => {
    setShowSuccessMessage(false);
    resetData();
  };

  // Render the UI for your table
  return (
    <>
      <Row>
        <Col md={12} className="d-flex position-relative align-items-center">
          <MonthSelection />
          {billingStateValues?.selectedMonth === 0 &&
          getDay < getScreenAcess ? (
            <>
              {userInfo?.groups?.includes(CS_Hub_GCS_Chargeback_Admins) ? (
                <></>
              ) : (
                <Col md={2} />
              )}
              <Col md={2} className="text-center action-btns">
                <AddNewRow />
                <CSVDownload data={data} />
                <FileUploadButton />
                <UndoButton />
              </Col>
            </>
          ) : (
            <>
              <Col
                md={
                  userInfo?.groups?.includes(CS_Hub_GCS_Chargeback_Admins)
                    ? 1
                    : 3
                }
              />
              <Col md={1} className="text-center action-btns">
                <CSVDownload data={data} />
              </Col>
            </>
          )}
          <Col md={3} className="p-1 ">
            <i class="fa fa-search search-icon-billing"></i>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              gotoPage={gotoPage}
            />
          </Col>
          {userInfo?.groups?.includes(CS_Hub_GCS_Chargeback_Admins) && (
            <Col md={2} className="p-1">
              <Link to={"/aws-billings"}>
                <button type="button" className=" teal-filled-btn w-100">
                  Back
                </button>
              </Link>
            </Col>
          )}
        </Col>
      </Row>
      <PageCountComponent />
      <table
        {...getTableProps()}
        className="custom-no-data-message-text"
        style={{ width: "100%" }}
      >
        <colgroup>
          <col style={{ width: "15%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "12%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "18%" }} />
          <col style={{ width: "5%" }} />
        </colgroup>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) =>
                column.hideHeader === false ? null : (
                  <th
                    {...column.getHeaderProps()}
                    onClick={() => {
                      //set sort desc, aesc or none?
                      //   updateMyData(setSortBy);
                      if (
                        column?.id === "GSID" ||
                        column?.id === "IO" ||
                        column?.id === "Billing Opco" ||
                        column?.id === "Billing Sub OpCo" ||
                        column?.id === "Billing First Name" ||
                        column?.id === "Billing Last Name" ||
                        column?.id === "Billing Email"
                      ) {
                        const desc =
                          column.isSortedDesc === true
                            ? false
                            : column.isSortedDesc === false
                            ? true
                            : false;
                        setSortBy([{ id: column.id, desc }, ...sortBy]);
                        const sortingValues = {
                          columnId: column?.id,
                          bool: desc,
                        };
                        dispatch({
                          type: SORTING_BOOLEAN,
                          payload: sortingValues,
                        });
                      }
                    }}
                  >
                    {column.render("Header")}
                    {column?.id === "Total Credits Allocated" ? " ($)" : null}

                    {/* // sorting */}
                    {column?.id === "GSID" ||
                    column?.id === "IO" ||
                    column?.id === "Billing Opco" ||
                    column?.id === "Billing Sub OpCo" ||
                    column?.id === "Billing First Name" ||
                    column?.id === "Billing Last Name" ||
                    column?.id === "Billing Email" ? (
                      <span className="d-inline-block caret-icon-sorting">
                        <i
                          class="fa fa-sort-desc d-inline align-middle margin-10-2"
                          style={{
                            color: column.isSorted
                              ? column.isSortedDesc
                                ? "black"
                                : "#cccccc"
                              : "#cccccc",
                          }}
                        />

                        <i
                          class="fa fa-sort-asc d-inline-block margin-10-2 zero-width-height align-middle"
                          style={{
                            color: column.isSorted
                              ? column.isSortedDesc
                                ? "#cccccc"
                                : "black"
                              : "#cccccc",
                          }}
                        />
                      </span>
                    ) : null}
                  </th>
                )
              )}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          <>
            {!data?.length ? (
              <tr>
                <td colspan="10" class="text-center p-2">
                  No data to display.
                </td>
              </tr>
            ) : (
              page.map((row, i) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()} key={row.original?.id}>
                    {row.cells.map((cell) => {
                      return cell?.column.Header === "Action" &&
                        billingStateValues.selectedMonth === 0 &&
                        getDay < getScreenAcess ? (
                        <td {...cell.getCellProps()}>
                          <ActionButtonComponent selectedRow={cell?.row} />
                        </td>
                      ) : (
                        <td
                          {...cell.getCellProps()}
                          className={`${
                            validationStatement(cell) ? "border-danger" : ""
                          }`}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </>
          {/* {billingStateValues?.selectedMonth === 0 ? <AddNewRow /> : null} */}
        </tbody>
      </table>
      <Row className="p-2"></Row>
      <PaginationDetails />
      {showConfirmationSave && (
        <CustomModal
          showModal={true}
          fileUpload={true}
          children={
            <RequestSubmitConfirmation
              save
              closeModalParent={() => setShowConfirmationSave(false)}
              proceedToSave={saveApiCall}
            />
          }
        />
      )}
      {showValidationError && (
        <CustomModal
          showModal={true}
          fileUpload={true}
          children={
            <RequestSubmitConfirmation
              error={true}
              closeModalParent={() => {
                setShowValidationError(false);
              }}
            />
          }
        />
      )}
      {showDeleteConfirmation && (
        <CustomModal
          showModal={true}
          fileUpload={true}
          children={
            <RequestSubmitConfirmation
              remove
              closeModalParent={() => {
                setShowDeleteConfirmation(false);
              }}
              proceedToSave={handleDelete}
            />
          }
        />
      )}
      {billingStateValues?.duplicatedData && (
        <CustomModal
          showModal={true}
          fileUpload={true}
          children={
            <RequestSubmitConfirmation
              duplicate={true}
              closeModalParent={() => {
                dispatch({
                  type: DUPLICATED_DATA,
                  payload: false,
                });
              }}
            />
          }
        />
      )}
      {isFileUploadOpen && (
        <CustomModal
          showModal={true}
          fileUpload={true}
          children={
            <FileUpload
              isFileUploadOpen={isFileUploadOpen}
              setFileUploadOpen={setFileUploadOpen}
              headerValues={gsidIoHeaders}
              datatype="GSIDIO"
              data={data}
            />
          }
        />
      )}
      {isEditOpen && (
        <CustomModal
          showModal={true}
          children={
            <AddEditForm
              setEditOpen={setEditOpen}
              editSelectedRow={editSelectedRow}
              setEditSelectedRow={setEditSelectedRow}
              setShowSuccessMessage={setShowSuccessMessage}
            />
          }
        />
      )}
      {showSuccessMessage && (
        <CustomModal
          showModal={true}
          fileUpload={true}
          children={
            <RequestSubmitConfirmation
              success
              closeModalParent={addEditFormClose}
            />
          }
        />
      )}
    </>
  );
}

export default Table;
