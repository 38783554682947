import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Form, InputGroup, FormControl } from 'react-bootstrap';

import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';

// Import Icons
import backicon from 'icons/back.svg';

// Helper
import { postApiCall } from 'helper/postApiCall';

// Components
import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';
import TextInput from 'components/Forms/TextInput';
import CustomModal from 'components/Modal/CustomModal';
import StaticDropdown from 'components/Forms/StaticDropdown';

// Internal
import CancelForm from '../CancelForm';
import SubmitForm from '../SubmitForm';

// Static Data
import {
  setAlarmStatistic,
  setAlarmPeriod,
  setAlarmCondition,
} from '../setAlarmStaticData';

// Validator
import { logsSetAlarmValidator } from './validator';

const SetAlarm = ({ application, tableData, closeSideCanvasParent }) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // ! API Common ID Token
  const idToken = `${userInfo.idToken}`;

  // ! Modal State & Callback
  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // Close Modal Callback (Modal No Click Part)
  const closeFormCallback = useCallback((value) => {
    closeSideCanvasParent(value);
  }, []);

  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const closeSubmitModalCallback = useCallback((value) => {
    setShowSubmitModal(value);
  }, []);

  // ! Form State
  const [filterPattern, setFilterPattern] = useState('');
  const [filterName, setFilterName] = useState('');
  const [metricName, setMetricName] = useState('');
  const [metricValue, setMetricValue] = useState('');
  const [unit, setUnit] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [alarmDescription, setAlarmDescription] = useState('');
  const [statistic, setStatistic] = useState('');
  const [period, setPeriod] = useState('');
  const [evaluationPeriod, setEvaluationPeriod] = useState('3');
  const [condition, setCondition] = useState('');
  const [threshold, setThreshold] = useState('');
  const [resource, setResource] = useState('');
  const [nameSpace, setNameSpace] = useState('AWS/CloudEyeCWLogGroups');
  const [additionalEmail, setAdditionalEmail] = useState([]);
  const [dimensionsFisrt, setDimensionsFisrt] = useState({
    key: '',
    value: '',
  });
  const [dimensionsSecond, setDimensionsSecond] = useState({
    key: '',
    value: '',
  });
  const [dimensionsThird, setDimensionsThird] = useState({
    key: '',
    value: '',
  });

  // Control State
  const [tagValue, setTagValue] = useState(1);

  // ! Form Validation State
  const [dataInputError, setDataInputError] = useState({});

  // ! Form Submit API State
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [formSubmitResponseData, setFormSubmitResponseData] = useState([]);
  const [formSubmitError, setFormSubmitError] = useState(null);

  // ! Form Submit API Variables
  const formSubmitApiUrl = `${process.env.REACT_APP_CLOUD_EYE_LOGS_SET_ALARM}`;

  // ! Form Submit
  function formHandler() {
    const errors = logsSetAlarmValidator({
      filterPattern,
      filterName,
      metricName,
      metricValue,
      unit,
      defaultValue,
      alarmDescription,
      statistic,
      period,
      evaluationPeriod,
      condition,
      threshold,
      resource,
      tagValue,
      dimensionsFisrt,
      dimensionsSecond,
      dimensionsThird,
    });
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setFormSubmitLoading(true);
      let resourceArray = [];
      resourceArray.push(resource);

      const formSubmitRequestBody = {
        Filterpattern: filterPattern,
        FilterName: filterName,
        MetricName: metricName,
        metricvalue: metricValue,
        ...(unit !== '' && {
          unit: unit,
        }),
        ...(defaultValue !== '' && {
          defaultvalue: defaultValue,
        }),
        Namespace: nameSpace,
        AlarmDescription: alarmDescription,
        Statistic: statistic,
        Period: period,
        EvaluationPeriods: evaluationPeriod,
        ComparisonOperator: condition,
        Threshold: threshold,
        Instance: resourceArray,
        application: application,
        requestoremailid: `${userInfo.email}`,
        ...(additionalEmail.length > 0 && {
          emailids: additionalEmail,
        }),
        ...(tagValue === 1 && {
          dimensions: { [dimensionsFisrt.key]: dimensionsFisrt.value },
        }),
        ...(tagValue === 2 && {
          dimensions: {
            [dimensionsFisrt.key]: dimensionsFisrt.value,
            [dimensionsSecond.key]: dimensionsSecond.value,
          },
        }),
        ...(tagValue === 3 && {
          dimensions: {
            [dimensionsFisrt.key]: dimensionsFisrt.value,
            [dimensionsSecond.key]: dimensionsSecond.value,
            [dimensionsThird.key]: dimensionsThird.value,
          },
        }),
      };

      postApiCall(formSubmitApiUrl, idToken, formSubmitRequestBody)
        .then((response) => {
          if (response.status === 200) {
            setFormSubmitResponseData(
              'Your request is submitted. You will recieve email notification on successful alert setup'
            );
          } else {
            setFormSubmitResponseData(
              'Error setting up the alarm, Contact Sony Global Cloud Team for Assistance'
            );
          }
          setShowSubmitModal(true);
          setFormSubmitLoading(false);
        })
        .catch((err) => {
          setFormSubmitError(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setFormSubmitLoading(false);
        });
    }
  }

  return (
    <div
      className='view-edit-box-conatiner pb-5 mb-5'
      style={{ width: '640px' }}
    >
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => closeSideCanvasParent('')}
          />
          <div>
            <h5 className='view-edit-box-title'>Set Alarm</h5>
          </div>
        </div>
      </div>

      <div className='mt-4 mb-5'>
        {formSubmitLoading && (
          <Row>
            <Col>
              <SpinnerLoader />
            </Col>
          </Row>
        )}
        {formSubmitLoading === false && formSubmitError !== null && (
          <Row>
            <Col>
              <DangerAlert message={formSubmitError} />
            </Col>
          </Row>
        )}
        {formSubmitLoading === false && formSubmitError === null && (
          <>
            <Row>
              <Col md={6}>
                <TextInput
                  label='Filter Pattern:'
                  value={filterPattern}
                  onChange={(e) => setFilterPattern(e.target.value)}
                  placeholder='Filter Pattern'
                  isInvalid={dataInputError.filterPattern ? true : false}
                  invalidMessage={dataInputError.filterPattern}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  label='Filter Name:'
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                  placeholder='Filter Name'
                  isInvalid={dataInputError.filterName ? true : false}
                  invalidMessage={dataInputError.filterName}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  label='Metric Name:'
                  value={metricName}
                  onChange={(e) => setMetricName(e.target.value)}
                  placeholder='Metric Name'
                  isInvalid={dataInputError.metricName ? true : false}
                  invalidMessage={dataInputError.metricName}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  label='Metric Value:'
                  value={metricValue}
                  onChange={(e) => setMetricValue(e.target.value)}
                  placeholder='Metric Value'
                  isInvalid={dataInputError.metricValue ? true : false}
                  invalidMessage={dataInputError.metricValue}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  label='Unit:'
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                  placeholder='Unit'
                  isInvalid={dataInputError.unit ? true : false}
                  invalidMessage={dataInputError.unit}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  label='Default Value:'
                  value={defaultValue}
                  onChange={(e) => setDefaultValue(e.target.value)}
                  placeholder='Default Value'
                  isInvalid={dataInputError.defaultValue ? true : false}
                  invalidMessage={dataInputError.defaultValue}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  label='Alarm Description:'
                  value={alarmDescription}
                  onChange={(e) => setAlarmDescription(e.target.value)}
                  placeholder='Alarm Description'
                  isInvalid={dataInputError.alarmDescription ? true : false}
                  invalidMessage={dataInputError.alarmDescription}
                />
              </Col>
              <Col md={6}>
                <StaticDropdown
                  label='Statistic:'
                  name='statistic'
                  defaultSelectMessage='Select Statistic'
                  value={statistic}
                  onChange={(e) => setStatistic(e.target.value)}
                  selectValues={setAlarmStatistic}
                  isInvalid={dataInputError.statistic ? true : false}
                  invalidMessage={dataInputError.statistic}
                />
              </Col>
              <Col md={6}>
                <StaticDropdown
                  label='Period:'
                  name='period'
                  defaultSelectMessage='Select Period'
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                  selectValues={setAlarmPeriod}
                  isInvalid={dataInputError.period ? true : false}
                  invalidMessage={dataInputError.period}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  label='Evaluation Period:'
                  name='evaluationPeriod'
                  value={evaluationPeriod}
                  onChange={(e) => setEvaluationPeriod(e.target.value)}
                  placeholder='Evaluation Period'
                  isInvalid={dataInputError.evaluationPeriod ? true : false}
                  invalidMessage={dataInputError.evaluationPeriod}
                />
              </Col>
              <Col md={6}>
                <StaticDropdown
                  label='Condition:'
                  name='condition'
                  defaultSelectMessage='Select Condition'
                  value={condition}
                  onChange={(e) => setCondition(e.target.value)}
                  selectValues={setAlarmCondition}
                  isInvalid={dataInputError.condition ? true : false}
                  invalidMessage={dataInputError.condition}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  label='Threshold:'
                  value={threshold}
                  onChange={(e) => setThreshold(e.target.value)}
                  placeholder='Threshold'
                  isInvalid={dataInputError.threshold ? true : false}
                  invalidMessage={dataInputError.threshold}
                />
              </Col>
              <Col md={6}>
                <Form.Group className='mb-3' controlId='textPatternText'>
                  <Form.Label>Resource</Form.Label>
                  <Form.Select
                    value={resource}
                    onChange={(e) => {
                      setResource(e.target.value);
                    }}
                    isInvalid={dataInputError.resource ? true : false}
                  >
                    <option>Select Resource</option>
                    {tableData.map((data, index) => (
                      <option key={index} value={data.Logs}>
                        {data.Logs}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>
                    {dataInputError.resource}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              {/* Dimensions */}
              <Col md={6}>
                <Form.Group className='mb-3' controlId='dimensions'>
                  <Form.Label>Dimension</Form.Label>
                  <InputGroup className='mb-3'>
                    <InputGroup.Text>Key</InputGroup.Text>
                    <FormControl
                      value={dimensionsFisrt.key}
                      onChange={(e) => {
                        setDimensionsFisrt({
                          ...dimensionsFisrt,
                          key: e.target.value,
                        });
                      }}
                      isInvalid={
                        dataInputError.dimensionFirstKey ? true : false
                      }
                    />

                    <InputGroup.Text>Value</InputGroup.Text>
                    <FormControl
                      value={dimensionsFisrt.value}
                      onChange={(e) => {
                        setDimensionsFisrt({
                          ...dimensionsFisrt,
                          value: e.target.value,
                        });
                      }}
                      isInvalid={
                        dataInputError.dimensionFirstValue ? true : false
                      }
                    />
                  </InputGroup>

                  {tagValue > 1 && (
                    <InputGroup className='mb-3'>
                      <InputGroup.Text>Key</InputGroup.Text>
                      <FormControl
                        value={dimensionsSecond.key}
                        onChange={(e) => {
                          setDimensionsSecond({
                            ...dimensionsSecond,
                            key: e.target.value,
                          });
                        }}
                        isInvalid={
                          dataInputError.dimensionSecondKey ? true : false
                        }
                      />
                      <InputGroup.Text>Value</InputGroup.Text>
                      <FormControl
                        value={dimensionsSecond.value}
                        onChange={(e) => {
                          setDimensionsSecond({
                            ...dimensionsSecond,
                            value: e.target.value,
                          });
                        }}
                        isInvalid={
                          dataInputError.dimensionSecondValue ? true : false
                        }
                      />
                    </InputGroup>
                  )}

                  {tagValue > 2 && (
                    <InputGroup className='mb-3'>
                      <InputGroup.Text>Key</InputGroup.Text>
                      <FormControl
                        value={dimensionsThird.key}
                        onChange={(e) => {
                          setDimensionsThird({
                            ...dimensionsThird,
                            key: e.target.value,
                          });
                        }}
                        isInvalid={
                          dataInputError.dimensionThirdKey ? true : false
                        }
                      />
                      <InputGroup.Text>Value</InputGroup.Text>
                      <FormControl
                        value={dimensionsThird.value}
                        onChange={(e) => {
                          setDimensionsThird({
                            ...dimensionsThird,
                            value: e.target.value,
                          });
                        }}
                        isInvalid={
                          dataInputError.dimensionThirdValue ? true : false
                        }
                      />
                    </InputGroup>
                  )}

                  <div>
                    {tagValue < 4 && (
                      <button
                        className='teal-bordered-btn mx-2'
                        onClick={() => {
                          let tv = tagValue;
                          setTagValue(tv + 1);
                        }}
                      >
                        Add Tag
                      </button>
                    )}
                    {tagValue > 1 && (
                      <button
                        className='teal-bordered-btn'
                        onClick={() => {
                          let tv = tagValue;
                          setTagValue(tv - 1);
                        }}
                      >
                        Remove Tag
                      </button>
                    )}
                  </div>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className='mb-3' controlId='resource'>
                  <Form.Label>
                    Additional Email
                    <br />
                    (Please press enter after entering the email id)
                  </Form.Label>
                  <ReactMultiEmail
                    emails={additionalEmail}
                    onChange={(value) => {
                      setAdditionalEmail(value);
                    }}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          {email}
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='px-3'>
              <Col md={12} className='d-flex justify-content-end'>
                <ul className='px-0 btn-ul'>
                  <li>
                    <button
                      type='button'
                      className='teal-bordered-btn'
                      onClick={() => {
                        setShowCancelModal(true);
                      }}
                    >
                      Cancel
                    </button>
                  </li>
                  <li>
                    <button
                      type='button'
                      className='teal-filled-btn'
                      onClick={() => formHandler()}
                    >
                      Submit
                    </button>
                  </li>
                </ul>
              </Col>
            </Row>
          </>
        )}
      </div>
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={
            <CancelForm
              closeModalParent={closeModalCallback}
              closeFormParent={closeFormCallback}
            />
          }
        />
      )}

      {showSubmitModal && (
        <CustomModal
          showModal={true}
          children={
            <SubmitForm
              closeSubmitModalParent={closeSubmitModalCallback}
              closeFormParent={closeFormCallback}
              message={formSubmitResponseData}
            />
          }
        />
      )}
    </div>
  );
};

export default SetAlarm;
