import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toastConfig = {
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: 5000,
  hideProgressBar: true,
};

export function toastMessage(message, options) {
  return toast(message, {
    type: toast.TYPE.INFO,
    ...options,
  });
}

export function infoToast(message, options) {
  return toast.info(message, options);
}

export function successToast(message, options) {
  return toast.success(message, { ...options });
}

export function errorToast(message, options) {
  return toast.error(message, { ...options });
}

export function warningToast(message, options) {
  return toast.warning(message, options);
}

export function dismissToast(id) {
  toast.dismiss(id);
}
