import React from 'react';
import { Accordion } from 'react-bootstrap';
import parse from 'html-react-parser';

import { faq } from 'static-contents/user-dashboard-faq';
import { useTranslation } from 'react-i18next';

const Faq = () => {
  const {t} = useTranslation();
  const faqItems = t("faq_items");
  return (
    <div className='custom-card'>
      <div className='maintitle-text mb-2'>{t("faqs")}</div>
      <Accordion>
        {faqItems.map((data, index) => (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>{data.question}</Accordion.Header>
            <Accordion.Body className='custom-word-break'>
              {parse(data.answer)}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default Faq;
