import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

// Import Constant
import { USER_INFO } from 'redux/user/userConstant';

import Security from './Security';

const CEMUVCCloudEyeSecurityIndex = ({ history, match }) => {
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();

  const application = match.params.application;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });

        const tempArray = [];
        for (var i = 0; i < info.groups.length; i++) {
          const searchTerm = 'CS Hub-ManagementUser-';
          const indexOfFirst = info.groups[i].indexOf(searchTerm);
          if (indexOfFirst > -1) {
            tempArray.push(info.groups[i].split('-')[2]);
          }
        }

        if (tempArray.includes(-1) || tempArray.length === 0) {
          history.push('/landing');
        }
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch, history]);
  return (
    <>
      {userInfo !== null && authState && authState.isAuthenticated && (
        <>
          <div className='cloudeye-user-container mt-3'>
            <Security applicationName={application} />
          </div>
        </>
      )}
    </>
  );
};

export default CEMUVCCloudEyeSecurityIndex;
