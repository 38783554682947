import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';

// Import Icons
import backicon from 'icons/back.svg';
import editicon from 'icons/edit.svg';

const CIDRViewBox = ({
  uid,
  viewBackParent,
  // status,
  optionData,
  editShowParent,
  userInfo,
}) => {
  const [showEditForm, setShowEditForm] = useState(false);

  function closeViewBox() {
    viewBackParent(false);
  }

  // Edit Form Show/Hide
  function EditFormHandler() {
    viewBackParent(false);
    editShowParent(true);
  }

  return (
    <div className='view-edit-box-conatiner'>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => closeViewBox()}
          />
          <div>
            <h5 className='view-edit-box-title'>AWS CIDR Request</h5>
            {(optionData.requestStatus === 'Approved' ||
              optionData.requestStatus === 'Cancelled' ||
              optionData.requestStatus === 'Declined') && (
              <Row>
                <Col>
                  <div className='d-flex justify-content-center'>
                    <p className='cidr-view-request-message'>
                      {optionData.RequestActionTakenBy &&
                        `Request ${optionData.requestStatus} by
                      ${optionData.RequestActionTakenBy}`}
                    </p>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
        <div className='view-edit-box-back-edit-container'>
          {optionData.requestStatus === 'Pending' && showEditForm === false && (
            <img
              src={editicon}
              alt='Edit'
              className='view-edit-box-edit-btn'
              onClick={() => EditFormHandler()}
            />
          )}
        </div>
      </div>

      {showEditForm === false && (
        <div className='cidr-view-title-container mt-4'>
          <Container>
            <Row>
              <Col>
                <table width='100%' className='cidr-view-table-container'>
                  <tbody>
                    <tr>
                      <td className='cidr-view-table-td-key' width='50%'>
                        Account Number
                      </td>
                      <td className='cidr-view-table-td-value' width='50%'>
                        {optionData.accountNumber}
                      </td>
                    </tr>
                    <tr>
                      <td className='cidr-view-table-td-key' width='50%'>
                        Account Name
                      </td>
                      <td className='cidr-view-table-td-value' width='50%'>
                        {optionData.accountName}
                      </td>
                    </tr>
                    <tr>
                      <td className='cidr-view-table-td-key' width='50%'>
                        Region
                      </td>
                      <td className='cidr-view-table-td-value' width='50%'>
                        {optionData.region}
                      </td>
                    </tr>
                    <tr>
                      <td className='cidr-view-table-td-key' width='50%'>
                        Number of IP's/Associated CIDR
                      </td>
                      <td className='cidr-view-table-td-value' width='50%'>
                        {optionData.ip}
                      </td>
                    </tr>
                    <tr>
                      <td className='cidr-view-table-td-key' width='50%'>
                        Allocated CIDR
                      </td>
                      <td className='cidr-view-table-td-value' width='50%'>
                        {optionData.allocCIDR}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};

export default CIDRViewBox;
