import React from "react";
import { useDispatch } from "react-redux";
import { ACCOUNT_REQUEST_RELOAD } from "redux/account-requests/accountRequestConstant";

const NewRequestSubmit = ({ refUid }) => {
  const dispatch = useDispatch();

  return (
    <div className="d-flex justify-content-center flex-column">
      <p>Request {refUid} edited Successfully.</p>
      {/* <p>Check your status using this ref#</p>
      <p>{refUid}</p> */}
      <p>
        <button
          type="button"
          onClick={() => {
            dispatch({
              type: ACCOUNT_REQUEST_RELOAD,
              payload: true,
            });
          }}
          className="teal-filled-btn"
        >
          Ok
        </button>
      </p>
    </div>
  );
};

export default NewRequestSubmit;
