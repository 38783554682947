import React, { useState } from "react";
import { useSelector } from "react-redux";

import Loader from "components/Common/Loader";
import AlertMessage from "components/Common/AlertMessage";

import { postApiCall } from "helper/postApiCall";

const DeleteAlarmConfirm = ({
  yesParent,
  closeModalParent,
  delAlarmList,
  application,
}) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  const handleConfirm = async () => {
    setLoading(true);
    const idToken = userInfo.idToken;
    const requestBody = {
      Email: userInfo.email,
      Alarmname: delAlarmList,
      Application: [application.trim()],
    };

    const apiUrl = `${process.env.REACT_APP_CLOUD_EYE_DEL_ALARM}`;

    await postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        if (response.status === 200) {
          setSuccessMsg(response.data.message);
          setError(null);
          //   closeModalParent();
        } else {
          setError("Failed to update");
        }
      })
      .catch((err) => {
        setError("Failed to update");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="d-flex justify-content-center flex-column">
      {!successMsg && (
        <>
          <p>Are you sure that you want to delete the data?</p>
          {error !== null && (
            <div className="d-flex justify-content-center flex-column">
              <AlertMessage
                cssClassName="alert-message-danger"
                alertMessage={error}
              />
            </div>
          )}
          {loading && <Loader />}
          {loading === false && error === null && (
            <ul className="btn-ul">
              <li>
                <button
                  type="button"
                  className="teal-bordered-btn"
                  onClick={() => {
                    closeModalParent(null);
                  }}
                >
                  No
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="teal-filled-btn"
                  onClick={() => {
                    handleConfirm();
                  }}
                >
                  Yes
                </button>
              </li>
            </ul>
          )}
        </>
      )}
      {successMsg && (
        <>
          <p>{successMsg}</p>
          <p>
            <button
              type="button"
              className="teal-filled-btn"
              onClick={() => {
                yesParent(null);
                setSuccessMsg(null);
                closeModalParent()
              }}
            >
              Ok
            </button>
          </p>
        </>
      )}
    </div>
  );
};

export default DeleteAlarmConfirm;
