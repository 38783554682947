import Loader from "components/Common/Loader";
import TextInput from "components/Forms/TextInput";
import CustomModal from "components/Modal/CustomModal";
import NewRequestCancel from "pages/Billings/Components/NewRequestCancel";
import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GsidIoFormValidator, pamFormValidator } from "../billingValidator";
import { postApiCall } from "helper/postApiCall";
import RequestSubmitConfirmation from "./RequestSubmitConfirmation";
import { useDispatch, useSelector } from "react-redux";
import { getISTTime } from "static-contents/billing-data";
import { TABLE_REFRESH } from "redux/billing/EditableTableConstant";

const AddEditForm = ({
  setEditOpen,
  editSelectedRow,
  setEditSelectedRow,
  setShowSuccessMessage,
  setData,
  operationType,
}) => {
  const { t } = useTranslation();
  //REDUX
  const dispatch = useDispatch();
  const billingStateValues = useSelector((state) => state.billing);
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const idToken = userInfo.idToken;

  const postApiUrl = `${process.env.REACT_APP_BILLING_GSIDIO_ADD_EDIT}`;
  const fileUploadApiUrl = `${process.env.REACT_APP_BILLING_FILE_UPLOAD}`;

  const [formState, setFormState] = useState({});
  const [dataInputError, setDataInputError] = useState({});
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);

  const [apiError, setApiError] = useState(null);
  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleContinue = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formDataObj = Object.fromEntries(formData.entries());
    const errors = pamFormValidator(formDataObj);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setEditSelectedRow({});
      //   setStep(step + 1);
      setLoading(true);
      // debugger;
      console.log("operationType", editSelectedRow?.index);
      if (operationType === "EDIT") {
        setData((oldData) =>
          oldData.map((row, index) =>
            index === editSelectedRow?.index ? formDataObj : row
          )
        );
        dispatch({
          type: TABLE_REFRESH,
          payload: !billingStateValues?.isTableRefresh,
        });
        setLoading(false);
        setEditOpen(false);
      } else {
        const requestBody = {
          data: [formDataObj],
          updatedby: userInfo?.email,
          updateddate: getISTTime().getDate,
          updatedtime: getISTTime().getTime,
          actionType: 0,
          datatype: "PAM",
        };
        postApiCall(fileUploadApiUrl, idToken, requestBody)
          .then((response) => {
            if (response?.data?.status === "Success") {
              setLoading(false);
              setEditOpen(false);
              setShowSuccessMessage(true);
            }
          })
          .catch((err) => {
            console.log("error", err);
            setApiError(
              "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
            );
          });
      }
    }
  };
  const handleData = ({ target }) => {
    setFormState({
      ...formState,
      [target.name]: target?.value,
    });
  };

  useEffect(() => {
    setFormState(editSelectedRow?.values);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Row className="px-3">
            <Col md={12} xs={12}>
              <h5 className="page-content-title mb-4">{t("PAM Cost")}</h5>
            </Col>
          </Row>
          <Form onSubmit={handleContinue}>
            <Row className="px-3">
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("AWS Account No") + ":"}
                  name="AWS Account No"
                  value={formState["AWS Account No"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("AWS Account No")}
                  isInvalid={dataInputError["AWS Account No"] ? true : false}
                  invalidMessage={dataInputError["AWS Account No"]}
                />
              </Col>
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("Count of Server Name") + ":"}
                  name="Count of Server Name"
                  value={formState["Count of Server Name"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Count of Server Name")}
                  isInvalid={
                    dataInputError?.["Count of Server Name"] ? true : false
                  }
                  invalidMessage={dataInputError?.["Count of Server Name"]}
                />
              </Col>
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("Sum of Unit Cost") + ":"}
                  name="Sum of Unit Cost"
                  value={formState["Sum of Unit Cost"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Sum of Unit Cost")}
                  isInvalid={
                    dataInputError?.["Sum of Unit Cost"] ? true : false
                  }
                  invalidMessage={dataInputError?.["Sum of Unit Cost"]}
                />
              </Col>
            </Row>
            <div className="col-md-5 offset-md-7">
              <Col md={12}>
                {operationType === "EDIT" && (
                  <p className="add-form-save-note mb-0">
                    * click on save to commit the changes
                  </p>
                )}
                <div className="d-flex justify-content-center">
                  {formSubmitLoading === false && apiError !== null && (
                    <Alert variant="danger">{apiError}</Alert>
                  )}
                </div>
              </Col>
              {formSubmitLoading === true && (
                <div className="mb-3">
                  <Loader />
                </div>
              )}
              {formSubmitLoading === false && (
                <ul className="px-0 btn-ul">
                  <li>
                    <button
                      type="button"
                      className="teal-bordered-btn"
                      onClick={() => {
                        setShowCancelModal(true);
                        setEditSelectedRow({});
                      }}
                    >
                      {t("cancel")}
                    </button>
                  </li>
                  <li>
                    <button type="submit" className="teal-filled-btn">
                      {operationType === "EDIT"
                        ? t("Update")
                        : t("save_submit")}
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </Form>
          {showCancelModal && (
            <CustomModal
              showModal={true}
              fileUpload={true}
              children={
                <NewRequestCancel
                  closeModalParent={() => setShowCancelModal(false)}
                  setEditOpen={setEditOpen}
                />
              }
            />
          )}
        </div>
      )}
    </>
  );
};

export default AddEditForm;
