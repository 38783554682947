import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { USER_INFO } from 'redux/user/userConstant';

import sonyLogo from 'images/sony-logo.svg';
import homeicon from 'icons/home-icon.svg';
import { Link } from 'react-router-dom';

const Header = () => {
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const logoutHandler = async () => {
    dispatch({
      type: USER_INFO,
      payload: null,
    });
    oktaAuth.signOut();
  };
  return (
    <>
      {userInfo !== null && (
        <header className='full-layout-header'>
          <div className='full-layout-left'>
            <img
              src={sonyLogo}
              alt='SONY'
              className='full-layout-logo'
              id='sony-full-logo'
            />
          </div>
          <div className='full-layout-right'>
            <ul>
              <li>
                <Link to='/dashboard'>
                  <img src={homeicon} alt='Home' className='full-layout-icon' />
                </Link>
              </li>
              <li>
                {userInfo !== null && (
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle>{userInfo.name}</Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            logoutHandler();
                          }}
                        >
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
