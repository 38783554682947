import React from 'react';
import { SecureRoute } from '@okta/okta-react';

const ValidateRoute = ({ component: Component, ...rest }) => (
  <SecureRoute
    {...rest}
    render={(props) => {
      return <Component {...props} />;
    }}
  />
);

export default ValidateRoute;
