export const 
networkHubValiadtor = ({
  accountNumberData,
  sourceRegion,
  accountNameData,
  sourceVpcId,
 
}) => {

  let errors = {};
  if (!accountNumberData) {
    errors.accountNumberData = 'Account number is required';
  }
  if (!accountNameData) {
    errors.accountNameData = 'Account Name is required';
  }

  if (!sourceRegion) {
    errors.sourceRegion = 'VPC Region is required';
  }

  let isSourceTGAnum = /[^0-9a-zA-Z]/.test(sourceVpcId);
  
  const isSourceTGOnlyAlphs = /^[a-zA-Z]*$/.test(sourceVpcId);

  if (!sourceVpcId) {
    errors.sourceVpcId = 'VPC ID is required';
  } else if (isSourceTGAnum === true) {
      errors.sourceVpcId = 'Please enter the correct alpha numeric value of the account VPC ID that will use the endpoints';
  } else if (isSourceTGOnlyAlphs) {
      errors.sourceVpcId = 'Please enter the correct alpha numeric value of the account VPC ID that will use the endpoints';
  }
  
  return errors;
 
};
