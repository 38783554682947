import { useOktaAuth } from "@okta/okta-react";
import Api from "api/api";
import {
  cloudEyeAppListApi,
  resourceHealthApi,
  toggleWebServiceApi,
  userAppMetricsApi,
  userServiceApi,
  webServiceApi,
} from "api/cloudeye";
import { infoToast } from "components/Notification";

import { useMutation, useQuery, useQueryClient } from "react-query";

export function useCloudListApplistApi() {
  const { authState } = useOktaAuth();

  return useQuery(
    [Api.cloudEyeAppList],
    apiWrapper(cloudEyeAppListApi, {
      Email: authState.idToken.claims.email,
      idToken: authState.idToken.idToken,
    }),
    { initialData: [] }
  );
}

export function useWebServiceApi(selectedApplicationName) {
  const { authState } = useOktaAuth();

  return useQuery(
    [Api.webserviceApi, selectedApplicationName],
    apiWrapper(webServiceApi, {
      application: selectedApplicationName,
      idToken: authState.idToken.idToken,
    }),
    { initialData: null, enabled: Boolean(selectedApplicationName) }
  );
}

export function useResourceHealthApi(selectedApplicationName) {
  const { authState } = useOktaAuth();

  return useQuery(
    [Api.resourceHeathApi, selectedApplicationName],
    apiWrapper(resourceHealthApi, {
      application: selectedApplicationName,
      idToken: authState.idToken.idToken,
    }),
    { initialData: null, enabled: Boolean(selectedApplicationName) }
  );
}

export function useUserServiceApi(selectedApplicationName) {
  const { authState } = useOktaAuth();

  return useQuery(
    [Api.userService, selectedApplicationName],
    apiWrapper(userServiceApi, {
      application: selectedApplicationName,
      idToken: authState.idToken.idToken,
    }),
    { initialData: null, enabled: Boolean(selectedApplicationName) }
  );
}

export function useUserAppMetricsApi(selectedApplicationName) {
  const { authState } = useOktaAuth();

  return useQuery(
    [Api.userAppMetrics, selectedApplicationName],
    apiWrapper(userAppMetricsApi, {
      application: selectedApplicationName,
      idToken: authState.idToken.idToken,
    }),
    { initialData: null, enabled: Boolean(selectedApplicationName) }
  );
}

export function useToggleWebService(selectedApplicationName) {
  const queryClient = useQueryClient();
  return useMutation(toggleWebServiceApi, {
    onSuccess: (res) => {
      infoToast(res.data.message);
      queryClient.refetchQueries([Api.webserviceApi, selectedApplicationName]);
    },
  });
}

export function apiWrapper(f, args = {}) {
  return function fu(context) {
    return f({ ...context.queryKey[1], ...args });
  };
}
