import React from "react";
import { Modal } from "react-bootstrap";

const CustomModal = ({ children, showModal, fileUpload }) => {
  return (
    <Modal size={`${fileUpload ? "md" : "lg"}`} centered show={showModal}>
      <Modal.Body className="text-center">
        {/* <p>
          <i className='fa fa-check-circle cidr-check-mark'></i>
        </p> */}
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
