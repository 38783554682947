import React, { useEffect } from "react";
import ReactECharts from "echarts-for-react";

const PercentagePie = ({ data, objectKeyData, pieTitle }) => {
  // let objectKey = Object.keys(data[objectKeyData])[0]; // Returns 1st Key of Object
  // let objectValue = Object.values(data[objectKeyData])[0];
  let objectValue = Object.values(data[objectKeyData]).pop();

  var dataSet;
  if (Object.keys(data[objectKeyData]).length > 0) {
    dataSet = [
      {
        name: "Used",
        value: parseFloat(objectValue).toFixed(1),
        color: "#3ac4aa",
      },
      {
        name: "Available",
        value: 100 - parseFloat(objectValue).toFixed(1),
        color: "#ddf3ee",
      },
    ];
  } else {
    dataSet = [];
  }

  var chartOptions = {
    title: {
      text: `${pieTitle}`,
      left: "center",
      top: "center",
      textStyle: {
        fontSize: 12,
        color: "#000000",
        overflow: "break",
        width: 80,
      },
    },
    maintainAspectRatio: false,
    tooltip: {
      show: true,
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: "pie",
        radius: ["52px", "42px"],
        data: dataSet.map(({ name, value, color }) => ({
          name,
          value,
          itemStyle: { color: color },
        })),
        label: {
          alignTo: "labelLine",
          formatter: "{c}",
          minMargin: 5,
          edgeDistance: 10,
        },
        labelLine: {
          length: 20,
          length2: 10,
          lineStyle: {
            color: "#42435C",
          },
        },
      },
    ],
  };

  useEffect(() => {}, []);

  return (
    <ReactECharts
      option={chartOptions}
      style={{ height: "200px", width: "100%" }}
    />
  );
};

export default PercentagePie;
