import React from 'react';
import ReactECharts from 'echarts-for-react';

const CPUUsagePie = ({ title, data }) => {
  let objectValue = Object.values(data).pop();

  var dataSet;
  if (Object.keys(data).length > 0) {
    dataSet = [
      {
        name: 'Used',
        value: 100 - parseInt(objectValue),
        color: '#3ac4aa',
      },
      {
        name: 'Available',
        value: parseInt(objectValue),
        color: '#ddf3ee',
      },
    ];
  } else {
    dataSet = [];
  }

  var chartOptions = {
    title: {
      text: `${title}`,
      left: 'center',
      top: 'center',
      textStyle: {
        fontSize: 12,
        color: '#000000',
        overflow: 'break',
        width: 80,
      },
    },
    maintainAspectRatio: false,
    tooltip: {
      show: true,
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: 'pie',
        radius: ['52px', '42px'],
        data: dataSet.map(({ name, value, color }) => ({
          name,
          value,
          itemStyle: { color: color },
        })),
        label: {
          alignTo: 'labelLine',
          formatter: '{c}',
          minMargin: 5,
          edgeDistance: 10,
        },
        labelLine: {
          length: 15,
          length2: 10,
          lineStyle: {
            color: '#42435C',
          },
        },
      },
    ],
  };

  return (
    <ReactECharts
      option={chartOptions}
      style={{ height: '200px', width: '100%' }}
    />
  );
};

export default CPUUsagePie;
