export const SELECTED_MONTH = "SELECTED_MONTH";
export const SORTING_BOOLEAN = "SORTING_BOOLEAN";
export const TABLE_REFRESH = "TABLE_REFRESH";
export const SHOW_MANDATORY = "SHOW_MANDATORY";
export const DUPLICATED_DATA = "DUPLICATED_DATA";
export const AWS_CREDITS_SHOW_MANDATORY = "AWS_CREDITS_SHOW_MANDATORY";
export const GSID_SHOW_MANDATORY = "GSID_SHOW_MANDATORY";
export const MOD_SHOW_MANDATORY = "MOD_SHOW_MANDATORY";
export const PAM_SHOW_MANDATORY = "PAM_SHOW_MANDATORY";
export const BILLING_SCREEN_DATE = "BILLING_SCREEN_DATE";
