import React from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

// Import Icons
import backicon from "icons/back.svg";
import editicon from "icons/edit.svg";
import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";

const ViewBox = ({ data, closeViewParent, openEditParent }) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  return (
    <div className="view-edit-box-conatiner">
      <div className="view-edit-box-top-bar">
        <div className="view-edit-box-back-title-container">
          <img
            src={backicon}
            alt="Back"
            className="view-edit-box-back-btn"
            onClick={() => closeViewParent(false)}
          />
          <div>
            <div>
              <h5 className="view-edit-box-title">Tool Exception Details</h5>
              {data.requestStatus === "Declined" && (
                <Row>
                  <Col md={12}>
                    <div className="d-flex justify-content-center">
                      <span className="account-request-view-request-message">
                        {data.RequestActionTakenBy &&
                          `Request ${data.requestStatus} by
                      ${data.RequestActionTakenBy}`}
                      </span>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
            {data.requestStatus === "Approved" && (
              <span className="view-edit-box-accountid ">
                Request ID: {data.uid}
              </span>
            )}
          </div>
          <div className="view-edit-box-back-edit-container">
            {(data.requestStatus === "Pending" ||
              data.requestStatus === "Declined") &&
              (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
                data.Gid === userInfo.preferred_username) && (
                <img
                  src={editicon}
                  alt="Edit"
                  className="view-edit-box-edit-btn disabled-div"
                  // onClick={() => {
                  //   closeViewParent(false);
                  //   openEditParent(true);
                  // }}
                  title="Edit"
                />
              )}
            {/* {data.requestStatus === 'Approved' &&
            (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
              data.emailAO.toLowerCase() === userInfo.email.toLowerCase() ||
              data.emailTC.toLowerCase() === userInfo.email.toLowerCase()) && (
              <img
                src={editicon}
                alt='Edit'
                className='view-edit-box-edit-btn'
                onClick={() => {
                  closeViewParent(false);
                  openEditParent(true);
                }}
              />
            )} */}
            {/* {data.requestStatus === "Update Declined" &&
              (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
                data.emailAO.toLowerCase() === userInfo.email.toLowerCase() ||
                data.emailTC.toLowerCase() ===
                  userInfo.email.toLowerCase()) && (
                <img
                  src={editicon}
                  alt="Edit"
                  className="view-edit-box-edit-btn"
                  onClick={() => {
                    closeViewParent(false);
                    openEditParent(true);
                  }}
                />
              )} */}
          </div>
        </div>
      </div>
      <div className="table-view-box-content mt-4 mb-5">
        <Row>
          <Col md={12} className="mt-3">
            <div className="account-request-view-table-container">
              <table width="100%">
                <tbody className="account-request-view-table-tbody">
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Request ID
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-uppercase"
                    >
                      {data.uid}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Requestor
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-uppercase"
                    >
                      {data.submittedBy}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Approved By
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data?.actiontakenby}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Approved On
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data?.actionTakenDate}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      GID
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.Gid}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Account Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.accountname}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Account Number
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.accountnumber}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Account Owner Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.securitycontact}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      BIA Rating
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.biarating}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Project Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.projectname}
                    </td>
                  </tr>

                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Exception Detail
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.exceptiondetails}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Exception Timeline
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.exceptiontimeline}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Exception Category
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.exceptioncategory}
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Exception Expiry Date
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.roleName}
                    </td>
                  </tr> */}
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Status
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.requestStatus}
                    </td>
                  </tr>
                  {data?.requestStatus === "Declined" && (
                    <tr>
                      <td
                        width="50%"
                        className="account-request-view-table-td-key"
                      >
                        Reason For Decline
                      </td>
                      <td
                        width="50%"
                        className="account-request-view-table-td-value"
                      >
                        {data.declineMessage}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Created On
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {moment(parseInt(data.uid.split("-")[1])).format(
                        "MMM DD, YYYY hh:mm A"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ViewBox;
