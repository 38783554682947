import React from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import Logo
import awsLogo from "images/aws-logo.svg";

const WorkspaceSidebar = () => {
  // Routing Variables
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const { t } = useTranslation();

  return (
    <div className="passport-sidebar-container">
      <ul className="passport-sidebar-logo-list">
        <li>
          <img src={awsLogo} alt="AWS" />
          <span className="passport-sidebar-title px-2">Backup</span>
        </li>
      </ul>

      <>
        <ul className="passport-sidebar-link-list">
          <li
            className={
              splitLocation[1] === "aws-backup-requests"
                ? "passport-sidebar-link-active"
                : "passport-sidebar-link-inactive"
            }
          >
            <Link to="/aws-backup-requests">{t("view_request")}</Link>
          </li>
          <li
            className={
              splitLocation[1] === "aws-backup-new-request"
                ? "passport-sidebar-link-active"
                : "passport-sidebar-link-inactive"
            }
          >
            <Link to="/aws-backup-new-request">{t("Backup Request")}</Link>
          </li>
        </ul>
      </>
    </div>
  );
};

export default withRouter(WorkspaceSidebar);
