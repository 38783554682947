import useInitScreen from "components/InitPage";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageContentSidebar from "components/PageContent/PageContentSidebar";
import awsLogo from "images/aws-logo.svg";
import azureLogo from "images/azure-logo.svg";
import { useResourceQueryApi } from "./dataloader";
import { useOktaAuth } from "@okta/okta-react";
import Table from "components/Table";
import SpinnerLoader from "components/Common/SpinnerLoader";
import { useFormik } from "formik";
import TextInput from "components/Forms/TextInput";
import { Form } from "react-bootstrap";
import StaticDropdown from "components/Forms/StaticDropdown";

export default function ResourceQuery() {
  useInitScreen();
  return (
    <Container fluid>
      <Row>
        <Col md={2} className="px-0">
          <PageContentSidebar
            firstLogoLink="/aws-resource-query"
            firstLogoImg={awsLogo}
            fisrtLogoAlt="AWS"
            firstLogoFirstMatchingLink="aws-resource-query"
            secondLogoLink="/azure-resource-query"
            secondLogoImg={azureLogo}
            secondLogoAlt="Azure"
            secondLogoFirstMatchingLink="azure-resource-query"
            showLinks="no"
          />
        </Col>
        <Col md={10}>
          <ResourceQueryMain />
        </Col>
      </Row>
    </Container>
  );
}

function ResourceQueryMain() {
  const { data = [], mutateAsync, isLoading } = useResourceQueryApi();
  const { authState } = useOktaAuth();

  const { handleSubmit, getFieldProps, values, setFieldValue } = useFormik({
    initialValues: { queryType: "", resourcetype: "", resourceversion: "" },
    onSubmit: async (values) => {
      const queryObject =
        values.queryType === "tag"
          ? { tagkey: values.tagkey?.trim(), tagvalue: values.tagvalue?.trim() }
          : {
              resourcetype: values.resourcetype,
              resourceversion: values.resourceversion,
            };
      mutateAsync({
        ...queryObject,
        idToken: authState.idToken.idToken,
      });
    },
  });

  return (
    <div>
      {isLoading && <SpinnerLoader />}

      <Row className="mb-3">
        <Col md={4}>
          <Form.Check
            label="Tag"
            type="radio"
            id={"tag"}
            checked={values.queryType === "tag"}
            {...getFieldProps("tag")}
            onChange={(e) => {
              setFieldValue("queryType", "tag");
            }}
          />
        </Col>
        <Col md={4}>
          <Form.Check
            label="Resource Name"
            type="radio"
            id={"resource"}
            checked={values.queryType === "resource"}
            {...getFieldProps("resource")}
            onChange={(e) => {
              setFieldValue("queryType", "resource");
            }}
          />
        </Col>
      </Row>

      <Row>
        {values.queryType === "resource" && (
          <React.Fragment>
            <Col md={4}>
              <StaticDropdown
                label="Resource Type:"
                {...getFieldProps("resourcetype")}
                defaultSelectMessage="Select Value"
                selectValues={[]}
              />
            </Col>

            <Col md={4}>
              <StaticDropdown
                label="Resource Type Version:"
                {...getFieldProps("resourceversion")}
                defaultSelectMessage="Select Value"
                selectValues={[]}
              />
            </Col>
          </React.Fragment>
        )}

        {values.queryType === "tag" && (
          <React.Fragment>
            <Col md={4}>
              <TextInput
                label="Tag Name:"
                {...getFieldProps("tagkey")}
                defaultSelectMessage="Select Value"
                selectValues={[]}
              />
            </Col>

            <Col md={4}>
              <TextInput
                label="Tag Value:"
                {...getFieldProps("tagvalue")}
                defaultSelectMessage="Select Value"
                selectValues={[]}
              />
            </Col>
          </React.Fragment>
        )}
        {values.queryType && (
          <Col md={4}>
            <div>
              <button
                className="teal-filled-btn"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Search
              </button>
            </div>
          </Col>
        )}
      </Row>

      {!!data.length && (
        <div className="custom-card mb-4">
          <Table
            columns={columns}
            data={data}
            idField="resid"
            text="Resource Table"
          />
        </div>
      )}
    </div>
  );
}

const columns = [
  {
    dataField: "accnum",
    text: "Account Number",
    sort: true,
    searchable: true,
  },
  {
    dataField: "accnum",
    text: "Account Name",
    sort: true,
    searchable: true,
  },
  {
    dataField: "resname",
    text: "Resource Name",
    sort: true,
    searchable: true,
  },
  {
    dataField: "version",
    text: "Version",
    sort: true,
    searchable: true,
  },
];
