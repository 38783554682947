import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

// Import Components
import PageContentSidebar from "components/PageContent/PageContentSidebar";
import NewRequest from "pages/CIDR/AWS/NewRequest/NewRequest";

// Import Constant
import { USER_INFO } from "redux/user/userConstant";

// Logo
import awsLogo from "images/aws-logo.svg";
import azureLogo from "images/azure-logo.svg";
import { useTranslation } from "react-i18next";

const CIDRAWSNewRequestIndex = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch]);
  return (
    <>
      {userInfo !== null && authState && authState.isAuthenticated && (
        <Container fluid>
          <Row>
            <Col md={2} className="px-0">
              <PageContentSidebar
                firstLogoLink="/aws-cidr-view-requests"
                firstLogoImg={awsLogo}
                fisrtLogoAlt="AWS"
                firstLogoFirstMatchingLink="aws-cidr-view-requests"
                firstLogoSecondMatchingLink="aws-cidr-new-request"
                firstLogoFirstLinkText={t("view_request")}
                firstLogoSecondLinkText={t("cidr_req")}
                secondLogoLink="/azure-cidr-view-requests"
                secondLogoImg={azureLogo}
                secondLogoAlt="Azure"
                secondLogoFirstMatchingLink="azure-cidr-view-requests"
                secondLogoSecondMatchingLink="azure-cidr-new-request"
                secondLogoFirstLinkText={t("view_request")}
                secondLogoSecondLinkText={t("cidr_req")}
                showLinks="yes"
              />
            </Col>
            <Col md={10}>
              <NewRequest />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default CIDRAWSNewRequestIndex;
