import React, { useState, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Import Form Component
import TextInput from 'components/Forms/TextInput';
import StaticDefaultValueDropdown from 'components/Forms/StaticDefaultValueDropdown';
import CustomModal from 'components/Modal/CustomModal';

// Import Internal Components
import FormProgress from './FormProgress';
import NewRequestCancel from './NewRequestCancel';

// Validator
import { groupEmailAddressValidate } from '../../Validator/validator';

// Static Data
import { trueFalseData } from '../StaticData/awsStaticData';

const GroupEmailAddress = ({ step, setStep, formData, setFormData }) => {
  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // handle onchange
  const handleData = ({ target }) => {
    setFormData({
      type: 'UPDATE_GROUP_EMAIL_ADDRESS',
      payload: { [target.name]: target.value },
    });
  };

  const handleContinue = (e) => {
    e.preventDefault();
    const errors = groupEmailAddressValidate(formData.groupEmailAddress);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setStep(step + 1);
    }
  };
  const {t} = useTranslation();

  return (
    <>
      <Row>
        <Col md={12} xs={12}>
          <FormProgress
            accountContact
            billingContact
            securityContact
            technicalContact
            billingInfo
          />
        </Col>
      </Row>
      <Row className='px-3'>
        <Col md={12} xs={12}>
          <h5 className='page-content-title mb-4'>{t("group_email_address")}</h5>
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        <Row className='px-3'>
          <Col md={6}>
            <TextInput
              label={"*"+t("technical_group")+":"}
              name='technicalGroup'
              value={formData.groupEmailAddress.technicalGroup}
              onChange={(e) => handleData(e)}
              placeholder={t("technical_group")}
              isInvalid={dataInputError.technicalGroup ? true : false}
              invalidMessage={dataInputError.technicalGroup}
              isInfoBubbleAvailable={true}
              infoBubbleTitle={t("group_email_technical_group_info_bubble")}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label={"*"+t("security_group")+":"}
              name='securityGroup'
              value={formData.groupEmailAddress.securityGroup}
              onChange={(e) => handleData(e)}
              placeholder={t("security_group")}
              isInvalid={dataInputError.securityGroup ? true : false}
              invalidMessage={dataInputError.securityGroup}
              isInfoBubbleAvailable={true}
              infoBubbleTitle={t("group_email_security_group_info_bubble")}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label={"*"+t("billing_group")+":"}
              name='billingGroup'
              value={formData.groupEmailAddress.billingGroup}
              onChange={(e) => handleData(e)}
              placeholder={t("billing_group")}
              isInvalid={dataInputError.billingGroup ? true : false}
              invalidMessage={dataInputError.billingGroup}
              isInfoBubbleAvailable={true}
              infoBubbleTitle={t("group_email_billing_group_info_bubble")}
            />
          </Col>
        </Row>
        {/* <Row className='px-3 mt-3'>
          <Col md={12} xs={12}>
            <h5 className='page-content-title mb-4'>Additional Details</h5>
          </Col>
        </Row>
        <Row className='px-3 mt-1'>
          <Col md={6}>
            <StaticDefaultValueDropdown
              label='*Avail Golden AMI:'
              name='pgcsgoldenami'
              value={formData.groupEmailAddress.pgcsgoldenami}
              onChange={(e) => handleData(e)}
              selectValues={trueFalseData}
              isInvalid={dataInputError.pgcsgoldenami ? true : false}
              invalidMessage={dataInputError.pgcsgoldenami}
            />
          </Col>
          <Col md={6}>
            <StaticDefaultValueDropdown
              label='*Avail Trend Micro:'
              name='ptm'
              value={formData.groupEmailAddress.ptm}
              onChange={(e) => handleData(e)}
              selectValues={trueFalseData}
              isInvalid={dataInputError.ptm ? true : false}
              invalidMessage={dataInputError.ptm}
            />
          </Col>
          <Col md={6}>
            <StaticDefaultValueDropdown
              label='*Avail Prisma Tool:'
              name='prisma'
              value={formData.groupEmailAddress.prisma}
              onChange={(e) => handleData(e)}
              selectValues={trueFalseData}
              isInvalid={dataInputError.prisma ? true : false}
              invalidMessage={dataInputError.prisma}
            />
          </Col>
          <Col md={6}>
            <StaticDefaultValueDropdown
              label='*Avail Managed AD:'
              name='sonyad'
              value={formData.groupEmailAddress.sonyad}
              onChange={(e) => handleData(e)}
              selectValues={trueFalseData}
              isInvalid={dataInputError.sonyad ? true : false}
              invalidMessage={dataInputError.sonyad}
            />
          </Col>
        </Row> */}
        <Row className='px-3'>
          <div className='col-md-5 offset-md-7'>
            <ul className='px-0 btn-ul'>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => setStep(step - 1)}
                >
                  {t("back")}
                </button>
              </li>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    setShowCancelModal(true);
                  }}
                >
                  {t("cancel")}
                </button>
              </li>
              <li>
                <button type='submit' className='teal-filled-btn'>
                  {t("save_continue")}
                </button>
              </li>
            </ul>
          </div>
        </Row>
      </Form>
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<NewRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

export default GroupEmailAddress;
