import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import Loader from "components/Common/Loader";
import EnableAccountFormMenu from "./EnableAccountFormMenu";

import CustomModal from "components/Modal/CustomModal";
import NewRequestCancel from "../NewRequestCancel";

import { SELECTED_INSTANCES } from "redux/aws-host-patch-management/patchAccountConstant";
import FormProgress from "../FormProgress";

let checkedItemsArr = [];
function TargetTab({
  step,
  setStep,
  formData,
  setFormData,
  instanceList,
  handleEditWindowClose,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedInstances = useSelector(
    (state) => state.awsHostPatching?.selectedInstancesList
  );

  // Response Data State
  const [loading, setLoading] = useState(false);
  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectAllCheckBox, setSelectAllCheckBox] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  const handleContinue = (e) => {
    e.preventDefault();

    const errors = {};
    // setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      dispatch({
        type: SELECTED_INSTANCES,
        payload: checkedItems,
      });
      setStep(step + 1);
    }
  };

  const RowComponent = ({ cell, row }) => {
    const handleCheckboxSelection = (e, rowData) => {
      debugger;
      const checkboxStatus = e.target.checked;
      const { InstanceId } = rowData;

      const isExists = checkedItems.includes(InstanceId);
      if (checkboxStatus && !isExists) {
        checkedItemsArr.push(InstanceId);
        setCheckedItems((checkedItems) => [...checkedItems, InstanceId]);
        if (checkedItemsArr?.length === instanceList?.length) {
          setSelectAllCheckBox(true);
        } else {
          setSelectAllCheckBox(false);
        }
      } else {
        const filteredItems = checkedItemsArr.filter(
          (item) => item !== InstanceId
        );
        checkedItemsArr = filteredItems ? filteredItems : [];
        setCheckedItems(() => [...filteredItems]);
        if (checkedItemsArr?.length === instanceList?.length) {
          setSelectAllCheckBox(true);
        } else {
          setSelectAllCheckBox(false);
        }
      }
    };

    return (
      <div className="custom-table-option-conatiner" tabIndex="0">
        <Form.Check
          // key={row?.accountnumber + row?.accountname}
          type="checkbox"
          className={`checkbox-input account-list-checkbox-multiselect`}
          onChange={(e) => handleCheckboxSelection(e, row)}
          defaultChecked={false}
          checked={
            checkedItems?.length > 0
              ? checkedItems.includes(row?.InstanceId)
              : false
          }
        />
      </div>
    );
  };

  const [options, setOptions] = useState({
    showOption: null,
    selectedOptionUID: null,
    selectedOptionData: {},
  });

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {t("showing_results", { from, to, size })}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: instanceList?.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  const defaultSorted = [
    // {
    //   dataField: "accountnumber",
    //   order: "desc",
    // },
  ];
  const columns = [
    {
      isDummyField: true,
      text: "",
      events: {
        onClick: () => {},
      },
      formatter: (cell, row) => (
        <RowComponent cell={cell} row={row}></RowComponent>
      ),
      headerFormatter: (cellContent, row) => {
        const handleMultiSelect = (e, row) => {
          const { checked } = e.target;
          if (checked) {
            setSelectAllCheckBox(true);
            const filterAccountDtails = instanceList.map(
              (item) => item?.InstanceId
            );
            checkedItemsArr = filterAccountDtails;
            setCheckedItems(filterAccountDtails);
          } else {
            setSelectAllCheckBox(false);
            setCheckedItems([]);
            checkedItemsArr = [];
          }
        };
        return (
          <>
            <Form.Check
              key={row?.InstanceId}
              type="checkbox"
              className="d-inline checkbox-input account-list-checkbox-multiselect"
              onChange={(e) => handleMultiSelect(e, row)}
              defaultChecked={false}
              checked={selectAllCheckBox}
            />
            <p className="checkbox-select-all">SELECT ALL</p>
          </>
        );
      },
      formatExtraData: options,
    },
    {
      dataField: "InstanceId",
      text: t("Instance Id").toUpperCase(),
      sort: true,
    },
    {
      dataField: "DisplayName",
      text: t("Display Name").toUpperCase(),
      sort: true,
    },
    {
      dataField: "SSM Status",
      text: t("SSM Status").toUpperCase(),
      sort: true,
    },
  ];

  useEffect(() => {
    if (
      selectedInstances?.length > 0 &&
      selectedInstances?.length === instanceList?.length
    ) {
      setSelectAllCheckBox(true);
      const allInstanceIds = instanceList.map((item) => item?.InstanceId);
      checkedItemsArr = allInstanceIds;
    } else {
      setSelectAllCheckBox(false);
    }
    if (selectedInstances && selectedInstances?.length) {
      setCheckedItems(selectedInstances);
    }
  }, []);
  return (
    <>
      <Row>
        <Col md={12} xs={12}>
          <FormProgress gisAccountSelection installatonSchedule />
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        {loading && <Loader />}
        {loading === false && (
          <>
            {loading === false && (
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={instanceList?.length}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <>
                    <ToolkitProvider
                      keyField="InstanceId"
                      columns={columns}
                      data={instanceList}
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row className="mb-2 mt-4">
                            <Col
                              md={8}
                              className="d-flex align-items-center mb-2"
                            >
                              <h5 className="page-content-title">
                                {t("Available Managed Nodes")}
                              </h5>
                            </Col>
                            <Col md={4} className="mb-2">
                              <div className="search-input-group">
                                <i className="fa fa-search"></i>
                                <SearchBar
                                  {...toolkitProps.searchProps}
                                  className="custom-search-input-form-control"
                                  placeholder={t("search")}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="role-note-text text-secondary text-center target-note fs-11 m-2">
                                <span className="font--bold text-dark note-text">
                                  {t("Note")}:
                                </span>
                                <span className="text-dark">
                                  {t(
                                    "Only SSM-managed nodes are listed here. To ensure all your instances receive automatic patches, please register any missing nodes with SSM."
                                  )}
                                </span>
                              </p>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12" className="mt-0 px-0">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={
                                    "custom-table-head bulk-update-table-head custom-pointer target-gis"
                                  }
                                  bodyClasses={"custom-table-body"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-3">
                            <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                              <div>
                                <PaginationTotalStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div>
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </>
                )}
              </PaginationProvider>
            )}
            {loading === false && instanceList?.length === 0 && (
              <div className="d-flex justify-content-center align-items-center">
                No Data to Display
              </div>
            )}
            <div className="col-md-12 mt-4">
              <ul className="px-0 btn-ul justify-content-end">
                <li>
                  <button
                    type="button"
                    className="teal-bordered-btn"
                    onClick={() => setStep(step - 1)}
                  >
                    {t("back")}
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="teal-bordered-btn"
                    onClick={() => {
                      setShowCancelModal(true);
                    }}
                  >
                    {t("cancel")}
                  </button>
                </li>
                <li>
                  <button
                    type="submit"
                    className={`${
                      checkedItems?.length ? "" : "disable-div"
                    } teal-filled-btn`}
                  >
                    {t("save_continue")}
                  </button>
                </li>
              </ul>
            </div>
          </>
        )}
      </Form>
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={
            <NewRequestCancel
              closeModalParent={closeModalCallback}
              handleEditWindowClose={handleEditWindowClose}
            />
          }
        />
      )}
    </>
  );
}

export default TargetTab;
