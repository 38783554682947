import React, {useState} from "react";
import { Row, Col, Form, Table } from 'react-bootstrap';
import BulkEditFormMenu from "./BulkEditFormMenu";
import CustomModal from 'components/Modal/CustomModal';
// Import Internal Component
import EditRequestCancel from '../Edit/EditRequestCancel';

function AccountConfirmation({ step, setStep, formData, setFormData, selectedAccounts, availableData }) {
    // Show Cancel Modal
    const [showCancelModal, setShowCancelModal] = useState(false);
    const enhancedData = availableData.filter(item => selectedAccounts?.includes(item.accountnumber))

    // Close Modal Callback (Modal No Click Part)
    const closeModalCallback = (value) => {
        setShowCancelModal(value);
    };

  return (
    <>
    <Row>
      <Col md={12} xs={12}>
        <BulkEditFormMenu accountConfirmation />
      </Col>
      <span className='subtitle-text mt-4 mb-2'>Below Accounts are selected for bulk update. Kindly check and Proceed further</span>
      <Col md={12} xs={12}>
      
    <Table bordered={true} size="sm" className="account-confirmation-table">
      <thead>
        <tr>
          <th>S.No</th>
          <th>Account Number</th>
          <th>Account Name</th>
        </tr>
      </thead>
      <tbody>
      {enhancedData.map(( item, index ) =><>
        <tr>
          <td>{index + 1}</td>
          <td>{item?.accountnumber}</td>
          <td>{item?.accountname}</td>
        </tr>
        </>
        )}

      </tbody>
    </Table>
    </Col>
    </Row>
    <Row className='px-3'>
              <div className='d-flex justify-content-end'>
                <ul className='px-0 btn-ul'>
                  <li>
                    <button
                      type='button'
                      className='teal-bordered-btn'
                      onClick={() => {
                        setShowCancelModal(true);
                      }}
                    >
                      Cancel
                    </button>
                  </li>
                  <li>
                    <button type='button' className='teal-filled-btn' onClick={() => setStep(1)}>
                      Save & Continue
                    </button>
                  </li>
                </ul>
              </div>
            </Row>
            {showCancelModal && (
          <CustomModal
            showModal={true}
            children={
              <EditRequestCancel closeModalParent={closeModalCallback} />
            }
          />
        )}
    </>
  )
}

export default AccountConfirmation