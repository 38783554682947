import React from 'react';
import { Row, Col } from 'react-bootstrap';

// Import Internal Components
import AccountListTable from './AccountListTable';

const AccountList = () => {
  return (
    <div className='mt-4'>
      <Row>
        <Col md={12}>
          <AccountListTable />
        </Col>
      </Row>
    </div>
  );
};

export default AccountList;
