import moment from "moment";
import React from "react";
import { ExportJsonCsv } from "react-export-json-csv";
import { useSelector } from "react-redux";

const csvHeaderList = [
  {
    key: "AWS Account No",
    name: "AWS Account No",
  },
  {
    key: "Count of Server Name",
    name: "Count of Server Name",
  },
  {
    key: "Sum of Unit Cost",
    name: "Sum of Unit Cost",
  },
];

const CSVDownload = ({
  fileDownload = null,
  data,
  setDownloaded = () => {},
}) => {
  const billingStateValues = useSelector((state) => state.billing);

  // OpsCost_2024_January_Charges
  const dateObj = new Date();

  const getMonthString = moment(dateObj)
    .subtract(billingStateValues?.selectedMonth === 0 ? 1 : 2, "months")
    .format("MMMM");
  const getYearString = moment(dateObj)
    .subtract(billingStateValues?.selectedMonth === 0 ? 1 : 2, "months")
    .format("YYYY");

  const fileTitle = `PAMCost_${getYearString}_${getMonthString}_Charges`;
  // for download csv
  const [formattedCsvData, setFormattedCsvData] = React.useState(data);

  return (
    <>
      <ExportJsonCsv
        fileTitle={fileTitle}
        headers={csvHeaderList}
        items={formattedCsvData}
        className="border-0 px-0 py-0 bg-transparent"
      >
        {fileDownload ? (
          <button
            type="button"
            className="btn csv-download-btn mb-3"
            onClick={() => {
              setDownloaded(true);
            }}
          >
            Download<i class="fa fa-download mx-2"></i>
          </button>
        ) : (
          <div class="billing tooltip">
            {" "}
            <i class="fa fa-download mx-2"></i>
            <span class="tooltiptext">Download</span>
          </div>
        )}
      </ExportJsonCsv>
    </>
  );
};

export default CSVDownload;
