import React, { useRef, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// Helper
import { postApiCall } from 'helper/postApiCall';

// Internal Components
import PercentagePie from './PercentagePie';
import CardDetail from './CardDetail';

// Line Charts
import LineChart from './LineChart';

// CPU Usage
import CPUUsagePie from './CPUUsagePie';
import CPUUsageLineChart from './CPUUsageLineChart';

// Components
import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

import { CLOUD_EYE_APPLICATION_NAME } from 'redux/cloudeye-user/cloueyeUserConstant';

// Icon
import backicon from 'icons/back.svg';

const EC2 = ({ application, instanceId, history, displayname }) => {
  const isMounted = useRef(false);
  const dispatch = useDispatch();

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [showCPUUsage, setShowCPUUsage] = useState(false);

  // API Variables
  const apiUrl = `${process.env.REACT_APP_CLOUD_EYE_EC2_METRICS}`;
  const idToken = `${userInfo.idToken}`;
  let requestBody = { application: application, instid: instanceId, days: '1' };

  // API State
  const [apiLoading, setApiLoading] = useState(true);
  const [apiError, setApiError] = useState(null);
  const [apiResponseData, setApiResponseData] = useState([]);

  // CPU Usage API
  const [cpuUsageAPILoading, setCpuUsageAPILoading] = useState(false);
  const [cpuUsageAPIData, setCpuUsageAPIData] = useState([]);
  const [cpuUsageAPIError, setCpuUsageAPIError] = useState(null);

  // CPU Usage API Variables
  const cpuUsageApiUrl = `${process.env.REACT_APP_CLOUD_EYE_EC2_METRICS_CPU_USAGE}`;

  useEffect(() => {
    // Role Checking Part
    const tempArray = [];
    for (var i = 0; i < userInfo.groups.length; i++) {
      const searchTerm = 'CS Hub-Monitoring-';
      const indexOfFirst = userInfo.groups[i].indexOf(searchTerm);
      if (indexOfFirst > -1) {
        tempArray.push(indexOfFirst);
      }
    }

    if (tempArray.includes(-1) || tempArray.length === 0) {
      history.push('/landing');
    } else {
      setApiLoading(true);
      postApiCall(apiUrl, idToken, requestBody)
        .then((response) => {
          setApiResponseData(response.data);
          setApiLoading(false);
        })
        .catch((err) => {
          setApiError(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setApiLoading(false);
        });
      setCpuUsageAPILoading(true);
      postApiCall(cpuUsageApiUrl, idToken, requestBody)
        .then((response) => {
          setCpuUsageAPIData(response.data);
          setCpuUsageAPILoading(false);
        })
        .catch((err) => {
          setCpuUsageAPIError(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setCpuUsageAPILoading(false);
        });
      isMounted.current = true;
    }

    return () => {};
  }, []);

  return (
    <>
      {apiLoading && cpuUsageAPILoading && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center mt-3'>
            <SpinnerLoader />
          </Col>
        </Row>
      )}
      {apiLoading && apiError !== null && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center mt-3'>
            <DangerAlert message={apiError} />
          </Col>
        </Row>
      )}
      {apiLoading === false && cpuUsageAPILoading === false && (
        <>
          <Row>
            <Col md={1} className='px-3 mb-3 d-flex align-items-center'>
              <Link
                to={`/cloudeye-management-user-view-dashboard/${application}`}
              >
                <div>
                  <img
                    src={backicon}
                    alt='Back'
                    className='view-edit-box-back-btn'
                  />
                </div>
              </Link>
            </Col>
            <Col md={11} className='px-3 mb-3'>
              <div>
                <span className='metrics-label-title'>Application Name</span> :{' '}
                <span className='metrics-value-title'>{application}</span>
              </div>
              <div>
                <span className='metrics-label-title'>Resource ID</span> :{' '}
                <span className='metrics-value-title'>{instanceId}</span>
              </div>
              <div>
                <span className='metrics-label-title'>Resource Name</span> :{' '}
                <span className='metrics-value-title'>{displayname}</span>
              </div>
            </Col>
          </Row>

          {/* EC2 Metrics Component */}
          {showCPUUsage === false && (
            <>
              <Row>
                <Col md={12} className='px-3'>
                  <div className='custom-card mb-3'>
                    <Row>
                      <Col md={12}>
                        <Row>
                          <Col md={9}>
                            <Row>
                              {apiResponseData['CPUUtilization'] && (
                                <Col md={3}>
                                  <PercentagePie
                                    data={apiResponseData}
                                    objectKeyData='CPUUtilization'
                                    pieTitle='CPU Utilisation'
                                  />
                                </Col>
                              )}
                              {apiResponseData['disk_used_percent'] && (
                                <Col md={3}>
                                  <PercentagePie
                                    data={apiResponseData}
                                    objectKeyData='disk_used_percent'
                                    pieTitle='Disk Used'
                                  />
                                  <div
                                    className='cpu-view-more'
                                    onClick={() => {
                                      setShowCPUUsage(true);
                                    }}
                                  >
                                    <span className='text-center'>
                                      View More
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {apiResponseData['EBSByteBalance'] && (
                                <Col md={3}>
                                  <PercentagePie
                                    data={apiResponseData}
                                    objectKeyData='EBSByteBalance'
                                    pieTitle='EBS Byte Balance'
                                  />
                                </Col>
                              )}
                              {apiResponseData['mem_used_percent'] && (
                                <Col md={3}>
                                  <PercentagePie
                                    data={apiResponseData}
                                    objectKeyData='mem_used_percent'
                                    pieTitle='Memory Used'
                                  />
                                </Col>
                              )}
                              <Col md={12}>
                                <ul className='metrics-legend-wrapper'>
                                  <li>
                                    <div className='metrics-high'></div>
                                    Used
                                  </li>
                                  <li>
                                    <div className='metrics-low'></div>
                                    Available
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={3} className='d-flex align-items-center'>
                            <CardDetail data={apiResponseData} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row>
                {apiResponseData['CPUUtilization'] && (
                  <Col md={6} className='px-2'>
                    <div className='custom-card mb-3'>
                      <LineChart
                        data={apiResponseData}
                        application={application}
                        instanceId={instanceId}
                        objectKeyData='CPUUtilization'
                        chartTitle='CPU Utilisation Graphs (%)'
                      />
                    </div>
                  </Col>
                )}
                {apiResponseData['disk_used_percent'] && (
                  <Col md={6} className='px-2'>
                    <div className='custom-card mb-3'>
                      <LineChart
                        data={apiResponseData}
                        application={application}
                        instanceId={instanceId}
                        objectKeyData='disk_used_percent'
                        chartTitle='Disk Used (%)'
                      />
                    </div>
                  </Col>
                )}
                {apiResponseData['EBSByteBalance'] && (
                  <Col md={6} className='px-2'>
                    <div className='custom-card mb-3'>
                      <LineChart
                        data={apiResponseData}
                        application={application}
                        instanceId={instanceId}
                        objectKeyData='EBSByteBalance'
                        chartTitle='EBS Byte Balance (%)'
                      />
                    </div>
                  </Col>
                )}
                {apiResponseData['mem_used_percent'] && (
                  <Col md={6} className='px-2'>
                    <div className='custom-card mb-3'>
                      <LineChart
                        data={apiResponseData}
                        application={application}
                        instanceId={instanceId}
                        objectKeyData='mem_used_percent'
                        chartTitle='Memory Used (%)'
                      />
                    </div>
                  </Col>
                )}
                {apiResponseData['mem_available_percent'] && (
                  <Col md={6} className='px-2'>
                    <div className='custom-card mb-3'>
                      <LineChart
                        data={apiResponseData}
                        application={application}
                        instanceId={instanceId}
                        objectKeyData='mem_available_percent'
                        chartTitle='Memory Available (%)'
                      />
                    </div>
                  </Col>
                )}
                {apiResponseData['cpu_usage_iowait'] && (
                  <Col md={6} className='px-2'>
                    <div className='custom-card mb-3'>
                      <LineChart
                        data={apiResponseData}
                        application={application}
                        instanceId={instanceId}
                        objectKeyData='cpu_usage_iowait'
                        chartTitle='CPU Usage IO Wait (%)'
                      />
                    </div>
                  </Col>
                )}
                {apiResponseData['cpu_usage_active'] && (
                  <Col md={6} className='px-2'>
                    <div className='custom-card mb-3'>
                      <LineChart
                        data={apiResponseData}
                        application={application}
                        instanceId={instanceId}
                        objectKeyData='cpu_usage_active'
                        chartTitle='CPU Usage Active (%)'
                      />
                    </div>
                  </Col>
                )}
                {apiResponseData['NetworkIn'] && (
                  <Col md={6} className='px-2'>
                    <div className='custom-card mb-3'>
                      <LineChart
                        data={apiResponseData}
                        application={application}
                        instanceId={instanceId}
                        objectKeyData='NetworkIn'
                        chartTitle='Network IN (Bytes)'
                      />
                    </div>
                  </Col>
                )}
                {apiResponseData['NetworkOut'] && (
                  <Col md={6} className='px-2'>
                    <div className='custom-card mb-3'>
                      <LineChart
                        data={apiResponseData}
                        application={application}
                        instanceId={instanceId}
                        objectKeyData='NetworkOut'
                        chartTitle='Network OUT (Bytes)'
                      />
                    </div>
                  </Col>
                )}
                {apiResponseData['disk_free'] && (
                  <Col md={6} className='px-2'>
                    <div className='custom-card mb-3'>
                      <LineChart
                        data={apiResponseData}
                        application={application}
                        instanceId={instanceId}
                        objectKeyData='disk_free'
                        chartTitle='Disk Free (Bytes)'
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </>
          )}

          {/* CPU Usage Component */}
          {showCPUUsage && (
            <>
              <Row>
                <Col md={12} className='px-3 mb-3'>
                  <img
                    src={backicon}
                    alt='Back'
                    className='view-edit-box-back-btn'
                    onClick={() => {
                      setShowCPUUsage(false);
                    }}
                  />
                  <span className='regular-text'>Go Back to EC2 Metrics</span>
                </Col>
                <Col md={12} className='px-3'>
                  <div className='custom-card mb-3'>
                    <Row>
                      {cpuUsageAPIData.map((data, index) => (
                        <Col md={2} key={index} className='mb-3'>
                          <CPUUsagePie title={data.Path} data={data.Values} />
                        </Col>
                      ))}
                      <Col md={12}>
                        <ul className='metrics-legend-wrapper'>
                          <li>
                            <div className='metrics-high'></div>
                            Used
                          </li>
                          <li>
                            <div className='metrics-low'></div>
                            Available
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                {cpuUsageAPIData.map((data, index) => (
                  <Col md={6} key={index} className='mb-3 px-2'>
                    <div className='custom-card mb-3'>
                      <CPUUsageLineChart
                        data={cpuUsageAPIData[index]}
                        application={application}
                        instanceId={instanceId}
                        objectKeyData={cpuUsageAPIData[index].Values}
                        chartTitle={`Path:${cpuUsageAPIData[index].Path}`}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(EC2);
