import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Form, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

// Import Form Component
import TextInput from "components/Forms/TextInput";
import Loader from "components/Common/Loader";
import BillingCompanyDropdown from "components/Forms/BillingCompanyDropdown";
import BillingSubCompanyDropdown from "components/Forms/BillingSubCompanyDropdown";
import BillingDepartmentDropdown from "components/Forms/BillingDepartmentDropdown";
import StaticDropdown from "components/Forms/StaticDropdown";
import CustomModal from "components/Modal/CustomModal";

// Import Internal Component
import EditRequestCancel from "../Edit/EditRequestCancel";
import BulkEditFormMenu from "./BulkEditFormMenu";
import EditRequestSubmit from "../Edit/EditRequestSubmit";

// Helper
import { getApiCall } from "helper/getApiCall";
import { postApiCall } from "helper/postApiCall";

// Static Data
import {
  staticEnvironmentType,
  staticAssetUserBase,
  staticDataClassification,
} from "../../StaticData/awsStaticData";
import { useHistory } from "react-router-dom";

import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

// Validator
import { accountDetailsValidate } from './Validator/BulkEditAccountsValidate'

const BulkEditAccountDetails = ({ step, setStep, formData, setFormData, selectedAccounts }) => {
  const {t}=useTranslation();
  const history  = useHistory()
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const accountRequestReload = useSelector(
    (state) => state.accountRequestReload
  );
  const { accountRequestReloadType } = accountRequestReload;

  // Set ID Token Globally
  const idToken = userInfo.idToken;

  // Response Data State
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  // Component Form Data State
  const [billingSubOpCompany, setBillingSubOpCompany] = useState([]);
  const [billingDepartment, setBillingDepartment] = useState([]);

  // Form Submit State
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  // const [newRequestUid, setNewRequestUid] = useState(null);

 // Bulk Edit 
 const [bulkEditFormSubmitSuccess, setBulkEditFormSubmitSuccess] = useState(false);
 const [apiError, setApiError] = useState(null);
  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);
  // account name
  const [acName,setAcName]=useState("");

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // handle onchange
  const handleData = ({ target }) => {
    if (target.name === "opco") {
      setFormData({
        type: "UPDATE_ACCOUNT_DETAILS",
        payload: {
          [`subopco`]: "",
        },
      });
      setFormData({
        type: "UPDATE_ACCOUNT_DETAILS",
        payload: {
          [`department`]: "",
        },
      });
      setBillingSubOpCompany([]);
      setBillingDepartment([]);
      let opCompany = target.value;
      if (opCompany !== "") {
        const getSubOpCompany = responseData.find(
          (t) => t.opco === opCompany
        ).subopcod;
        setBillingSubOpCompany(getSubOpCompany);
      }
    }
    if (target.name === "subopco") {
      setFormData({
        type: "UPDATE_ACCOUNT_DETAILS",
        payload: {
          [`department`]: "",
        },
      });
      setBillingDepartment([]);
      let opSubCompany = target.value;
      if (opSubCompany !== "") {
        const getDepartment = billingSubOpCompany.find(
          (t) => t.subopco === opSubCompany
        ).dept;
        setBillingDepartment(getDepartment);
      }
    }

    setFormData({
      type: "UPDATE_ACCOUNT_DETAILS",
      payload: { [target.name]: target.value },
    });
  };

  const handleContinue = (e) => {
    debugger
    e.preventDefault();

    const errors = accountDetailsValidate(formData.accountDetails);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setStep(step + 1);
    }
  };


  useEffect(() => {
    if (accountRequestReloadType === true) {
      setFormSubmitSuccess(false);
      setBulkEditFormSubmitSuccess(false);
    }
    setLoading(true);
 // adding query string fr oauth
 const queryObj = `{
  "submittedBy": "${userInfo?.email}",
  "type_of_user": ${userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? 1 : 0}
}`
const encodedString = Buffer.from(queryObj).toString('base64');
// end

const apiUrl = `${process.env.REACT_APP_AWS_ACCOUNT_REQUEST_BILLING_INFO}?enchash=${encodedString}`;

    getApiCall(apiUrl, idToken).then((response) => {
      setResponseData(response.data.body);
      let getSubOpCompany = [];
      if (formData.accountDetails.opco !== "") {
        setBillingSubOpCompany([]);
        setBillingDepartment([]);

        const checkOpco = response.data.body.find(
          (t) => t.opco === formData.accountDetails.opco
        );

        if (checkOpco === undefined) {
          setBillingSubOpCompany([]);
        } else {
          getSubOpCompany = response.data.body.find(
            (t) => t.opco === formData.accountDetails.opco
          ).subopcod;
          setBillingSubOpCompany(getSubOpCompany);
        }
      }
      if (formData.accountDetails.subopco !== "") {
        setBillingDepartment([]);

        const checkSubOpco = getSubOpCompany.find(
          (t) => t.subopco === formData.accountDetails.subopco
        );

        if (checkSubOpco === undefined) {
          setBillingDepartment([]);
        } else {
          const getDepartment = getSubOpCompany.find(
            (t) => t.subopco === formData.accountDetails.subopco
          ).dept;
          setBillingDepartment(getDepartment);
        }
      }
      setLoading(false);
    });

    return () => {
      setResponseData([]);
    };
  }, [accountRequestReloadType]);

 
  return (
    <>
      <Row>
      <Col md={12}>
                <div className='d-flex justify-content-center'>
                  {formSubmitLoading === false && apiError !== null && (
                    <Alert variant='danger'>{apiError}</Alert>
                  )}
                </div>
              </Col>
        <Col md={12} xs={12}>
          <BulkEditFormMenu accountDetails />
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        {loading && (
          <div className="mb-3">
            <Loader />
          </div>
        )}
        {loading === false && (
          <>
            <Row className="px-3">

              <Col md={12}>
                <BillingCompanyDropdown
                  label="*Operating Company:"
                  defaultSelectMessage="Select Operating Company"
                  name="opco"
                //   disabled
                  value={formData.accountDetails.opco}
                  onChange={(e) => handleData(e)}
                  selectValues={responseData}
                  isInvalid={dataInputError.opco ? true : false}
                  invalidMessage={dataInputError.opco}
                />
              </Col>
              <Col md={12}>
                <BillingSubCompanyDropdown
                  label="*Sub Operating Company:"
                  defaultSelectMessage="Select Sub Operating Company"
                  name="subopco"
                  value={formData.accountDetails.subopco}
                  onChange={(e) => handleData(e)}
                  selectValues={billingSubOpCompany}
                  isInvalid={dataInputError.subopco ? true : false}
                  invalidMessage={dataInputError.subopco}
                />
              </Col>
              <Col md={12}>
                <BillingDepartmentDropdown
                  label="*Department:"
                  defaultSelectMessage="Select Department"
                  name="department"
                  value={formData.accountDetails.department}
                  onChange={(e) => handleData(e)}
                  selectValues={billingDepartment}
                  isInvalid={dataInputError.department ? true : false}
                  invalidMessage={dataInputError.department}
                />
              </Col>

              {/* <Col md={12}>
                <StaticDropdown
                  label="*Environment Type:"
                  defaultSelectMessage="Select Environment Type"
                  name="environmenttype"
                //   disabled
                  value={formData.accountDetails.environmenttype}
                  onChange={(e) => handleData(e)}
                  selectValues={staticEnvironmentType}
                  isInvalid={dataInputError.environmenttype ? true : false}
                  invalidMessage={dataInputError.environmenttype}
                />
              </Col> */}

            </Row>

            <Row className="px-3">
              <div className="d-flex justify-content-end">
                {formSubmitLoading === true && <Loader />}
                {formSubmitLoading === false && (
                  <ul className="px-0 btn-ul">
                    <li>
                      <button
                        type="button"
                        className="teal-bordered-btn"
                        onClick={() => setStep(step - 1)}
                      >
                        Back
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="teal-bordered-btn"
                        onClick={() => {
                          setShowCancelModal(true);
                        }}
                      >
                        Cancel
                      </button>
                    </li>
                    <li>
                      <button type="submit" className="teal-filled-btn">
                        Save & Continue
                      </button>
                    </li>
                  </ul>
                )}
              </div>
            </Row>
          </>
        )}
      </Form>
      {formSubmitSuccess && (
        <CustomModal showModal={true} children={<EditRequestSubmit />} />
      )}

{bulkEditFormSubmitSuccess && (
        <CustomModal showModal={true} children={<EditRequestSubmit message={"Bulk"}/>} />
      )}

      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<EditRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

export default BulkEditAccountDetails;
