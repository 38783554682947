import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import ReactECharts from 'echarts-for-react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

// Import Component
import TrendCanvas from 'components/Canvas/TrendCanvas';
import SpinnerLoader from 'components/Common/SpinnerLoader';
import CustomModal from 'components/Modal/CustomModal';
import TrendMicroModal from './TrendMicroModal';

// Import Constant
import {
  PRISMA,
  REMEDY_TICKET,
  TREND,
  MORIARTY,
  SERVICE,
} from 'redux/dashboard/dashboardConstant';

import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

const TrendMicro = () => {
  const [trendCanvasTitle, setTrendCanvasTitle] = useState(null);
  const [selectedLegend, setSelectedLegend] = useState(null);

  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState({});

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const dashboardChart = useSelector((state) => state.dashboardChart);
  const { trendCanvas } = dashboardChart;

  var awsDataSet = [
    {
      name: 'Critical',
      value: responseData.Error,
      color: '#ff3f3f',
    },
    {
      name: 'InActive ',
      value: responseData.InActive,
      color: '#ffe181',
    },
    {
      name: 'Active ',
      value: responseData.Active,
      color: '#69bc50',
    },
    {
      name: 'Warning',
      value: responseData.Warning,
      color: '#FD7B43',
    },
  ];

  var awsOption = {
    maintainAspectRatio: false,
    tooltip: {
      show: true,
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: 'pie',
        radius: '40%',
        // radius: ['40px'],
        data: awsDataSet.map(({ name, value, color, borderRadius }) => ({
          name,
          value,
          itemStyle: { color: color, borderRadius: borderRadius },
        })),
        label: {
          formatter: '{c}',
          position: 'outer',
          alignTo: 'labelLine',
          bleedMargin: 0,
        },
        labelLine: {
          length: 20,
          length2: 0,
          maxSurfaceAngle: 100,
        },
      },
    ],
  };

  function onChartClick(param, echarts) {
    setTrendCanvasTitle('Host Details');
    const getLabelText = param.data.name;
    if (getLabelText === 'Critical') {
      setSelectedLegend('Error');
    } else {
      setSelectedLegend(getLabelText.trim());
    }
    dispatch({
      type: TREND,
      payload: true,
    });
    dispatch({
      type: REMEDY_TICKET,
      payload: false,
    });
    dispatch({
      type: PRISMA,
      payload: false,
    });
    dispatch({
      type: SERVICE,
      payload: false,
    });
    dispatch({
      type: MORIARTY,
      payload: false,
    });
  }

  function shortDispatch() {
    dispatch({
      type: TREND,
      payload: true,
    });
    dispatch({
      type: REMEDY_TICKET,
      payload: false,
    });
    dispatch({
      type: PRISMA,
      payload: false,
    });
    dispatch({
      type: SERVICE,
      payload: false,
    });
    dispatch({
      type: MORIARTY,
      payload: false,
    });
  }

  function trendMicroDownload() {
    setDownloadLoading(true);
    const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: setIdToken,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_TREND_MICRO_DOWNLOAD}`,
        {
          email: userInfo.email,
        },
        config
      )
      .then((response) => {
        if (response.status === 200) {
          setModalMessage(
            'Trend micro host report will be shared to your email id in next few minutes'
          );
        } else {
          setModalMessage(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
        }
        setDownloadLoading(false);
        setShowModal(true);
      });
  }

  const closeModalCallback = useCallback((value) => {
    setShowModal(value);
  }, []);

  useEffect(() => {
    const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

    if (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: setIdToken,
        },
      };

      axios
        .post(`${process.env.REACT_APP_TREND_MICRO_COUNT}`, {}, config)
        .then((response) => {
          setResponseData(response.data);
          setLoading(false);
        });
    }

    return () => {
      setResponseData([]);
    };
  }, []);

  return (
    <div className='custom-card'>
      <Row>
        <Col className='d-flex justify-content-between align-items-center'>
          <h5 className='dashboard-title mb-3'>TrendMicro Workload Security</h5>
          {downloadLoading && (
            <div className='d-flex flex-column justify-content-center'>
              <SpinnerLoader />
              {/* <span className='custom-loader-message'>
                File will be downloaded in a while !
              </span> */}
            </div>
          )}
          {downloadLoading === false && (
            <button
              className='teal-bordered-download-btn-sm'
              onClick={() => {
                trendMicroDownload();
              }}
            >
              <i
                className='fa fa-download'
                style={{ fontSize: '12px', marginRight: '6px' }}
              ></i>
              Download
            </button>
          )}
        </Col>
      </Row>
      {loading && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center mt-3'>
            <SpinnerLoader />
          </Col>
        </Row>
      )}
      {loading === false && (
        <>
          <Row>
            <Col md={12} xs={12}>
              <ReactECharts
                option={awsOption}
                onEvents={{
                  click: onChartClick,
                }}
                style={{ height: '200px', width: '100%' }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ul className='trend-micro-legend-list-wrapper px-0'>
                <li
                  className='trend-micro-legend-list'
                  onClick={() => {
                    setTrendCanvasTitle('Host Details');
                    setSelectedLegend('inactive');
                    shortDispatch();
                  }}
                >
                  <div className='trend-micro-legend-list-yellow'></div>
                  Unmanaged
                </li>
                <li
                  className='trend-micro-legend-list'
                  onClick={() => {
                    setTrendCanvasTitle('Host Details');
                    setSelectedLegend('critical');
                    shortDispatch();
                  }}
                >
                  <div className='trend-micro-legend-list-red'></div>
                  Error
                </li>

                <li
                  className='trend-micro-legend-list'
                  onClick={() => {
                    setTrendCanvasTitle('Host Details');
                    setSelectedLegend('warning');
                    shortDispatch();
                  }}
                >
                  <div className='trend-micro-legend-list-orange'></div>
                  Warning
                </li>
              </ul>
            </Col>
          </Row>
        </>
      )}

      {trendCanvas === true && (
        <TrendCanvas
          title={trendCanvasTitle}
          selectedLegend={selectedLegend.toLowerCase()}
          userInfo={userInfo}
        />
      )}

      {showModal && (
        <CustomModal
          showModal={true}
          children={
            <TrendMicroModal
              message={modalMessage}
              closeModal={closeModalCallback}
            />
          }
        />
      )}
    </div>
  );
};

export default TrendMicro;
