import Api from "api/api";
import client from "api/client";
import { prearePostRequest } from "api/cloudeye";

export function helpAndSupportListApi(values) {
  return client(Api.helpAndSupport, {
    ...prearePostRequest(values),
  }).then((res) => res.data);
}

export function helpAndSupportCreateApi(values) {
  return client(Api.createHelpSupportTicket, {
    ...prearePostRequest(values),
  }).then((res) => res.data);
}
