import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

// Import Internal Components
import ToolsExceptionSidebar from "../ToolsExceptionSidebar";
import AccountRequestsTable from "./AccountRequestsTable";

// Import Constant
import { USER_INFO } from "redux/user/userConstant";
import {
  CS_Hub_AWS_Account_Request_Admin,
  CS_Hub_AWS_Dynamo_Search_Request_Admin,
  CS_Hub_Security_Admin_Branch_1,
  CS_Hub_Security_Admin_Branch_2,
  CS_Hub_Security_Tool_Exception_Approver_Group_1,
  CS_Hub_Security_Tool_Exception_Approver_Group_2,
} from "roles/roles";
import NotFound from "pages/NotFound";
import { useTranslation } from "react-i18next";

// Logo
import awsLogo from "images/aws-logo.svg";
import azureLogo from "images/azure-logo.svg";
import PageContentSidebar from "components/PageContent/PageContentSidebar";

const ExceptionViewIndex = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { authState, oktaAuth } = useOktaAuth();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const [userAccess, setUserAccess] = useState(false);

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch]);

  useEffect(() => {
    const userAccessBool =
      userInfo?.groups?.includes(CS_Hub_Security_Admin_Branch_1) ||
      userInfo?.groups?.includes(CS_Hub_Security_Admin_Branch_2) ||
      userInfo?.groups?.includes(CS_Hub_AWS_Account_Request_Admin) ||
      userInfo?.groups?.includes(
        CS_Hub_Security_Tool_Exception_Approver_Group_2
      ) ||
      userInfo?.groups?.includes(
        CS_Hub_Security_Tool_Exception_Approver_Group_1
      )
        ? true
        : false;
    setUserAccess(userAccessBool);
  }, [userInfo]);

  return (
    <>
      {userInfo !== null && authState && authState.isAuthenticated && (
        <>
          {userAccess ? (
            <Container fluid>
              <Row>
                <Col md={2} className="px-0">
                  <PageContentSidebar
                    firstLogoLink="/tool-exception-aws-view"
                    firstLogoImg={awsLogo}
                    fisrtLogoAlt="AWS"
                    firstLogoFirstMatchingLink="tool-exception-aws-view"
                    firstLogoSecondMatchingLink="tool-exception-aws-add"
                    firstLogoFirstLinkText={t("view_request")}
                    firstLogoSecondLinkText={t("Add Request")}
                    secondLogoLink="/tool-exception-azure-view"
                    secondLogoImg={azureLogo}
                    secondLogoAlt="Azure"
                    secondLogoFirstMatchingLink="tool-exception-azure-view"
                    secondLogoSecondMatchingLink="tool-exception-azure-add"
                    secondLogoFirstLinkText={t("view_request")}
                    secondLogoSecondLinkText={t("Add Request")}
                    showLinks="yes"
                  />
                </Col>
                <Col md={10} className="mt-4">
                  <AccountRequestsTable />
                </Col>
              </Row>
            </Container>
          ) : (
            <NotFound notAuthorised />
          )}
        </>
      )}
    </>
  );
};

export default ExceptionViewIndex;
