import React from 'react';
import { Row, Col } from 'react-bootstrap';

const BulkEditFormMenu = ({
  accountContact,
  billingContact,
  securityContact,
  technicalContact,
  billingInfo,
  groupEmailAddress,
  accountDetails,
  accountConfirmation,
}) => {
  return (
    <Row>
   <Col md={4}>
        <button
          className={
            accountConfirmation
              ? 'view-edit-box-form-title-btn-active'
              : 'view-edit-box-form-title-btn'
          }
        >
          Account Confirmation
        </button>
      </Col>

      <Col md={4}>
        <button
          className={
            billingInfo
              ? 'view-edit-box-form-title-btn-active'
              : 'view-edit-box-form-title-btn'
          }
        >
          Billing Info
        </button>
      </Col>

      <Col md={4}>
        <button
          className={
            accountDetails
              ? 'view-edit-box-form-title-btn-active'
              : 'view-edit-box-form-title-btn'
          }
        >
          Account Details
        </button>
      </Col>
      <Col md={4}>
        <button
          className={
            billingContact
              ? 'view-edit-box-form-title-btn-active'
              : 'view-edit-box-form-title-btn'
          }
        >
          Billing Contact
        </button>
      </Col>

    </Row>
  );
};

export default BulkEditFormMenu;
