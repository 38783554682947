import React from "react";
import { Row, Col } from "react-bootstrap";

// Import Icons
import backicon from "icons/back.svg";
import editicon from "icons/edit.svg";

import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";

const AccountView = ({
  data,
  cloudTitle,
  closeViewParent,
  openEditParent,
  userInfo,
}) => {
  return (
    <div className="view-edit-box-conatiner">
      <div className="view-edit-box-top-bar">
        <div className="view-edit-box-back-title-container">
          <img
            src={backicon}
            alt="Back"
            className="view-edit-box-back-btn"
            onClick={() => closeViewParent(false)}
          />

          <span className="view-edit-box-accountid mx-3">Account Details</span>
        </div>
        <div className="view-edit-box-back-edit-container">
          <img
            src={editicon}
            alt="Edit"
            className="view-edit-box-edit-btn"
            onClick={() => {
              closeViewParent(false);
              openEditParent(true);
            }}
          />
        </div>
      </div>
      <div className="table-view-box-content mt-4 mb-5">
        <Row>
          <Col md={12}>
            <div className="account-request-view-title-container">
              <p className="account-request-view-title">Account Contact</p>
            </div>
          </Col>
          <Col md={12} className="mt-3">
            <div className="account-request-view-table-container">
              <table width="100%">
                <tbody className="account-request-view-table-tbody">
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      First Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.firstNameAO}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Last Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.lastNameAO}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Email
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.emailAO}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Country
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.countryAO}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Phone Number
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.phoneNoAO}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-3">
            <div className="account-request-view-title-container">
              <p className="account-request-view-title">Billing Contact</p>
            </div>
          </Col>
          <Col md={12} className="mt-3">
            <div className="account-request-view-table-container">
              <table width="100%">
                <tbody className="account-request-view-table-tbody">
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      First Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.firstNameBC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Last Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.lastNameBC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Email
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.emailBC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Country
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.countryBC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Phone Number
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.phoneNoBC}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-3">
            <div className="account-request-view-title-container">
              <p className="account-request-view-title">Security Contact</p>
            </div>
          </Col>
          <Col md={12} className="mt-3">
            <div className="account-request-view-table-container">
              <table width="100%">
                <tbody className="account-request-view-table-tbody">
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      First Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.firstNameSC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Last Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.lastNameSC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Email
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.emailSC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Country
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.countrySC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Phone Number
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.phoneNoSC}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-3">
            <div className="account-request-view-title-container">
              <p className="account-request-view-title">Technical Contact</p>
            </div>
          </Col>
          <Col md={12} className="mt-3">
            <div className="account-request-view-table-container">
              <table width="100%">
                <tbody className="account-request-view-table-tbody">
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      First Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.firstNameTC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Last Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.lastNameTC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Email
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.emailTC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Country
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.countryTC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Phone Number
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.phoneNoTC}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-3">
            <div className="account-request-view-title-container">
              <p className="account-request-view-title">Billing Info</p>
            </div>
          </Col>
          <Col md={12} className="mt-3">
            <div className="account-request-view-table-container">
              <table width="100%">
                <tbody className="account-request-view-table-tbody">
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Billing Operating Company
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.billingopco}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Billing Sub Operating Company
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.billingSubOpco}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Billing Department
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.billingDepartment}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Billing Region
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.billingRegion}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      MAX Monthly Expected Spend in USD
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.maxSpend}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Bill To
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.billto}
                    </td>
                  </tr>
                  {data.io && (
                    <tr>
                      <td
                        width="50%"
                        className="account-request-view-table-td-key"
                      >
                        IO
                      </td>
                      <td
                        width="50%"
                        className="account-request-view-table-td-value"
                      >
                        {data.io}
                      </td>
                    </tr>
                  )}
                  {data.costcenter && (
                    <tr>
                      <td
                        width="50%"
                        className="account-request-view-table-td-key"
                      >
                        Cost Center
                      </td>
                      <td
                        width="50%"
                        className="account-request-view-table-td-value"
                      >
                        {data.costcenter}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-3">
            <div className="account-request-view-title-container">
              <p className="account-request-view-title">Group Email Address</p>
            </div>
          </Col>
          <Col md={12} className="mt-3">
            <div className="account-request-view-table-container">
              <table width="100%">
                <tbody className="account-request-view-table-tbody">
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Technical Group:
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.technicalGroup}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Security Group:
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.securityGroup}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Billing Group: 
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.billingGroup}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
          <Col md={12} className="mt-3">
            <div className="account-request-view-title-container">
              <p className="account-request-view-title">Additional Details</p>
            </div>
          </Col>
          <Col md={12} className="mt-3">
            <div className="account-request-view-table-container">
              <table width="100%">
                <tbody className="account-request-view-table-tbody">
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Avail Golden AMI:
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {(data.pgcsgoldenami?.toLowerCase() == "no" || data.pgcsgoldenami?.toLowerCase() == "false") ? "No" : "Yes"}
                    </td>
                  </tr>

                { userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? 
                  <>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Avail Trend Micro:
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {(data.ptm?.toLowerCase() == "no" || data.ptm?.toLowerCase() == "false") ? "No" : "Yes"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Avail Conformity Tool:
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {(data.conformity?.toLowerCase() == "no" || data.conformity?.toLowerCase() == "false") ? "No" : "Yes"}
                    </td>
                  </tr>
                  </>
                  : null}
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Avail Managed AD:
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {(data.sonyad?.toLowerCase() == "no" || data.sonyad?.toLowerCase() == "false") ? "No" : "Yes"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-3">
            <div className="account-request-view-title-container">
              <p className="account-request-view-title">Account Details</p>
            </div>
          </Col>
          <Col md={12} className="mt-3">
            <div className="account-request-view-table-container">
              <table width="100%">
                <tbody className="account-request-view-table-tbody">
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      *Account Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.accountName}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      *Account Description
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.accountdescription}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      *Application Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.purposeid}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      *Operating Company
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.opco}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      *Sub Operating Company
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.subopco}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      *Department
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.department}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      *Environment
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.environmenttype}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      *Asset User Base
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.assetUserBase}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      *Data Classification
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.dataClassification}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AccountView;
