import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import ReactECharts from 'echarts-for-react';
import { useDispatch, useSelector } from 'react-redux';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

import TrendCanvas from './TrendCanvas';

// Import Constant
import { PRISMA, TREND } from 'redux/cloudeye-user/cloueyeUserConstant';

// Helper
import { postApiCall } from 'helper/postApiCall';

const TrendMicro = ({ applicationName, userInfo }) => {
  const dispatch = useDispatch();

  const cloudEye = useSelector((state) => state.cloudEye);
  const { trendCanvas } = cloudEye;

  const apiUrl = `${process.env.REACT_APP_CLOUD_EYE_SECURITY_TREND_MICRO_COUNT}`;
  const idToken = `${userInfo.idToken}`;

  const [canvasTitle, setCanvasTitle] = useState(null);
  const [selectedLegend, setSelectedLegend] = useState(null);

  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [error, setError] = useState(null);

  var dataSet;
  if (Object.keys(responseData).length > 0) {
    dataSet = [
      {
        name: 'Critical',
        value: responseData.Error,
        color: '#ff3f3f',
      },
      {
        name: 'InActive ',
        value: responseData.InActive,
        color: '#ffe181',
      },
      {
        name: 'Active ',
        value: responseData.Active,
        color: '#69bc50',
      },
      {
        name: 'Warning',
        value: responseData.Warning,
        color: '#FD7B43',
      },
    ];
  } else {
    dataSet = [];
  }

  var pieOption = {
    maintainAspectRatio: false,
    tooltip: {
      show: true,
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: 'pie',
        radius: ['60px', '50px'],
        data: dataSet.map(({ name, value, color, borderRadius, type }) => ({
          name,
          value,
          itemStyle: { color: color, borderRadius: borderRadius },
          type,
        })),
        label: {
          alignTo: 'labelLine',
          formatter: '{c}',
          minMargin: 5,
          edgeDistance: 10,
        },
        labelLine: {
          length: 20,
          length2: 10,
        },
      },
    ],
  };

  function onChartClick(param, echarts) {
    setCanvasTitle('Trend Host Details');
    const getLabelText = param.data.name;
    if (getLabelText === 'Critical') {
      setSelectedLegend('Error');
    } else {
      setSelectedLegend(getLabelText.trim());
    }
    dispatch({
      type: TREND,
      payload: true,
    });
    dispatch({
      type: PRISMA,
      payload: false,
    });
  }

  // 1st Load
  useEffect(() => {
    setLoading(true);
    const requestBody = {
      applicationname: `${applicationName}`,
    };

    postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        setResponseData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(
          'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
        );
        setLoading(false);
      });

    return () => {
      setResponseData([]);
    };
  }, [applicationName]);

  return (
    <div className='custom-card'>
      {loading && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center mt-3'>
            <SpinnerLoader />
          </Col>
        </Row>
      )}
      {loading === false && error !== null && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center mt-3'>
            <DangerAlert message={error} />
          </Col>
        </Row>
      )}
      {loading === false && error === null && (
        <>
          <Row>
            <Col md={12} xs={12}>
              <p className='subtitle-text px-2'>Trend Micro</p>
            </Col>
            <Col md={12} xs={12}>
              <ReactECharts
                option={pieOption}
                onEvents={{
                  click: onChartClick,
                }}
                className='aws-chart-logo-center-security'
                style={{ height: '200px', width: '100%' }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className='d-flex align-items-center'>
              <ul className='trend-micro-legend-list-wrapper align-items-center px-0'>
                <li className='trend-micro-legend-list'>
                  <div className='trend-micro-legend-list-yellow'></div>
                  Unmanaged
                </li>
                <li className='trend-micro-legend-list'>
                  <div className='trend-micro-legend-list-red'></div>
                  Error
                </li>

                <li className='trend-micro-legend-list'>
                  <div className='trend-micro-legend-list-orange'></div>
                  Warning
                </li>
              </ul>
            </Col>
          </Row>
        </>
      )}

      {trendCanvas === true && (
        <TrendCanvas
          userInfo={userInfo}
          title={canvasTitle}
          selectedLegend={selectedLegend}
          applicationName={applicationName}
        />
      )}
    </div>
  );
};

export default TrendMicro;
