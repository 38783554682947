import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

// Import Components
import PageContentSidebar from 'components/PageContent/PageContentSidebar';

// Internal
import AWSTable from './AWSTable';

// Import Constant
import { USER_INFO } from 'redux/user/userConstant';

// Logo
import awsLogo from 'images/aws-logo.svg';
import azureLogo from 'images/azure-logo.svg';

// Roles
import {
  CS_Hub_AWS_Account_Request_Admin,
  CS_Hub_AWS_Account_Search_Read_Only_Security,
  CS_Hub_AWS_Dynamo_Search_Request_Admin,
} from 'roles/roles';

const AWSAccountSearchIndex = ({ history }) => {
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });

        if (
          info.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
          info.groups.includes(CS_Hub_AWS_Account_Search_Read_Only_Security) ||
          info.groups.includes(CS_Hub_AWS_Dynamo_Search_Request_Admin)
        ) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          history.push('/dashboard');
        }
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch, history]);
  return (
    <>
      {userInfo !== null &&
        authState &&
        authState.isAuthenticated &&
        isAuthenticated && (
          <Container fluid>
            <Row>
              <Col md={2} className='px-0'>
                <PageContentSidebar
                  firstLogoLink='/aws-account-search'
                  firstLogoImg={awsLogo}
                  fisrtLogoAlt='AWS'
                  firstLogoFirstMatchingLink='aws-account-search'
                  secondLogoLink='/azure-account-search'
                  secondLogoImg={azureLogo}
                  secondLogoAlt='Azure'
                  secondLogoFirstMatchingLink='azure-account-search'
                  showLinks='no'
                />
              </Col>
              <Col md={10}>
                <AWSTable />
              </Col>
            </Row>
          </Container>
        )}
    </>
  );
};

export default AWSAccountSearchIndex;
