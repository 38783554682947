import React from 'react';

// Import Components
import Header from './Header';
import SideBar from './Sidebar';
import PageContent from './PageContent';

const index = ({ children }) => {
  return (
    <>
      <Header />
      <SideBar />
      <PageContent children={children} />
    </>
  );
};

export default index;
