import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonGroup,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Row,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { postApiCall } from "helper/postApiCall";
import Loader from "components/Common/Loader";
import { getISTTime } from "static-contents/billing-data";
import GsidCSVDownload from "../GSIDIO/CSVDownload";
import ModCSVDownload from "../Modification/CSVDownload";
import AwsCreditsCSVDownload from "../AWSCredits/CSVDownload";
import PamCSVDownload from "../PAMCosts/CSVDownload";
import OpscostCSVDownload from "../../../Requests/BillingDetail/CSVDownload";
const postApiUrl = `${process.env.REACT_APP_BILLING_FILE_UPLOAD}`;

function FileUpload({
  isFileUploadOpen,
  setFileUploadOpen,
  headerValues,
  datatype,
  resetData = () => {},
  data = null,
}) {
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const idToken = userInfo.idToken;
  const { t } = useTranslation();
  let fileToJson = [];

  const [fileName, setFileName] = useState("");
  const [step, setStep] = useState(0);
  const [selectedAction, setSelectedAction] = useState(0);
  const [fileUploadErrors, setfileUploadErrors] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  // const [fileToJson, setFileToJson] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [isDownloaded, setDownloaded] = useState(false);

  const ref = useRef();

  const csvParser = (data) => {
    const text = data.split(/\r\n|\n/);
    const rows = [];
    const [first, ...lines] = text;
    const headers = first.split(",");
    const checkHeaders = headers.filter((item) => !headerValues.includes(item));
    const filteredLines = lines.filter((everyString) => everyString !== "");

    filteredLines.map((line) => {
      const values = line.split(",");
      const row = Object.fromEntries(
        headers.map((header, i) => [header, values[i]])
      );
      rows.push(row);
    });
    fileToJson = rows;
    // return rows;
    return checkHeaders?.length;
  };

  const filterCsvHeader = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsText(file, "UTF-8");

      fileReader.onload = () => {
        resolve(csvParser(fileReader.result));
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadImage = async (e) => {
    debugger;
    setfileUploadErrors(null);
    setFileName("");
    setFileContent(null);
    const file = e.target.files[0];
    const filePath = e.target.files[0].name;
    const fileSize = e.target.files[0].size;
    const checkFileSize = Math.round(fileSize / 1024);

    const allowedExtensions = ["csv"];
    const fileExtension = filePath.split(".").pop();

    if (checkFileSize >= 5120) {
      setfileUploadErrors("File size should be below 5MB");
      ref.current.value = "";
    } else if (!allowedExtensions.includes(fileExtension)) {
      setfileUploadErrors("file type not allowed");
      ref.current.value = "";
    } else {
      setLoading(true);
      const isNotValidHeaders = await filterCsvHeader(file);
      setLoading(false);
      if (isNotValidHeaders) {
        setfileUploadErrors("valid headers are missing");
        setStep(0);
      } else {
        setFileName(file.name);
        setFileContent(file);
        handleUpload();
      }
    }
  };

  const handleChange = (e) => {
    setSelectedAction(Number(e.target.value));
  };

  const handleUpload = () => {
    // setFileUploadOpen(false);

    setApiError(null);
    const requestBody = {
      data: fileToJson,
      updatedby: userInfo?.email,
      updateddate: getISTTime().getDate,
      updatedtime: getISTTime().getTime,
      actionType: selectedAction,
      datatype: datatype,
    };
    // console.log("fileToJson", requestBody);
    setLoading(true);

    postApiCall(postApiUrl, idToken, requestBody)
      .then((response) => {
        if (response?.data?.status === "Success") {
          setLoading(false);
          setShowSuccessMessage(true);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setApiError(
          "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
        );
      });
  };
  const DownloadMessage = () => {
    return (
      <div>
        <p>
          Please download the file by clicking the button below, and use the
          same file for uploading.
        </p>
        {datatype === "GSIDIO" ? (
          <GsidCSVDownload
            fileDownload={true}
            data={data}
            setDownloaded={setDownloaded}
          />
        ) : datatype === "AWS Credit" ? (
          <AwsCreditsCSVDownload
            fileDownload={true}
            data={data}
            setDownloaded={setDownloaded}
          />
        ) : datatype === "MOD" ? (
          <ModCSVDownload
            fileDownload={true}
            data={data}
            setDownloaded={setDownloaded}
          />
        ) : datatype === "PAM" ? (
          <PamCSVDownload
            fileDownload={true}
            data={data}
            setDownloaded={setDownloaded}
          />
        ) : (
          datatype === "OpsCost" && (
            <OpscostCSVDownload
              fileDownload={true}
              data={data}
              setDownloaded={setDownloaded}
            />
          )
        )}
      </div>
    );
  };
  return (
    <>
      {isLoading ? (
        <>
          <p>File upload is in progress... It may take a while</p>
          <Loader />
        </>
      ) : null}
      {showSuccessMessage || apiError ? (
        <>
          {`${
            showSuccessMessage
              ? "Your file has been uploaded successfully."
              : apiError
          } `}
          <ul className="btn-ul mb-0">
            <li>
              <button
                type="button"
                className="teal-filled-btn"
                onClick={() => {
                  setFileUploadOpen(false);
                  if (datatype === "PAM") {
                    resetData();
                  }
                }}
              >
                Ok
              </button>
            </li>
          </ul>
        </>
      ) : null}
      {!isLoading && !showSuccessMessage && (
        <>
          <div className="billing-upload-container" />
          <div
            className="billing-upload-dropdown "
            show={true}
            style={{ right: "-16px", top: "0px" }}
          >
            {step === 0 && <DownloadMessage />}
            {step === 1 && (
              <div>
                <div className="text-center billing-action-type-h fw-bold">
                  Action Type
                </div>
                <Form.Check
                  type="radio"
                  label={`Append to the existing file`}
                  id={`Append-File`}
                  checked={selectedAction === 0}
                  value={0}
                  onClick={(e) => handleChange(e)}
                  className="cursor-pointer-selection ps-0 billing-action-type a"
                />
                <Form.Check
                  type="radio"
                  label={`Replace the source file`}
                  id={`Replace-File`}
                  checked={selectedAction === 1}
                  value={1}
                  onClick={(e) => handleChange(e)}
                  className="cursor-pointer-selection ps-0 billing-action-type b"
                />
              </div>
            )}

            {step === 2 && (
              <>
                <div className="text-center">
                  Make sure the (csv)file that is being uploaded has the headers
                </div>
                <div
                  as="button"
                  className="file-upload-bg"
                  onClick={() => setfileUploadErrors(null)}
                >
                  <Row>
                    <Col md={12} sm={12} className="p-1 month-selection-radio">
                      <Form.Group
                        controlId="formFile"
                        className=" billing-file-upload-csv"
                      >
                        {/* <Form.Label>{t("file_upload")}</Form.Label> */}
                        {fileName !== "" && (
                          <div className="mb-3 file-name-label">{fileName}</div>
                        )}
                        <div className="custom-upload-wrapper">
                          <button className="custom-upload-button">
                            <i className="fa fa-cloud-upload"></i>
                            {t("add_file")}
                          </button>
                          <input
                            type="file"
                            ref={ref}
                            onChange={(e) => {
                              uploadImage(e);
                            }}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </>
            )}
            {fileUploadErrors && (
              <p className="text-danger mb-0 fs-13">{fileUploadErrors}</p>
            )}
            <ul className="px-0 btn-ul mb-0 justify-content-center billing-upload-ul">
              <li>
                <button
                  type="button"
                  className="teal-bordered-btn"
                  onClick={() => {
                    setFileUploadOpen(false);
                    setStep(0);
                    setFileName("");
                  }}
                >
                  {t("cancel")}
                </button>
              </li>
              <li>
                <button
                  className={`${
                    step === 0 ? "padding-8-20" : ""
                  } teal-filled-btn ${
                    step === 2 && fileName === ""
                      ? "disabled-div disabled-bg"
                      : ""
                  } ${
                    !isDownloaded && step === 0
                      ? "disabled-div disabled-bg"
                      : ""
                  }`}
                  type="button"
                  onClick={(e) => {
                    setfileUploadErrors(null);
                    setFileName("");
                    if (step === 0) {
                      setStep(1);
                    } else if (step === 1) {
                      setStep(2);
                    } else {
                      handleUpload();
                    }
                  }}
                >
                  {step === 2 ? t("Upload") : t("Next")}
                </button>
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
}

export default FileUpload;
