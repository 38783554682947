const cidrValidator = (ip, region, accountNumber, accountName, submittedBy) => {
  const inputError = {};

  if (ip.trim() === '') {
    inputError.v_ip = 'IP must not be empty';
  }

  if (region === '') {
    inputError.v_region = 'Region must not be empty';
  }

  if (accountNumber.trim() === '') {
    inputError.v_accountNumber = 'Account Number must not be empty';
  }

  if (accountName === '') {
    inputError.v_accountName = 'Account Name must not be empty';
  }

  if (submittedBy === '') {
    inputError.v_submittedBy = 'SubmittedBy must not be empty';
  }

  return {
    inputError,
    valid: Object.keys(inputError).length < 1,
  };
};

export { cidrValidator };
