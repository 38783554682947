import React, { useMemo, useState, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import countryList from 'react-select-country-list';

// Import Form Component
import TextInput from 'components/Forms/TextInput';
import CountryDropdown from 'components/Forms/CountryDropdown';

// Import Common Component
import CustomModal from 'components/Modal/CustomModal';

// Import Internal Component
import EditRequestCancel from './EditRequestCancel';
import EditFormMenu from './EditFormMenu';

// Validator
import { accountContactValidate } from '../../../Validator/validator';

const EditAccountContact = ({ step, setStep, formData, setFormData }) => {
  // Fetch Country List
  const countryData = useMemo(() => countryList().getData(), []);

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // handle onchange
  const handleData = ({ target }) => {
    setFormData({
      type: 'UPDATE_ACCOUNT_CONTACT',
      payload: { [target.name]: target.value },
    });
  };

  const handleContinue = (e) => {
    e.preventDefault();
    const errors = accountContactValidate(formData.accountContact);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setStep(step + 1);
    }
  };
  return (
    <>
      <Row>
        <Col md={12} xs={12}>
          <EditFormMenu accountContact setStep={setStep} step={step} formData={formData} setDataInputError={setDataInputError}/>
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        <Row className='px-3 mt-3'>
          <Col md={12}>
            <TextInput
              label='*First Name:'
              name='firstNameAO'
              value={formData.accountContact.firstNameAO}
              onChange={(e) => handleData(e)}
              placeholder='First Name'
              isInvalid={dataInputError.firstNameAO ? true : false}
              invalidMessage={dataInputError.firstNameAO}
            />
          </Col>
          <Col md={12}>
            <TextInput
              label='*Last Name:'
              name='lastNameAO'
              value={formData.accountContact.lastNameAO}
              onChange={(e) => handleData(e)}
              placeholder='Last Name'
              isInvalid={dataInputError.lastNameAO ? true : false}
              invalidMessage={dataInputError.lastNameAO}
            />
          </Col>
          <Col md={12}>
            <TextInput
              label='*Email:'
              name='emailAO'
              value={formData.accountContact.emailAO}
              onChange={(e) => handleData(e)}
              placeholder='Mail Id'
              isInvalid={dataInputError.emailAO ? true : false}
              invalidMessage={dataInputError.emailAO}
            />
          </Col>
          <Col md={12}>
            <CountryDropdown
              label='*Country:'
              name='countryAO'
              value={formData.accountContact.countryAO}
              onChange={(e) => handleData(e)}
              selectValues={countryData}
              isInvalid={dataInputError.countryAO ? true : false}
              invalidMessage={dataInputError.countryAO}
            />
          </Col>
          <Col md={12}>
            <TextInput
              label='*Phone Number:'
              name='phoneNoAO'
              value={formData.accountContact.phoneNoAO}
              onChange={(e) => handleData(e)}
              placeholder='Phone Number'
              isInvalid={dataInputError.phoneNoAO ? true : false}
              invalidMessage={dataInputError.phoneNoAO}
            />
          </Col>
        </Row>
        <Row className='px-3'>
          <div className='d-flex justify-content-end'>
            <ul className='px-0 btn-ul'>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    setShowCancelModal(true);
                  }}
                >
                  Cancel
                </button>
              </li>
              <li>
                <button type='submit' className='teal-filled-btn'>
                  Save & Continue
                </button>
              </li>
            </ul>
          </div>
        </Row>
      </Form>
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<EditRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

export default EditAccountContact;
