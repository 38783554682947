export const requestTrainingValidator = ({ cloudType, cloudService }) => {
  let errors = {};

  if (!cloudType) {
    errors.cloudType = 'Cloud type is required';
  }

  if (!cloudService) {
    errors.cloudService = 'Cloud service is required';
  }

  return errors;
};
