import React from 'react';
import { Row, Col } from 'react-bootstrap';

const EditFormMenu = ({
  accountContact,
  billingContact,
  securityContact,
  technicalContact,
  billingInfo,
  groupEmailAddress,
  accountDetails,
}) => {
  return (
    <Row className='mt-4'>
      <Col md={4}>
        <button
          className={
            accountContact
              ? 'view-edit-box-form-title-btn-active'
              : 'view-edit-box-form-title-btn'
          }
        >
          Account Contact
        </button>
      </Col>
      <Col md={4}>
        <button
          className={
            billingContact
              ? 'view-edit-box-form-title-btn-active'
              : 'view-edit-box-form-title-btn'
          }
        >
          Billing Contact
        </button>
      </Col>
      <Col md={4}>
        <button
          className={
            securityContact
              ? 'view-edit-box-form-title-btn-active'
              : 'view-edit-box-form-title-btn'
          }
        >
          Security Contact
        </button>
      </Col>
      <Col md={4}>
        <button
          className={
            technicalContact
              ? 'view-edit-box-form-title-btn-active'
              : 'view-edit-box-form-title-btn'
          }
        >
          Technical Contact
        </button>
      </Col>
      <Col md={4}>
        <button
          className={
            billingInfo
              ? 'view-edit-box-form-title-btn-active'
              : 'view-edit-box-form-title-btn'
          }
        >
          Billing Info
        </button>
      </Col>
      <Col md={4}>
        <button
          className={
            groupEmailAddress
              ? 'view-edit-box-form-title-btn-active'
              : 'view-edit-box-form-title-btn'
          }
        >
          Group Email Address
        </button>
      </Col>
      <Col md={4}>
        <button
          className={
            accountDetails
              ? 'view-edit-box-form-title-btn-active'
              : 'view-edit-box-form-title-btn'
          }
        >
          Account Details
        </button>
      </Col>
    </Row>
  );
};

export default EditFormMenu;
