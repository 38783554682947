import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Row, Col } from 'react-bootstrap';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import Pagination from 'components/Pagination/Pagination';
import DangerAlert from 'components/Message/DangerAlert';

// Helper
import { postApiCall } from 'helper/postApiCall';

import backicon from 'icons/back.svg';

// Import Constant
import { TREND } from 'redux/security/securityConstant';

const TrendCanvas = ({ title, selectedLegend, userInfo, applicationName }) => {
  // Set Legend Label
  const [canvasSelectLegend, setCanvasSelectLegend] = useState(selectedLegend);

  // API State
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [error, setError] = useState(null);

  const [recordLength, setRecordLength] = useState(0);

  // API Variable
  const apiUrl = `${process.env.REACT_APP_CLOUD_EYE_SECURITY_TREND_MICRO_DETAILS}`;
  const idToken = `${userInfo.idToken}`;

  // Pagination Part
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = useCallback(() => {
    const requestBody = {
      applicationname: applicationName,
    };
    setLoading(true);
    postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        setResponseData(response.data.body);
        setLoading(false);
      })
      .catch((err) => {
        setError(
          'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
        );
        setLoading(false);
      });
  }, [apiUrl, idToken]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    const selectedLabelData = responseData.filter(
      (d) => d.ComputerStatus === canvasSelectLegend.toLowerCase()
    );

    setRecordLength(selectedLabelData.length);

    return selectedLabelData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, PageSize, responseData, canvasSelectLegend]);

  useEffect(() => {
    fetchData();
    return () => {
      setResponseData([]);
    };
  }, []);

  const dispatch = useDispatch();
  function hideCanvas() {
    dispatch({
      type: TREND,
      payload: false,
    });
  }

  return (
    <div className='offcanvas-details-wrapper pb-5'>
      {loading && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center'>
            <SpinnerLoader />
          </Col>
        </Row>
      )}
      {loading === false && (
        <>
          <div className='offcanvas-details-top-bar my-3'>
            <div className='offcanvas-details-back-heading'>
              <img
                src={backicon}
                alt='Back'
                className='view-edit-box-back-btn'
                onClick={() => hideCanvas()}
              />
              <h5 className='view-edit-box-title d-flex align-items-center'>
                {title}
              </h5>
            </div>
            <div className='offcanvas-legend-details'>
              <ul className='canvas-legend-list-wrapper'>
                <li
                  className={
                    canvasSelectLegend.toLowerCase() === 'active'
                      ? 'canvas-legend-list-active'
                      : 'canvas-legend-list'
                  }
                  onClick={() => setCanvasSelectLegend('active')}
                >
                  <div className='canvas-legend-list-green'></div>
                  Managed
                </li>

                <li
                  className={
                    canvasSelectLegend.toLowerCase() === 'inactive'
                      ? 'canvas-legend-list-active'
                      : 'canvas-legend-list'
                  }
                  onClick={() => setCanvasSelectLegend('inactive')}
                >
                  <div className='canvas-legend-list-yellow'></div>
                  Unmanaged
                </li>

                <li
                  className={
                    canvasSelectLegend.toLowerCase() === 'error'
                      ? 'canvas-legend-list-active'
                      : 'canvas-legend-list'
                  }
                  onClick={() => setCanvasSelectLegend('error')}
                >
                  <div className='canvas-legend-list-red'></div>
                  Error
                </li>

                <li
                  className={
                    canvasSelectLegend.toLowerCase() === 'warning'
                      ? 'canvas-legend-list-active'
                      : 'canvas-legend-list'
                  }
                  onClick={() => setCanvasSelectLegend('warning')}
                >
                  <div className='canvas-legend-list-orange'></div>
                  Warning
                </li>
              </ul>
            </div>
          </div>

          {loading && (
            <Row>
              <Col className='d-flex justify-content-center align-items-center'>
                <SpinnerLoader />
              </Col>
            </Row>
          )}

          {loading === false && currentTableData.length === 0 && (
            <p className='text-center'>No data to display</p>
          )}

          {loading === false && currentTableData.length > 0 && (
            <>
              <Table
                responsive
                className=' mb-0 table-centered table-nowrap table-borderless'
              >
                <thead className='offcanvas-details-table-head-font'>
                  <tr className='mb-2'>
                    <td>HOST NAME</td>
                    <td>DISPLAY NAME</td>
                    <td>PLATFORM</td>
                    <td>STATUS MESSAGE</td>
                    <td>STATUS</td>
                  </tr>
                </thead>
                <tbody className='offcanvas-details-table-body-font'>
                  {currentTableData.map((data, index) => (
                    <tr key={index} className='mb-3'>
                      <td>{data.hostname}</td>
                      <td>{data.AccountName}</td>
                      <td>{data.Platform}</td>
                      <td>{data.StatusMessage}</td>
                      <td align='center'>
                        {canvasSelectLegend.toLowerCase() === 'error' && (
                          <div className='badge-wrapper red-badge'>
                            {data.ComputerStatus}
                          </div>
                        )}
                        {canvasSelectLegend.toLowerCase() === 'inactive' && (
                          <div className='badge-wrapper yellow-badge'>
                            {data.ComputerStatus}
                          </div>
                        )}
                        {canvasSelectLegend.toLowerCase() === 'active' && (
                          <div className='badge-wrapper green-badge'>
                            {data.ComputerStatus}
                          </div>
                        )}

                        {canvasSelectLegend.toLowerCase() === 'warning' && (
                          <div className='badge-wrapper orange-badge'>
                            {data.ComputerStatus}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className='d-flex justify-content-end align-items-baseline'>
                <Pagination
                  className='pagination-bar'
                  currentPage={currentPage}
                  totalCount={recordLength}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                  currentTableDataLength={currentTableData.length}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TrendCanvas;
