import React from "react";
import gcpLogo from "icons/google-cloud.svg";
import { useTranslation } from "react-i18next";

const GcpServiceStatus = () => {
  const { t } = useTranslation();
  return (
    <div className="custom-card">
      <h5 className="dashboard-title mb-3">{t("GCP Service Status")}</h5>
      <div>
        <img
          src={gcpLogo}
          alt="GCP"
          className="service-status-img gcs-count-logo"
        />
        <a
          href="https://status.cloud.google.com/"
          className="service-status-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("clickHereToViewMore")}
        </a>
        <p className="service-status-description">{t("checkBackTextAzure")}</p>
      </div>
    </div>
  );
};

export default GcpServiceStatus;
