import Loader from "components/Common/Loader";
import TextInput from "components/Forms/TextInput";
import { postApiCall } from "helper/postApiCall";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BILLING_SCREEN_DATE } from "redux/billing/EditableTableConstant";
import { getISTTime, month } from "static-contents/billing-data";

const ChargeBackSetting = ({ setShowChargeBackSetting }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const idToken = userInfo.idToken;

  const [date, setDate] = useState(10);
  const [inputError, setInputError] = useState(false);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = React.useState(false);

  const screenDateApi = process.env.REACT_APP_BILLING_HOME_DETAILS;

  const handleData = ({ target }) => {
    let dateStr = /^[0-9]{0,}$/.test(target?.value);
    if (dateStr) {
      setDate(target?.value);
      if (!target?.value) {
        setInputError(true);
      } else {
        setInputError(false);
      }
    } else {
      setInputError(false);
    }
  };
  const handleSubmit = () => {
    setLoading(true);
    const requestBody = { dayNo: `${date}` };
    postApiCall(screenDateApi, idToken, requestBody)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setStep(1);
        }
      })
      .catch((err) => {
        console.log("something went wrong with api");
      });
  };
  const handleCancel = () => {
    setShowChargeBackSetting(false);
  };
  const getMonthString = new Date(getISTTime().getDate)?.getMonth();
  const getMonthName = month[getMonthString];
  return (
    <div>
      {" "}
      {loading ? (
        <Loader />
      ) : (
        <Row className={`${step === 0 ? "py-4" : "pt-2"}`}>
          {step === 0 && (
            <Col md={12} className="text-center mb-3">
              <Row>
                <Col md={1} />
                <Col
                  md={5}
                  className="text-end pe-0 d-flex justify-content-center flex-column"
                >
                  <p className="mb-0">Enable the screen until </p>
                </Col>
                <Col
                  md={5}
                  className="text-start ps-0 enable-screen-input-container"
                >
                  <TextInput
                    label={""}
                    name="date"
                    value={date}
                    onChange={(e) => handleData(e)}
                    placeholder={""}
                    isInvalid={inputError}
                    // invalidMessage={""}
                    className="enable-screen-input text-center"
                  />
                  <p className="mb-0 d-inline-block"> of {getMonthName}</p>
                </Col>
                <Col md={1} />
              </Row>
            </Col>
          )}
          {step === 1 && (
            <Col md={12} className="text-center">
              <p className="">Your request has been successfully updated.</p>
            </Col>
          )}
          <Col md={12} className="text-center">
            <ul className="px-0 mb-0 btn-ul">
              <li>
                <button
                  type="button"
                  className="teal-bordered-btn"
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  {`${step === 0 ? "Cancel" : "Ok"}`}
                </button>
              </li>
              {step === 0 && (
                <li>
                  <button
                    type="submit"
                    className="teal-filled-btn"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save & Submit
                  </button>
                </li>
              )}
            </ul>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ChargeBackSetting;
