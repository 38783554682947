import React from "react";
import useInitScreen from "components/InitPage";
import { useViewCloudEyeRequestsByEmail } from "./dataloader";
import { useOktaAuth } from "@okta/okta-react";
import SpinnerLoader from "components/Common/SpinnerLoader";
import Table from "components/Table";
import { useFormik } from "formik";
import * as yup from "yup";
import { Col, Container, Row } from "react-bootstrap";
import TextInput from "components/Forms/TextInput";
import { Link } from "react-router-dom";
import CloudEyeIcon from "components/Common/CloudEyeIcon";

const validationSchema = yup.object({
  Email: yup.string().email().required("Required"),
});

export default function ViewMain() {
  useInitScreen();
  return (
    <Container fluid>
      <Row>
        <Col md={2} className="px-0">
          <div className="page-content-sidebar-container">
            <ul className="passport-sidebar-logo-list">
              <li>
                <CloudEyeIcon />
                <span className="passport-sidebar-title px-2">CloudEye</span>
              </li>
            </ul>
            <ul className="page-content-sidebar-link-list p-0">
              <li className={"page-content-sidebar-link-active"}>
                <Link to={`/view-cloud-eye-request`}>View Access</Link>
              </li>
              <li className={"page-content-sidebar-link-inactive"}>
                <Link to={`/create-cloud-eye-request`}>Add Access</Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col md={10}>
          <ViewRequestsInCloudEye />
        </Col>
      </Row>
    </Container>
  );
}

export function ViewRequestsInCloudEye() {
  const {
    mutateAsync: getRequestsByEmail,
    isLoading,
    data = [],
  } = useViewCloudEyeRequestsByEmail();
  const { authState } = useOktaAuth();
  const { handleSubmit, getFieldProps, errors, touched } = useFormik({
    validationSchema,
    initialValues: {
      Email: "",
    },
    onSubmit: async (values) => {
      await getRequestsByEmail({
        ...values,
        idToken: authState.idToken.idToken,
      });
    },
  });

  const handleKeyPress = async (target) => {
    if (target.charCode == 13) {
      await getRequestsByEmail({
        Email: target.target.value,
        idToken: authState.idToken.idToken,
      });
    }
  };

  return (
    <div>
      <div className="custom-card mb-4">
        <div className="view-edit-box-top-bar" style={{ marginBottom: 10 }}>
          <div className="view-edit-box-back-title-container">
            <div>
              <h5 className="view-edit-box-title">View CloudEye User access</h5>
            </div>
          </div>
        </div>

        <Row>
          <Col md={5}>
            <TextInput
              label="Email:"
              {...getFieldProps("Email")}
              isInvalid={errors.Email && touched.Email}
              invalidMessage={errors.Email}
              placeholder="Email"
              onKeyPress={handleKeyPress}
            />
          </Col>
          <Col md={2} style={{ alignSelf: "center" }}>
            <div>
              <button
                className="btn teal-filled-btn"
                onClick={() => handleSubmit()}
              >
                Get Access
              </button>
            </div>
          </Col>
        </Row>
      </div>

      {isLoading && <SpinnerLoader />}
      {!!data.length && (
        <div className="custom-card mb-4">
          <Table
            data={data}
            columns={columns}
            text="Application List"
            idField="Application"
          />
        </div>
      )}
    </div>
  );
}

const columns = [
  {
    dataField: "Application",
    text: "Application",
    sort: true,
    searchable: true,
  },
  {
    dataField: "Role",
    text: "Role",
    sort: true,
    searchable: true,
  },
];
