import React from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

// Import Icons
import backicon from 'icons/back.svg';

const ViewBox = ({ data, closeViewParent }) => {
  return (
    <div className='view-edit-box-conatiner'>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => closeViewParent(false)}
          />
          <div>
            <h5 className='view-edit-box-title'>Custom Role</h5>
            {(data.requeststatus === 'Approved' ||
              data.requeststatus === 'Cancelled' ||
              data.requeststatus === 'Declined') && (
              <Row>
                <Col md={12}>
                  <div className='d-flex justify-content-center'>
                    <span className='account-request-view-request-message'>
                      {data.RequestActionTakenBy &&
                      <>
                       <span> {`Request ${data.requeststatus} by`}</span><br></br>
                      {data.RequestActionTakenBy}
                      </>
                      }
                      
                    </span>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
      <div className='table-view-box-content mt-4 mb-5'>
        <Row>
          <Col md={12} className='mt-3'>
            <div className='account-request-view-table-container'>
              <table width='100%'>
                <tbody className='account-request-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Request ID
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-uppercase'
                    >
                      {data.uid}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Requestor
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-uppercase'
                    >
                      {data.submittedBy}
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Name
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.userName}
                    </td>
                  </tr> */}
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      GID
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.Gid}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Account Name
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.accountname}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Account Number
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.accountnumber}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Purpose
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.tag}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Role Name
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.roleName}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Status
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.requeststatus}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Created On
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {moment(parseInt(data.uid.split('-')[1])).format(
                        'MMM DD, YYYY hh:mm A'
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ViewBox;
