import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";

// Import Internal Components
import { useSelector } from "react-redux";
import SpinnerLoader from "components/Common/SpinnerLoader";
import TrendMicro from "./TrendMicro";
import PrismaBranch2 from "./PrismaBranch2";
import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";

// new dashboard
// import ColorCardsComponent from "components/Graph/ColorCardsComponent";
// import DoughnutComponent from "components/Graph/DoughnutComponent";
//images
import awscolor from "icons/sub-menu-icons/awscolor.svg";
import dynamoDBIcon from "icons/aws-dynamodb-icon.svg";
// import tmConfirmity from "images/tm-confirm.png";
// import tmSecurity from "images/tm-security.png";

ChartJS.register(...registerables);

const SecurityBranch1 = ({ history }) => {
  // ! Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [loading, setLoading] = useState(true);

  const [
    cloudAccountDropdownOptions,
    setCloudAccountDropdownOptions,
  ] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState();

  const apiUrl = `${process.env.REACT_APP_SECURITY_ACCOUNT_LIST}`;
  const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

  useEffect(() => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: setIdToken,
      },
    };
    const requestBody = {
      cloud: "ALL",
      Email: userInfo.email,
      type_of_user: userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)
        ? 1
        : 0,
    };
    axios.post(apiUrl, requestBody, config).then((response) => {
      if (response && response.data) {
        const accountOptions = response.data.map((r) => ({
          label: `${r.accountname} - ${r.accountnumber}`,
          value: r.accountnumber,
        }));
        setCloudAccountDropdownOptions(accountOptions);
        setSelectedAccount(accountOptions[0].value);
      }
      setLoading(false);
    });
  }, []);

  const labels = ["January", "february", "march", "april", "may", "june"];
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Conformity report",
        data: [65, 25, 60, 32, 52, 27, 40],
        fill: false,
        borderColor: "#44dcc1",
        tension: 0.6,
      },
      {
        label: "Inspector report",
        data: [30, 59, 30, 64, 26, 54, 20],
        fill: false,
        borderColor: "#4099f6",
        tension: 0.6,
      },
    ],
  };

  const memoryData = {
    labels: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
    datasets: [
      {
        label: "",
        data: [30, 50, 40, 60, 50, 70, 65],
        fill: true,
        borderColor: "#ff748d",
        tension: 0.6,
        pointStyle: "dot",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 300, 0);
          gradient.addColorStop(0, "#ff183f");

          gradient.addColorStop(1, "#ffc8d2");
          ctx.fillStyle = gradient;
          return gradient;
        },
      },
    ],
  };
  const cardComponentData = [
    {
      mainTitle: "Orders Received",
      iconName: awscolor,
      totalCount: "486",
      subHeading: "Completed Order",
      subCount: "38",
      color: "rgb(63,152,246)",
      background:
        "linear-gradient(90deg, rgba(63,152,246,1) 0%, rgba(88,168,251,1) 41%, rgba(108,179,250,1) 100%)",
    },
    {
      mainTitle: "Total Sales",
      iconName: awscolor,
      totalCount: "1284",
      subHeading: "This Month",
      subCount: "14",
      color: "rgb(45,213,179)",
      background:
        "linear-gradient(90deg, rgba(45,213,179,1) 0%, rgba(68,220,193,1) 41%, rgba(68,220,193,1) 100%)",
    },
    {
      mainTitle: "Revenue",
      iconName: awscolor,
      totalCount: "755",
      subHeading: "This Month",
      subCount: "13",
      color: "#fd9175",
      color: "rgb(249,187,92)",
      background:
        "linear-gradient(90deg, rgba(249,187,92,1) 0%, rgba(249,193,104,1) 41%, rgba(248,201,123,1) 100%)",
    },
    {
      mainTitle: "Total Profit",
      iconName: awscolor,
      totalCount: "300",
      subHeading: "This Month",
      subCount: "18",
      color: "#a352db",
      color: "rgb(247,93,120)",
      background:
        "linear-gradient(90deg, rgba(247,93,120,1) 0%, rgba(248,113,136,1) 41%, rgba(247,130,150,1) 100%)",
    },
  ];
  const firstDoughnutdata = {
    datasets: [
      {
        label: "",
        data: [300, 50],
        backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)"],
        hoverOffset: 4,
      },
    ],
  };
  const memoryGraphOptions = {
    plugins: {
      legend: false, // Hide legend
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };
  const options = {
    responsive: false,
    maintainAspectRatio: false,
    cutout: "75%",
  };
  const secondDoughnutdata = {
    datasets: [
      {
        label: "",
        data: [300, 250],
        backgroundColor: ["white", "#76ffe7"],
        hoverOffset: 4,
        borderColor: "#68b1fa",
      },
    ],
  };

  return (
    <>
      <Container fluid className="px-4">
        {loading && (
          <Row>
            <Col md={12} className="d-flex justify-content-center mt-5">
              <SpinnerLoader />
            </Col>
          </Row>
        )}

        {loading === false &&
          (cloudAccountDropdownOptions &&
          cloudAccountDropdownOptions.length > 0 ? (
            <>
              <Row className="align-items-baseline mt-5">
                <Col className="mt-3 d-flex flex-wrap">
                  <div className="maintitle-text">Select Cloud Account</div>
                  <div className="mx-3">
                    <select
                      name="cloudaccount"
                      id="cloudaccount"
                      className="date-range-select"
                      value={selectedAccount}
                      onChange={(e) => {
                        setSelectedAccount(e.target.value);
                      }}
                    >
                      {cloudAccountDropdownOptions.map((o) => (
                        <option value={o.value}>{o.label}</option>
                      ))}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                {/* {cardComponentData.map((item) => (
                  <ColorCardsComponent
                    mainTitle={item?.mainTitle}
                    iconName={item?.iconName}
                    totalCount={item?.totalCount}
                    subHeading={item?.subHeading}
                    subCount={item?.subCount}
                    color={item?.color}
                    background={item?.background}
                  />
                ))} */}
              </Row>
              <Row className="mt-4 mx-015 second-graph">
                <div className="doughnut-g-line bg-white-security rounded">
                  <h6 className="fw-bold mb-4 mt-3">Monthly Report</h6>
                  <Line data={data} />
                </div>
                {/* <div className=""> */}
                {/* <DoughnutComponent
                  title="Trend Micro"
                  titleCount={3325}
                  data={firstDoughnutdata}
                  options={options}
                  dgraphCount={1}
                  inColor={firstDoughnutdata?.datasets[0]?.backgroundColor[0]}
                  outColor={firstDoughnutdata?.datasets[0]?.backgroundColor[1]}
                  firstValue={674}
                  secondValue={23}
                />
                <DoughnutComponent
                  title="TM Sentry"
                  titleCount={826}
                  data={secondDoughnutdata}
                  options={options}
                  dgraphCount={2}
                  inColor={secondDoughnutdata?.datasets[0]?.backgroundColor[0]}
                  outColor={secondDoughnutdata?.datasets[0]?.backgroundColor[1]}
                  firstValue={823}
                  secondValue={155}
                /> */}
              </Row>
              <Row className="mt-4 mx-015 mb-4">
                <div className="memory-report me-4">
                  <div className="ps-5">
                    <img
                      src={dynamoDBIcon}
                      alt="memory"
                      className="memory-icon mt-3 mb-0"
                    />
                    <h6 className="fw-bold mb-0">
                      {
                        memoryData?.datasets[0]?.data[
                          memoryData?.datasets[0]?.data?.length - 1
                        ]
                      }
                      %
                    </h6>
                    <h6 className="fw-bold mb-4">Memory</h6>
                  </div>
                  <Line data={memoryData} options={memoryGraphOptions} />
                </div>
                <div className="memory-report me-4 tmConfirmity p-3 text-center">
                  {/* <img src={tmConfirmity} alt="memory" className=" mt-3 mb-0" /> */}
                  <h6 className="fw-bold mb-3 mt-3">Trend Micro Confirmity</h6>
                  <p className="fw-500 fs-13">
                    Data from 2023-11-21 to 2023-11-28
                  </p>
                  <button className="manage-list teal-bordered-btn">
                    Manage List
                  </button>
                </div>
                <div className="memory-report tmSecurity p-3 text-center">
                  {/* <img src={tmSecurity} alt="memory" className=" mt-3 mb-0" /> */}
                  <h6 className="fw-bold mb-3 mt-3">Trend Micro Security</h6>
                  <p className="fw-500 fs-13">
                    Data from 2023-11-21 to 2023-11-28
                  </p>
                  <Button className="check-them-out">Check Them Out</Button>
                </div>
              </Row>
              {/* <Row className="mt-4">
                <Col md={6} className="px-2">
                  <div className="custom-card">
                    <p className="subtitle-text px-2">
                      TrendMicro Workload Security
                    </p>
                    <TrendMicro accountnumber={selectedAccount} />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="custom-card">
                    <PrismaBranch2 accountnumber={selectedAccount} />
                  </div>
                </Col>
              </Row> */}
            </>
          ) : (
            <h5>
              You are not identified as either account owner or Technical
              Contact for any Cloud Account. Contact Sony Global Cloud Support
              for additional details.
            </h5>
          ))}
      </Container>
    </>
  );
};

export default SecurityBranch1;
