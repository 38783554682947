import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';

// Helper
import { postApiCall } from 'helper/postApiCall';

// Import Icons
import backicon from 'icons/back.svg';

import Pagination from 'components/Pagination/Pagination';
import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

const ViewUsers = ({
  title,
  closeContainer,
  role,
  accountNumber,
  accountName,
}) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // Component State
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [error, setError] = useState(null);

  // API Variable
  const userDetailsApi = `${process.env.REACT_APP_OKTA_VIEW_ASSIGNMENT_USERS}`;
  const idToken = `${userInfo.idToken}`;
  let userRequestBody = {
    // role: `${role}`,
    accountnumber: `${accountNumber}`,
    accountname: `${accountName}`,
  };

  // Pagination Part
  let PageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch Data
  const fetchData = useCallback(() => {
    postApiCall(userDetailsApi, idToken, userRequestBody)
      .then((response) => {
        let tempArray = [];
        for (var a in response.data) {
          if (a === role) {
            var b = response.data[a];
            for (let i = 0; i < b.length; i++) {
              let tempObj = {};
              tempObj['status'] = b[i]['status'];
              tempObj['name'] = b[i]['name'];
              tempObj['gid'] = b[i]['gid'];
              tempObj['email'] = b[i]['email'];
              tempArray.push(tempObj);
            }
          }
        }
        setResponseData(tempArray);
        setLoading(false);
      })
      .catch((err) => {
        setError(
          'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
        );
        setLoading(false);
      });
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return responseData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, responseData, PageSize]);

  useEffect(() => {
    fetchData();

    return () => {
      setResponseData([]);
    };
  }, [fetchData]);
  return (
    <div className='view-edit-box-conatiner pb-5' style={{ width: '650px' }}>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => closeContainer('')}
          />
          <h5 className='view-edit-box-title'>{title}</h5>
        </div>
      </div>
      {loading && <SpinnerLoader />}
      {loading === false && error !== null && <DangerAlert message={error} />}
      {loading === false && (
        <div>
          <Table
            responsive
            className='mt-3 mb-0 table-centered table-nowrap table-borderless'
          >
            <thead className='offcanvas-details-table-head-font'>
              <tr className='mb-2'>
                <td>GID</td>
                <td>NAME</td>
                <td>EMAIL</td>
                <td>STATUS</td>
              </tr>
            </thead>
            <tbody className='offcanvas-details-table-body-font'>
              {currentTableData.map((data, index) => (
                <tr key={index} className='mb-3'>
                  <td>{data.gid}</td>
                  <td>{data.name}</td>
                  <td>{data.email}</td>
                  <td>
                    {data.status === 'ACTIVE' ? (
                      <span className='approved-badge'>{data.status}</span>
                    ) : (
                      <span className='declined-badge'>{data.status}</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className='d-flex justify-content-end align-items-baseline'>
            <Pagination
              className='pagination-bar'
              currentPage={currentPage}
              totalCount={responseData.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
              currentTableDataLength={currentTableData.length}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewUsers;
