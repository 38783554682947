export const networkHubValiadtor = ({
  sourceAccountNumber,
  sourceCIDR,
  sourceRegion,
  sourceTransitGateway,
  destinationAccountNumber,
  destinationCIDR,
  destinationRegion,
  destinationTransitGateway,
}) => {
  let errors = {};

  if (!sourceAccountNumber) {
    errors.sourceAccountNumber = 'Account number is required';
  }

  let isSourceCIDR = /\d*.\.*.\//i.test(sourceCIDR);
  if (!sourceCIDR) {
    errors.sourceCIDR = 'CIDR is required';
  } else if (isSourceCIDR === false) {
    errors.sourceCIDR = 'Only digits, dots, / is allowed';
  }

  if (!sourceRegion) {
    errors.sourceRegion = 'Region is required';
  }

  let isSourceTGAnum = /^\d+$/.test(sourceTransitGateway);
  if (!sourceTransitGateway) {
    errors.sourceTransitGateway = 'Transit Gateway ID is required';
  } else if (isSourceTGAnum === false) {
    errors.sourceTransitGateway = 'Only digits is allowed';
  }

  if (!destinationAccountNumber) {
    errors.destinationAccountNumber = 'Account number is required';
  }

  let isDestinationCIDR = /\d*.\.*.\//i.test(sourceCIDR);
  if (!destinationCIDR) {
    errors.destinationCIDR = 'CIDR is required';
  } else if (isDestinationCIDR === false) {
    errors.destinationCIDR = 'Only digits, dots, / is allowed';
  }

  if (!destinationRegion) {
    errors.destinationRegion = 'Region is required';
  }

  let isDestinationTGAnum = /^\d+$/.test(destinationTransitGateway);
  if (!destinationTransitGateway) {
    errors.destinationTransitGateway = 'Transit Gateway ID is required';
  } else if (isDestinationTGAnum === false) {
    errors.destinationTransitGateway = 'Only digits is allowed';
  }

  return errors;
};
