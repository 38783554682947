import {
    ENVIRONMENT_VARIABLE
  } from './vpcConstant';
  
  export const vpcReducer = (
    state = {
      environmentValue: {}
    },
    action
  ) => {
    switch (action.type) {
      case ENVIRONMENT_VARIABLE:    
        return { ...state, environmentValue: action.payload };
    
      default:
        return state;
    }
  };
  