export const billingInfoValidate = (values) => {
    let errors = {};
    if (!values.billingopco) {
      errors.billingopco = 'Billing operating company is required';
    }
    if (!values.billingSubOpco) {
      errors.billingSubOpco = 'Billing sub operating company is required';
    }
  
    if (!values.billingDepartment) {
      errors.billingDepartment = 'Billing department is required';
    }
  
    // if (!values.billingRegion) {
    //   errors.billingRegion = 'Billing region is required';
    // }
  
    // if (!values.maxSpend) {
    //   errors.maxSpend = 'Max spend is required';
    // }
    // if (!values.billto) {
    //   errors.billto = 'Bill to is required';
    // }
  
    // if (values.billto === 'Global IS (GISC)') {
    //   if (!values.io) {
    //     errors.io = 'IO is required';
    //   }
    // } else {
    //   if (!values.costcenter) {
    //     errors.costcenter = 'Cost center is required';
    //   }
    // }
  
    return errors;
  };

  export const accountDetailsValidate = (values) => {
    let errors = {};
    if (!values.opco) {
      errors.opco = 'Operating company is required';
    }
    if (!values.subopco) {
      errors.subopco = 'Sub operating company is required';
    }
  
    if (!values.department) {
      errors.department = 'Department is required';
    }
  
    return errors;
  };
  export const billingContactValidate = (values, toggleOn) => {
    let errors = {};
    if(toggleOn){
    if (!values.firstNameBC) {
      errors.firstNameBC = 'First name is required';
    } else if (/^[a-zA-Z- ]*$/.test(values.firstNameBC) === false) {
      errors.firstNameBC = 'Only alphabets & hyphens allowed';
    }
  
    if (!values.lastNameBC) {
      errors.lastNameBC = 'Last name is required';
    } else if (/^[a-zA-Z- ]*$/.test(values.lastNameBC) === false) {
      errors.lastNameBC = 'Only alphabets & hyphens allowed';
    }
  
    let isEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
      values.emailBC
    );
    if (!values.emailBC) {
      errors.emailBC = 'Email address is required';
    } else if (isEmail === false) {
      errors.emailBC = 'Email address is invalid';
    }
  
    if (!values.countryBC) {
      errors.countryBC = 'Country is required';
    }
  
    let isnum = /^(?=.*[0-9])[- +()0-9]+$/.test(values.phoneNoBC);
  
    if (!values.phoneNoBC) {
      errors.phoneNoBC = 'Phone number is required';
    } else if (isnum === false) {
      errors.phoneNoBC = 'Only digits, space, hyphens and plus is allowed';
    }
    
  }
  return errors;
  };

  