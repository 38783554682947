import React from 'react';
import { Row, Col } from 'react-bootstrap';

const CardDetail = ({ data }) => {
  return (
    <Row>
      <Col md={12} className='card-detail-wrapper'>
        {data['BackendConnectionErrors'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Backend Connection Errors</div>
                <div className='card-values'>
                  {Object.keys(data['BackendConnectionErrors']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['BackendConnectionErrors']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['HealthyHostCount'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Healthy Host Count</div>
                <div className='card-values'>
                  {Object.keys(data['HealthyHostCount']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['HealthyHostCount']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['Latency'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Latency</div>
                <div className='card-values'>
                  {Object.keys(data['Latency']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(Object.values(data['Latency']).pop()).toFixed(2)}B
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['UnHealthyHostCount'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>UnHealthy Host Count</div>
                <div className='card-values'>
                  {Object.keys(data['UnHealthyHostCount']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['UnHealthyHostCount']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['HTTPCode_Backend_5XX'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>HTTPCode Backend 5XX</div>
                <div className='card-values'>
                  {Object.keys(data['HTTPCode_Backend_5XX']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['HTTPCode_Backend_5XX']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['HTTPCode_Backend_2XX'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>HTTPCode Backend 2XX</div>
                <div className='card-values'>
                  {Object.keys(data['HTTPCode_Backend_2XX']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['HTTPCode_Backend_2XX']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['HTTPCode_ELB_5XX'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>HTTPCode ELB 5XX</div>
                <div className='card-values'>
                  {Object.keys(data['HTTPCode_ELB_5XX']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['HTTPCode_ELB_5XX']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['RequestCount'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Request Count</div>
                <div className='card-values'>
                  {Object.keys(data['RequestCount']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(Object.values(data['RequestCount']).pop()).toFixed(
                    2
                  )}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}

        {data['ActiveFlowCount'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Active Flow Count</div>
                <div className='card-values'>
                  {Object.keys(data['ActiveFlowCount']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['ActiveFlowCount']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['ActiveConnectionCount'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Active Connection Count</div>
                <div className='card-values'>
                  {Object.keys(data['ActiveConnectionCount']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['ActiveConnectionCount']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['NewConnectionCount'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>New Connection Count</div>
                <div className='card-values'>
                  {Object.keys(data['NewConnectionCount']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['NewConnectionCount']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['RejectedConnectionCount'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Rejected Connection Count</div>
                <div className='card-values'>
                  {Object.keys(data['RejectedConnectionCount']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['RejectedConnectionCount']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['HTTPCode_ELB_5XX_Count'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>HTTPCode ELB 5XX Count</div>
                <div className='card-values'>
                  {Object.keys(data['HTTPCode_ELB_5XX_Count']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['HTTPCode_ELB_5XX_Count']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['TargetResponseTime'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Target Response Time</div>
                <div className='card-values'>
                  {Object.keys(data['TargetResponseTime']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['TargetResponseTime']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['HTTP_Redirect_Count'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>HTTP Redirect Count</div>
                <div className='card-values'>
                  {Object.keys(data['HTTP_Redirect_Count']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['HTTP_Redirect_Count']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['NewFlowCount'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>New Flow Count</div>
                <div className='card-values'>
                  {Object.keys(data['NewFlowCount']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(Object.values(data['NewFlowCount']).pop()).toFixed(
                    2
                  )}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['ProcessedBytes'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Processed Bytes</div>
                <div className='card-values'>
                  {Object.keys(data['ProcessedBytes']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['ProcessedBytes']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['TCP_Client_Reset_Count'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>TCP Client Reset Count</div>
                <div className='card-values'>
                  {Object.keys(data['TCP_Client_Reset_Count']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['TCP_Client_Reset_Count']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['TCP_ELB_Reset_Count'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>TCP ELB Reset Count</div>
                <div className='card-values'>
                  {Object.keys(data['TCP_ELB_Reset_Count']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['TCP_ELB_Reset_Count']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
        {data['NewFlowCount_TCP'] && (
          <div className='mb-2'>
            <div>
              <div className='card-title'>New Flow Count TCP</div>
              <div className='card-values'>
                {Object.keys(data['NewFlowCount_TCP']).pop()}
              </div>
              <div className='card-values'>
                {parseInt(
                  Object.values(data['NewFlowCount_TCP']).pop()
                ).toFixed(2)}
              </div>
            </div>
          </div>
        )}
      </Col>
      <Col md={12} className='d-flex justify-content-end mt-3'>
        <div className='regular-text utc-note'>*All timings are in UTC</div>
      </Col>
    </Row>
  );
};

export default CardDetail;
