import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import  {useTranslation} from 'react-i18next';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

// Helper
import { postApiCall } from 'helper/postApiCall';

// Roles
import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

import pdficon from 'icons/pdf-icon.svg';

const ViewDocuments = () => {
  const {t} = useTranslation();
  const isMounted = useRef(false);

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // API State
  const [isAdmin, setIsAdmin] = useState(false);
  const [apiLoading, setApiLoading] = useState(true);
  const [apiResponseData, setApiResponseData] = useState([]);
  const [apiError, setApiError] = useState(null);

  // Search State
  const [filterQuery, setFilterQuery] = useState('');
  const [searchParam] = useState(['subject', 'categoryname', 'description']);
  const [userDocumentData, setUserDocumentData] = useState([]);
  const [adminDocumentData, setAdminDocumentData] = useState([]);

  // API Variable
  const idToken = `${userInfo.idToken}`;
  const apiUrl = `${process.env.REACT_APP_VIEW_DOCUMENTS}`;

  function compare(a, b) {
    if (a.categoryname < b.categoryname) {
      return -1;
    }
    if (a.categoryname > b.categoryname) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    if (isMounted.current) {
      if (filterQuery === '') {
        let tagGroup = apiResponseData.reduce((r, a) => {
          r[a.tag] = [...(r[a.tag] || []), a];
          return r;
        }, {});

        let sortedUserTag = tagGroup.user.sort(compare);

        let userCategoryGroup = sortedUserTag.reduce((r, a) => {
          r[a.categoryname] = [...(r[a.categoryname] || []), a];
          return r;
        }, {});

        setUserDocumentData(userCategoryGroup);

        if (isAdmin === true) {
          let sortedAdminTag = tagGroup.admin.sort(compare);

          let adminCategoryGroup = sortedAdminTag.reduce((r, a) => {
            r[a.categoryname] = [...(r[a.categoryname] || []), a];
            return r;
          }, {});
          
          setAdminDocumentData(adminCategoryGroup);
        }
      } // Empty Filter
      else {
        // User
        let userDocumentResult = [];
        userDocumentResult = apiResponseData.filter((data) => {
          return searchParam.some((newItem) => {
            return (
              data[newItem]
                .toString()
                .toLowerCase()
                .indexOf(filterQuery.toLowerCase()) > -1
            );
          });
        });

        if (userDocumentResult.length > 0) {
          let userTagGroup = userDocumentResult.reduce((r, a) => {
            r[a.tag] = [...(r[a.tag] || []), a];
            return r;
          }, {});

          if (userTagGroup['user']) {
            let sortedUserTag = userTagGroup.user.sort(compare);

            let userCategoryGroup = sortedUserTag.reduce((r, a) => {
              r[a.categoryname] = [...(r[a.categoryname] || []), a];
              return r;
            }, {});

            setUserDocumentData(userCategoryGroup);
          } else {
            setUserDocumentData([]);
          }
        } else {
          setUserDocumentData([]);
        }

        // Admin
        let adminDocumentResult = [];
        adminDocumentResult = apiResponseData.filter((data) => {
          return searchParam.some((newItem) => {
            return (
              data[newItem]
                .toString()
                .toLowerCase()
                .indexOf(filterQuery.toLowerCase()) > -1
            );
          });
        });

        if (adminDocumentResult.length > 0) {
          let adminTagGroup = adminDocumentResult.reduce((r, a) => {
            r[a.tag] = [...(r[a.tag] || []), a];
            return r;
          }, {});

          if (adminTagGroup['admin']) {
            let sortedAdminTag = adminTagGroup.admin.sort(compare);

            let adminCategoryGroup = sortedAdminTag.reduce((r, a) => {
              r[a.categoryname] = [...(r[a.categoryname] || []), a];
              return r;
            }, {});
           
            setAdminDocumentData(adminCategoryGroup);
          } else {
            setAdminDocumentData([]);
          }
        } else {
          setAdminDocumentData([]);
        }
      } // Filter has value
    } // isMounted
    // return () => {}
  }, [filterQuery]);

  useEffect(() => {
    let requestBody;
    let isAdminVar = false;

    if (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)) {
      requestBody = { tag: `admin` };
      isAdminVar = true;
      setIsAdmin(true);
    } else {
      requestBody = { tag: `user` };
      isAdminVar = false;
      setIsAdmin(false);
    }

    const fetchDocuments = async () => {
      setApiLoading(true);
      postApiCall(apiUrl, idToken, requestBody)
        .then((response) => {
          setApiResponseData(response.data);
          let tagGroup = response.data.reduce((r, a) => {
            r[a.tag] = [...(r[a.tag] || []), a];
            return r;
          }, {});

          let sortedUserTag = tagGroup.user.sort(compare);

          let userCategoryGroup = sortedUserTag.reduce((r, a) => {
            r[a.categoryname] = [...(r[a.categoryname] || []), a];
            return r;
          }, {});

          setUserDocumentData(userCategoryGroup);

          if (isAdminVar === true) {
            let sortedAdminTag = tagGroup.admin.sort(compare);

            let adminCategoryGroup = sortedAdminTag.reduce((r, a) => {
              r[a.categoryname] = [...(r[a.categoryname] || []), a];
              return r;
            }, {});
            setAdminDocumentData(adminCategoryGroup);
          }
          isMounted.current = true;
          setApiLoading(false);
        })
        .catch((err) => {
          setApiError(
            t("failed_to_retrieve_data")
          );
          setApiLoading(false);
        });
    };

    fetchDocuments();
  }, []);

  return (
    <Container fluid>
      {apiLoading && (
        <Row>
          <Col md={12} className='d-flex justify-content-center mt-5'>
            <SpinnerLoader />
          </Col>
        </Row>
      )}

      {apiLoading === false && apiError !== null && (
        <Row>
          <Col md={12}>
            <DangerAlert message={apiError} />
          </Col>
        </Row>
      )}

      {apiLoading === false && apiError === null && (
        <>
          <Row className='d-flex align-items-center'>
            <Col md={7} className='mt-3'>
              <span className='maintitle-text'>{t("documents")}</span>
            </Col>

            {userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ===
              false && <Col md={2} className='mt-3'></Col>}

            <Col md={3} className='mt-3'>
              <div className='search-input-group'>
                <i className='fa fa-search'></i>
                <input
                  type='text'
                  className='custom-search-input-form-control'
                  placeholder='Search'
                  value={filterQuery}
                  onChange={(e) => setFilterQuery(e.target.value)}
                />
              </div>
            </Col>

            {userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) && (
              <Col md={2} className='mt-3 '>
                <Link to='/add-document'>
                  <button className='teal-filled-btn w-100'>
                    <i
                      className='fa fa-plus'
                      style={{ marginRight: '6px' }}
                    ></i>
                    {t("add_document")}
                  </button>
                </Link>
              </Col>
            )}
          </Row>

          <Row>
            <Col>
              {isAdmin && Object.keys(adminDocumentData).length > 0 && (
                <Row>
                  <Col md={12} className='mt-4'>
                    <span className='maintitle-text'>{t("admin")}</span>
                  </Col>
                  {Object.keys(adminDocumentData).map((data, index) => (
                    <Col md={4} className='mt-3 mb-3' key={index}>
                      <p className='subtitle-text mb-4'>{data}</p>

                      {adminDocumentData[data].map((documentdata, index) => (
                        <div className='documents-container' key={index}>
                          <div className='document-logo'>
                            <img src={pdficon} alt='PDF' />
                          </div>
                          <div className='document-title'>
                            <a
                              href={documentdata.Url}
                              className='documents-description'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {documentdata.subject}
                            </a>
                            <span className='documents-a-link'>
                              {documentdata.description}
                            </span>
                          </div>
                        </div>
                      ))}
                    </Col>
                  ))}
                </Row>
              )}

              {isAdmin && Object.keys(adminDocumentData).length === 0 && (
                <Row>
                  <Col md={12} className='mt-4'>
                    <span className='maintitle-text'>{t("admin")}</span>
                  </Col>
                  <Col md={12}>{t("no_records_matching")}</Col>
                </Row>
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              {Object.keys(userDocumentData).length > 0 ? (
                <Row>
                  {isAdmin && (
                    <Col md={12} className='mt-4'>
                      <span className='maintitle-text'>{t("user")}</span>
                    </Col>
                  )}
                  {Object.keys(userDocumentData).map((data, index) => (
                    <Col md={4} className='mt-4 mb-3' key={index}>
                      <p className='subtitle-text mb-4'>{data}</p>

                      {userDocumentData[data].map((documentdata, index) => (
                        <div className='documents-container' key={index}>
                          <div className='document-logo'>
                            <img src={pdficon} alt='PDF' />
                          </div>
                          <div className='document-title'>
                            <a
                              href={documentdata.Url}
                              className='documents-description'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {documentdata.subject}
                            </a>
                            <span className='documents-a-link'>
                              {documentdata.description}
                            </span>
                          </div>
                        </div>
                      ))}
                    </Col>
                  ))}
                </Row>
              ) : (
                <Row>
                  <Col md={12} className='mt-4'>
                    <span className='maintitle-text'>{t("user")}</span>
                  </Col>
                  <Col md={12}>{t("no_records_matching")}</Col>
                </Row>
              )}
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ViewDocuments;
