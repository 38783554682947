import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { CLOUD_EYE_MANAGEMENT_VIEW_DETAIL } from 'redux/cloudeye-management/cloudEyeManagementConstant';

import BillingView from './BillingView';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';
import { useTranslation } from 'react-i18next';

const BillingWidget = ({ loading, data, errorResponse }) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const cloudEyeManagement = useSelector((state) => state.cloudEyeManagement);
  const { cloudEyeManagementView } = cloudEyeManagement;

  const [dateValue, setDateValue] = useState('1');

  return (
    <>
      <Row>
        <Col md={12}>
          <div className='custom-card mb-3' style={{ height: '220px' }}>
            <Row>
              <Col md={8} xs={6} className='mb-4'>
                <div className='billing-title'>Billing</div>
              </Col>
              {loading && (
                <Col
                  md={12}
                  className='d-flex justify-content-center align-items-center mt-3'
                >
                  <SpinnerLoader />
                </Col>
              )}
              {loading === false && errorResponse === null && (
                <Col md={4} xs={6}>
                  <Form.Select
                    size='sm'
                    value={dateValue}
                    onChange={(e) => setDateValue(e.target.value)}
                  >
                    <option value='1'>Current Month</option>
                    <option value='2'>Last Month</option>
                  </Form.Select>
                </Col>
              )}
            </Row>
            {errorResponse !== null && (
              <Row>
                <Col className='d-flex justify-content-center align-items-center mt-3'>
                  <DangerAlert message={errorResponse} />
                </Col>
              </Row>
            )}
            {loading === false && errorResponse === null && (
              <Row>
                <div className='d-flex flex-column'>
                  <div className='billing-total-value'>
                    <span>
                      {dateValue === '1'
                        ? new Intl.NumberFormat('en-IN', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(data.currentmonth)
                        : new Intl.NumberFormat('en-IN', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(data.lastmonth)}
                    </span>
                  </div>
                  <div className='d-flex flex-row flex-wrap justify-content-between'>
                    <div className='billing-total-text'>Total Cost</div>
                    <div
                      className='billing-view-account'
                      onClick={() => {
                        dispatch({
                          type: CLOUD_EYE_MANAGEMENT_VIEW_DETAIL,
                          payload: 'View Accounts',
                        });
                      }}
                    >
                      View Account
                    </div>
                  </div>
                  <div className='mt-3'>
                    <a
                      href='https://app.apptio.com/cloudability'
                      className='billing-more-details'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {t("clickHereToViewMore")}                   
                    </a>
                  </div>
                </div>
              </Row>
            )}
          </div>
        </Col>
      </Row>
      {cloudEyeManagementView === 'View Accounts' && (
        <BillingView data={data} />
      )}
    </>
  );
};

export default BillingWidget;
