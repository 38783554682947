import Loader from "components/Common/Loader";
import { postApiCall } from "helper/postApiCall";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import {
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RequestSubmitConfirmation from "./AWS/AWSCredits/RequestSubmitConfirmation";
import CustomModal from "components/Modal/CustomModal";
import MonthPicker from "components/MonthPicker/MonthPickerInput.js";
import { ExportJsonCsv } from "react-export-json-csv";
import { useRef } from "react";
import { getAwsSdkSts, handleDownload } from "api/utilities/awsSts";
import FileUpload from "./AWS/FileUpload/FileUpload";
import BillingHomeCard from "./BillingHomeCard";
import {
  cardChargebackData,
  cardDataInvoice,
  getISTTime,
} from "static-contents/billing-data";
import InvoiceGeneraionModal from "./InvoiceGeneraionModal";
import VersionHistoryModal from "./VersionHistoryModal";
import InvoiceDownloadModal from "./InvoiceDownloadModal";
import { BILLING_SCREEN_DATE } from "redux/billing/EditableTableConstant";

const dateObject = new Date();
const monthList = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export default function AdditionalFeaturesAdmin({ selAccountLists, cloud }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_BILLING_HOME_DETAILS;
  const csvLink = useRef();
  //REDUX
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const idToken = userInfo.idToken;

  const [step, setStep] = useState(0);
  const [isMonthChecked, setMonthChecked] = useState(0);
  const [isYearChecked, setYearChecked] = useState(1);
  const [isDropDownOpen, setDropDownOpen] = useState(false);
  const [isYearDropDown, setYearDropDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoiceMessage, setInvoiceMessage] = useState(null);
  const [showInvoiceConfirmation, setShowInvoiceConfirmation] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");

  const [updateWindowStep, setUpdateWindowStep] = useState(0);
  const [isUpdateMonthChecked, setUpdateMonthChecked] = useState(0);
  const [isUpdateYearChecked, setUpdateYearChecked] = useState(1);
  const [isUpdateDropDownOpen, setUpdateDropDownOpen] = useState(false);
  const [isUpdateYearDropDown, setUpdateYearDropDown] = useState(false);

  //csv file state
  const [csvFileData, setCsvFileData] = useState([]);
  const [csvHeaderData, setCsvHeaderData] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");

  // download error
  const [showDownloadError, setShowDownloadError] = useState(false);
  const [downloadSuccessMsg, setDownloadSuccessMsg] = useState(
    "Download is in progress..."
  );
  const [showInvoiceGeneration, setShowInvoiceGeneration] = useState(false);

  const [showDownloadSuccess, setShowDownloadSuccess] = useState(false);
  const [downloadErrorMsg, setDownloadErrorMsg] = useState(
    "Error downloading the file contact Sony_Cloud_Support_Global@sony.com for further assistance"
  );
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [auditData, setAuditData] = useState(cardChargebackData);
  const [invoiceAuditData, setInvoiceAuditData] = useState(cardDataInvoice);
  const [versionData, setVersionData] = useState([]);
  const [showVersionHistory, setShowVersionHistory] = useState(false);
  const [showDownloadModalOpen, setDownloadModalOpen] = useState(false);

  const selAccounts = useMemo(() => {
    return selAccountLists?.map((acc) => acc.DropdownValue);
  }, [selAccountLists]);
  const generateInvoiceApi = `${process.env.REACT_APP_CHARGEBACK_GENERATE_INVOICE}`;
  const reportDownloadApi = `${process.env.REACT_APP_GIT_WORKSPACE_REPORT}`;
  const handleChange = (type, value) => {
    if (type === "M") {
      setMonthChecked(Number(value));
    } else if (type === "Y") {
      setYearChecked(Number(value));
    }
  };
  const handleUpdateChange = (type, value) => {
    if (type === "M") {
      setUpdateMonthChecked(Number(value));
    } else if (type === "Y") {
      setUpdateYearChecked(Number(value));
    }
  };
  const getSelectedMonth = (value) => {
    setSelectedMonth(value);
  };
  const handleDownloadReports = (reportType) => {
    const requestBody = {
      actionType: reportType === "gitlab" ? "gitlab" : "workspace",
    };
    setDownloadLoading(true);
    postApiCall(reportDownloadApi, idToken, requestBody)
      .then(async (response) => {
        const csvFileTitle = `${moment(dateObject).format("MMMM")} ${
          reportType === "gitlab" ? "GitLab" : "Workspace"
        } Report.xlsx`;

        const downloadStatus = await handleDownload(
          response.data,
          csvFileTitle
        );

        if (downloadStatus) {
          setDownloadLoading(false);
          setShowDownloadError(false);
          setShowDownloadSuccess(true);
        } else {
          setShowDownloadError(true);
          setDownloadLoading(false);
          setShowDownloadSuccess(false);
        }
      })
      .catch((err) => {
        console.log("error from trigger download", err);
      });
  };
  const handleGenerateInvoice = async (
    monthType = null,
    operationtype = null
  ) => {
    let csvFileTitle;
    if (operationtype) {
      setDownloadLoading(true);
      const yearCheckedVal =
        Number(isYearChecked) === 0
          ? dateObject?.getFullYear() - 1
          : dateObject?.getFullYear();

      const monthIndex = monthList.findIndex((item) => item === selectedMonth);

      const requestBody = {
        month: monthType
          ? moment(dateObject).format("MM")
          : moment(
              `${monthIndex + 1}/01/${
                monthType ? dateObject?.getFullYear() + "" : yearCheckedVal + ""
              }`
            ).format("MM"),
        year: monthType ? dateObject?.getFullYear() + "" : yearCheckedVal + "",
        requestor: userInfo?.email,
        actionType: "download",
      };
      postApiCall(generateInvoiceApi, idToken, requestBody)
        .then(async (response) => {
          setDropDownOpen(false);

          const validateMonth =
            Number(requestBody.month - 1) === 0 ? true : false;

          csvFileTitle = `${requestBody.year}-${requestBody.month}-Charges-${
            validateMonth
              ? moment(`${requestBody.month}/01/${requestBody.year}`)
                  .subtract(1, "months")
                  .format("YYYY")
              : requestBody.year
          }-${moment(new Date(`${requestBody.month}/01/${requestBody.year}`))
            .subtract(1, "months")
            .format("MM")}-Usage-v1.xlsx`;

          // getAwsSdkSts();
          // const downloadStatus = await handleDownload(idToken);

          // signed url
          const downloadStatus = await handleDownload(
            response.data,
            csvFileTitle
          );

          if (downloadStatus) {
            setDownloadLoading(false);
            setShowDownloadError(false);
            setShowDownloadSuccess(true);
          } else {
            setShowDownloadError(true);
            setDownloadLoading(false);
            setShowDownloadSuccess(false);
          }
        })
        .catch((err) => {
          console.log("error from trigger download", err);
        });

      // const csvHeaderList = Object.keys(invoiceData[0]).map((item) => {
      //   return {
      //     key: item,
      //     name: item,
      //   };
      // });
      // for setting the data for front end csv generation
      // setCsvFileData(invoiceData);
      // setCsvHeaderData(csvHeaderList);
      // setCsvFileName(csvFileTitle);
      // csvLink.current.click();
    } else {
      setLoading(true);
      const getYearString = moment(dateObject).format("YYYY");
      const getMonthValue = moment(dateObject).format("MM");
      const getMonthString = moment(dateObject).format("MMMM");
      const requestBody = {
        month: getMonthValue,
        // Monthname: getMonthString,
        year: getYearString,
        requestor: userInfo?.email,
        actionType: "generate",
        updatedby: userInfo?.email,
        updateddate: getISTTime().getDate,
        updatedtime: getISTTime().getTime,
      };
      setDropDownOpen(false);
      postApiCall(generateInvoiceApi, idToken, requestBody)
        .then((response) => {
          // const message = response?.data[0]?.["Message"]?.replace(
          //   new RegExp("{" + "month" + "}", "gi"),
          //   getMonthString
          // );
          setShowInvoiceGeneration(false);
          const message =
            "Invoice is getting genarated. Kindly download the file after 20min";
          setInvoiceMessage(message);
          setShowInvoiceConfirmation(true);

          setLoading(false);
        })
        .catch((err) => {
          console.log("something went wrong with api");
        });
    }
  };
  const fetchData = (category = null) => {
    const requestData = category ? { category: category } : {};
    if (category) {
      setVersionData([]);
      setShowVersionHistory(true);
    }
    postApiCall(apiUrl, idToken, requestData).then((response) => {
      const responseData = response?.data;
      if (!category) {
        const getScreenEnableDate = responseData?.find(
          (item) => item?.category === "ScreenEnablement"
        );
        dispatch({
          type: BILLING_SCREEN_DATE,
          payload: Number(getScreenEnableDate?.dayNo) + 1,
        });
        const data = responseData
          ?.filter(
            (item) =>
              item.category !== "Invoice" &&
              item.category !== "ScreenEnablement"
          )
          .map((item) => {
            const getMatch = cardChargebackData?.find(
              (data) => data.categoryName === item.category
            );
            return Object.assign({}, item, getMatch);
          });

        const invoiceData = cardDataInvoice.map((item) => {
          const getMatch = responseData?.find(
            (data) => data.category === item.categoryName
          );
          return Object.assign({}, getMatch, item);
        });
        setInvoiceAuditData(invoiceData);
        setAuditData(data);
      } else if (category) {
        setVersionData(response?.data?.versions);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="position-relative">
        {loading ? (
          <div className="position-absolute text-center spinner-billing">
            {" "}
            <Loader />{" "}
          </div>
        ) : null}

        {downloadLoading ? (
          <div className="billing-loader-bg position-absolute text-center spinner-billing">
            {" "}
            <Loader msg={"Download is in progress..."} />{" "}
          </div>
        ) : null}
        <Row
          style={
            downloadLoading || loading
              ? { opacity: "0.5", pointerEvents: "none" }
              : { opacity: "1" }
          }
        >
          <Col md={12}>
            <BillingHomeCard
              cardData={invoiceAuditData}
              setShowInvoiceGeneration={setShowInvoiceGeneration}
              setDownloadModalOpen={setDownloadModalOpen}
              versionHistory={`Download ${moment(dateObject).format("MMMM")}`}
              handleDownloadInvoice={handleGenerateInvoice}
              handleDownloadReports={handleDownloadReports}
            />
          </Col>

          <p className="billing-hr mb-0" />
          <Col md={12}>
            <BillingHomeCard cardData={auditData} fetchData={fetchData} />
          </Col>
          {/* <Col md={8} className="d-block margin-0-auto">
            {billingChart}
          </Col> */}
        </Row>

        {showInvoiceConfirmation && (
          <CustomModal
            showModal={true}
            children={
              <RequestSubmitConfirmation
                invoice={invoiceMessage}
                closeModalParent={() => {
                  setShowInvoiceConfirmation(false);
                }}
              />
            }
          />
        )}
        {showDownloadError && (
          <CustomModal
            showModal={true}
            children={
              <RequestSubmitConfirmation
                downloadError={downloadErrorMsg}
                closeModalParent={() => {
                  setShowDownloadError(false);
                }}
              />
            }
          />
        )}
        {/* {showDownloadSuccess && (
        <CustomModal
          showModal={true}
          children={
            <RequestSubmitConfirmation
              downloadError={downloadSuccessMsg}
              closeModalParent={() => {
                setShowDownloadSuccess(false);
              }}
            />
          }
        />
      )} */}
        <div className="invisible ">
          <ExportJsonCsv
            items={csvFileData}
            fileTitle={csvFileName}
            headers={csvHeaderData}
          >
            <button ref={csvLink}>
              {" "}
              <i class="fa fa-download" />
            </button>
          </ExportJsonCsv>
        </div>
      </div>
      {showInvoiceGeneration && (
        <CustomModal
          showModal={true}
          fileUpload={true}
          children={
            <InvoiceGeneraionModal
              handleGenerateInvoice={handleGenerateInvoice}
              handleChange={handleChange}
              setShowInvoiceGeneration={setShowInvoiceGeneration}
              isMonthChecked={isMonthChecked}
            />
          }
        />
      )}
      {showVersionHistory && (
        <CustomModal
          showModal={true}
          children={
            <VersionHistoryModal
              setShowVersionHistory={setShowVersionHistory}
              versionData={versionData}
            />
          }
        />
      )}
      {showDownloadModalOpen && (
        <CustomModal
          showModal={true}
          fileUpload={true}
          children={
            <InvoiceDownloadModal
              step={step}
              setDownloadModalOpen={setDownloadModalOpen}
              setYearDropDown={setYearDropDown}
              handleChange={handleChange}
              isMonthChecked={isMonthChecked}
              handleGenerateInvoice={handleGenerateInvoice}
              isYearDropDown={isYearDropDown}
              setStep={setStep}
              dateObject={dateObject}
              isYearChecked={isYearChecked}
              getSelectedMonth={getSelectedMonth}
            />
          }
        />
      )}
    </>
  );
}
