const ipData = [
  {
    label: `16 Ip's - CIDR block of /28`,
    value: `16 Ip's - CIDR block of /28`,
  },
  {
    label: `32 Ip's - CIDR block of /27`,
    value: `32 Ip's - CIDR block of /27`,
  },
  {
    label: `64 Ip's - CIDR block of /26`,
    value: `64 Ip's - CIDR block of /26`,
  },
  {
    label: `128 Ip's - CIDR block of /25`,
    value: `128 Ip's - CIDR block of /25`,
  },
  {
    label: `256 Ip's - CIDR block of /24`,
    value: `256 Ip's - CIDR block of /24`,
  },
  {
    label: `512 Ip's - CIDR block of /23`,
    value: `512 Ip's - CIDR block of /23`,
  },
];

export { ipData };
