import React from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import Logo
import requesttrainingcolor from 'icons/sub-menu-icons/request-training-color.svg';
import { t } from 'i18next';

const RequestTrainingSidebar = () => {
  // Routing Variables
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const {t}=useTranslation();

  return (
    <div className='passport-sidebar-container'>
      <ul className='passport-sidebar-logo-list'>
        <li>
          <img src={requesttrainingcolor} alt='Cloud Eye' />
          <span className='passport-sidebar-title px-2'>{t("cloud_training")}</span>
        </li>
      </ul>

      <>
        <ul className='passport-sidebar-link-list'>
          <li
            className={
              splitLocation[1] === 'request-training-view'
                ? 'passport-sidebar-link-active'
                : 'passport-sidebar-link-inactive'
            }
          >
            <Link to='/request-training-view'>{t('view_request')}</Link>
          </li>
          <li
            className={
              splitLocation[1] === 'request-training-add'
                ? 'passport-sidebar-link-active'
                : 'passport-sidebar-link-inactive'
            }
          >
            <Link to='/request-training-add'>{t("request_training")}</Link>
          </li>
        </ul>
      </>
    </div>
  );
};

export default withRouter(RequestTrainingSidebar);
