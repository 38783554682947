import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Form, Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { withRouter } from 'react-router';

// Import Icons
import backicon from 'icons/back.svg';

// Data
import { awsRegionData } from 'pages/CIDR/region-data';
import { ipData } from 'pages/CIDR/ip-data';
import { useTranslation } from 'react-i18next';

const CIDREditBox = ({
  uid,
  editBackParent,
  status,
  optionData,
  history,
  refreshDataParent,
}) => {
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const { t } = useTranslation();
  const [formSubmitMessage, setFormSubmitMessage] = useState('');

  // Set Region Based on Service Type
  const [serviceBasedRegion, setServiceBasedRegion] = useState(awsRegionData);

  // ! Edit Form State
  const [accountNumber, setAccountNumber] = useState(optionData.accountNumber);
  const [accountName, setAccountName] = useState(optionData.accountName);
  const [region, setRegion] = useState(optionData.region);
  const [ip, setIp] = useState(optionData.ip);

  // ! Edit Loading State
  const [editLoading, setEditLoading] = useState(false);

  // ! Show Success Edit Modal
  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);

  // ! Cancel Edit State (No)
  const [cancelNoEdit, setCancelNoEdit] = useState(false);

  function closeEditBox() {
    editBackParent(false);
  }

  async function formHandleSubmit(e) {
    e.preventDefault();
    setEditLoading(true);
    // Api Call
    const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: setIdToken,
      },
    };

    const apiUrl = `${process.env.REACT_APP_AWS_CIDR_ADD_REQUEST}`;
    const response = await axios.post(
      apiUrl,
      {
        ip: ip,
        accountNumber: accountNumber,
        accountName: accountName,
        region: region,
        requestStatus: status,
        submittedBy: optionData.submittedBy,
        userNumber: optionData.userNumber,
        uid: uid,
        state: 'Update',
      },
      config
    );
    const { data } = response;
    if (data.status === 'Success') {
      setEditLoading(false);
      setFormSubmitMessage(
        'Your account detail was updated and submitted successfully'
      );
      setShowEditSuccessModal(true);
    } else {
      setEditLoading(false);
      setFormSubmitMessage(
        'Failed to update data, Contact Sony Global Cloud Team for Assistance'
      );
      setShowEditSuccessModal(true);
    }
  }
  return (
    <div className='view-edit-box-conatiner'>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => closeEditBox()}
          />
          <div>
            <h5 className='view-edit-box-title'>AWS CIDR Request</h5>
          </div>
        </div>
      </div>

      <div className='mt-4'>
        <Form onSubmit={formHandleSubmit}>
          <Row>
            <Col md={12}>
              <Form.Group className='mb-3' controlId='selectAccountNumber'>
                <Form.Label>Select Account</Form.Label>

                <Form.Control
                  type='text'
                  readOnly
                  placeholder='Select Account'
                  value={accountNumber}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className='mb-3' controlId='selectRegion'>
                <Form.Label>Select Region</Form.Label>

                <Form.Select
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                >
                  {serviceBasedRegion.map((data, index) => (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className='mb-3' controlId='selectAccountName'>
                <Form.Label>Account Name</Form.Label>

                <Form.Control
                  type='text'
                  readOnly
                  placeholder='Select Account Name'
                  value={accountName}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className='mb-3' controlId='selectIp'>
                <Form.Label>*Select Number of IP's/Associated CIDR</Form.Label>

                <Form.Select value={ip} onChange={(e) => setIp(e.target.value)}>
                  {ipData.map((data, index) => (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={12}>
              {editLoading ? (
                <div className='d-flex justify-content-center mt-5'>
                  <Spinner animation='border' />
                </div>
              ) : (
                <div className='d-flex justify-content-end'>
                  <div>
                    <button
                      type='button'
                      className='teal-bordered-btn'
                      onClick={() => setCancelNoEdit(true)}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className='mx-2'>
                    <button type='submit' className='teal-filled-btn'>
                      Save & Submit
                    </button>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Form>
      </div>

      {/* Edit Success Modal */}
      {showEditSuccessModal && (
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={showEditSuccessModal}
        >
          <Modal.Body className='text-center'>
            <p>
              <i className='bx bxs-check-circle cidr-check-mark'></i>
            </p>
            <p className='cidr-modal-success-msg'>
              {/* Your account detail was updated and submitted successfully */}
              {formSubmitMessage}
            </p>

            <p>
              <button
                type='button'
                className='mx-3 teal-filled-btn'
                onClick={() => {
                  closeEditBox();
                  refreshDataParent(true);
                }}
              >
                Ok
              </button>
            </p>
          </Modal.Body>
        </Modal>
      )}

      {/* Cancel Edit Modal */}
      {cancelNoEdit && (
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={cancelNoEdit}
        >
          <Modal.Body className='text-center'>
            <p>
              <i className='bx bxs-check-circle cidr-check-mark'></i>
            </p>
            <p className='cidr-modal-success-msg'>
            <p>{t("data_loss_warning")}</p>
            <p>{t("sure_to_continue")}</p>
            </p>

            <p>
              <button
                type='button'
                className='teal-bordered-btn'
                onClick={() => {
                  setCancelNoEdit(false);
                }}
              >
                No
              </button>
              <button
                type='button'
                className='mx-3 teal-filled-btn'
                onClick={() => {
                  setCancelNoEdit(false);
                  closeEditBox();
                }}
              >
                Yes
              </button>
            </p>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default withRouter(CIDREditBox);
