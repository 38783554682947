export const DefaultAWSData = {
  accountContact: {
    firstNameAO: '',
    lastNameAO: '',
    emailAO: '',
    countryAO: '',
    phoneNoAO: '',
  },
  billingContact: {
    firstNameBC: '',
    lastNameBC: '',
    emailBC: '',
    countryBC: '',
    phoneNoBC: '',
  },
  securityContact: {
    firstNameSC: '',
    lastNameSC: '',
    emailSC: '',
    countrySC: '',
    phoneNoSC: '',
  },
  technicalContact: {
    firstNameTC: '',
    lastNameTC: '',
    emailTC: '',
    countryTC: '',
    phoneNoTC: '',
  },
  billingInfo: {
    billingopco: '',
    billingSubOpco: '',
    billingDepartment: '',
    billingRegion: '',
    maxSpend: '',
    billto: '',
    io: '',
    costcenter: '',
  },
  groupEmailAddress: {
    technicalGroup: '',
    securityGroup: '',
    billingGroup: '',
    pgcsgoldenami: 'No',
    ptm: 'Yes',
    conformity: 'Yes',
    sonyad: 'No',
  },
  accountDetails: {
    opco: '',
    subopco: '',
    department: '',
    environmenttype: '',
    assetUserBase: '',
    dataClassification: '',
    purposeid: '',
    accountdescription: '',
  },
  accountnumber: '',
  accountname: '',
  accstatus: '',
};

export const AWSReducer = (awsData, { type, payload }) => {
  switch (type) {
    case 'UPDATE_ACCOUNT_CONTACT':
      return {
        ...awsData,
        accountContact: {
          ...awsData.accountContact,
          ...payload,
        },
      };
    case 'UPDATE_BILLING_CONTACT':
      return {
        ...awsData,
        billingContact: {
          ...awsData.billingContact,
          ...payload,
        },
      };
    case 'UPDATE_SECURITY_CONTACT':
      return {
        ...awsData,
        securityContact: {
          ...awsData.securityContact,
          ...payload,
        },
      };
    case 'UPDATE_TECHNICAL_CONTACT':
      return {
        ...awsData,
        technicalContact: {
          ...awsData.technicalContact,
          ...payload,
        },
      };
    case 'UPDATE_BILLING_INFO':
      return {
        ...awsData,
        billingInfo: {
          ...awsData.billingInfo,
          ...payload,
        },
      };
    case 'UPDATE_GROUP_EMAIL_ADDRESS':
      return {
        ...awsData,
        groupEmailAddress: {
          ...awsData.groupEmailAddress,
          ...payload,
        },
      };
    case 'UPDATE_ACCOUNT_DETAILS':
      return {
        ...awsData,
        accountDetails: {
          ...awsData.accountDetails,
          ...payload,
        },
      };
    case 'UPDATE_COMMON_DETAILS':
      return {
        ...awsData,
        ...payload,
      };

    default:
      return awsData;
  }
};
