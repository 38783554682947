import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

import ReasonMessage from '../ReasonMessage';
import TriggerMessage from '../TriggerMessage';

// Helper
import { postApiCall } from 'helper/postApiCall';

import faqIcon from 'icons/FAQ.svg';
import refreshIcon from 'icons/Refresh.svg';
import { t } from 'i18next';

const AzureTable = () => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // API State
  const [apiLoading, setApiLoading] = useState(true);
  const [apiResponseData, setApiResponseData] = useState([]);
  const [apiError, setApiError] = useState(null);

  // Component State
  // Reason Component
  const [showReason, setShowReason] = useState(false);
  const [reasonMessage, setReasonMessage] = useState('');
  const closeModalCallback = useCallback((value) => {
    setShowReason(value);
    setReasonMessage('');
  }, []);

  // Trigger Component
  const [showTrigger, setShowTrigger] = useState(false);
  const [triggerMessage, setTriggerMessage] = useState('');

  const closeTriggerModalCallback = useCallback((value) => {
    setShowTrigger(value);
    setTriggerMessage('');
  }, []);

  // API Variables
  let apiUrl = `${process.env.REACT_APP_AZURE_MORIARTY_VIEW}`;
  const idToken = `${userInfo.idToken}`;
  let requestBody = '{}';

  const columns = [
    {
      dataField: 'AccountId',
      text: t('account_number'),
      sort: true,
      searchable: true,
    },
    {
      dataField: 'AccountName',
      text: t('account_name'),
      sort: true,
      searchable: true,
    },
    {
      dataField: 'Envtype',
      text: t('env_type'),
      sort: true,
      searchable: true,
    },
    {
      dataField: 'ExecutionDate',
      text: t("last_refresh_rate"),
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        return <div className='text-center'>{row.ExecutionDate}</div>;
      },
    },
    {
      dataField: 'Status',
      text: t("status"),
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (row.Status === 'success') {
          return (
            <span className='approved-badge text-capitalize'>{row.Status}</span>
          );
        } else {
          return (
            <span className='pending-badge text-capitalize'>{row.Status}</span>
          );
        }
      },
    },
    {
      dataField: 'dummyActionField',
      text: '',
      formatter: (cellContent, row) => {
        if (row.Status === 'success') {
          return (
            <Row>
              <Col></Col>
              <Col>
                <img
                  src={refreshIcon}
                  alt='Refresh'
                  className='custom-pointer'
                  onClick={() => {
                    triggerCall(row.AccountId);
                  }}
                />
              </Col>
            </Row>
          );
        } else {
          return (
            <Row>
              <Col>
                <img
                  src={faqIcon}
                  alt='FAQ'
                  className='custom-pointer'
                  onClick={() => {
                    setShowReason(true);
                    setReasonMessage(row.Reason);
                  }}
                />
              </Col>
              <Col>
                <img
                  src={refreshIcon}
                  alt='Refresh'
                  className='custom-pointer'
                  onClick={() => {
                    triggerCall(row.AccountId);
                  }}
                />
              </Col>
            </Row>
          );
        }
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: 'AccountId',
      order: 'asc',
    },
  ];

  const customTotal = (from, to, size) => (
    <span className='react-bootstrap-table-pagination-total'>
      {t("showing_results",{from,to,size})}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: apiResponseData.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  // Trigger Function
  function triggerCall(accountNumber) {
    setShowTrigger(true);
    const triggerApiUrl = `${process.env.REACT_APP_AZURE_MORIARTY_TRIGGER_CALL}`;
    let triggerRequestBody = {
      accountnumber: `${accountNumber}`,
    };

    postApiCall(triggerApiUrl, idToken, triggerRequestBody)
      .then((response) => {
        setTriggerMessage(response.data);
      })
      .catch((err) => {
        setTriggerMessage(
          'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
        );
      });
  }

  useEffect(() => {
    function fetchData() {
      setApiLoading(true);
      postApiCall(apiUrl, idToken, requestBody)
        .then((response) => {
          setApiResponseData(response.data);
          setApiLoading(false);
        })
        .catch((err) => {
          setApiError(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setApiLoading(false);
        });
    }

    fetchData();
    return () => {
      setApiResponseData([]);
    };
  }, []);

  return (
    <>
      {apiLoading && (
        <div className='mt-3'>
          <SpinnerLoader />
        </div>
      )}

      {apiLoading === false && apiError !== null && (
        <Row>
          <Col md={12} className='d-flex justify-content-center mt-4'>
            <DangerAlert message={apiError} />
          </Col>
        </Row>
      )}

      {apiLoading === false && (
        <Row>
          <Col>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField='AccountId'
              columns={columns}
              data={apiResponseData}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField='AccountId'
                  columns={columns}
                  data={apiResponseData}
                  search
                >
                  {(toolkitProps) => (
                    <>
                      <Row className='d-flex align-items-center'>
                        <Col md={9} className='mt-3'>
                          <span className='subtitle-text'>
                            {t("azure_moriarity_flow")}
                          </span>
                        </Col>

                        {apiResponseData.length > 0 && (
                          <Col md={3} className='mt-3'>
                            <div className='search-input-group'>
                              <i className='fa fa-search'></i>
                              <SearchBar
                                {...toolkitProps.searchProps}
                                className='custom-search-input-form-control'
                                placeholder={t('search_placeholder')}
                
                              />
                            </div>
                          </Col>
                        )}
                      </Row>

                      <>
                        <Row>
                          <Col md={12} xl={12} className='mt-4'>
                            <div className='table-responsive'>
                              <BootstrapTable
                                keyField={'AccountId'}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={'table align-middle table-nowrap'}
                                headerWrapperClasses={
                                  'custom-table-head custom-pointer'
                                }
                                bodyClasses={'custom-table-body'}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                          <Col>
                            {apiResponseData.length === 0 && (
                              <div className='custom-error-message text-center'>
                                {t('no_data_to_display')}
                              </div>
                            )}
                          </Col>
                        </Row>

                        <Row className='align-items-md-center mt-3'>
                          <Col className='d-flex flex-wrap justify-content-between align-items-baseline'>
                            <div>
                              <PaginationTotalStandalone {...paginationProps} />
                            </div>
                            <div>
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </>
                    </>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </Col>
        </Row>
      )}
      {showReason && (
        <ReasonMessage
          closeModalParent={closeModalCallback}
          message={reasonMessage}
        />
      )}
      {showTrigger && (
        <TriggerMessage
          closeModalParent={closeTriggerModalCallback}
          message={triggerMessage}
        />
      )}
    </>
  );
};

export default AzureTable;
