import {
    TREND_MICRO_SUBSCRIPTION_DETAILS, TREND_MICRO_SUBSCRIPTION_RELOAD
  } from './subscriptionDetailsConstant';
  
  export const subscriptionDetailsReducer = (
    state = {
      trendMicroSubscriptionDetails: [],
      trendMicroSubscriptionReload: false
    },
    action
  ) => {
    switch (action.type) {
      case TREND_MICRO_SUBSCRIPTION_DETAILS:
        return { ...state, trendMicroSubscriptionDetails: action.payload };
      default:
        return state;
    }
  };
  
  