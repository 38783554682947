import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import aws from "images/aws-logo.svg";
import azure from "images/azure-logo.svg";
import gcpLogo from "icons/google-cloud.svg";
import { Link, withRouter } from "react-router-dom";

import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";
import Loader from "components/Common/Loader";
import SpinnerLoader from "components/Common/SpinnerLoader";

const RequestWidget = ({ history }) => {
  const user = useSelector((state) => state.user);

  const { userInfo } = user;
  const [reports, setReports] = useState({});

  useEffect(() => {
    const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

    if (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: setIdToken,
        },
      };

      axios
        .get(`${process.env.REACT_APP_WIDGET_COUNTS}`, config)
        .then((response) => {
          setReports(response.data);
        });
    }

    return () => {
      setReports({});
    };
  }, []);
  return (
    <>
      <Col md={6} xs={12} className="">
        <Row>
          <Col md={6} xs={12} className="">
            <div className="custom-card mb-3">
              <div className="d-flex report-widget-wrapper">
                <h5 className="dashboard-title dboard-title-center mb-2">
                  Cloud Accounts
                </h5>
                <div className="text-muted mt-2 report-widget-logo-value-wrapper">
                  <div className="dashboard-req-count">
                    <img
                      src={aws}
                      alt="AWS"
                      className="report-widget-img gcs-count-logo"
                    />
                    <h4 className="report-widget-value-font mb-0">
                      {reports.AWSaccounts ? (
                        reports.AWSaccounts
                      ) : (
                        <SpinnerLoader sm="true" />
                      )}
                    </h4>
                  </div>
                  <div className="dashboard-req-count">
                    <img
                      src={azure}
                      alt="AWS"
                      className="report-widget-img gcs-count-logo"
                    />
                    <h4 className="report-widget-value-font mb-0">
                      {reports.Azureaccounts > -1 ? (
                        reports.Azureaccounts
                      ) : (
                        <SpinnerLoader sm="true" />
                      )}
                    </h4>
                  </div>
                  <div className="dashboard-req-count">
                    <img
                      src={gcpLogo}
                      alt="GCP"
                      className="report-widget-img gcs-count-logo"
                    />
                    <h4 className="report-widget-value-font mb-0">
                      {reports.Gcpaccounts > -1 ? (
                        reports.Gcpaccounts
                      ) : (
                        <SpinnerLoader sm="true" />
                      )}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col md={6} xs={12} className="">
            <div className="custom-card mb-3">
              <div className="d-flex report-widget-wrapper">
                <h5 className="dashboard-title dboard-title-center mb-2">
                  New Account Request
                </h5>
                <div className="text-muted mt-2 report-widget-logo-value-wrapper">
                  <div className="dashboard-req-count">
                    <Link to="/aws-account-requests" className="custom-pointer">
                      <img
                        src={aws}
                        alt="AWS"
                        className="report-widget-img gcs-count-logo"
                      />
                    </Link>
                    <h4 className="report-widget-value-font mb-0">
                      {reports.AWSAccReq > -1 ? (
                        reports.AWSAccReq
                      ) : (
                        <SpinnerLoader sm="true" />
                      )}
                    </h4>
                  </div>
                  <div className="dashboard-req-count">
                    <Link
                      to="/azure-account-requests"
                      className="custom-pointer"
                    >
                      <img
                        src={azure}
                        alt="AWS"
                        className="report-widget-img gcs-count-logo"
                      />
                    </Link>
                    <h4 className="report-widget-value-font mb-0">
                      {reports.AzureAccReq > -1 ? (
                        reports.AzureAccReq
                      ) : (
                        <SpinnerLoader sm="true" />
                      )}
                    </h4>
                  </div>
                  <div className="dashboard-req-count">
                    <Link to="/gcp-account-requests" className="custom-pointer">
                      <img
                        src={gcpLogo}
                        alt="GCP"
                        className="report-widget-img gcs-count-logo gcs-count-logo"
                      />
                    </Link>
                    <h4 className="report-widget-value-font mb-0">
                      {reports.GcpAccReq > -1 ? (
                        reports.GcpAccReq
                      ) : (
                        <SpinnerLoader sm="true" />
                      )}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={6} xs={12} className="">
        <Row className="custom-card margin-2px">
          <Col md={7} xs={12} className="">
            <div className=" mb-1 last-request-container">
              <div className="d-flex report-widget-wrapper request-h5-container">
                <h5 className="dashboard-title dboard-title-center ">
                  Account Update Request
                </h5>
                <h5 className="dashboard-title dboard-title-center ">
                  New CIDR Request
                </h5>
                <h5 className="dashboard-title dboard-title-center ">
                  New Workspace request
                </h5>
              </div>
            </div>
          </Col>

          <Col md={5} xs={12} className="">
            <div className=" ">
              <div className="d-flex report-widget-wrapper cidr-req-widg">
                <div className="image-container d-flex justify-content-evenly">
                  <img
                    src={aws}
                    alt="AWS"
                    className="report-widget-img custom-pointer gcs-count-logo-right"
                  />
                  <img
                    src={azure}
                    alt="Azure"
                    className="report-widget-img custom-pointer gcs-count-logo-right"
                  />
                  <img
                    src={gcpLogo}
                    alt="GCP"
                    className="report-widget-img gcs-count-logo gcs-count-logo-right"
                  />
                </div>
                <div className="text-muted mt-0 report-widget-logo-value-wrapper combined-card-counts">
                  <div className="dashboard-req-count">
                    <Link to="/aws-account-requests" className="custom-pointer">
                      <h4 className="report-widget-value-font mb-0">0</h4>
                    </Link>

                    <Link
                      to="/azure-account-requests"
                      className="custom-pointer"
                    >
                      <h4 className="report-widget-value-font mb-0">0</h4>
                    </Link>

                    <Link to="/gcp-account-requests" className="custom-pointer">
                      <h4 className="report-widget-value-font mb-0">0</h4>
                    </Link>
                  </div>
                  <div className="dashboard-req-count">
                    <Link
                      to="/aws-cidr-view-requests"
                      className="custom-pointer"
                    >
                      <h4 className="report-widget-value-font mb-0">
                        {reports.AWSCidrReq > -1 ? (
                          reports.AWSCidrReq
                        ) : (
                          <SpinnerLoader sm="true" />
                        )}
                      </h4>
                    </Link>
                    <Link
                      to="/azure-cidr-view-requests"
                      className="custom-pointer"
                    >
                      <h4 className="report-widget-value-font mb-0">
                        {reports.AzureCidrReq > -1 ? (
                          reports.AzureCidrReq
                        ) : (
                          <SpinnerLoader sm="true" />
                        )}
                      </h4>
                    </Link>
                    <Link
                      to="/aws-cidr-view-requests"
                      className="custom-pointer"
                    >
                      <h4 className="report-widget-value-font mb-0">0</h4>
                    </Link>
                  </div>
                  <div className="dashboard-req-count">
                    <Link to="/aws-workspaces-view" className="custom-pointer">
                      <h4 className="report-widget-value-font mb-0">
                        {reports.AWSWorkReq > -1 ? (
                          reports.AWSWorkReq
                        ) : (
                          <SpinnerLoader sm="true" />
                        )}
                      </h4>
                    </Link>
                    <Link to="/aws-workspaces-view" className="custom-pointer">
                      <h4 className="report-widget-value-font mb-0">0</h4>
                    </Link>
                    <Link to="/aws-workspaces-view" className="custom-pointer">
                      <h4 className="report-widget-value-font mb-0">0</h4>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default withRouter(RequestWidget);
