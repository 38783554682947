import React, { useState, useRef, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import CustomModal from 'components/Modal/CustomModal';
import TextInput from 'components/Forms/TextInput';

import Cancel from './Cancel';
import Submit from './Submit';

// Helper
import { postApiCall } from 'helper/postApiCall';

// Validator
import { templateValiadtor } from './validator';

const AddTemplate = () => {
  const ref = useRef();
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // ID Token
  const idToken = `${userInfo.idToken}`;

  // Component State
  const [showCancel, setShowCancel] = useState(false);
  const [showSubmit, setshowSubmit] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancel(value);
  }, []);

  // Data Input Error
  const [dataInputError, setDataInputError] = useState({});
  const [uploadError, setuploadError] = useState();

  // API State
  const [loading, setLoading] = useState(false);

  // Form State
  const [templateName, setTemplateName] = useState('');
  const [templateKey, setTemplateKey] = useState('');
  const [template, setTemplate] = useState('');

  const [formSubmitMessage, setFormSubmitMessage] = useState(null);

  // Base64
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Upload JSON
  const uploadJSON = async (e) => {
    const file = e.target.files[0];
    const filePath = e.target.files[0].name;
    const fileSize = e.target.files[0].size;
    const checkFileSize = Math.round(fileSize / 1024);

    const allowedExtensions = ['json'];
    const fileExtension = filePath.split('.').pop();

    if (checkFileSize >= 5120) {
      setuploadError('File size should be below 5MB');
      ref.current.value = '';
    } else if (!allowedExtensions.includes(fileExtension)) {
      setuploadError('file should be in json extension');
      ref.current.value = '';
    } else {
      var reader = new FileReader();

      reader.onload = async function () {
        try {
          await JSON.parse(reader.result);
          const base64 = await convertBase64(file);
          setTemplate(base64);
          setTemplateKey(file.name);
        } catch (err) {
          // console.log(err);
          setuploadError('Invalid JSON format');
        }
      };

      reader.readAsText(e.target.files[0]);
    }
  };

  // Form Submit
  function formSubmit() {
    const formApiUrl = `${process.env.REACT_APP_TEMPLATES_ADD}`;
    const errors = templateValiadtor({
      templateName,
      templateKey,
      template,
    });
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setLoading(true);
      const currentEpochTime = Date.now();
      const formRequestBody = {
        uid: `u-${currentEpochTime}`,
        templateName: `${templateName}`,
        templateKey: `${templateKey}`,
        template: `${template}`,
      };

      postApiCall(formApiUrl, idToken, formRequestBody)
        .then((response) => {
          if (response.data.message !== '') {
            setFormSubmitMessage(response.data.message);
          } else {
            setFormSubmitMessage(
              'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
            );
          }
          setshowSubmit(true);
          setLoading(false);
        })
        .catch((err) => {
          setFormSubmitMessage(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setshowSubmit(true);
          setLoading(false);
        });
    }
  }

  return (
    <>
      <Row>
        <Col md={9} className='mb-2'>
          <h5 className='page-content-title'>Add Template</h5>
        </Col>
        <Col md={3} className='mt-2 mb-2'>
          <Link to='/templates-view'>
            <button className='teal-filled-btn w-100'>
              <i className='fa fa-plus mx-2'></i>
              View Template
            </button>
          </Link>
        </Col>
      </Row>
      {loading && (
        <Row>
          <Col>
            <div className='d-flex justify-content-center'>
              <SpinnerLoader />
            </div>
          </Col>
        </Row>
      )}

      {loading === false && (
        <>
          <Row>
            <Col md={6} className='mb-2'>
              <TextInput
                label='Template Name'
                placeholder='Template Name'
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                isInvalid={dataInputError.templateName ? true : false}
                invalidMessage={dataInputError.templateName}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group
                controlId='formFile'
                className='mb-3 d-flex flex-column'
              >
                <Form.Label>Upload Template</Form.Label>
                {templateKey !== '' && (
                  <div className='mb-3'>{templateKey}</div>
                )}
                <div className='custom-upload-wrapper'>
                  <button className='custom-upload-button'>
                    <i className='fa fa-cloud-upload'></i>
                    Add File
                  </button>
                  <input
                    type='file'
                    ref={ref}
                    onChange={(e) => {
                      uploadJSON(e);
                    }}
                  />
                </div>
              </Form.Group>
              <div className='custom-error-message-text'>
                {dataInputError.template}
              </div>
              <div className='custom-error-message-text'>{uploadError}</div>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='d-flex flex-row mt-3'>
              <ul className='btn-ul'>
                <li>
                  <button
                    type='button'
                    className='teal-bordered-btn'
                    onClick={() => {
                      setShowCancel(true);
                    }}
                  >
                    Cancel
                  </button>
                </li>
                <li>
                  <button
                    type='button'
                    className='teal-filled-btn'
                    onClick={() => {
                      formSubmit();
                    }}
                  >
                    Submit
                  </button>
                </li>
              </ul>
            </Col>
          </Row>
        </>
      )}

      {showCancel && (
        <CustomModal
          showModal={true}
          children={<Cancel closeModalParent={closeModalCallback} />}
        />
      )}

      {showSubmit && (
        <CustomModal
          showModal={true}
          children={<Submit message={formSubmitMessage} />}
        />
      )}
    </>
  );
};

export default AddTemplate;
