import { useQuery } from "react-query";
import { useOktaAuth } from "@okta/okta-react";
import Api from "api/api";
import { apiWrapper } from "pages/UserCloudEye/dataloader";
import { errorToast, successToast } from "components/Notification";

import { useMutation } from "react-query";
import {
  createRequestForAppsInCloudEye,
  getAppListForCloudEyeAppRequestApi,
  viewCloudEyeRequestsByEmail,
} from "api/cloudeye";

export function useApplicationListApiForCloudEye() {
  const { authState } = useOktaAuth();

  return useQuery(
    [Api.appListForCloudEyeRequest],
    apiWrapper(getAppListForCloudEyeAppRequestApi, {
      idToken: authState.idToken.idToken,
    }),
    {
      initialData: [],
      onError: (res) => {
        errorToast("failed to fetch apps list");
      },
    }
  );
}

export function useCreateRequestForAppsInCloudEye() {
  return useMutation(createRequestForAppsInCloudEye, {
    onSuccess: (res) => {
      successToast(res.message);
    },
  });
}

export function useViewCloudEyeRequestsByEmail() {
  return useMutation(viewCloudEyeRequestsByEmail, {
    onSuccess: (res) => {
      successToast(res.message);
    },
  });
}
