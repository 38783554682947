import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { useTranslation } from "react-i18next";

// Import Icon
import awsLogo from "images/aws-logo.svg";
import azureLogo from "images/azure-logo.svg";

// Import Constant
import { USER_INFO } from "redux/user/userConstant";

import {
  CS_Hub_AWS_Account_Request_Admin,
  CS_Hub_AWS_Dynamo_Search_Request_Admin,
  CS_Hub_Security_Admin_Branch_2,
} from "roles/roles";
import NotFound from "pages/NotFound";

const AccountUpdateCloudPreference = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const { authState, oktaAuth } = useOktaAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch]);

  const userAccess =
    userInfo?.groups?.includes(CS_Hub_Security_Admin_Branch_2) ||
    userInfo?.groups?.includes(CS_Hub_AWS_Dynamo_Search_Request_Admin) ||
    userInfo?.groups?.includes(CS_Hub_AWS_Account_Request_Admin)
      ? true
      : false;

  return (
    <>
      {userInfo !== null &&
        authState &&
        authState.isAuthenticated &&
        (userAccess ? (
          <div className="header-section-new flex-column">
            <p className="cloud-preference-p">{t("cloud_preference")}</p>
            <div className="d-flex flex-column flex-md-row gap-4">
              <Link to="/aws-account-update-requests">
                <div className="logoContainer">
                  <img
                    src={awsLogo}
                    alt="AWS"
                    className="cloud-preference-img"
                  />
                </div>
              </Link>

              <Link to="/azure-account-update-requests">
                <div className="logoContainer">
                  <img
                    src={azureLogo}
                    alt="Azure"
                    className="cloud-preference-img"
                    style={{ marginLeft: "20px" }}
                  />
                </div>
              </Link>
            </div>
          </div>
        ) : (
          <NotFound notAuthorised />
        ))}
    </>
  );
};

export default AccountUpdateCloudPreference;
