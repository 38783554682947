export const DefaultAWSData = {
  toolsExceptionData: {
    accountNumber: "",
    BIARating: "",
    accountName: "",
    projectName: "",
    accountOwnerName: "",
    exceptionDetail: "",
    exceptionTimeline: "",
    exceptionCategory: "",
  },

  requestStatus: "",
  uid: "",
  aws_account_id: "",
  RequestActionTakenBy: "",
  submittedBy: "",
};

export const AWSReducer = (awsData, { type, payload }) => {
  switch (type) {
    case "TOOLS_EXCEPTION":
      return {
        ...awsData,
        toolsExceptionData: {
          ...awsData.toolsExceptionData,
          ...payload,
        },
      };

    default:
      return awsData;
  }
};
