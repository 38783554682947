import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import ReactSpeedometer from 'react-d3-speedometer';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

import { CLOUD_EYE_USER_TABLE } from 'redux/cloudeye-user/cloueyeUserConstant';

const ApplicationHealth = ({
  serviceLoading,
  serviceData,
  serviceError,
  applicationLoading,
  applicationData,
  applicationError,
}) => {
  const dispatch = useDispatch();

  return (
    <div className='custom-card'>
      <Row>
        <Col md={6} className='mt-3 d-flex justify-content-center'>
          {serviceLoading && <SpinnerLoader />}
          {serviceLoading === false && serviceError === null && (
            <>
              <div className='speedometer-container'>
                <ReactSpeedometer
                  width={200}
                  height={150}
                  ringWidth={35}
                  needleTransitionDuration={2000}
                  needleHeightRatio={0.5}
                  minValue={0}
                  maxValue={1.1}
                  maxSegmentLabels={0}
                  startColor={'#FE6161'}
                  endColor={'#47E897'}
                  currentValueText=''
                  value={
                    serviceData.data.overall.toLowerCase() === 'healthy'
                      ? 1
                      : 0.1
                  }
                  segments={1000}
                />
                <div
                  className='speedometer-title custom-pointer'
                  onClick={() => {
                    dispatch({
                      type: CLOUD_EYE_USER_TABLE,
                      payload: 'Service',
                    });
                  }}
                >
                  {serviceData.data.overall.toLowerCase() === 'healthy' ? (
                    <>
                      <span>Service</span>
                      <span className='healthy-text'>
                        {serviceData.data.overall}
                      </span>
                    </>
                  ) : (
                    <>
                      <span>Service</span>
                      <span className='unhealthy-text'>
                        {serviceData.data.overall}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {serviceLoading === false && serviceError !== null && (
                <DangerAlert message={serviceError} />
              )}
            </>
          )}
        </Col>
        <Col md={6} className='mt-3 d-flex justify-content-center'>
          {applicationLoading && <SpinnerLoader />}
          {applicationLoading === false && applicationError === null && (
            <>
              <div className='speedometer-container'>
                <ReactSpeedometer
                  width={200}
                  height={150}
                  ringWidth={35}
                  // needleColor={'#E5E5E5'}
                  needleTransitionDuration={2000}
                  needleHeightRatio={0.5}
                  minValue={0}
                  maxValue={1.1}
                  maxSegmentLabels={0}
                  startColor={'#FE6161'}
                  endColor={'#47E897'}
                  currentValueText=''
                  value={
                    applicationData.data.overall.toLowerCase() === 'healthy'
                      ? 1
                      : 0.1
                  }
                  segments={1000}
                />
                <div
                  className='speedometer-title custom-pointer'
                  onClick={() => {
                    dispatch({
                      type: CLOUD_EYE_USER_TABLE,
                      payload: 'Application',
                    });
                  }}
                >
                  {applicationData.data.overall.toLowerCase() === 'healthy' ? (
                    <>
                      <span>Application</span>
                      <span className='healthy-text'>
                        {applicationData.data.overall}
                      </span>
                    </>
                  ) : (
                    <>
                      <span>Application</span>
                      <span className='unhealthy-text'>
                        {applicationData.data.overall}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {applicationLoading === false && applicationError !== null && (
                <DangerAlert message={applicationError} />
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ApplicationHealth;
