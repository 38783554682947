import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import MenuTabs from '../MenuTabs';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

import ViewAlarm from './ViewAlarm';
import FilterPattern from './FilterPattern';
import SetAlarm from './SetAlarm';

// Helper
import { postApiCall } from 'helper/postApiCall';

import { CLOUD_EYE_APPLICATION_NAME } from 'redux/cloudeye-user/cloueyeUserConstant';

const Logs = ({ applicationName }) => {
  const isMounted = useRef(false);
  const dispatch = useDispatch();

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [selectedApplicationName, setSelectedApplicationName] = useState('');

  // Action State
  const [showSideCanvas, setShowSideCanvas] = useState('');

  const closeSideCanvasCallback = useCallback((value) => {
    setShowSideCanvas(value);
  }, []);

  // API Variables
  const apiUrl = `${process.env.REACT_APP_CLOUD_EYE_LOGS_TABLE}`;
  const idToken = `${userInfo.idToken}`;
  const requestBody = { application: selectedApplicationName };

  // API State
  const [apiLoading, setApiLoading] = useState(true);
  const [apiResponseData, setApiResponseData] = useState([]);
  const [apiError, setApiError] = useState(null);

  const columns = [
    {
      dataField: 'Logs',
      text: 'Logs',
      sort: true,
      searchable: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ];

  const customTotal = (from, to, size) => (
    <span className='react-bootstrap-table-pagination-total'>
      Showing {from} to {to} of {size}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: apiResponseData.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    if (isMounted.current) {
      setApiLoading(true);
      postApiCall(apiUrl, idToken, requestBody)
        .then((response) => {
          let tempArray = [];
          for (const property in response.data.body) {
            let tempObj = {};
            tempObj['id'] = property;
            tempObj['Logs'] = response.data.body[property];
            tempArray.push(tempObj);
          }
          setApiResponseData(tempArray);
          setApiLoading(false);
        })
        .catch((err) => {
          setApiError(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setApiLoading(false);
        });
    }
    return () => {
      setApiResponseData([]);
    };
  }, [selectedApplicationName, dispatch]);

  useEffect(() => {
    setSelectedApplicationName(applicationName);

    isMounted.current = true;

    dispatch({
      type: CLOUD_EYE_APPLICATION_NAME,
      payload: applicationName,
    });
  }, []);

  return (
    <Row>
      <Col md={12} className='px-3'>
        <MenuTabs applicationName={selectedApplicationName} />
        <Row>
          {selectedApplicationName === '' && (
            <Col
              md={12}
              className='d-flex justify-content-center align-items-center '
            >
              Please select the application
            </Col>
          )}
          {selectedApplicationName !== '' && (
            <>
              <Col md={12} className='mb-3'>
                <div className='custom-card mb-4'>
                  {apiLoading && (
                    <Row>
                      <Col>
                        <SpinnerLoader />
                      </Col>
                    </Row>
                  )}

                  {apiLoading && apiError !== null && (
                    <Row>
                      <Col>
                        <DangerAlert message={apiError} />
                      </Col>
                    </Row>
                  )}

                  {apiLoading === false && apiError === null && (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={apiResponseData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={apiResponseData}
                          search
                        >
                          {(toolkitProps) => (
                            <>
                              <Row className='d-flex align-items-center'>
                                <Col md={7} className='mb-2'>
                                  <div className='subtitle-text'>
                                    Cloudwatch Logs
                                  </div>
                                </Col>

                                {apiResponseData.length > 0 && (
                                  <>
                                    <Col md={3} className='mb-2'>
                                      <div className='search-input-group'>
                                        <i className='fa fa-search'></i>
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                          className='custom-search-input-form-control'
                                        />
                                      </div>
                                    </Col>
                                    <Col md={2} className='mb-2'>
                                      <Dropdown>
                                        <Dropdown.Toggle className='logs-action-dropdown w-100'>
                                          Actions
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='logs-action-dropdown-item'>
                                          <Dropdown.Item
                                            onClick={() => {
                                              setShowSideCanvas('View Alarm');
                                            }}
                                          >
                                            View Alarm
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => {
                                              setShowSideCanvas(
                                                'Filter Pattern'
                                              );
                                            }}
                                          >
                                            Filter Pattern
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => {
                                              setShowSideCanvas('Set Alarm');
                                            }}
                                          >
                                            Set Alarm
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </Col>
                                  </>
                                )}
                              </Row>

                              <>
                                <Row>
                                  <Col md={12} xl={12} className='mt-4'>
                                    <div className='table-responsive'>
                                      <BootstrapTable
                                        keyField={'id'}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          'table align-middle table-nowrap'
                                        }
                                        headerWrapperClasses={
                                          'custom-table-head custom-pointer'
                                        }
                                        bodyClasses={'custom-table-body'}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                  <Col>
                                    {apiResponseData.length === 0 && (
                                      <div className='custom-error-message text-center'>
                                        No Data to Display
                                      </div>
                                    )}
                                  </Col>
                                </Row>

                                <Row className='align-items-md-center mt-3'>
                                  <Col className='d-flex flex-wrap justify-content-between align-items-baseline'>
                                    <div>
                                      <PaginationTotalStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div>
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            </>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}

                  {/* View Alarm */}
                  {showSideCanvas === 'View Alarm' && (
                    <ViewAlarm
                      application={selectedApplicationName}
                      resource='CWLogGroups'
                      closeSideCanvasParent={closeSideCanvasCallback}
                    />
                  )}

                  {/* Filter Pattern */}
                  {showSideCanvas === 'Filter Pattern' && (
                    <FilterPattern
                      application={selectedApplicationName}
                      instid={apiResponseData}
                      closeSideCanvasParent={closeSideCanvasCallback}
                    />
                  )}

                  {/* Set Alarm */}
                  {showSideCanvas === 'Set Alarm' && (
                    <SetAlarm
                      application={selectedApplicationName}
                      tableData={apiResponseData}
                      closeSideCanvasParent={closeSideCanvasCallback}
                    />
                  )}
                </div>
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default Logs;
