import React from 'react';

// Import Internal Components
import AccountContact from './AccountContact';
import BillingContact from './BillingContact';
import SecurityContact from './SecurityContact';
import TechnicalContact from './TechnicalContact';
import BillingInfo from './BillingInfo';
import GroupEmailAddress from './GroupEmailAddress';
import AccountDetails from './AccountDetails';

const FormSteps = (step, setStep, formData, setFormData) => 
[
  {
    title: 'Subscription Details',
    content: (
      <AccountDetails
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
      />
    ),
  },
  {
    title: 'Subscription Contact',
    content: (
      <AccountContact
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
      />
    ),
  },
  {
    title: 'Security Contact',
    content: (
      <SecurityContact
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
      />
    ),
  },
  {
    title: 'Technical Contact',
    content: (
      <TechnicalContact
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
      />
    ),
  },
  {
    title: 'Billing Info',
    content: (
      <BillingInfo
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
      />
    ),
  },
  
];

export default FormSteps;
