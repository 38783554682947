import React from "react";
import { useTranslation } from "react-i18next";

export default function AdditionalFeaturesUser({ selAccounts, cloud }) {
  const { t } = useTranslation();
  return (
    <div className="custom-card h-100 d-flex flex-column justify-content-between">
      <div>
        <h5 className="dashboard-title mb-3">Additional Features</h5>
        <h6 className="dashboard-title mb-2">Billing Query</h6>

        <div>
          <p className="service-status-description my-0 mb-2">
            Users can raise a billing query by clicking on the button provided
            below
          </p>
        </div>
        <button
          className="teal-filled-btn float-end"
          // onClick={() => setShowCreateForm(true)}
        >
          Raise a Billing Query
        </button>
      </div>
      <div>
        <h6 className="dashboard-title mb-2">Savings Plan</h6>

        <div>
          <p className="service-status-description my-0 mb-2">
            Users can raise a billing query by clicking on the button provided
            below
          </p>
        </div>
        <button
          className="teal-filled-btn float-end"
          // onClick={() => setShowCreateForm(true)}
        >
          + Request for Savings Plan
        </button>
      </div>
    </div>
  );
}
