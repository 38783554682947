import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import CustomModal from 'components/Modal/CustomModal';
import TextInput from 'components/Forms/TextInput';

import Cancel from './Cancel';
import Submit from './Submit';

// Validator
import { roleAddRequestValidate } from './validator';

// Helper
import { getApiCall } from 'helper/getApiCall';
import { postApiCall } from 'helper/postApiCall';

//static data
import { customeRoleData, customeRoleDataValues } from 'static-contents/customRoleData';

// Roles
import {
  CS_Hub_AWS_Account_Request_Admin,
} from 'roles/roles';
const AddRequest = () => {
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // React Select Option
  const customStyles = {
    control: (base) => ({
      ...base,
      border: '1px solid red',
    }),
  };

  // Data Input Error
  const [dataInputError, setDataInputError] = useState({});

  // Account Number State & API State
  const [loadingDropdown, setLoadingDropdown] = useState(true);
  const [accountNumberNameData, setAccountNumberNameData] = useState([]);
  const [accountNumberData, setAccountNumberData] = useState('');
  const [accountNameData, setAccountNameData] = useState('');
  const [getAccountsApiError, setGetAccountsApiError] = useState(null);

  const [accountNumberArray, setaccountNumberArray] = useState([]);

  const [role, setRole] = useState('');
  const [roleValue, setRoleValue] = useState('');
  const [tagDescription, setTagDescription] = useState('');

  const [showCancel, setShowCancel] = useState(false);
  const [showSubmit, setshowSubmit] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancel(value);
  }, []);

  const closeModalSubmit = useCallback((value) => {
    setshowSubmit(value);
  }, []);

  const [formSubmitMessage, setFormSubmitMessage] = useState(null);
  const [errorMessgeSubmit, setErrorMessgeSubmit] = useState(null);

  // ID Token
  const idToken = `${userInfo.idToken}`;

  // Form Submit Variable
  const formApiUrl = `${process.env.REACT_APP_CUSTOM_ROLE_ADD}`;

  // Form Submit
  function handleFormSubmit(e) {
    e.preventDefault();
    // Validate
    const errors = roleAddRequestValidate({
      accountNumberData,
      accountNameData,
      tagDescription,
      role,
    });
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } 
    else {
      setLoadingDropdown(true);
      const currentEpochTime = Date.now();
      const formRequestBody = {
        accountnumber: `${accountNumberData}`,
        accountname: `${accountNameData}`,
        tag: `${tagDescription}`,
        Gid: `${userInfo?.preferred_username}`,
        requeststatus: 'Pending',
        submittedBy: `${userInfo?.email}`,
        uid: `u-${currentEpochTime}`,
        roleName: `${roleValue}`,
      };

      postApiCall(formApiUrl, idToken, formRequestBody)
        .then((response) => {
          if (response.data?.error) {
            setFormSubmitMessage(response.data?.error);
            setErrorMessgeSubmit(true)
          } else {
            setFormSubmitMessage(null);
            setErrorMessgeSubmit(false)
          }
          setshowSubmit(true);
          setLoadingDropdown(false);
        })
        .catch((err) => {
          setFormSubmitMessage(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setshowSubmit(true);
          setLoadingDropdown(false);
        });
    }
  }

  // API Variables
  const apiUrl = `${process.env.REACT_APP_CUSTOM_ROLE_ACCOUNT_NUMBER_NAME}`;

  // Account Number Useeffect
  useEffect(() => {
    setGetAccountsApiError(null);
    setLoadingDropdown(true);
    setAccountNumberNameData([]);

    const requestBody = {
      "email": `${userInfo?.email}`,
      "user_type": `${userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? "admin" : "user"}`
    }

    postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        setAccountNumberNameData(response.data);

        const accountNumberOptions = response.data
          .filter((f) => f !== '')
          .map((d) => ({
            value: d.split(' - ')[0],
            label: d.split(' - ')[0],
          }));

        setaccountNumberArray(accountNumberOptions);

        setLoadingDropdown(false);
      })
      .catch((err) => {
        setLoadingDropdown(false);
        setGetAccountsApiError(
          'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
        );
      });

    return () => {
      setAccountNumberNameData([]);
    };
  }, []);
  const {t} = useTranslation();

  const handleChange = (e) => {
    setTagDescription(e.target.value)
    if(e.target.value?.length > 10){
      dataInputError.v_tagDescription = "it allows only limited character"
    } else {
      dataInputError.v_tagDescription = null
    }
  }

  return (
    <>
      <Row>
        <Col className='mb-3'>
          <h5 className='page-content-title'>
            {t("request_custom_role_creation")}
          </h5>
        </Col>
      </Row>
      {loadingDropdown && (
        <Row>
          <Col>
            <div className='d-flex justify-content-center'>
              <SpinnerLoader />
            </div>
          </Col>
        </Row>
      )}
      {loadingDropdown === false && getAccountsApiError === null && (
        <Form onSubmit={handleFormSubmit}>
          <Row>
            <Col md={6} className='mt-2'>
              <Form.Group className='mb-3' controlId='selectAccountNo'>
                <Form.Label>{t("select_account_octa")}</Form.Label>
                <Select
                  name='account number'
                  placeholder='Account Number'
                  searchable={true}
                  value={{ label: accountNumberData }}
                  isClearable={true}
                  onChange={(value) => {
                    const accNum = value !== null ? value.value : '';
                    setAccountNumberData(accNum);

                    if (value !== null) {
                      for (let i = 0; i < accountNumberNameData.length; i++) {
                        let accNumName = accountNumberNameData[i];
                        let splited = accNumName.split(' - ')[0];
                        if (splited === accNum) {
                          let splitedAccName = accNumName.split(' - ')[1];
                          setAccountNameData(splitedAccName);
                        }
                      }
                    } else {
                      setAccountNameData('');
                    }
                  }}
                  options={accountNumberArray}
                  styles={dataInputError.v_accountNumberData && customStyles}
                />

                <p className='custom-error-message'>
                  {dataInputError.v_accountNumberData}
                </p>
              </Form.Group>
            </Col>
  {/* <-- newly added field role selection --> */}

            <Col md={6} className='mt-2 '>
              <Form.Group className='mb-3' controlId='selectAccountNo'>
                <Form.Label>{t("Role")}</Form.Label>
                <Select
                  name='Role'
                  placeholder='Role'
                  searchable={true}
                  value={{ label: role }}
                  isClearable={true}
                  onChange={(value) => {
                    const roleDesc = value !== null ? value.value : '';
                    const roleValue = customeRoleDataValues.find(item => item?.label === roleDesc)
                    setRole(roleDesc);
                    setRoleValue(roleValue?.value)
                  }}
                  options={customeRoleData}
                  styles={dataInputError.v_role && customStyles}
                />

                <p className='custom-error-message'>
                  {dataInputError.v_role}
                </p>
              </Form.Group>
            </Col>
            
{/* --- end ---  */}
          </Row>
          <Row>
            <Col md={6} className='mt-2'>
              <Form.Group className='mb-3' controlId='selectAccountName'>
                <Form.Label>{t("account_name")}</Form.Label>

                <Form.Control
                  type='text'
                  readOnly
                  placeholder={t("account_name")}
                  value={accountNameData}
                  isInvalid={dataInputError.v_accountNameData ? true : false}
                />

                <Form.Control.Feedback type='invalid'>
                  {dataInputError.v_accountNameData}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
     
            <Col md={6}>
              <TextInput
                label={t("Purpose")}
                placeholder={t("Purpose")}
                value={tagDescription}
                onChange={(e) => handleChange(e)}
                isInvalid={dataInputError.v_tagDescription ? true : false}
                invalidMessage={dataInputError.v_tagDescription}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
             { 
             accountNumberData && accountNameData && tagDescription && role 
             ? 
             <p className="account-name-change-text text-start mb-1"><span  className='font--bold text-dark note-text'>{t("Note")}:</span><span>{t("The policy with name ")}<b>{`"${roleValue}"`}</b>{t(" will be created with all deny permissions in the beginning.")}</span> <br></br> <span>{t("Admin of the respective account needs to update the policy with the required permission set.")}</span>
             <br></br>
             <br></br>
             <span>{t("To add required permission sets for")}  <b>{`"${roleValue}"`}</b> {t("roles, kindly follow the below steps")} : </span>
             <br></br>
             <span className="ms-1">{t("1. Login to account as administrator (using AWS SSO)")}</span>  <br></br>
             <span className="ms-1">{t("2. Go to IAM >> Policies, look far path /customsso/v1/")}</span>  <br></br>
             <span className="ms-1">{t("3. Policy name to look for is - ")}<b>{`"${roleValue}"`}</b></span>  <br></br>
             <span className="ms-1">{t("4. Add the required permission set and save")}</span>
             </p>
              : 
              null
               }
            </Col>
          </Row>

          <Row>
            <Col md={4} className='d-flex flex-row mt-3'>
              <ul className='btn-ul'>
                <li>
                  <button
                    type='button'
                    className='teal-bordered-btn'
                    onClick={() => {
                      setShowCancel(true);
                    }}
                  >
                    {t("cancel")}
                  </button>
                </li>
                <li>
                  <button type='submit' className='teal-filled-btn'>
                    {t("submit")}
                  </button>
                </li>
              </ul>
            </Col>
          </Row>
        </Form>
      )}

      {showCancel && (
        <CustomModal
          showModal={true}
          children={<Cancel closeModalParent={closeModalCallback} />}
        />
      )}

      {showSubmit && (
        <CustomModal
          showModal={true}
          children={<Submit message={formSubmitMessage} errorMessgeSubmit={errorMessgeSubmit} closeModalParent={closeModalSubmit}/>}
        />
      )}
    </>
  );
};

export default AddRequest;
