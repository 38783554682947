import { useSelector } from "react-redux";

export function Authority({ name, ...props }) {
  const {
    userInfo: { groups },
  } = useSelector((state) => state.user);

  if (!groups.length) {
    return null;
  }

  if (!name) {
    throw Error("You  must provide a name to check for authority");
  }

  let apps = [];

  if (props.apps) {
    apps = props.apps || [];
  } else {
    apps = groups;
  }

  const selectedAppPermission = apps?.find((item) => item.includes(name));
  const isSelectedAppPermissionReadOnly =
    selectedAppPermission.includes(":ReadOnly");
  return isSelectedAppPermissionReadOnly ? null : props.children;
}
