import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

// Import Constant
import { USER_INFO } from "redux/user/userConstant";
import BillingAdmin from "./BillingAdmin";
import BillingUser from "./BiilingUser";
import {
  CS_Hub_AWS_Account_Request_Admin,
  CS_Hub_GCS_Chargeback_Admins,
} from "roles/roles";
import "../billings.scss";

const AWSBillings = ({ history }) => {
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch, history]);
  return (
    <>
      {userInfo !== null &&
        authState &&
        authState.isAuthenticated &&
        userInfo.groups.includes(CS_Hub_GCS_Chargeback_Admins) && (
          <BillingAdmin />
          // : (
          //   <BillingUser />
          // )
         /*: (*/)}
    </>
  );
};

export default AWSBillings;
