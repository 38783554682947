import React from 'react';

// Import Internal Component
import EditForm from './Edit/EditForm';

// Import Icons
import backicon from 'icons/back.svg';

const AccountRequestsEdit = ({ data, cloudTitle, closeEditParent }) => {
  console.log('aaaaaaaaaaa edit', data)
  return (
    <div className='view-edit-box-conatiner'>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => closeEditParent(false)}
          />
          <h5 className='view-edit-box-title'>{cloudTitle} Subscription Details</h5>
          {(data.requestStatus === 'Approved' ||
            data.requestStatus === 'Update Declined') && (
            <span className='view-edit-box-accountid mx-3'>
              Subscription ID: {data.azure_account_id}
            </span>
          )}
        </div>
      </div>
      <div className='table-edit-box-content px-1 mt-4 mb-5'>
        <EditForm data={data} />
      </div>
    </div>
  );
};

export default AccountRequestsEdit;
