import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const { SearchBar } = Search;

export default function Table({
  data,
  columns = [],
  text = "",
  idField = "",
  sizePerPage = 10,
}) {
  const {t} = useTranslation()
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );

  const pageOptions = {
    sizePerPage,
    totalSize: data.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  return (
    <PaginationProvider
      pagination={paginationFactory(pageOptions)}
      keyField={idField}
      columns={columns}
      data={data}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField={idField}
          columns={columns}
          data={data}
          search
        >
          {(toolkitProps) => (
            <>
              <Row className="d-flex align-items-center">
                <Col md={9} className="mb-2">
                  <div className="subtitle-text">
                    <span className="text-uppercase"></span>
                    {text}
                  </div>
                </Col>

                {data.length > 0 && (
                  <>
                    <Col md={3} className="mb-2">
                      <div className="search-input-group">
                        <i className="fa fa-search"></i>
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder={t("search_placeholder")}
                          className="custom-search-input-form-control"
                        />
                      </div>
                    </Col>
                  </>
                )}
              </Row>

              <>
                <Row>
                  <Col md={12} xl={12} className="mt-4">
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField={{ idField }}
                        responsive
                        bordered={false}
                        striped={false}
                        // defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={
                          "custom-table-head custom-pointer"
                        }
                        bodyClasses={"custom-table-body"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  </Col>
                  <Col>
                    {data.length === 0 && (
                      <div className="custom-error-message text-center">
                        No Data to Display
                      </div>
                    )}
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-3">
                  <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                    <div>
                      <PaginationTotalStandalone {...paginationProps} />
                    </div>
                    <div>
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </>
            </>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>
  );
}
