import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  SELECTED_MONTH,
  SORTING_BOOLEAN,
} from "redux/billing/EditableTableConstant";

const MonthSelection = () => {
  const billingStateValues = useSelector((state) => state.billing);
  const dispatch = useDispatch();
  const [isMonthChecked, setMonthChecked] = React.useState(
    billingStateValues.selectedMonth
  );
  const handleChange = (e) => {
    setMonthChecked(Number(e.target.value));
    dispatch({
      type: SELECTED_MONTH,
      payload: Number(e.target.value),
    });
    const sortingValues = {
      columnId: null,
      bool: undefined,
    };
    dispatch({
      type: SORTING_BOOLEAN,
      payload: sortingValues,
    });
  };
  return (
    <>
      {/* <Row> */}
      {/* <Col md={12}>
        <p className="form-title-p mb-2 p-3">Select Month</p>
      </Col> */}
      {/* <span className="form-title-span">
      Scan the targets and compare their installed patches against
      a list of approved patches in the patch baseline. Select to
      scan or to scan and install missing patches.
    </span> */}
      {/* <Col md={12} className="d-flex"> */}
      <Col md={2} className="p-1 month-selection-radio">
        <Form.Check
          type="radio"
          label={`Current month`}
          id={`Current-month `}
          checked={isMonthChecked === 0}
          value={0}
          onChange={(e) => handleChange(e)}
          className="cursor-pointer-selection"
        />
      </Col>
      <Col md={3} className="p-1  month-selection-radio">
        <Form.Check
          type="radio"
          label={`Previous month`}
          id={`default-radio`}
          checked={isMonthChecked === 1}
          value={1}
          onChange={(e) => handleChange(e)}
          className="cursor-pointer-selection"
        />
      </Col>
      {/* <Col md={4} className="p-1  month-selection-radio">
          <Form.Check
            type="radio"
            label={`Last two months`}
            id={`default-radio-two`}
            checked={isMonthChecked === 2}
            value={2}
            onChange={(e) => handleChange(e)}
          />
        </Col> */}
      {/* <Col md={2} /> */}

      {/* </Row> */}
    </>
  );
};

export default MonthSelection;
