import React from 'react';
import { Link } from 'react-router-dom';

const ModalSubmit = ({ message }) => {
  return (
    <div className='d-flex justify-content-center flex-column'>
      <p>{message}</p>
      <ul className='btn-ul'>
        <li>
          <button type='button' className='teal-filled-btn'>
            <Link to='/dashboard'>Ok</Link>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ModalSubmit;
