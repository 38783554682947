import React, { useReducer, useEffect, useState } from 'react';
import { AWSReducer, DefaultAWSData } from './edit-aws-reducer';

import EditFormSteps from 'pages/AccountSearch/AWS/Edit/EditFormSteps';

const AWSEdit = ({ data }) => {
  let [step, setStep] = useState(0);
  const [formData, setFormData] = useReducer(AWSReducer, DefaultAWSData);
  let steps = EditFormSteps(step, setStep, formData, setFormData);

  useEffect(() => {
    // Account Contact
    setFormData({
      type: 'UPDATE_ACCOUNT_CONTACT',
      payload: {
        [`firstNameAO`]: data.firstNameAO,
        [`lastNameAO`]: data.lastNameAO,
        [`emailAO`]: data.emailAO,
        [`countryAO`]: data.countryAO,
        [`phoneNoAO`]: data.phoneNoAO,
      },
    });
    // Billing Contact
    setFormData({
      type: 'UPDATE_BILLING_CONTACT',
      payload: {
        [`firstNameBC`]: data.firstNameBC,
        [`lastNameBC`]: data.lastNameBC,
        [`emailBC`]: data.emailBC,
        [`countryBC`]: data.countryBC,
        [`phoneNoBC`]: data.phoneNoBC,
      },
    });
    // Security Contact
    setFormData({
      type: 'UPDATE_SECURITY_CONTACT',
      payload: {
        [`firstNameSC`]: data.firstNameSC,
        [`lastNameSC`]: data.lastNameSC,
        [`emailSC`]: data.emailSC,
        [`countrySC`]: data.countrySC,
        [`phoneNoSC`]: data.phoneNoSC,
      },
    });
    // Technical Contact
    setFormData({
      type: 'UPDATE_TECHNICAL_CONTACT',
      payload: {
        [`firstNameTC`]: data.firstNameTC,
        [`lastNameTC`]: data.lastNameTC,
        [`emailTC`]: data.emailTC,
        [`countryTC`]: data.countryTC,
        [`phoneNoTC`]: data.phoneNoTC,
      },
    });
    // Billing Info
    setFormData({
      type: 'UPDATE_BILLING_INFO',
      payload: {
        [`billingopco`]: data.billingopco ? data.billingopco : '',
        [`billingSubOpco`]: data.billingSubOpco ? data.billingSubOpco : '',
        [`billingDepartment`]: data.billingDepartment
          ? data.billingDepartment
          : '',
        [`billingRegion`]: data.billingRegion,
        [`maxSpend`]: data.maxSpend,
        [`billto`]: data.billto,
        [`io`]: data.io ? data.io : 'N/A',
        [`costcenter`]: data.costcenter ? data.costcenter : 'N/A',
      },
    });
    // Group Email Address
    setFormData({
      type: 'UPDATE_GROUP_EMAIL_ADDRESS',
      payload: {
        [`technicalGroup`]: data.technicalGroup,
        [`securityGroup`]: data.securityGroup,
        [`billingGroup`]: data.billingGroup,
        [`pgcsgoldenami`]: (data.pgcsgoldenami?.toLowerCase() == "no" || data.pgcsgoldenami?.toLowerCase() == "false") ? "No" : "Yes" ,
        [`ptm`]: (data.ptm?.toLowerCase() == "no" || data.ptm?.toLowerCase() == "false") ? "No" : "Yes" ,
        [`conformity`]: (data.conformity?.toLowerCase() == "no" || data.conformity?.toLowerCase() == "false") ? "No" : "Yes",
        [`sonyad`]: (data.sonyad?.toLowerCase() == "no" || data.sonyad?.toLowerCase() == "false") ? "No" : "Yes",
      },
    });
    // Account Details
    setFormData({
      type: 'UPDATE_ACCOUNT_DETAILS',
      payload: {
        [`opco`]: data.opco ? data.opco : '',
        [`subopco`]: data.subopco ? data.subopco : '',
        [`department`]: data.department ? data.department : '',
        [`environmenttype`]: data.environmenttype,
        [`assetUserBase`]: data.assetUserBase,
        [`dataClassification`]: data.dataClassification,
        [`purposeid`]: data.purposeid,
        [`accountdescription`]: data.accountdescription,
      },
    });

    setFormData({
      type: 'UPDATE_COMMON_DETAILS',
      payload: { [`accountnumber`]: data.accountnumber },
    });
    setFormData({
      type: 'UPDATE_COMMON_DETAILS',
      payload: { [`accountname`]: data.accountname },
    });
    setFormData({
      type: 'UPDATE_COMMON_DETAILS',
      payload: { [`accstatus`]: data.accstatus },
    });
  }, []);

  return <>{steps[`${step}`].content}</>;
};

export default AWSEdit;
