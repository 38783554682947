import React from "react";
import { Spinner } from "react-bootstrap";

const SpinnerLoader = ({ sm = null }) => {
  return (
    <div className={`d-flex justify-content-center ${sm && "fs-11"}`}>
      {sm ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <Spinner animation="border" />
      )}
    </div>
  );
};

export default SpinnerLoader;
