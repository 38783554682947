import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';

import {
  CS_Hub_AWS_Account_Request_Admin,
  // Everyone_Role,
  // CS_Hub_AWS_Account_CIDR_Security,
} from 'roles/roles';

const CIDRDeclineBox = ({
  show,
  uid,
  declineNoParent,
  userInfo,
  declinedParent,
  cidrServiceType,
}) => {
  // ! Modal Show State
  const [showDeclineModal, setShowDeclineModal] = useState(show);

  // ! Decline Yes Loading State
  const [declineLoading, setDeclineLoading] = useState(false);

  // ! Decline Success State
  const [declineSuccess, setDeclineSuccess] = useState(false);

  // ! Decline Yes Function
  function declineRequest() {
    setDeclineLoading(true);
    const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

    if (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: setIdToken,
        },
      };

      const apiUrl = `${process.env.REACT_APP_AZURE_CIDR_REQUEST_MODIFY}`;
      axios
        .post(
          `${apiUrl}?uid=${uid}&type=0&admingid=${userInfo.preferred_username}&adminemail=${userInfo.email}`,
          {},
          config
        )
        .then((response) => {
          setDeclineLoading(false);
          setDeclineSuccess(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <>
      {declineSuccess === false && (
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={showDeclineModal}
        >
          <Modal.Body className='text-center'>
            <p>
              <i className='bx bxs-check-circle cidr-check-mark'></i>
            </p>
            <p className='cidr-modal-success-msg'>
              Are you sure you want to decline this request?
            </p>

            {declineLoading ? (
              <Spinner animation='border' />
            ) : (
              <p>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    declineNoParent(false);
                  }}
                >
                  No
                </button>
                <button
                  type='button'
                  className='mx-3 teal-filled-btn'
                  onClick={() => {
                    declineRequest();
                  }}
                >
                  Yes
                </button>
              </p>
            )}
          </Modal.Body>
        </Modal>
      )}

      {declineSuccess && (
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={showDeclineModal}
        >
          <Modal.Body className='text-center'>
            <p>
              <i className='bx bxs-check-circle cidr-check-mark'></i>
            </p>
            <p className='cidr-modal-success-msg'>Request Declined!</p>

            <p>
              <button
                type='button'
                className='mx-3 teal-filled-btn'
                onClick={() => {
                  setDeclineSuccess(false);
                  setShowDeclineModal(false);
                  declinedParent(true);
                }}
              >
                ok
              </button>
            </p>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default CIDRDeclineBox;
