import React from "react";
import { useTranslation } from "react-i18next";
import { useOktaAuth } from "@okta/okta-react";
import { Col, Form, Row } from "react-bootstrap";

const InvoiceGeneraionModal = ({
  handleChange,
  setShowInvoiceGeneration = () => {},
  handleGenerateInvoice,
  isMonthChecked,
}) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-center flex-column">
      <p>{t("Invoice will be generated for current month")}</p>
      <div className="text-center">{/* Generate invoice for */}</div>
      <div onClick={() => handleChange("M", 0)}>
        {/* <Row>
          <Col md={12} sm={12} className="p-1 month-selection-radio">
            <Form.Check
              type="radio"
              label={`current month`}
              id={`generate-current-month`}
              checked={isMonthChecked === 0}
              value={0}
              onClick={(e) => handleChange(e)}
              className="cursor-pointer-selection text-center ps-0"
            />
          </Col>
        </Row> */}
      </div>
      <ul className="px-0 btn-ul mb-0 justify-content-center">
        <li>
          <button
            type="button"
            className="teal-bordered-btn"
            onClick={() => {
              setShowInvoiceGeneration(false);
            }}
          >
            {t("cancel")}
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={(e) => {
              handleGenerateInvoice("currentMonth", null);
            }}
            className="teal-filled-btn"
          >
            {t("Proceed")}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default InvoiceGeneraionModal;
