import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Modal, Spinner, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import Loader from 'components/Common/Loader';

// Data
import { awsRegionData } from 'pages/CIDR/region-data';
import { ipData } from 'pages/CIDR/ip-data';

// Validator
import { cidrValidator } from './cidrValidator';
import { t } from 'i18next';

const NewRequest = ({ history }) => {
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [apiError, setApiError] = useState(null);

  // Set Region Based on Service Type
  const [serviceBasedRegion, setServiceBasedRegion] = useState(awsRegionData);

  // Account Number & Account Name API Data State
  const [loadingDropdown, setLoadingDropdown] = useState(true);
  const [accountNumberNameData, setAccountNumberNameData] = useState([]);
  const [accountNumberData, setAccountNumberData] = useState('');
  const [accountNameData, setAccountNameData] = useState('');

  // Component State
  const [region, setRegion] = useState('');
  const [ip, setIp] = useState('');

  // Data Input Error
  const [dataInputError, setDataInputError] = useState({});

  // New Request Success
  const [newRequestLoading, setNewRequestLoading] = useState(false);
  const [newRequestAdded, setNewRequestAdded] = useState(false);
  const [newRequestUid, setNewRequestUid] = useState('');

  // Cancel New Request
  const [cancelNewRequest, setCancelNewRequest] = useState(false);

  // New Request Form Submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    cidrNewRequest({
      ip: ip,
      region: region,
      accountNumber: accountNumberData,
      accountName: accountNameData,
      requestStatus: 'Pending',
      submittedBy: userInfo.preferred_username,
      userNumber: userInfo.preferred_username,
      uid: `u-${Date.now()}`,
    });
  };

  async function cidrNewRequest(cidrNewRequestData) {
    setApiError(null);
    setDataInputError({});
    const { ip, region, accountNumber, accountName, submittedBy, uid } =
      cidrNewRequestData;

    const { valid, inputError } = cidrValidator(
      ip,
      region,
      accountNumber,
      accountName,
      submittedBy
    );

    if (!valid) {
      setDataInputError(inputError);
    } else {
      setNewRequestLoading(true);
      const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: setIdToken,
        },
      };
      const uidValue = Date.now();
      const apiUrl = `${process.env.REACT_APP_AWS_CIDR_ADD_REQUEST}`;
      axios
        .post(
          apiUrl,
          {
            ip: ip,
            region: region,
            accountNumber: accountNumberData,
            accountName: accountNameData,
            requestStatus: 'Pending',
            submittedBy: userInfo.preferred_username,
            userNumber: userInfo.preferred_username,
            uid: `u-${uidValue}`,
          },
          config
        )
        .then((response) => {
          setNewRequestLoading(false);
          setNewRequestAdded(true);
          setNewRequestUid(uidValue);
        })
        .catch((err) => {
          setNewRequestLoading(false);
          setApiError(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
        });
    }
  }

  const [accountNumberArray, setaccountNumberArray] = useState([]);
  const {t}=useTranslation();

  useEffect(() => {
    setApiError(null);
    setLoadingDropdown(true);
    setAccountNumberNameData([]);
    const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: setIdToken,
      },
    };
    const accNumNameApiUrl = `${process.env.REACT_APP_AWS_CIDR_ADD_REQUEST_ACCOUNT_NUMBER_NAME}`;
    axios
      .get(accNumNameApiUrl, config)
      .then((response) => {
        setAccountNumberNameData(response.data);

        const accountNumberOptions = response.data.map((d) => ({
          value: d.split(' - ')[0],
          label: d.split(' - ')[0],
        }));

        setaccountNumberArray(accountNumberOptions);

        setLoadingDropdown(false);
      })
      .catch((err) => {
        setLoadingDropdown(false);
        setApiError(
          'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
        );
      });

    return () => {
      setAccountNumberNameData([]);
    };
  }, [userInfo.idToken]);

  const customStyles = {
    control: (base) => ({
      ...base,
      border: '1px solid red',
    }),
  };

  return (
    <div className='mt-4'>
      <Row>
        <h5 className='cidr-new-request-heading'>
          {t('request_cidr_block')}
        </h5>
      </Row>
      {loadingDropdown && (
        <Row>
          <Col>
            <div className='d-flex justify-content-center'>
              <Loader />
            </div>
          </Col>
        </Row>
      )}
      {loadingDropdown === false && (
        <>
          <Form onSubmit={handleFormSubmit}>
            <Row className='mt-5'>
              <Col md={6} className='mt-3'>
                <Form.Group className='mb-3' controlId='selectAccountNo'>
                  <Form.Label>{t("select_account")}</Form.Label>

                  {/* <Form.Select
                    value={accountNumberData}
                    onChange={accountNumberHandler}
                    isInvalid={dataInputError.v_accountNumber ? true : false}
                  >
                    <option value=''>Account Number</option>
                    {accountNumberNameData.map((data, index) => (
                      <option key={index} value={data.split(' - ')[0]}>
                        {data.split(' - ')[0]}
                      </option>
                    ))}
                  </Form.Select> */}

                  <Select
                    name='account number'
                    placeholder='Account Number'
                    searchable={true}
                    value={{ label: accountNumberData }}
                    isClearable={true}
                    onChange={(value) => {
                      const accNum = value !== null ? value.value : '';
                      setAccountNumberData(accNum);

                      if (value !== null) {
                        for (let i = 0; i < accountNumberNameData.length; i++) {
                          let accNumName = accountNumberNameData[i];
                          let splited = accNumName.split(' - ')[0];
                          if (splited === accNum) {
                            let splitedAccName = accNumName.split(' - ')[1];
                            setAccountNameData(splitedAccName);
                          }
                        }
                      } else {
                        setAccountNameData('');
                      }
                    }}
                    options={accountNumberArray}
                    styles={dataInputError.v_accountNumber && customStyles}
                  />

                  <p className='custom-error-message'>
                    {dataInputError.v_accountNumber}
                  </p>
                </Form.Group>
              </Col>
              <Col md={6} className='mt-3'>
                <Form.Group className='mb-3' controlId='selectRegion'>
                  <Form.Label>{t('select_region')}</Form.Label>

                  <Form.Select
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                    isInvalid={dataInputError.v_region ? true : false}
                  >
                    <option value=''>{t("placeholder_none")}</option>
                    {serviceBasedRegion.map((data, index) => (
                      <option key={index} value={data.value}>
                        {data.label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>
                    {dataInputError.v_region}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className='mt-3'>
                <Form.Group className='mb-3' controlId='selectAccountName'>
                  <Form.Label>{(t('account_name'))}</Form.Label>

                  <Form.Control
                    type='text'
                    readOnly
                    placeholder='Account Name'
                    value={accountNameData}
                    isInvalid={dataInputError.v_accountName ? true : false}
                  />

                  <Form.Control.Feedback type='invalid'>
                    {dataInputError.v_accountName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className='mt-3'>
                <Form.Group className='mb-3' controlId='selectIp'>
                  <Form.Label>
                    {"*"+t("numberof_ip's")}
                  </Form.Label>

                  <Form.Select
                    value={ip}
                    onChange={(e) => setIp(e.target.value)}
                    isInvalid={dataInputError.v_ip ? true : false}
                  >
                    <option value=''>{t("placeholder_none")}</option>
                    {ipData.map((data, index) => (
                      <option key={index} value={data.value}>
                        {data.label}
                      </option>
                    ))}
                  </Form.Select>

                  <Form.Control.Feedback type='invalid'>
                    {dataInputError.v_ip}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {newRequestLoading === false && apiError !== null && (
                <Col md={12}>
                  <Alert variant='danger'>{apiError}</Alert>
                </Col>
              )}
              <Col md={12} className='mt-3'>
                <div className='d-flex justify-content-end'>
                  {newRequestLoading ? (
                    <div className='mt-3'>
                      <Spinner animation='border' />
                    </div>
                  ) : (
                    <>
                      <div>
                        <button
                          type='button'
                          className='teal-bordered-btn'
                          onClick={() => setCancelNewRequest(true)}
                        >
                          {t('cancel')}
                        </button>
                      </div>
                      <div className='mx-2'>
                        <button type='submit' className='teal-filled-btn'>
                          {t('submit')}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Form>
          <Row className='mt-5'>
            <Col>
              <p className='cidr-note text-center'>
               {t('cidr_note')}
              </p>
            </Col>
          </Row>
        </>
      )}

      {/* Show Modal on Success New Request */}
      {newRequestAdded && (
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={newRequestAdded}
        >
          <Modal.Body className='text-center'>
            <p>
              <i className='bx bxs-check-circle cidr-check-mark'></i>
            </p>
            <p className='cidr-modal-success-msg'>
              {t('cidr_success_msg')}
            </p>
            <p className='cidr-modal-ref-msg'>
              {t('cidr_ref_msg')}
            </p>
            <p className='cidr-ref-number'>{newRequestUid}</p>
            <p>
              <button
                type='button'
                className='mx-3 teal-filled-btn'
                onClick={() => {
                  setNewRequestAdded(false);
                  setAccountNumberData('');
                  setAccountNameData('');
                  setRegion('');
                  setIp('');
                  history.push('/aws-cidr-view-requests');
                }}
              >
                {t('ok')}
              </button>
            </p>
          </Modal.Body>
        </Modal>
      )}

      {/* Cancel New Request Modal */}
      {cancelNewRequest && (
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={cancelNewRequest}
        >
          <Modal.Body className='text-center'>
            <p>
              <i className='bx bxs-check-circle cidr-check-mark'></i>
            </p>
            <p className='cidr-modal-success-msg'>
              {t("cidr_warning_msg")}
            </p>

            <p>
              <button
                type='button'
                className='teal-bordered-btn'
                onClick={() => {
                  setCancelNewRequest(false);
                }}
              >
                {t('no')}
              </button>
              <button
                type='button'
                className='mx-3 teal-filled-btn'
                onClick={() => {
                  history.push('/aws-cidr-view-requests');
                }}
              >
                {t('yes')}
              </button>
            </p>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default withRouter(NewRequest);
