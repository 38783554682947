export const GsidIoFormValidator = (values) => {
  const errors = {};
  let isEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    values["Billing Email"]
  );
  if (!values["Billing Email"]) {
    errors["Billing Email"] = "Email address is required";
  } else if (isEmail === false) {
    errors["Billing Email"] = "Email address is invalid";
  }

  if (!values["Billing First Name"]) {
    errors["Billing First Name"] = "First name is required";
  } else if (/^[a-zA-Z- ]*$/.test(values?.["Billing First Name"]) === false) {
    errors["Billing First Name"] = "Only alphabets & hyphens allowed";
  }

  if (!values["Billing Last Name"]) {
    errors["Billing Last Name"] = "Last name is required";
  } else if (/^[a-zA-Z- ]*$/.test(values?.["Billing Last Name"]) === false) {
    errors["Billing Last Name"] = "Only alphabets & hyphens allowed";
  }

  if (!values["Billing Region"]) {
    errors["Billing Region"] = "Region is required";
  }
  if (!values["Billing Department"]) {
    errors["Billing Department"] = "Department is required";
  }
  if (!values["Billing Sub OpCo"]) {
    errors["Billing Sub OpCo"] = "Sub opco is required";
  }
  if (!values["Billing Opco"]) {
    errors["Billing Opco"] = "Opco is required";
  }
  if (!values["GSID"]) {
    errors["GSID"] = "GSID is required";
  }
  if (!values["IO"]) {
    errors["IO"] = "IO is required";
  }

  return errors;
};

export const pamFormValidator = (values) => {
  const errors = {};
  if (!values["AWS Account No"]) {
    errors["AWS Account No"] = "AWS account no is required";
  }
  if (!values["Count of Server Name"]) {
    errors["Count of Server Name"] = "Count of server name is required";
  }
  if (!values["Sum of Unit Cost"]) {
    errors["Sum of Unit Cost"] = "Sum of unit cost is required";
  }

  return errors;
};

export const opsCostFormValidator = (values) => {
  const errors = {};
  if (!values["Account ID"]) {
    errors["Account ID"] = "Account id is required";
  }
  if (!values["GSID Tags"]) {
    errors["GSID Tags"] = "GSID tags is required";
  }
  if (!values["Vendor"]) {
    errors["Vendor"] = "Vendor is required";
  }
  if (!values["Account Name"]) {
    errors["Account Name"] = "Account name is required";
  }

  if (!values["Account Region"]) {
    errors["Account Region"] = "Account region is required";
  }

  if (!values["Ops Cost"]) {
    errors["Ops Cost"] = "Ops cost is required";
  }

  if (!values["IO Code/Cost Center"]) {
    errors["IO Code/Cost Center"] = "IO code/cost center is required";
  }

  if (!values["Remarks"]) {
    errors["Remarks"] = "Remarks is required";
  }

  return errors;
};
