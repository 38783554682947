import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Loader from "components/Common/Loader";
import EnableAccountFormMenu from "./EnableAccountFormMenu";

import CustomModal from "components/Modal/CustomModal";
import NewRequestCancel from "../NewRequestCancel";

// Static Data
import { dayOfWeek, hoursData, minutesData, weeksData } from "../../staticData";

import { PROVIDED_TIME } from "redux/aws-host-patch-management/patchAccountConstant";

// Validator
import { accountHostPatchValidate } from "../../../Validator/validator";
import FormProgress from "../FormProgress";

function InstallSchedule({
  step,
  setStep,
  formData,
  setFormData,
  handleEditWindowClose,
}) {
  const { t } = useTranslation();

  // Response Data State
  const [loading, setLoading] = useState(false);
  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  // Time Picker State
  const [startDay, setStartDay] = useState("");
  const [startWeek, setStartWeek] = useState("");
  const [dateCollections, setDateCollections] = useState([]);

  // Data Input Error
  const [dataInputError, setDataInputError] = useState({});

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);
  const handleContinue = (e) => {
    e.preventDefault();

    const errors = accountHostPatchValidate(formData.installatonSchedule);

    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setStep(step + 1);
    }
  };
  useEffect(() => {
    const dateArray = [];
    for (let i = 0; i <= 29; i++) {
      const date = moment().add(i, "day").format("MMM-DD-YYYY");
      dateArray.push(date);
    }
    setDateCollections(dateArray);
  }, []);
  const handleInputChange = (e) => {
    const { target } = e;
    setFormData({
      type: "UPDATE_INSTALLATION_SCHEDULE",
      payload: { [target.name]: target.value },
    });
  };

  const getOptionName = (data, dataArr) => {
    const getName = dataArr.find((item) => item.name === data);
    return getName?.value
      ?.toLowerCase()
      .replace(/(?:(^.)|(\s+.))/g, function (match) {
        return match.charAt(match.length - 1).toUpperCase();
      });
  };

  return (
    <>
      <Row>
        <Col md={12} xs={12}>
          <FormProgress gisAccountSelection />
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        {loading && <Loader />}
        {loading === false && (
          <Row className="install-schedule my-4">
            <div className="snooze-alarm-title ms-4 mb-4">
              Patch Installation Schedule
            </div>
            <div className="d-flex flex-row flex-wrap justify-content-center ">
              <Form.Group className="mb-3 px-1" controlId="week">
                <Form.Select
                  size="sm"
                  name="week"
                  className="snooze-alarm-time-select-date"
                  value={formData.installatonSchedule.week}
                  onChange={(e) => {
                    handleInputChange(e);
                    setStartWeek(e.target.value);
                  }}
                  isInvalid={dataInputError.week ? true : false}
                >
                  <option value="">Select Week of a month</option>
                  {weeksData.map((data, index) => (
                    <option key={index} value={data?.name}>
                      {data?.value}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback
                  type="invalid"
                  className="snooze-invalid-feedback"
                >
                  {dataInputError.week}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3 px-1" controlId="dow">
                <Form.Select
                  size="sm"
                  name="dow"
                  className="snooze-alarm-time-select-date"
                  value={formData.installatonSchedule.dow}
                  onChange={(e) => {
                    handleInputChange(e);
                    setStartDay(e.target.value);
                  }}
                  isInvalid={dataInputError.dow ? true : false}
                >
                  <option value="">Select Day of a week</option>
                  {dayOfWeek.map((data, index) => (
                    <option key={index} value={data?.name}>
                      {data?.value}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback
                  type="invalid"
                  className="snooze-invalid-feedback"
                >
                  {dataInputError.dow}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3 px-1" controlId="startTime">
                <Form.Select
                  size="sm"
                  name="startDateHour"
                  className="snooze-alarm-time-select"
                  value={formData.installatonSchedule.startDateHour}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  isInvalid={dataInputError.startDateHour ? true : false}
                >
                  <option value="">HH</option>
                  {hoursData.map((data, index) => (
                    <option key={index} value={data}>
                      {data}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback
                  type="invalid"
                  className="snooze-invalid-feedback"
                >
                  {dataInputError.startDateHour}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3 px-1" controlId="startTime">
                <Form.Select
                  size="sm"
                  name="startDateMinute"
                  className="snooze-alarm-time-select"
                  value={formData.installatonSchedule.startDateMinute}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  isInvalid={dataInputError.startDateMinute ? true : false}
                >
                  <option value="">MM</option>
                  {minutesData.map((data, index) => (
                    <option key={index} value={data}>
                      {data}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback
                  type="invalid"
                  className="snooze-invalid-feedback"
                >
                  {dataInputError.startDateMinute}
                </Form.Control.Feedback>
              </Form.Group>
              {/* <span className="install-schedule-utc pb-3">(UTC)</span> */}
            </div>
            <div className="fw-500 text-center">
              {formData.installatonSchedule.week
                ? getOptionName(formData.installatonSchedule.week, weeksData)
                : "______"}{" "}
              {formData.installatonSchedule.dow
                ? getOptionName(formData.installatonSchedule.dow, dayOfWeek)
                : "______"}{" "}
              of Every month @{" "}
              {formData.installatonSchedule.startDateHour
                ? formData.installatonSchedule.startDateHour
                : "00"}
              :
              {formData.installatonSchedule.startDateMinute
                ? formData.installatonSchedule.startDateMinute
                : "00"}{" "}
              UTC
            </div>
            <div className="col-md-12 mt-4">
              <ul className="px-0 btn-ul justify-content-center">
                <li>
                  <button
                    type="button"
                    className="teal-bordered-btn"
                    onClick={() => setStep(step - 1)}
                  >
                    {t("back")}
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="teal-bordered-btn"
                    onClick={() => {
                      setShowCancelModal(true);
                    }}
                  >
                    {t("cancel")}
                  </button>
                </li>
                <li>
                  <button type="submit" className="teal-filled-btn">
                    {t("save_continue")}
                  </button>
                </li>
              </ul>
            </div>
          </Row>
        )}
      </Form>
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={
            <NewRequestCancel
              closeModalParent={closeModalCallback}
              handleEditWindowClose={handleEditWindowClose}
            />
          }
        />
      )}
    </>
  );
}

export default InstallSchedule;
