import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next';

// Import Form Component
import TextInput from 'components/Forms/TextInput';

// Import Common Components
import Loader from 'components/Common/Loader';
import BillingCompanyDropdown from 'components/Forms/BillingCompanyDropdown';
import BillingSubCompanyDropdown from 'components/Forms/BillingSubCompanyDropdown';
import BillingDepartmentDropdown from 'components/Forms/BillingDepartmentDropdown';
import StaticDropdown from 'components/Forms/StaticDropdown';
import CustomModal from 'components/Modal/CustomModal';

// Import Internal Component
import EditRequestCancel from './EditRequestCancel';
import EditFormMenu from './EditFormMenu';
import EditRequestSubmit from './EditRequestSubmit';

// Helper
import { getApiCall } from 'helper/getApiCall';
import { postApiCall } from 'helper/postApiCall';

// Validator
import { billingInfoValidate } from '../../../Validator/validator';

// Static Data
import {
  staticbillingRegion,
  staticspend,
  staticbillto,
} from '../../StaticData/awsStaticData';

import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

const EditBillingInfo = ({ step, setStep, formData, setFormData }) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const {t} = useTranslation();
  
  // Set ID Token Globally
  const idToken = userInfo.idToken;

  // Form Submit State
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [formSubmitSuccessMsg, setFormSubmitSuccessMsg] = useState(null);

  // Response Data State
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [apiError, setApiError] = useState(null);

  // Component Form Data State
  const [billingSubOpCompany, setBillingSubOpCompany] = useState([]);
  const [billingDepartment, setBillingDepartment] = useState([]);
  // const [billTo, setbillTo] = useState(null);

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // handle onchange
  const handleData = ({ target }) => {
    if (target.name === 'billingopco') {
      setFormData({
        type: 'UPDATE_BILLING_INFO',
        payload: {
          [`billingSubOpco`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_BILLING_INFO',
        payload: {
          [`billingDepartment`]: '',
        },
      });
      setBillingSubOpCompany([]);
      setBillingDepartment([]);
      let opCompany = target.value;
      if (opCompany !== '') {
        const getSubOpCompany = responseData.find(
          (t) => t.opco === opCompany
        ).subopcod;
        setBillingSubOpCompany(getSubOpCompany);
      }
    }
    if (target.name === 'billingSubOpco') {
      setFormData({
        type: 'UPDATE_BILLING_INFO',
        payload: {
          [`billingDepartment`]: '',
        },
      });
      setBillingDepartment([]);
      let opSubCompany = target.value;
      if (opSubCompany !== '') {
        const getDepartment = billingSubOpCompany.find(
          (t) => t.subopco === opSubCompany
        ).dept;
        setBillingDepartment(getDepartment);
      }
    }

    if (target.name === 'io') {
      setFormData({
        type: 'UPDATE_BILLING_INFO',
        payload: {
          [`costcenter`]: 'NA',
        },
      });
    }

    if (target.name === 'costcenter') {
      setFormData({
        type: 'UPDATE_BILLING_INFO',
        payload: {
          [`io`]: 'NA',
        },
      });
    }

    setFormData({
      type: 'UPDATE_BILLING_INFO',
      payload: { [target.name]: target.value },
    });
  };

  const handleContinue = (e) => {
    e.preventDefault();
    const errors = billingInfoValidate(formData.billingInfo);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
       // Form Submission
        setFormSubmitLoading(true);
        const formSubmitApiUrl = `${process.env.REACT_APP_TREND_MICRO_AWS_ACCOUNT_REQUEST_NEW_REQUEST_SUBMIT}`;
        // Request body condition based on request status
        var updatedReqStatus;
        var updatedAccountName;
        if (
          formData.requestStatus === 'Approved' ||
          formData.requestStatus === 'Update Declined'
        ) {
          updatedReqStatus = 'Pending Approval for Update';
          updatedAccountName = formData.unEditableAccountName;
        }
  
        if (formData.requestStatus === 'Pending') {
          updatedReqStatus = 'Pending';
          updatedAccountName = formData.accountDetails.subscriptionname;
        }
        setLoading(true)
        postApiCall(formSubmitApiUrl, idToken, {
          firstNameAO: formData.accountContact.firstNameAO,
          lastNameAO: formData.accountContact.lastNameAO,
          emailAO: formData.accountContact.emailAO,
          countryAO: formData.accountContact.countryAO,
          phoneNoAO: formData.accountContact.phoneNoAO,

          firstNameBC: formData.billingContact.firstNameBC,
          lastNameBC: formData.billingContact.lastNameBC,
          emailBC: formData.billingContact.emailBC,
          countryBC: formData.billingContact.countryBC,
          phoneNoBC: formData.billingContact.phoneNoBC,

          firstNameSC: formData.securityContact.firstNameSC,
          lastNameSC: formData.securityContact.lastNameSC,
          emailSC: formData.securityContact.emailSC,
          countrySC: formData.securityContact.countrySC,
          phoneNoSC: formData.securityContact.phoneNoSC,

          firstNameTC: formData.technicalContact.firstNameTC,
          lastNameTC: formData.technicalContact.lastNameTC,
          emailTC: formData.technicalContact.emailTC,
          countryTC: formData.technicalContact.countryTC,
          phoneNoTC: formData.technicalContact.phoneNoTC,

          billingopco: formData.billingInfo.billingopco,
          billingSubOpco: formData.billingInfo.billingSubOpco,
          billingDepartment: formData.billingInfo.billingDepartment,
          billingRegion: formData.billingInfo.billingRegion,
          billto: formData.billingInfo.billto,
          io: formData.billingInfo.io,
          costcenter: formData.billingInfo.costcenter,

          subscriptionname: `${updatedAccountName}`,
          subscriptiondescription: formData.accountDetails.subscriptiondescription,
          subscriptionid: formData.accountDetails.subscriptionID,
          opco: formData.accountDetails.opco,
          subopco: formData.accountDetails.subopco,
          department: formData.accountDetails.department,
          environmenttype: formData.accountDetails.environmenttype,

          // assetUserBase: formData.accountDetails.assetUserBase,
          // dataClassification: formData.accountDetails.dataClassification,
          // purposeid: formData.accountDetails.purposeid,

          accountName: `${updatedAccountName}`,
          requestStatus: `${updatedReqStatus}`,
          uid: formData.uid,
          submittedBy: formData.submittedBy,
          azure_account_id: formData.azure_account_id,
          RequestActionTakenBy: formData.RequestActionTakenBy,
          type : 'Edit'
        }).then((response) => {
          if (response.data.message !== '') {
            const apiErrMsg = (response?.data?.message === 'Subscription already exists in Trend Micro Deep Security') ? 400 : 200
           // setNewRequestUid(`u-${currentEpochTime}`);
            setFormSubmitSuccess(true);
            setFormSubmitLoading(false);
            setFormSubmitSuccessMsg(apiErrMsg)
            setLoading(false)
          } else {
            setApiError(
              t("Failed to update the request, Contact Sony Global Cloud Team for Assistance")
            );
          }

        }).catch((err) => {
          setFormSubmitLoading(false);
          setLoading(false)
          setApiError(
            t("Failed to update the request, Contact Sony Global Cloud Team for Assistance")
          );
        });;
      }
  };

  useEffect(() => {
    setLoading(true);

    // adding query string fr oauth
    const queryObj = `{
      "submittedBy": "${userInfo?.email}",
      "type_of_user": ${userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? 1 : 0}
    }`
    const encodedString = Buffer.from(queryObj).toString('base64');
    // end

    const apiUrl = `${process.env.REACT_APP_AWS_ACCOUNT_REQUEST_BILLING_INFO}?enchash=${encodedString}`;
    const idToken = userInfo.idToken;
    getApiCall(apiUrl, idToken).then((response) => {
      setResponseData(response.data.body);
      let getSubOpCompany = [];
      if (formData.billingInfo.billingopco !== '') {
        setBillingSubOpCompany([]);
        setBillingDepartment([]);

        const checkOpco = response.data.body.find(
          (t) => t.opco === formData.billingInfo.billingopco
        );

        if (checkOpco === undefined) {
          setBillingSubOpCompany([]);
        } else {
          getSubOpCompany = response.data.body.find(
            (t) => t.opco === formData.billingInfo.billingopco
          ).subopcod;
          setBillingSubOpCompany(getSubOpCompany);
        }
      }
      if (formData.billingInfo.billingSubOpco !== '') {
        setBillingDepartment([]);

        const checkSubOpco = getSubOpCompany.find(
          (t) => t.subopco === formData.billingInfo.billingSubOpco
        );

        if (checkSubOpco === undefined) {
          setBillingDepartment([]);
        } else {
          const getDepartment = getSubOpCompany.find(
            (t) => t.subopco === formData.billingInfo.billingSubOpco
          ).dept;
          setBillingDepartment(getDepartment);
        }
      }
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Row>
        <Col md={12} xs={12}>
          <EditFormMenu billingInfo />
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        {loading && <Loader />}
        {loading === false && (
          <>
            <Row className='px-3'>
              <Col md={12}>
                <BillingCompanyDropdown
                  label='*Select Billing Operating Company:'
                  defaultSelectMessage='Select Operating Company'
                  name='billingopco'
                  value={formData.billingInfo.billingopco}
                  onChange={(e) => handleData(e)}
                  selectValues={responseData}
                  isInvalid={dataInputError.billingopco ? true : false}
                  invalidMessage={dataInputError.billingopco}
                />
              </Col>

              <Col md={12}>
                <BillingSubCompanyDropdown
                  label='*Billing Sub Operating Company:'
                  defaultSelectMessage='Select Sub Operating Company'
                  name='billingSubOpco'
                  value={formData.billingInfo.billingSubOpco}
                  onChange={(e) => handleData(e)}
                  selectValues={billingSubOpCompany}
                  isInvalid={dataInputError.billingSubOpco ? true : false}
                  invalidMessage={dataInputError.billingSubOpco}
                />
              </Col>

              <Col md={12}>
                <BillingDepartmentDropdown
                  label='*Billing Department:'
                  defaultSelectMessage='Select Department'
                  name='billingDepartment'
                  value={formData.billingInfo.billingDepartment}
                  onChange={(e) => handleData(e)}
                  selectValues={billingDepartment}
                  isInvalid={dataInputError.billingDepartment ? true : false}
                  invalidMessage={dataInputError.billingDepartment}
                />
              </Col>

              <Col md={12}>
                <StaticDropdown
                  label='*Billing Region:'
                  defaultSelectMessage='Select Region'
                  name='billingRegion'
                  value={formData.billingInfo.billingRegion}
                  onChange={(e) => handleData(e)}
                  selectValues={staticbillingRegion}
                  isInvalid={dataInputError.billingRegion ? true : false}
                  invalidMessage={dataInputError.billingRegion}
                />
              </Col>

              {/* <Col md={12}>
                <StaticDropdown
                  label='*Max Monthly Expected Spend in USD:'
                  defaultSelectMessage='Select Expected Spend'
                  name='maxSpend'
                  value={formData.billingInfo.maxSpend}
                  onChange={(e) => handleData(e)}
                  selectValues={staticspend}
                  isInvalid={dataInputError.maxSpend ? true : false}
                  invalidMessage={dataInputError.maxSpend}
                />
              </Col> */}

              <Col md={12}>
                <StaticDropdown
                  label='*Bill To:'
                  defaultSelectMessage='Select Bill To'
                  name='billto'
                  value={formData.billingInfo.billto}
                  onChange={(e) => handleData(e)}
                  selectValues={staticbillto}
                  isInvalid={dataInputError.billto ? true : false}
                  invalidMessage={dataInputError.billto}
                />
              </Col>

              {formData.billingInfo.billto === 'Global IS (GISC)' && (
                <Col md={12}>
                  <TextInput
                    label='*IO:'
                    name='io'
                    value={formData.billingInfo.io}
                    onChange={(e) => handleData(e)}
                    placeholder='IO'
                    isInvalid={dataInputError.io ? true : false}
                    invalidMessage={dataInputError.io}
                  />
                </Col>
              )}

              {formData.billingInfo.billto === 'Other Teams' && (
                <Col md={12}>
                  <TextInput
                    label='*Cost Center:'
                    name='costcenter'
                    value={formData.billingInfo.costcenter}
                    onChange={(e) => handleData(e)}
                    placeholder='Cost Center'
                    isInvalid={dataInputError.costcenter ? true : false}
                    invalidMessage={dataInputError.costcenter}
                  />
                </Col>
              )}
            </Row>
            <Row className='px-3'>
              <div className='d-flex justify-content-end'>
                <ul className='px-0 btn-ul'>
                  <li>
                    <button
                      type='button'
                      className='teal-bordered-btn'
                      onClick={() => setStep(step - 1)}
                    >
                      Back
                    </button>
                  </li>
                  <li>
                    <button
                      type='button'
                      className='teal-bordered-btn'
                      onClick={() => {
                        setShowCancelModal(true);
                      }}
                    >
                      Cancel
                    </button>
                  </li>
                  <li>
                    <button type='submit' className='teal-filled-btn'>
                      Save & Submit
                    </button>
                  </li>
                </ul>
              </div>
            </Row>
          </>
        )}
        {formSubmitSuccess && (
        <CustomModal showModal={true} children={<EditRequestSubmit formSubmitSuccessMsg={formSubmitSuccessMsg}/>} />
      )}
        {showCancelModal && (
          <CustomModal
            showModal={true}
            children={
              <EditRequestCancel closeModalParent={closeModalCallback} />
            }
          />
        )}
      </Form>
    </>
  );
};

export default EditBillingInfo;
