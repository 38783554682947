import React from 'react';
import { Row, Col } from 'react-bootstrap';

// Import Icons
import backicon from 'icons/back.svg';

const ViewBox = ({ data, closeViewParent }) => {
  return (
    <div className='view-edit-box-conatiner pb-5'>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => closeViewParent(false)}
          />
          <div>
            <h5 className='view-edit-box-title'>Managed AD Request</h5>
          </div>
        </div>
      </div>
      <div className='table-view-box-content mt-4 mb-5'>
        <Row>
          <Col md={12} className='mt-3'>
            <div className='account-request-view-table-container'>
              <table width='100%'>
                <tbody className='account-request-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Request ID
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-uppercase'
                    >
                      {data.uid}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Account Number
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-uppercase'
                    >
                      {data.accountnumber}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Region
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.region}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      VPC CIDR
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-uppercase'
                    >
                      {data.cidr}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Operating Company
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.opco}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Primary Contact
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.primaryContact}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Admin Access
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-uppercase'
                    >
                      {data.adminAccess}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Status
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.requestStatus}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Application Name
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.applicationName}
                    </td>
                  </tr>
                  {data.adminAccess === 'yes' && (
                    <tr>
                      <td
                        width='50%'
                        className='account-request-view-table-td-key'
                      >
                        Users List
                      </td>
                      <td
                        width='50%'
                        className='account-request-view-table-td-value'
                      >
                        {data.users.map(function (item, i) {
                          return (
                            <span key={i}>
                              {item}
                              {data.users.length - 1 !== i && ' , '}
                            </span>
                          );
                        })}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ViewBox;
