import React, { useMemo, useState, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import countryList from 'react-select-country-list';
import {useTranslation} from 'react-i18next';
// Import Form Component
import TextInput from 'components/Forms/TextInput';
import CountryDropdown from 'components/Forms/CountryDropdown';
import CustomModal from 'components/Modal/CustomModal';

// Import Internal Components
import FormProgress from './FormProgress';
import NewRequestCancel from './NewRequestCancel';

// Validator
import { technicalContactValidate } from '../../Validator/validator';

const TechnicalContact = ({ step, setStep, formData, setFormData }) => {
  // Fetch Country List
  const countryData = useMemo(() => countryList().getData(), []);

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // Set Same Data
  const sameAsAccountData = (e) => {
    let getCheckBoxState = e.target.checked;
    if (getCheckBoxState === true) {
      setFormData({
        type: 'UPDATE_TECHNICAL_CONTACT',
        payload: {
          [`firstNameTC`]: formData.accountContact.firstNameAO,
        },
      });
      setFormData({
        type: 'UPDATE_TECHNICAL_CONTACT',
        payload: {
          [`lastNameTC`]: formData.accountContact.lastNameAO,
        },
      });
      setFormData({
        type: 'UPDATE_TECHNICAL_CONTACT',
        payload: {
          [`emailTC`]: formData.accountContact.emailAO,
        },
      });
      setFormData({
        type: 'UPDATE_TECHNICAL_CONTACT',
        payload: {
          [`countryTC`]: formData.accountContact.countryAO,
        },
      });
      setFormData({
        type: 'UPDATE_TECHNICAL_CONTACT',
        payload: {
          [`phoneNoTC`]: formData.accountContact.phoneNoAO,
        },
      });
    } else {
      setFormData({
        type: 'UPDATE_TECHNICAL_CONTACT',
        payload: {
          [`firstNameTC`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_TECHNICAL_CONTACT',
        payload: {
          [`lastNameTC`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_TECHNICAL_CONTACT',
        payload: {
          [`emailTC`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_TECHNICAL_CONTACT',
        payload: {
          [`countryTC`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_TECHNICAL_CONTACT',
        payload: {
          [`phoneNoTC`]: '',
        },
      });
    }
  };
  

  // handle onchange
  const handleData = ({ target }) => {
    setFormData({
      type: 'UPDATE_TECHNICAL_CONTACT',
      payload: { [target.name]: target.value },
    });
  };

  const handleContinue = (e) => {
    e.preventDefault();
    const errors = technicalContactValidate(formData.technicalContact);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setStep(step + 1);
    }
  };
  const {t}=useTranslation();
  return (
    <>
      <Row>
        <Col md={12} xs={12}>
          <FormProgress accountContact billingContact securityContact />
        </Col>
      </Row>
      <Row className='px-3'>
        <Col md={12} xs={12}>
          <h5 className='page-content-title mb-4'>{t("technical_contact")}</h5>
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        <Row className='px-3'>
          <Col md={6}>
            <TextInput
              label={"*"+t("first_name")+":"}
              name='firstNameTC'
              value={formData.technicalContact.firstNameTC}
              onChange={(e) => handleData(e)}
              placeholder={t("first_name")}
              isInvalid={dataInputError.firstNameTC ? true : false}
              invalidMessage={dataInputError.firstNameTC}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label={"*"+t("last_name")+":"}
              name='lastNameTC'
              value={formData.technicalContact.lastNameTC}
              onChange={(e) => handleData(e)}
              placeholder={t("last_name")}
              isInvalid={dataInputError.lastNameTC ? true : false}
              invalidMessage={dataInputError.lastNameTC}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label={"*"+t("email")+":"}
              name='emailTC'
              value={formData.technicalContact.emailTC}
              onChange={(e) => handleData(e)}
              placeholder={t("email")}
              isInvalid={dataInputError.emailTC ? true : false}
              invalidMessage={dataInputError.emailTC}
            />
          </Col>
          <Col md={6}>
            <CountryDropdown
              label={"*"+t("country")+":"}
              name='countryTC'
              value={formData.technicalContact.countryTC}
              onChange={(e) => handleData(e)}
              selectValues={countryData}
              isInvalid={dataInputError.countryTC ? true : false}
              invalidMessage={dataInputError.countryTC}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label={"*"+t("phone_number")+":"}
              name='phoneNoTC'
              value={formData.technicalContact.phoneNoTC}
              onChange={(e) => handleData(e)}
              placeholder={t("phone_number")}
              isInvalid={dataInputError.phoneNoTC ? true : false}
              invalidMessage={dataInputError.phoneNoTC}
            />
          </Col>
        </Row>
        <Row className='px-3'>
          <Col>
            <Form.Group className='mb-3 '>
              <Form.Check
                type='checkbox'
                label={t("same_as_account_contact")}
                className='checkbox-input'
                onChange={(e) => sameAsAccountData(e)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className='px-3'>
          <div className='col-md-5 offset-md-7'>
            <ul className='px-0 btn-ul'>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => setStep(step - 1)}
                >
                  {t("back")}
                </button>
              </li>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    setShowCancelModal(true);
                  }}
                >
                  {t("cancel")}
                </button>
              </li>
              <li>
                <button type='submit' className='teal-filled-btn'>
                  {t("save_continue")}
                </button>
              </li>
            </ul>
          </div>
        </Row>
      </Form>
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<NewRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

export default TechnicalContact;
