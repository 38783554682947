import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from 'components/Common/Loader';
import AlertMessage from 'components/Common/AlertMessage';

import { postApiCall } from 'helper/postApiCall';

const AccountDecline = ({
  yesParent,
  closeModalParent,
  status,
  uid,
}) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successDeclined, setSuccessDeclined] = useState(false);

  async function pendingToDeclined() {
    setLoading(true);
    const idToken = userInfo.idToken;
    const requestBody = `{"uid":"${uid}"}`;

    const getApiUrl = `${process.env.REACT_APP_AWS_ACCOUNT_REQUEST_MODIFY_REQUEST}`;

    const setApiUrl = `${getApiUrl}?uid=${uid}&type=0&reqstatus=${status}&admingid=${userInfo.preferred_username}&adminemail=${userInfo.email}`;
    await postApiCall(setApiUrl, idToken, requestBody).then((response) => {
      if (response.data.status === 'Success') {
        setSuccessDeclined(true);
      } else {
        setError('Failed to updated');
      }
    });
  }

  async function pendingApprovalforUpdateToDeclined() {
    setLoading(true);
    const idToken = userInfo.idToken;
    const requestBody = `{"uid":"${uid}"}`;
    const getStatus = status;
    const replaceSpaceWithUnderscore = getStatus.replace(/ /g, '_');
    const setStatus = replaceSpaceWithUnderscore;
    const getApiUrl = `${process.env.REACT_APP_AWS_ACCOUNT_REQUEST_MODIFY_REQUEST}`;

    const setApiUrl = `${getApiUrl}?uid=${uid}&type=0&reqstatus=${setStatus}&admingid=${userInfo.preferred_username}&adminemail=${userInfo.email}`;
    await postApiCall(setApiUrl, idToken, requestBody).then((response) => {
      if (response.data.status === 'Success') {
        setSuccessDeclined(true);
      } else {
        setError('Failed to updated');
      }
    });
  }

  return (
    <div className='d-flex justify-content-center flex-column'>
      {successDeclined === false && (
        <>
          <p>Are you sure you want to decline this request</p>
          {error !== null && (
            <div className='d-flex justify-content-center flex-column'>
              <AlertMessage
                cssClassName='alert-message-danger'
                alertMessage={error}
              />
            </div>
          )}
          {loading && <Loader />}
          {loading === false && error === null && (
            <ul className='btn-ul'>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    closeModalParent(null);
                  }}
                >
                  No
                </button>
              </li>
              <li>
                <button
                  type='button'
                  className='teal-filled-btn'
                  onClick={() => {
                    status === 'Decline'
                      ? pendingToDeclined()
                      : pendingApprovalforUpdateToDeclined();
                  }}
                >
                  Yes
                </button>
              </li>
            </ul>
          )}
        </>
      )}
      {successDeclined === true && (
        <>
          <p>Request Declined!</p>
          <p>
            <button
              type='button'
              className='teal-filled-btn'
              onClick={() => {
                yesParent(null);
              }}
            >
              Ok
            </button>
          </p>
        </>
      )}
    </div>
  );
};

export default AccountDecline;
