import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form, InputGroup, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import CustomModal from 'components/Modal/CustomModal';
import TextInput from 'components/Forms/TextInput';

import Cancel from './Cancel';
import Submit from './Submit';

import DangerAlert from 'components/Message/DangerAlert';
import CreatableSelect from 'react-select/creatable';
import { t } from 'i18next';
// Validator
import { networkHubValiadtor } from './validator';

// Helper
import { getApiCall } from 'helper/getApiCall';
import { postApiCall } from 'helper/postApiCall';

// Static Data
import { vpcRegionData } from 'pages/VPC/vpcRegionData';

const AddRequest = (props) => {
  const isHostIsProd = useSelector((state) => state?.vpcRequests?.environmentValue);
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  // Common Variable
  const { location } = useHistory()
const requestModeIsEdit = location?.state?.mode
const editableData = location?.state?.row

  const idToken = `${userInfo.idToken}`;

  // React Select Option
  const customStyles = {
    control: (base) => ({
      ...base,
      border: '1px solid red',
    }),
  };

  // Data Input Error
  const [dataInputError, setDataInputError] = useState({});

  // Common State
  const [loading, setLoading] = useState(true);

  // Component State
  const [showCancel, setShowCancel] = useState(false);
  const [showSubmit, setshowSubmit] = useState(false);


  const [accountNumberNameData, setAccountNumberNameData] = useState([]);
  const [accountNumberData, setAccountNumberData] = useState('');
  const [accountNameData, setAccountNameData] = useState('');
  const [editAccName, setEditAccName] =useState(true);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancel(value);
  }, []);

  // Form State
  const [sourceRegion, setSourceRegion] = useState('');
  const [sourceVpcId, setSourceVpcId] = useState('');
  const [formSubmitMessage, setFormSubmitMessage] = useState(null);

  // Form Submit
  function formSubmit() {
    const formApiUrl = `${process.env.REACT_APP_NETWORK_VPC_ADD}`;
    const errors = networkHubValiadtor({
      accountNumberData,
      sourceRegion,
      accountNameData,
      sourceVpcId,
    });
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setLoading(true);
      const currentEpochTime = Date.now();
      const formRequestBody = {
        uid: requestModeIsEdit ? editableData?.uid : `u-${currentEpochTime}`,
        submittedBy: `${userInfo?.preferred_username}`,
        accountnumber: `${accountNumberData}`,
        vpcregion: `${sourceRegion}`,
        accountName: `${accountNameData}`,
        vpcId: `vpc-${sourceVpcId}`,
        emailID: `${userInfo?.email}`,
        type: requestModeIsEdit ? 'edit' : 'add',
        requestStatus: "pending",
        environment: `${isHostIsProd}`,
      };

      postApiCall(formApiUrl, idToken, formRequestBody)
        .then((response) => {
          if (response.data.message !== '') {
            const apiErrMsg = (response?.data?.message === 'Failure') ? "Requested VPC ID already associated, please re-check the VPC ID." : response?.data?.message
            setFormSubmitMessage(apiErrMsg);
          } else {
            setFormSubmitMessage(
              'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
            );
          }
          setshowSubmit(true);
          setLoading(false);
        })
        .catch((err) => {
          setFormSubmitMessage(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setshowSubmit(true);
          setLoading(false);
        });
    }
  }

  // Account Number State & API State
  const [accountNumberArray, setaccountNumberArray] = useState([]);
  const [accountsApiError, setAccountsApiError] = useState(null);

  const accountApiUrl = `${process.env.REACT_APP_AWS_CIDR_ADD_REQUEST_ACCOUNT_NUMBER_NAME}`;

  useEffect(() => {
    setLoading(true);

    getApiCall(accountApiUrl, idToken)
      .then((response) => {
        setAccountNumberNameData(response.data);
        const accountNumberOptions = response.data
          .filter((f) => f !== '')
          .map((d) => ({
            value: d.split(' - ')[0],
            label: d.split(' - ')[0],
          }));

        setaccountNumberArray(accountNumberOptions);

        if(location?.state?.mode === 'edit'){
          setAccountNumberData(editableData?.accountnumber)
          setAccountNameData(editableData?.accountName)
          setSourceRegion(editableData?.vpcregion)

          const filteredVpcId = editableData?.vpcId?.split('-')[1]
          setSourceVpcId(filteredVpcId)
        }
        
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAccountsApiError(
          'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
        );
      });

    return () => {
      setaccountNumberArray([]);
    };
  }, []);

  return (
    <>
      <Row>
        <Col className='mb-2'>
          <h5 className='page-content-title'>Request New Connection</h5>
        </Col>
      </Row>
      {loading && (
        <Row>
          <Col>
            <div className='d-flex justify-content-center'>
              <SpinnerLoader />
            </div>
          </Col>
        </Row>
      )}
      {loading === false && accountsApiError !== null && (
        <DangerAlert message={accountsApiError} />
      )}
      {loading === false && accountsApiError === null && (
        <>
          <Row>
            <Col md={6} className='mb-2'>
              <Form.Group controlId='accountNo'>
                <Form.Label>Select Account:</Form.Label>
                <CreatableSelect
                      name='account number'
                      placeholder='Account Number'
                      searchable={true}
                      value={{ label: accountNumberData }}
                      isClearable={true}
                      onChange={(value) => {
                        setAccountNameData('');
                        const accNum = value !== null ? value.value : '';
                        setAccountNumberData(accNum);
                        const accNumber= accNum.toString().length;
                        if (value !== null) {
                          if(/^[0-9]*$/.test(accNum) === false){
                            dataInputError.accountNumberData= 'Only digits allowed';
                          }
                          else if(accNumber==12){
                            dataInputError.accountNumberData='';
                            accountNumberArray.filter((i)=>{
                            if(i.label!=""){
                              if(i.label!=accNum){
                                setAccountNameData('');
                                setEditAccName(false); 
                              }
                              else{
                                dataInputError.accountNumberData= '';
                              }
                            }
                            })
                          }
                          else{
                            dataInputError.accountNumberData= 'Please enter valid account number'; 
                          }
                        // }
                          for (let i = 0; i < accountNumberNameData.length; i++) {
                            let accNumName = accountNumberNameData[i];
                            let splited = accNumName.split(' - ')[0];
                            if (splited === accNum) {
                              let splitedAccName = accNumName.split(' - ')[1];
                              setAccountNameData(splitedAccName);
                              setEditAccName(true); 
                            }
                          } 
                        }
    
                        else {
                          setAccountNameData('');
                          setEditAccName(true); 
                        }

                      }}
                      options={accountNumberArray}
                      styles={dataInputError.accountNumberData && customStyles}
                    />
                <p className='custom-error-message'>
                  {dataInputError.accountNumberData}
                </p>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-2'>
              <Form.Group className='mb-3' controlId='selectAccountName'>
                <Form.Label>{(t('account_name'))}</Form.Label>

                <Form.Control
                  type='text'
                  readOnly={editAccName}
                  placeholder='Account Name'
                  value={accountNameData}
                  isInvalid={dataInputError.accountNameData ? true : false}
                  onChange={(e)=>{
                    setAccountNameData(e.target.value)
                  }}
                />

                <Form.Control.Feedback type='invalid'>
                  {dataInputError.accountNameData}
                </Form.Control.Feedback>
              </Form.Group>
              </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-2'>
             <Form.Label>VPC Id</Form.Label>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='basic-addon1'>vpc-</InputGroup.Text>
                <FormControl
                  placeholder='Enter VPC Id'
                  aria-label='sourceTGA'
                  value={sourceVpcId}
                  isInvalid={dataInputError.sourceVpcId ? true : false}
                  onChange={(e) => setSourceVpcId(e.target.value)}
                />
              </InputGroup>
              <div className='custom-error-message-text'>
                {dataInputError.sourceVpcId}
              </div>
            </Col>
          </Row> 
          <Row>
            <Col md={6} className='mb-2'>
              <Form.Group controlId='selectRegion' className="mb-3">
                <Form.Label>Select Region</Form.Label>
                <Form.Select
                  value={sourceRegion}
                  onChange={(e) => setSourceRegion(e.target.value)}
                  isInvalid={dataInputError.sourceRegion ? true : false}
                >
                  <option value=''>None</option>
                  {vpcRegionData.map((data, index) => (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  {dataInputError.sourceRegion}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>   
          <Row>
            <Col md={6} className='d-flex flex-row mt-3'>
              <ul className='btn-ul'>
                <li>
                  <button
                    type='button'
                    className='teal-bordered-btn'
                    onClick={() => {
                      setShowCancel(true);
                    }}
                  >
                    Cancel
                  </button>
                </li>
                <li>
                  <button
                    type='button'
                    className='teal-filled-btn'
                    onClick={() => {
                      formSubmit();
                    }}
                  >
                    Submit
                  </button>
                </li>
              </ul>
            </Col>
          </Row>

          {showCancel && (
            <CustomModal
              showModal={true}
              children={<Cancel closeModalParent={closeModalCallback} />}
            />
          )}

          {showSubmit && (
            <CustomModal
              showModal={true}
              children={<Submit message={formSubmitMessage} />}
            />
          )}
        </>
      )}
    </>
  );
};

export default AddRequest;
