export const customeRoleData = [
  { label: "custom devops1", value: "custom devops1" },
  { label: "custom devops2", value: "custom devops2" },
  { label: "custom service1", value: "custom service1" },
  { label: "custom service2", value: "custom service2" },
  { label: "custom readonly1", value: "custom readonly1" },
  { label: "custom readonly2", value: "custom readonly2" },
];

export const customeRoleDataValues = [
  { label: "custom devops1", value: "CustomDevops1" },
  { label: "custom devops2", value: "CustomDevops2" },
  { label: "custom service1", value: "CustomService1" },
  { label: "custom service2", value: "CustomService2" },
  { label: "custom readonly1", value: "CustomReadOnly1" },
  { label: "custom readonly2", value: "CustomReadOnly2" },
];

export const biaRatingOption = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

export const exceptionTimeLineOption = [
  { label: "3 months", value: "3 months" },
  { label: "6 months", value: "6 months" },
  { label: "12 months", value: "12 months" },
];

export const exceptionCategoryOption = [
  { label: "Tools", value: "Tools" },
  { label: "Critical Configurations", value: "Critical Configurations" },
  { label: "Vulnerabilities Patching", value: "Vulnerabilities Patching" },
  { label: "Misconfiguration", value: "Misconfiguration" },
];
