import { WORKSPACE_REQUEST_RELOAD } from './workspaceRequestConstant';

export const workspaceRequestReloadReducer = (
  state = {
    workspaceRequestReloadType: false,
  },
  action
) => {
  switch (action.type) {
    case WORKSPACE_REQUEST_RELOAD:
      return { ...state, workspaceRequestReloadType: action.payload };

    default:
      return state;
  }
};
