import moment from "moment";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

const dateObject = new Date();
const monthVal = moment(dateObject).format("MM");

const MonthPicker = ({ isYearChecked, getSelectedMonth }) => {
  const [selectedMonth, setSelectedMonth] = useState("");
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const handleMonthSelection = (month) => {
    setSelectedMonth(month);
    getSelectedMonth(month);
  };
  return (
    <>
      <Row className="m-4">
        <Col md={12}>
          <Row>
            <Col md={12}>
              <p className="subtitle-text p-2 text-center bg-grey">
                Year -{" "}
                {isYearChecked === 0
                  ? dateObject?.getFullYear() - 1
                  : dateObject?.getFullYear()}
              </p>
            </Col>
            {months.map((item, index) => (
              <Col
                md={4}
                className={`text-center p-2 month-in custom-pointer ${
                  selectedMonth === item ? "selectedMonth" : ""
                } ${
                  isYearChecked === 1 && index > monthVal - 1
                    ? "disabled-div"
                    : ""
                }`}
                onClick={() => {
                  handleMonthSelection(item);
                }}
              >
                {item}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default MonthPicker;
