import { CLOUD_EYE_MANAGEMENT_VIEW_DETAIL } from './cloudEyeManagementConstant';

export const cloudEyeManagementReducer = (
  state = {
    cloudEyeManagementView: '',
  },
  action
) => {
  switch (action.type) {
    case CLOUD_EYE_MANAGEMENT_VIEW_DETAIL:
      return { ...state, cloudEyeManagementView: action.payload };

    default:
      return state;
  }
};
