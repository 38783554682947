import React from "react";
import { Link } from "react-router-dom";

const NewRequestSubmit = ({ refUid }) => {
  return (
    <div className="d-flex justify-content-center flex-column">
      <p>Your account request was submitted successfully</p>
      <p>Check your status using this ref#</p>
      <p>{refUid}</p>
      <p>
        <button type="button" className="teal-filled-btn">
          <Link to="/gcp-account-requests">Ok</Link>
        </button>
      </p>
    </div>
  );
};

export default NewRequestSubmit;
