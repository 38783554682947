import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CountryDropdown = ({
  label,
  selectValues,
  isInvalid,
  invalidMessage,
  placeholder = "select_country",
  ...others
}) => {
  const { t } = useTranslation();
  return (
    <Form.Group className="mb-3">
      <Form.Label>{label}</Form.Label>

      <Form.Select isInvalid={isInvalid} {...others}>
        <option value="">{t(placeholder)}</option>
        {/* {selectValues.map((data, index) => (
          <option key={index} value={data.label}>
            {data.value}-{data.label}
          </option>
        ))} */}

        {selectValues.map((data, index) => (
          <option key={index} value={data.label}>
            {data.label}
          </option>
        ))}
      </Form.Select>

      <Form.Control.Feedback type="invalid">
        {invalidMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default CountryDropdown;
