import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Alert, Col, Row } from "react-bootstrap";

import makeData from "./makeData";
import AccountRequestsView from "./AccountRequestsView";
import AccountRequestsEdit from "./AccountRequestsEdit";
import RequestSubmitConfirmation from "./RequestSubmitConfirmation";
import CustomModal from "components/Modal/CustomModal";
// import { billingData } from "./makeData.js";

//internal component
import Table from "./CustomTable";
import { getApiCall } from "helper/getApiCall";
import Loader from "components/Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { postApiCall } from "helper/postApiCall";
import MonthSelection from "./MonthSelection";
import {
  BILLING_SCREEN_DATE,
  SELECTED_MONTH,
  SORTING_BOOLEAN,
  TABLE_REFRESH,
} from "redux/billing/EditableTableConstant";
import {
  billingScreen,
  getISTTime,
} from "../../../static-contents/billing-data";

const getDay = new Date().getDate();

const Styles = styled.div`
  padding: 1rem;
  padding-top: 0rem;

  table {
    border-spacing: 0;
    border: 1px solid #e0e0e0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      padding: 0.5rem;
    }
    th,
    td {
      margin: 0;
      //   padding: 0.5rem;
      border-bottom: 1px solid #e0e0e0 !important;
      border-right: 1px solid #e0e0e0 !important;

      :last-child {
        border-right: 0;
      }

      input,
      .row--input p {
        // font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
        background-color: #f1f5f7;
        padding: 0.5rem;
        // width: 11.2rem;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        display: block;
        width: 100%;
      }
      input:focus-visible {
        // outline: 3px solid #e0e0e0 !important;
        outline: unset !important;
        color: #3ac4aa !important;
        padding: 0.5rem;
        font-weight: bold;
        display: block;
        width: 100%;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
  .table--action-btn .teal-bordered-btn {
    font-size: 12px;
  }
  .border-danger {
    outline: 1px solid #ffa6ae !important;
    border-radius: 2px;
    outline-offset: -3px;
  }
  .go-to-page-input {
    width: 60px;
    outline: 1px solid #9ce1d4;
    padding-left: 0.3rem;
  }
`;

// Be sure to pass our updateMyData and the skipPageReset option

function BillingDetail() {
  const billingStateValues = useSelector((state) => state.billing);
  const [getScreenAcess, setScreenAccess] = useState(0);
  /* 
    - Columns is a simple array right now, but it will contain some logic later on. It is recommended by react-table to memoize the columns data
    - Here in this example, we have grouped our columns into two headers. react-table is flexible enough to create grouped table headers
  */
  const columns =
    billingStateValues?.selectedMonth === 0 && getDay < getScreenAcess
      ? [
          {
            hideHeader: false,
            Header: "Date",
            columns: [
              {
                Header: "Account ID",
                accessor: "Account ID",
              },
            ],
          },
          {
            hideHeader: false,
            Header: "Info",
            columns: [
              {
                Header: "Account Name",
                accessor: "Account Name",
              },
              {
                Header: "GSID Tags",
                accessor: "GSID Tags",
              },
              {
                Header: "Vendor",
                accessor: "Vendor",
              },

              {
                Header: "Account Region",
                accessor: "Account Region",
              },
              {
                Header: "Ops Cost",
                accessor: "Ops Cost",
              },
              {
                Header: "IO Code/Cost Center",
                accessor: "IO Code/Cost Center",
              },
              {
                Header: "Remarks",
                accessor: "Remarks",
              },
              {
                Header: "Action",
                accessor: "action",
              },
            ],
          },
        ]
      : [
          {
            hideHeader: false,
            Header: "Date",
            columns: [
              {
                Header: "Account ID",
                accessor: "Account ID",
              },
            ],
          },
          {
            hideHeader: false,
            Header: "Info",
            columns: [
              {
                Header: "Account Name",
                accessor: "Account Name",
              },
              {
                Header: "GSID Tags",
                accessor: "GSID Tags",
              },
              {
                Header: "Vendor",
                accessor: "Vendor",
              },

              {
                Header: "Account Region",
                accessor: "Account Region",
              },
              {
                Header: "Ops Cost",
                accessor: "Ops Cost",
              },
              {
                Header: "IO Code/Cost Center",
                accessor: "IO Code/Cost Center",
              },
              {
                Header: "Remarks",
                accessor: "Remarks",
              },
            ],
          },
        ];

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const idToken = userInfo.idToken;

  const [data, setData] = React.useState([]);

  const [originalData, setOriginalData] = React.useState([]);
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const [showViewBox, setShowViewBox] = React.useState(false);
  const [showConfirmationSave, setShowConfirmationSave] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

  const [selectedViewData, setSelectedViewData] = React.useState(null);
  const [removeSorting, setRemoveSorting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [apiError, setApiError] = React.useState(null);

  let sortBy = [
    { id: "Account ID" },
    { id: "Account Name" },
    { id: "IO Code/Cost Center" },
    { id: "GSID Tags" },
  ];
  const apiUrl = `${process.env.REACT_APP_OPS_COST_GET_DATA}`;
  const postApiUrl = `${process.env.REACT_APP_OPS_COST_PUT_DATA}`;
  const screenDateApi = process.env.REACT_APP_BILLING_HOME_DETAILS;

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, column, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [column?.id]: value,
          };
        }
        return row;
      })
    );
    dispatch({
      type: TABLE_REFRESH,
      payload: !billingStateValues?.isTableRefresh,
    });
  };

  const fetchTableData = useCallback(() => {
    setLoading(true);
    const requestBody = {
      month: billingStateValues.selectedMonth,
    };
    postApiCall(apiUrl, idToken, requestBody)
      // getApiCall("https://jsonplaceholder.typicode.com/posts")
      .then((response) => {
        const enhancedData = response.data;

        setData(() => makeData(enhancedData, enhancedData?.length));
        setOriginalData(() => makeData(enhancedData, enhancedData?.length));
        setLoading(false);
      })
      .catch((err) => {
        console.log("something went wrong with api");
      });
  }, [billingStateValues.selectedMonth]);

  const fetchScreenDate = () => {
    const requestBody = {};
    setLoading(true);
    postApiCall(screenDateApi, idToken, requestBody)
      .then((response) => {
        const responseData = response?.data;
        const getScreenEnableDate = responseData.find(
          (item) => item?.category === "ScreenEnablement"
        );
        setScreenAccess(Number(getScreenEnableDate?.dayNo) + 1);
        dispatch({
          type: BILLING_SCREEN_DATE,
          payload: Number(getScreenEnableDate?.dayNo) + 1,
        });
      })
      .catch((err) => {
        console.log("something went wrong with api");
      });
  };

  React.useEffect(() => {
    fetchScreenDate();
  }, []);

  React.useEffect(() => {
    fetchTableData();
    return () => {
      setData([]);
    };
  }, [fetchTableData, billingStateValues.selectedMonth]);

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  // Let's add a data resetter/randomizer to help
  // illustrate that flow...
  const resetData = () => {
    setData(originalData);
    setShowConfirmationSave(false);
    setRemoveSorting(true);
    const sortingValues = {
      columnId: null,
      bool: undefined,
    };
    dispatch({
      type: SORTING_BOOLEAN,
      payload: sortingValues,
    });
    fetchTableData();
  };

  const handleCloseViewBox = () => {
    setShowViewBox(false);
    setShowConfirmationSave(false);
  };

  const saveDataApi = (tableData) => {
    setLoading(true);
    const requestBody = {
      data: tableData,
      updatedby: userInfo?.email,
      updateddate: getISTTime().getDate,
      updatedtime: getISTTime().getTime,
    };

    postApiCall(postApiUrl, idToken, requestBody)
      .then((response) => {
        if (response.data.status === "Success") {
          setLoading(false);
          setShowSuccessMessage(true);
        }
      })
      .catch((err) => {
        setApiError(
          "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
        );
      });
  };
  const undoConfirmation = () => {
    setShowConfirmationSave(true);
  };

  const TableData = useMemo(() => {
    return (
      <Table
        columns={columns}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        setOriginalData={setOriginalData}
        setShowViewBox={setShowViewBox}
        setSelectedViewData={setSelectedViewData}
        showViewBox={showViewBox}
        sortBy={sortBy}
        removeSorting={removeSorting}
        saveDataApi={saveDataApi}
        undoConfirmation={undoConfirmation}
        resetData={resetData}
      />
    );
  }, [
    billingStateValues?.sortingBoolean,
    originalData,
    billingStateValues?.isTableRefresh,
  ]);

  const successRedirection = () => {
    setShowSuccessMessage(false);
    fetchTableData();
  };

  return (
    <>
      {loading === false && apiError !== null && (
        <Alert variant="danger">{apiError}</Alert>
      )}
      {loading ? <Loader /> : null}
      {loading ? null : (
        <>
          <Row className="mb-4">
            <Col md={6} className="d-flex align-items-center mb-2">
              <h3 className="page-content-title mb-0">OPS Cost</h3>
            </Col>
            <Col md={4} />
          </Row>
          <Styles>{TableData}</Styles>
        </>
      )}
      {showViewBox && (
        <AccountRequestsView
          data={selectedViewData}
          cloudTitle="AWS"
          closeViewParent={handleCloseViewBox}
        />
      )}
      {/* {showEditBox && (
        <AccountRequestsEdit
        closeEditParent={handleCloseViewBox},
        handleNewData={AddNewData},
        />
      )} */}
      {showConfirmationSave && (
        <CustomModal
          showModal={true}
          children={
            <RequestSubmitConfirmation
              proceedToSave={resetData}
              closeModalParent={handleCloseViewBox}
            />
          }
        />
      )}
      {showSuccessMessage && (
        <CustomModal
          showModal={true}
          children={
            <RequestSubmitConfirmation
              success
              closeModalParent={successRedirection}
            />
          }
        />
      )}
    </>
  );
}

export default BillingDetail;
