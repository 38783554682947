import React, { useState, useEffect } from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

const Licenses = () => {
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

    if (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: setIdToken,
        },
      };

      axios
        .get(`${process.env.REACT_APP_LICENSES_URL}`, config)
        .then((response) => {
          setResponseData(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setError(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setLoading(false);
        });
    }

    return () => {
      setResponseData([]);
    };
  }, []);

  return (
    <div className='custom-card'>
      <h5 className='dashboard-title mb-3'>Licenses</h5>
      {loading && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center mt-3'>
            <SpinnerLoader />
          </Col>
        </Row>
      )}

      {loading === false && error !== null && (
        <Row>
          <Col>
            <DangerAlert message={error} />
          </Col>
        </Row>
      )}

      {loading === false && error === null && (
        <Table responsive className='licenses-table'>
          <thead>
            <tr>
              <td className='text-uppercase'>Tool</td>
              <td className='text-uppercase'>Used</td>
              <td className='text-uppercase'>Available</td>
              <td className='text-uppercase'>Total</td>
            </tr>
          </thead>
          <tbody>
            {responseData.map((data, index) => {
              return (
                <tr key={index}>
                  <td className='text-muted mb-0'>{data.Tool}</td>
                  <td className='text-muted mb-0'>{data.Used}</td>
                  <td className='text-muted mb-0'>{data.Available}</td>
                  <td className='text-muted mb-0'>{data.Total}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default Licenses;
