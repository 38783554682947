import axios from 'axios';

export const postApiCall = (apiUrl, token, requestBody) => {
  const idToken = `{ "idToken":{ "idToken":"${token}"}}`;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: idToken,
    },
  };

  return axios
    .post(apiUrl, requestBody, config)
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};
