export const DefaultAWSData = {
  accountDetails: {
    region: "",
    drBackUp: "",
    crossBackUp: "",
    backUpPlan: "",
  },
};

export const AWSReducer = (awsData, { type, payload }) => {
  switch (type) {
    case "UPDATE_BACKUP_PLAN":
      return {
        ...awsData,
        accountDetails: {
          ...awsData.accountDetails,
          ...payload,
        },
      };

    default:
      return awsData;
  }
};
