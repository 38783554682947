import React, { useMemo, useState, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import countryList from 'react-select-country-list';

// Import Form Component
import TextInput from 'components/Forms/TextInput';
import CountryDropdown from 'components/Forms/CountryDropdown';

// Import Common Component
import CustomModal from 'components/Modal/CustomModal';

// Import Internal Component
import EditRequestCancel from './EditRequestCancel';
import EditFormMenu from './EditFormMenu';

// Validator
import { billingContactValidate } from '../../validator';

const EditBillingContact = ({ step, setStep, formData, setFormData }) => {
  // Fetch Country List
  const countryData = useMemo(() => countryList().getData(), []);

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // Set Same Data
  const sameAsAccountData = (e) => {
    let getCheckBoxState = e.target.checked;
    if (getCheckBoxState === true) {
      setFormData({
        type: 'UPDATE_BILLING_CONTACT',
        payload: {
          [`firstNameBC`]: formData.accountContact.firstNameAO,
        },
      });
      setFormData({
        type: 'UPDATE_BILLING_CONTACT',
        payload: {
          [`lastNameBC`]: formData.accountContact.lastNameAO,
        },
      });
      setFormData({
        type: 'UPDATE_BILLING_CONTACT',
        payload: {
          [`emailBC`]: formData.accountContact.emailAO,
        },
      });
      setFormData({
        type: 'UPDATE_BILLING_CONTACT',
        payload: {
          [`countryBC`]: formData.accountContact.countryAO,
        },
      });
      setFormData({
        type: 'UPDATE_BILLING_CONTACT',
        payload: {
          [`phoneNoBC`]: formData.accountContact.phoneNoAO,
        },
      });
    } else {
      setFormData({
        type: 'UPDATE_BILLING_CONTACT',
        payload: {
          [`firstNameBC`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_BILLING_CONTACT',
        payload: {
          [`lastNameBC`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_BILLING_CONTACT',
        payload: {
          [`emailBC`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_BILLING_CONTACT',
        payload: {
          [`countryBC`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_BILLING_CONTACT',
        payload: {
          [`phoneNoBC`]: '',
        },
      });
    }
  };

  // handle onchange
  const handleData = ({ target }) => {
    setFormData({
      type: 'UPDATE_BILLING_CONTACT',
      payload: { [target.name]: target.value },
    });
  };

  const handleContinue = (e) => {
    e.preventDefault();
    const errors = billingContactValidate(formData.billingContact);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setStep(step + 1);
    }
  };
  return (
    <>
      <Row>
        <Col md={12} xs={12}>
          <EditFormMenu billingContact />
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        <Row className='px-3 mt-3'>
          <Col md={12}>
            <TextInput
              label='*First Name:'
              name='firstNameBC'
              value={formData.billingContact.firstNameBC}
              onChange={(e) => handleData(e)}
              placeholder='First Name'
              isInvalid={dataInputError.firstNameBC ? true : false}
              invalidMessage={dataInputError.firstNameBC}
            />
          </Col>
          <Col md={12}>
            <TextInput
              label='*Last Name:'
              name='lastNameBC'
              value={formData.billingContact.lastNameBC}
              onChange={(e) => handleData(e)}
              placeholder='Last Name'
              isInvalid={dataInputError.lastNameBC ? true : false}
              invalidMessage={dataInputError.lastNameBC}
            />
          </Col>
          <Col md={12}>
            <TextInput
              label='*Email:'
              name='emailBC'
              value={formData.billingContact.emailBC}
              onChange={(e) => handleData(e)}
              placeholder='Mail Id'
              isInvalid={dataInputError.emailBC ? true : false}
              invalidMessage={dataInputError.emailBC}
            />
          </Col>
          <Col md={12}>
            <CountryDropdown
              label='*Country:'
              name='countryBC'
              value={formData.billingContact.countryBC}
              onChange={(e) => handleData(e)}
              selectValues={countryData}
              isInvalid={dataInputError.countryBC ? true : false}
              invalidMessage={dataInputError.countryBC}
            />
          </Col>
          <Col md={12}>
            <TextInput
              label='*Phone Number:'
              name='phoneNoBC'
              value={formData.billingContact.phoneNoBC}
              onChange={(e) => handleData(e)}
              placeholder='Phone Number'
              isInvalid={dataInputError.phoneNoBC ? true : false}
              invalidMessage={dataInputError.phoneNoBC}
            />
          </Col>
        </Row>
        <Row className='px-3'>
          <Col>
            <Form.Group className='mb-3 '>
              <Form.Check
                type='checkbox'
                label='Same as Account Contact'
                className='checkbox-input'
                onChange={(e) => sameAsAccountData(e)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className='px-3'>
          <div className='d-flex justify-content-end'>
            <ul className='px-0 btn-ul'>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => setStep(step - 1)}
                >
                  Back
                </button>
              </li>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    setShowCancelModal(true);
                  }}
                >
                  Cancel
                </button>
              </li>
              <li>
                <button type='submit' className='teal-filled-btn'>
                  Save & Continue
                </button>
              </li>
            </ul>
          </div>
        </Row>
      </Form>
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<EditRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

export default EditBillingContact;
