export const roleAddRequestValidate = ({
  accountNumberData,
  accountNameData,
  tagDescription,
  role
}) => {
  let errors = {};

  if (!accountNumberData) {
    errors.v_accountNumberData = 'Account number is required';
  }

  if (!accountNameData) {
    errors.v_accountNameData = 'Account name is required';
  }

  if (!tagDescription) {
    errors.v_tagDescription = 'Purpose is required';
  }
  
  if (!role) {
    errors.v_role = 'Role is required';
  }
  

  return errors;
};
