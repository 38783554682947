import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import ReactSpeedometer from 'react-d3-speedometer';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

import { CLOUD_EYE_USER_TABLE } from 'redux/cloudeye-user/cloueyeUserConstant';

const QuickOverView = ({
  webserviceLoading,
  webserviceData,
  webServiceError,
  resourceHealthLoading,
  resourceHealthData,
  resourceHealthError,
}) => {
  const dispatch = useDispatch();

  return (
    <div className='custom-card'>
      <Row>
        <Col md={6} className='mt-3 d-flex justify-content-center'>
          {webserviceLoading && <SpinnerLoader />}
          {webserviceLoading === false && webServiceError === null && (
            <>
              {webserviceData.data.body.map((data, index) => (
                <div key={index} className='speedometer-container'>
                  <ReactSpeedometer
                    width={200}
                    height={150}
                    ringWidth={35}
                    needleTransitionDuration={2000}
                    needleHeightRatio={0.5}
                    minValue={0}
                    maxValue={1.1}
                    maxSegmentLabels={0}
                    startColor={'#FE6161'}
                    endColor={'#47E897'}
                    segments={1000}
                    currentValueText=''
                    value={
                      data
                        .split('--')[1]
                        .toLowerCase()
                        .trim() === 'healthy'
                        ? 1
                        : 0.1
                    }
                  />
                  <div
                    className='speedometer-title '
                    onClick={() => {
                      dispatch({
                        type: CLOUD_EYE_USER_TABLE,
                        payload: 'Webservice Health',
                      });
                    }}
                  >
                    {data
                      .split('--')[1]
                      .trim()
                      .toLowerCase() === 'healthy' ? (
                      <>
                        <span>Webservice/URL</span>
                        <span className='healthy-text custom-pointer'>
                          {data.split('--')[1].trim()}
                        </span>
                      </>
                    ) : (
                      <>
                        <span>Webservice/URL</span>
                        <span className='unhealthy-text custom-pointer'>
                          {data.split('--')[1].trim()}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              ))}
              {webserviceLoading === false && webServiceError !== null && (
                <DangerAlert message={webServiceError} />
              )}
            </>
          )}
        </Col>
        <Col md={6} className='mt-3 d-flex justify-content-center'>
          {resourceHealthLoading && <SpinnerLoader />}
          {resourceHealthLoading === false && resourceHealthError === null && (
            <>
              {resourceHealthData.data.overall.map((data, index) => (
                <div key={index} className='speedometer-container'>
                  <ReactSpeedometer
                    width={200}
                    height={150}
                    ringWidth={35}
                    needleTransitionDuration={2000}
                    needleHeightRatio={0.5}
                    minValue={0}
                    maxValue={1.1}
                    maxSegmentLabels={0}
                    startColor={'#FE6161'}
                    endColor={'#47E897'}
                    currentValueText=''
                    value={data.overall.toLowerCase() === 'healthy' ? 1 : 0.1}
                    segments={1000}
                  />

                  {data.overall.toLowerCase() !== 'healthy' ? (
                    <div
                      className='speedometer-title custom-pointer'
                      onClick={() => {
                        dispatch({
                          type: CLOUD_EYE_USER_TABLE,
                          payload: 'Resource Health',
                        });
                      }}
                    >
                      <span>Resource Health</span>
                      <span className='unhealthy-text'>{data.overall}</span>
                    </div>
                  ) : (
                    <div className='speedometer-title'>
                      <span>Resource Health</span>
                      <span className='healthy-text'>{data.overall}</span>
                    </div>
                  )}
                </div>
              ))}
              {resourceHealthLoading === false &&
                resourceHealthError !== null && (
                  <DangerAlert message={resourceHealthError} />
                )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default QuickOverView;
