import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

// Component
import PageContentSidebar from 'components/PageContent/PageContentSidebar';

// Import Internal Components
import AWSTable from './AWSTable';

// Import Constant
import { USER_INFO } from 'redux/user/userConstant';

// Logo
import awsLogo from 'images/aws-logo.svg';
import azureLogo from 'images/azure-logo.svg';

// Roles
import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

const MoriartyAWSIndex = ({ history }) => {
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });

        if (!info.groups.includes(CS_Hub_AWS_Account_Request_Admin)) {
          history.push('/landing');
        }
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch]);

  return (
    <>
      {userInfo !== null && authState && authState.isAuthenticated && (
        <>
          <Container fluid>
            <Row>
              <Col md={2} className='px-0'>
                <PageContentSidebar
                  firstLogoLink='/aws-moriarty'
                  firstLogoImg={awsLogo}
                  fisrtLogoAlt='AWS'
                  firstLogoFirstMatchingLink='aws-moriarty'
                  secondLogoLink='/azure-moriarty'
                  secondLogoImg={azureLogo}
                  secondLogoAlt='Azure'
                  secondLogoFirstMatchingLink='azure-moriarty'
                  showLinks='no'
                />
              </Col>
              <Col md={10}>
                <AWSTable />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default MoriartyAWSIndex;
