import viewAlarmIcon from "icons/view-alarm.svg";
import { useTranslation } from "react-i18next";

export default function ViewAlarmIcon(props) {
  const {t} = useTranslation();
  return (
    <img
      src={viewAlarmIcon}
      alt="view alarm icon"
      title={t("view_alarm")}
      {...props}
    />
  );
}
