import React, { useState } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const ExceptionDownload = ({ cloudName, formattedCsvData }) => {
  // To change the file name modify here
  const fileName = `${cloudName} Tools Exception`;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(formattedCsvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <>
      <button
        type="button"
        className="teal-bordered-btn w-100"
        onClick={() => {
          exportToCSV();
        }}
      >
        <i className="fa fa-download" style={{ marginRight: "6px" }}></i>
        Download
      </button>
    </>
  );
};

export default ExceptionDownload;
