export const managedAdValiadtor = ({
  accountNumber,
  region,
  vpcCIDR,
  opco,
  primaryContact,
  adminAccess,
  estimatedAppUsers,
  usersList,
}) => {
  let errors = {};

  if (!accountNumber) {
    errors.accountNumber = 'Account number is required';
  }

  if (!region) {
    errors.region = 'Region is required';
  }

  let isVpcCIDR = /\d*.\.*.\//i.test(vpcCIDR);
  if (!vpcCIDR) {
    errors.vpcCIDR = 'VPC CIDR is required';
  } else if (isVpcCIDR === false) {
    errors.vpcCIDR = 'Only digits, dots, / is allowed';
  }

  if (!opco) {
    errors.opco = 'Opco is required';
  }

  let isEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    primaryContact
  );
  if (!primaryContact) {
    errors.primaryContact = 'Primary contact is required';
  } else if (isEmail === false) {
    errors.primaryContact = 'Primary contact is invalid';
  }

  if (adminAccess === 'yes') {
    if (usersList.length === 0) {
      errors.usersList = 'Users list is required';
    }
  }

  return errors;
};
