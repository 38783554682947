import React, { useReducer, useState } from 'react';
import { AzureReducer, DefaultAzureData } from './azure-reducer';
import FormSteps from './FormSteps';

const NewRequest = () => {
  let [step, setStep] = useState(0);
  const [formData, setFormData] = useReducer(AzureReducer, DefaultAzureData);
  let steps = FormSteps(step, setStep, formData, setFormData);
  return <>{steps[`${step}`].content}</>;
};

export default NewRequest;
