import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";

// Component for Global Filter
function GlobalFilter({ globalFilter, setGlobalFilter, gotoPage }) {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
    gotoPage(0);
  }, 200);

  return (
    <div>
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="search"
        className="w-100 form-control custom-search-input-form-control"
      />
    </div>
  );
}

export default GlobalFilter;
