import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form, InputGroup, FormControl } from 'react-bootstrap';
import Multiselect from "multiselect-react-dropdown";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Import Common Components
import TextInput from 'components/Forms/TextInput';
import CustomModal from 'components/Modal/CustomModal';
import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

import Cancel from './AddRequest/Cancel';
import Update from './ViewRequest/Update';

// Static Data
import { vpcRegionData } from 'pages/VPC/vpcRegionData';

// Helper
import { postApiCall } from 'helper/postApiCall';
import { getApiCall } from 'helper/getApiCall';

const VpcDisassociationForm = () => {
    const { location } = useHistory()
    const history = useHistory();

    const selectedVPC = location?.state?.vpcObj
    const isHostIsProd = useSelector((state) => state?.vpcRequests?.environmentValue);
    const user = useSelector((state) => state.user);
    const { userInfo } = user;
    const idToken = `${userInfo?.idToken}`;

    const removeApiUrl = `${process.env.REACT_APP_NETWORK_VPC_REMOVE}`
   
    // API State
    const [showCancel, setShowCancel] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);
    const [zoneData, setZoneData] = useState([]);
    const [zoneValue, setZoneValue] = useState([]);
    const [zoneValueError, setZoneValueError] = useState(null);

    const [apiLoading, setApiLoading] = useState(false);
    const [accountsApiError, setAccountsApiError] = useState(null);
    
    //Update request callback
    const [updateModalCode, setUpdateModalCode] = useState(null);
    const [removeModalCode, setRemoveModalCode] = useState(null);
      // Form Submit
  function formSubmit(e) {
    e.preventDefault()
    const zoneIds = zoneValue.map(zoneid => zoneid.DropdownValue)
    if(zoneValue?.length){
      const requestData = {
        "uid": `${selectedVPC?.uid}`,
        "zoneId": zoneIds,
        "vpcregion": `${selectedVPC?.vpcregion}`,
        "accountnumber": `${selectedVPC?.accountnumber}`,
        "vpcId": `${selectedVPC?.vpcId}`,
        "environment": `${isHostIsProd}`,
        }
      setApiLoading(true);
      postApiCall(removeApiUrl, idToken, requestData)
        .then((response) => {
          setApiLoading(false);
          setShowSubmit(true);
          setRemoveModalCode(response?.status)
        })
        .catch((err) => {
          setShowSubmit(true);
          setRemoveModalCode(400)
          setApiLoading(false);
          setAccountsApiError(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
        });
    } else {
      setZoneValueError('Please select zone id')
    }
  }

  useEffect(() => {
    if(selectedVPC?.uid){
    const optionFormatter = (regionList) => {
     return regionList?.map(item => {
        return {
          DropdownLabel: item,
          DropdownValue: item
        }
      })
    }
     setZoneData(optionFormatter(location?.state?.regionList))
  } else {
    history.push('/vpc-endpoint-view');
  }

  }, []);

    // Close Modal Callback (Modal No Click Part)
    const closeModalCallback = useCallback((value) => {
        setShowSubmit(value);
        if(removeModalCode === 200){
         history.push('/vpc-endpoint-view');
        } 
      }, [removeModalCode]);

    // yesParentCallback
    const yesParentCallback = (value) =>{
      setShowCancel(value);
    }
    
    return (
        <>
        {apiLoading && (
        <Row>
          <Col>
            <div className='d-flex justify-content-center'>
              <SpinnerLoader />
            </div>
          </Col>
        </Row>
      )}
            {apiLoading === false && accountsApiError !== null && (
        <DangerAlert message={accountsApiError} />
      )}
      {apiLoading === false && accountsApiError === null && (
         <Col md={10}>
        <Row>
        <Col className='m-3'>
          <h5 className='page-content-title'>VPC Endpoint Disassociation</h5>
        </Col>
      </Row>
     
      <Form onSubmit={formSubmit}>

        <Row className='px-3 mt-3'>
          <Col md={6}>
            <TextInput
              label='Account Number:'
              name='account_number'
              value={selectedVPC?.accountnumber}
              placeholder='Account Number'
              isDisabled={true}
            />
          </Col>
          </Row>
          <Row className='px-3 mt-3'>
          <Col md={6}>
            <TextInput
             label='Account Name:'
             name='account_name'
              value={selectedVPC?.accountName}
              placeholder='Enter VPC Id'
              isDisabled={true}
            />
          </Col>
          </Row>
          <Row className='px-3 mt-3'>
          <Col md={6}>
            <TextInput
              label='VPC Id:'
              name='vpc_id'
              value={selectedVPC?.vpcId}
              placeholder='Enter VPC Id'
              isDisabled={true}
            />
          </Col>
          </Row>
          <Row className='px-3 mt-3'>
          <Col md={6}>
            <TextInput
              label='Region:'
              name='region'
              value={selectedVPC?.vpcregion}
              placeholder='Enter Region'
              isDisabled={true}
            />
          </Col>
          </Row>
          <Row className='px-3 mt-3 zone-multi-select'>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="selectZone">
                <Form.Label>Select Zone</Form.Label>
                <Multiselect
                  selectedValues={{}}
                  options={zoneData}
                  onSelect={(selectedList, selectedItem) => {
                    const selectedArr = [...selectedList]
                    setZoneValue(selectedArr);
                    setZoneValueError(null);
                  }}
                  onRemove={(selectedList, selectedItem) => {
                    const selectedArr = [...selectedList]
                    setZoneValue(selectedArr);
                    setZoneValueError(null);
                  }}
                  displayValue="DropdownLabel"
                  disablePreSelectedValues={true}
                  closeOnSelect={false}

                  singleSelect={false}
                  value={zoneValue}
                />

               {zoneValueError && <p className="custom-error-message">
                  {zoneValueError}
                </p>}
              </Form.Group>
            </Col>
            </Row>
    
        <Row className='px-3'>
          <div className='d-flex justify-content-start flex-row mt-3 col-md-6'>
            <ul className='px-0 btn-ul'>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    setShowCancel(true);
                  }}
                >
                  Cancel
                </button>
              </li>
              <li>
                <button type='submit' className='teal-filled-btn'>
                  Save & Continue
                </button>
              </li>
            </ul>
          </div>
        </Row>
      </Form>
      </Col>
      )}
      {showCancel && (
            <CustomModal
              showModal={true}
              children={
              <Cancel 
              closeModalParent={yesParentCallback} 
              />}
            />
          )}
    {showSubmit && (
        <CustomModal
          showModal={true}
          children={
            <Update
              mode='Remove'
              yesParent={closeModalCallback}
              status={removeModalCode}
            />
          }
        />
      )}
        </>
    )
}

export default VpcDisassociationForm;