import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import ApplicationView from './ApplicationView';

// Icons
import totalapplicationicon from 'icons/total-application-icon.svg';
import healthyicon from 'icons/healthy-icon.svg';
import unhealthyicon from 'icons/unhealthy-icon.svg';

import { CLOUD_EYE_MANAGEMENT_VIEW_DETAIL } from 'redux/cloudeye-management/cloudEyeManagementConstant';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

const ViewWidget = ({ loading, data, errorResponse, currentDomainName }) => {
  const dispatch = useDispatch();

  const cloudEyeManagement = useSelector((state) => state.cloudEyeManagement);
  const { cloudEyeManagementView } = cloudEyeManagement;

  function applicationHealth(value) {
    dispatch({
      type: CLOUD_EYE_MANAGEMENT_VIEW_DETAIL,
      payload: value,
    });
  }

  return (
    <>
      {loading && (
        <Row>
          <Col
            md={12}
            className='d-flex justify-content-center align-items-center mb-3'
          >
            <div className='custom-card mb-3 w-100'>
              <SpinnerLoader />
            </div>
          </Col>
        </Row>
      )}
      {loading === false && errorResponse !== null && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center mt-3'>
            <div className='custom-card mb-3'>
              <DangerAlert message={errorResponse} />
            </div>
          </Col>
        </Row>
      )}
      {loading === false && errorResponse === null && (
        <Row>
          <Col md={4} xs={12} className='mb-2'>
            <div
              className='custom-card mb-3 d-flex flex-column custom-pointer'
              onClick={() => applicationHealth('Total')}
              style={{ height: '200px' }}
            >
              <img
                src={totalapplicationicon}
                alt='Total'
                className='view-widget-icon'
              />
              <div className='view-widget-count px-2 mt-4'>{data.length}</div>
              <div className='view-widget-title px-2'>Total Applications</div>
            </div>
          </Col>
          <Col md={4} xs={12} className='mb-2'>
            <div
              className='custom-card mb-3 d-flex flex-column custom-pointer'
              onClick={() => applicationHealth('Healthy')}
              style={{ height: '200px' }}
            >
              <img
                src={healthyicon}
                alt='Healthy'
                className='view-widget-icon'
              />
              <div className='view-widget-count px-2 mt-4'>
                {data.filter((d) => d.Status === 'Healthy').length}
              </div>
              <div className='view-widget-title px-2'>Healthy</div>
            </div>
          </Col>
          <Col
            md={4}
            xs={12}
            className='mb-2'
            onClick={() => applicationHealth('Unhealthy')}
          >
            <div
              className='custom-card mb-3 d-flex flex-column custom-pointer'
              style={{ height: '200px' }}
            >
              <img
                src={unhealthyicon}
                alt='Unhealthy'
                className='view-widget-icon'
              />
              <div className='view-widget-count px-2 mt-4'>
                {data.filter((d) => d.Status === 'Unhealthy').length}
              </div>
              <div className='view-widget-title px-2'>Unhealthy</div>
            </div>
          </Col>
        </Row>
      )}

      {(cloudEyeManagementView === 'Total' ||
        cloudEyeManagementView === 'Healthy' ||
        cloudEyeManagementView === 'Unhealthy') && (
        <ApplicationView
          data={data}
          filter={cloudEyeManagementView}
          currentDomainName={currentDomainName}
        />
      )}
    </>
  );
};

export default ViewWidget;
