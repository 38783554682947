import React from 'react';
import { SecureRoute } from '@okta/okta-react';

const AuthRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <SecureRoute
    {...rest}
    render={(props) => {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default AuthRoute;
