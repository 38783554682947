import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import MenuTabs from '../MenuTabs';

import pageconstruction from 'images/pageconstruction.svg';

import { CLOUD_EYE_APPLICATION_NAME } from 'redux/cloudeye-user/cloueyeUserConstant';

import Api from "api/api";
import { useQueryClient } from "react-query";
import { useCloudListApplistApi } from "../dataloader";


const Automation = () => {

  useCloudListApplistApi();
  const queryClient = useQueryClient();
  const applist = queryClient.getQueryData([Api.cloudEyeAppList]);

  const isMounted = useRef(false);
  const dispatch = useDispatch();

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const cloudEye = useSelector((state) => state.cloudEye);
  const { cloudEyeApplicationName } = cloudEye;

  const [userApplicationDetails, setUserApplicationDetails] = useState([]);
  const [selectedApplicationName, setSelectedApplicationName] = useState('');

  // Application Bar State
  const [toggleApplicationBar, setToggleApplicationBar] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 767) {
      setToggleApplicationBar(true);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [toggleApplicationBar]);

  useEffect(() => {
    let userApplicationDetailsArray = [];
    for (var i = 0; i < userInfo.groups.length; i++) {
      const searchTerm = 'Monitoring';
      const indexOfFirst = userInfo.groups[i].indexOf(searchTerm);
      if (indexOfFirst === 7) {
        let temptext = userInfo.groups[i].substring(
          18,
          userInfo.groups[i].length
        );
        userApplicationDetailsArray.push(temptext);
      }
    }
    setUserApplicationDetails(userApplicationDetailsArray);
    setSelectedApplicationName(cloudEyeApplicationName);
    isMounted.current = true;
  }, []);

  return (
    <Row>
      {toggleApplicationBar ? (
        <Col md={2} className='px-0 custom-application-bar'>
          <div
            className='custom-arrow-icon'
            onClick={() => setToggleApplicationBar(false)}
          >
            <i className='fa fa-angle-left'></i>
          </div>
          <div className='application-name-container'>
            <span className='subtitle-text px-1'>Application Details</span>
            <Form.Group className='mt-3' value=''>
              <Form.Select
                // value={
                //   userApplicationDetails.includes(selectedApplicationName)
                //     ? selectedApplicationName
                //     : ''
                // }
                 value={
                  userApplicationDetails.filter(
                    (item) => item === 'selectedApplicationName'
                  )
                    ? selectedApplicationName
                    : ''
                }

                onChange={(e) => {
                  setSelectedApplicationName(e.target.value);
                  dispatch({
                    type: CLOUD_EYE_APPLICATION_NAME,
                    payload: `${e.target.value}`,
                  });
                }}
              >
                <option value=''>Select an Application</option>
                {applist.map((data, index) => (
                  <option key={index} value={data.trim()}>
                    {data.trim()}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
        </Col>
      ) : (
        <Col md={1} className='px-0 custom-application-bar'>
          <div
            className='custom-arrow-icon'
            onClick={() => setToggleApplicationBar(true)}
          >
            <i className='fa fa-angle-right'></i>
          </div>
          <div className='application-name-container'>
            <h5 className='application-bar-text-rotate'>Application Details</h5>
          </div>
        </Col>
      )}

      <Col md={toggleApplicationBar ? 10 : 11} className='px-3'>
        <MenuTabs />
        <div className='d-flex justify-content-center mt-3'>
          <img src={pageconstruction} alt='Coming Soon' />
        </div>
      </Col>
    </Row>
  );
};

export default Automation;
