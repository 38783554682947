import React from 'react';
import { useDispatch } from 'react-redux';

import { TREND_MICRO_SUBSCRIPTION_RELOAD } from 'redux/trend-micro-onboarding/subscriptionDetailsConstant';

const EditRequestSubmit = ( { formSubmitSuccessMsg } ) => {
  const dispatch = useDispatch();
  return (
    <div className='d-flex justify-content-center flex-column'>
      {formSubmitSuccessMsg === 400 ? 
      <p> Subscription already exists in Trend Micro Deep Security</p>
      :
      <p>Your subscription detail was updated and submitted successfully</p>
}
      <p>
        <button
          type='button'
          className='teal-filled-btn'
          onClick={() => {
            dispatch({
              type: TREND_MICRO_SUBSCRIPTION_RELOAD,
              payload: true,
            });
          }}
        >
          Ok
        </button>
      </p>
    </div>
  );
};

export default EditRequestSubmit;
