import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import { useSelector } from 'react-redux';

// Date Picker
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from 'react-modern-calendar-datepicker';

import { postApiCall } from 'helper/postApiCall';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

import TicketDetails from './TicketDetails';

const TicketChart = ({ loading, data, region, errorResponse }) => {
  const isMounted = useRef(false);

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const ticketApiUrl = `${process.env.REACT_APP_CLOUD_EYE_MANAGEMENT_TICKET}`;
  const idToken = `${userInfo.idToken}`;

  var currentDate = moment().format('YYYY/MM/DD');
  var checkCurrentDate = moment(currentDate, 'YYYY/MM/DD');

  // Past 3 Month Date
  var pastMonthDate = moment().subtract(3, 'months').format('YYYY/MM/DD');
  var checkPastMonthDate = moment(pastMonthDate, 'YYYY/MM/DD');

  // Default Date

  const defaultFrom = {
    year: parseInt(checkPastMonthDate.format('YYYY')),
    month: parseInt(checkPastMonthDate.format('MM')),
    day: parseInt(checkPastMonthDate.format('DD')),
  };

  const defaultTo = {
    year: parseInt(checkCurrentDate.format('YYYY')),
    month: parseInt(checkCurrentDate.format('MM')),
    day: parseInt(checkCurrentDate.format('DD')),
  };

  // Minmum & Maximum Date Range

  const minimumDateRange = {
    year: parseInt(checkPastMonthDate.format('YYYY')),
    month: parseInt(checkPastMonthDate.format('MM')),
    day: parseInt(checkPastMonthDate.format('DD')),
  };

  const maximumDateRange = {
    year: parseInt(checkCurrentDate.format('YYYY')),
    month: parseInt(checkCurrentDate.format('MM')),
    day: parseInt(checkCurrentDate.format('DD')),
  };

  // Date Picker Props
  const defaultValue = {
    from: defaultFrom,
    to: defaultTo,
  };
  const [selectedDayRange, setSelectedDayRange] = useState(defaultValue);

  // Component State
  const [teamDropDown, setTeamDropDown] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('All');
  const [dateDropdownValue, setDateDropdownValue] = useState('Weekly');
  const [startingDate, setStartingDate] = useState('');
  const [endingDate, setEndingDate] = useState('');
  const [search, setSearch] = useState(false);

  const [showCalendar, setShowCalendar] = useState(false);

  // Date Range Picker OnChange
  function datePickerOnChange(value) {
    setSelectedDayRange(value);

    if (value.from !== null && value.to !== null) {
      let mergeFromDate = `${value.from.year}-${value.from.month}-${value.from.day}`;
      let convertedFromDate = moment(mergeFromDate).format('DD-MM-YYYY');
      setStartingDate(convertedFromDate);
      let mergeToDate = `${value.to.year}-${value.to.month}-${value.to.day}`;
      let convertedToDate = moment(mergeToDate).format('DD-MM-YYYY');
      setEndingDate(convertedToDate);
    }
  }

  // Canvas State
  const [showCanvas, setShowCanvas] = useState(false);
  const [canvasTitle, setCanvasTitle] = useState('');

  const [dataInputError, setDataInputError] = useState({});

  const [ticketApiLoading, setTicketApiLoading] = useState(true);
  const [ticketApiError, setTicketApiError] = useState(null);

  const [xAxisData, setXAxisData] = useState([]);
  const [activeYAxisData, setActiveYAxisData] = useState([]);
  const [closedYAxisData, setClosedYAxisData] = useState([]);

  const closeTicketDetailCallback = useCallback((value) => {
    setShowCanvas(value);
  }, []);

  // ! Chart Datasets
  var remedyDataSet = [
    {
      name: 'Active',
      type: 'bar',
      stack: '1',
      barWidth: 15,
      data: activeYAxisData,
    },
    {
      name: 'Resolved',
      type: 'bar',
      stack: '1',
      barWidth: 15,
      data: closedYAxisData,
    },
  ];

  // ! Chart Options
  var option = {
    tooltip: {},
    legend: {
      show: false,
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        axisLine: {
          show: false,
        },
        data: xAxisData,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: function (value) {
            if (value < 1e3) return value;
            if (value >= 1e3) return +(value / 1e3).toFixed(1) + 'K';
          },
        },
      },
    ],
    series: remedyDataSet,
  };

  // Date Dropdown OnChange
  function getSelectedData(e) {
    const dropdownValue = e.target.value;
    setDateDropdownValue(dropdownValue);
    if (dropdownValue === 'Weekly') {
      setStartingDate(moment().subtract(7, 'd').format('DD-MM-YYYY'));
      setEndingDate(moment().format('DD-MM-YYYY'));
    }
    if (dropdownValue === 'Monthly') {
      setStartingDate(moment().subtract(30, 'd').format('DD-MM-YYYY'));
      setEndingDate(moment().format('DD-MM-YYYY'));
    }
    // if (dropdownValue === 'Custom Date') {
    //   setShowCalendar(true);
    // }
  }

  // ! Chart Click Functions
  function onChartClick(param, echarts) {
    // console.log(param.seriesName);
    setCanvasTitle(`${param.seriesName} Details`);
    setShowCanvas(true);
  }

  function legendClick(legendText) {
    setCanvasTitle(`${legendText} Details`);
    setShowCanvas(true);
  }

  // Search Function
  function searchTicketChart() {
    setShowCalendar(false);
    const ticketRequestBody = {
      region: region,
      teamname: `${selectedTeam}`,
      startdate: `${startingDate}`,
      enddate: `${endingDate}`,
      kind: 'count',
    };

    setTicketApiLoading(true);
    postApiCall(ticketApiUrl, idToken, ticketRequestBody)
      .then((response) => {
        // X Axis
        let tempXAxisArray = [];
        for (let i = 0; i < response.data['counts'].length; i++) {
          tempXAxisArray.push(response.data['counts'][i]['date']);
        }
        setXAxisData(tempXAxisArray);

        // Closed Y Axis
        let tempClosedYAxisArray = [];
        for (let i = 0; i < response.data['counts'].length; i++) {
          let tempClosedObj = {};
          tempClosedObj.value = response.data['counts'][i]['closed'];
          tempClosedObj.itemStyle = {
            color: '#3AC4AA',
          };
          tempClosedYAxisArray.push(tempClosedObj);
        }
        setClosedYAxisData(tempClosedYAxisArray);

        // Active Y Axis
        let tempActiveYAxisArray = [];
        for (let i = 0; i < response.data['counts'].length; i++) {
          let tempActiveObj = {};
          tempActiveObj.value = response.data['counts'][i]['active'];
          tempActiveObj.itemStyle = {
            color: '#B1EFE4',
          };
          tempActiveYAxisArray.push(tempActiveObj);
        }
        setActiveYAxisData(tempActiveYAxisArray);
        setTicketApiLoading(false);
      })
      .catch((err) => {
        setTicketApiError(
          'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
        );
        setTicketApiLoading(false);
      });
  }

  // Dependency Load
  useEffect(() => {
    if (isMounted.current) {
      searchTicketChart();
    }
    return () => {};
  }, [search]);

  // 1st Load
  useEffect(() => {
    setTicketApiLoading(true);
    const ticketRequestBody = {
      region: region,
      teamname: 'ALL',
      startdate: `${moment().subtract(7, 'd').format('DD-MM-YYYY')}`,
      enddate: `${moment().format('DD-MM-YYYY')}`,
      kind: 'count',
    };

    postApiCall(ticketApiUrl, idToken, ticketRequestBody)
      .then((response) => {
        // X Axis
        let tempXAxisArray = [];
        for (let i = 0; i < response.data['counts'].length; i++) {
          tempXAxisArray.push(response.data['counts'][i]['date']);
        }
        setXAxisData(tempXAxisArray);

        // Closed Y Axis
        let tempClosedYAxisArray = [];
        for (let i = 0; i < response.data['counts'].length; i++) {
          let tempClosedObj = {};
          tempClosedObj.value = response.data['counts'][i]['closed'];
          tempClosedObj.itemStyle = {
            color: '#3AC4AA',
          };
          tempClosedYAxisArray.push(tempClosedObj);
        }
        setClosedYAxisData(tempClosedYAxisArray);

        // Active Y Axis
        let tempActiveYAxisArray = [];
        for (let i = 0; i < response.data['counts'].length; i++) {
          let tempActiveObj = {};
          tempActiveObj.value = response.data['counts'][i]['active'];
          tempActiveObj.itemStyle = {
            color: '#B1EFE4',
          };
          tempActiveYAxisArray.push(tempActiveObj);
        }
        setActiveYAxisData(tempActiveYAxisArray);

        // Set Team
        setTeamDropDown(response.data['Teams']);
        setTicketApiLoading(false);
      })
      .catch((err) => {
        setTicketApiError(
          'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
        );
        setTicketApiLoading(false);
      });

    isMounted.current = true;

    return () => {
      setXAxisData([]);
      setActiveYAxisData([]);
      setClosedYAxisData([]);
      setTeamDropDown([]);
      setSelectedTeam('All');
      setDateDropdownValue('Weekly');
    };
  }, []);

  return (
    <>
      <Row>
        <Col md={12}>
          <div className='custom-card mb-3'>
            <Row>
              <Col md={3} xs={12} className='mb-2'>
                <div className='subtitle-text'>Ticket Details</div>
              </Col>
              {ticketApiLoading && (
                <Col md={12} className='d-flex justify-content-center'>
                  <SpinnerLoader />
                </Col>
              )}
              {ticketApiLoading === false && ticketApiError === null && (
                <>
                  <Col md={3} xs={12} className='mb-2'>
                    <Form.Select
                      size='sm'
                      value={selectedTeam.trim()}
                      onChange={(e) => {
                        setSelectedTeam(e.target.value);
                      }}
                      isInvalid={dataInputError.selectedTeam ? true : false}
                    >
                      <option value='All'>All</option>
                      {teamDropDown.map((data, index) => (
                        <option key={index} value={data.trim()}>
                          {data.trim()}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col md={4} xs={12} className='mb-2 d-flex flex-column'>
                    <Form.Select
                      className='mb-2'
                      size='sm'
                      value={dateDropdownValue}
                      onChange={(e) => {
                        getSelectedData(e);
                      }}
                    >
                      <option value='Weekly'>Weekly</option>
                      <option value='Monthly'>Monthly</option>
                      <option value='Custom Date'>Custom Date</option>
                    </Form.Select>
                  </Col>
                  <Col md={2} xs={12} className='mb-2'>
                    <button
                      type='button'
                      className='teal-filled-btn w-100'
                      onClick={() => {
                        searchTicketChart();
                      }}
                    >
                      <i className='fa fa-search'></i>
                    </button>
                  </Col>
                  {dateDropdownValue === 'Custom Date' && (
                    <>
                      <Col md={12}>
                        {/* {startingDate !== '' && endingDate !== '' && (
                          <div className='snooze-alarm-title d-flex justify-content-center'>
                            Start Date: {startingDate} - End Date: {endingDate}
                          </div>
                        )} */}
                        <div className='d-flex justify-content-center mb-2'>
                          <button
                            className='teal-bordered-download-btn-sm'
                            onClick={() => {
                              setShowCalendar(!showCalendar);
                            }}
                          >
                            {showCalendar
                              ? 'Hide Calendar'
                              : 'Select Date Range'}
                          </button>
                        </div>
                        {/* {showCalendar && (
                          <div className='snooze-alarm-title mt-3 d-flex justify-content-center'>
                            Select Date Range :
                          </div>
                        )} */}
                      </Col>
                      {showCalendar && (
                        <Col md={12} className='d-flex justify-content-center'>
                          <Calendar
                            value={selectedDayRange}
                            onChange={(value) => {
                              setSelectedDayRange(value);
                              datePickerOnChange(value);
                            }}
                            colorPrimary='#3ac4aa'
                            colorPrimaryLight='#ddf3ee'
                            shouldHighlightWeekends
                            minimumDate={minimumDateRange}
                            maximumDate={maximumDateRange}
                          />
                        </Col>
                      )}
                    </>
                  )}
                </>
              )}
            </Row>

            {ticketApiLoading === false && ticketApiError === null && (
              <>
                <Row>
                  <Col md={12} className='d-flex justify-content-end flex-wrap'>
                    <ul className='remedy-legend'>
                      <li onClick={() => legendClick('Resolved')}>
                        <div className='remedy-incident'></div>
                        Resolved
                      </li>
                      <li onClick={() => legendClick('Active')}>
                        <div className='remedy-workorder'></div>
                        Active
                      </li>
                    </ul>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div style={{ height: '305px', width: '100%' }}>
                      <ReactECharts
                        option={option}
                        onEvents={{
                          click: onChartClick,
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}

            {ticketApiLoading === false && ticketApiError !== null && (
              <Row>
                <Col className='d-flex justify-content-center align-items-center mt-3'>
                  <DangerAlert message={ticketApiError} />
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>

      {showCanvas === true && (
        <TicketDetails
          title={canvasTitle}
          startDate={startingDate}
          endDate={endingDate}
          region={region}
          team={selectedTeam}
          userInfo={userInfo}
          closeTicketDetailParent={closeTicketDetailCallback}
        />
      )}
    </>
  );
};

export default TicketChart;
