import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

import ViewBox from './ViewBox';

// Import Icon
import dots from 'icons/3-dots.svg';

// Roles
import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

// Helper
import { postApiCall } from 'helper/postApiCall';

const RequestTrainingTable = () => {
  const { t } = useTranslation();
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // API Variables
  const apiUrl = `${process.env.REACT_APP_REQUEST_TRAINING_VIEW}`;
  const idToken = `${userInfo.idToken}`;
  let requestBody = '';
  if (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)) {
    requestBody = `{"type_of_user":1}`;
  } else {
    requestBody = `{"submittedBy":"${userInfo.email}", "type_of_user":0}`;
  }

  // API State
  const [apiLoading, setApiLoading] = useState(true);
  const [apiResponseData, setApiResponseData] = useState([]);
  const [apiError, setApiError] = useState(null);

  // Table Callback
  const fetchTableData = useCallback(() => {
    setApiLoading(true);
    postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        setApiResponseData(response.data);
        setApiLoading(false);
      })
      .catch((err) => {
        setApiError(
          'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
        );
        setApiLoading(false);
      });
  }, [apiUrl, idToken, requestBody]);

  // ! Option Part
  const [options, setOptions] = useState({
    showOption: null,
    selectedOptionUID: null,
    selectedOptionData: {},
  });

  // Option Click Handler
  function optionHandler(selectedOptionDataValues) {
    if (options.showOption) {
      setOptions({
        showOption: false,
        selectedOptionUID: selectedOptionDataValues.uid,
        selectedOptionData: selectedOptionDataValues,
      });
    } else {
      setOptions({
        showOption: true,
        selectedOptionUID: selectedOptionDataValues.uid,
        selectedOptionData: selectedOptionDataValues,
      });
    }
  }

  // ! View Part
  // ! View Box State & Callback
  const [showViewBox, setShowViewBox] = useState(false);

  // ! Close View Callback
  const closeViewCallback = useCallback((value) => {
    setShowViewBox(value);
  }, []);

  // Datatable Variables & Options
  const columns = [
    {
      dataField: 'dummyUid',
      isDummyField: true,
      text: t("request_id"),
      sort: true,
      formatter: (cell, row) => {
        return <span className='text-uppercase'>{`${row.uid}`}</span>;
      },
    },
    {
      dataField: 'gid',
      text: t("gid"),
      sort: true,
      searchable: true,
    },
    {
      dataField: 'submittedBy',
      text: t("email"),
      sort: true,
      searchable: true,
    },
    {
      dataField: 'type',
      text: t("cloud_type"),
      sort: true,
      searchable: true,
    },
    {
      dataField: 'service',
      text: t("cloud_service"),
      sort: true,
      searchable: true,
    },
    {
      dataField: 'uid',
      text: t("created_on"),
      sort: true,
      formatter: (cellContent, row) => {
        let dateConverted = moment(parseInt(row.uid.split('-')[1])).format(
          'MMM DD, YYYY hh:mm A'
        );
        return <span>{dateConverted}</span>;
      },
      sortFunc: (a, b, order) => {
        let dateConvertedA = moment(parseInt(a.split('-')[1])).format(
          'MMM DD, YYYY hh:mm A'
        );
        let dateConvertedB = moment(parseInt(b.split('-')[1])).format(
          'MMM DD, YYYY hh:mm A'
        );
        if (order === 'asc') {
          return Date.parse(dateConvertedA) - Date.parse(dateConvertedB);
        } else if (order === 'desc') {
          return Date.parse(dateConvertedB) - Date.parse(dateConvertedA);
        }
      },
    },
    {
      dataField: 'dummyActionField',
      text: '',
      formatExtraData: options,
      formatter: (cellContent, row) => {
        return (
          <div
            className='custom-table-option-conatiner'
            onClick={() => {
              optionHandler(row);
            }}
          >
            <img src={dots} alt='action' className='p-2' />
            {options.showOption === true &&
              options.selectedOptionUID === row.uid && (
                <div className='custom-table-option'>
                  <ul>
                    <li
                      onClick={() => {
                        setShowViewBox(true);
                      }}
                    >
                      {t('view')}
                    </li>
                  </ul>
                </div>
              )}
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: 'uid',
      order: 'desc',
    },
  ];

  const customTotal = (from, to, size) => (
    <span className='react-bootstrap-table-pagination-total'>
      {t("showing_results",{from,to,size})}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: apiResponseData.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    fetchTableData();
    return () => {
      setApiResponseData([]);
    };
  }, [fetchTableData]);

  return (
    <div>
      {apiLoading && <SpinnerLoader />}

      {apiLoading === false && (
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField='uid'
          columns={columns}
          data={apiResponseData}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField='uid'
              columns={columns}
              data={apiResponseData}
              search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  <Row className='mb-2'>
                    <Col md={6} className='d-flex align-items-center mb-2'>
                      <h5 className='page-content-title'>
                        {t("cloud_training_request")}
                      </h5>
                    </Col>

                    <Col md={3} className='mb-2'>
                      <div className='search-input-group'>
                        <i className='fa fa-search'></i>
                        <SearchBar
                          {...toolkitProps.searchProps}
                          className='custom-search-input-form-control'
                          placeholder={t("search_placeholder")}
                        />
                      </div>
                    </Col>

                    <Col md={3} className='mb-2'>
                      <Link to='/request-training-add'>
                        <button className='teal-filled-btn w-100'>
                          <i className='fa fa-plus mx-2'></i>
                          {t("new_request")}
                        </button>
                      </Link>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl='12' className='mt-4'>
                      <div className='table-responsive'>
                        <BootstrapTable
                          keyField={'uid'}
                          responsive
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={'table align-middle table-nowrap'}
                          headerWrapperClasses={
                            'custom-table-head custom-pointer'
                          }
                          bodyClasses={'custom-table-body'}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className='align-items-md-center mt-3'>
                    <Col className='d-flex flex-wrap justify-content-between align-items-baseline'>
                      <div>
                        <PaginationTotalStandalone {...paginationProps} />
                      </div>
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      )}

      {apiLoading === false &&
        apiError === null &&
        apiResponseData.length === 0 && (
          <div className='d-flex justify-content-center align-items-center'>
            {t("no_data_to_display")}
          </div>
        )}

      {apiLoading === false && apiError !== null && (
        <DangerAlert message={apiError} />
      )}

      {/* View Box */}
      {showViewBox && (
        <ViewBox
          data={options.selectedOptionData}
          closeViewParent={closeViewCallback}
        />
      )}
    </div>
  );
};

export default RequestTrainingTable;
