import SpinnerLoader from "components/Common/SpinnerLoader";
import DangerAlert from "components/Message/DangerAlert";
import Table from "components/Table";
import { postApiCall } from "helper/postApiCall";
import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import calendar from "../../images/calendar.png";

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function InvoiceBreakdownTable({ selAccountLists, cloud }) {
  const { t } = useTranslation();

  const selAccounts = useMemo(() => {
    return selAccountLists?.map(acc => acc.DropdownValue)
  }, [selAccountLists]);

  const [isLoading, setIsLoading] = useState(false);
  const [filterDate, setfilterDate] = useState(new Date());

  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const [data, setData] = useState([]);
  const [apiError, setApiError] = useState(null);

  const idToken = `${userInfo.idToken}`;
  const apiURL = `${process.env.REACT_APP_BILLING_COST_REPORT}`;

  useEffect(() => {
    let requestBody = {
      cloud,
      accounts: selAccounts,
      month: month[filterDate?.getMonth()],
      year: filterDate?.getFullYear() ? filterDate?.getFullYear() + "" : "",
    };

    const fetchData = async () => {
      setIsLoading(true);
      postApiCall(apiURL, idToken, requestBody)
        .then((response) => {
          setData(response.data || []);
          setIsLoading(false);
        })
        .catch((err) => {
          setApiError(t("failed_to_retrieve_data"));
          setIsLoading(false);
        });
    };

    fetchData();
  }, [selAccounts, cloud, filterDate]);

  if (isLoading === false && apiError !== null)
    return (
      <Row>
        <Col md={12}>
          <DangerAlert message={apiError} />
        </Col>
      </Row>
    );

  return (
    <div className="custom-card">
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <>
          <div className="month-datepicker d-flex align-items-center">
            <img
              src={calendar}
              alt="month-datepicker-image"
              className="calendar-img"
            />
            <DatePicker
              dateFormat="MMMM yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
              selected={filterDate}
              onChange={(date) => setfilterDate(date)}
            />
          </div>

          <div className="billing-custom-table">
            <Table
              striped
              bordered
              columns={columns}
              data={data}
              idField="id"
              text="Invoice Breakdown"
              downloadEnabled
            />
          </div>
        </>
      )}
    </div>
  );
}

const columns = [
  {
    dataField: "accountname",
    text: "ACCOUNT NAME",
    sort: true,
    searchable: true,
  },
  {
    dataField: "accountnumber",
    text: "ACCOUNT NUMBER",
    sort: true,
    searchable: true,
  },
  {
    dataField: "hostingcharges",
    text: "HOSTING CHARGES",
    sort: true,
    searchable: true,
  },
  {
    dataField: "global",
    text: "GLOBAL SUPPORT FTC",
    sort: true,
    searchable: true,
  },
  {
    dataField: "securitytool",
    text: "SECURITY TOOL COST TO EGIS/GIS",
    sort: true,
    searchable: true,
  },
  {
    dataField: "totalcost",
    text: "TOTAL COST",
    sort: true,
    searchable: true,
  },
];
