import React, { useReducer, useEffect, useState } from "react";
import { AWSReducer, DefaultAWSData } from "../edit-aws-reducer";

// Static Data
import { dayOfWeek, weeksData } from "../../staticData";

import EditEnableAccountFormSteps from "./EditEnableAccountFormSteps";

const EditEnableAccountForm = ({
  data,
  instanceList,
  handleEditWindowClose,
}) => {
  let [step, setStep] = useState(0);
  const [formData, setFormData] = useReducer(AWSReducer, DefaultAWSData);
  let steps = EditEnableAccountFormSteps(
    step,
    setStep,
    formData,
    setFormData,
    data,
    instanceList,
    handleEditWindowClose
  );
  const weekOfMonth = weeksData.find((item) => item.name === data?.week);
  const weekVal = weekOfMonth?.name;
  const dayOfWeekData = dayOfWeek.find((item) => item.name === data?.dow);
  const dowVal = dayOfWeekData?.name;

  useEffect(() => {
    const initialInstances = data?.instances ? data?.instances : null;
    const mappedInstances = [];
    if (initialInstances) {
      const regionList = data?.instances?.map((item) => Object.keys(item)[0]);

      let temp;
      for (let i = 0; i <= regionList.length - 1; i++) {
        temp = initialInstances[i][regionList[i]];
        for (let j = 0; j <= temp?.length - 1; j++) {
          mappedInstances.push(initialInstances[i][regionList[i]][j]);
        }
      }
    }
    setFormData({
      type: "UPDATE_INSTALLATION_SCHEDULE",
      payload: {
        [`startDateHour`]: data?.hour ? data?.hour : "",
        [`startDateMinute`]: data?.minute ? data?.minute : "",
        [`week`]: weekVal ? weekVal : "",
        [`dow`]: dowVal ? dowVal : "",
      },
    });

    setFormData({
      type: "UPDATE_TARGET_INSTANCE",
      payload: {
        [`instances`]: mappedInstances?.length ? mappedInstances : "",
        [`tagName`]: data?.tags?.tagName ? data?.tags?.tagName : "",
        [`tagDescription`]: data?.tags?.tagDescription
          ? data?.tags?.tagDescription
          : "",
        [`region`]: data?.tags?.region ? data?.tags?.region : "",
      },
    });
    // Request Status
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`requestStatus`]: data?.requestStatus },
    });
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`uid`]: data?.uid },
    });
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`aws_account_id`]: data?.aws_account_id },
    });
    if (
      data.requestStatus === "Approved" ||
      data.requestStatus === "Update Declined"
    ) {
      setFormData({
        type: "UPDATE_COMMON_DETAILS",
        payload: { [`unEditableAccountName`]: data?.accountName },
      });
    }
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`RequestActionTakenBy`]: data?.RequestActionTakenBy },
    });
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`submittedBy`]: data?.submittedBy },
    });
  }, []);
  return <>{steps[`${step}`].content}</>;
};

export default EditEnableAccountForm;
