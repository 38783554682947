import React from "react";

// Import Internal Components
import EditToolsException from "./EditBackUpRequest";

const EditFormSteps = (
  step,
  setStep,
  formData,
  setFormData,
  accountNumberNameData,
  accountNumberArray
) => {
  return [
    {
      title: "AWS Backup Request",
      content: (
        <EditToolsException
          setStep={setStep}
          step={step}
          formData={formData}
          setFormData={setFormData}
          accountNumberNameData={accountNumberNameData}
          accountNumberArray={accountNumberArray}
        />
      ),
    },
  ];
};

export default EditFormSteps;
