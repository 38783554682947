import {
  helpAndSupportCreateApi,
  helpAndSupportListApi,
} from "api/helpAndSupport";
import { useQuery, useQueryClient } from "react-query";
import { useOktaAuth } from "@okta/okta-react";
import Api from "api/api";
import { apiWrapper } from "pages/UserCloudEye/dataloader";
import { errorToast, successToast } from "components/Notification";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";

export function useHelpAndSupportListApi() {
  const { authState } = useOktaAuth();
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const isAdmin = userInfo?.groups?.includes(
    "CS Hub - AWS Account Request Admin"
  );
  const postBody = isAdmin
    ? null
    : { submittedBy: authState.idToken.claims.email };

  return useQuery(
    [Api.helpAndSupport],
    apiWrapper(helpAndSupportListApi, {
      ...postBody,
      idToken: authState.idToken.idToken,
    }),
    {
      enabled: !!userInfo,
      initialData: [],
      onError: (res) => {
        errorToast("failed to fetch support requests");
        return "failed to fetch support requests";
      },
    }
  );
}

export function useCreateSupportTicket(selectedApplicationName) {
  const queryClient = useQueryClient();
  return useMutation(helpAndSupportCreateApi, {
    onSuccess: (res) => {
      queryClient.refetchQueries([Api.helpAndSupport]);
      successToast(res.message);
    },
  });
}
