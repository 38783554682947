import React, { useCallback } from 'react';

// Import Internal Components
import EditAccountContact from './EditAccountContact';
import EditBillingContact from './EditBillingContact';
import EditSecurityContact from './EditSecurityContact';
import EditTechnicalContact from './EditTechnicalContact';
import EditBillingInfo from './EditBillingInfo';
import EditGroupEmailAddress from './EditGroupEmailAddress';
import EditAccountDetails from './EditAccountDetails';

const EditFormSteps = (step, setStep, formData, setFormData) => {
  return [
    {
      title: 'Account Contact',
      content: (
        <EditAccountContact
          setStep={setStep}
          step={step}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      title: 'Billing Contact',
      content: (
        <EditBillingContact
          setStep={setStep}
          step={step}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      title: 'Security Contact',
      content: (
        <EditSecurityContact
          setStep={setStep}
          step={step}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      title: 'Technical Contact',
      content: (
        <EditTechnicalContact
          setStep={setStep}
          step={step}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      title: 'Billing Info',
      content: (
        <EditBillingInfo
          setStep={setStep}
          step={step}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      title: 'Group Email Address',
      content: (
        <EditGroupEmailAddress
          setStep={setStep}
          step={step}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      title: 'Account details',
      content: (
        <EditAccountDetails
          setStep={setStep}
          step={step}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
  ];
};

export default EditFormSteps;
