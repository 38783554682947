import React from 'react';
import { Form } from 'react-bootstrap';

const TextInput = ({
  label,
  children,
  type = 'text',
  placeholder,
  isInvalid,
  invalidMessage,
  isInfoBubbleAvailable,
  infoBubbleTitle,
  isDisabled,
  isReadOnly,
  ...otherProps
}) => (
  <div>
    <>
      <Form.Group className='mb-3'>
        <Form.Label>
          {label}
          {isInfoBubbleAvailable && (
            <i
              className='fa fa-info-circle px-2'
              data-toggle='tooltip'
              data-placement='bottom'
              title={infoBubbleTitle}
            ></i>
          )}
        </Form.Label>
        <Form.Control
          type={type}
          placeholder={placeholder}
          isInvalid={isInvalid}
          disabled={isDisabled}
          readOnly={isReadOnly}
          {...otherProps}
        />
        <Form.Control.Feedback type='invalid'>
          {invalidMessage}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  </div>
);

export default TextInput;
