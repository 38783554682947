import React, { useEffect, useCallback, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";

// Import Icons
import backicon from "icons/back.svg";

// Helper
import { postApiCall } from "helper/postApiCall";

import SpinnerLoader from "components/Common/SpinnerLoader";
import DangerAlert from "components/Message/DangerAlert";
import Pagination from "components/Pagination/Pagination";
import CustomModal from "components/Modal/CustomModal";
import DeleteAlarmConfirm from "./DeleteAlarmConfirm";

const ViewAlarm = ({ application, namespace, closeViewParent, id }) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // API State
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [error, setError] = useState(null);
  const [delAlarmNameList, setDelAlarmNameList] = useState([]);
  const [showDelModal, setShowDelModal] = useState(false);

  // API Variables
  const apiUrl = `${process.env.REACT_APP_CLOUD_EYE_VIEW_ALARMS}`;
  const idToken = `${userInfo.idToken}`;
  const requestBody = {
    application: `${application.trim()}`,
    namespace: `${namespace}`,
    identifier: id,
  };

  // Pagination Part
  let PageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch Data
  const fetchData = useCallback(() => {
    setLoading(true);
    postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        setResponseData(response.data.body);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(
          "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
        );
        setLoading(false);
      })
      .finally(() => {
        setDelAlarmNameList([]);
      });
  }, [requestBody]);

  const handleAlarmSelection = (isChecked, alarmName) => {
    if (!isChecked) {
      setDelAlarmNameList((l) => l.filter((aName) => aName !== alarmName));
    } else {
      setDelAlarmNameList((l) => {
        const updatedList = l.filter((aName) => aName !== alarmName);
        return [...updatedList, alarmName];
      });
    }
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return responseData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, responseData, PageSize]);


  useEffect(() => {
    fetchData();
    return () => {
      setResponseData([]);
      setDelAlarmNameList([]);
    };
  }, []);

  if (!id) {
    alert("View Alarm id not present");
    return null;
  }
  return (
    <div className="view-edit-box-conatiner pb-5" style={{ width: "640px" }}>
      <div className="view-edit-box-top-bar">
        <div className="view-edit-box-back-title-container w-auto">
          <img
            src={backicon}
            alt="Back"
            className="view-edit-box-back-btn"
            onClick={() => closeViewParent(false)}
          />
          <div>
            <h5 className="view-edit-box-title">Alarm Details</h5>
          </div>
        </div>

        {/* <button
          type="button"
          className="teal-filled-btn"
          onClick={() => {
            delAlarmNameList.length > 0 && setShowDelModal(true);
          }}
        >
          Delete
        </button> */}
      </div>
      {loading && <SpinnerLoader />}
      {loading === false && error !== null && <DangerAlert message={error} />}
      {loading === false && error === null && responseData.length === 0 && (
        <div className="custom-error-message text-center mt-4">
          No Alarms Set For This Resource
        </div>
      )}
      {loading === false && error === null && responseData.length > 0 && (
        <div className="table-view-box-content mt-4 mb-5">
          {currentTableData.map((data, index) => (
            <Row key={index}>
              {/* <Col
                md={1}
                className="d-flex justify-content-center align-items-center"
              >
                <Form.Check
                  id={`alarm-checkbox-${data.split("|")[1]}`}
                  name={`alarm-checkbox-${data.split("|")[1]}`}
                  type="checkbox"
                  checked={delAlarmNameList.find((al) => {
                    return al == data.split("|")[1];
                  })}
                  onChange={(event) =>
                    handleAlarmSelection(
                      event.target.checked,
                      data.split("|")[1]
                    )
                  }
                />
              </Col> */}
              <Col md={11} className="mb-3 view-alarm-row">
                <div className="view-alarm-container">
                  <Row>
                    <Col md={3} className="mb-1 px-3">
                      <div className="view-alarm-key mb-2">STATE</div>
                      <div className="view-alarm-data">
                        {data.split("|")[0]}
                      </div>
                    </Col>
                    <Col md={5} className="mb-1 px-3">
                      <div className="view-alarm-key mb-2">NAME</div>
                      <div className="view-alarm-data">
                        {data.split("|")[1]}
                      </div>
                    </Col>
                    <Col md={3} className="mb-1 px-3">
                      <div className="view-alarm-key mb-2">DESCRIPTION</div>
                      <div className="view-alarm-data">
                        {data.split("|")[2]}
                      </div>
                    </Col>
                    <Col
                      md={1}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <i className="fa fa-angle-down"></i>
                    </Col>
                    <Row className="view-alarm-sub-data mx-0 px-0">
                      <Col md={3} className="mt-1 mb-2 px-3">
                        <div className="view-alarm-key mb-2">UPDATED TIME</div>
                        <div className="view-alarm-data">
                          {data.split("|")[4]}
                        </div>
                      </Col>
                      <Col md={5} className="mt-1 mb-2 px-3">
                        <div className="view-alarm-key mb-2">
                          ALERT STATE REASON
                        </div>
                        <div className="view-alarm-data">
                          {data.split("|")[5]}
                        </div>
                      </Col>
                      <Col md={3} className="mt-1 mb-2 px-3">
                        <div className="view-alarm-key mb-2">METRIC</div>
                        <div className="view-alarm-data">
                          {data.split("|")[3]}
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </div>
              </Col>
            </Row>
          ))}
          <div className="d-flex justify-content-end align-items-baseline">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={responseData.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
              currentTableDataLength={currentTableData.length}
            />
          </div>
        </div>
      )}
      <CustomModal
        showModal={showDelModal}
        children={
          <DeleteAlarmConfirm
            closeModalParent={() => setShowDelModal(false)}
            yesParent={fetchData}
            delAlarmList={delAlarmNameList}
            application={application}
          />
        }
      />
    </div>
  );
};

export default ViewAlarm;
