import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from 'components/Common/Loader';
import AlertMessage from 'components/Common/AlertMessage';

import { postApiCall } from 'helper/postApiCall';

const AccountRequestsApprove = ({
  yesParent,
  closeModalParent,
  status,
  uid,
}) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successApproved, setSuccessApproved] = useState(false);

  // async function pendingToApproved() {
  //   setLoading(true);
  //   const idToken = userInfo.idToken;
  //   const requestBody = `{"uid":"${uid}"}`;

  //   const apiFirst = `${process.env.REACT_APP_AZURE_PENDING_TO_APPROVED_FIRST_URL}`;

  //   const apiSecond = `${process.env.REACT_APP_TREND_MICRO_AWS_ACCOUNT_REQUEST_NEW_REQUEST_SUBMIT}`;

  //   const firstApiUrl = apiFirst;
  //   await postApiCall(firstApiUrl, idToken, requestBody).then((response) => {
  //     if (response.data.status === 'Success') {
  //       pendingtoApprovedSecondAPI();
  //     } else {
  //       setError('Failed to create account');
  //     }
  //   });

  //   async function pendingtoApprovedSecondAPI() {
  //     const requestBody = `{}`;
  //     const secondApiUrl = `${apiSecond}?uid=${uid}&type=1&reqstatus=${status}&admingid=${userInfo.preferred_username}&adminemail=${userInfo.email}`;
  //     await postApiCall(secondApiUrl, idToken, requestBody).then((response) => {
  //       if (response.data.status === 'Success') {
  //         setSuccessApproved(true);
  //         setLoading(false);
  //       }
  //     });
  //   }
  // }

  async function pendingApprovalforUpdateToApprove() {
    setLoading(true);
    const idToken = userInfo.idToken;
    const requestBody = `{}`;
    const getStatus = status;
    const replaceSpaceWithUnderscore = getStatus.replace(/ /g, '_');
    const setStatus = replaceSpaceWithUnderscore;
    const apiUrl = `${process.env.REACT_APP_TREND_MICRO_AZURE_ACCOUNT_REQUEST_EDIT_REQUEST_SUBMIT}`;
    const setApiUrl = `${apiUrl}?uid=${uid}&type=1&reqstatus=${setStatus}&admingid=${userInfo.preferred_username}&adminemail=${userInfo.email}`;

    await postApiCall(setApiUrl, idToken, requestBody).then((response) => {
      if (response.data.message === "Successfully Approved.") {
        setSuccessApproved(true);
      } else {
        setError('Failed to updated');
      }
    });
  }

  return (
    <div className='d-flex justify-content-center flex-column'>
      {successApproved === false && (
        <>
          <p>Are you sure you want to approve this request</p>
          {error !== null && (
            <div className='d-flex justify-content-center flex-column'>
              <AlertMessage
                cssClassName='alert-message-danger'
                alertMessage={error}
              />
            </div>
          )}
          {loading && <Loader />}
          {loading === false && error === null && (
            <ul className='btn-ul'>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    closeModalParent(null);
                  }}
                >
                  No
                </button>
              </li>
              <li>
                <button
                  type='button'
                  className='teal-filled-btn'
                  onClick={() => {
                     pendingApprovalforUpdateToApprove();
                  }}
                >
                  Yes
                </button>
              </li>
            </ul>
          )}
        </>
      )}
      {successApproved === true && (
        <>
          <p>Request Approved!</p>
          <p>
            <button
              type='button'
              className='teal-filled-btn'
              onClick={() => {
                yesParent(null);
              }}
            >
              Ok
            </button>
          </p>
        </>
      )}
    </div>
  );
};

export default AccountRequestsApprove;
