import React from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import {useTranslation} from 'react-i18next';

// Import Logo
import awsLogo from 'images/aws-logo.svg';

const WorkspaceSidebar = () => {
  // Routing Variables
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const {t}=useTranslation();

  return (
    <div className='passport-sidebar-container'>
      <ul className='passport-sidebar-logo-list'>
        <li>
          <img src={awsLogo} alt='AWS' />
          <span className='passport-sidebar-title px-2'>Workspaces</span>
        </li>
      </ul>

      <>
        <ul className='passport-sidebar-link-list'>
          <li
            className={
              splitLocation[1] === 'aws-workspaces-view'
                ? 'passport-sidebar-link-active'
                : 'passport-sidebar-link-inactive'
            }
          >
            <Link to='/aws-workspaces-view'>{t('view_request')}</Link>
          </li>
          <li
            className={
              splitLocation[1] === 'aws-workspaces-add'
                ? 'passport-sidebar-link-active'
                : 'passport-sidebar-link-inactive'
            }
          >
            <Link to='/aws-workspaces-add'>{t('request_workspace')}</Link>
          </li>
        </ul>
      </>
    </div>
  );
};

export default withRouter(WorkspaceSidebar);
