import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import { ACCOUNT_REQUEST_RELOAD } from 'redux/account-requests/accountRequestConstant';

const EditRequestSubmit = ({message = ''}) => {
  const dispatch = useDispatch();
  const history  = useHistory()
  const handleOnclick = () => {
    if(message === "Bulk"){
      history.push("/aws-account-update-requests")
    }
    else {
    dispatch({
      type: ACCOUNT_REQUEST_RELOAD,
      payload: true,
    });
  }
  }
  return (
    <div className='d-flex justify-content-center flex-column'>
      {/* <p>{message === "Bulk" ? "Bulk Update Request submitted successfully. You will be notified on successful updation": 'Update Request Edited Successfully'} </p> */}
      <p>Update Request Submitted Successfully</p>
      <p>
        <button
          type='button'
          className='teal-filled-btn'
          onClick={() => {handleOnclick()}}
        >
          Ok
        </button>
      </p>
    </div>
  );
};

export default EditRequestSubmit;
