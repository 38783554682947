import React from 'react';
import { useTranslation } from 'react-i18next';

const FormProgress = ({
  accountContact,
  securityContact,
  technicalContact,
  billingInfo,
  accountDetails,
}) => {
  const {t}=useTranslation();
  return (
<ol className='FormStepList'>
         <li className='FormStepList-item'>
        {accountDetails ? (
          <span className='FormStepIcon  is-passed'>
            <span className='FormStepIcon-circle'>
              <i className='fa fa-check'></i>
            </span>
            <span className='FormStepIcon-title-active'>{t("Subscription Details")}</span>
          </span>
        ) : (
          <span className='FormStepIcon '>
            <span className='FormStepIcon-circle'></span>
            <span className='FormStepIcon-title'>{t("Subscription Details")}</span>
          </span>
        )}
      </li>
      <li className='FormStepList-item'>
        {accountContact ? (
          <span className='FormStepIcon is-passed'>
            <span className='FormStepIcon-circle'>
              <i className='fa fa-check'></i>
            </span>
            <span className='FormStepIcon-title-active'>{t("Subscription Contact")}</span>
          </span>
        ) : (
          <span className='FormStepIcon'>
            <span className='FormStepIcon-circle'></span>
            <span className='FormStepIcon-title'>{t("Subscription Contact")}</span>
          </span>
        )}
      </li>

      {/* <li className='FormStepList-item'>
        {billingContact ? (
          <span className='FormStepIcon is-passed'>
            <span className='FormStepIcon-circle'>
              <i className='fa fa-check'></i>
            </span>
            <span className='FormStepIcon-title-active'>{t("billing_contact")}</span>
          </span>
        ) : (
          <span className='FormStepIcon'>
            <span className='FormStepIcon-circle'></span>
            <span className='FormStepIcon-title'>{t("billing_contact")}</span>
          </span>
        )}
      </li> */}

      <li className='FormStepList-item'>
        {securityContact ? (
          <span className='FormStepIcon is-passed'>
            <span className='FormStepIcon-circle'>
              <i className='fa fa-check'></i>
            </span>
            <span className='FormStepIcon-title-active'>{t("security_contact")}</span>
          </span>
        ) : (
          <span className='FormStepIcon'>
            <span className='FormStepIcon-circle'></span>
            <span className='FormStepIcon-title'>{t("security_contact")}</span>
          </span>
        )}
      </li>

      <li className='FormStepList-item'>
        {technicalContact ? (
          <span className='FormStepIcon is-passed'>
            <span className='FormStepIcon-circle'>
              <i className='fa fa-check'></i>
            </span>
            <span className='FormStepIcon-title-active'>{t("technical_contact")}</span>
          </span>
        ) : (
          <span className='FormStepIcon'>
            <span className='FormStepIcon-circle'></span>
            <span className='FormStepIcon-title'>{t("technical_contact")}</span>
          </span>
        )}
      </li>

      <li className='FormStepList-item'>
        {billingInfo ? (
          <span className='FormStepIcon FormStepIcon--last is-passed'>
            <span className='FormStepIcon-circle'>
              <i className='fa fa-check'></i>
            </span>
            <span className='FormStepIcon-title-active'>{t("billing_info")}</span>
          </span>
        ) : (
          <span className='FormStepIcon FormStepIcon--last'>
            <span className='FormStepIcon-circle'></span>
            <span className='FormStepIcon-title'>{t("billing_info")}</span>
          </span>
        )}
      </li>

      {/* <li className='FormStepList-item'>
        {groupEmailAddress ? (
          <span className='FormStepIcon is-passed'>
            <span className='FormStepIcon-circle'>
              <i className='fa fa-check'></i>
            </span>
            <span className='FormStepIcon-title-active'>
              {t("group_email_address")}
            </span>
          </span>
        ) : (
          <span className='FormStepIcon'>
            <span className='FormStepIcon-circle'></span>
            <span className='FormStepIcon-title'>{t("group_email_address")}</span>
          </span>
        )}
      </li> */}

   
    </ol>
  );
};

export default FormProgress;
