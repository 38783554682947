import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

// Import Icons
import backicon from 'icons/back.svg';

// Helper
import { postApiCall } from 'helper/postApiCall';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';
import TextInput from 'components/Forms/TextInput';
import CustomModal from 'components/Modal/CustomModal';

import CancelForm from './CancelForm';
import SubmitForm from './SubmitForm';

const R53SetAlarm = ({ application, closeSetAlarmParent }) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // Close Modal Callback (Modal No Click Part)
  const closeFormCallback = useCallback((value) => {
    closeSetAlarmParent(value);
  }, []);

  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const closeSubmitModalCallback = useCallback((value) => {
    setShowSubmitModal(value);
  }, []);

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Form State
  const [url, setUrl] = useState('');

  // API State
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [error, setError] = useState(null);

  // API Variables
  const apiUrl = `${process.env.REACT_APP_CLOUD_EYE_R53_SET_ALARM}`;
  const idToken = `${userInfo.idToken}`;
  const requestBody = {
    application: `${application.trim()}`,
    Url: `${url}`,
    RequesterEmail: `${userInfo.email}`,
  };

  // Form Submit
  function formHandler() {
    if (url === '') {
      setDataInputError({
        url: 'Url is required',
      });
    } else {
      setLoading(true);
      postApiCall(apiUrl, idToken, requestBody)
        .then((response) => {
          if (response.data.status === 'success') {
            setResponseData(
              'Your request is submitted. You will recieve email notification on successful alert setup'
            );
          } else {
            setResponseData(
              'Error setting up the alarm, Contact Sony Global Cloud Team for Assistance'
            );
          }
          setShowSubmitModal(true);
          setLoading(false);
        })
        .catch((err) => {
          setError(
            'Error setting up the alarm, Contact Sony Global Cloud Team for Assistance'
          );
          setLoading(false);
        });
    }
  }

  return (
    <div className='view-edit-box-conatiner pb-5'>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => closeSetAlarmParent(false)}
          />
          <div>
            <h5 className='view-edit-box-title'>Create R53 Health Check</h5>
          </div>
        </div>
      </div>
      {loading && <SpinnerLoader />}
      {loading === false && error !== null && <DangerAlert message={error} />}
      {loading === false && error === null && (
        <div className='mt-4'>
          <Row>
            <Col md={8}>
              <TextInput
                label='Application Name:'
                name='applicationName'
                value={application}
                placeholder='Application Name'
                isDisabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <TextInput
                label='URL:'
                name='url'
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder='URL'
                isInvalid={dataInputError.url ? true : false}
                invalidMessage={dataInputError.url}
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <TextInput
                label='Requestor Email:'
                name='requestorEmail'
                value={userInfo.email}
                placeholder='Requestor Email'
                isDisabled={true}
              />
            </Col>
          </Row>
          <Row className='px-3'>
            <Col md={8} className='d-flex justify-content-end'>
              <ul className='px-0 btn-ul'>
                <li>
                  <button
                    type='button'
                    className='teal-bordered-btn'
                    onClick={() => {
                      setShowCancelModal(true);
                    }}
                  >
                    Cancel
                  </button>
                </li>
                <li>
                  <button
                    type='button'
                    className='teal-filled-btn'
                    onClick={() => formHandler()}
                  >
                    Submit
                  </button>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      )}
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={
            <CancelForm
              closeModalParent={closeModalCallback}
              closeFormParent={closeFormCallback}
            />
          }
        />
      )}

      {showSubmitModal && (
        <CustomModal
          showModal={true}
          children={
            <SubmitForm
              message={responseData}
              closeSubmitModalParent={closeSubmitModalCallback}
              closeFormParent={closeFormCallback}
            />
          }
        />
      )}
    </div>
  );
};

export default R53SetAlarm;
