import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import Loader from "components/Common/Loader";
import { getApiCall } from "helper/getApiCall";
import { postApiCall } from "helper/postApiCall";
import { cardChargebackData } from "static-contents/billing-data";

const BillingHomeCard = ({
  cardData,
  children,
  setShowInvoiceGeneration = () => {},
  fetchData = () => {},
  setDownloadModalOpen = () => {},
  versionHistory = "Version History",
  handleDownloadInvoice = () => {},
  handleDownloadReports = () => {},
}) => {
  const history = useHistory();

  const handleNavigation = (item) => {
    if (item?.category !== "Invoices Actions" && item?.link) {
      history.push(item?.link);
    } else if (item?.title === "Generate Invoices") {
      setShowInvoiceGeneration(true);
    } else if (item?.title === "Download Invoices") {
      setDownloadModalOpen(true);
    } else if (item?.category === "Reports") {
      const reportType =
        item?.title === "GitLab Report" ? "gitlab" : "workspace";
      handleDownloadReports(reportType);
    }
  };
  const handleVersionClick = async (e, item) => {
    e.stopPropagation();
    console.log(item);
    fetchData(item?.categoryName);
  };

  return (
    <Row>
      {!cardData[0]?.updatedby ? (
        <>
          {cardData?.map((item) => (
            <Col md={3}>
              <div
                className={`custom-card rounded-3 px-0 py-0 position-relative  billing--home--card`}
                onClick={() => {
                  handleNavigation(item);
                }}
              >
                <p className="pb-1 mb-2"></p>

                <div
                  className={`text-end  border-bottom border-1 fs-11 px-4 mb-3  ${
                    item?.category !== "Invoices Actions" &&
                    item?.category !== "Reports"
                      ? "py-3  pt-2"
                      : "padding-top-25"
                  }`}
                >
                  <span className="billing-view-detail">
                    {item?.category !== "Invoices Actions" &&
                    item?.category !== "Reports"
                      ? versionHistory
                      : " "}
                  </span>
                </div>

                <p
                  className=" mb-0 billing-mod-icon"
                  style={{ backgroundColor: item?.color }}
                >
                  {item?.name}
                </p>

                <div className="py-5">
                  <Loader />
                </div>
              </div>
            </Col>
          ))}
        </>
      ) : (
        cardData.map((item) => (
          <>
            <Col md={3}>
              <div
                className={`custom-card rounded-3 px-0 py-0 position-relative  billing--home--card`}
                onClick={() => {
                  handleNavigation(item);
                }}
              >
                <p className="pb-1 mb-2"></p>

                <div
                  className={`text-end  border-bottom border-1 fs-11 px-4 mb-3  ${
                    item?.title !== "Generate Invoices"
                      ? "py-3  pt-2"
                      : "padding-top-25"
                  }`}
                >
                  {item?.title === "Download Invoices" ||
                  item?.category === "Reports" ? (
                    <span
                      className="billing-view-detail"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item?.title === "Download Invoices") {
                          handleDownloadInvoice("currentMonth", "download");
                        } else {
                          const reportType =
                            item?.title === "GitLab Report"
                              ? "gitlab"
                              : "workspace";
                          handleDownloadReports(reportType);
                        }
                      }}
                    >
                      {`${versionHistory} ${
                        item?.category === "Reports" ? "Report" : "Invoice"
                      }`}
                    </span>
                  ) : (
                    item?.title !== "Generate Invoices" &&
                    item?.category !== "Reports" && (
                      <span
                        className="billing-view-detail"
                        onClick={(e) => {
                          handleVersionClick(e, item);
                        }}
                      >
                        {versionHistory}
                      </span>
                    )
                  )}
                </div>

                <p
                  className=" mb-0 billing-mod-icon"
                  style={{ backgroundColor: item?.color }}
                >
                  {item?.name}
                </p>

                <>
                  <p className="px-4 pt-4 mb-0 chargeBack-action">
                    {item?.category}
                  </p>
                  <p className={`${"px-4"}  mb-0 chargeBack-heading`}>
                    {item?.title}
                  </p>

                  <p className={`px-4 mb-1 chargeBack-desc description`}>
                    {item?.desc}
                  </p>

                  <div className="updated-by-div">
                    <p
                      className={`px-4 mb-0 pt-2 chargeBack-desc details ${
                        item?.title === "Download Invoices" ||
                        item?.category === "Reports"
                          ? "invisible"
                          : ""
                      }`}
                    >
                      {item?.title === "Generate Invoices"
                        ? "Generated On"
                        : "Last Updated"}
                      :{" "}
                      {`${item?.updateddate || "-"}, ${
                        item?.updatedtime || "-"
                      }`}
                    </p>
                    <p
                      className={`px-4 pb-3 mb-0 chargeBack-desc details last ${
                        item?.title === "Download Invoices" ||
                        item?.category === "Reports"
                          ? "invisible"
                          : ""
                      } `}
                    >
                      By: {item?.updatedby || "-"}
                    </p>
                  </div>
                </>
              </div>
            </Col>
          </>
        ))
      )}
    </Row>
  );
};

export default BillingHomeCard;
