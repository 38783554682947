import React, { useState, useRef, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import MenuTabs from '../MenuTabs';

import pageconstruction from 'images/pageconstruction.svg';

const Automation = () => {
  const isMounted = useRef(false);
  // const dispatch = useDispatch();

  // Redux State
  // const user = useSelector((state) => state.user);
  // const { userInfo } = user;

  const cloudEye = useSelector((state) => state.cloudEye);
  const { cloudEyeApplicationName } = cloudEye;

  const [selectedApplicationName, setSelectedApplicationName] = useState('');

  useEffect(() => {
    setSelectedApplicationName(cloudEyeApplicationName);
    isMounted.current = true;
  }, []);

  return (
    <Row>
      <Col md={12} className='px-3'>
        <MenuTabs applicationName={selectedApplicationName} />
        <div className='d-flex justify-content-center mt-3'>
          <img src={pageconstruction} alt='Coming Soon' />
        </div>
      </Col>
    </Row>
  );
};

export default Automation;
