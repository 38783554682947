import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = ({ msg }) => {
  return (
    <>
      <div className="d-flex justify-content-center mt-5">
        <Spinner animation="border" />
      </div>
      {msg && (
        <div className="text-center mt-3">
          <h6>{msg}</h6>
        </div>
      )}
    </>
  );
};

export default Loader;
