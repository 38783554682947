import viewMetrics from "icons/metrics-icon.svg";
import { useTranslation } from "react-i18next";

export default function ViewMetricsIcon(props) {
  const {t} = useTranslation();
  return (
    <img
      src={viewMetrics}
      alt="view metrics icon"
      title={t("view_metrics")}
      {...props}
    />
  );
}
