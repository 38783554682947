import React from "react";

const BackUpPlanComponent = () => (
  <>
    <ul className="warning-ul">
      Backup plans are created with default parameters and will start the backup
      process once tag the resource with default-backup=true. The plan rules and
      resource assignments can be changed after it is been created.
    </ul>
  </>
);

export default BackUpPlanComponent;
