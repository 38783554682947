import React, { useRef, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// Helper
import { postApiCall } from 'helper/postApiCall';

// Components
import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

// Internal

import LineChart from './LineChart';

// Icon
import backicon from 'icons/back.svg';

import { CLOUD_EYE_APPLICATION_NAME } from 'redux/cloudeye-user/cloueyeUserConstant';

const DynamoDB = ({ application, instanceId, history, displayname }) => {
  const isMounted = useRef(false);
  const dispatch = useDispatch();

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // API Variables
  const apiUrl = `${process.env.REACT_APP_CLOUD_EYE_DYNAMODB_METRICS}`;
  const idToken = `${userInfo.idToken}`;
  let requestBody = {
    application: application,
    instid: instanceId,
    days: '30',
  };

  // API State
  const [apiLoading, setApiLoading] = useState(true);
  const [apiError, setApiError] = useState(null);
  const [apiResponseData, setApiResponseData] = useState([]);

  useEffect(() => {
    // Role Checking Part
    const tempArray = [];
    for (var i = 0; i < userInfo.groups.length; i++) {
      const searchTerm = 'CS Hub-Monitoring-';
      const indexOfFirst = userInfo.groups[i].indexOf(searchTerm);
      if (indexOfFirst > -1) {
        tempArray.push(indexOfFirst);
      }
    }

    if (tempArray.includes(-1) || tempArray.length === 0) {
      history.push('/landing');
    } else {
      setApiLoading(true);
      postApiCall(apiUrl, idToken, requestBody)
        .then((response) => {
          setApiResponseData(response.data);
          setApiLoading(false);
        })
        .catch((err) => {
          setApiError(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setApiLoading(false);
        });
      isMounted.current = true;
    }

    return () => {};
  }, []);

  return (
    <>
      {apiLoading && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center mt-3'>
            <SpinnerLoader />
          </Col>
        </Row>
      )}
      {apiLoading && apiError !== null && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center mt-3'>
            <DangerAlert message={apiError} />
          </Col>
        </Row>
      )}
      {apiLoading === false && (
        <>
          <Row>
            <Col md={1} className='px-3 mb-3 d-flex align-items-center'>
              <Link
                to={`/cloudeye-management-user-view-dashboard/${application}`}
              >
                <div>
                  <img
                    src={backicon}
                    alt='Back'
                    className='view-edit-box-back-btn'
                  />
                </div>
              </Link>
            </Col>
            <Col md={11} className='px-3 mb-3'>
              <div>
                <span className='metrics-label-title'>Application Name</span> :{' '}
                <span className='metrics-value-title'>{application}</span>
              </div>
              <div>
                <span className='metrics-label-title'>Resource ID</span> :{' '}
                <span className='metrics-value-title'>{instanceId}</span>
              </div>
              <div>
                <span className='metrics-label-title'>Resource Name</span> :{' '}
                <span className='metrics-value-title'>{displayname}</span>
              </div>
            </Col>
          </Row>

          <Row>
            {Object.keys(apiResponseData).map((data, index) => (
              <Col md={6} className='px-3' key={index}>
                <div className='custom-card mb-3'>
                  <LineChart
                    data={apiResponseData}
                    application={application}
                    instanceId={instanceId}
                    objectKeyData={Object.keys(apiResponseData)[index]}
                    chartTitle={Object.keys(apiResponseData)[index]}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </>
      )}
    </>
  );
};

export default withRouter(DynamoDB);
