import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { ExportJsonCsv } from 'react-export-json-csv';


import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

import ViewUsers from './ViewUsers';

// Icon
import backicon from 'icons/back.svg';

// Helper
import { postApiCall } from 'helper/postApiCall';
import { useTranslation } from 'react-i18next';


const csvHeaderList = [
  {
    key: 'group',
    name: 'Okta Group/Role name',
  },
  {
    key: 'status',
    name: 'User Status - In Okta',
  },
  {
    key: 'name',
    name: 'Name',
  },
  {
    key: 'gid',
    name: 'GID',
  },
  {
    key: 'email',
    name: 'Email',
  },
]

const RoleDetails = ({
  accountNumber,
  accountName,
  selectedAccountNumber,
  selectedAccountName,
}) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const {t} = useTranslation();
  // API State
  const [apiLoading, setApiLoading] = useState(true);
  const [apiError, setApiError] = useState(null);
  const [apiData, setApiData] = useState([]);
  const fileTitle = `${accountNumber}-role-details `
  // Table State

  // View State and Callback
  const [selectedRoleName, setSelectedRoleName] = useState(null);
  const [showViewBox, setShowViewBox] = useState(false);

  // for download csv
  const [formattedCsvData, setFormattedCsvData] = useState([]);

  const closeViewBoxCallback = useCallback((value) => {
    setShowViewBox(value);
  }, []);

  const columns = [
    {
      dataField: 'roleName',
      text: 'ROLES',
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (row.userCount > 0) {
          return (
            <div
              type='button'
              className='okta-view-assignment-datatable-value'
              onClick={() => {
                setSelectedRoleName(row.roleName);
                setShowViewBox(true);
              }}
            >
              {row.roleName}
            </div>
          );
        } else {
          return row.roleName;
        }
      },
    },
    {
      dataField: 'userCount',
      text: 'NO. OF USERS',
      sort: true,
      searchable: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: 'roleName',
      order: 'asc',
    },
  ];

  const customTotal = (from, to, size) => (
    <span className='react-bootstrap-table-pagination-total'>
      Showing {from} to {to} of {size}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: apiData.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  // API Variable
  const idToken = `${userInfo.idToken}`;

  useEffect(() => {
    // API Variables
    const apiURL = `${process.env.REACT_APP_OKTA_VIEW_ASSIGNMENT_USERS}`;
    let requestBody = {
      accountnumber: `${accountNumber}`,
      accountname: `${accountName}`,
    };
    const fetchDetails = async () => {
      await postApiCall(apiURL, idToken, requestBody)
        .then((response) => {
          let tempArray = [];
          for (let i = 0; i < Object.keys(response.data).length; i++) {
            let tempObj = {};
            tempObj['roleName'] = Object.keys(response.data)[i];
            tempObj['userCount'] = Object.values(response.data)[i].length;
            tempArray.push(tempObj);
          }
          if(response?.data) {
            const finalCsvData = []
            Object.keys(response?.data).map(k => { return response?.data[k].map(item => {
              finalCsvData.push({...item,
                group: k,
                gid: `"${item?.gid}"`
              })
            }) 
            });
            setFormattedCsvData(finalCsvData)
          }
          setApiData(tempArray);
          setApiLoading(false);
        })
        .catch((err) => {
          setApiError(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setApiLoading(false);
        });
    };

    fetchDetails();
    return () => {
      setApiData([]);
    };
  }, [accountNumber, accountName]);

  const CsvFileComponent = () => <>
      <ExportJsonCsv fileTitle={fileTitle} headers={csvHeaderList} items={formattedCsvData} className="border-0 px-0 py-0 w-100">
      <button class="teal-filled-btn w-100"><i class="fa fa-download mx-2"></i>{t("download")}</button>
      </ExportJsonCsv>
  </>

const NoteText = () =>  <>
<p className='role-note-text text-secondary margin-left-8 note-text-border'>
  <span  className='font--bold text-dark note-text'>{t("Note")}:</span>
  <span>** {t('The below Okta groups/roles  will no longer be supported')} 
   <span className='font--bold text-dark'> {t("post Sept 15th.")}</span>
    </span><br></br>

<span>{t("Start migrating your teams to the new Okta groups ( powered by AWS IAM Center )  by having them request access")} </span><br></br>

<span>{t("through “Okta Access Requests” from OKTA homepage . Custom roles have to requested separately")}</span>
<br></br>
<span className='role-note-text text-secondary'>{t("For further queries - Drop an email to")}<a className="text-primary text-decoration-underline font--bold">{" <"}Sony_Cloud_Support_Global@sony.com{">"}</a> **</span>
</p>
</>
  return (
    <>
      <Row>
        <Col>
          {apiLoading && (
            <div className='d-flex flex-column justify-content-center'>
              <SpinnerLoader />
              <div className='text-center regular-text mt-3'>
                {t("Please wait this might take few seconds to load the data...")}
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        {apiLoading === false && apiError !== null && (
          <DangerAlert message={apiError} />
        )}
      </Row>
      <Row>
        {apiLoading === false && (
          <Col md={12}>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField='roleName'
              columns={columns}
              data={apiData}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField='roleName'
                  columns={columns}
                  data={apiData}
                  search
                >
                  {(toolkitProps) => (
                    <>
                      <Row className='d-flex align-items-center'>
                        <Col md={6} className='d-flex align-items-center mb-3'>
                          <div>
                            <img
                              src={backicon}
                              alt='Back'
                              className='view-edit-box-back-btn'
                              onClick={() => {
                                selectedAccountNumber('');
                                selectedAccountName('');
                              }}
                            />
                          </div>
                          <div className='subtitle-text px-2'>ROLES</div>
                        </Col>
                        <Col md={3} className='mb-2'>
                          {formattedCsvData?.length ? 
                          <CsvFileComponent/>
                          : null }
                          </Col>
                        {apiData.length > 0 && (
                          <Col md={3} className='mb-2'>
                            <div className='search-input-group'>
                              <i className='fa fa-search'></i>
                              <SearchBar
                                {...toolkitProps.searchProps}
                                placeholder={t("search_placeholder")}
                                className='custom-search-input-form-control'
                              />
                            </div>
                          </Col>
                        )}
                      </Row>
                      <Row>
                      {/* <Col md={8}>
                        <NoteText />
                      </Col> */}
                      <Col md={4}></Col>
                      </Row>

                      <>
                        <Row>
                          <Col md={12} xl={12} className='mt-4'>
                            <div className='table-responsive'>
                              <BootstrapTable
                                keyField={'roleName'}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={'table align-middle table-nowrap'}
                                headerWrapperClasses={
                                  'custom-table-head custom-pointer'
                                }
                                bodyClasses={'custom-table-body'}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                          <Col>
                            {apiData.length === 0 && (
                              <div className='custom-error-message text-center'>
                                No Data to Display
                              </div>
                            )}
                          </Col>
                        </Row>

                        <Row className='align-items-md-center mt-3'>
                          <Col className='d-flex flex-wrap justify-content-between align-items-baseline'>
                            <div>
                              <PaginationTotalStandalone {...paginationProps} />
                            </div>
                            <div>
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </>
                    </>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </Col>
        )}

        {showViewBox && (
          <ViewUsers
            title={selectedRoleName}
            role={selectedRoleName}
            accountNumber={accountNumber}
            accountName={accountName}
            closeContainer={closeViewBoxCallback}
          />
        )}
      </Row>
    </>
  );
};

export default RoleDetails;
