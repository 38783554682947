import React, { useState, useEffect } from 'react';

const PageContent = ({ children }) => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 767) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return (
    // <div className='page-content page-content-padding' id='page-content'>
    <div
      className={
        'page-content ' + (!isMobileView ? 'page-content-padding' : '')
      }
      id='page-content'
    >
      {children}
    </div>
  );
};

export default PageContent;
