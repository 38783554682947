import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

import "css/common/alert-message.css";

// Component
import PageContentSidebar from "components/PageContent/PageContentSidebar";

//helper
import { getApiCall } from "helper/getApiCall";

// Import Internal Components
import BillingDetail from "./BillingDetail";

// Import Constant
import { USER_INFO } from "redux/user/userConstant";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import NotFound from "pages/NotFound";
import {
  CS_Hub_AWS_Account_Request_Admin,
  CS_Hub_Cloud_Chargeback_Admins,
  CS_Hub_GCS_Chargeback_Admins,
} from "roles/roles";

const ModificationViewIndex = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch]);
  // Redux State

  const userAccess = userInfo?.groups?.includes(CS_Hub_GCS_Chargeback_Admins)
    ? true
    : false;

  return (
    <>
      {userInfo !== null &&
        authState &&
        authState.isAuthenticated &&
        (userAccess ? (
          <>
            <Container fluid>
              <Row>
                <Col md={12}>
                  <BillingDetail />
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <NotFound notAuthorised />
        ))}
    </>
  );
};

export default ModificationViewIndex;
