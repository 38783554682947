import { TAB_ERROR_VALUES, IS_DIRTY_APPLICAION_NAME  } from './editValueConstant';

export const editValueReducer = (
  state = {
    tabErrorReduxValues: {},
    is_dirty_application_name: false,
  },
  action
) => {
  switch (action.type) {
    case TAB_ERROR_VALUES:
      return { ...state, tabErrorReduxValues: action.payload };
    case IS_DIRTY_APPLICAION_NAME:
      return { ...state, is_dirty_application_name: action.payload }; 
    default:
      return state;
  }
};
