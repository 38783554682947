import React from "react";

const DrBackUpComponent = () => (
  <>
    <ul className="warning-ul">
      Cross region backup is optional and works for EC2, RDS, EFS and S3. Cross
      region backup for DynamoDB is not supported. The following regions are
      supported as primary and cross region backup:
      <li>{"us-east-1 <> us-east-2"}</li>
      <li>{"us-west-1<>us-west-2"}</li>
      <li>{"ap-southeast-1<>ap-south-1"}</li>
      <li>{"eu-west-1<>eu-west-2"}</li>
      <li>{"ap-northeast-1<>ap-northeast-3"}</li>
    </ul>
  </>
);

export default DrBackUpComponent;
