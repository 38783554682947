import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { useTranslation } from "react-i18next";

// Component
import PageContentSidebar from "components/PageContent/PageContentSidebar";

// Logo
import awsLogo from "images/aws-logo.svg";
import azureLogo from "images/azure-logo.svg";
import gcpLogo from "icons/google-cloud.svg";

// Import Internal Components
import NewRequest from "./NewRequest";

// Import Constant
import { USER_INFO } from "redux/user/userConstant";

const AzureNewRequestIndex = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch]);
  return (
    <>
      {userInfo !== null && authState && authState.isAuthenticated && (
        <>
          <Container fluid>
            <Row>
              <Col md={2} className="px-0">
                <PageContentSidebar
                  type="accountRequest"
                  firstLogoLink="/aws-account-requests"
                  firstLogoImg={awsLogo}
                  fisrtLogoAlt="AWS"
                  firstLogoFirstMatchingLink="aws-account-requests"
                  firstLogoSecondMatchingLink="aws-new-account-request"
                  firstLogoFirstLinkText={t("view_request")}
                  firstLogoSecondLinkText={t("account_request")}
                  secondLogoLink="/azure-account-requests"
                  secondLogoImg={azureLogo}
                  secondLogoAlt="Azure"
                  secondLogoFirstMatchingLink="azure-account-requests"
                  secondLogoSecondMatchingLink="azure-new-account-request"
                  secondLogoFirstLinkText={t("view_request")}
                  secondLogoSecondLinkText={t("account_request")}
                  thirdLogoLink="/gcp-account-requests"
                  thirdLogoImg={gcpLogo}
                  thirdLogoAlt="GCP"
                  thirdLogoFirstMatchingLink="gcp-account-requests"
                  thirdLogoSecondMatchingLink="gcp-new-account-request"
                  thirdLogoFirstLinkText={t("view_request")}
                  thirdLogoThirdLinkText={t("account_request")}
                  showLinks="yes"
                />
              </Col>
              <Col md={10}>
                <h5 className="page-content-title mt-4">
                  {t("azure_account_requests")}
                </h5>
                <NewRequest />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default AzureNewRequestIndex;
