import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Row, Col } from 'react-bootstrap';
import axios from 'axios';

import SpinnerLoader from 'components/Common/SpinnerLoader';

import Pagination from 'components/Pagination/Pagination';

import backicon from 'icons/back.svg';

// Import Constant
import { TREND } from '../../redux/dashboard/dashboardConstant';

const TrendCanvas = ({ title, selectedLegend, userInfo }) => {
  // API State
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  // Set Legend Label
  const [canvasSelectLegend, setCanvasSelectLegend] = useState(selectedLegend);

  // Filtered State
  const [filteredLoading, setFilteredLoading] = useState(true);
  const [selectedLableValues, setSelectedLableValues] = useState([]);

  const dispatch = useDispatch();
  function hideCanvas() {
    dispatch({
      type: TREND,
      payload: false,
    });
  }

  // API Variables
  const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

  // Pagination Part
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch Data
  const fetchPrismaData = useCallback(() => {
    setFilteredLoading(true);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: setIdToken,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_TREND_MICRO_DETAILS}`,
        {
          status: `${canvasSelectLegend}`,
        },
        config
      )
      .then((response) => {
        setResponseData(response.data.body);

        let selectedLabelData = response.data.body.filter(
          (d) => d.ComputerStatus === canvasSelectLegend
        );

        setSelectedLableValues(selectedLabelData);

        setLoading(false);
        setFilteredLoading(false);
      });
  }, [canvasSelectLegend]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return selectedLableValues.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, selectedLableValues, PageSize]);

  useEffect(() => {
    fetchPrismaData();
    return () => {
      setResponseData([]);
    };
  }, [fetchPrismaData, canvasSelectLegend]);

  return (
    <div className='offcanvas-details-wrapper pb-5'>
      {loading && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center'>
            <SpinnerLoader />
          </Col>
        </Row>
      )}
      {loading === false && (
        <>
          <div className='offcanvas-details-top-bar my-3'>
            <div className='offcanvas-details-back-heading'>
              <img
                src={backicon}
                alt='Back'
                className='view-edit-box-back-btn'
                onClick={() => hideCanvas()}
              />
              <h5 className='view-edit-box-title d-flex align-items-center'>
                {title}
              </h5>
            </div>
            <div className='offcanvas-legend-details'>
              <ul className='canvas-legend-list-wrapper'>
                <li
                  className={
                    canvasSelectLegend === 'active'
                      ? 'canvas-legend-list-active'
                      : 'canvas-legend-list'
                  }
                  onClick={() => setCanvasSelectLegend('active')}
                >
                  <div className='canvas-legend-list-green'></div>
                  Managed
                </li>

                <li
                  className={
                    canvasSelectLegend === 'inactive'
                      ? 'canvas-legend-list-active'
                      : 'canvas-legend-list'
                  }
                  onClick={() => setCanvasSelectLegend('inactive')}
                >
                  <div className='canvas-legend-list-yellow'></div>
                  Unmanaged
                </li>

                <li
                  className={
                    canvasSelectLegend === 'error'
                      ? 'canvas-legend-list-active'
                      : 'canvas-legend-list'
                  }
                  onClick={() => setCanvasSelectLegend('error')}
                >
                  <div className='canvas-legend-list-red'></div>
                  Error
                </li>

                <li
                  className={
                    canvasSelectLegend === 'warning'
                      ? 'canvas-legend-list-active'
                      : 'canvas-legend-list'
                  }
                  onClick={() => setCanvasSelectLegend('warning')}
                >
                  <div className='canvas-legend-list-orange'></div>
                  Warning
                </li>
              </ul>
            </div>
          </div>

          {filteredLoading && (
            <Row>
              <Col className='d-flex justify-content-center align-items-center'>
                <SpinnerLoader />
              </Col>
            </Row>
          )}

          {loading === false &&
            filteredLoading === false &&
            currentTableData.length === 0 && (
              <p className='text-center'>No data to display</p>
            )}

          {loading === false &&
            filteredLoading === false &&
            currentTableData.length > 0 && (
              <>
                <Table
                  responsive
                  className=' mb-0 table-centered table-nowrap table-borderless'
                >
                  <thead className='offcanvas-details-table-head-font'>
                    <tr className='mb-2'>
                      <td>ACCOUNT NAME</td>
                      <td>ACCOUNT ID</td>
                      <td>HOST ID</td>
                      <td>HOST NAME</td>
                      <td>STATUS</td>
                    </tr>
                  </thead>
                  <tbody className='offcanvas-details-table-body-font'>
                    {currentTableData.map((data, index) => (
                      <tr key={index} className='mb-3'>
                        <td>{data.AccountName}</td>
                        <td>{data.Accountnumber}</td>
                        <td>{data.hostname}</td>
                        <td>{data.DisplayName}</td>
                        <td align='center'>
                          {canvasSelectLegend === 'error' && (
                            <div className='badge-wrapper red-badge'>
                              {data.ComputerStatus}
                            </div>
                          )}
                          {canvasSelectLegend === 'inactive' && (
                            <div className='badge-wrapper yellow-badge'>
                              {data.ComputerStatus}
                            </div>
                          )}
                          {canvasSelectLegend === 'active' && (
                            <div className='badge-wrapper green-badge'>
                              {data.ComputerStatus}
                            </div>
                          )}

                          {canvasSelectLegend === 'warning' && (
                            <div className='badge-wrapper orange-badge'>
                              {data.ComputerStatus}
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className='d-flex justify-content-end align-items-baseline'>
                  <Pagination
                    className='pagination-bar'
                    currentPage={currentPage}
                    totalCount={responseData.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                    currentTableDataLength={currentTableData.length}
                  />
                </div>
              </>
            )}
        </>
      )}
    </div>
  );
};

export default TrendCanvas;
