import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form, Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import Form Component
import TextInput from 'components/Forms/TextInput';
import Loader from 'components/Common/Loader';
import BillingCompanyDropdown from 'components/Forms/BillingCompanyDropdown';
import BillingSubCompanyDropdown from 'components/Forms/BillingSubCompanyDropdown';
import BillingDepartmentDropdown from 'components/Forms/BillingDepartmentDropdown';
import StaticDropdown from 'components/Forms/StaticDropdown';
import CustomModal from 'components/Modal/CustomModal';

// Import Internal Components
import FormProgress from './FormProgress';
import NewRequestSubmit from './NewRequestSubmit';
import NewRequestCancel from './NewRequestCancel';

// Helper
import { getApiCall } from 'helper/getApiCall';
import { postApiCall } from 'helper/postApiCall';

// Validator
import { accountDetailsValidate, subscriptionIDValidation } from '../../Validator/validator';

// Static Data
import {
  staticEnvironmentType,
  staticAssetUserBase,
  staticDataClassification,
} from '../StaticData/awsStaticData';
import { TREND_MICRO_SUBSCRIPTION_DETAILS } from 'redux/trend-micro-onboarding/subscriptionDetailsConstant';
import { CS_Hub_AWS_Account_Request_Admin, Everyone_Role } from 'roles/roles';

const AccountDetails = ({ step, setStep, formData, setFormData }) => {
  const {t}=useTranslation();
  const dispatch = useDispatch();

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // Get subscription details
  const allSubscriptionDetails = useSelector(
    (state) => state.trendMicroSubscriptions
  );
  const { trendMicroSubscriptionDetails } = allSubscriptionDetails

  const [apiError, setApiError] = useState(null);

  // Set ID Token Globally
  const idToken = userInfo.idToken;

  // Response Data State
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  // Component Form Data State
  const [billingSubOpCompany, setBillingSubOpCompany] = useState([]);
  const [billingDepartment, setBillingDepartment] = useState([]);

  // Form Submit State
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [newRequestUid, setNewRequestUid] = useState(null);

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  //get data by subscription
  const [subscriptionUID, setSubscriptionUID] = useState(null);
  const [isValidUID, setValidUID] = useState(null);
  const [isCheckboxChecked, setCheckboxChecked] = useState(null);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);


  const accountRequestReload = useSelector(
    (state) => state.accountRequestReload
  );
  const { accountRequestReloadType } = accountRequestReload;

  // API URL
  const apiUrl = `${process.env.REACT_APP_TREND_MICRO_AZURE_VIEW_REQUEST_URL}`;

  // Request Body
  const requestBody = userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)
  ? `{"submittedBy":"${userInfo.preferred_username}", "email":"${userInfo.email}","type_of_user":1}`
  : `{"submittedBy":"${userInfo.preferred_username}", "email":"${userInfo.email}", "type_of_user":0}`;

  const fetchTableData = useCallback(() => {
    setLoading(true);

    postApiCall(apiUrl, idToken, requestBody).then((response) => {
      
      dispatch({
        type: TREND_MICRO_SUBSCRIPTION_DETAILS,
        payload: response?.data?.body,
      });
      setLoading(false);
    });
  }, [apiUrl, idToken, requestBody]);

  // handle onchange
  const handleData = ({ target }) => {
    if (target.name === 'opco') {
      setFormData({
        type: 'UPDATE_ACCOUNT_DETAILS',
        payload: {
          [`subopco`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_ACCOUNT_DETAILS',
        payload: {
          [`department`]: '',
        },
      });
      setBillingSubOpCompany([]);
      setBillingDepartment([]);
      let opCompany = target.value;
      if (opCompany !== '') {
        const getSubOpCompany = responseData.find(
          (t) => t.opco === opCompany
        ).subopcod;
        setBillingSubOpCompany(getSubOpCompany);
      }
    }
    if (target.name === 'subopco') {
      setFormData({
        type: 'UPDATE_ACCOUNT_DETAILS',
        payload: {
          [`department`]: '',
        },
      });
      setBillingDepartment([]);
      let opSubCompany = target.value;
      if (opSubCompany !== '') {
        const getDepartment = billingSubOpCompany.find(
          (t) => t.subopco === opSubCompany
        ).dept;
        setBillingDepartment(getDepartment);
      }
    }
    
    // if (
    //   formData.accountDetails.opco !== '' &&
    //   formData.accountDetails.purposeid !== '' &&
    //   formData.accountDetails.environmenttype !== ''
    // ) {
    //   setFormData({
    //     type: 'UPDATE_ACCOUNT_DETAILS',
    //     payload: {
    //       [`accountName`]: `${
    //         formData.accountDetails.opco
    //       }_${formData.accountDetails.purposeid.replace(/ /g, '_')}_${
    //         formData.accountDetails.environmenttype
    //       }`,
    //     },
    //   });
    // }

    
    if(target.name === 'subscriptionID'){
      const validSubscriptionID = target.value.replace(/[^a-z0-9-]/gi,'');
      const splitValues = [...validSubscriptionID]
      const a = formData?.accountDetails?.subscriptionID.length < target.value.length
      if(a){
      switch (true) {
        case (splitValues.length >= 8 && splitValues[8] != '-'):
          splitValues.splice(8, 0, '-')
          break;
        case (splitValues.length >= 13 && splitValues[13] != '-'):
          splitValues.splice(13, 0, '-')
          break;
        case (splitValues.length >= 18 && splitValues[18] != '-'):
          splitValues.splice(18, 0, '-')
        break;
        case (splitValues.length >= 23 && splitValues?.[23] != '-'):
          splitValues.splice(23, 0, '-')
        break;
      }
    }
    
    if(splitValues.length <= 36){
      const hipenAddedValue = splitValues?.join('')
      if(splitValues.length === 36){
        const errors = subscriptionIDValidation(splitValues);
        setDataInputError(errors);
   
      }
      setFormData({
        type: 'UPDATE_ACCOUNT_DETAILS',
        payload: { [target.name]: hipenAddedValue},
      });
    }
    }
    if(target?.name === 'subscriptionUID'){
      if(target?.value === ''){
        const checkboxUncheck = {target : { checked : false}}
        sameAsAccountData(checkboxUncheck)
        setFormData({
          type: 'UPDATE_ACCOUNT_DETAILS',
          payload: { 
            ['subscriptionUID']: '',
            ['subscriptionUIDCheckbox']: false,
          },
        });
      }
      
      setFormData({
        type: 'UPDATE_ACCOUNT_DETAILS',
        payload: { [target.name]: target?.value},
      });
      setSubscriptionUID(target?.value)
      setCheckboxChecked(false)
    }
    if(target.name !== 'subscriptionID'){
    setFormData({
      type: 'UPDATE_ACCOUNT_DETAILS',
      payload: { [target.name]: target.value },
    });
    }
  };

  const handleContinue = (e) => { 
    e.preventDefault();
    const errors = accountDetailsValidate(formData.accountDetails);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setStep(step + 1);
    }
  }

  useEffect(() => {
    setLoading(true);

    // adding query string fr oauth
    const queryObj = `{
      "submittedBy": "${userInfo?.email}",
      "type_of_user": ${userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? 1 : 0}
    }`
    const encodedString = Buffer.from(queryObj).toString('base64');
    // end

    const apiUrl = `${process.env.REACT_APP_AZURE_ACCOUNT_REQUEST_BILLING_INFO}?enchash=${encodedString}`;

    getApiCall(apiUrl, idToken).then((response) => {
      setResponseData(response.data.body);
      setLoading(false);
    });
    
  }, []);

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

   // Set Same Data
   const sameAsAccountData = (e) => {  
    let getCheckBoxState = e.target.checked;
    if(!getCheckBoxState) {
      setSubscriptionUID(null)
      setCheckboxChecked(false)
        setBillingDepartment([]);
        setBillingSubOpCompany([])
        setFormData({
          type: 'UPDATE_ACCOUNT_DETAILS',
          payload: {
            ['opco']: '',
            ['subopco']: '',
            ['department']: '',
            ['environmenttype']: '',
            ['accountdescription']: '',
            ['accountName']: '',
            ['subscriptionUID']: '',
            ['subscriptionID']: '',
            ['subscriptionUIDCheckbox']: false,
          },
        });
          setFormData({
        type: 'UPDATE_TECHNICAL_CONTACT',
        payload: {
          [`firstNameTC`]: '',
          [`lastNameTC`]: '',
          [`emailTC`]: '',
          [`countryTC`]: '',
          [`phoneNoTC`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_ACCOUNT_CONTACT',
        payload: {
          [`firstNameAO`]: '',
          [`lastNameAO`]: '',
          [`emailAO`]: '',
          [`countryAO`]: '',
          [`phoneNoAO`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_SECURITY_CONTACT',
        payload: {
          [`firstNameSC`]: '',
          [`lastNameSC`]: '',
          [`emailSC`]: '',
          [`countrySC`]: '',
          [`phoneNoSC`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_BILLING_CONTACT',
        payload: {
          [`billingopco`]: '',
          [`billingSubOpco`]: '',
          [`billingDepartment`]: '',
          [`billingRegion`]: '',
          [`billto`]: '',
          [`io`]: '',
          [`costcenter`]: '',
        },
      });

    } else {
      setValidUID(validateSubscriptionID())
      if(!validateSubscriptionID()){
        setCheckboxChecked(true)
        const selectedSubscription = trendMicroSubscriptionDetails?.find(item => item.uid === subscriptionUID.toLowerCase())
        let getSubOpCompany;
        if(selectedSubscription && selectedSubscription?.opco !== ''){
           getSubOpCompany = responseData.find(
            (t) => t.opco === selectedSubscription?.opco
          )?.subopcod;
          setBillingSubOpCompany(getSubOpCompany);
        }
        if (selectedSubscription && selectedSubscription?.subopco !== '') {
          const getDepartment = getSubOpCompany?.find(
            (t) => t.subopco === selectedSubscription?.subopco
          )?.dept;
          setBillingDepartment(getDepartment);
        }
        console.log('selectedSubscription', selectedSubscription)
        setFormData({
          type: 'UPDATE_ACCOUNT_DETAILS',
          payload: {
            ['opco']: selectedSubscription?.opco,
            ['subopco']: selectedSubscription?.subopco,
            ['department']: selectedSubscription?.department,
            ['environmenttype']: selectedSubscription?.environmenttype,
            ['accountdescription']: selectedSubscription?.subscriptiondescription,
            ['accountName']: selectedSubscription?.subscriptionname,
            ['subscriptionUID']: selectedSubscription?.uid,
            ['subscriptionID']: selectedSubscription?.subscriptionid,
            ['subscriptionUIDCheckbox']: true,
            
          },
        });
          setFormData({
        type: 'UPDATE_TECHNICAL_CONTACT',
        payload: {
          [`firstNameTC`]: selectedSubscription?.firstNameTC,
          [`lastNameTC`]: selectedSubscription?.lastNameTC,
          [`emailTC`]: selectedSubscription?.emailTC,
          [`countryTC`]: selectedSubscription?.countryTC,
          [`phoneNoTC`]: selectedSubscription?.phoneNoTC,
        },
      });
      setFormData({
        type: 'UPDATE_ACCOUNT_CONTACT',
        payload: {
          [`firstNameAO`]: selectedSubscription?.firstNameAO,
          [`lastNameAO`]: selectedSubscription?.lastNameAO,
          [`emailAO`]: selectedSubscription?.emailAO,
          [`countryAO`]: selectedSubscription?.countryAO,
          [`phoneNoAO`]: selectedSubscription?.phoneNoAO,
        },
      });
      setFormData({
        type: 'UPDATE_SECURITY_CONTACT',
        payload: {
          [`firstNameSC`]: selectedSubscription?.firstNameSC,
          [`lastNameSC`]: selectedSubscription?.lastNameSC,
          [`emailSC`]: selectedSubscription?.emailSC,
          [`countrySC`]: selectedSubscription?.countrySC,
          [`phoneNoSC`]: selectedSubscription?.phoneNoSC,
        },
      });
      setFormData({
        type: 'UPDATE_BILLING_INFO',
        payload: {
          [`billingopco`]: selectedSubscription?.billingopco,
          [`billingSubOpco`]: selectedSubscription?.billingSubOpco,
          [`billingDepartment`]: selectedSubscription?.billingDepartment,
          [`billingRegion`]: selectedSubscription?.billingRegion,
          [`billto`]: selectedSubscription?.billto,
          [`io`]: selectedSubscription?.io,
          [`costcenter`]: selectedSubscription?.costcenter,
        },
      });
      } else {
       setCheckboxChecked(false)
    }
  };
}

  //validateSubscriptionID
  const validateSubscriptionID = () => {
    if(subscriptionUID?.length === 15){
      const UID = subscriptionUID?.split('-')
      const reg = new RegExp('^[0-9]+$');
      const selectedSubscription = trendMicroSubscriptionDetails?.find(item => item.uid === subscriptionUID.toLowerCase())
      if(UID?.[0]?.toUpperCase() === 'U' && reg.test(UID?.[1]) && selectedSubscription){
        return false
      }
      return true
  } return true
}
    console.log('ppppppppp22222', dataInputError)

  return (
    <>
      <Row>
        <Col md={12} xs={12}>
        <FormProgress />
        </Col>
      </Row>
      <Row className='px-3'>
        <Col md={12} xs={12}>
        <h5 className='page-content-title mb-4'>{t("Subscription Details")}</h5>
        </Col>
      </Row>
      <Form onSubmit={handleContinue} autoComplete={false}>
        {loading && <Loader />}
        {loading === false && (
          <>
            <Row className='px-3'>
            <Col md={6}>
              <TextInput
                  label={"*"+t("Subscription Name")+":"}
                  name='accountName'
                  value={formData.accountDetails.accountName}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Subscription Name")}
                  isInvalid={dataInputError.accountName ? true : false}
                  invalidMessage={dataInputError.accountName}
                />
               
              </Col>
              <Col md={6}>
              <TextInput
                  label={"*"+t("Subscription Description")+":"}
                  name='accountdescription'
                  value={formData.accountDetails.accountdescription}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Subscription Description")}
                  isInvalid={dataInputError.accountdescription ? true : false}
                  invalidMessage={dataInputError.accountdescription}
                />
               
              </Col>
              <Col md={6}>
                <TextInput
                  label={"*"+t("Subscription ID")+":"}
                  name='subscriptionID'
                  value={formData.accountDetails.subscriptionID}
                  autoComplete="off"
                  onChange={(e) => handleData(e)} 
                  placeholder={t("Subscription ID")}
                  isInvalid={(dataInputError.subscriptionID || (formData.accountDetails.subscriptionID &&formData.accountDetails.subscriptionID?.length !== 36)) ? true : false}
                  invalidMessage={(formData.accountDetails.subscriptionID && formData.accountDetails.subscriptionID?.length !== 36) ? 'Please enter a valid subscription ID' : dataInputError.subscriptionID}
                />
              </Col>
              <Col md={6}>
                <BillingCompanyDropdown
                  label={"*"+t("operating_company")+":"}
                  defaultSelectMessage={t("select_op_company")}
                  name='opco'
                  value={formData.accountDetails.opco}
                  onChange={(e) => handleData(e)}
                  selectValues={responseData}
                  isInvalid={dataInputError.opco ? true : false}
                  invalidMessage={dataInputError.opco}
                />
              </Col>
              <Col md={6}>
                <BillingSubCompanyDropdown
                  label={"*"+t("sub_operating_company")+":"}
                  defaultSelectMessage={t("select_subop_company")}
                  name='subopco'
                  value={formData.accountDetails.subopco}
                  onChange={(e) => handleData(e)}
                  selectValues={billingSubOpCompany}
                  isInvalid={dataInputError.subopco ? true : false}
                  invalidMessage={dataInputError.subopco}
                />
              </Col>
              <Col md={6}>
                <BillingDepartmentDropdown
                  label={"*"+t("department")+":"}
                  defaultSelectMessage={t("select_department")}
                  name='department'
                  value={formData.accountDetails.department}
                  onChange={(e) => handleData(e)}
                  selectValues={billingDepartment}
                  isInvalid={dataInputError.department ? true : false}
                  invalidMessage={dataInputError.department}
                />
              </Col>

              <Col md={6}>
                <StaticDropdown
                  label={"*"+t("environment_type")+":"}
                  defaultSelectMessage={t("select_environment_type")}
                  name='environmenttype'
                  value={formData.accountDetails.environmenttype}
                  onChange={(e) => handleData(e)}
                  selectValues={staticEnvironmentType}
                  isInvalid={dataInputError.environmenttype ? true : false}
                  invalidMessage={dataInputError.environmenttype}
                />
              </Col>

            </Row>

            <Row className='px-3'>
              <Col md={12}>
                <div className='d-flex justify-content-center'>
                  {formSubmitLoading === false && apiError !== null && (
                    <Alert variant='danger'>{apiError}</Alert>
                  )}
                </div>
              </Col>
              <Col className='col-md-6'>
            <Form.Group className='mb-1'>
              <Form.Check
                checked={formData.accountDetails.subscriptionUIDCheckbox}
                // value={formData.accountDetails.subscriptionUIDCheckbox}
                type='checkbox'
                label={t("Please enter your Request ID to prepopulate all the details from existing subscription.")}
                className='checkbox-input'
                onChange={(e) => sameAsAccountData(e)}
              />
            </Form.Group>
              <TextInput
                  label={null}
                  name='subscriptionUID'
                  value={formData.accountDetails.subscriptionUID}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Enter UID")}
                  isInvalid={isValidUID}
                  invalidMessage={isValidUID ? "please enter a valid UID" : null}
                />
               
          </Col>
              <div className='col-md-5 offset-md-1'>
                {formSubmitLoading === true && (
                  <div className='mb-3'>
                    <Loader />
                  </div>
                )}
                {formSubmitLoading === false && (
                  <ul className='px-0 btn-ul mt-5 ms-5 ps-5'>
                    <li>
                      <button
                        type='button'
                        className='teal-bordered-btn'
                        onClick={() => {
                          setShowCancelModal(true);
                        }}
                      >
                        {t("cancel")}
                      </button>
                    </li>
                    <li>
                      <button type='submit' className='teal-filled-btn'>
                        {t("save_continue")}
                      </button>
                    </li>
                  </ul>
                )}
              </div>
            </Row>
          </>
        )}
      </Form>
      {formSubmitSuccess && (
        <CustomModal
          showModal={true}
          children={<NewRequestSubmit refUid={newRequestUid} />}
        />
      )}
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<NewRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

export default AccountDetails;
