import { PRISMA, TREND } from './securityConstant';

export const securityReducer = (
  state = {
    prismaCanvas: false,
    trendCanvas: false,
  },
  action
) => {
  switch (action.type) {
    case PRISMA:
      return { ...state, prismaCanvas: action.payload };

    case TREND:
      return { ...state, trendCanvas: action.payload };

    default:
      return state;
  }
};
