// Common
const setAlarmStatistic = [
  { label: "Minimum", value: "Minimum" },
  { label: "Maximum", value: "Maximum" },
  { label: "Sum", value: "Sum" },
  { label: "Average", value: "Average" },
];

const setAlarmPeriod = [
  { label: "5 min", value: "300" },
  { label: "10 min", value: "600" },
  { label: "15 min", value: "900" },
  { label: "1 hour", value: "3600" },
];

const setAlarmCondition = [
  { label: "Greater Than Threshold", value: "GreaterThanThreshold" },
  {
    label: "Greater Than Or Equal To Threshold",
    value: "GreaterThanOrEqualToThreshold",
  },
  { label: "Less Than Threshold", value: "LessThanThreshold" },
  {
    label: "Less Than Or Equal To Threshold",
    value: "LessThanOrEqualToThreshold",
  },
];

const setAlarmThreshold = [
  { label: "80", value: "80" },
  { label: "85", value: "85" },
  { label: "90", value: "90" },
  { label: "95", value: "95" },
  { label: "100", value: "100" },
];

// EC2 Set Alarm

const ec2MetricNames = [
  { label: "CPU Utilization(%)", value: "CPUUtilization" },
  { label: "Memory Used(%)", value: "mem_used_percent" },
  { label: "Disk Used(%)", value: "disk_used_percent" },
];

const ec2MetricNamesForLinux = [
  { label: "CPU Utilization(%)", value: "CPUUtilization" },
  { label: "Memory Used(%)", value: "mem_used_percent" },
  { label: "Disk Used(%)", value: "disk_used_percent" },
];

const ec2MetricNamesForWindows = [
  { label: "LogicalDisk % Free Space", value: "LogicalDisk % Free Space" },
  {
    label: "Memory % Committed Bytes In Use ",
    value: "Memory % Committed Bytes In Use ",
  },
  { label: "CPUUtilization", value: "CPUUtilization" },
];

// EFS Set Alarm

const efsMetricNames = [
  { label: "PermittedThroughput", value: "PermittedThroughput" },
];

// LB Set Alarm

const lbMetricNames = [
  { label: "ActiveConnectionCount", value: "ActiveConnectionCount" },
  { label: "UnHealthyHostCount", value: "UnHealthyHostCount" },
];

const lbPeriod = [
  { label: "5 min", value: "300" },
  { label: "10 min", value: "600" },
  { label: "15 min", value: "900" },
  { label: "1 hour", value: "3600" },
];

// RDS Set Alarm

const rdsMetricNames = [
  { label: "CPU Utilization(%)", value: "CPUUtilization" },
  { label: "FreeableMemory(Bytes)", value: "FreeableMemory" },
  { label: "FreeStorageSpace(Bytes)", value: "FreeStorageSpace" },
];

export {
  ec2MetricNames,
  ec2MetricNamesForLinux,
  ec2MetricNamesForWindows,
  setAlarmStatistic,
  setAlarmPeriod,
  setAlarmCondition,
  setAlarmThreshold,
  efsMetricNames,
  lbMetricNames,
  lbPeriod,
  rdsMetricNames,
};
