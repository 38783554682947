import React from 'react';
import { Form } from 'react-bootstrap';

const BillingDepartmentDropdown = ({
  label,
  defaultSelectMessage,
  selectValues,
  isInvalid,
  invalidMessage,
  ...others
}) => {
  return (
    <Form.Group className='mb-3'>
      <Form.Label>{label}</Form.Label>

      <Form.Select isInvalid={isInvalid} {...others}>
        <option value=''>{defaultSelectMessage}</option>
        {selectValues.map((data, index) => (
          <option key={index} value={data}>
            {data}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type='invalid'>
        {invalidMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default BillingDepartmentDropdown;
