import React, { useRef } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DUPLICATED_DATA,
  AWS_CREDITS_SHOW_MANDATORY,
} from "redux/billing/EditableTableConstant";
import { GetUserAuth, billingScreen } from "static-contents/billing-data";

// Create an editable cell renderer
const EditableCell = (props) => {
  const {
    data,
    sortBy,
    setSortBy,
    value: initialValue,
    row: { index },
    //   column: { id },
    column,
    updateMyData, // This is a custom function that we supplied to our table instance
  } = props;

  const getScreenAcess = billingScreen?.pamCostScreenDate();
  const dispatch = useDispatch();
  const billingStateValues = useSelector((state) => state.billing);
  const myInputCell = useRef();
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const [isVisible, setVisible] = React.useState(false);

  const onChange = (e) => {
    if (
      billingStateValues.pamShowMandatory.awsAccountNo ||
      billingStateValues.pamShowMandatory.countOfServerName ||
      billingStateValues.pamShowMandatory.sumOfUnitCost
    ) {
      dispatch({
        type: AWS_CREDITS_SHOW_MANDATORY,
        payload: {
          awsAccountNo: null,
          countOfServerName: null,
          sumOfUnitCost: null,
        },
      });
    }

    // allow only numbers
    if (column?.id === "Ops Cost") {
      const value = e.target.value?.replace(/[^\.0-9]/g, "");
      setValue(value);
    } else {
      setValue(e.target.value);
    }
  };

  // We'll only update the external data when the input is blurred

  const onBlur = (e) => {
    // e.preventDefault();
    const valueWithoutSpace = value?.trim();

    if (
      column?.id === "Total Credits Allocated" ||
      column?.id === "Remainder"
    ) {
      const costFormatted = parseFloat(
        valueWithoutSpace?.replace(/,/g, "")
      ).toLocaleString("en-IN");
      const opsCost = costFormatted !== "NaN" ? costFormatted.toString() : "0";
      setValue(opsCost);
      updateMyData(index, column, opsCost);
    } else {
      updateMyData(index, column, valueWithoutSpace);
    }
    setVisible(false);

    // GSID DUPLICATE REMOVAL

    // const prevValue = data.find((row, i) => i === index);
    // const updatedData = data.map((row, i) => {
    //   if (i === index) {
    //     return {
    //       ...data[index],
    //       [column?.id]: valueWithoutSpace,
    //     };
    //   }
    //   return row;
    // });

    // const isDuplicate = updatedData.filter(
    //   (item) => item?.["GSID Tags"] === valueWithoutSpace
    // );
    // if ((isDuplicate?.length > 1) && (column?.id === "GSID Tags)) {
    //   dispatch({
    //     type: DUPLICATED_DATA,
    //     payload: true,
    //   });
    //   setValue(prevValue?.["GSID Tags"]);
    // } else {
    //   updateMyData(index, column, valueWithoutSpace);

    //   dispatch({
    //     type: DUPLICATED_DATA,
    //     payload: false,
    //   });
    // }
  };
  const getDay = new Date().getDate();
  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    if (
      isVisible &&
      billingStateValues?.selectedMonth === 0 &&
      getDay < getScreenAcess
    ) {
      myInputCell.current.selectionStart = myInputCell.current.selectionEnd;
      myInputCell.current.focus();
    }
  }, [isVisible]);

  return (
    <>
      {" "}
      <div
        className={`row--input `}
        onDoubleClick={() => {
          setVisible(true);
        }}
        key={column?.id}
      >
        {isVisible &&
        billingStateValues.selectedMonth === 0 &&
        getDay < getScreenAcess ? (
          <input
            className="custom-no-data-message-text"
            ref={myInputCell}
            value={value}
            onChange={onChange}
            onBlur={(e) => onBlur(e)}
          />
        ) : (
          <p key={column?.id}>
            {value ? value : <span className="invisible">00000</span>}
          </p>
        )}
      </div>
    </>
  );
};

export default EditableCell;
