import React, { useMemo, useState, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import countryList from 'react-select-country-list';
import {useTranslation} from 'react-i18next';

// Import Form Component
import TextInput from 'components/Forms/TextInput';
import CountryDropdown from 'components/Forms/CountryDropdown';

// Import Common Component
import CustomModal from 'components/Modal/CustomModal';

// Import Internal Components
import FormProgress from './FormProgress';
import NewRequestCancel from './NewRequestCancel';

// Validator
import { accountContactValidate } from '../../Validator/validator';

const AccountContact = ({ step, setStep, formData, setFormData }) => {
  const { t } = useTranslation();
  // Fetch Country List
  const countryData = useMemo(() => countryList().getData(), []);

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // handle onchange
  const handleData = ({ target }) => {
    setFormData({
      type: 'UPDATE_ACCOUNT_CONTACT',
      payload: { [target.name]: target.value },
    });
  };

  const handleContinue = (e) => {
    e.preventDefault();
    const errors = accountContactValidate(formData.accountContact);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setStep(step + 1);
    }
  };

  return (
    <>
      <Row>
        <Col md={12} xs={12}>
          <FormProgress />
        </Col>
      </Row>
      <Row className='px-3'>
        <Col md={12} xs={12}>
          <h5 className='page-content-title mb-4'>{t("account_contact")}</h5>
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        <Row className='px-3'>
          <Col md={6}>
            <TextInput
              label={"*"+t("first_name")+":"}
              name='firstNameAO'
              value={formData.accountContact.firstNameAO}
              onChange={(e) => handleData(e)}
              placeholder={t("first_name")}
              isInvalid={dataInputError.firstNameAO ? true : false}
              invalidMessage={dataInputError.firstNameAO}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label={"*"+t("last_name")+":"}
              name='lastNameAO'
              value={formData.accountContact.lastNameAO}
              onChange={(e) => handleData(e)}
              placeholder={t("last_name")}
              isInvalid={dataInputError.lastNameAO ? true : false}
              invalidMessage={dataInputError.lastNameAO}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label={"*"+t("email")+":"}
              name='emailAO'
              value={formData.accountContact.emailAO}
              onChange={(e) => handleData(e)}
              placeholder={t("email")}
              isInvalid={dataInputError.emailAO ? true : false}
              invalidMessage={dataInputError.emailAO}
            />
          </Col>
          <Col md={6}>
            <CountryDropdown
              label={"*"+t("country")+":"}
              name='countryAO'
              value={formData.accountContact.countryAO}
              onChange={(e) => handleData(e)}
              selectValues={countryData}
              isInvalid={dataInputError.countryAO ? true : false}
              invalidMessage={dataInputError.countryAO}
              
            />
          </Col>
          <Col md={6}>
            <TextInput
              label={"*"+t("phone_number")+":"}
              name='phoneNoAO'
              value={formData.accountContact.phoneNoAO}
              onChange={(e) => handleData(e)}
              placeholder={t("phone_number")}
              isInvalid={dataInputError.phoneNoAO ? true : false}
              invalidMessage={dataInputError.phoneNoAO}
            />
          </Col>
        </Row>
        <Row className='px-3'>
          <div className='col-md-5 offset-md-7'>
            <ul className='px-0 btn-ul'>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    setShowCancelModal(true);
                  }}
                >
                  {t("cancel")}
                </button>
              </li>
              <li>
                <button type='submit' className='teal-filled-btn'>
                  {t("save_continue")}
                </button>
              </li>
            </ul>
          </div>
        </Row>
      </Form>
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<NewRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

export default AccountContact;
