import React from "react";
import { useTranslation } from "react-i18next";

const FormProgress = ({
  gisAccountSelection,
  installatonSchedule,
  targetInstance,
  reviewAccount,
}) => {
  const { t } = useTranslation();
  return (
    <ol className="FormStepList">
      <li className="FormStepList-item">
        {gisAccountSelection ? (
          <span className="FormStepIcon is-passed">
            <span className="FormStepIcon-circle">
              <i className="fa fa-check"></i>
            </span>
            <span className="FormStepIcon-title-active">
              {t("AWS Patch Automation")}
            </span>
          </span>
        ) : (
          <span className="FormStepIcon">
            <span className="FormStepIcon-circle"></span>
            <span className="FormStepIcon-title">
              {t("AWS Patch Automation")}
            </span>
          </span>
        )}
      </li>

      <li className="FormStepList-item">
        {installatonSchedule ? (
          <span className="FormStepIcon is-passed">
            <span className="FormStepIcon-circle">
              <i className="fa fa-check"></i>
            </span>
            <span className="FormStepIcon-title-active">
              {t("Installation Schedule")}
            </span>
          </span>
        ) : (
          <span className="FormStepIcon">
            <span className="FormStepIcon-circle"></span>
            <span className="FormStepIcon-title">
              {t("Installation Schedule")}
            </span>
          </span>
        )}
      </li>

      <li className="FormStepList-item">
        {targetInstance ? (
          <span className="FormStepIcon is-passed">
            <span className="FormStepIcon-circle">
              <i className="fa fa-check"></i>
            </span>
            <span className="FormStepIcon-title-active">
              {t("Target Instance")}
            </span>
          </span>
        ) : (
          <span className="FormStepIcon">
            <span className="FormStepIcon-circle"></span>
            <span className="FormStepIcon-title">{t("Target Instance")}</span>
          </span>
        )}
      </li>

      <li className="FormStepList-item">
        {reviewAccount ? (
          <span className="FormStepIcon FormStepIcon--last is-passed">
            <span className="FormStepIcon-circle">
              <i className="fa fa-check"></i>
            </span>
            <span className="FormStepIcon-title-active">
              {t("Review Account")}
            </span>
          </span>
        ) : (
          <span className="FormStepIcon FormStepIcon--last">
            <span className="FormStepIcon-circle"></span>
            <span className="FormStepIcon-title">{t("Review Account")}</span>
          </span>
        )}
      </li>
    </ol>
  );
};

export default FormProgress;
