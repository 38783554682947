export const accountContactValidate = (values) => {
  let errors = {};

  if (!values.firstNameAO) {
    errors.firstNameAO = 'First name is required';
  } else if (/^[a-zA-Z- ]*$/.test(values.firstNameAO) === false) {
    errors.firstNameAO = 'Only alphabets & hyphens allowed';
  }

  if (!values.lastNameAO) {
    errors.lastNameAO = 'Last name is required';
  } else if (/^[a-zA-Z- ]*$/.test(values.lastNameAO) === false) {
    errors.lastNameAO = 'Only alphabets & hyphens allowed';
  }

  let isEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    values.emailAO
  );
  if (!values.emailAO) {
    errors.emailAO = 'Email address is required';
  } else if (isEmail === false) {
    errors.emailAO = 'Email address is invalid';
  }

  if (!values.countryAO) {
    errors.countryAO = 'Country is required';
  }

  let isnum = /^(?=.*[0-9])[- +()0-9]+$/.test(values.phoneNoAO);

  if (!values.phoneNoAO) {
    errors.phoneNoAO = 'Phone number is required';
  } else if (isnum === false) {
    errors.phoneNoAO = 'Only digits, space, hyphens and plus is allowed';
  }

  return errors;
};

export const billingContactValidate = (values) => {
  let errors = {};
  if (!values.firstNameBC) {
    errors.firstNameBC = 'First name is required';
  } else if (/^[a-zA-Z- ]*$/.test(values.firstNameBC) === false) {
    errors.firstNameBC = 'Only alphabets & hyphens allowed';
  }

  if (!values.lastNameBC) {
    errors.lastNameBC = 'Last name is required';
  } else if (/^[a-zA-Z- ]*$/.test(values.lastNameBC) === false) {
    errors.lastNameBC = 'Only alphabets & hyphens allowed';
  }

  let isEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    values.emailBC
  );
  if (!values.emailBC) {
    errors.emailBC = 'Email address is required';
  } else if (isEmail === false) {
    errors.emailBC = 'Email address is invalid';
  }

  if (!values.countryBC) {
    errors.countryBC = 'Country is required';
  }

  let isnum = /^(?=.*[0-9])[- +()0-9]+$/.test(values.phoneNoBC);

  if (!values.phoneNoBC) {
    errors.phoneNoBC = 'Phone number is required';
  } else if (isnum === false) {
    errors.phoneNoBC = 'Only digits, space, hyphens and plus is allowed';
  }
  return errors;
};

export const securityContactValidate = (values) => {
  let errors = {};
  if (!values.firstNameSC) {
    errors.firstNameSC = 'First name is required';
  } else if (/^[a-zA-Z- ]*$/.test(values.firstNameSC) === false) {
    errors.firstNameSC = 'Only alphabets & hyphens allowed';
  }

  if (!values.lastNameSC) {
    errors.lastNameSC = 'Last name is required';
  } else if (/^[a-zA-Z- ]*$/.test(values.lastNameSC) === false) {
    errors.lastNameSC = 'Only alphabets & hyphens allowed';
  }

  let isEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    values.emailSC
  );
  if (!values.emailSC) {
    errors.emailSC = 'Email address is required';
  } else if (isEmail === false) {
    errors.emailSC = 'Email address is invalid';
  }

  if (!values.countrySC) {
    errors.countrySC = 'Country is required';
  }

  let isnum = /^(?=.*[0-9])[- +()0-9]+$/.test(values.phoneNoSC);

  if (!values.phoneNoSC) {
    errors.phoneNoSC = 'Phone number is required';
  } else if (isnum === false) {
    errors.phoneNoSC = 'Only digits, space, hyphens and plus is allowed';
  }
  return errors;
};

export const technicalContactValidate = (values) => {
  let errors = {};
  if (!values.firstNameTC) {
    errors.firstNameTC = 'First name is required';
  } else if (/^[a-zA-Z- ]*$/.test(values.firstNameTC) === false) {
    errors.firstNameTC = 'Only alphabets & hyphens allowed';
  }

  if (!values.lastNameTC) {
    errors.lastNameTC = 'Last name is required';
  } else if (/^[a-zA-Z- ]*$/.test(values.lastNameTC) === false) {
    errors.lastNameTC = 'Only alphabets & hyphens allowed';
  }

  let isEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    values.emailTC
  );
  if (!values.emailTC) {
    errors.emailTC = 'Email address is required';
  } else if (isEmail === false) {
    errors.emailTC = 'Email address is invalid';
  }

  if (!values.countryTC) {
    errors.countryTC = 'Country is required';
  }

  let isnum = /^(?=.*[0-9])[- +()0-9]+$/.test(values.phoneNoTC);

  if (!values.phoneNoTC) {
    errors.phoneNoTC = 'Phone number is required';
  } else if (isnum === false) {
    errors.phoneNoTC = 'Only digits, space, hyphens and plus is allowed';
  }
  return errors;
};

export const billingInfoValidate = (values) => {
  let errors = {};
  if (!values.billingopco) {
    errors.billingopco = 'Billing operating company is required';
  }
  if (!values.billingSubOpco) {
    errors.billingSubOpco = 'Billing sub operating company is required';
  }

  if (!values.billingDepartment) {
    errors.billingDepartment = 'Billing department is required';
  }

  if (!values.billingRegion) {
    errors.billingRegion = 'Billing region is required';
  }

  // if (!values.maxSpend) {
  //   errors.maxSpend = 'Max spend is required';
  // }
  if (!values.billto) {
    errors.billto = 'Bill to is required';
  }

  if (values.billto === 'Global IS (GISC)') {
    if (!values.io) {
      errors.io = 'IO is required';
    }
  } else {
    if (!values.costcenter) {
      errors.costcenter = 'Cost center is required';
    }
  }

  return errors;
};

export const groupEmailAddressValidate = (values) => {
  let errors = {};
  let isEmailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  if (!values.technicalGroup) {
    errors.technicalGroup = 'Technical Group is required';
  } else if (isEmailRegex.test(values.technicalGroup) === false) {
    errors.technicalGroup = 'Email address is invalid';
  }
  if (!values.securityGroup) {
    errors.securityGroup = 'Security Group is required';
  } else if (isEmailRegex.test(values.securityGroup) === false) {
    errors.securityGroup = 'Email address is invalid';
  }

  if (!values.billingGroup) {
    errors.billingGroup = 'Billing Group is required';
  } else if (isEmailRegex.test(values.billingGroup) === false) {
    errors.billingGroup = 'Email address is invalid';
  }

  return errors;
};

export const accountDetailsValidate = (values) => {
  let errors = {};
  if (!values.opco) {
    errors.opco = 'Operating company is required';
  }
  if (!values.subopco) {
    errors.subopco = 'Sub operating company is required';
  }

  if (!values.department) {
    errors.department = 'Department is required';
  }

  if (!values.environmenttype) {
    errors.environmenttype = 'Environment type is required';
  }

  if (!values.accountdescription) {
    errors.accountdescription = 'Subscription Description is required';
  }

  if (!values.subscriptionID || subscriptionIDValidation(values.subscriptionID)) {
    errors.subscriptionID = subscriptionIDValidation(values.subscriptionID) ? 'Please enter a valid subscription ID' : 'Subscription ID is required';
  }

  if (!values.accountName) {
    errors.accountName = 'Subscription Name is required';
  }

  return errors;
};

export const accountDetailsEditValidate = (values) => {
  let errors = {};
  if (!values.opco) {
    errors.opco = 'Operating company is required';
  }
  if (!values.subopco) {
    errors.subopco = 'Sub operating company is required';
  }

  if (!values.department) {
    errors.department = 'Department is required';
  }

  if (!values.environmenttype) {
    errors.environmenttype = 'Environment type is required';
  }

  if (!values.subscriptiondescription) {
    errors.subscriptiondescription = 'Subscription Description is required';
  }

  if (!values.subscriptionID || subscriptionIDValidation(values.subscriptionID)) {
    errors.subscriptionID = subscriptionIDValidation(values.subscriptionID) ? 'Please enter a valid subscription ID' : 'Subscription ID is required';
  }

  if (!values.subscriptionname) {
    errors.subscriptionname = 'Subscription Name is required';
  }

  return errors;
};

export const subscriptionIDValidation = (splitValues) => {
  const cloneSplitValues = Array.isArray(splitValues) ? splitValues : Array.from(splitValues);

  const hipenValidation =( cloneSplitValues[8] == '-' && cloneSplitValues[13] == '-' && cloneSplitValues[18] == '-' && cloneSplitValues[23] == '-')
 
  cloneSplitValues[8] = 'u' 
  cloneSplitValues[13] = 'u'
  cloneSplitValues[18] = 'u' 
  cloneSplitValues[23] = 'u'
  const getWithoutHipen = cloneSplitValues.join('')
  if (getWithoutHipen.match(/^[0-9a-z]+$/) && hipenValidation && getWithoutHipen.length == 36){
    return false
  } else{
    const errors = {};
    errors.subscriptionID = 'Please enter a valid subscription ID';
    return errors
  }
}
