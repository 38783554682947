import React from 'react';
import { Row, Col } from 'react-bootstrap';

const CardDetail = ({ data }) => {
  return (
    <Row>
      <Col md={12} className='card-detail-wrapper'>
        {data['ClientConnections'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Client Connections</div>
                <div className='card-values'>
                  {Object.keys(data['ClientConnections']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['ClientConnections']).pop()
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}

        {data['TotalIOBytes'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Total IO Bytes</div>
                <div className='card-values'>
                  {Object.keys(data['TotalIOBytes']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(Object.values(data['TotalIOBytes']).pop()).toFixed(
                    2
                  )}
                  B
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}

        {data['PermittedThroughput'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Permitted Throughput</div>
                <div className='card-values'>
                  {Object.keys(data['PermittedThroughput']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['PermittedThroughput']).pop()
                  ).toFixed(2)}
                  B
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}

        {data['MetadataIOBytes'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Metadata IO Bytes</div>
                <div className='card-values'>
                  {Object.keys(data['MetadataIOBytes']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['MetadataIOBytes']).pop()
                  ).toFixed(2)}
                  B
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}

        {data['DataWriteIOBytes'] && (
          <>
            <div className='mb-2'>
              <div>
                <div className='card-title'>Metadata IO Bytes</div>
                <div className='card-values'>
                  {Object.keys(data['DataWriteIOBytes']).pop()}
                </div>
                <div className='card-values'>
                  {parseInt(
                    Object.values(data['DataWriteIOBytes']).pop()
                  ).toFixed(2)}
                  B
                </div>
              </div>
            </div>
            <div className='card-center-border'></div>
          </>
        )}
      </Col>
      <Col md={12} className='d-flex justify-content-end mt-3'>
        <div className='regular-text utc-note'>*All timings are in UTC</div>
      </Col>
    </Row>
  );
};

export default CardDetail;
