// DEVELOPMENT (Development Env)

// export const oktaConfig = {
//   clientId: `0oadbb9keb1XlrS9b357`,
//   issuer: `https://sonytech.okta.com`,
//   redirectUri: `http://localhost:3000/login/callback`,
//   end_session_redirect_uri: 'http://localhost:3000/',
//   scopes: ['openid', 'profile', 'email', 'groups'],
//   pkce: true,
//   disableHttpsCheck: true,
// };

// PRODUCTION (Production Env)

export const oktaConfig = {
  clientId: `0oawqj5wbS08LqvBw356`,
  issuer: `https://sonytech.okta.com`,
   redirectUri: `https://hub.cs.sony.com/login/callback`,
   end_session_redirect_uri: "https://hub.cs.sony.com",
  // redirectUri: `http://localhost:3000/login/callback`,
  // end_session_redirect_uri: "http://localhost:3000/",
  scopes: ["openid", "profile", "email", "groups"],
  pkce: true,
  disableHttpsCheck: true,
};

