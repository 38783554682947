import React from "react";
import InstallSchedule from "./InstallSchedule";
import TargetTab from "./TargetTab";
import ReviewAccount from "./ReviewAccount";

const EditEnableAccountFormSteps = (
  step,
  setStep,
  formData,
  setFormData,
  data,
  instanceList,
  handleEditWindowClose
) => [
  {
    title: "Installation Schedule",
    content: (
      <InstallSchedule
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
        selectedAccounts={data}
        handleEditWindowClose={handleEditWindowClose}
      />
    ),
  },
  {
    title: "Target Instance",
    content: (
      <TargetTab
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
        selectedAccounts={data}
        instanceList={instanceList}
        handleEditWindowClose={handleEditWindowClose}
      />
    ),
  },
  {
    title: "Review Account",
    content: (
      <ReviewAccount
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
        selectedAccounts={data}
        instanceList={instanceList}
        handleEditWindowClose={handleEditWindowClose}
      />
    ),
  },
];

export default EditEnableAccountFormSteps;
