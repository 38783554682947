import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

// Import Routes
import { LoginCallback } from "@okta/okta-react";

// OKTA Authentication
import Home from "../src/pages/OktaAuth/Home";
import Landing from "../src/pages/OktaAuth/Landing";

import {
  authProtectedRoutes,
  publicRoutes,
  validateRoutes,
  authProtectedFullLayoutRoutes,
} from "./routes/";
import AuthRoute from "./routes/authroute";
import PublicRoute from "./routes/publicroute";
import ValidateRoute from "./routes/validateroute";

// layouts
import Layout from "./components/Layout/Layout";
import FullLayout from "./components/FullLayout/FullLayout";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

// Global Style CSS
import "./App.scss";
import "rc-tooltip/assets/bootstrap.css";

// Import Okta Config
import { oktaConfig } from "./oktaConfig";

// ! Page Not Found
import NotFound from "pages/NotFound";

const oktaAuth = new OktaAuth(oktaConfig);

const App = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(
      toRelativeUrl(originalUri || "/landing", window.location.origin)
    );
  };

  return (
    <>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route exact path="/login/callback" component={LoginCallback} /> */}
          <Route exact path="/login/callback">
            <LoginCallback
              errorComponent={() => {
                debugger;
                console.log("sony tech okta");
                localStorage.clear();
                window.open("https://hub.cs.sony.com", "_self");
                // history.push("/");
                return null;
              }}
            />
          </Route>
          <Route exact path="/landing" component={Landing} />

          {/* {publicRoutes.map((route, idx) => (
            <PublicRoute
              path={route.path}
              component={route.component}
              key={idx}
              exact
            />
          ))} */}

          {validateRoutes.map((route, idx) => (
            <ValidateRoute
              path={route.path}
              component={route.component}
              key={idx}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <AuthRoute
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              exact
            />
          ))}

          {authProtectedFullLayoutRoutes.map((route, idx) => (
            <AuthRoute
              path={route.path}
              layout={FullLayout}
              component={route.component}
              key={idx}
              exact
            />
          ))}

          <Route component={NotFound} />
        </Switch>
      </Security>
    </>
  );
};

export default App;
