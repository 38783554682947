import Loader from "components/Common/Loader";
import TextInput from "components/Forms/TextInput";
import CustomModal from "components/Modal/CustomModal";
import NewRequestCancel from "pages/Billings/Components/NewRequestCancel";
import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  GsidIoFormValidator,
  opsCostFormValidator,
} from "../../Billings/AWS/billingValidator";
import { postApiCall } from "helper/postApiCall";
import RequestSubmitConfirmation from "./RequestSubmitConfirmation";
import { useDispatch, useSelector } from "react-redux";
import { getISTTime } from "static-contents/billing-data";
import { TABLE_REFRESH } from "redux/billing/EditableTableConstant";

const AddEditForm = ({
  setEditOpen,
  editSelectedRow,
  setEditSelectedRow,
  setShowSuccessMessage,
  setData,
  operationType,
}) => {
  const { t } = useTranslation();
  //REDUX
  const dispatch = useDispatch();
  const billingStateValues = useSelector((state) => state.billing);
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const idToken = userInfo.idToken;

  const postApiUrl = `${process.env.REACT_APP_BILLING_GSIDIO_ADD_EDIT}`;
  const fileUploadApiUrl = `${process.env.REACT_APP_BILLING_FILE_UPLOAD}`;

  const [formState, setFormState] = useState({});
  const [dataInputError, setDataInputError] = useState({});
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);

  const [apiError, setApiError] = useState(null);
  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleContinue = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formDataObj = Object.fromEntries(formData.entries());
    const errors = opsCostFormValidator(formDataObj);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setEditSelectedRow({});
      //   setStep(step + 1);
      setLoading(true);
      // debugger;
      console.log("operationType", editSelectedRow?.index);
      if (operationType === "EDIT") {
        setData((oldData) =>
          oldData.map((row, index) =>
            index === editSelectedRow?.index ? formDataObj : row
          )
        );
        dispatch({
          type: TABLE_REFRESH,
          payload: !billingStateValues?.isTableRefresh,
        });
        setLoading(false);
        setEditOpen(false);
      } else {
        const requestBody = {
          data: [formDataObj],
          updatedby: userInfo?.email,
          updateddate: getISTTime().getDate,
          updatedtime: getISTTime().getTime,
          actionType: 0,
          datatype: "OpsCost",
        };

        postApiCall(fileUploadApiUrl, idToken, requestBody)
          .then((response) => {
            if (response?.data?.status === "Success") {
              setLoading(false);
              setEditOpen(false);
              setShowSuccessMessage(true);
            }
          })
          .catch((err) => {
            console.log("error", err);
            setApiError(
              "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
            );
          });
      }
    }
  };
  const handleData = ({ target }) => {
    if (target?.name === "Ops Cost") {
      const value = target.value?.replace(/[^\.0-9]/g, "");
      setFormState({
        ...formState,
        [target.name]: value,
      });
    } else {
      setFormState({
        ...formState,
        [target.name]: target?.value,
      });
    }
  };

  useEffect(() => {
    setFormState(editSelectedRow?.values);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Row className="px-3">
            <Col md={12} xs={12}>
              <h5 className="page-content-title mb-4">{t("Ops Cost")}</h5>
            </Col>
          </Row>
          <Form onSubmit={handleContinue}>
            <Row className="px-3">
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("Account ID") + ":"}
                  name="Account ID"
                  value={formState["Account ID"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Account ID")}
                  isInvalid={dataInputError["Account ID"] ? true : false}
                  invalidMessage={dataInputError["Account ID"]}
                />
              </Col>
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("GSID Tags") + ":"}
                  name="GSID Tags"
                  value={formState["GSID Tags"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("GSID Tags")}
                  isInvalid={dataInputError?.["GSID Tags"] ? true : false}
                  invalidMessage={dataInputError?.["GSID Tags"]}
                />
              </Col>
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("Vendor") + ":"}
                  name="Vendor"
                  value={formState["Vendor"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Vendor")}
                  isInvalid={dataInputError?.["Vendor"] ? true : false}
                  invalidMessage={dataInputError?.["Vendor"]}
                />
              </Col>
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("Account Name") + ":"}
                  name="Account Name"
                  value={formState["Account Name"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Account Name")}
                  isInvalid={dataInputError?.["Account Name"] ? true : false}
                  invalidMessage={dataInputError?.["Account Name"]}
                />
              </Col>
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("Account Region") + ":"}
                  name="Account Region"
                  value={formState["Account Region"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Account Region")}
                  isInvalid={dataInputError?.["Account Region"] ? true : false}
                  invalidMessage={dataInputError?.["Account Region"]}
                />
              </Col>
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("Ops Cost") + ":"}
                  name="Ops Cost"
                  value={formState["Ops Cost"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Ops Cost")}
                  isInvalid={dataInputError?.["Ops Cost"] ? true : false}
                  invalidMessage={dataInputError?.["Ops Cost"]}
                />
              </Col>
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("IO Code/Cost Center") + ":"}
                  name="IO Code/Cost Center"
                  value={formState["IO Code/Cost Center"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("IO Code/Cost Center")}
                  isInvalid={
                    dataInputError?.["IO Code/Cost Center"] ? true : false
                  }
                  invalidMessage={dataInputError?.["IO Code/Cost Center"]}
                />
              </Col>
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("Remarks") + ":"}
                  name="Remarks"
                  value={formState["Remarks"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Remarks")}
                  isInvalid={dataInputError?.["Remarks"] ? true : false}
                  invalidMessage={dataInputError?.["Remarks"]}
                />
              </Col>
            </Row>
            <div className="col-md-5 offset-md-7">
              <Col md={12}>
                {operationType === "EDIT" && (
                  <p className="add-form-save-note mb-0">
                    * click on save to commit the changes
                  </p>
                )}
                <div className="d-flex justify-content-center">
                  {formSubmitLoading === false && apiError !== null && (
                    <Alert variant="danger">{apiError}</Alert>
                  )}
                </div>
              </Col>
              {formSubmitLoading === true && (
                <div className="mb-3">
                  <Loader />
                </div>
              )}
              {formSubmitLoading === false && (
                <ul className="px-0 btn-ul">
                  <li>
                    <button
                      type="button"
                      className="teal-bordered-btn"
                      onClick={() => {
                        setShowCancelModal(true);
                        setEditSelectedRow({});
                      }}
                    >
                      {t("cancel")}
                    </button>
                  </li>
                  <li>
                    <button type="submit" className="teal-filled-btn">
                      {operationType === "EDIT"
                        ? t("Update")
                        : t("save_submit")}
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </Form>
          {showCancelModal && (
            <CustomModal
              showModal={true}
              fileUpload={true}
              children={
                <NewRequestCancel
                  closeModalParent={() => setShowCancelModal(false)}
                  setEditOpen={setEditOpen}
                />
              }
            />
          )}
        </div>
      )}
    </>
  );
};

export default AddEditForm;
