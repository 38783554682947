import React, { useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';

import AccountDetails from './AccountDetails';
import RoleDetails from './RoleDetails';

const ViewAssignment = () => {
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');

  const accountNumberDetailCallback = useCallback((value) => {
    setAccountNumber(value);
  }, []);

  const accountNameDetailCallback = useCallback((value) => {
    setAccountName(value);
  }, []);

  return (
    <Row>
      <Col>
        {accountNumber === '' && accountName === '' && (
          <AccountDetails
            selectedAccountNumber={accountNumberDetailCallback}
            selectedAccountName={accountNameDetailCallback}
          />
        )}

        {accountNumber !== '' && accountName !== '' && (
          <RoleDetails
            selectedAccountNumber={accountNumberDetailCallback}
            selectedAccountName={accountNameDetailCallback}
            accountNumber={accountNumber}
            accountName={accountName}
          />
        )}
      </Col>
    </Row>
  );
};

export default ViewAssignment;
