const Api = {
  cloudEyeAppList: process.env.REACT_APP_CLOUD_EYE_APP_LIST,
  webserviceApi: process.env.REACT_APP_CLOUD_EYE_USER_WEBSERVICE,
  resourceHeathApi: process.env.REACT_APP_CLOUD_EYE_USER_RESOURCE_HEALTH,
  toggleWebServiceApi: process.env.REACT_APP_CLOUD_EYE_USER_WEBSERVICE_TOGGLE,
  helpAndSupport: process.env.REACT_APP_HELP_AND_SUPPORT_LIST,
  onDemandScan: process.env.REACT_APP_ON_DEMAND_SCAN,
  createHelpSupportTicket: process.env.REACT_APP_HELP_AND_SUPPORT_CREATE,
  appListForCloudEyeRequest:
    process.env.REACT_APP_CLOUD_EYE_APPS_LIST_TO_REQUEST,
  createCloudEyeAppRequest:
    process.env.REACT_APP_CLOUD_EYE_CREATE_REQUEST_FOR_APP,
  viewCloudEyeRequestsForEmail:
    process.env.REACT_APP_CLOUD_EYE_CREATE_REQUESTS_FOR_EMAIL,
  resourceQuery: process.env.REACT_APP_RESOURCE_QUERY,
  userService:process.env.REACT_APP_CLOUD_EYE_USER_SERVICE,
  userAppMetrics:process.env.REACT_APP_CLOUD_EYE_USER_APPLICATION,
  securityTrendMicroConformity:process.env.REACT_APP_SECURITY_TRENDMICRO_CONFORMITY,
  securityTrendMicroConformityBranch2:process.env.REACT_APP_SECURITY_TRENDMICRO_CONFORMITY_BRANCH_2,
  
  securityTrendMicroDetails: process.env.REACT_APP_SECURITY_TREND_MICRO_CONFORMITY_DETAILS,
  securityTrendMicroDetailsBranch2: process.env.REACT_APP_SECURITY_TREND_MICRO_CONFORMITY_DETAILS_BRANCH_2
};

export default Api;
