import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import countryList from 'react-select-country-list';

// Import Form Component
import Loader from "components/Common/Loader";
import TextInput from 'components/Forms/TextInput';
import CountryDropdown from 'components/Forms/CountryDropdown';

// Import Common Component
import CustomModal from 'components/Modal/CustomModal';

// Import Internal Component
import EditRequestCancel from "../Edit/EditRequestCancel";
import BulkEditFormMenu from "./BulkEditFormMenu";
import EditRequestSubmit from "../Edit/EditRequestSubmit";

// Helper
import { postApiCall } from "helper/postApiCall";

// Validator
import { billingContactValidate } from './Validator/BulkEditAccountsValidate';

function BulkEditBillingContact({ step, setStep, formData, setFormData, selectedAccounts }) {
    const {t}=useTranslation();
    // Redux State
    const user = useSelector((state) => state.user);
    const { userInfo } = user;

    // Set ID Token Globally
    const idToken = userInfo.idToken;

    const accountRequestReload = useSelector(
        (state) => state.accountRequestReload
      );
    const { accountRequestReloadType } = accountRequestReload;

    const countryData = useMemo(() => countryList().getData(), []);

    // Data Input Error State
    const [dataInputError, setDataInputError] = useState({});
  
    // Show Cancel Modal
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [loading, setLoading] = useState(false);
    // Form Submit State
    const [formSubmitLoading, setFormSubmitLoading] = useState(false);
    const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
    const [bulkEditFormSubmitSuccess, setBulkEditFormSubmitSuccess] = useState(false);
    const [apiError, setApiError] = useState(null);
    
    const [toggleOn, setToggle] = useState(false);
    // Close Modal Callback (Modal No Click Part)
    const closeModalCallback = useCallback((value) => {
      setShowCancelModal(value);
    }, []);
  
   
  
    // handle onchange
    const handleData = ({ target }) => {
      setFormData({
        type: 'UPDATE_BILLING_CONTACT',
        payload: { [target.name]: target.value },
      });
    };

    
  useEffect(() => {
    if (accountRequestReloadType === true) {
      setFormSubmitSuccess(false);
      setBulkEditFormSubmitSuccess(false);
    }
},[]);
  
    const handleContinue = (e) => {
      e.preventDefault();
      const errors = billingContactValidate(formData.billingContact, toggleOn);
      setDataInputError(errors);
      if (Object.keys(errors).length > 0) {
        return;
      } else {
         // Form Submission
      setFormSubmitLoading(true);
      // const currentEpochTime = Date.now();
      const formSubmitApiUrl = `${process.env.REACT_APP_ACCOUNT_BULK_EDIT_URL}`;
      // Request body condition based on request status

      const requestBody = {
        accountnumbers: selectedAccounts,
        billingopco: formData.billingInfo.billingopco,
        billingSubOpco: formData.billingInfo.billingSubOpco,
        billingDepartment: formData.billingInfo.billingDepartment,
        // billingRegion: formData.billingInfo.billingRegion,
        // maxSpend: formData.billingInfo.maxSpend,
        // billto: formData.billingInfo.billto,
        // io: formData.billingInfo.io,
        // costcenter: formData.billingInfo.costcenter,
        opco: formData.accountDetails.opco,
        subopco: formData.accountDetails.subopco,
        department: formData.accountDetails.department,
        submittedBy:userInfo?.email,
      }
      if(toggleOn){
        // billing contact values
        requestBody.firstNameBC= formData.billingContact.firstNameBC  
        requestBody.lastNameBC= formData.billingContact.lastNameBC
        requestBody.emailBC= formData.billingContact.emailBC
        requestBody.countryBC= formData.billingContact.countryBC
        requestBody.phoneNoBC= formData.billingContact.phoneNoBC
        }

      postApiCall(formSubmitApiUrl, idToken, requestBody)
      .then((response) => {
        if (response.data?.status === "Success") {
          // setNewRequestUid(`u-${currentEpochTime}`);
          setBulkEditFormSubmitSuccess(true);
          setFormSubmitLoading(false);
          // history.push("/aws-account-update-requests")
        }
      })
      .catch((err) => {
        setFormSubmitLoading(false);
        setApiError(
          t("failed_to_retrieve_data")
        );
      });
      }
    };

  const handleToggle = (e) => {
    const { value } = e.target
    if(value === "false"){
      setToggle(true)
    } else {
      setToggle(false)
      const errors={}
      errors.firstNameBC = false;
      errors.lastNameBC = false;
      errors.emailBC = false;
      errors.countryBC = false;
      errors.phoneNoBC = false;
      setDataInputError(errors);
    }
}

    return (
        <>
       {loading && (
          <div className="mb-3">
            <Loader />
          </div>
        )}
       
         {loading === false && (
            <>
        <Row>
          <Col md={12} xs={12}>
          <BulkEditFormMenu billingContact />
          </Col>
        </Row>
        <Form onSubmit={handleContinue}>
        <Row className='px-3 mt-3'>
        <Col md={12}>
      <Form.Check 
        onChange={(e) => handleToggle(e)}
        type="switch"
        value={toggleOn}
        id="custom-switch"
        label="Do you want to update Billing Contact ?"
      />
    </Col>
    </Row>
    <Row className={`${!toggleOn ? 'disable-div' : ''} px-3 mt-3 `} >
          
            <Col md={12}>
              <TextInput
                label='*First Name:'
                name='firstNameBC'
                value={formData.billingContact.firstNameBC}
                onChange={(e) => handleData(e)}
                placeholder='First Name'
                isInvalid={dataInputError.firstNameBC ? true : false}
                invalidMessage={dataInputError.firstNameBC}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label='*Last Name:'
                name='lastNameBC'
                value={formData.billingContact.lastNameBC}
                onChange={(e) => handleData(e)}
                placeholder='Last Name'
                isInvalid={dataInputError.lastNameBC ? true : false}
                invalidMessage={dataInputError.lastNameBC}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label='*Email:'
                name='emailBC'
                value={formData.billingContact.emailBC}
                onChange={(e) => handleData(e)}
                placeholder='Mail Id'
                isInvalid={dataInputError.emailBC ? true : false}
                invalidMessage={dataInputError.emailBC}
              />
            </Col>
            <Col md={12}>
              <CountryDropdown
                label='*Country:'
                name='countryBC'
                value={formData.billingContact.countryBC}
                onChange={(e) => handleData(e)}
                selectValues={countryData}
                isInvalid={dataInputError.countryBC ? true : false}
                invalidMessage={dataInputError.countryBC}
              />
            </Col>
            <Col md={12}>
              <TextInput
                label='*Phone Number:'
                name='phoneNoBC'
                value={formData.billingContact.phoneNoBC}
                onChange={(e) => handleData(e)}
                placeholder='Phone Number'
                isInvalid={dataInputError.phoneNoBC ? true : false}
                invalidMessage={dataInputError.phoneNoBC}
              />
            </Col>
          </Row>

          <Row className='px-3'>
            <div className='d-flex justify-content-end'>
            {formSubmitLoading === true && <Loader />}
                {formSubmitLoading === false && (
              <ul className='px-0 btn-ul'>
                <li>
                  <button
                    type='button'
                    className='teal-bordered-btn'
                    onClick={() => setStep(step - 1)}
                  >
                    Back
                  </button>
                </li>
                <li>
                  <button
                    type='button'
                    className='teal-bordered-btn'
                    onClick={() => {
                      setShowCancelModal(true);
                    }}
                  >
                    Cancel
                  </button>
                </li>
                <li>
                  <button type='submit' className='teal-filled-btn'>
                    Save & Submit
                  </button>
                </li>
              </ul>
                  )}
            </div>
          </Row>
        </Form>
        {formSubmitSuccess && (
        <CustomModal showModal={true} children={<EditRequestSubmit />} />
      )}

{bulkEditFormSubmitSuccess && (
        <CustomModal showModal={true} children={<EditRequestSubmit message={"Bulk"}/>} />
      )}

      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<EditRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
      </>
         
    )};
    </>
    )
}

export default BulkEditBillingContact