import React from 'react';
import { Modal } from 'react-bootstrap';

import SpinnerLoader from 'components/Common/SpinnerLoader';

const TriggerMessage = ({ closeModalParent, message }) => {
  return (
    <Modal size='lg' centered show={true}>
      <Modal.Body className='text-center'>
        <div className='d-flex justify-content-center flex-column'>
          {message === '' && <SpinnerLoader />}
          {message !== '' && (
            <>
              <p>
                <i className='fa fa-check-circle cidr-check-mark'></i>
              </p>
              <p>{message}</p>
              <ul className='btn-ul'>
                <li>
                  <button
                    type='button'
                    className='teal-filled-btn'
                    onClick={() => {
                      closeModalParent(false);
                    }}
                  >
                    Ok
                  </button>
                </li>
              </ul>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TriggerMessage;
