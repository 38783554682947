import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function WarningMessage({ setShowAccountListError }) {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-center flex-column">
      <p>
        {t(
          "We couldn't find any cloud accounts with your Email listed as the owner in our system. Kindly connect with Sony Global Cloud team for further assistance."
        )}
      </p>
      <ul className="btn-ul">
        <li>
          <Link to="/tool-exception-aws-view">
            <button
              type="button"
              className="teal-filled-btn"
              onClick={() => {
                setShowAccountListError(false);
              }}
            >
              {t("ok")}
            </button>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default WarningMessage;
