import {
  ACCOUNT_BACKUP_RELOAD,
  ACCOUNT_REQUEST_CLOUD_TYPE,
  ACCOUNT_REQUEST_RELOAD,
} from "./accountRequestConstant";

export const accountRequestCloudReducer = (
  state = {
    accountRequestCloudType: "AWS",
  },
  action
) => {
  switch (action.type) {
    case ACCOUNT_REQUEST_CLOUD_TYPE:
      return { ...state, accountRequestCloudType: action.payload };

    default:
      return state;
  }
};

export const accountRequestReloadReducer = (
  state = {
    accountRequestReloadType: false,
  },
  action
) => {
  switch (action.type) {
    case ACCOUNT_REQUEST_RELOAD:
      return { ...state, accountRequestReloadType: action.payload };

    default:
      return state;
  }
};
export const accountRequestBackupReducer = (
  state = {
    accountRequestBackupType: false,
  },
  action
) => {
  switch (action.type) {
    case ACCOUNT_BACKUP_RELOAD:
      return { ...state, accountRequestBackupType: action.payload };

    default:
      return state;
  }
};
