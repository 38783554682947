import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SpinnerLoader from "components/Common/SpinnerLoader";
import DangerAlert from "components/Message/DangerAlert";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { postApiCall } from "helper/postApiCall";
import Multiselect from "multiselect-react-dropdown";
import backicon from "icons/back.svg";
import { useHistory } from "react-router-dom";
import BillingDetails from "../BillingDetails";
import BillingChart from "../BillingChart";
import AdditionalFeaturesAdmin from "../AdditionalFeaturesAdmin";
import InvoiceBreakdownTable from "../InvoiceBreakdownTable";
import {
  CS_Hub_AWS_Account_Request_Admin,
  CS_Hub_GCS_Billing_Admins,
} from "roles/roles";
import CustomModal from "components/Modal/CustomModal";
import ChargeBackSetting from "./ChargeBackSetting";

const BillingAdmin = () => {
  const { t } = useTranslation();
  const history = useHistory();

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const [apiLoading, setApiLoading] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [selAccounts, setSelAccounts] = useState([]);
  const [apiError, setApiError] = useState(null);
  const [showChargeBackSetting, setShowChargeBackSetting] = useState(false);
  const [isInvoiceChartOpen, setInvoiceChartOpen] = useState(false);
  const idToken = `${userInfo.idToken}`;
  const accountListApiUrl = `${process.env.REACT_APP_SECURITY_ACCOUNT_LIST}`;

  const handleBack = () => {
    history.push("/billings");
  };

  useEffect(() => {
    let requestBody = {
      cloud: "aws",
      type_of_user: userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)
        ? 1
        : 0,
    };

    const fetchData = async () => {
      setApiLoading(true);
      postApiCall(accountListApiUrl, idToken, requestBody)
        .then((response) => {
          let tempArray = [];
          for (let i = 0; i < response.data?.length; i++) {
            let tempObj = {};
            tempObj["rdsUniqueId"] = i;
            tempObj["DropdownLabel"] =
              response.data?.[i].accountname +
              " - " +
              response.data?.[i].accountnumber;
            tempObj["DropdownValue"] = response.data?.[i].accountnumber;
            tempArray.push(tempObj);
          }
          setAccountList(tempArray);
          setApiLoading(false);
        })
        .catch((err) => {
          setApiError(t("failed_to_retrieve_data"));
          setApiLoading(false);
        });
    };

    fetchData();
  }, []);

  const handleChargebackMenu = () => {
    setShowChargeBackSetting(true);
  };

  return (
    <>
      <Container fluid>
        {apiLoading && (
          <Row>
            <Col md={12} className="d-flex justify-content-center mt-5">
              <SpinnerLoader />
            </Col>
          </Row>
        )}

        {apiLoading === false && apiError !== null && (
          <Row>
            <Col md={12}>
              <DangerAlert message={apiError} />
            </Col>
          </Row>
        )}

        {apiLoading === false && apiError === null && (
          <>
            {!accountList?.length > 0 ? (
              <h5>
                You are not identified as either account owner or Billing
                Contact for any Cloud Account. Contact Sony Global Cloud Support
                for additional details.
              </h5>
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="mt-2">
                      {/* <img
                      src={backicon}
                      alt="Back"
                      className="view-edit-box-back-btn mx-0"
                      onClick={handleBack}
                    /> */}
                      <span className="maintitle-text mx-3">
                        {t("GCS Chargeback")}
                      </span>
                    </div>
                    {/* {userInfo?.groups?.includes(CS_Hub_GCS_Billing_Admins) && ( */}
                    <div
                      className="mt-2 "
                      onClick={() => {
                        handleChargebackMenu();
                      }}
                    >
                      <i class="fa fa-ellipsis-h gcs-dashboard" />
                    </div>
                  </div>
                  <div
                    className="mt-2 invoice-report cursor-pointer"
                    onClick={() => {
                      setInvoiceChartOpen(true);
                    }}
                  >
                    <i class="fa fa-bar-chart me-1 fs-5" /> Invoice By Month
                  </div>
                  {/* )} */}
                  {/* <div className="w-50">
                    <Multiselect
                      selectedValues={{}}
                      options={accountList}
                      onSelect={(selectedList, selectedItem) => {
                        setSelAccounts((currSelect) => [...selectedList]);
                      }}
                      onRemove={(selectedList, selectedItem) => {
                        setSelAccounts((currSelect) => [...selectedList]);
                      }}
                      displayValue="DropdownLabel"
                      disablePreSelectedValues={true}
                      closeOnSelect={false}
                      avoidHighlightFirstOption={true}
                      singleSelect={false}
                      value={selAccounts}
                      hideSelectedList
                      showCheckbox
                    />
                  </div> */}
                </div>
                {/* <BillingDetails selAccountLists={selAccounts} cloud="aws" /> */}
                <Row className="mb-3 mt-2">
                  <Col md={12}>
                    <AdditionalFeaturesAdmin
                      selAccountLists={selAccounts}
                      cloud="aws"
                    />
                  </Col>
                  {/* <Col md={8}>
                    <BillingChart selAccountLists={selAccounts} cloud="aws" />
                  </Col> */}
                </Row>
                <Row>
                  {/* <Col md={12}>
                    <InvoiceBreakdownTable
                      selAccountLists={selAccounts}
                      cloud="aws"
                    />
                  </Col> */}
                </Row>
              </>
            )}
          </>
        )}
      </Container>
      {showChargeBackSetting && (
        <CustomModal
          showModal={true}
          fileUpload={true}
          children={
            <ChargeBackSetting
              setShowChargeBackSetting={setShowChargeBackSetting}
            />
          }
        />
      )}
      {isInvoiceChartOpen && (
        <CustomModal
          showModal={true}
          children={
            <BillingChart
              selAccountLists={selAccounts}
              cloud="aws"
              height="340px"
              innerHeight="340px"
              setInvoiceChartOpen={setInvoiceChartOpen}
            />
          }
        />
      )}
    </>
  );
};

export default BillingAdmin;
