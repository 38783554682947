import Api from "api/api";
import client from "api/client";

export function prearePostRequest({ idToken, ...rest }) {
  if (!idToken) {
    throw Error("Id Token Not Present");
  }

  return {
    body: rest,
    skipToken: true,
    headers: {
      Authorization: `{"idToken":{"idToken":"${idToken}"}}`,
    },
  };
}

export function cloudEyeAppListApi(values) {
  // return Promise.resolve([
  //   "Sonar(PROD)",
  //   "Jmanage(PROD)",
  //   "Sonar(Development):ReadOnly",
  // ]);
  return client(Api.cloudEyeAppList, {
    ...prearePostRequest(values),
  }).then((res) => res.data);
}

export function webServiceApi(values) {
  return client(Api.webserviceApi, {
    ...prearePostRequest(values),
  });
}

export function resourceHealthApi(values) {
  return client(Api.resourceHeathApi, {
    ...prearePostRequest(values),
  });
}

export function userServiceApi(values) {
  return client(Api.userService, {
    ...prearePostRequest(values),
  });
}

export function userAppMetricsApi(values) {
  return client(Api.userAppMetrics, {
    ...prearePostRequest(values),
  });
}

export function toggleWebServiceApi(values) {
  return client(Api.toggleWebServiceApi, {
    ...prearePostRequest(values),
  });
}

export function getAppListForCloudEyeAppRequestApi(values) {
  return client(Api.appListForCloudEyeRequest, {
    ...prearePostRequest(values),
  }).then((res) => res.data);
}

export function createRequestForAppsInCloudEye(values) {
  return client(Api.createCloudEyeAppRequest, {
    ...prearePostRequest(values),
  }).then((res) => res.data);
}

export function viewCloudEyeRequestsByEmail(values) {
  return client(Api.viewCloudEyeRequestsForEmail, {
    ...prearePostRequest(values),
  }).then((res) => res.data);
}
