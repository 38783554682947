import React from "react";

// Import Internal Components
import GISAccount from "./GISAccount";
import ScanningAndInstallation from "./ScanningAndInstallation";
import InstallSchedule from "./EnableWindow/InstallSchedule";
import TargetTab from "./EnableWindow/TargetTab";
import ReviewAccount from "./EnableWindow/ReviewAccount";

const FormSteps = (
  step,
  setStep,
  formData,
  setFormData,
  instanceList,
  setInstanceList
) => [
  {
    title: "",
    content: (
      <GISAccount
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
        instanceList={instanceList}
        setInstanceList={setInstanceList}
      />
    ),
  },
  {
    title: "",
    content: (
      <InstallSchedule
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
        instanceList={instanceList}
      />
    ),
  },
  {
    title: "",
    content: (
      <TargetTab
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
        instanceList={instanceList}
      />
    ),
  },
  {
    title: "",
    content: (
      <ReviewAccount
        setStep={setStep}
        step={step}
        formData={formData}
        setFormData={setFormData}
        instanceList={instanceList}
      />
    ),
  },
];

export default FormSteps;
