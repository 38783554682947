import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Table, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Moment from "moment";


import backicon from "icons/back.svg";

// Import Constant
import { PRISMA } from "redux/security/securityConstant";

import SpinnerLoader from "components/Common/SpinnerLoader";

import Pagination from "components/Pagination/Pagination";
import { useTrendMicroConformityDetailsApi } from "./dataloader";

const PrismaCanvas = ({
  userInfo,
  title,
  selectedLegend,
  selectedCloudType,
}) => {
  const dispatch = useDispatch();

  // Set Legend Label
  const [canvasSelectLegend, setCanvasSelectLegend] = useState(
    selectedLegend.toLowerCase()
  );

  // API State

  const { isFetching: loading, data: responseData } =
    useTrendMicroConformityDetailsApi({
      severity: canvasSelectLegend,
      qty: "7",
      attr: "day",
      type: selectedCloudType,
    });

  // Filtered State

  // Hide Canvas
  function hideCanvas() {
    dispatch({
      type: PRISMA,
      payload: false,
    });
  }

  // API Variables
  const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

  // Pagination Part
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch Data

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return responseData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, PageSize]);

  const { t } = useTranslation();

  return (
    <div className="offcanvas-details-wrapper pb-5">
      {loading && (
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <SpinnerLoader />
          </Col>
        </Row>
      )}
      {loading === false && (
        <>
          <div className="offcanvas-details-top-bar">
            <div className="offcanvas-details-back-heading">
              <img
                src={backicon}
                alt="Back"
                className="view-edit-box-back-btn"
                onClick={() => hideCanvas()}
              />
              <h5 className="view-edit-box-title d-flex align-items-center">
                {title}
                <i
                  className="fa fa-info-circle px-2"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={`Note: Data as per last 7 days, from (${Moment()
                    .subtract(7, "d")
                    .format("YYYY-MM-DD")} to ${Moment().format(
                    "YYYY-MM-DD"
                  )})`}
                ></i>
              </h5>
            </div>
            <div className="offcanvas-legend-details">
              <ul className="canvas-legend-list-wrapper px-0">
                <li
                  className={
                    canvasSelectLegend === "extreme"
                      ? "canvas-legend-list-active"
                      : "canvas-legend-list"
                  }
                  onClick={() => setCanvasSelectLegend("extreme")}
                >
                  <div className="canvas-legend-list-red"></div>
                  Extreme
                </li>
                <li
                  className={
                    canvasSelectLegend === "very_high"
                      ? "canvas-legend-list-active"
                      : "canvas-legend-list"
                  }
                  onClick={() => setCanvasSelectLegend("very_high")}
                >
                  <div className="canvas-legend-list-orange"></div>
                  Very High
                </li>
                <li
                  className={
                    canvasSelectLegend === "high"
                      ? "canvas-legend-list-active"
                      : "canvas-legend-list"
                  }
                  onClick={() => setCanvasSelectLegend("high")}
                >
                  <div className="canvas-legend-list-yellow"></div>
                  High
                </li>
              </ul>
            </div>
          </div>

          {loading === false && currentTableData.length === 0 && (
            <p className="text-center">No data to display</p>
          )}

          {loading === false && currentTableData.length > 0 && (
            <>
              <Table
                responsive
                className=" mb-0 table-centered table-nowrap table-borderless"
              >
                <thead className="offcanvas-details-table-head-font">
                  <tr className="mb-2">
                    <td>ACCOUNT NUMBER</td>
                    <td>ACCOUNT NAME</td>
                    <td>CLOUD</td>
                    <td>EXTREME</td>
                    <td>VERY HIGH</td>
                    <td>HIGH</td>
                  </tr>
                </thead>
                <tbody className="offcanvas-details-table-body-font">
                  {currentTableData.map((data, index) => (
                    <tr key={index} className="mb-3">
                      <td>{data.accountnumber}</td>
                      <td>{data.accountname}</td>
                      <td className="text-uppercase">{data.Cloud}</td>
                      <td align="center">
                        <div className="badge-wrapper red-badge">
                          {data.EXTREME}
                        </div>
                      </td>
                      <td align="center">
                        <div className="badge-wrapper orange-badge">
                          {data.VERY_HIGH}
                        </div>
                      </td>
                      <td align="center">
                        <div className="badge-wrapper yellow-badge">
                          {data.HIGH}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="d-flex justify-content-end align-items-baseline">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={responseData.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                  currentTableDataLength={currentTableData.length}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PrismaCanvas;
