import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import ReactECharts from 'echarts-for-react';
import { useDispatch, useSelector } from 'react-redux';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';

import PrismaCanvas from './PrismaCanvas';

// Import Constant
import { PRISMA, TREND } from 'redux/cloudeye-user/cloueyeUserConstant';

// Static Data
import { datesStaticData } from './securityStaticData';

// Helper
import { postApiCall } from 'helper/postApiCall';

const Prisma = ({ applicationName, userInfo }) => {
  const isMounted = useRef(false);

  const dispatch = useDispatch();

  const cloudEye = useSelector((state) => state.cloudEye);
  const { prismaCanvas } = cloudEye;

  const apiUrl = `${process.env.REACT_APP_CLOUD_EYE_SECURITY_PRISMA_COUNT}`;
  const idToken = `${userInfo.idToken}`;

  const [canvasTitle, setCanvasTitle] = useState(null);
  const [selectedLegend, setSelectedLegend] = useState(null);

  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [error, setError] = useState(null);

  // Form State
  const [selectedDate, setSelectedDate] = useState('24-Hour');

  var dataSet;
  if (
    loading === false &&
    responseData.length > 0 &&
    responseData.filter((e) => e.type === 'aws').length > 0
  ) {
    var getData = responseData.find((ele) => ele.type === 'aws');
    dataSet = [
      {
        name: 'High',
        value: getData.High,
        color: '#ff3f3f',
        type: 'aws',
      },
      {
        name: 'Medium',
        value: getData.Medium,
        color: '#ffe181',
        type: 'aws',
      },
      {
        name: 'Low',
        value: getData.Low,
        color: '#69bc50',
        type: 'aws',
      },
    ];
  } else {
    dataSet = [];
  }

  var pieOption = {
    maintainAspectRatio: false,
    tooltip: {
      show: true,
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: 'pie',
        radius: ['60px', '50px'],
        data: dataSet.map(({ name, value, color, borderRadius, type }) => ({
          name,
          value,
          itemStyle: { color: color, borderRadius: borderRadius },
          type,
        })),
        label: {
          alignTo: 'labelLine',
          formatter: '{c}',
          minMargin: 5,
          edgeDistance: 10,
        },
        labelLine: {
          length: 20,
          length2: 10,
        },
      },
    ],
  };

  function onChartClick(param, echarts) {
    setCanvasTitle('Alert Details');
    setSelectedLegend(param.data.name);

    dispatch({
      type: PRISMA,
      payload: true,
    });
    dispatch({
      type: TREND,
      payload: false,
    });
  }

  // Date Dependency Load
  useEffect(() => {
    if (isMounted.current) {
      setLoading(true);
      const requestBody = {
        applicationname: `${applicationName}`,
        datenum: `${selectedDate.split('-')[0]}`,
        datetype: `${selectedDate.split('-')[1]}`,
      };

      postApiCall(apiUrl, idToken, requestBody)
        .then((response) => {
          setResponseData(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setError(
            'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
          );
          setLoading(false);
        });
    }
    return () => {
      setResponseData([]);
    };
  }, [selectedDate]);

  // 1st Load
  useEffect(() => {
    setLoading(true);
    const requestBody = {
      applicationname: `${applicationName}`,
      datenum: `${selectedDate.split('-')[0]}`,
      datetype: `${selectedDate.split('-')[1]}`,
    };

    postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        setResponseData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(
          'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
        );
        setLoading(false);
      });

    isMounted.current = true;

    return () => {
      setResponseData([]);
    };
  }, [applicationName]);

  return (
    <div className='custom-card'>
      {loading && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center mt-3'>
            <SpinnerLoader />
          </Col>
        </Row>
      )}
      {loading === false && error !== null && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center mt-3'>
            <DangerAlert message={error} />
          </Col>
        </Row>
      )}
      {loading === false && error === null && (
        <>
          <Row>
            <Col md={7} xs={6}>
              <p className='subtitle-text px-2'>Prisma</p>
            </Col>
            <Col md={5} xs={6}>
              <Form.Select
                className='mb-2'
                size='sm'
                value={selectedDate}
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                }}
              >
                {datesStaticData.map((data, index) => (
                  <option key={index} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={12} xs={12}>
              <ReactECharts
                option={pieOption}
                onEvents={{
                  click: onChartClick,
                }}
                className='aws-chart-logo-center-security'
                style={{ height: '200px', width: '100%' }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className='d-flex align-items-center'>
              <ul className='legend-list-wrapper px-0'>
                <li className='legend-list'>
                  <div className='legend-list-red'></div>
                  High
                </li>
                <li className='legend-list'>
                  <div className='legend-list-yellow'></div>
                  Medium
                </li>
                <li className='legend-list'>
                  <div className='legend-list-green'></div>
                  Low
                </li>
              </ul>
            </Col>
          </Row>
        </>
      )}

      {prismaCanvas === true && (
        <PrismaCanvas
          userInfo={userInfo}
          title={canvasTitle}
          selectedLegend={selectedLegend}
          applicationName={applicationName}
          datenum={selectedDate.split('-')[0]}
          datetype={selectedDate.split('-')[1]}
        />
      )}
    </div>
  );
};

export default Prisma;
