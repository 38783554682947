import React, { useState } from "react";

const RequestSubmitConfirmation = ({
  downloadSuccessMsg = null,
  downloadError = null,
  invoice = null,
  duplicate = null,
  remove = null,
  error = null,
  success = null,
  save = null,
  closeModalParent,
  proceedToSave = () => {},
}) => {
  const message = () => {
    if (downloadSuccessMsg) {
      return <p>{downloadSuccessMsg}</p>;
    } else if (downloadError) {
      return <p>{downloadError}</p>;
    } else if (invoice) {
      return <p>{invoice}</p>;
    } else if (duplicate) {
      return (
        <p>
          Duplicates are not allowed for GSID Tags. Please try with other
          values.
        </p>
      );
    } else if (remove) {
      return <p>Are you sure to remove the entry?</p>;
    } else if (error) {
      return <p>Please fill the required fields.</p>;
    } else if (success) {
      return <p>Your request has been successfully submitted.</p>;
    } else if (save) {
      return (
        <p>
          All the changes made in the current session will be submitted to
          calculate Chargeback. Click "Ok" to continue.
        </p>
      );
    } else {
      return (
        <p>
          Please note that all of the changes made so far in the current session
          will be reverted. Click "OK" to continue.
        </p>
      );
    }
  };
  return (
    <div className="d-flex justify-content-center flex-column">
      <>
        {message()}

        <ul className="btn-ul mb-0">
          <li>
            <button
              type="button"
              className="teal-bordered-btn"
              onClick={() => {
                closeModalParent(null);
              }}
            >
              {success ||
              error ||
              duplicate ||
              invoice ||
              downloadError ||
              downloadSuccessMsg
                ? "Ok"
                : "Cancel"}
            </button>
          </li>

          {!success &&
          !error &&
          !duplicate &&
          !invoice &&
          !downloadError &&
          !downloadSuccessMsg ? (
            <li>
              <button
                type="button"
                className="teal-filled-btn"
                onClick={() => {
                  proceedToSave();
                }}
              >
                Ok
              </button>
            </li>
          ) : null}
        </ul>
      </>
    </div>
  );
};

export default RequestSubmitConfirmation;
