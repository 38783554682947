export const documentValidator = ({
  isUrlSelected,
  subject,
  category,
  url,
  filePath,
  baseFile,
  description,
}) => {
  let errors = {};

  if (!subject) {
    errors.subject = 'Subject name is required';
  }

  if (!category) {
    errors.category = 'Category name is required';
  }

  if (!description) {
    errors.description = 'Description name is required';
  }

  if (isUrlSelected) {
    if (!url) {
      errors.url = 'URL is required';
    }
  } else {
    if (!baseFile) {
      errors.baseFile = 'File is required';
    }
    if (!filePath) {
      errors.filePath = 'Path is required';
    }
  }

  return errors;
};
