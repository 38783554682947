import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';

const ApplicationDatatable = ({ applicationData }) => {
  const [applicationDataArray, setApplicationDataArray] = useState([]);
  const {t} = useTranslation();
  
  const columns = [
    {
      dataField: 'StatusCheckTime',
      text: 'STATUS CHECK TIME',
      sort: true,
      searchable: true,
    },
    {
      dataField: 'LastStatus',
      text: 'LAST STATUS',
      sort: true,
      searchable: true,
    },
    {
      dataField: 'Service',
      text: 'SERVICE',
      sort: true,
      searchable: true,
    },
    {
      dataField: 'InstanceId',
      text: 'INSTANCE ID',
      sort: true,
      searchable: true,
    },
    {
      dataField: 'ServerName',
      text: 'SERVER NAME',
      sort: true,
      searchable: true,
    },
    {
      dataField: 'ServerStatus',
      text: 'STATUS',
      sort: true,
      searchable: true,
    },
    // {
    //   dataField: 'dummyActionField',
    //   text: 'ACTION',
    //   formatter: (cellContent, row) => {
    //     return (
    //       <button type='button' className='teal-nobordered-btn'>
    //         View Metrics
    //       </button>
    //     );
    //   },
    // },
  ];

  const defaultSorted = [
    {
      dataField: 'InstanceId',
      order: 'desc',
    },
  ];

  const customTotal = (from, to, size) => (
    <span className='react-bootstrap-table-pagination-total'>
      Showing {from} to {to} of {size}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: applicationDataArray.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    let applicationArray = [];

    for (var i = 0; i < applicationData.data.body.length; i++) {
      var applicationObject = {};
      applicationObject['ServiceUniqueId'] = i;
      applicationObject['StatusCheckTime'] =
        applicationData.data.body[i].StatusCheckTime;
      applicationObject['LastStatus'] = applicationData.data.body[i].LastStatus;
      applicationObject['Service'] = applicationData.data.body[i].Service;
      applicationObject['InstanceId'] = applicationData.data.body[i].InstanceId;
      applicationObject['ServerName'] = applicationData.data.body[i].ServerName;
      applicationObject['ServerStatus'] =
        applicationData.data.body[i].ServerStatus;

      applicationArray.push(applicationObject);
    }

    setApplicationDataArray(applicationArray);
  }, []);

  return (
    <div className='custom-card mb-4'>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField='InstanceId'
        columns={columns}
        data={applicationDataArray}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField='InstanceId'
            columns={columns}
            data={applicationDataArray}
            search
          >
            {(toolkitProps) => (
              <>
                <Row className='d-flex align-items-center'>
                  <Col md={9} className='mb-2'>
                    <span className='subtitle-text'>Service Details</span>
                  </Col>

                  {applicationDataArray.length > 0 && (
                    <Col md={3}>
                      <div className='search-input-group'>
                        <i className='fa fa-search'></i>
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder={t("search_placeholder")}
                          className='custom-search-input-form-control'
                        />
                      </div>
                    </Col>
                  )}
                </Row>

                <>
                  <Row>
                    <Col md={12} xl={12} className='mt-4'>
                      <div className='table-responsive'>
                        <BootstrapTable
                          keyField={'InstanceId'}
                          responsive
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={'table align-middle table-nowrap'}
                          headerWrapperClasses={
                            'custom-table-head custom-pointer'
                          }
                          bodyClasses={'custom-table-body'}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                    <Col>
                      {applicationDataArray.length === 0 && (
                        <div className='custom-error-message text-center'>
                          No Data to Display
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className='align-items-md-center mt-3'>
                    <Col className='d-flex flex-wrap justify-content-between align-items-baseline'>
                      <div>
                        <PaginationTotalStandalone {...paginationProps} />
                      </div>
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </div>
  );
};

export default ApplicationDatatable;
