import React, { useState, useEffect, useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';

import Pagination from 'components/Pagination/Pagination';

import { CLOUD_EYE_MANAGEMENT_VIEW_DETAIL } from 'redux/cloudeye-management/cloudEyeManagementConstant';

// Import Icons
import backicon from 'icons/back.svg';

const ApplicationView = ({ data, filter, currentDomainName }) => {
  const dispatch = useDispatch();

  const [arrayData, setArrayData] = useState([]);

  // Pagination Part
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return arrayData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, arrayData, PageSize]);

  useEffect(() => {
    let filteredArray;

    if (filter === 'Total') {
      setArrayData(data);
    }
    if (filter === 'Healthy') {
      filteredArray = data.filter((x) => x.Status === 'Healthy');
      setArrayData(filteredArray);
    }
    if (filter === 'Unhealthy') {
      filteredArray = data.filter((x) => x.Status === 'Unhealthy');
      setArrayData(filteredArray);
    }
    return () => {
      setArrayData([]);
    };
  }, []);

  return (
    <div className='view-edit-box-conatiner pb-5'>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => {
              dispatch({
                type: CLOUD_EYE_MANAGEMENT_VIEW_DETAIL,
                payload: '',
              });
            }}
          />
          <h5 className='view-edit-box-title'>{filter} Application Details</h5>
        </div>
      </div>

      {arrayData.length > 0 && (
        <Table
          responsive
          className='mt-3 mb-0 table-centered table-nowrap table-borderless'
        >
          <thead className='offcanvas-details-table-head-font'>
            <tr className='mb-2'>
              <td>App</td>
              <td>Status</td>
              <td>Downtime</td>
            </tr>
          </thead>

          <tbody className='offcanvas-details-table-body-font'>
            {currentTableData.map((data, index) => (
              <tr key={index} className='mb-3'>
                <td>
                  {/* <Link to={`/cloudeye-management-user-view/${data.App}`}>
                    {data.App}
                  </Link> */}
                  <a
                    href={`${currentDomainName}/cloudeye-management-user-view-dashboard/${data.App}`}
                    className='service-status-link'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {data.App}
                  </a>
                </td>
                <td>
                  {data.Status === 'Healthy' && (
                    <div className='approved-badge'>{data.Status}</div>
                  )}
                  {data.Status === 'Unhealthy' && (
                    <div className='declined-badge'>{data.Status}</div>
                  )}
                </td>
                <td>{data.Downtime}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {arrayData.length === 0 && (
        <div className='regular-text d-flex justify-content-center mt-3'>
          No Data to Display
        </div>
      )}
      {arrayData.length > 0 && (
        <div className='d-flex justify-content-end align-items-baseline mt-3 mb-5'>
          <Pagination
            className='pagination-bar'
            currentPage={currentPage}
            totalCount={arrayData.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
            currentTableDataLength={currentTableData.length}
          />
        </div>
      )}
    </div>
  );
};

export default ApplicationView;
