import React from "react";
import { Row, Col } from "react-bootstrap";

// Import Icons
import backicon from "icons/back.svg";
import editicon from "icons/edit.svg";

import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";

const AccountRequestsView = ({
  data,
  cloudTitle,
  closeViewParent,
  openEditParent,
  userInfo,
}) => {
  return (
    <div className="view-edit-box-conatiner">
      <div className="view-edit-box-top-bar">
        <div className="view-edit-box-back-title-container">
          <img
            src={backicon}
            alt="Back"
            className="view-edit-box-back-btn"
            onClick={() => closeViewParent(false)}
          />
          <div>
            <h5 className="view-edit-box-title">Account Backup Details</h5>
            {(data.requestStatus === "Approved" ||
              data.requestStatus === "Cancelled" ||
              data.requestStatus === "Declined") && (
              <Row>
                <Col md={12}>
                  <div className="d-flex justify-content-center">
                    <span className="account-request-view-request-message">
                      {data.RequestActionTakenBy &&
                        `Request ${data.requestStatus} by
                      ${data.RequestActionTakenBy}`}
                    </span>
                  </div>
                </Col>
              </Row>
            )}
          </div>
          {data.requestStatus === "Approved" && (
            <span className="view-edit-box-accountid mx-3">
              Account ID: {data.accountNumber}
            </span>
          )}
        </div>
        <div className="view-edit-box-back-edit-container">
          {data.requestStatus === "Pending" &&
            (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
              data.emailAO.toLowerCase() === userInfo.email.toLowerCase() ||
              data.emailTC.toLowerCase() === userInfo.email.toLowerCase() ||
              data.submittedBy === userInfo.preferred_username) && (
              <img
                src={editicon}
                alt="Edit"
                className="view-edit-box-edit-btn"
                onClick={() => {
                  closeViewParent(false);
                  openEditParent(true);
                }}
              />
            )}
          {/* {data.requestStatus === 'Approved' &&
            (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
              data.emailAO.toLowerCase() === userInfo.email.toLowerCase() ||
              data.emailTC.toLowerCase() === userInfo.email.toLowerCase()) && (
              <img
                src={editicon}
                alt='Edit'
                className='view-edit-box-edit-btn'
                onClick={() => {
                  closeViewParent(false);
                  openEditParent(true);
                }}
              />
            )} */}
          {data.requestStatus === "Update Declined" &&
            (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
              data.emailAO.toLowerCase() === userInfo.email.toLowerCase() ||
              data.emailTC.toLowerCase() === userInfo.email.toLowerCase()) && (
              <img
                src={editicon}
                alt="Edit"
                className="view-edit-box-edit-btn"
                onClick={() => {
                  closeViewParent(false);
                  openEditParent(true);
                }}
              />
            )}
        </div>
      </div>
      <div className="table-view-box-content mt-4 mb-5">
        <Row>
          {/* <Col md={12} className="mt-3">
            <div className="account-request-view-title-container">
              <p className="account-request-view-title">
                Account Backup Details
              </p>
            </div>
          </Col> */}
          <Col md={12} className="mt-3">
            <div className="account-request-view-table-container">
              <table width="100%">
                <tbody className="account-request-view-table-tbody">
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Request Id
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.uid}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Account Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.accountName}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Account Number
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.accountNumber}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Region
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.region}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Cross Region Backup
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.drBackUp === "true" ? "Yes" : "No"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Cross Account Backup
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.crossBackUp === "true" ? "Yes" : "No"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      BackUp Plan
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.backUpPlan}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Submitted By
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.submittedBy}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Request Status
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.requestStatus}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AccountRequestsView;
