import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

// Import Icons
import backicon from 'icons/back.svg';

// Helper
import { postApiCall } from 'helper/postApiCall';

import SpinnerLoader from 'components/Common/SpinnerLoader';
import DangerAlert from 'components/Message/DangerAlert';
import Pagination from 'components/Pagination/Pagination';

const ViewAlarm = ({ application, namespace, closeViewParent }) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // API State
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [error, setError] = useState(null);

  // API Variables
  const apiUrl = `${process.env.REACT_APP_CLOUD_EYE_VIEW_ALARMS}`;
  const idToken = `${userInfo.idToken}`;
  const requestBody = {
    application: `${application.trim()}`,
    namespace: `${namespace}`,
  };

  // Pagination Part
  let PageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch Data
  const fetchData = useCallback(() => {
    setLoading(true);
    postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        setResponseData(response.data.body);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(
          'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
        );
        setLoading(false);
      });
  }, [requestBody]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return responseData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, responseData, PageSize]);

  useEffect(() => {
    fetchData();
    return () => {
      setResponseData([]);
    };
  }, []);
  return (
    <div className='view-edit-box-conatiner pb-5' style={{ width: '640px' }}>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => closeViewParent(false)}
          />
          <div>
            <h5 className='view-edit-box-title'>Alarm Details</h5>
          </div>
        </div>
      </div>
      {loading && <SpinnerLoader />}
      {loading === false && error !== null && <DangerAlert message={error} />}
      {loading === false && error === null && responseData.length === 0 && (
        <div className='custom-error-message text-center mt-4'>
          No Data to Display
        </div>
      )}
      {loading === false && error === null && responseData.length > 0 && (
        <div className='table-view-box-content mt-4 mb-5'>
          {currentTableData.map((data, index) => (
            <Row key={index}>
              <Col md={12} className='mb-3'>
                <div className='view-alarm-container'>
                  <Row>
                    <Col md={3} className='mb-2 px-3'>
                      <div className='view-alarm-key mb-2'>STATE</div>
                      <div className='view-alarm-data'>
                        {data.split('|')[0]}
                      </div>
                    </Col>
                    <Col md={6} className='mb-2 px-3'>
                      <div className='view-alarm-key mb-2'>NAME</div>
                      <div className='view-alarm-data'>
                        {data.split('|')[1]}
                      </div>
                    </Col>
                    <Col md={3} className='mb-2 px-3'>
                      <div className='view-alarm-key mb-2'>DESCRIPTION</div>
                      <div className='view-alarm-data'>
                        {data.split('|')[2]}
                      </div>
                    </Col>
                    <Col md={3} className='mb-2 px-3'>
                      <div className='view-alarm-key mb-2'>UPDATED TIME</div>
                      <div className='view-alarm-data'>
                        {data.split('|')[4]}
                      </div>
                    </Col>
                    <Col md={6} className='mb-2 px-3'>
                      <div className='view-alarm-key mb-2'>
                        ALERT STATE REASON
                      </div>
                      <div className='view-alarm-data'>
                        {data.split('|')[5]}
                      </div>
                    </Col>
                    <Col md={3} className='mb-2 px-3'>
                      <div className='view-alarm-key mb-2'>METRIC</div>
                      <div className='view-alarm-data'>
                        {data.split('|')[3]}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          ))}
          <div className='d-flex justify-content-end align-items-baseline'>
            <Pagination
              className='pagination-bar'
              currentPage={currentPage}
              totalCount={responseData.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
              currentTableDataLength={currentTableData.length}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewAlarm;
