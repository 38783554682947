import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Row, Col } from 'react-bootstrap';
import axios from 'axios';

import backicon from 'icons/back.svg';

// Import Constant
import { PRISMA } from 'redux/security/securityConstant';

import SpinnerLoader from 'components/Common/SpinnerLoader';

import Pagination from 'components/Pagination/Pagination';

const PrismaCanvas = ({
  userInfo,
  title,
  selectedLegend,
  applicationName,
  datenum,
  datetype,
}) => {
  const dispatch = useDispatch();

  // API State
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  // Set Legend Label
  const [canvasSelectLegend, setCanvasSelectLegend] = useState(
    selectedLegend.toLowerCase()
  );

  // Filtered State
  const [filteredLoading, setFilteredLoading] = useState(true);
  const [selectedLableValues, setSelectedLableValues] = useState([]);

  // Hide Canvas
  function hideCanvas() {
    dispatch({
      type: PRISMA,
      payload: false,
    });
  }

  // API Variables
  const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

  // Pagination Part
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch Data
  const fetchPrismaData = useCallback(() => {
    setFilteredLoading(true);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: setIdToken,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_CLOUD_EYE_SECURITY_PRISMA_DETAILS}`,
        {
          severity: canvasSelectLegend,
          qty: `${datenum}`,
          attr: `${datetype}`,
          applicationname: applicationName,
        },
        config
      )
      .then((response) => {
        setResponseData(response.data);
        const selectedLabelData = response.data.filter(
          (data) => data.sev === canvasSelectLegend.toLowerCase()
        );
        setSelectedLableValues(selectedLabelData);
        setLoading(false);
        setFilteredLoading(false);
      });
  }, [canvasSelectLegend]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return selectedLableValues.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, selectedLableValues, PageSize]);

  useEffect(() => {
    fetchPrismaData();
    return () => {
      setResponseData([]);
    };
  }, [fetchPrismaData, canvasSelectLegend]);

  return (
    <div className='offcanvas-details-wrapper pb-5'>
      {loading && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center'>
            <SpinnerLoader />
          </Col>
        </Row>
      )}
      {loading === false && (
        <>
          <div className='offcanvas-details-top-bar'>
            <div className='offcanvas-details-back-heading'>
              <img
                src={backicon}
                alt='Back'
                className='view-edit-box-back-btn'
                onClick={() => hideCanvas()}
              />
              <h5 className='view-edit-box-title d-flex align-items-center'>
                {title}
              </h5>
            </div>
            <div className='offcanvas-legend-details'>
              <ul className='canvas-legend-list-wrapper px-0'>
                <li
                  className={
                    canvasSelectLegend === 'high'
                      ? 'canvas-legend-list-active'
                      : 'canvas-legend-list'
                  }
                  onClick={() => setCanvasSelectLegend('high')}
                >
                  <div className='canvas-legend-list-red'></div>
                  High
                </li>
                <li
                  className={
                    canvasSelectLegend === 'medium'
                      ? 'canvas-legend-list-active'
                      : 'canvas-legend-list'
                  }
                  onClick={() => setCanvasSelectLegend('medium')}
                >
                  <div className='canvas-legend-list-yellow'></div>
                  Medium
                </li>
                <li
                  className={
                    canvasSelectLegend === 'low'
                      ? 'canvas-legend-list-active'
                      : 'canvas-legend-list'
                  }
                  onClick={() => setCanvasSelectLegend('low')}
                >
                  <div className='canvas-legend-list-green'></div>
                  Low
                </li>
              </ul>
            </div>
          </div>

          {filteredLoading && (
            <Row>
              <Col className='d-flex justify-content-center align-items-center'>
                <SpinnerLoader />
              </Col>
            </Row>
          )}

          {loading === false &&
            filteredLoading === false &&
            currentTableData.length === 0 && (
              <p className='text-center'>No data to display</p>
            )}

          {loading === false &&
            filteredLoading === false &&
            currentTableData.length > 0 && (
              <>
                <Table
                  responsive
                  className=' mb-0 table-centered table-nowrap table-borderless'
                >
                  <thead className='offcanvas-details-table-head-font'>
                    <tr className='mb-2'>
                      <td>ACCOUNT NAME</td>
                      <td>ACCOUNT ID</td>
                      <td>ALERT ID</td>
                      <td>POLICY</td>
                      <td>ALERT SEVERITY</td>
                      <td>RESOURCE</td>
                    </tr>
                  </thead>
                  <tbody className='offcanvas-details-table-body-font'>
                    {currentTableData.map((data, index) => (
                      <tr key={index} className='mb-3'>
                        <td>{data.accountname}</td>
                        <td>{data.accountid}</td>
                        <td>{data.alertid}</td>
                        <td>{data.policy}</td>
                        <td align='center'>
                          {canvasSelectLegend.toLowerCase() === 'high' && (
                            <div className='badge-wrapper red-badge'>
                              {data.sev}
                            </div>
                          )}
                          {canvasSelectLegend.toLowerCase() === 'medium' && (
                            <div className='badge-wrapper yellow-badge'>
                              {data.sev}
                            </div>
                          )}
                          {canvasSelectLegend.toLowerCase() === 'low' && (
                            <div className='badge-wrapper green-badge'>
                              {data.sev}
                            </div>
                          )}
                        </td>
                        <td>{data.resource}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className='d-flex justify-content-end align-items-baseline'>
                  <Pagination
                    className='pagination-bar'
                    currentPage={currentPage}
                    totalCount={responseData.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                    currentTableDataLength={currentTableData.length}
                  />
                </div>
              </>
            )}
        </>
      )}
    </div>
  );
};

export default PrismaCanvas;
