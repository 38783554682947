import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = ({notAuthorised}) => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center not-found-bg text-center'>
      {notAuthorised ? <div className='not-found-section'>
        <div className='not-found-page'>
          You are not authorised
        </div>
        <Link to='/landing' className='teal-filled-btn'>
          Go back to Home
        </Link>
      </div>
      :
      <div className='not-found-section'>
        <h1 className='not-found-error'>404</h1>
        <div className='not-found-page'>
          Ooops!!! The page you are looking for is not found
        </div>
        <Link to='/landing' className='teal-filled-btn'>
          Go back to Home
        </Link>
      </div>}
    </div>
  );
};

export default NotFound;
