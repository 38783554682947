import { SELECTED_INSTANCES, PROVIDED_TIME } from "./patchAccountConstant";

export const patchAccountReducer = (
  state = {
    selectedInstancesList: [],
  },
  action
) => {
  switch (action.type) {
    case SELECTED_INSTANCES:
      return { ...state, selectedInstancesList: action.payload };
    default:
      return state;
  }
};
