import Loader from "components/Common/Loader";
import TextInput from "components/Forms/TextInput";
import CustomModal from "components/Modal/CustomModal";
import NewRequestCancel from "pages/Billings/Components/NewRequestCancel";
import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GsidIoFormValidator } from "../billingValidator";
import { postApiCall } from "helper/postApiCall";
import RequestSubmitConfirmation from "./RequestSubmitConfirmation";
import { useSelector } from "react-redux";
import { getISTTime } from "static-contents/billing-data";

const AddEditForm = ({
  setEditOpen,
  editSelectedRow,
  setEditSelectedRow,
  setShowSuccessMessage,
}) => {
  const { t } = useTranslation();
  //REDUX
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const idToken = userInfo.idToken;

  const postApiUrl = `${process.env.REACT_APP_BILLING_GSIDIO_ADD_EDIT}`;
  const [formState, setFormState] = useState({});
  const [dataInputError, setDataInputError] = useState({});
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);

  const [apiError, setApiError] = useState(null);
  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleContinue = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formDataObj = Object.fromEntries(formData.entries());
    const errors = GsidIoFormValidator(formDataObj);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setEditSelectedRow({});
      //   setStep(step + 1);
      setLoading(true);
      const requestBody = {
        requestType: "put",
        actionType: "GSIDIO",
        updatedby: userInfo?.email,
        updateddate: getISTTime().getDate,
        updatedtime: getISTTime().getTime,
        data: [formDataObj],
      };
      postApiCall(postApiUrl, idToken, requestBody)
        .then((response) => {
          if (response.data.status === "Success") {
            setLoading(false);
            setEditOpen(false);
            setShowSuccessMessage(true);
          }
        })
        .catch((err) => {
          setApiError(
            "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
          );
        });
    }
  };
  const handleData = ({ target }) => {
    setFormState({
      ...formState,
      [target.name]: target?.value,
    });
  };

  useEffect(() => {
    setFormState(editSelectedRow?.values);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Row className="px-3">
            <Col md={12} xs={12}>
              <h5 className="page-content-title mb-4">
                {t("GSID IO Mapping")}
              </h5>
            </Col>
          </Row>
          <Form onSubmit={handleContinue}>
            <Row className="px-3">
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("GSID") + ":"}
                  name="GSID"
                  value={formState["GSID"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("GSID")}
                  isInvalid={dataInputError["GSID"] ? true : false}
                  invalidMessage={dataInputError["GSID"]}
                />
              </Col>
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("IO") + ":"}
                  name="IO"
                  value={formState["IO"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("IO")}
                  isInvalid={dataInputError?.["IO"] ? true : false}
                  invalidMessage={dataInputError?.["IO"]}
                />
              </Col>
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("Opco") + ":"}
                  name="Billing Opco"
                  value={formState["Billing Opco"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Opco")}
                  isInvalid={dataInputError?.["Billing Opco"] ? true : false}
                  invalidMessage={dataInputError?.["Billing Opco"]}
                />
              </Col>

              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("Sub Opco") + ":"}
                  name="Billing Sub OpCo"
                  value={formState["Billing Sub OpCo"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Sub Opco")}
                  isInvalid={
                    dataInputError?.["Billing Sub OpCo"] ? true : false
                  }
                  invalidMessage={dataInputError?.["Billing Sub OpCo"]}
                />
              </Col>
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("Department") + ":"}
                  name="Billing Department"
                  value={formState["Billing Department"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Department")}
                  isInvalid={
                    dataInputError?.["Billing Department"] ? true : false
                  }
                  invalidMessage={dataInputError?.["Billing Department"]}
                />
              </Col>
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("Region") + ":"}
                  name="Billing Region"
                  value={formState["Billing Region"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Region")}
                  isInvalid={dataInputError?.["Billing Region"] ? true : false}
                  invalidMessage={dataInputError?.["Billing Region"]}
                />
              </Col>
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("First Name") + ":"}
                  name="Billing First Name"
                  value={formState["Billing First Name"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("First Name")}
                  isInvalid={
                    dataInputError?.["Billing First Name"] ? true : false
                  }
                  invalidMessage={dataInputError?.["Billing First Name"]}
                />
              </Col>
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("Last Name") + ":"}
                  name="Billing Last Name"
                  value={formState["Billing Last Name"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Last Name")}
                  isInvalid={
                    dataInputError?.["Billing Last Name"] ? true : false
                  }
                  invalidMessage={dataInputError?.["Billing Last Name"]}
                />
              </Col>
              <Col md={6} className="text-start">
                <TextInput
                  label={"*" + t("Email") + ":"}
                  name="Billing Email"
                  value={formState["Billing Email"]}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Email")}
                  isInvalid={dataInputError?.["Billing Email"] ? true : false}
                  invalidMessage={dataInputError?.["Billing Email"]}
                />
              </Col>
            </Row>
            <div className="col-md-5 offset-md-7">
              <Col md={12}>
                <div className="d-flex justify-content-center">
                  {formSubmitLoading === false && apiError !== null && (
                    <Alert variant="danger">{apiError}</Alert>
                  )}
                </div>
              </Col>
              {formSubmitLoading === true && (
                <div className="mb-3">
                  <Loader />
                </div>
              )}
              {formSubmitLoading === false && (
                <ul className="px-0 btn-ul">
                  <li>
                    <button
                      type="button"
                      className="teal-bordered-btn"
                      onClick={() => {
                        setShowCancelModal(true);
                        setEditSelectedRow({});
                      }}
                    >
                      {t("cancel")}
                    </button>
                  </li>
                  <li>
                    <button type="submit" className="teal-filled-btn">
                      {t("save_submit")}
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </Form>
          {showCancelModal && (
            <CustomModal
              showModal={true}
              fileUpload={true}
              children={
                <NewRequestCancel
                  closeModalParent={() => setShowCancelModal(false)}
                  setEditOpen={setEditOpen}
                />
              }
            />
          )}
        </div>
      )}
    </>
  );
};

export default AddEditForm;
