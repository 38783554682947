import React from "react";
import { Row, Col } from "react-bootstrap";

const EditEnableAccountFormMenu = ({
  installatonSchedule,
  targetInstance,
  reviewAccount,
  handleEditWindowClose,
}) => {
  return (
    <Row>
      <Col md={4}>
        <button
          className={
            installatonSchedule
              ? "view-edit-box-form-title-btn-active"
              : "view-edit-box-form-title-btn"
          }
        >
          Installation Schedule
        </button>
      </Col>

      <Col md={4}>
        <button
          className={
            targetInstance
              ? "view-edit-box-form-title-btn-active padding-13"
              : "view-edit-box-form-title-btn padding-13"
          }
        >
          Target Instance
        </button>
      </Col>

      <Col md={4}>
        <button
          className={
            reviewAccount
              ? "view-edit-box-form-title-btn-active padding-13"
              : "view-edit-box-form-title-btn padding-13"
          }
        >
          Review Account
        </button>
      </Col>
    </Row>
  );
};

export default EditEnableAccountFormMenu;
