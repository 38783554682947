import React, { useMemo, useState, useCallback } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Import Form Component
import TextInput from "components/Forms/TextInput";
import CountryDropdown from "components/Forms/CountryDropdown";
import { postApiCall } from "helper/postApiCall";

// Import Common Component
import CustomModal from "components/Modal/CustomModal";

// Import Internal Component
import EditRequestCancel from "./EditRequestCancel";
import EditFormMenu from "./EditFormMenu";

// Validator
import { toolExceptionValidate } from "../../Validator/validator";

// Roles
import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";
import {
  biaRatingOption,
  exceptionCategoryOption,
  exceptionTimeLineOption,
} from "static-contents/customRoleData";
import { regionData } from "static-contents/region-data";

const EditToolsException = ({
  step,
  setStep,
  formData,
  setFormData,
  accountNumberNameData,
  accountNumberArray,
}) => {
  const { t } = useTranslation();

  // React Select Option
  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid red",
    }),
  };

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // ID Token
  const idToken = `${userInfo.idToken}`;

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Account Number State & API State
  const [loadingDropdown, setLoadingDropdown] = useState(true);
  const [accountNumberData, setAccountNumberData] = useState("");
  const [accountNameData, setAccountNameData] = useState("");
  const [getAccountsApiError, setGetAccountsApiError] = useState(null);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // handle onchange
  const handleData = (e, target, accountName = null) => {
    debugger;
    if (target?.name === "accountNumber") {
      setFormData({
        type: "TOOLS_EXCEPTION",
        payload: {
          ["accountNumber"]: e?.value,
          ["accountName"]: accountName ? accountName : "",
        },
      });
    } else if (
      target?.name === "exceptionTimeline" ||
      target?.name === "BIARating" ||
      target?.name === "exceptionCategory"
    ) {
      setFormData({
        type: "TOOLS_EXCEPTION",
        payload: { [target.name]: e?.value },
      });
    } else {
      setFormData({
        type: "TOOLS_EXCEPTION",
        payload: { [e.target.name]: e.target.value },
      });
    }
  };

  const handleContinue = (e) => {
    e.preventDefault();
    console.log(formData);
    const errors = toolExceptionValidate(formData.toolsExceptionData);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
    }
  };

  return (
    <>
      {/* <Row>
        <Col md={12} xs={12}>
          <EditFormMenu
            accountContact
            setStep={setStep}
            step={step}
            formData={formData}
            setDataInputError={setDataInputError}
          />
        </Col>
      </Row> */}
      <Form onSubmit={handleContinue}>
        <Row className="px-3 mt-3">
          <Col md={12}>
            <Form.Group className="mb-3" controlId="selectAccountNo">
              <Form.Label>{t("Select Account For Exception")}</Form.Label>
              <Select
                name="accountNumber"
                placeholder="Account Number"
                searchable={true}
                value={{ label: formData.toolsExceptionData.accountNumber }}
                isClearable={true}
                onChange={(e, value) => {
                  if (e !== null) {
                    const accNum = e?.value;

                    for (let i = 0; i < accountNumberNameData.length; i++) {
                      let accNumName = accountNumberNameData[i];
                      let splited = accNumName.split(" - ")[0];
                      if (splited === accNum) {
                        let splitedAccName = accNumName.split(" - ")[1];
                        setAccountNameData(splitedAccName);
                        handleData(e, value, splitedAccName);
                      }
                    }
                  } else {
                    setAccountNameData("");
                    handleData(e, value);
                  }
                }}
                options={accountNumberArray}
                styles={dataInputError.accountNumber && customStyles}
              />

              <p className="custom-error-message">
                {dataInputError.accountNumber}
              </p>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="selectBIARating">
              <Form.Label>{t("BIA Rating")}</Form.Label>
              <Select
                name="BIARating"
                placeholder="BIA Rating"
                searchable={true}
                value={{ label: formData.toolsExceptionData.BIARating }}
                isClearable={true}
                onChange={(e, value) => {
                  handleData(e, value);
                }}
                options={biaRatingOption}
                styles={dataInputError.BIARating && customStyles}
              />

              <p className="custom-error-message">{dataInputError.BIARating}</p>
            </Form.Group>
          </Col>
          <Col md={12}>
            <TextInput
              label="*Account Name:"
              name="accountName"
              value={formData.toolsExceptionData.accountName}
              isReadOnly={true}
              onChange={(e) => handleData(e)}
              placeholder="Account Name"
              isInvalid={dataInputError.accountName ? true : false}
              invalidMessage={dataInputError.accountName}
            />
          </Col>
          <Col md={12}>
            <TextInput
              label="*Project Name:"
              name="projectName"
              value={formData.toolsExceptionData.projectName}
              onChange={(e) => handleData(e)}
              placeholder="Project Name"
              isInvalid={dataInputError.projectName ? true : false}
              invalidMessage={dataInputError.projectName}
            />
          </Col>
          <Col md={12}>
            <TextInput
              label="*Account Owner Name:"
              name="accountOwnerName"
              value={formData.toolsExceptionData.accountOwnerName}
              onChange={(e) => handleData(e)}
              placeholder="Phone Number"
              isInvalid={dataInputError.accountOwnerName ? true : false}
              invalidMessage={dataInputError.accountOwnerName}
            />
          </Col>
          <Col md={12}>
            <TextInput
              label="*Exception Detail:"
              name="exceptionDetail"
              value={formData.toolsExceptionData.exceptionDetail}
              onChange={(e) => handleData(e)}
              placeholder="Exception Detail"
              isInvalid={dataInputError.exceptionDetail ? true : false}
              invalidMessage={dataInputError.exceptionDetail}
            />
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="selectExceptionTimeline">
              <Form.Label>{t("Exception Timeline")}</Form.Label>
              <Select
                name="exceptionTimeline"
                placeholder="Exception Timeline"
                searchable={true}
                value={{ label: formData.toolsExceptionData.exceptionTimeline }}
                isClearable={true}
                onChange={(e, value) => {
                  handleData(e, value);
                }}
                options={exceptionTimeLineOption}
                styles={dataInputError.exceptionTimeline && customStyles}
              />

              <p className="custom-error-message">
                {dataInputError.exceptionTimeline}
              </p>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="selectExceptionTimeline">
              <Form.Label>{t("Exception Category")}</Form.Label>
              <Select
                name="exceptionCategory"
                placeholder="Exception Category"
                searchable={true}
                value={{ label: formData.toolsExceptionData.exceptionCategory }}
                isClearable={true}
                onChange={(e, value) => {
                  handleData(e, value);
                }}
                options={exceptionCategoryOption}
                styles={dataInputError.exceptionCategory && customStyles}
              />

              <p className="custom-error-message">
                {dataInputError.exceptionCategory}
              </p>
            </Form.Group>
          </Col>
          <Col md={12} className="mt-2 ">
            <Form.Group className="mb-3" controlId="selectExceptionTimeline">
              <Form.Label>{t("Region")}</Form.Label>
              <Select
                name="region"
                placeholder="Select Region"
                searchable={true}
                value={{ label: formData.toolsExceptionData?.region }}
                isClearable={true}
                onChange={(e, value) => {
                  handleData(e, value);
                }}
                options={regionData}
                styles={dataInputError.region && customStyles}
              />

              <p className="custom-error-message">{dataInputError.region}</p>
            </Form.Group>
          </Col>
        </Row>
        <Row className="px-3">
          <div className="d-flex justify-content-end">
            <ul className="px-0 btn-ul">
              <li>
                <button
                  type="button"
                  className="teal-bordered-btn"
                  onClick={() => {
                    setShowCancelModal(true);
                  }}
                >
                  Cancel
                </button>
              </li>
              <li>
                <button type="submit" className="teal-filled-btn">
                  Save & Submit
                </button>
              </li>
            </ul>
          </div>
        </Row>
      </Form>
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<EditRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

export default EditToolsException;
