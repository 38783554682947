export const vpcRegionData = [
    { label: 'US East (N. Virginia) - us-east-1', value: 'us-east-1' },
    {
        label: 'Asia Pacific (Singapore) - ap-southeast-1',
        value: 'ap-southeast-1',
      },
      { label: 'Europe (Ireland) - eu-west-1', value: 'eu-west-1' },
      { label: 'Asia Pacific (Tokyo) - ap-northeast-1', value: 'ap-northeast-1' },
      { label: 'US West (Oregon) - us-west-2', value: 'us-west-2' }
]
