import React from 'react';

// Import Internal Component
import BulkEditForm from './BulkEditForm';

// Import Icons
import backicon from 'icons/back.svg';

const BulkAccountEdit = ({ data, cloudTitle, closeEditParent, availableData, selectedData }) => {
  return (
    <div className='view-edit-box-conatiner'>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => closeEditParent(false)}
          />
          <h5 className='view-edit-box-title'>{cloudTitle} Account Details</h5>
          {/* {data.requestStatus === 'Approved' && (
            <span className='view-edit-box-accountid mx-3'>
              Account ID: {data.accountnumber}
            </span>
          )} */}
        </div>
      </div>
      <div className='table-edit-box-content px-1 mt-4 mb-5'>
        <BulkEditForm data={data} availableData={availableData} selectedData={selectedData} />
      </div>
    </div>
  );
};

export default BulkAccountEdit;
