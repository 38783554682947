import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ACCOUNT_BACKUP_RELOAD } from "redux/account-requests/accountRequestConstant";

const NewRequestSubmit = ({ refUid, update = null }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    if (update) {
      dispatch({
        type: ACCOUNT_BACKUP_RELOAD,
        payload: true,
      });
    }
  };
  return (
    <div className="d-flex justify-content-center flex-column">
      <p>
        Your backup request was {update === "update" ? "submitted" : "updated"}{" "}
        successfully
      </p>
      <p>Check your status using this ref#</p>
      <p>{refUid}</p>
      <p>
        <button
          type="button"
          className="teal-filled-btn"
          onClick={() => {
            handleClick();
          }}
        >
          <Link to="/aws-backup-requests">Ok</Link>
        </button>
      </p>
    </div>
  );
};

export default NewRequestSubmit;
