import {
  CLOUD_EYE_USER_TABLE,
  PRISMA,
  TREND,
  CLOUD_EYE_APPLICATION_NAME,
} from './cloueyeUserConstant';

export const cloudEyeReducer = (
  state = {
    cloudEyeUserTable: '',
    cloudEyeApplicationName: '',
  },
  action
) => {
  switch (action.type) {
    case CLOUD_EYE_USER_TABLE:
      return { ...state, cloudEyeUserTable: action.payload };

    case PRISMA:
      return { ...state, prismaCanvas: action.payload };

    case TREND:
      return { ...state, trendCanvas: action.payload };

    case CLOUD_EYE_APPLICATION_NAME:
      return { ...state, cloudEyeApplicationName: action.payload };

    default:
      return state;
  }
};
