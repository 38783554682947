const cloudServiceData = [
  {
    service: 'Athena',
    type: 'AWS',
  },
  {
    service: 'Cloudsearch',
    type: 'AWS',
  },
  {
    service: 'ElasticSearch',
    type: 'AWS',
  },
  {
    service: 'EMR',
    type: 'AWS',
  },
  {
    service: 'Kenesis',
    type: 'AWS',
  },
  {
    service: 'Redshift',
    type: 'AWS',
  },
  {
    service: 'QuickSight',
    type: 'AWS',
  },
  {
    service: 'DataExchange',
    type: 'AWS',
  },
  {
    service: 'DataPipeline',
    type: 'AWS',
  },
  {
    service: 'Glue',
    type: 'AWS',
  },
  {
    service: 'AWSLakeFormation',
    type: 'AWS',
  },
  {
    service: 'AWSStepFunctions',
    type: 'AWS',
  },
  {
    service: 'AppFlow',
    type: 'AWS',
  },
  {
    service: 'EventBridge',
    type: 'AWS',
  },
  {
    service: 'MQ',
    type: 'AWS',
  },
  {
    service: 'SNS',
    type: 'AWS',
  },
  {
    service: 'SQS',
    type: 'AWS',
  },
  {
    service: 'AppSync',
    type: 'AWS',
  },
  {
    service: 'AWS CostExplorer',
    type: 'AWS',
  },
  {
    service: 'AWS Budgets',
    type: 'AWS',
  },
  {
    service: 'AWS CostandUsageReport',
    type: 'AWS',
  },
  {
    service: 'ManagedBlockchain',
    type: 'AWS',
  },
  {
    service: 'QuantumLedgerDB(QLDB)',
    type: 'AWS',
  },
  {
    service: 'EC2',
    type: 'AWS',
  },
  {
    service: 'LightSail',
    type: 'AWS',
  },
  {
    service: 'ElasticBeanstalk',
    type: 'AWS',
  },
  {
    service: 'Lambda',
    type: 'AWS',
  },
  {
    service: 'ECR',
    type: 'AWS',
  },
  {
    service: 'ECS',
    type: 'AWS',
  },
  {
    service: 'EKS',
    type: 'AWS',
  },
  {
    service: 'AWS Copilot',
    type: 'AWS',
  },
  {
    service: 'AWS Fargate',
    type: 'AWS',
  },
  {
    service: 'Aurora',
    type: 'AWS',
  },
  {
    service: 'DynamoDB',
    type: 'AWS',
  },
  {
    service: 'Elasticache',
    type: 'AWS',
  },
  {
    service: 'Neptune',
    type: 'AWS',
  },
  {
    service: 'RDS',
    type: 'AWS',
  },
  {
    service: 'Timestream',
    type: 'AWS',
  },
  {
    service: 'AWSVPC',
    type: 'AWS',
  },
  {
    service: 'APIGateway',
    type: 'AWS',
  },
  {
    service: 'CloudFront',
    type: 'AWS',
  },
  {
    service: 'Route53',
    type: 'AWS',
  },
  {
    service: 'AWSPrivateLink',
    type: 'AWS',
  },
  {
    service: 'AWS AppMesh',
    type: 'AWS',
  },
  {
    service: 'AWS CloudMap',
    type: 'AWS',
  },
  {
    service: 'AWS DirectConnect',
    type: 'AWS',
  },
  {
    service: 'GlobalAccellerator',
    type: 'AWS',
  },
  {
    service: 'AWS TransitGateway',
    type: 'AWS',
  },
  {
    service: 'ElasticLoadBalancing',
    type: 'AWS',
  },
  {
    service: 'S3',
    type: 'AWS',
  },
  {
    service: 'EBS',
    type: 'AWS',
  },
  {
    service: 'EFS',
    type: 'AWS',
  },
  {
    service: 'S3Glacier',
    type: 'AWS',
  },
  {
    service: 'AWS Backup',
    type: 'AWS',
  },
  {
    service: 'AWS Snow',
    type: 'AWS',
  },
  {
    service: 'AWS StorageGateway',
    type: 'AWS',
  },
  {
    service: 'CloudEndure',
    type: 'AWS',
  },
  {
    service: 'Anomaly Detector',
    type: 'Azure',
  },
  {
    service: 'Azure Cognitive Search',
    type: 'Azure',
  },
  {
    service: 'Azure Machine Learning',
    type: 'Azure',
  },
  {
    service: 'Azure Cognitive Services',
    type: 'Azure',
  },
  {
    service: 'Computer Vision',
    type: 'Azure',
  },
  {
    service: 'Custom Vision',
    type: 'Azure',
  },
  {
    service: 'Face API',
    type: 'Azure',
  },
  {
    service: 'Azure Immersive Reader',
    type: 'Azure',
  },
  {
    service: 'Conversational language understanding',
    type: 'Azure',
  },
  {
    service: 'Personalizer',
    type: 'Azure',
  },
  {
    service: 'QnA Maker',
    type: 'Azure',
  },
  {
    service: 'Speech to text',
    type: 'Azure',
  },
  {
    service: 'Translator',
    type: 'Azure',
  },
  {
    service: 'Azure Applied AI Services',
    type: 'Azure',
  },
  {
    service: 'Azure Analysis Services',
    type: 'Azure',
  },
  {
    service: 'Azure Data Lake Storage',
    type: 'Azure',
  },
  {
    service: 'Azure Data Share',
    type: 'Azure',
  },
  {
    service: 'Data Catalog',
    type: 'Azure',
  },
  {
    service: 'Data Lake Analytics',
    type: 'Azure',
  },
  {
    service: 'R Server for HDInsight',
    type: 'Azure',
  },
  {
    service: 'Azure Databricks',
    type: 'Azure',
  },
  {
    service: 'Azure Synapse Analytics',
    type: 'Azure',
  },
  {
    service: 'Data Factory',
    type: 'Azure',
  },
  {
    service: 'Power BI Embedded',
    type: 'Azure',
  },
  {
    service: 'App Service',
    type: 'Azure',
  },
  {
    service: 'Azure Quantum',
    type: 'Azure',
  },
  {
    service: 'Azure VMware Solution',
    type: 'Azure',
  },
  {
    service: 'Azure CycleCloud',
    type: 'Azure',
  },
  {
    service: 'Azure Spot Virtual Machines',
    type: 'Azure',
  },
  {
    service: 'Azure Virtual Desktop',
    type: 'Azure',
  },
  {
    service: 'Virtual Machines',
    type: 'Azure',
  },
  {
    service: 'Linux Virtual Machines',
    type: 'Azure',
  },
  {
    service: 'Static Web Apps',
    type: 'Azure',
  },
  {
    service: 'Azure Functions',
    type: 'Azure',
  },
  {
    service: 'Azure Container Instances',
    type: 'Azure',
  },
  {
    service: 'Azure Service Fabric',
    type: 'Azure',
  },
  {
    service: 'Web App for Containers',
    type: 'Azure',
  },
  {
    service: 'Azure Kubernetes Service (AKS)',
    type: 'Azure',
  },
  {
    service: 'Azure Red Hat OpenShift',
    type: 'Azure',
  },
  {
    service: 'Azure Container Registry',
    type: 'Azure',
  },
  {
    service: 'Azure Cache for Redis',
    type: 'Azure',
  },
  {
    service: 'Azure SQL',
    type: 'Azure',
  },
  {
    service: 'Table Storage',
    type: 'Azure',
  },
  {
    service: 'Azure SQL Database',
    type: 'Azure',
  },
  {
    service: 'Azure Database for MariaDB',
    type: 'Azure',
  },
  {
    service: 'Azure Database for PostgreSQL',
    type: 'Azure',
  },
  {
    service: 'Visual Studio',
    type: 'Azure',
  },
  {
    service: 'SDKs',
    type: 'Azure',
  },
  {
    service: 'Visual Studio Code',
    type: 'Azure',
  },
  {
    service: 'App Configuration',
    type: 'Azure',
  },
  {
    service: 'Azure Pipelines',
    type: 'Azure',
  },
  {
    service: 'Azure Monitor',
    type: 'Azure',
  },
  {
    service: 'Azure DevTest Labs',
    type: 'Azure',
  },
  {
    service: 'Azure Load Testing',
    type: 'Azure',
  },
  {
    service: 'DevOps tool integrations',
    type: 'Azure',
  },
  {
    service: 'Azure Maps',
    type: 'Azure',
  },
  {
    service: 'Azure Disk Storage',
    type: 'Azure',
  },
  {
    service: 'Application Gateway',
    type: 'Azure',
  },
  {
    service: 'VPN Gateway',
    type: 'Azure',
  },
  {
    service: 'Load Balancer',
    type: 'Azure',
  },
  {
    service: 'Azure Firewall',
    type: 'Azure',
  },
  {
    service: 'Azure DNS',
    type: 'Azure',
  },
  {
    service: 'Kinect DK',
    type: 'Azure',
  },
];

export { cloudServiceData };
