import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from 'components/Common/Loader';
import AlertMessage from 'components/Common/AlertMessage';

import { postApiCall } from 'helper/postApiCall';

const Decline = ({ yesParent, closeModalParent, uid }) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successDeclined, setSuccessDeclined] = useState(false);

  async function declineRequest() {
    setLoading(true);
    const idToken = userInfo.idToken;
    const requestBody = `{}`;
    const apiUrl = `${process.env.REACT_APP_CUSTOM_ROLE_REQUEST}`;
    const setApiUrl = `${apiUrl}?uid=${uid}&type=0&adminemail=${userInfo.email}`;

    await postApiCall(setApiUrl, idToken, requestBody)
      .then((response) => {
        setSuccessDeclined(true);
      })
      .catch((err) => {
        setError(err);
        setSuccessDeclined(false);
      });
  }

  return (
    <div className='d-flex justify-content-center flex-column'>
      {successDeclined === false && (
        <>
          <p>Are you sure you want to decline this request</p>
          {loading && <Loader />}
          {loading === false && error === null && (
            <ul className='btn-ul'>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    closeModalParent(null);
                  }}
                >
                  No
                </button>
              </li>
              <li>
                <button
                  type='button'
                  className='teal-filled-btn'
                  onClick={() => {
                    declineRequest();
                  }}
                >
                  Yes
                </button>
              </li>
            </ul>
          )}
        </>
      )}

      {successDeclined === true && (
        <>
          {error !== null && (
            <div className='d-flex justify-content-center flex-column'>
              <AlertMessage
                cssClassName='alert-message-danger'
                alertMessage={error}
              />
            </div>
          )}
          {error === null && (
            <>
              <p>Request Declined!</p>
              <p>
                <button
                  type='button'
                  className='teal-filled-btn'
                  onClick={() => {
                    yesParent(null);
                  }}
                >
                  Ok
                </button>
              </p>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Decline;
