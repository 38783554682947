import MonthPicker from "components/MonthPicker/MonthPickerInput";
import moment from "moment";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const InvoiceDownloadModal = ({
  setDownloadModalOpen = () => {},
  setYearDropDown = () => {},
  handleChange = () => {},
  isMonthChecked,
  handleGenerateInvoice = () => {},
  isYearDropDown,
  setStep = () => {},
  dateObject,
  isYearChecked,
  step,
  getSelectedMonth = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="w-100 invoice-download d-flex flex-column justify-content-between py-4 position-relative">
        <div className="position-relative ">
          <div>
            <div className="text-center">
              {/* Generate invoice for */}
              Download invoices for
            </div>
            <div className="d-flex justify-content-center">
              <>
                {step === 0 && (
                  <>
                    <Row className="m-4">
                      <Col
                        md={6}
                        sm={6}
                        className="p-1 pe-0 year-selection-radio d-flex"
                        onClick={() => handleChange("Y", 0)}
                      >
                        <Form.Check
                          type="radio"
                          label={`${dateObject?.getFullYear() - 1}`}
                          id={`generate-current-year`}
                          checked={isYearChecked === 0}
                          value={0}
                          className="cursor-pointer-selection text-center ps-0"
                        />
                      </Col>

                      <Col
                        md={6}
                        className="p-1 padding-left-30 year-selection-radio d-flex"
                        onClick={() => handleChange("Y", 1)}
                      >
                        <Form.Check
                          type="radio"
                          label={`${dateObject?.getFullYear()}`}
                          id={`generate-previous-year`}
                          checked={isYearChecked === 1}
                          value={1}
                          className="cursor-pointer-selection text-center ps-0"
                        />
                      </Col>
                    </Row>
                  </>
                )}
                {step === 1 && (
                  <MonthPicker
                    isYearChecked={isYearChecked}
                    getSelectedMonth={getSelectedMonth}
                  />
                )}
              </>
            </div>
            <ul className="px-0 btn-ul mb-0 justify-content-center">
              {step === 1 && (
                <li>
                  <button
                    type="button"
                    className="teal-bordered-btn"
                    onClick={() => {
                      setStep(0);
                    }}
                  >
                    {t("back")}
                  </button>
                </li>
              )}
              <li>
                <button
                  type="button"
                  className="teal-bordered-btn"
                  onClick={() => {
                    setYearDropDown(false);
                    setStep(0);
                    setDownloadModalOpen(false);
                  }}
                >
                  {t("cancel")}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={(e) => {
                    if (step === 1) {
                      handleGenerateInvoice(null, "download");
                      setStep(0);
                      setYearDropDown(false);
                      setDownloadModalOpen(false);
                    } else if (step === 0) {
                      setStep(1);
                    }
                  }}
                  className="teal-filled-btn"
                >
                  {step === 0 ? t("Continue") : t("Submit")}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDownloadModal;
