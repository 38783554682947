import React, { useMemo, useState, useCallback } from "react";
import { Row, Col, Form, Alert } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Import Form Component
import TextInput from "components/Forms/TextInput";
import CountryDropdown from "components/Forms/CountryDropdown";
import { postApiCall } from "helper/postApiCall";

// Import Common Component
import CustomModal from "components/Modal/CustomModal";
import Loader from "components/Common/Loader";

// Import Internal Component
import EditRequestCancel from "./EditRequestCancel";

// Validator
import { awsBackUpValidate } from "../../../Validator/validator";

// Roles
import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";
import {
  biaRatingOption,
  exceptionCategoryOption,
  exceptionTimeLineOption,
} from "static-contents/customRoleData";
import {
  awsBackupRegionData,
  backUpScheduleData,
  optionalDropDownData,
} from "static-contents/region-data";
import NewRequestSubmit from "../../NewRequest/NewRequestSubmit";
import CrossBackUpTextComponent from "../../CrossBackUpTextComponent";
import DrBackUpComponent from "../../DrBackUpComponent";
import BackUpPlanComponent from "../../BackUpPlanComponent";
import WarningMessage from "../../WarningMessage";

const EditBackUpRequest = ({
  step,
  setStep,
  formData,
  setFormData,
  accountNumberNameData,
  accountNumberArray,
}) => {
  const { t } = useTranslation();

  // React Select Option
  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid red",
    }),
  };

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // ID Token
  const idToken = `${userInfo.idToken}`;

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Account Number State & API State
  const [loading, setLoading] = useState(false);
  const [accountNumberData, setAccountNumberData] = useState("");
  const [accountNameData, setAccountNameData] = useState("");
  const [getAccountsApiError, setGetAccountsApiError] = useState(null);

  const [apiError, setApiError] = useState(null);
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [newRequestUid, setNewRequestUid] = useState(null);

  const [warningMessage, setWarningMessage] = useState(null);
  const [isDisclaimerAgreed, setIsDisclaimerAgreed] = useState(false);
  const [isCrossBkupAgreed, setCrossBkupAgreed] = useState(false);
  const [isDrBkupAgreed, setDrBkupAgreed] = useState(false);
  const [isBkupPlanAgreed, setBkupPlanAgreed] = useState(false);
  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // handle onchange
  const handleData = (e, val = null, accountName = null) => {
    if (val === "accountNumber" && e) {
      setFormData({
        type: "UPDATE_BACKUP_REQUEST",
        payload: {
          ["accountNumber"]: e.value,
          ["accountName"]: accountName,
        },
      });
    } else if (!e) {
      setFormData({
        type: "UPDATE_BACKUP_REQUEST",
        payload: {
          ["accountNumber"]: "",
          ["accountName"]: "",
        },
      });
    } else {
      setFormData({
        type: "UPDATE_BACKUP_REQUEST",
        payload: { [e.target.name]: e.target.value },
      });
    }
  };

  const handleContinue = (e) => {
    e.preventDefault();
    const formDataObj = formData.accountDetails;
    const errors = awsBackUpValidate(formDataObj);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setFormSubmitLoading(true);
      // Form Submission
      const formSubmitApiUrl = `${process.env.REACT_APP_AWS_BACKUP_ADD}`;
      formDataObj.crossBackUp =
        formDataObj.crossBackUp === "yes" ? "true" : "false";
      formDataObj.drBackUp = formDataObj.drBackUp === "yes" ? "true" : "false";
      const requestBody = {
        ...formDataObj,
        requestStatus: "Pending",
        uid: `${formData.uid}`,
        submittedBy: userInfo.email,
        type: "edit",
      };
      postApiCall(formSubmitApiUrl, idToken, requestBody)
        .then((response) => {
          if (response.data.message === "Request updated successfully") {
            setNewRequestUid(`${formData.uid}`);
            setFormSubmitSuccess(true);
            setFormSubmitLoading(false);
          }
        })
        .catch((err) => {
          setFormSubmitLoading(false);
          setApiError(t("failed_to_retrieve_data"));
        });
    }
  };

  return (
    <>
      {/* <Row>
        <Col md={12} xs={12}>
          <EditFormMenu
            accountContact
            setStep={setStep}
            step={step}
            formData={formData}
            setDataInputError={setDataInputError}
          />
        </Col>
      </Row> */}
      <Form onSubmit={handleContinue}>
        {(loading || getAccountsApiError) && <Loader />}
        {loading === false && getAccountsApiError === null && (
          <>
            <Row className="px-3 mt-3">
              <Col md={12}>
                <Form.Group className="mb-3" controlId="selectAccountNo">
                  <Form.Label>{t("Account Number")}</Form.Label>
                  <Select
                    name="accountNumber"
                    placeholder="Account Number"
                    searchable={true}
                    value={{ label: formData.accountDetails.accountNumber }}
                    isClearable={true}
                    onChange={(e, value) => {
                      if (e !== null) {
                        const accNum = e?.value;

                        for (let i = 0; i < accountNumberNameData.length; i++) {
                          let accNumName = accountNumberNameData[i];
                          let splited = accNumName.split(" - ")[0];
                          if (splited === accNum) {
                            let splitedAccName = accNumName.split(" - ")[1];
                            setAccountNameData(splitedAccName);
                            handleData(e, "accountNumber", splitedAccName);
                          }
                        }
                      } else {
                        setAccountNameData("");
                        handleData(e, value);
                      }
                    }}
                    options={accountNumberArray}
                    styles={dataInputError.accountNumber && customStyles}
                  />

                  <p className="custom-error-message">
                    {dataInputError.accountNumber}
                  </p>
                </Form.Group>
              </Col>

              <Col md={12}>
                <TextInput
                  label="*Account Name:"
                  name="accountName"
                  value={formData.accountDetails.accountName}
                  isReadOnly={true}
                  onChange={(e) => handleData(e)}
                  placeholder="Account Name"
                  isInvalid={dataInputError.accountName ? true : false}
                  invalidMessage={dataInputError.accountName}
                />
              </Col>
              <Col md={12} className="mt-2 ">
                <CountryDropdown
                  label={"*Region"}
                  name="region"
                  placeholder="Select Region"
                  value={formData?.accountDetails?.region}
                  onChange={(e) => handleData(e)}
                  selectValues={awsBackupRegionData}
                  isInvalid={dataInputError?.region ? true : false}
                  invalidMessage={dataInputError?.region}
                />
              </Col>
              <Col md={12} xs={12} className="px-3 pt-3">
                <h5 className="page-content-title mb-3">
                  {t("Backup vaults and plans")}
                </h5>
              </Col>
              <p className="custom-no-data-message-text">
                Note :
                <strong>
                  {`Vault in the active region is enabled by default`}
                </strong>
              </p>
              <Col md={12} className="mt-2 ">
                <CountryDropdown
                  label={"Would you like to have a cross region backup"}
                  name="drBackUp"
                  placeholder="Select"
                  value={formData?.accountDetails?.drBackUp}
                  onChange={(e) => handleData(e)}
                  selectValues={optionalDropDownData}
                  onClick={() => {
                    if (!isDrBkupAgreed) {
                      setWarningMessage("drBackUp");
                      setIsDisclaimerAgreed(false);
                    }
                  }}
                />
              </Col>
              <Col md={12} className="mt-2 ">
                <CountryDropdown
                  label={"Would you like to have a cross account backup"}
                  name="crossBackUp"
                  placeholder="Select"
                  value={formData?.accountDetails?.crossBackUp}
                  onChange={(e) => handleData(e)}
                  selectValues={optionalDropDownData}
                  onClick={() => {
                    if (!isCrossBkupAgreed) {
                      setWarningMessage("crossBackUp");
                      setIsDisclaimerAgreed(false);
                    }
                  }}
                />
              </Col>
              <Col md={12} className="mt-2 ">
                <CountryDropdown
                  label={"*BackUp Plan"}
                  name="backUpPlan"
                  placeholder="Select"
                  value={formData?.accountDetails?.backUpPlan}
                  onChange={(e) => handleData(e)}
                  selectValues={backUpScheduleData}
                  isInvalid={dataInputError?.backUpPlan ? true : false}
                  invalidMessage={dataInputError?.backUpPlan}
                  onClick={() => {
                    if (!isBkupPlanAgreed) {
                      setWarningMessage("backUpPlan");
                      setIsDisclaimerAgreed(false);
                    }
                  }}
                />
              </Col>
            </Row>
            <Row className="px-3">
              <Col md={12}>
                <div className="d-flex justify-content-center">
                  {formSubmitLoading === false && apiError !== null && (
                    <Alert variant="danger">{apiError}</Alert>
                  )}
                </div>
              </Col>
              <div className="col-md-5 offset-md-7">
                {formSubmitLoading === true && (
                  <div className="mb-3">
                    <Loader />
                  </div>
                )}
                {formSubmitLoading === false && (
                  <ul className="px-0 btn-ul">
                    <li>
                      <button
                        type="button"
                        className="teal-bordered-btn"
                        onClick={() => {
                          setShowCancelModal(true);
                        }}
                      >
                        {t("cancel")}
                      </button>
                    </li>
                    <li>
                      <button type="submit" className="teal-filled-btn">
                        {t("submit")}
                      </button>
                    </li>
                  </ul>
                )}
              </div>
            </Row>
          </>
        )}
      </Form>
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<EditRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
      {formSubmitSuccess && (
        <CustomModal
          showModal={true}
          children={
            <NewRequestSubmit refUid={newRequestUid} update={"update"} />
          }
        />
      )}
      {warningMessage && (
        <CustomModal
          showModal={true}
          children={
            <WarningMessage
              children={
                warningMessage === "crossBackUp" ? (
                  <CrossBackUpTextComponent />
                ) : warningMessage === "drBackUp" ? (
                  <DrBackUpComponent />
                ) : (
                  warningMessage === "backUpPlan" && <BackUpPlanComponent />
                )
              }
              setAgreedPlan={
                warningMessage === "crossBackUp"
                  ? setCrossBkupAgreed
                  : warningMessage === "drBackUp"
                  ? setDrBkupAgreed
                  : warningMessage === "backUpPlan" && setBkupPlanAgreed
              }
              handleClose={setWarningMessage}
              setIsDisclaimerAgreed={setIsDisclaimerAgreed}
              isDisclaimerAgreed={isDisclaimerAgreed}
              setFormData={setFormData}
              fieldName={warningMessage}
              edit={true}
            />
          }
        />
      )}
    </>
  );
};

export default EditBackUpRequest;
