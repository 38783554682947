import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Moment from "moment";

// Import Component
import PrismaCanvas from "../../components/Canvas/PrismaCanvas";
import PrismaDownload from "./PrismaDownload";

import SpinnerLoader from "components/Common/SpinnerLoader";

// Import Constant
import {
  PRISMA,
  REMEDY_TICKET,
  TREND,
  MORIARTY,
  SERVICE,
} from "../../redux/dashboard/dashboardConstant";

import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";


const Prisma = () => {
  const [primsaCanvasTitle, setPrismaCanvasTitle] = useState(null);
  const [selectedLegend, setSelectedLegend] = useState(null);
  const [selectedCloudType, setSelectedCloudType] = useState(null);

  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const dashboardChart = useSelector((state) => state.dashboardChart);
  const { prismaCanvas } = dashboardChart;

  var awsDataSet;
  if (
    responseData.length > 0 &&
    responseData.filter((e) => e.type === "aws").length > 0
  ) {
    var getAWSData = responseData.find((el) => el.type === "aws");
    awsDataSet = [
      {
        name: "Extreme",
        value: getAWSData.EXTREME,
        color: "#ff3f3f",
        type: "aws",
      },
      {
        name: "Very High",
        value: getAWSData.VERY_HIGH,
        color: "#fd7b43",
        type: "aws",
      },
      {
        name: "High",
        value: getAWSData.HIGH,
        color: "#ffe181",
        type: "aws",
      },
    ];
  } else {
    awsDataSet = [];
  }

  var azureDataSet;
  if (
    responseData.length > 0 &&
    responseData.filter((e) => e.type === "azure").length > 0
  ) {
    var getAzureData = responseData.find((el) => el.type === "azure");
    azureDataSet = [
      {
        name: "Extreme",
        value: getAzureData.EXTREME,
        color: "#ff3f3f",
        type: "azure",
      },
      {
        name: "Very High",
        value: getAzureData.VERY_HIGH,
        color: "#fd7b43",
        type: "azure",
      },
      {
        name: "High",
        value: getAzureData.HIGH,
        color: "#ffe181",
        type: "azure",
      },
    ];
  } else {
    azureDataSet = [];
  }

  var awsOption = {
    maintainAspectRatio: false,
    tooltip: {
      show: true,
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: "pie",
        radius: ["40px", "30px"],
        data: awsDataSet.map(({ name, value, color, borderRadius, type }) => ({
          name,
          value,
          itemStyle: { color: color, borderRadius: borderRadius },
          type,
        })),
        label: {
          formatter: "{c}",
          position: "outer",
          alignTo: "labelLine",
          bleedMargin: 0,
        },
        labelLine: {
          length: 20,
          length2: 0,
          maxSurfaceAngle: 100,
        },
      },
    ],
  };

  var azureOption = {
    maintainAspectRatio: false,
    tooltip: {
      show: true,
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: "pie",
        radius: ["40px", "30px"],
        data: azureDataSet.map(({ name, value, type, color }) => ({
          name,
          value,
          type,
          itemStyle: { color: color },
        })),
        label: {
          formatter: "{c}",
          position: "outer",
          alignTo: "labelLine",
          bleedMargin: 0,
        },
        labelLine: {
          length: 20,
          length2: 0,
          maxSurfaceAngle: 100,
        },
      },
    ],
  };

  function onChartClick(param, echarts) {
    // console.log(param.data);

    setPrismaCanvasTitle("Alert Details");
    setSelectedLegend(param.data.name);
    setSelectedCloudType(param.data.type);
    dispatch({
      type: PRISMA,
      payload: true,
    });
    dispatch({
      type: TREND,
      payload: false,
    });
    dispatch({
      type: REMEDY_TICKET,
      payload: false,
    });
    dispatch({
      type: SERVICE,
      payload: false,
    });
    dispatch({
      type: MORIARTY,
      payload: false,
    });
  }

  useEffect(() => {
    const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

    if (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: setIdToken,
        },
      };

      axios
        .post(`${process.env.REACT_APP_PRISMA_ALERT_COUNT}`, {}, config)
        .then((response) => {
          setResponseData(response.data);
          setLoading(false);
        });
    }

    return () => {
      setResponseData([]);
    };
  }, []);

  return (
    <div className="custom-card">
      <Row>
        <Col className="d-flex justify-content-between align-items-center">
          <div>
            <h5 className="dashboard-title pb-0 mb-0">TrendMicro Conformity</h5>
            <a
              href="https://cloudone.trendmicro.com/conformity/"
              className="splunk-link mx-0"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here for more details...
            </a>
          </div>
          <PrismaDownload userInfo={userInfo} />
        </Col>
      </Row>
      {loading && (
        <Row>
          <Col className="d-flex justify-content-center align-items-center mt-3">
            <SpinnerLoader />
          </Col>
        </Row>
      )}
      {loading === false && (
        <>
          <Row>
            <Col md={6} xs={6}>
              <ReactECharts
                option={awsOption}
                onEvents={{
                  click: onChartClick,
                }}
                className="aws-chart-logo-center"
                style={{ height: "200px", width: "100%" }}
              />
            </Col>

            <Col md={6} xs={6}>
              <ReactECharts
                option={azureOption}
                onEvents={{
                  click: onChartClick,
                }}
                className="azure-chart-logo-center"
                style={{ height: "200px", width: "100%" }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ul className="legend-list-wrapper px-0 mb-0">
                <li className="legend-list">
                  <div className="legend-list-red"></div>
                  Extreme
                </li>
                <li className="legend-list">
                  <div className="legend-list-orange"></div>
                  Very High
                </li>
                <li className="legend-list">
                  <div className="legend-list-yellow"></div>
                  High
                </li>
              </ul>
              <small className="prisma-note">
                Note: Data as per last 7 days, from (
                {Moment().subtract(7, "d").format("YYYY-MM-DD")} to{" "}
                {Moment().format("YYYY-MM-DD")})
              </small>
            </Col>
          </Row>
        </>
      )}

      {prismaCanvas === true && (
        <PrismaCanvas
          userInfo={userInfo}
          title={primsaCanvasTitle}
          selectedLegend={selectedLegend}
          selectedCloudType={selectedCloudType}
        />
      )}
    </div>
  );
};

export default Prisma;
