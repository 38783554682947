import React, { useState } from "react";
import { useSelector } from "react-redux";

import Loader from "components/Common/Loader";
import AlertMessage from "components/Common/AlertMessage";

import { postApiCall } from "helper/postApiCall";

const AccountRequestsApprove = ({ yesParent, closeModalParent, uid }) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [approveMessage, setApproveMessage] = useState(null);
  const [successApproved, setSuccessApproved] = useState(false);

  function approveRequest() {
    setLoading(true);
    const idToken = userInfo.idToken;
    const requestBody = `{}`;
    const apiUrl = `${process.env.REACT_APP_AWS_BACKUP_SUBMIT}`;
    const setApiUrl = `${apiUrl}?uid=${uid}&type=1&adminemail=${userInfo.email}`;

    postApiCall(setApiUrl, idToken, requestBody)
      .then((response) => {
        if (response?.data?.message === "Success") {
          setSuccessApproved(true);
          setApproveMessage(response.data.message);
        }
      })
      .catch((err) => {
        setError("Failed to approved");
        setSuccessApproved(false);
      });
  }

  return (
    <div className="d-flex justify-content-center flex-column">
      {successApproved === false && (
        <>
          <p>Are you sure you want to approve this request</p>
          {loading && <Loader />}
          {loading === false && error === null && (
            <ul className="btn-ul">
              <li>
                <button
                  type="button"
                  className="teal-bordered-btn"
                  onClick={() => {
                    closeModalParent(null);
                  }}
                >
                  No
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="teal-filled-btn"
                  onClick={() => {
                    approveRequest();
                  }}
                >
                  Yes
                </button>
              </li>
            </ul>
          )}
        </>
      )}

      {successApproved === true && (
        <>
          {error !== null && (
            <div className="d-flex justify-content-center flex-column">
              <AlertMessage
                cssClassName="alert-message-danger"
                alertMessage={error}
              />
            </div>
          )}

          <>
            {approveMessage === null && <p>Request Approved!</p>}
            {approveMessage !== null && <p>{approveMessage}</p>}
            <p>
              <button
                type="button"
                className="teal-filled-btn"
                onClick={() => {
                  yesParent(null);
                }}
              >
                Ok
              </button>
            </p>
          </>
        </>
      )}
    </div>
  );
};

export default AccountRequestsApprove;
