import React, { useState, useEffect } from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

// Import Canvas
import MoriartyCanvas from 'components/Canvas/MoriartyCanvas';

import SpinnerLoader from 'components/Common/SpinnerLoader';

// Import Constant
import {
  PRISMA,
  REMEDY_TICKET,
  TREND,
  MORIARTY,
  SERVICE,
} from 'redux/dashboard/dashboardConstant';

import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

const Moriarty = () => {
  const [moriartyCanvasTitle, setMoriartyCanvasTitle] = useState(null);
  const [selectedEnvType, setSelectedEnvType] = useState(null);

  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const dashboardChart = useSelector((state) => state.dashboardChart);
  const { moriartyCanvas } = dashboardChart;

  function canvasHandler(Envtype) {
    setMoriartyCanvasTitle('Failure Details');
    setSelectedEnvType(Envtype);

    dispatch({
      type: PRISMA,
      payload: false,
    });
    dispatch({
      type: TREND,
      payload: false,
    });
    dispatch({
      type: REMEDY_TICKET,
      payload: false,
    });
    dispatch({
      type: SERVICE,
      payload: false,
    });
    dispatch({
      type: MORIARTY,
      payload: true,
    });
  }

  useEffect(() => {
    const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

    if (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: setIdToken,
        },
      };

      axios
        .get(`${process.env.REACT_APP_MORIARTY_STATS}`, config)
        .then((response) => {
          setResponseData(response.data);
          setLoading(false);
        });
    }

    return () => {
      setResponseData([]);
    };
  }, []);
  return (
    <div className='custom-card mb-3'>
      <h5 className='dashboard-title mb-3'>Moriarty Run</h5>

      {loading && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center mt-3'>
            <SpinnerLoader />
          </Col>
        </Row>
      )}
      {loading === false && responseData.length === 0 && (
        <Row>
          <Col className='d-flex justify-content-center align-items-center mt-3'>
            <span className='custom-no-data-message-text'>
              No Data to Display
            </span>
          </Col>
        </Row>
      )}
      {loading === false && responseData.length > 0 && (
        <Table
          responsive
          className=' mb-0 table-centered table-nowrap table-borderless'
        >
          <thead className='dashboard-table-head'>
            <tr>
              <td>DATE</td>
              <td>SUCCESS</td>
              <td>FAILURE</td>
              <td>ENV TYPE</td>
            </tr>
          </thead>
          <tbody className='dashboard-table-body'>
            {responseData.map((data, index) => {
              return (
                <tr key={index} style={{ cursor: 'default' }}>
                  <td className='text-muted mb-0 '>{data.LastExeDate}</td>
                  <td className='text-muted mb-0 '>
                    <div
                      className='approved-badge text-capitalize'
                      style={{ fontSize: '11px' }}
                    >
                      {data.Success}
                    </div>
                  </td>
                  {data.Failure === 0 && (
                    <td className='text-muted mb-0 custom-pointer '>
                      <div
                        className='pending-badge text-capitalize text-decoration-underline'
                        style={{ fontSize: '11px' }}
                      >
                        {data.Failure}
                      </div>
                    </td>
                  )}

                  {data.Failure > 0 && (
                    <td
                      className='text-muted mb-0 custom-pointer '
                      onClick={() => {
                        canvasHandler(data.Envtype);
                      }}
                    >
                      <div
                        className='pending-badge text-capitalize text-decoration-underline'
                        style={{ fontSize: '11px' }}
                      >
                        {data.Failure}
                      </div>
                    </td>
                  )}

                  <td className='text-muted mb-0'>{data.Envtype}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}

      {moriartyCanvas === true && (
        <MoriartyCanvas
          title={moriartyCanvasTitle}
          envType={selectedEnvType}
          userInfo={userInfo}
        />
      )}
    </div>
  );
};

export default Moriarty;
