import React from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ToolExceptionSidebar = () => {
  // Routing Variables
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const { t } = useTranslation();

  return (
    <div className="okta-sidebar-container">
      <ul className="okta-sidebar-logo-list">
        <li>
          {/* <img src={oktaicon} alt='Okta' /> */}
          <span className="okta-sidebar-title px-2">Tools Exception</span>
        </li>
      </ul>

      <>
        {(splitLocation[1] === "tool-exception-azure-view" ||
          splitLocation[1] === "tool-exception-azure-add") && (
          <ul className="okta-sidebar-link-list">
            <li
              className={
                splitLocation[1] === "tool-exception-view"
                  ? "okta-sidebar-link-active px-3"
                  : "okta-sidebar-link-inactive px-3"
              }
            >
              <Link to="/tool-exception-azure-view">{t("view_request")}</Link>
            </li>
            <li
              className={
                splitLocation[1] === "tool-exception-azure-add"
                  ? "okta-sidebar-link-active px-3"
                  : "okta-sidebar-link-inactive px-3"
              }
            >
              <Link to="/tool-exception-azure-add">{t("Add Request")}</Link>
            </li>
          </ul>
        )}
      </>
    </div>
  );
};

export default withRouter(ToolExceptionSidebar);
