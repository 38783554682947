import React from "react";
import { Link } from "react-router-dom";

const Submit = ({ message, errorMessgeSubmit, closeModalParent }) => {
  return (
    <div className="d-flex justify-content-center flex-column">
      {message !== null && (
        <>
          {errorMessgeSubmit ? (
            <i class="fa fa-warning text-danger h4"></i>
          ) : null}
          <p className={errorMessgeSubmit ? "text-danger" : ""}>{message}</p>
        </>
      )}
      {message === null && (
        <p>Exception Request has been Created Successfully!</p>
      )}
      <ul className="btn-ul">
        <li>
          {errorMessgeSubmit ? (
            <a>
              <button
                type="button"
                className="px-3 py-2 rounded custom-danger-alert"
                onClick={() => {
                  closeModalParent(false);
                }}
              >
                Ok
              </button>
            </a>
          ) : (
            <Link to="/tool-exception-azure-view">
              <button
                type="button"
                className="teal-filled-btn custom-danger-alert"
              >
                Ok
              </button>
            </Link>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Submit;
