import React from "react";
import { Link, useLocation, withRouter } from "react-router-dom";

const MenuTabs = ({ applicationName, accountName, accountNameLoading }) => {
  // Routing Variables
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <div className="d-flex justify-content-lg-between align-items-baseline flex-wrap">
      <ul className="cloudeye-user-menu-tabs">
        <Link to="/cloudeye-user">
          <li className={splitLocation[1] === "cloudeye-user" ? "active" : ""}>
            Dashboard
          </li>
        </Link>
        <Link to="/cloudeye-user-automation">
          <li
            className={
              splitLocation[1] === "cloudeye-user-automation" ? "active" : ""
            }
          >
            Automation
          </li>
        </Link>
        <Link to="/cloudeye-user-logs">
          <li
            className={
              splitLocation[1] === "cloudeye-user-logs" ? "active" : ""
            }
          >
            Logs
          </li>
        </Link>
      </ul>
      {applicationName !== "" && accountNameLoading === false && (
        <div className="application-name-account-name">
          {applicationName} in {accountName.data.accname}{" "}
          <i className="fa fa-suitcase"></i>
        </div>
      )}
    </div>
  );
};

export default withRouter(MenuTabs);
